(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("Asn", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhReceiptHost + "/web/asn", {}, {
            'getAsnList': { url: iServiceConfig.iwhReceiptHost + "/web/asn/getAsnList", method: 'POST'},
            'saveAsn': { url: iServiceConfig.iwhReceiptHost + "/web/asn/saveAsn", method: 'POST'},
            'createAsnCode': { url: iServiceConfig.iwhReceiptHost + "/web/asn/createAsnCode", method: 'POST'},
            'getAsnOperLogList': { url: iServiceConfig.iwhReceiptHost + "/web/asn/getAsnOperLogList", method: 'POST'},
            'deleteAsnByCode': { url: iServiceConfig.iwhReceiptHost + "/web/asn/deleteAsnByCode", method: 'POST'},
            'closeAsn': { url: iServiceConfig.iwhReceiptHost + "/web/asn/closeAsn", method: 'POST'},
            'getAsnByCode': { url: iServiceConfig.iwhReceiptHost + "/web/asn/getAsnByCode", method: 'POST'},
            'getAsnDetailList' : { url: iServiceConfig.iwhReceiptHost + "/web/asn/getAsnDetailList", method: 'POST'},
            'getAsnDetailById' : { url: iServiceConfig.iwhReceiptHost + "/web/asn/getAsnDetailById", method: 'POST'},
            'deleteAsnDetailById' : { url: iServiceConfig.iwhReceiptHost + "/web/asn/deleteAsnDetailById", method: 'POST'},
            'saveAsnDetail' : { url: iServiceConfig.iwhReceiptHost + "/web/asn/saveAsnDetail", method: 'POST'},
            'getSkuRuleInfo' : { url: iServiceConfig.iwhReceiptHost + "/web/asn/getSkuRuleInfo", method: 'POST'},
            'cancleAsn' : { url: iServiceConfig.iwhReceiptHost + "/web/asn/cancleAsn", method: 'POST'}
        });
    }]);
})();