(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleUser', UserModuleUser);

    UserModuleUser.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleUser ($resource, iServiceConfig) {
        var service = $resource('web/user', {}, {
            'list': { url: iServiceConfig.iuserHost + "/web/user/list", method: 'POST'},
            'save': {url: iServiceConfig.iuserHost + "/web/user/save", method: 'POST'},
            'delete': {url: iServiceConfig.iuserHost + "/web/user/delete", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/user/detail", method: 'POST'},
            'disable':{url: iServiceConfig.iuserHost + "/web/user/disable",method:'POST'},
            'enable':{url: iServiceConfig.iuserHost + "/web/user/enable",method:'POST'},
            'roles':{url: iServiceConfig.iuserHost + "/web/user/roles",method:'GET'},
            'editpassword':{url: iServiceConfig.iuserHost + "/web/user/editpassword",method:'POST'},
            'listEmployees':{url: iServiceConfig.iuserHost + "/web/user/listEmployees", method:'POST'},
            'menus':{url: iServiceConfig.iuserHost + "/web/user/menus", method:'GET'}
        });

        return service;
    }
})();
