(function()  {
	'use strict';

	angular.module('iwh-dataModule')
		.controller('IwhOwnerController', OwnerController);

	// 注入依赖
	OwnerController.$inject = ['IwhOwner','$rootScope', '$scope', 'NgTableParams', '$uibModal',
		'NgSelect', 'toastr', '$state', '$timeout', 'NgTree'];

	/**
	 * 控制器
	 */
	function OwnerController(IwhOwner,$rootScope, $scope, NgTableParams, $uibModal, NgSelect,
							 toastr, $state, $timeout, NgTree) {
		var vm = this;
		vm.tabs = [];
		vm.ownerData = null;
		vm.currOwnerDto = null;
		vm.ownerCode = null;
		vm.ownerCodeFilter = null;
		vm.search = search;
		vm.search1 = search1;
		vm.removeTab = removeTab;
		vm.showOwner = showOwner;
		vm.ownerDetail = ownerDetail;
		vm.confirmDelete = confirmDelete;
		vm.deleteData = deleteData;
		vm.addloc = addloc;
		vm.updateloc = updateloc;
		vm.addzone = addzone;
		vm.updatezone = updatezone;
		vm.updataOwner = updataOwner;
		vm.deleteZoneData = deleteZoneData;
		vm.confirmDeleteZone = confirmDeleteZone;
		vm.ownerdata = null;
		vm.contentId = "noSelect.html";
		vm.ownerTemplate = "owner-ownerdetail.html";
		vm.locTemplate = "owner-locdetail.html";
		vm.zoneTemplate = "owner-zonedetail.html";
		// 刷新树
		vm.ownerTableParams = new NgTableParams({count:10}, {
			getData: function(params) {
				var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'code'}, params.filter());

				return IwhOwner.getOwnerList({}, postParams,
					function(resp) {
						vm.currOwnerDto = null;
						vm.contentId = "noSelect.html";
						vm.ownerCode = "-1";
						vm.ownerName = "-1";
						vm.select = "-1";
						params.total(resp.content.tc);
					}).$promise.then(function(data) {
						var datas = data.content.datas;
						return datas;
				});
			}
		});
		vm.refreshOwnerData = function() {
			vm.ownerTableParams.reload();
		};
		vm.refreshOwnerData();

		// 树节点点击事件
		vm.selectOwner = function(data){
			vm.currOwnerDto = null;
			if ( data == undefined || data == null) {
				vm.contentId = "noSelect.html";
				vm.ownerCode = "-1";
				vm.ownerName = "-1";
				vm.select = "-1";
				return;
			}
			vm.showOwner(data);
		};


		// 通用方法
		function search() {
			vm.tableParams.reload();

		}

		function search1() {
			vm.tableParams1.reload();

		}

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		//货主库位列表
		vm.tableParams = new NgTableParams({count: 10}, {
			getData: function(params) {
				var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'code'}, params.filter());
				if($state.params.postParams!=null) {
					var  temp = new Object();
				}
				postParams.ownerCode = vm.ownerCode;

				return IwhOwner.ownerLocationList({}, postParams,
					function(resp) {
						params.total(resp.content.tc);
					}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		//货主库区列表
		vm.tableParams1 = new NgTableParams({count: 10}, {
			getData: function(params) {
				var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'code'}, params.filter());
				if($state.params.postParams!=null) {
					var  temp = new Object();
				}
				postParams.ownerCode = vm.ownerCode;

				return IwhOwner.ownerZoneList({}, postParams,
					function(resp) {
						params.total(resp.content.tc);
					}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		function showOwner(data) {
			vm.contentId = "storageTypeZone-edit.html";
			vm.ownerCode = data.code;
			vm.ownerName = data.name;
			vm.select = vm.ownerCode;
			ownerDetail(data);
			vm.search();
			vm.search1();
		}

		// 查看选中的货主信息
		function ownerDetail(data){
			IwhOwner.ownerDetail({},{ownerCode:data.code}, function(resp){
				var owner = resp.content;
                owner.ownerCode = vm.ownerCode;
                owner.ownerName = vm.ownerName;
                vm.currOwnerDto = data;
				var entity = {
					ownerTemplate:"owner-ownerdetail.html",
					locTemplate:"owner-locdetail.html",
					zoneTemplate:"owner-zonedetail.html",
					data : {
						ownerData : owner,
					},
					newOwner : false,
					active : true
				};
				vm.ownerdata = entity.data;
				vm.tabs.push(entity);
				$timeout(function(){
					vm.active = vm.tabs.length;
					entity.active = 0;
			   },0);
			});
		}

		function updataOwner(data){
			console.log(data);
			data.adsfasfasd="dfdffd";
			IwhOwner.ownerUpdate({},data,function(res){
				if(res.status === 'SUCCESS') {
					toastr.success("修改成功", "成功");
				}
			});
		}

		function updateloc(data) {
			var ownerCode = data;
			return IwhOwner.ownerLocationSave({}, ownerCode).$promise.then(function(resp) {
				if(resp.status === 'SUCCESS') {
					return resp;
				}
			});
		}

		function addloc(){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'ownerloceidt.html',
				controller : createOwnerlocModle,
				controllerAs : '$ctrl',
				resolve : {}
			});
		}
		createOwnerlocModle.$inject = [ '$uibModalInstance' ];
		function createOwnerlocModle($uibModalInstance) {
			var $ctrl = this;
			$ctrl.ok = function(){
				$uibModalInstance.close();
			};
			$ctrl.response = {};
			$ctrl.data = {};
			$ctrl.data.sortOrder = "";
			$ctrl.data.locCode = "";
			$ctrl.data.ownerCode = vm.select;
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			};
			$ctrl.submitloc = function(data) {
				if(data.locCode==""||data.locCode==null){
					toastr.error("","请选择库位编码！");
					return;
				}
				if(data.storageType==""||data.storageType==null){
                    toastr.error("","请选择库位类型！");
                    return;
				}
				var temp = updateloc(data);
				console.log(data);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						toastr.success("添加成功", '操作结果');
						vm.search();
						$ctrl.ok();
					} else {
						toastr.error(resp.errorMsg, '错误');
					}
				});
			};
		}

		function updatezone(data) {
			var ownerCode = data;
			return IwhOwner.ownerZoneSave({}, ownerCode).$promise.then(function(resp) {
				if(resp.status === 'SUCCESS') {
					return resp;
				}
			});
		}

		function addzone(){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'ownerzoneeidt.html',
				controller : createOwnerModle,
				controllerAs : '$ctrl',
				resolve : {}
			});
		}

		createOwnerModle.$inject = [ '$uibModalInstance' ];
		function createOwnerModle($uibModalInstance) {
			var $ctrl = this;
			$ctrl.ok = function(){
				$uibModalInstance.close();
			};
			$ctrl.response = {};
			$ctrl.data = {};
			$ctrl.data.sortOrder = "";
			$ctrl.data.zoneCode = "";
			$ctrl.data.ownerCode = vm.select;
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			};
			$ctrl.submitzone = function(data) {
                if(data.zoneCode==""||data.zoneCode==null){
                    toastr.error("","请选择库区编码！");
                    return;
                }
                if(data.storageType==""||data.storageType==null){
                    toastr.error("","请选择库区类型！");
                    return;
                }
				var temp = updatezone(data);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						toastr.success("添加成功", '操作结果');
						vm.search1();
						$ctrl.ok();
					} else {
						toastr.error(resp.errorMsg, '错误');
					}
				});
			};
		}

		function deleteData(row){
			console.log(row.id);
			IwhOwner.ownerLocationDelete({}, {id: row.id}, function() {
				vm.search();
			});
		}

		function confirmDelete(row) {
			vm.modalInstance = $uibModal.open({
				templateUrl: 'deleteOwnerModal.html',
				controller: deleteModal,
				controllerAs: '$ctrl',
				resolve: {
					item: function () {
						return row;
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.deleteData(item);
			});
		}

		deleteModal.$inject = ['$uibModalInstance', 'item'];
		function deleteModal($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;

			function ok() {
				$uibModalInstance.close($ctrl.item);
			}

			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}

		function deleteZoneData(row){
			console.log(row.id);
			IwhOwner.ownerZoneDelete({}, {id: row.id}, function() {
				vm.search1();
			});
		}

		function confirmDeleteZone(row) {
			vm.modalInstance = $uibModal.open({
				templateUrl: 'deleteOwnerModal.html',
				controller: deleteModal,
				controllerAs: '$ctrl',
				resolve: {
					item: function () {
						return row;
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.deleteZoneData(item);
			});
		}

		// deleteModal.$inject = ['$uibModalInstance', 'item'];
		// function deleteModal($uibModalInstance, item) {
		// 	var $ctrl = this;
		// 	$ctrl.item = item;
		// 	$ctrl.ok = ok;
		// 	$ctrl.cancel = cancel;
        //
		// 	function ok() {
		// 		$uibModalInstance.close($ctrl.item);
		// 	}
        //
		// 	function cancel() {
		// 		$uibModalInstance.dismiss('cancel');
		// 	}
		// }
	}
})();

