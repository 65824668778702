(function () {
    'use strict';

    angular.module('ireportModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('rpt-reportConfig', {
                parent: 'main',
                url: 'report/reportConfig',
                data: {
                    'pageTitle': '仓库报表配置',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'report-module/reportConfig/reportConfig.html',
                        controller: 'RptReportConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
