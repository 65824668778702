(function () {
    'use strict';
    angular.module('iergateModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('ergateappcache', {
                parent: 'main',
                url: 'ergate/appcache',
                data: {
                    'pageTitle': '应用缓存配置'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'ergate-module/appcache/appcache.html',
                        controller: 'ErgateAppCacheController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();

