(function () {
    'use strict';
    var app = angular.module('iwh-receiptModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('asn', {
            parent: 'main',
            url: 'iwh-receipt/asn?asnNum',
            params: {
                'ownerCode': null,
                'postParams': null,
                'asnNum':null
            },
            data: {
                'pageTitle': '到货通知单',
                'ownerCode' : null,
                'postParams': null,
                'asnNum':null
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-receipt-module/asn/asn.html',
                    controller: 'AsnController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

