(function () {
    'use strict';

    angular.module('iwh-shipModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('generatePickOrder', {
                parent: 'main',
                url: 'iwh-ship/generatePickOrder',
                data: {
                    'pageTitle': '拣选单生成'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/generatePickOrder.html',
                        controller: 'GeneratePickOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('pickOrder', {
                parent: 'main',
                url: 'iwh-ship/pickOrder',
                data: {
                    'pageTitle': '拣选单管理'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/pickOrder.html',
                        controller: 'PickOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('printPickOrder', {
                parent: 'main',
                url: 'iwh-ship/printPickOrder',
                data: {
                    'pageTitle': '拣选单打印'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/printPickOrder.html',
                        controller: 'PrintPickOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('pickRegStart', {
                parent: 'main',
                url: 'iwh-ship/pickRegStart',
                data: {
                    'pageTitle': '拣选开始登记'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/pickRegStart.html',
                        controller: 'PickRegStartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('pickRegEnd', {
                parent: 'main',
                url: 'iwh-ship/pickRegEnd',
                data: {
                    'pageTitle': '拣选结束登记'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/pickRegEnd.html',
                        controller: 'PickRegEndController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('wholePick', {
                parent: 'main',
                url: 'iwh-ship/wholePick',
                data: {
                    'pageTitle': '整单拣选确认'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/wholePick.html',
                        controller: 'WholePickController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('pickConfirm', {
                parent: 'main',
                url: 'iwh-ship/pickConfirm?soNum=&pickNum=',
                params: {
                    'soNum': null,
                    'pickNum': null
                },
                data: {
                    'pageTitle': '拣选确认',
                    'soNum': null,
                    'pickNum': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/pickConfirm.html',
                        controller: 'PickConfirmController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('sowingPick', {
                parent: 'main',
                url: 'iwh-ship/sowingPick',
                data: {
                    'pageTitle': '播种分拣'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/sowingPick.html',
                        controller: 'SowingPickController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('cancelPick', {
                parent: 'main',
                url: 'iwh-ship/cancelPick',
                data: {
                    'pageTitle': '取消拣货'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/cancelPick.html',
                        controller: 'CancelPickController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('pickOrderRule', {
                parent: 'main',
                url: 'iwh-ship/pickOrderRule',
                data: {
                    'pageTitle': '拣选单规则执行'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pick/pickOrderRule.html',
                        controller: 'PickOrderRuleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
        ;
    }
})();
