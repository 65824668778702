(function()  {
    'use strict';

    angular.module('itms-orderModule')
        .controller('TmsLoadMgtController', TmsLoadMgtController);

    TmsLoadMgtController.$inject = ['$rootScope','$stateParams', 'TmsRoadOrderExecute', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout', 'iServiceConfig'];

    function TmsLoadMgtController ($rootScope,$stateParams, RoadOrderExecute, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.tabs = [];
        vm.roadNum = null;
        vm.modalInstance = null;
        vm.iServiceConfig = iServiceConfig;
        vm.productType = {
            code : null,
            name : null
        };
        vm.carrier = {
            carrierCode : null,
            carrierName : null
        }
        vm.orderSource = [
                     {id : 1, title : "手工创建"},
                     {id : 2, title : "上游ERP"},
                     {id : 3, title : "上游WMS"},
                     {id : 4, title : "上游OMS"},
                 ];

        vm.transportMode = [
            {id : 10, title : "陆运"},
            {id : 20, title : "空运"},
            {id : 30, title : "水运"},
        ];

        vm.transportType = [
            {id : 10, title : "快递"},
            {id : 20, title : "物流"},
        ];
        vm.type = [
            {id : 10, title : "中转"},
            {id : 20, title : "直运"},
        ];

        vm.status = [

            {id : 20, title : "已计划"},
            {id : 30, title : "运输中"},

        ];
        vm.statusMap = {'0':'新建','10':'已提交','20':'已计划','30':'运输中','40':'已完成', '-10' :'取消'};

        vm.tstatus = [
            {id : 0, title : "新建"},
            {id : 10, title : "已提交"},
            {id : 15, title : "已审核"},
            {id : 20, title : "已分配承运商"},
            {id : 30, title : "已计划"},
            {id : 35, title : "已装货"},
            {id : 40, title : "运输中"},
            {id : 45, title : "已卸货"},
            {id : 50, title : "已签收"},
            {id : 60, title : "已回单"},
            {id : -10, title : "已取消"},
        ];
        vm.tstatusMap = {'0':'新建','10':'已提交','15':'已审核 ','20':'已分配承运商','30':'已计划','35':'已装货','40':'运输中','45':'已卸货','50' :'已签收','60':'已回单', '-10' :'取消'};


        function pageTitle() {
            return $window.document.title;
        }



      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count(),
					statusList:[20,30]
				}, params.filter());
				return RoadOrderExecute.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});


		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}



		vm.show = show;
		function show(row) {
            RoadOrderExecute.showRoadOrder({}, row.roadNum, function(resp) {
				var RoadOrderExecute = resp.content;
				var entity = {
						heading : '装卸',
						contentId : "show.html",
						active : true,
                        statusStr : vm.statusMap[RoadOrderExecute.roadOrder.status+""],
                        roadOrder : RoadOrderExecute.roadOrder,
                        transportOrderList : RoadOrderExecute.transportOrderList,
                        roadOrderGpsList : RoadOrderExecute.roadOrderGpsList,
                        transportTableParam : new NgTableParams({}, {counts: [],getData:function(){return RoadOrderExecute.transportOrderList}}),
                        gpsTableParam : new NgTableParams({}, {counts: [],getData:function(){return RoadOrderExecute.roadOrderGpsList}}),
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

        vm.load = load;
		function load(row,t) {
            if(row.status != 30){
                toastr.error("已计划状态才能装货");
                return;
            }
            RoadOrderExecute.load({}, row.id, function (resp) {
                if (resp.content == "success") {
                    toastr.success("装货成功");
                    RoadOrderExecute.showRoadOrder({}, t.roadOrder.roadNum, function(resp) {
                        t.transportOrderList = resp.content.transportOrderList;
                    });
                }
            });
        }

        vm.unload = unload;
        function unload(row,t) {
            if(row.status != 40){
                toastr.error("运输状态才能卸货");
                return;
            }
            RoadOrderExecute.unload({}, row.id, function (resp) {
                if (resp.content == "success") {
                    toastr.success("卸货成功");
                    RoadOrderExecute.showRoadOrder({}, t.roadOrder.roadNum, function(resp) {
                        t.transportOrderList = resp.content.transportOrderList;
                    });
                }
            });
        }


        vm.confirmSign = confirmSign;
        function confirmSign(row,t) {
            if(row.status!=45){
                toastr.error("已卸货状态才能签收");
                return;
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'signConfirm.html',
                controller: signModal,
                controllerAs: '$ctrl',
                resolve: {
                    item :{
                        row : row,
                        t : t
                    }
                }
            });
        }
        signModal.$inject = ['$uibModalInstance','item'];
        function signModal($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.vo = {
                signatory : null,
                signNotes : null,
                id : item.row.id,
                status : item.row.status ,
                transportNum :item.row.transportNum,
                custOrderNum : item.row.custOrderNum,
                transportOrderDetailList : []
            }
            $ctrl.sign = sign;
            function sign() {
                $ctrl.vo.transportOrderDetailList.push($ctrl.item.row);
                    RoadOrderExecute.sign({}, $ctrl.vo, function (resp) {
                        if (resp.content == "success") {
                            toastr.success("签收成功");
                            RoadOrderExecute.showRoadOrder({},$ctrl.item.t.roadOrder.roadNum, function(resp) {
                                $ctrl.item.t.transportOrderList = resp.content.transportOrderList;
                                $uibModalInstance.close($ctrl.item);
                            });
                        }
                    });

            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.batchPass = batchPass
        function  batchPass() {
            var vo = {
                examineList : [],
                accept : 1
            }
            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                vo.examineList.push(dto);
            }
            RoadOrderExecute.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核通过");
                    vm.tableParams.reload();
                }
            });
        }

        vm.batchCancel = batchCancel
        function  batchCancel(t) {
            var vo = {
                examineList : [],
                accept : 2
            }
            var examineList = [];
            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                console.log(dto.status);
                examineList.push(dto);

            }
            vo.examineList = examineList;
            RoadOrderExecute.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核拒绝成功");
                    vm.tableParams.reload();
                }
            });
        }


        vm.cloneObj = cloneObj;
        function cloneObj(obj) {
            var newObj = {};
            if (obj instanceof Array) {
                newObj = [];
            }
            for (var key in obj) {
                var val = obj[key];
                newObj[key] = typeof val === 'object' ? cloneObj(val): val;
            }
            return newObj;
        };

        vm.driveOutOne = driveOutOne;
        function driveOutOne(t) {
            if (t.roadOrder.status != 20) {
                toastr.error("已计划状态才能发车");
                return;
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'driveOutConfirm.html',
                controller: driveOutOneModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : t.roadOrder,
                }
            });
        }

        driveOutOneModal.$inject = ['$uibModalInstance','item'];
        function driveOutOneModal($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.driveOut = driveOut;
            function driveOut() {
                var list = [];
                list.push($ctrl.item)
                    RoadOrderExecute.driveOut({}, list, function (resp) {
                        if (resp.content == "success") {
                            toastr.success("发车成功");
                            vm.tableParams.reload();
                        }
                    });
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.driveOut = driveOut;
        function driveOut() {
            if (vm.tableParams.getSelected() == 0) {
                toastr.error("请先选择运输计划");
                return;
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'driveOutConfirm.html',
                controller: driveOutModal,
                controllerAs: '$ctrl',
                resolve: {
                }
            });
        }
        driveOutModal.$inject = ['$uibModalInstance'];
        function driveOutModal($uibModalInstance) {
            var $ctrl = this;
            $ctrl.item = vm.tableParams.getSelected();
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.driveOut = driveOut;
            function driveOut() {
                for (var i = 0; i < $ctrl.item.length; i++) {
                    if ($ctrl.item[i].status == 20) {
                    } else {
                        toastr.error("已计划状态才能发车");
                        return;
                    }
                    RoadOrderExecute.driveOut({}, $ctrl.item, function (resp) {
                        if (resp.content == "success") {
                            toastr.success("发车成功");
                            $uibModalInstance.close($ctrl.item);
                            vm.tableParams.reload();
                        }
                    });
                }
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.carBack = carBack;
        function carBack(t) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'carBackConfirm.html',
                controller: carBackModal,
                controllerAs: '$ctrl',
                resolve: {
                }
            });
        }
        carBackModal.$inject = ['$uibModalInstance'];
        function carBackModal($uibModalInstance) {
            var $ctrl = this;
            $ctrl.item = vm.tableParams.getSelected();
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.carBack = carBack;
            function carBack() {
                if ($ctrl.item.length == 0) {
                    toastr.error("请先选择运输计划");
                    return;
                }
                for (var i = 0; i < $ctrl.item.length; i++) {
                    if ($ctrl.item[i].status == 30) {
                    } else {
                        toastr.error("运输中状态才能收车");
                        return;
                    }
                    RoadOrderExecute.carBack({}, $ctrl.item, function (resp) {
                        if (resp.content == "success") {
                            toastr.success("收车成功");
                            $uibModalInstance.close($ctrl.item);
                            vm.tableParams.reload();
                        }
                    });
                }
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
