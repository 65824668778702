(function () {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('iEptShipOrder', iEptShipOrder);

        iEptShipOrder.$inject = ['$resource', 'iServiceConfig'];

    function iEptShipOrder($resource, iServiceConfig) {
        var service = $resource('web/shipOrder', {}, {
            'list': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/list", method: 'POST'},
            'listLog': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/listLog", method: 'POST'},
            'listDetail': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/listDetail", method: 'POST'},
            'saveHeader': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/saveHeader", method: 'POST'},
            'saveDetail': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/saveDetail", method: 'POST'},
            'deleteDetailBatch': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/deleteDetailBatch", method: 'POST'},
            'getById': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/getById/:id", method: 'GET'},
            'getDetailById': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/getDetailById/:id", method: 'GET'},
            'confirm': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/confirm/:id", method: 'GET'},
            'confirmBatch': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/confirmBatch", method: 'POST'},
            'pushToTms': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/pushToTms/:id", method: 'GET'},
            'pushToWms': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/pushToWms/:id", method: 'GET'},
            'cancel': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/cancel/:id", method: 'GET'},
            'cancelBatch': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/cancelBatch", method: 'POST'},
            'pushToTmsBatch': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/pushToTms/batch", method: 'POST'},
            'pushToWmsBatch': {url: iServiceConfig.ienterpriseHost + "/web/shipOrder/pushToWms/batch", method: 'POST'},
            'getWarehouseList': {url: iServiceConfig.issoHost + "/api/warehouse/getWarehouseList", method: 'GET'}
        });

        return service;
    }
})();
