(function()  {
    'use strict';

    angular.module('iwh-dataModule')
        .controller('IwhEquipmentController', EquipmentController);

    EquipmentController.$inject = ['$scope','$rootScope','$stateParams', 'IwhEquipment',
        'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function EquipmentController ($scope,$rootScope,$stateParams, Equipment, NgTableParams,
                                  $uibModal, NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
            {id : 1, title : "启用"},
            {id : -1, title : "禁用"},
        ];
        vm.type = [  
             {id : null, title : "不限"},
             {id : 10, title : "采购发货"},
             {id : 20, title : "其他"}
         ];
        
        function pageTitle() {
            return $window.document.title;
        }


        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Equipment.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        if($state.params.carrierCode!=null) {
            // vm.tableParams;
        }
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.validate = validate;
        function validate(carrierCode){
        	if(carrierCode==null ||carrierCode==""){
        		return
			}else{
                Equipment.validateCode({}, carrierCode, function(resp) {
                    if(resp.content=="success"){

                    }
                });
			}
		}

		vm.createEquipment = createEquipment;
		function createEquipment() {
            Equipment.createEquipment({}, {}, function(resp) {
            	var equipmentSpecs = resp.content;
                var entity = {
                    heading: '新建',
                    contentId: "createEquipment.html",
                    active: true,
                    watch: '',
                    data: {
                        status: 1,
						specStatus:1,
                        specBeanList: equipmentSpecs.specBeanList
                    }

                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });


		}

		vm.changed = changed;
		function changed(t){
            if(t.watch == '-1'){
                t.data.specCode = "";
                t.data.type = "";
                t.data.length = "";
                t.data.width = "";
                t.data.height = "";
                t.data.load = "";
                t.data.specId = "";
                t.data.specRemark = "";
            	return;
			}
            Equipment.getEquipmentSpec({},t.watch,function(resp){
            	var equipmentSpec = resp.content;
                t.data.specCode = equipmentSpec.specCode;
                t.data.type = equipmentSpec.type;
                t.data.length = equipmentSpec.length;
                t.data.width = equipmentSpec.width;
                t.data.height = equipmentSpec.height;
                t.data.load = equipmentSpec.load;
                t.data.specId = equipmentSpec.specId;
                t.data.specRemark = equipmentSpec.specRemark;
                 });
		}

		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = t.data;
			 if(!t.data.specCode){
			     toastr.error("","规格编码不能为空！");
			     return;
             }
			 if(t.data.eid){
                 Equipment.saveEquipment({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 Equipment.saveEquipment({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.show = show;
		function show(row) {
            Equipment.showEquipment({}, row.id, function(resp) {
				var Carrier = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showEquipment.html",
						active : true,
						data : Carrier,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = function(row) {
            Equipment.editeEquipment({}, row.id, function(resp) {
				var editeVO = resp.content;
				var entity = {
                    heading : '编辑',
                    contentId : "createEquipment.html",
                    active : true,
                    watch : editeVO.specCode,
                    data : editeVO,
                    specBeanList: editeVO.specBeanList
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
			 });
		};

        vm.validate = function(code){
            if(code==null ||code==""){
                return
            }else{
                Equipment.validateCode({}, code, function(resp) {
                    if(resp.content=="success"){}
                });
            }
        };

        vm.validateSpecCode = validateSpecCode;
        function validateSpecCode(t){
            if(t.data.specCode==null ||t.data.specCode==""){
                return
            }else if(t.watch=="新建"){
                Equipment.validateSpecCode({}, t.data.specCode, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }
        vm.enable = enable;
        function enable(t){
        	var vo = {};
        	vo.status = t.status;
        	vo.id = t.id;
            Equipment.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    if(vo.status==1){
                        toastr.success("禁用成功！")
                    }else{
                        toastr.success("启用成功！");
                    }
                    vm.tableParams.reload();
                }
            });
		}
		
		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                Equipment.deleteEquipment({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }
		
		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            if (t.data.type==null||t.data.type==''){
                toastr.error("请选择类型！");
                return;
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t, item.index, item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
