(function()  {
    'use strict';

    angular.module('iwh-receiptModule').controller('ReceiptPaperController', ReceiptPaperController);
    ReceiptPaperController.$inject = ["$rootScope","$stateParams", "$scope", "$state", "$timeout", "toastr", "ReceiptPaper", "$uibModal"];
    function ReceiptPaperController($rootScope,$stateParams, $scope, $state, $timeout, toastr, ReceiptPaper, $uibModal) {
        var vm = this;
        vm.allowReceipt = true;
        vm.tabs = [];                   // 记录所有的tab
        vm.active = 0;                  // 总共打开tab的总数
        vm.asnNum = "";
        vm.asnInfoObj = {};
        vm.sliceRowIndex = {};
        vm.errorFormStyle = "validateError";        // 错误表单元素样式名称
        vm.success = success;
        vm.fail = fail;
        // ASN详情状态常量
        vm.asnDetailStatus = {
            newdata : "1",
            none : "10",
            doing : "20",
            over : "25",
            completed : "30"
        };
        
        // ASN详情不同状态对应的样式名称
        vm.asnDetailStyle = {
            none : "receive_normal",
            doing : "receive_processing",
            completed : "receive_completed"
        };
        
        // 进入页面获取扫描到货通知单焦点
        jQuery("#asnCodeInput").focus();

        // 缓存商品序号
        vm.cacheSkuSerial = {
            data : {
                 // "货主编码 + 商品编码" : "|商品序号1|商品序号2|....|"
            },

            // 判断商品序号是否存在
            skuSerialIsExist : function(cacheObj, row, skuSerial) {
                var skuKey = row.ownerCode + "-$$-" + row.skuCode,
                    alreadyCollectedSerial  = cacheObj.data[skuKey];
                if ( undefined == alreadyCollectedSerial ) {
                    return false;
                } else {
                    return -1 != (alreadyCollectedSerial || "").indexOf("|" + skuSerial + "|");
                }
            },

            // 添加商品序号
            addSkuSerial : function (cacheObj, row, skuSerial) {
                var skuKey = row.ownerCode + "-$$-" + row.skuCode,
                    alreadyCollectedSerial = cacheObj.data[skuKey];
                if ( alreadyCollectedSerial == undefined ) {
                    cacheObj.data[skuKey] = "|" + skuSerial + "|";
                } else {
                    if ( !cacheObj.skuSerialIsExist(cacheObj, row, skuSerial) ) {
                        alreadyCollectedSerial += skuSerial + "|";
                        cacheObj.data[skuKey] = alreadyCollectedSerial;
                    }
                }
            },

            // 移除商品序号
            removeSkuSerial : function(cacheObj, row, skuSerial) {
                var skuKey = row.ownerCode + "-$$-" + row.skuCode,
                    alreadyCollectedSerial = cacheObj.data[skuKey];
                if ( undefined != alreadyCollectedSerial ) {
                    cacheObj.data[skuKey] = alreadyCollectedSerial.replace("|" + skuSerial + "|", "|");
                }
            }
        };


        vm.initTableRowData = initTableRowData;                     // 表格行初始化
        vm.eventKeyupHandler = eventKeyupHandler;                   // keyup事件处理
        vm.eventBlurHandler = eventBlurHandler;                     // blur事件处理
        vm.eventTableRowClickHandler = eventTableRowClickHandler;   // 表格行点击事件
        vm.sliceTableRow = sliceTableRow;                           // 拆分行
        vm.removeTableRow = removeTableRow;                         // 移除指定行
        vm.overReceive = overReceive;                               // 超收
        vm.cancelOverReceive = cancelOverReceive;                   // 取消超收
        vm.serviceLoadAsnInfo = serviceLoadAsnInfo;                 // 根据ASN编码加载ASN基础信息
        vm.changeOrder = changeOrder;                               // 根据ASN编码加载ASN基础信息， 切换
        vm.serviceAffirmReceiving = serviceAffirmReceiving;         // 确认收货
        vm.serviceAffirmAllReceiving = serviceAffirmAllReceiving;   // 确认整单收货
        vm.initRowStyle = initRowStyle;                             // 设置行颜色
        vm.isNeedReceive = isNeedReceive;                           // 判断是否可以收货
        vm.collectSeriesNumber = collectSeriesNumber;               // 采集商品序列号
        vm.initLoadFromParam = function() {
            if($stateParams.asnNum) {
                vm.asnNum = $stateParams.asnNum;
                vm.serviceLoadAsnInfo();
            }
        }
        function collectSeriesNumber($event, $index, row, vm) {
            var cacheSkuSerial = vm.cacheSkuSerial;
            $uibModal.open({
                templateUrl : 'collectSkuSeriNum.html',
                controller : ['$uibModalInstance', 'item', function($uibModalInstance, item) {
                    var $ctrl = this;
                    $ctrl.item = item;

                    // 关闭按钮
                    $ctrl.close = function() {
                        $uibModalInstance.close($ctrl.item);
                    };

                    // 移动序列号
                    $ctrl.removeSeri = function($event, data, seriNum) {
                        data._skuDelFlag = 1;
                        data._skuDelSeri = seriNum;
                    };

                    // 确认移除
                    $ctrl.removeOk = function($event, data, seriNum) {
                        var seris = data.seriNums;
                        if ( !!seris && seris instanceof Array ) {
                            var tSeris = [];
                            for ( var i = 0, len = seris.length; i < len; i++ ) {
                                if ( seriNum != seris[i] ) {
                                    tSeris.push(seris[i]);
                                }
                            }
                            data.seriNums = tSeris;
                            cacheSkuSerial.removeSkuSerial(cacheSkuSerial, data, seriNum);
                        }
                        data._skuDelFlag = 0;
                        data._skuDelSeri = "";
                    };

                    // 取消移除
                    $ctrl.removeCancel = function($event, data) {
                        data._skuDelFlag = 0;
                        data._skuDelSeri = "";
                    };

                    // 扫描序列号
                    $ctrl.scanSeriNum = function($event, data) {
                        if ( data._skuDelFlag == 1 ) {
                            data._skuDelFlag = 0;
                            data._skuDelSeri = "";
                        }

                        var keycode = window.event ? $event.keyCode : ($event.which || $event.keyCode);
                        if ( keycode == 13 ) {
                            var scanSeriNum = $ctrl.item.scanSeriNum, hasError = false;
                            $ctrl.item.errMsg = "";
                            if ( row.seriNums.length >= row.inputReceiveQty ) {
                                $ctrl.item.errMsg = "商品序号采集完毕，不能继续采集";
                                $ctrl.item.scanSeriNum = "";
                                hasError = true;
                            }

                            // 判重
                            if ( !hasError ) {
                                if ( cacheSkuSerial.skuSerialIsExist(cacheSkuSerial, data, scanSeriNum) ) {
                                    $ctrl.item.errMsg = "商品序号已采集";
                                    hasError = true;
                                }
                            }

                            // 验证序列号是否重复
                            if ( !hasError ) {
                                ReceiptPaper.checkSerialNumAvailable({}, {
                                    "ownerCode": data.ownerCode,
                                    "skuCode": data.skuCode,
                                    "sn": scanSeriNum
                                }, function (resp) {
                                    if (resp.status == "SUCCESS" && !!resp.content) {
                                        if (resp.content.snExist == 0) {
                                            data.seriNums.push(resp.content.seriNum);
                                            data.seriNums = data.seriNums.reverse();
                                            $ctrl.item.scanSeriNum = "";
                                            cacheSkuSerial.addSkuSerial(cacheSkuSerial, data, resp.content.seriNum);
                                        } else {
                                            $ctrl.item.errMsg = resp.content.errMsg || "";
                                        }
                                    }
                                });
                            }
                        }
                    };
                }],
                controllerAs : '$ctrl',
                backdrop : 'static',
                resolve : {
                    item : {
                        row : row,
                        errMsg : ""
                    }
                }
            });
        }


        function initRowStyle(rowData, isReceving) {
            if ( rowData ) {
                if ( isReceving ) {
                    rowData.styleName = vm.asnDetailStyle.doing; // 正在收货
                } else {
                    // 收货中
                    if ( rowData.status == vm.asnDetailStatus.newdata || rowData.status == vm.asnDetailStatus.none
                            || rowData.status == vm.asnDetailStatus.doing  || rowData.status == vm.asnDetailStatus.over ) {
                        if ( rowData.styleName == vm.asnDetailStyle.doing ) {
                            rowData.styleName = vm.asnDetailStyle.none;
                        } else {
                            rowData.styleName = vm.asnDetailStyle.doing;
                        }
                    } else {
                        rowData.styleName = vm.asnDetailStyle.completed; // 收货完成
                    }
                }
                return;
            }
            rowData.styleName = vm.asnDetailStyle.none;
        }


        function isNeedReceive(rowData) {
            if(rowData) {
                if(rowData.status == vm.asnDetailStatus.newdata || rowData.status == vm.asnDetailStatus.none
                            || rowData.status == vm.asnDetailStatus.doing  || rowData.status == vm.asnDetailStatus.over) {
                    return true;
                }
            }
            return false;
        }
        
        function changeOrder() {
            vm.asnNum = "";
            vm.asnInfoObj = {};
            vm.sliceRowIndex = {};
            setTimeout(function(){
                jQuery("#asnCodeInput").focus();
            }, 10);
        }
        
        function initTableRowData(row){
            if ( undefined == row ) {
                vm.allowReceipt = true;
            }
            row._status = row.status;
            row._lineNum = row.lineNum;
            row.seriNums = [];

            // 控制商品序号移除
            row._skuDelFlag = 0;  // 移除标志（控制页面展示）
            row._skuDelSeri = ''; // 待移除序号

            if (undefined == row.receiveQty) {
                row._qty = row.qty;
            } else {
                row._qty = row.qty - row.receiveQty;
            }

            // 输入量
            if ( row.inputReceiveQty < 0 ) {
                row.inputReceiveQty = "";
            }
        }

        function eventBlurHandler($event, row) {
            var target = $event.target, domObj = angular.element(target);
            // LPN输入框
            if ( domObj.hasClass("inputReceiveLPN") ) {
                row.inputReceiveLPN = _valideCodeFormat(domObj, row.inputReceiveLPN, true);
            }
        }

        function eventKeyupHandler($event, row) {
            var target = $event.target, domObj = angular.element(target);

            // ASN输入框
            if ( domObj.hasClass("asnNum") ) {
                var keycode = window.event ? $event.keyCode : ($event.which || $event.keyCode);
                if ( keycode == 13 ) {
                    serviceLoadAsnInfo();
                }
            }

            // CASE输入框
            else if ( domObj.hasClass("inputReceiveCase") ) {
                row.inputReceiveCase = _valideCodeFormat(domObj, row.inputReceiveCase, false);
            }

            // 库位输入框
            else if ( domObj.hasClass("inputReceiveLoc") ) {
                if(!row.oldInputReceiveLoc || row.oldInputReceiveLoc != row.inputReceiveLoc) {
                	vm.success();
                    ReceiptPaper.validateLocationExist({},{
                        locationCode : row.inputReceiveLoc
                    },function(resp){
                        var data = resp.content;
                        if ( data.status > 0 ) {
                            row.locError = false;
                            row.inputReceiveLoc = data.locationCode;
                            domObj.removeClass(vm.errorFormStyle);
                        } else {
                            row.locError = true;
                            domObj.addClass(vm.errorFormStyle);
                        }
                        row.oldInputReceiveLoc = row.inputReceiveLoc;
                    },function(resp){
                    	vm.fail();
                    });
                }
            }

            // 收货量输入框
            else if ( domObj.hasClass("inputReceiveQty") ) {
                var tInputReceiveQty = row.inputReceiveQty || "";
                row.inputReceiveQty = tInputReceiveQty.replace(/(^0)|([^0-9])/g, "");
            }
        }

        function eventTableRowClickHandler($event, row) {
            var targetNode = $event.target.nodeName.toLowerCase();
            console.log(targetNode);
            if ( targetNode == "label" || targetNode == "div" || targetNode == "td" ) {
                vm.initRowStyle(row, false);
                row.doreceiving = row.status != vm.asnDetailStatus.completed && !row.doreceiving;
            } else {
                vm.initRowStyle(row, true);
                row.doreceiving = row.status != vm.asnDetailStatus.completed;
            }
        }

        function overReceive($event, $index, row) {
            if ( undefined == row ) {
                return;
            }

            // 处理事件冒泡
            $event.stopPropagation();
            row.inputReceiveQty = "";
            row.status = vm.asnDetailStatus.over;
            vm.initRowStyle(row, true);
        }

        function cancelOverReceive($event, $index, row) {
            if ( undefined == row ) {
                return;
            }

            // 处理事件冒泡
            $event.stopPropagation();

            row.status = vm.asnDetailStatus.completed;
            row.styleName = vm.asnDetailStyle.completed;
        }

        function sliceTableRow($event, $index, row) {
            if ( undefined == row ) {
                return;
            }
            // 处理事件冒泡
            $event.stopPropagation();

            row.styleName = vm.asnDetailStyle.doing;

            var _t = angular.copy(row);
            var indexObj = vm.sliceRowIndex[_t.lineNum];
            _t.sliceRow = "1";

            // 设置拆分行子行号
            if ( undefined == indexObj ) {
                indexObj = 0;
                vm.sliceRowIndex[_t.lineNum] = indexObj;
            }
            indexObj += 1;
            vm.sliceRowIndex[_t.lineNum] = indexObj;

            _t.lineNum = _t._lineNum + "_" + indexObj;
            _t.qty = "";
            _t.receiveQty = "";
            _t.surplusQty = "";
            _t.inputReceiveQty = "";
            vm.asnInfoObj.asnDetailBeanList.splice($index + 1, 0, _t);

        }

        function removeTableRow($event, $index, row) {
            $event.stopPropagation(); // 处理事件冒泡
            vm.asnInfoObj.asnDetailBeanList.splice($index, 1);
        }

        function serviceLoadAsnInfo() {
            console.log("serviceLoadAsnInfo");
            if ( (vm.asnNum || "").replace(/(^\s+)|(\s+$)/g, "") == "" ) {
                toastr.error("到货通知单号不能为空");
                vm.fail();
                return;
            }
            // 当已经加载后就不再加载
            // if(vm.asnInfoObj.asnNum && vm.asnInfoObj.asnNum == vm.asnNum) {
            // 	return;
            // }
            // 初始化
            vm.asnInfoObj = {};
            vm.allowReceipt = true;
            vm.success();
            ReceiptPaper.getAsnInfo({}, {
                asnNum : vm.asnNum
            }, function (resp) {
                vm.allowReceipt = false;
                vm.asnInfoObj = resp.content;
                if( vm.asnInfoObj.asnDetailBeanList ) {
                	var asnJsonStr = vm.asnInfoObj.asnJsonStr;
                    angular.forEach(vm.asnInfoObj.asnDetailBeanList, function(data,index,array){
                        data.inputReceiveLPN = data.lpn;
                        data.inputReceiveCase = data.caseNum;
                        data.inputReceiveLoc = data.receiveLoc;
                        data.bizType = "RECEIPT";
                        data.needDefault = true;
                        data.needRequire = true;
                        data.bizNum = vm.asnInfoObj.asnNum;
                        data.bizExtNum = vm.asnInfoObj.extNum;
                        data.bizLineNum = data.lineNum;
                        data.bizExtLineNum = data.extLineNum;
                        data.bizJson = asnJsonStr;
                        data.bizDetailJson = angular.toJson(data);
                        data.uomBaseQty = data.uomQty;      // UOM对应的基础数量
                        if(data.status == vm.asnDetailStatus.completed) {
                            vm.initRowStyle(data);
                        }
                    });
                }
            },function(resp) {
            	vm.fail();
            })
        }

        function serviceAffirmReceiving() {
        	vm.success();
            var dataList = vm.asnInfoObj.asnDetailBeanList, params = [];
            if ( !(dataList instanceof Array) && typeof dataList.length == "undefined" ) {
                toastr.error("收货数据异常");
                vm.fail();
                return;
            }
            for (var i = 0; i < dataList.length; i++ ) {
                var t = dataList[i];
                if ( vm.isNeedReceive(t) && t.styleName == vm.asnDetailStyle.doing ) {
                    params.push(t);
                }
            }

            if ( params.length <= 0 ) {
                toastr.error("请选择要收货的商品（选中商品行背景绿色显示）");
                vm.fail();
                return;
            }

            // 验证商品
            for (var i = 0; i < params.length; i++ ) {
                var param = params[i];
                if ( !param.inputReceiveLoc ) {
                    toastr.error("行号："+param.lineNum+" 收货库位未输入，请输入");
                    vm.fail();
                    return;
                }
                if ( param.locError == true ) {
                    toastr.error("行号："+param.lineNum+" 收货库位[" + param.inputReceiveLoc + "]不存在，请仔细确认");
                    vm.fail();
                    return;
                }
//                if ( !param.inputReceiveLPN ) {
//                    toastr.error("行号："+param.lineNum+" LPN未输入，请输入");
//                    vm.fail();
//                    return;
//                }

                var _actualQty = param.inputReceiveQty || 0;
                if ( !("" + _actualQty).match(/(^0$)|(^[1-9][0-9]*$)/g) ) {
                    toastr.error("上架单明细号 [" + param.lineNum +
                        "] 上架数量 [" + _actualQty + "] 格式错误。<br/>格式：正整数，只允许包含0-9数字，不能是0开头");
                    vm.fail();
                    return;
                }

                if ( _actualQty <= 0) {
                    toastr.error("行号："+param.lineNum+" 收货数量必须 > 0，请重新输入");
                    vm.fail();
                    return;
                }

                if ( param.requireLicenseNum == 1 && param.seriNums.length != param.inputReceiveQty ) {
                    toastr.error("行号："+param.lineNum+" 商品采集序列号个数与收货数量不匹配");
                    vm.fail();
                    return;
                }
            }

            callReceiptService({
                asnNum: vm.asnNum,
                asnDetails: params
            });
        }

        function serviceAffirmAllReceiving() {
        	vm.success();
            var dataList = vm.asnInfoObj.asnDetailBeanList, params = [];
            if ( !(dataList instanceof Array) && typeof dataList.length == "undefined" ) {
                toastr.error("收货数据异常");
                vm.fail();
                return;
            }
            for (var i = 0; i < dataList.length;  i++ ) {
                var t = dataList[i];
                if ( t.status == vm.asnDetailStatus.completed ) {
                    continue;
                }
                params.push(t);
            }

            if ( params.length <= 0 ) {
                toastr.error("请选择要收货的商品（选中商品行背景绿色显示）");
                vm.fail();
                return;
            }

            // 验证商品
            for (var i = 0; i < params.length; i++ ) {
                var param = params[i];
                if ( !param.inputReceiveLoc ) {
                    toastr.error("行号："+param.lineNum+" 收货库位未输入，请输入");
                    vm.fail();
                    return;
                }
                if ( param.locError == true ) {
                    toastr.error("行号："+param.lineNum+" 收货库位[" + param.inputReceiveLoc + "]无效，请仔细确认");
                    vm.fail();
                    return;
                }
//                if ( !param.inputReceiveLPN ) {
//                    toastr.error("行号："+param.lineNum+" LPN未输入，请输入");
//                    vm.fail();
//                    return;
//                }

                var _actualQty = param.inputReceiveQty || 0;
                if ( !("" + _actualQty).match(/(^0$)|(^[1-9][0-9]*$)/g) ) {
                    toastr.error("上架单明细号 [" + param.lineNum +
                        "] 上架数量 [" + _actualQty + "] 格式错误。<br/>格式：正整数，只允许包含0-9数字，不能是0开头");
                    vm.fail();
                    return;
                }

                if ( _actualQty <= 0) {
                    toastr.error("行号："+param.lineNum+" 收货数量必须 > 0，请重新输入");
                    vm.fail();
                    return;
                }

                if ( param.requireLicenseNum == 1 && param.seriNums.length != param.inputReceiveQty ) {
                    toastr.error("行号："+param.lineNum+" 商品采集序列号个数与收货数量不匹配");
                    vm.fail();
                    return;
                }
            }

            callReceiptService({
                asnNum: vm.asnNum,
                asnDetails: params
            });
        }

        function callReceiptService(param) {
        	vm.success();
        	var list = param.asnDetails;
        	var batchList = [];
        	var j = 0;
        	if (list.length > 400) {
        		
        		 for (var i = 0; i < list.length; i++ ) {
        			 if (i % 400 == 0) {
        				 batchList[j] = [];
        				 j = j + 1;
        			 }
        			 batchList[j-1].push(list[i]);
        		 }
        		 var index = 0;
             	callReceipt(batchList, param.asnNum, index);
        	} else {
        		ReceiptPaper.receiptPC({}, param, function (resp) {
                    var content = resp.content;
                    if ( content.resultType == "warn" ) {
                        var callParam = resp.content.backParamsJson, errorMsg = resp.content.errorMsg;
                        vm.modalInstance = $uibModal.open({
                            templateUrl : 'confirm-warning.html',
                            controller : ['$uibModalInstance', 'item', function($uibModalInstance, item) {
                                var $ctrl = this;
                                $ctrl.item = item;
                                $ctrl.ok = ok;
                                $ctrl.cancel = cancel;

                                function ok() {
                                    $uibModalInstance.close($ctrl.item);
                                    callReceiptService(eval("(" + callParam + ")"));
                                }

                                function cancel() {
                                    $uibModalInstance.dismiss('cancel');
                                }
                            }],
                            controllerAs : '$ctrl',
                            resolve : {
                                item : {
                                    errorMsg : errorMsg
                                }
                            }
                        });
                    } else {
                        toastr.success("收货成功");
                        vm.success();
                        vm.serviceLoadAsnInfo();
                    }
                },function(resp) {
                	vm.success();
                });
        	}
        }
        
        function callReceipt(batchList, asnNum, index) {
        	console.log("callReceipt batchList index,length: ", index, batchList.length);
        	if (index >= batchList.length) {
        		toastr.success("当前收货单已全部收货完成");
        		vm.serviceLoadAsnInfo();
        		console.log("callReceipt end of batchList index,length: ", index, batchList.length);
        		return;
        	}
        	var param = {asnDetails: batchList[index], asnNum: asnNum};
    		ReceiptPaper.receiptPC({}, param, function (resp) {
                var content = resp.content;
                if ( content.resultType == "warn" ) {
                	toastr.error("第 "+(index)+" 批，存储收货警告，共 "+batchList.length+" 批. 请先处理！");
                    var callParam = resp.content.backParamsJson, errorMsg = resp.content.errorMsg;
                    vm.modalInstance = $uibModal.open({
                        templateUrl : 'confirm-warning.html',
                        controller : ['$uibModalInstance', 'item', function($uibModalInstance, item) {
                            var $ctrl = this;
                            $ctrl.item = item;
                            $ctrl.ok = ok;
                            $ctrl.cancel = cancel;

                            function ok() {
                                $uibModalInstance.close($ctrl.item);
                                callReceiptService(eval("(" + callParam + ")"));
                            }

                            function cancel() {
                                $uibModalInstance.dismiss('cancel');
                            }
                        }],
                        controllerAs : '$ctrl',
                        resolve : {
                            item : {
                                errorMsg : errorMsg
                            }
                        }
                    });
                } else {
                	index = index + 1;
                    toastr.success("第 "+(index)+" 批，已收货成功，共 "+batchList.length+" 批");
                    vm.success();
                    callReceipt(batchList, asnNum, index)
                }
            },function(resp) {
            	vm.success();
            });
        }

        function fail() {
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }
        success();
        
        //////////////////////////////////////////////////////////////////////////////
        // 获取指定lineNum的对象
        function _getSliceParentRow(lineNum){
            var _datas = vm.asnInfoObj.asnDetailBeanList;
            for ( var i = 0, len = _datas.length; i < len; i++ ) {
                var t = _datas[i];
                if ( t.lineNum == lineNum ) {
                    return t;
                }
            }
        }

        // 获取指定lineNum拆分行输入QTY的总数
        function _getSliceRowInputQtyCount (lineNum) {
            var _datas = vm.asnInfoObj.asnDetailBeanList, count = 0;
            for ( var i = 0, len = _datas.length; i < len; i++ ) {
                var t = _datas[i], tempInputQty = parseInt(t.inputReceiveQty);
                if ( isNaN(tempInputQty) ) {
                    tempInputQty = 0;
                }
                if ( t.sliceRow == "1" && t.lineNum.indexOf(t._lineNum + "_") != -1 ) {
                    count += tempInputQty;
                }
            }
            return count;
        }

        // 验证LPN、CASE、库位代码格式
        function _valideCodeFormat(domObj, code, flag) {
            code = (code || "");
            code = code.replace(/(^\s+)|(\s+$)/g, ""); // 替换首尾空格
            code = code.replace(/[^A-Za-z0-9-]+/g, ""); // 替换非合法数字
            if ( code.length <= 0 ) {
                if ( flag ) {
                    domObj.addClass(vm.errorFormStyle)
                }
            } else {
                domObj.removeClass(vm.errorFormStyle);
            }
            return code.replace(/[^A-Za-z0-9-]+/g, "");
        }
        vm.initLoadFromParam();
    }
})();

