(function() {
	'use strict';

	angular.module('iwh-dataModule').controller('IwhContainerController',
			ContainerController);

	ContainerController.$inject = [ 'IwhContainer', 'NgTableParams', '$uibModal',
			 '$timeout', 'toastr','$state'];

	function ContainerController(Container, NgTableParams, $uibModal, 
			 $timeout, toastr,$state) {
		var vm = this;
		vm.modalInstance = null;
		vm.tableParams = null;
		vm.tenantDetail = null;
		vm.tabs = [];
		vm.active = 0;
		vm.search = search;
		vm.removeTab = removeTab;
		
		vm.confirmOp=confirmOp;
//		-------------------------------------
		vm.status = [ {'id' : null,'title' : ''},
		              {'id' : -1,'title' : '已禁用'},
		              {'id' : 1,'title' : '已启用'} 
		            ];
		vm.ctypes = [{'id':'', 'title':''},
		        {'id':'TBOX', 'title':'周转箱'}, 
		        {'id':'PBOX', 'title':'包装箱'},
		        {'id':'PALLET', 'title':'托盘'}];
		vm.ctypesMap = {'TBOX':'周转箱','PBOX':'包装箱','PALLET':'托盘'};
//		--------------------------------------------------
		vm.create=create;
		vm.showAddContainer=showAddContainer;
		vm.showContainerDetail=showContainerDetail;
		vm.containerEdit=containerEdit;
		vm.delContainer=delContainer;
		vm.containerStatus=containerStatus;
		vm.updataContain=updataContain;
		vm.saveContain=saveContain;
		vm.saveGroup=saveGroup;
		vm.updataGroup=updataGroup;
		vm.groupEdit=groupEdit;
		vm.groupDel=groupDel;
		vm.groupChangeStatus=groupChangeStatus;
		vm.showAddCreat=showAddCreat;
		vm.detail=detail;
// 通用方法
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
//列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Container.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});
		function create(){
			var entity = {
					containView:"contain-view.html",
					containHead:'contain-head.html',
					containEdit:"contain-edit.html",
					type : vm.ctypes,
					heading : '新建',
					contentId : "containergroup-edit.html",
					data : {
						containerGroup:{
							code:null,
							containerList:[]
						},
						tempContainer:null,
						container:{},
						showContain:false,  //是否展示新增页面
						editeContain:false,
						tempContainStatus:{},
					},
					NewContainGroup : true,
					active : true
				};
				
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
		}
//		展示添加
		function showAddContainer(t){
			if(t.data.editeContain){
				t.data.tempContainStatus.edit=false;
			}
				t.data.container={};
				t.data.showContain=!t.data.showContain;
				t.data.editeContain=false;
			}
		
		function showAddCreat(t){
			if(t.data.editeContain){
				t.data.tempContainStatus.edit=false;
				t.data.showContain=!t.data.showContain;
			}
			t.data.container={};
			t.data.showContain=!t.data.showContain;
			t.data.editeContain=false;
		}
		
		
		function showContainerDetail(row,t){
			row.view=!row.view;
		}	
//		编辑容器
		function containerEdit(row,t){
			if(t.data.editeContain){
				t.data.tempContainStatus.edit=false;
			}
			row.edit=true;
			var temp=new Object();  
		     for(var p in row)  
		   {  
		    var name=p;//属性名称   
		    var value=row[p];//属性对应的值   
		    temp[name]=row[p];  
		   } 
			t.data.container=temp;
			t.data.tempContainer=row;
			t.data.tempContainStatus=row;
			
			t.data.showContain=true;
			t.data.editeContain=true;
		}
		
		
//	删除容器
		function delContainer(row,t){
			if(t.NewContainGroup){
				vm.confirmOp('del',function(){
					var idx = t.data.containerGroup.containerList.indexOf(row);
					t.data.containerGroup.containerList.splice(idx, 1);
				});
			}else{
				vm.confirmOp('del',function(){
					Container.delContainer({},row.id,function(resp){
						toastr.success("删除成功", "成功");
						
						vm.conList(t);
					});
				});
			}
		}
		vm.conList=conList;
		function conList(t){
			Container.detailGroup({},t.data.containerGroup.id,function(resp){
				t.data.containerGroup.containerList=resp.content.containerList;
			});
		}
//		改变容器状态
		function containerStatus(row,t){
			var msg=null;
			if(row.status===1){
				msg='disable';
			}else{
				msg='enable'
			}
			vm.confirmOp(msg,function(){
				Container.enableContainer({},{id:row.id,status:row.status},function(resp){
					toastr.success("改变状态成功", "成功");
					vm.conList(t);
				});
				
			});
		}
//		新建保存容器
		function saveContain(t,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.NewContainGroup){
            	var used = false;
            	angular.forEach(t.data.containerGroup.containerList, function(data) {
            			if(data.specCode===t.data.container.specCode){
	            		toastr.warning("编码已经被使用", "警告");
	            		used=true;
	            		return;
            			
            		}
	            });
            	if(used){
	            	return;
	            }
                  t.data.containerGroup.containerList.push(t.data.container);
                  vm.showAddContainer(t);
                      
            }else{
            	Container.checkContainerCode({},{specCode:t.data.container.specCode,groupCode:t.data.containerGroup.code},
                		function(resp){
                			
                		vm.confirmOp('save',function(){
                			t.data.container.groupCode=t.data.containerGroup.code;
                			Container.saveContainer({},t.data.container,function(resp){
                				toastr.success("添加成功", "成功");
                				vm.showAddContainer(t);
                				vm.conList(t);
                			});
                      	    
                      	   }
                         );
                	});
            }
            
		}
//		更新容器
		function updataContain(t,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
          
            if(t.newSku){
            	var dex=t.data.containerGroup.containerList.indexOf(t.data.tempContainer);
            	for (var int = 0; int < t.data.containerGroup.containerList.length; int++) {
            		if(int===dex){
            			continue;
            		}
            		if(t.data.container.specCode===t.data.containerGroup.containerList[int].specCode){
	            		toastr.warning("编码已经被使用", "警告");
	            		return;
            		}
				}
            	vm.confirmOp('updata',function(){
                	 var pack= t.data.container;
                		for(var p in pack)  
                		 {  
                			var name=p;//属性名称   
                			var value=pack[p];//属性对应的值   
                			t.data.tempContainer[name]=pack[p];  
                		 } 
                      	     vm.showAddContainer(t);
            	});
            }else{
            	vm.confirmOp('updata',function(){
            	Container.updataContainer({},t.data.container,function(resp){
            		toastr.success("更新成功", "成功");
            		vm.showAddContainer(t);
    				vm.conList(t);
            	});
            		
           	   }
              );
            }
            
		}
		
//		保存容器组
		function saveGroup(t, idx, form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            Container.checkGroupCode({},t.data.containerGroup.code,function(resp){
            	vm.confirmOp('save',function(){
            		Container.saveGroup({},t.data.containerGroup,function(res){
        			toastr.success("新增成功", "成功");
        			vm.removeTab(idx);
        			});
        		});
            	
            });
		}
//		更新容器组
		function updataGroup(t, idx, form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            vm.confirmOp('updata',function(){
            	Container.updataGroup({},t.data.containerGroup,function(res){
    			toastr.success("更新成功", "成功");
    			vm.removeTab(idx);
    			});
    		});
		}
		
//		编辑
		function groupEdit(row){
			Container.detailGroup({},row.id,function(resp){
				var containerGroup=resp.content.containerGroupBean;
				var list=resp.content.containerList;
				var entity = {
						containView:"contain-view.html",
						containHead:'contain-head.html',
						containEdit:"contain-edit.html",
						type : [{'id':'', 'title':''},
						        {'id':'TBOX', 'title':'周转箱'}, 
						        {'id':'PBOX', 'title':'包装箱'},
						        {'id':'PALLET', 'title':'托盘'}],
						heading : '编辑['+row.code+"]",
						contentId : "containergroup-edit.html",
						data : {
							containerGroup:containerGroup,
							tempContainer:null,
							container:{},
							showContain:false,  //是否展示新增页面
							editeContain:false,
							tempContainStatus:{},
						},
						NewContainGroup : false,
						active : true
					};
				    entity.data.containerGroup.containerList=list;
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			});
			
		}
//		删除
		function groupDel(row){
			vm.confirmOp('del',function(){
				Container.delGroup({},row.id,function(resp){
					toastr.success("删除成功", "成功");
					vm.tableParams.reload();
				});
			});
			
		}
//		改变容器状态
		function groupChangeStatus(row){
			var msg=null;
			if(row.status===1){
				msg='disable';
			}else{
				msg='enable'
			}
			vm.confirmOp(msg,function(){
				Container.enable({},{id:row.id,status:row.status},function(resp){
					toastr.success("改变状态成功", "成功");
					vm.tableParams.reload();
				});
				
			});
		}
		
		function detail(row){
			Container.detailGroup({},row.id,function(resp){
				var containerGroup=resp.content.containerGroupBean;
				var list=resp.content.containerList;
				var entity = {
						containView:"contain-view.html",
						containHead:'contain-head.html',
						containEdit:"contain-edit.html",
						
						type : [{'id':'', 'title':''},
						        {'id':'TBOX', 'title':'周转箱'}, 
						        {'id':'PBOX', 'title':'包装箱'},
						        {'id':'PALLET', 'title':'托盘'}],
						heading : '查看',
						contentId : "containergroup-view.html",
						data : {
							containerGroup:containerGroup,
							tempContainer:null,
							container:{},
							showContain:false,  //是否展示新增页面
							editeContain:false,
							tempContainStatus:{},
						},
						NewContainGroup : false,
						active : true
					};
				    entity.data.containerGroup.containerList=list;
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			});
		}
		
		
		
		
		
		
	
		
		
		// 确认操作封装
		function confirmOp(p,func){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-opcontainer.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						op:p
					}
				}
			});
			vm.modalInstance.result.then(function(item) {
				return func();
			});
		}
		confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;
			function ok() {
				$uibModalInstance.close(true);
			}
			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}

	}
})();
