(function() {
    "use strict";
    angular.module("itms-orderModule").factory("TmsTransportOrder", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsOrderHost + "/web/transportOrder", {}, {
        	'list': { url: iServiceConfig.itmsOrderHost + "/web/transportOrder/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/saveAndUpdate", method: 'POST'},
            'create': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/create", method: 'POST'},
            'edite': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/edite", method: 'POST'},
            'show': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/show", method: 'POST'},
            'assign': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/assign", method: 'POST'},
            'getDetail': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/getDetail", method: 'POST'},
            'sign': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/sign", method: 'POST'},
            'batchSign': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/batchSign", method: 'POST'},
            'examine': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/examine", method: 'POST'},
            'distributLine': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/distributLine", method: 'POST'},
            'withdraw': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/withdraw", method: 'POST'},
            'codes': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/withdraw", method: 'POST'},
            'getMeetLine': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/getMeetLine", method: 'POST'},
            'getLineDetail': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/getLineDetail", method: 'POST'},
            'getBestLine': {url: iServiceConfig.itmsOrderHost + "/web/transportOrder/getBestLine", method: 'POST'}
        });
    }]);
})();