(function () {
    'use strict';

    angular.module('iwh-innerModule').controller('CountDifferenceController',CountDifferenceController);

    CountDifferenceController.$inject = ['CountDifference', 'NgTableParams', '$timeout', 'toastr','enums','Principal'];

    function CountDifferenceController(CountDifference, NgTableParams, $timeout, toastr,enums,Principal){
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.params = {};


        vm.search = search;
        vm.removeTab = removeTab;
        vm.formatDateTime = formatDateTime;

        vm.detail = detail;
        vm.cancel = cancel;
        vm.handleBySku = handleBySku;
        vm.handleByLot = handleByLot;
        vm.handleByGenAdjust = handleByGenAdjust;
        vm.handleBySkuOne = handleBySkuOne;
        vm.handleByLotOne = handleByLotOne;
        
        vm.handleByGenAdjustOne = handleByGenAdjustOne;

        vm.reportCallback = reportCallback;
        // vm.printdiv = printdiv;
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.typeObj = enums('inner.DifferenceDealType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.CountDiffStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.yesOrNoObj = enums('inner.YesOrNo');
        vm.yesOrNo = vm.yesOrNoObj.list('title', 'id');
        vm.handleBySkuBefore = handleBySkuBefore;
       // 列表页功能
        vm.tableParams = new NgTableParams({count : 10}, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return CountDifference.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });
        vm.buildParam = function buildParam() {
            return {

            };
        }
        // 通用方法
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(tabs,idx) {
            tabs.splice(idx, 1);
        }
        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        // 查看明细
        function detail(row){
            var entity = {
                heading : '查看明细【'+row.diffNum+'】',
                contentId : "detail.html",
                tableParams:new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.diffNum = row.diffNum;
                        return CountDifference.findCountDifferenceDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                data: row,
                active : true,
                activeDetail :0,
                tabs:[]
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        // 取消
        function cancel(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else{
                var list = vm.tableParams.getSelected();
                for(var i=0;i<list.length;i++){
                    if(list[i].id){
                        temp.push(list[i].id);
                    }
                }
            }
            CountDifference.cancel({},temp,function(resp){
            }).$promise.then(function(data){
                toastr.success("成功取消", "成功");
                vm.tableParams.reload();
            });
        }

        function handleBySkuBefore() {
            console.log(1111111);
            var list = vm.tableParams.getSelected();
            if(list.length==0){
                toastr.error("","请先选择单号！");
                return false;
            }else{
                return true;
            }
        }
        // 处理
        function handleBySku() {
            var ids=[];
            var list = vm.tableParams.getSelected();
            if(list.length==0){
                toastr.error("","请先选择单号！");
                return;
            }
            for(var i=0;i<list.length;i++){
                if(list[i].id){
                    ids.push(list[i].id);
                }
            }
            CountDifference.handleBySku({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("处理成功", "成功");
                vm.tableParams.reload();
            });
        }

        function handleByLot() {
            var ids=[];
            var list = vm.tableParams.getSelected();
            if(list.length==0){
                toastr.error("","请先选择单号！");
                return;
            }
            for(var i=0;i<list.length;i++){
                if(list[i].id){
                    ids.push(list[i].id);
                }
            }
            CountDifference.handleByLot({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("处理成功", "成功");
                vm.tableParams.reload();
            });
        }

        function handleByGenAdjust() {
            var ids=[];
            var list = vm.tableParams.getSelected();
            if(list.length==0){
                toastr.error("","请先选择单号！");
                return;
            }
            for(var i=0;i<list.length;i++){
                if(list[i].id){
                    ids.push(list[i].id);
                }
            }
            CountDifference.handleByGenAdjust({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("处理成功", "成功");
                vm.tableParams.reload();
            });
        }
        
        function handleBySkuOne(t) {
            var ids=[];
           ids.push(t.data.id);
            CountDifference.handleBySku({},ids,function(resp){
            }).$promise.then(function(data){
                t.tableParams.reload();
                t.data.status=20;
                toastr.success("处理成功", "成功");
                vm.tableParams.reload();
            });
        }

        function handleByLotOne(t) {
            var ids=[];
            ids.push(t.data.id);
            CountDifference.handleByLot({},ids,function(resp){
            }).$promise.then(function(data){
                t.tableParams.reload();
                t.data.status=20;
                toastr.success("处理成功", "成功");
                vm.tableParams.reload();
            });
        }
        function handleByGenAdjustOne(t) {
            var ids=[];
            ids.push(t.data.id);
            CountDifference.handleByGenAdjust({},ids,function(resp){
            }).$promise.then(function(data){
                t.tableParams.reload();
                t.data.status=20;
                toastr.success("处理成功", "成功");
                vm.tableParams.reload();
            });
        }
        
        function reportCallback($reportCode, data){
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (data) {
            	var param = {};
                param = {
                    tenantId: data.tenantId,
                    warehouseCode: data.warehouseCode,
                    diffNum: data.diffNum,
                }
                return param;
            } else {
            	var param = [];
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            diffNum : list[i].diffNum
                        });
                    }
            	}
                return param;
            }
        }
    }

})();