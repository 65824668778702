(function()  {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptGenSupplySettlementController', iEptGenSupplySettlementController);

    iEptGenSupplySettlementController.$inject = ['$rootScope', 'enums', 'SupplySettlement', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function iEptGenSupplySettlementController ($rootScope, enums,  SupplySettlement, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.genParam = {};
        vm.processing = false;
        function pageTitle() {
            return $window.document.title;
        }
        
        vm.preGen = preGen;
		function preGen() {
			SupplySettlement.preGen({},vm.genParam,function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	vm.supplySettleDataList = resp.content;
                	console.log(vm.supplySettleDataList);
                }
            });
		}
		
		vm.genSettle = function(ssd) {
			vm.processing = true;
			var sss = "";
			if (!ssd.ownerName || ssd.ownerName.trim() == '') {
				sss = sss + "请输入货主名称, "
			}
			if (!ssd.supplierCode || ssd.supplierCode.trim() == '') {
				sss = sss + "请输入供应商编码, "
			}
			if (!ssd.supplierName || ssd.supplierName.trim() == '') {
				sss = sss + "请输入供应商名称, "
			}
			if (!ssd.settleMethod || ssd.settleMethod.trim() == '') {
				sss = sss + "请输入结算方式, "
			}
			
			if (sss != "") {
				toastr.error("结算单："+ ssd.settlementNum+" 错误："+ sss);
				vm.processing = false;
        		return;
			}
			SupplySettlement.gen({},[ssd],function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	var msg = "生成结算单成功，";
                	if (resp.content && resp.content.length > 0) {
                		msg = msg +"单号：";
                		angular.forEach(resp.content, function(item){
                			msg = msg +item.settlementNum+", ";
                		});
                	}
                	toastr.success(msg);
                	var list = [];
                	angular.forEach(vm.supplySettleDataList, function(item){
                		if (item.settlementNum != ssd.settlementNum) {
                			list.push(item);
                		}
                	});
                	vm.supplySettleDataList = list;
                }
                vm.processing = false;
            }, function(err) {
            	console.log("err", err);
            	toastr.error(err.data.errorMsg);
            	vm.processing = false;
            });
		}

        vm.gen = gen;
        function gen() {
        	vm.processing = true;
        	var list = [];
        	var msg = "";
        	angular.forEach(vm.supplySettleDataList, function(item){
    			if (!item.id || item.id == 0) {
    				var sss = "";
    				if (!item.ownerName || item.ownerName.trim() == '') {
    					sss = sss + "请输入货主名称, "
    				}
    				if (!item.supplierCode || item.supplierCode.trim() == '') {
    					sss = sss + "请输入供应商编码, "
    				}
    				if (!item.supplierName || item.supplierName.trim() == '') {
    					sss = sss + "请输入供应商名称, "
    				}
    				if (!item.settleMethod || item.settleMethod.trim() == '') {
    					sss = sss + "请输入结算方式, "
    				}
    				
    				if (sss != "") {
    					msg = msg + "结算单："+ item.settlementNum+" 错误："+ sss+ "<br>";
    				}
    				list.push(item);
    			}
    		});
        	if (msg != "") {
        		toastr.error(msg);
        		vm.processing = false;
        		return;
			}
        	if (list.length == 0) {
        		toastr.error("当前无可提交生成结算单的数据！");
        		vm.processing = false;
        		return;
        	}
        	SupplySettlement.gen({},list,function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	var msg = "生成结算单成功，";
                	if (resp.content && resp.content.length > 0) {
                		msg = msg +"单号：";
                		angular.forEach(resp.content, function(item){
                			msg = msg +item.settlementNum+", ";
                		});
                	}
                	toastr.success(msg);
                	vm.supplySettleDataList = [];
                }
                vm.processing = false;
            },function(err) {
            	console.log("err", err);
            	toastr.error(err.data.errorMsg);
            	vm.processing = false;
            });
        }
        if ($state.params.recordNums && $state.params.recordNums.length > 0) {
        	vm.genParam.recordNums = $state.params.recordNums;
        	vm.preGen();
        }
    }
})();
