(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('MovePlanTask', MovePlanTask);

    MovePlanTask.$inject = ['$resource', 'iServiceConfig'];

    function MovePlanTask ($resource, iServiceConfig) {
        var service = $resource('web/movePlanTask', {}, {
            'scanMovePlanNum': {url: iServiceConfig.iwhInnerHost + "/web/movePlanTask/scanMovePlanNum", method: 'POST'},
            'move': {url: iServiceConfig.iwhInnerHost + "/web/movePlanTask/move", method: 'POST'},
            'moveAll': {url: iServiceConfig.iwhInnerHost + "/web/movePlanTask/moveAll", method: 'POST'},
            'randomMove': {url: iServiceConfig.iwhInnerHost + "/web/movePlanTask/randomMove", method: 'POST'}
        });

        return service;
    }
})();
