(function () {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusFileImportTemplate', iBusFileImportTemplate);

    iBusFileImportTemplate.$inject = ['$resource', 'iServiceConfig'];

    function iBusFileImportTemplate($resource, iServiceConfig) {
        var service = $resource('web/importTemplate', {}, {
            'search': {url: iServiceConfig.ibusHost + "/web/importTemplate/list", method: 'POST'},
            'preSave': {url: iServiceConfig.ibusHost + "/web/importTemplate/preSave", method: 'POST'},
            'save': {
                url: iServiceConfig.ibusHost + "/web/importTemplate/save", method: 'POST',
                transformRequest: function (data) {
                    if (data === undefined)
                        return data;

                    var fd = new FormData();
                    angular.forEach(data, function (value, key) {
                        // console.log("key:" + key + ";value:" + value);
                        if (value instanceof FileList) {
                            if (value.length == 1) {
                                fd.append(key, value[0]);
                            } else {
                                angular.forEach(value, function (file, index) {
                                    fd.append(key + '_' + index, file);
                                });
                            }
                        } else {
                            fd.append(key, value);
                        }
                    });

                    return fd;
                },
                headers: {'Content-Type': undefined}
            },
            'updateStatus': {url: iServiceConfig.ibusHost + "/web/importTemplate/updateStatus/:id/:status/:code", method: 'POST'},
            'preUpdate': {url: iServiceConfig.ibusHost + "/web/importTemplate/preUpdate/:id", method: 'POST'},
            'update': {
                url: iServiceConfig.ibusHost + "/web/importTemplate/update/:id", method: 'POST',
                transformRequest: function (data) {
                    if (data === undefined)
                        return data;

                    var fd = new FormData();
                    angular.forEach(data, function (value, key) {
                        // console.log("key:" + key + ";value:" + value);
                        if (value instanceof FileList) {
                            if (value.length == 1) {
                                fd.append(key, value[0]);
                            } else {
                                angular.forEach(value, function (file, index) {
                                    fd.append(key + '_' + index, file);
                                });
                            }
                        } else {
                            fd.append(key, value);
                        }
                    });

                    return fd;
                },
                headers: {'Content-Type': undefined}
            },
            'showLogs': {url: iServiceConfig.ibusHost + "/web/importTemplate/showLogs/:code", method: 'POST'},
            'downloadTemplate': {
                url: iServiceConfig.ibusHost + "/web/importTemplate/download/:id",
                method: 'POST',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            }
        });

        return service;
    }
})();
