(function () {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('iEptRequisitionDetail', iEptRequisitionDetail);

    iEptRequisitionDetail.$inject = ['$resource', 'iServiceConfig'];

    function iEptRequisitionDetail($resource, iServiceConfig) {
        var service = $resource('web/requisitionDetail', {}, {
            'search': {url: iServiceConfig.ienterpriseHost + "/web/requisitionDetail/list", method: 'POST'},
            'preSave': {url: iServiceConfig.ienterpriseHost + "/web/requisitionDetail/preSave", method: 'GET'},
            'save': {url: iServiceConfig.ienterpriseHost + "/web/requisitionDetail/save", method: 'POST'},
            'getLotRule': {url: iServiceConfig.ienterpriseHost + "/web/requisitionDetail/getLotRule", method: 'GET'},
            'preUpdate': {url: iServiceConfig.ienterpriseHost + "/web/requisitionDetail/preUpdate", method: 'GET'},
            'deleteBatch': {url: iServiceConfig.ienterpriseHost + "/web/requisitionDetail/deleteBatch", method: 'POST'}
        });

        return service;
    }
})();
