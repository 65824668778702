(function()  {
    'use strict';

    angular.module('itms-orderModule')
        .controller('TmsTransportDispatchController', TmsTransportDispatchController);

    TmsTransportDispatchController.$inject = ['$rootScope','$stateParams', 'TmsTransportDispatch', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout', 'iServiceConfig'];

    function TmsTransportDispatchController ($rootScope,$stateParams, TransportDispatch, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.iServiceConfig = iServiceConfig;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.orderData = "orderData.html";
        vm.orderProducts = "orderProducts.html";
        vm.roadParam = {};
        vm.roadDetailParam = {};
        vm.vehicleTableParam = {};
        vm.vehicleList = [];
        vm.list = [];
        vm.roadDetail = [];
        vm.search = {
            lineCode : ""
        }
        vm.findVO = {
            transportOrderMap : {},
        }

        vm.productType = {
            code : null,
            name : null
        };

        vm.orderSource = [
                     {id : 1, title : "手工创建"},
                     {id : 2, title : "上游ERP"},
                     {id : 3, title : "上游WMS"},
                     {id : 4, title : "上游OMS"},
                 ];

        vm.transportMode = [
            {id : 10, title : "陆运"},
            {id : 20, title : "空运"},
            {id : 30, title : "水运"},
        ];

        vm.transportType = [
            {id : 10, title : "快递"},
            {id : 20, title : "物流"},
        ];
        vm.priority = [
            {id : 1, title : "1"},
            {id : 2, title : "2"},
            {id : 3, title : "3"},
            {id : 4, title : "4"},
            {id : 5, title : "5"},
            {id : 6, title : "6"},
            {id : 7, title : "7"},
            {id : 8, title : "8"},
            {id : 9, title : "9"},
            {id : 10, title : "10"},
        ];
        vm.type = [
            {id : 10, title : "中转"},
            {id : 20, title : "直运"},
        ];
        vm.payMethod = [
            {id : 10, title : "预付"},
            {id : 20, title : "到付"},
        ];

        vm.payStatus = [
            {id : 0, title : "未付款"},
            {id : 10, title : "部分支付"},
            {id : 20, title : "全部支付"},
        ];
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "已提交"},
            {id : 15, title : "审核拒绝"},
            {id : 20, title : "已审核"},
            {id : 30, title : "已转运单"},
            {id : 35, title : "已装货"},
            {id : 40, title : "运输中"},
            {id : 45, title : "已卸货"},
            {id : 50, title : "已签收"},
            {id : 60, title : "已回单"},
            {id : -10, title : "已取消"},
        ];
        vm.roadParam = new NgTableParams({}, {counts: [],
            getData: function(params) {
				return TransportDispatch.listNewRoads({}, {}, function(resp) {
					
				}).$promise.then(function(data) {
					var datas = data.content;
					return datas;
				});
            }});
        vm.roadDetailParam = new NgTableParams({}, {counts: [],getData:function(){return vm.roadDetail}});
        
        function pageTitle() {
            return $window.document.title;
        }

		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.searchTransportOrder = searchTransportOrder;
		function searchTransportOrder() {
            if (!vm.search.lineCode) {
                toastr.error("请选择需要调度的运输线路");
                return;
            }
			vm.roadDetail = [];
            TransportDispatch.listForLine({}, vm.search, function(resp) {
                vm.findVO = resp.content;
                var map = vm.findVO.transportOrderMap;
                vm.roadParam.reload();
                vm.roadDetailParam.reload();
                vm.list = [];
                for(var key in map){
                    var data = {
                        lineCode : key,
                        transportNum : map[key].length,
                        carrierCode: map[key].length > 0 ? map[key][0].carrierCode : "",
                        carrierName: map[key].length > 0 ? map[key][0].carrierName : "",
                        list : map[key]
                    }
                    console.log("d", data);
                    data.tableParam = new NgTableParams({}, {counts: [],getData:function(){return data.list}});
                    
                    vm.list.push(data);
                }
            });
        }
		vm.update = update;
		function update(t, index,form){
			var valid = form.$valid;
			 var saveVO = {
                 TransportDispatch :{},
                 orderDetailList :[],
             };
            var mydate = new Date();
            t.TransportDispatch.orderTime = mydate.toLocaleDateString();
            saveVO.TransportDispatch = t.TransportDispatch;
            saveVO.orderDetailList = t.orderDetailList;
			 if(t.TransportDispatch.id){
				 TransportDispatch.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 TransportDispatch.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.showRoadDetail = showRoadDetail;
		function showRoadDetail(row) {
            TransportDispatch.showDetail({}, row.roadNum, function(resp) {
				vm.roadDetail = resp.content;
            });
		}
		
		vm.edite = edite;
		function edite(row) {
            TransportDispatch.edite({}, row.id, function(resp) {
				var TransportDispatch = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "orderMaintenance.html",
						active : true,
                        TransportDispatch : TransportDispatch.TransportDispatch,
                        orderDetailList: TransportDispatch.orderDetailList,
                        orderDetail :{
                            productType : null,
                            productTypeName : null,
                            productName : null
                    },
                    tableParams: new NgTableParams({}, {counts: [],getData:function(){return entity.orderDetailList}})
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}




        vm.cloneObj = cloneObj;
        function cloneObj(obj) {
            var newObj = {};
            if (obj instanceof Array) {
                newObj = [];
            }
            for (var key in obj) {
                var val = obj[key];
                newObj[key] = typeof val === 'object' ? cloneObj(val): val;
            }
            return newObj;
        };

        vm.submit = submit ;
        function submit(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'createConfirm.html',
                controller: submitModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : row,
                    }
                }
            });
        }
        submitModal.$inject = ['$uibModalInstance', 'item'];
        function submitModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.name = null;
            $ctrl.cancel = cancel;
            function cancel() {
            	vm.roadDetail = [];
                TransportDispatch.cancel({}, $ctrl.item.id, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("取消成功！");
                        $uibModalInstance.close($ctrl.item);
                        vm.searchTransportOrder(vm.search);
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.cancelRoad = cancelRoad ;
        function cancelRoad(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'cancelRoadConfirm.html',
                controller: cancelRoadModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : row,
                    }
                }
            });
        }
        cancelRoadModal.$inject = ['$uibModalInstance', 'item'];
        function cancelRoadModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item.t;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.name = null;
            $ctrl.cancelRoad = cancelRoad;
            function cancelRoad() {
                var vo ={
                    id : $ctrl.item.id,
                    roadNum : $ctrl.item.roadNum
                }
                vm.roadDetail = [];
                TransportDispatch.cancel({}, vo, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("取消成功！");
                        $uibModalInstance.close($ctrl.item);
                        vm.searchTransportOrder(vm.search);
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.cancelTransport = cancelTransport ;
        function cancelTransport(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'cancelTransportConfirm.html',
                controller: cancelTransportModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : row,
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
                TransportDispatch.cancelTransport({}, item.id, function(resp) {
                        if(resp.content=="success"){
                            toastr.success("取消成功！");
                            vm.searchTransportOrder(vm.search);
                            TransportDispatch.showDetail({}, row.roadNum, function(resp) {
                                vm.roadDetail =resp.content;
                                vm.roadDetailParam.reload();

                            });
                        }
                    });
                });
        }
        cancelTransportModal.$inject = ['$uibModalInstance', 'item'];
        function cancelTransportModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item.t;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.name = null;
            $ctrl.cancelTransport = cancelTransport;
            function cancelTransport() {
                TransportDispatch.cancelTransport({},  $ctrl.item.id, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("取消成功！");
                        $uibModalInstance.close($ctrl.item);
                        vm.searchTransportOrder(vm.search);
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

		vm.distributeVehicle = distributeVehicle ;
		function distributeVehicle(row) {
			vm.vehicleTableParam = new NgTableParams({}, {counts: [],getData:function(params){
            	var postParams = angular.merge({
					carrierCode: row.carrierCode
				}, params.filter());
				return TransportDispatch.getVehicle({}, postParams, function(resp) {
					//params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content;


					return datas;
				}); 
            }});
			
			vm.modalInstance = $uibModal.open({
                templateUrl: 'distributeVehicle.html',
                controller: distributeVehicleModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        row : row
                    }
                }
            });
			
        }
        distributeVehicleModal.$inject = ['$uibModalInstance', 'item'];
        function distributeVehicleModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.list = vm.vehicleList ;
            $ctrl.tableParam = vm.vehicleTableParam;
            $ctrl.distributeVehicle = distributeVehicle;

            function distributeVehicle() {
                var vehicles = $ctrl.tableParam.getSelected();
                if(vehicles.length!=1){
                    toastr.error("请选择一辆车");
                    return;
                }
                var vehicle = vehicles[0];
                var vo ={
                    id : $ctrl.item.row.id,
                    vehicle : vehicle
                }
                TransportDispatch.distributVehicle({}, vo, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("分配成功！");
                        $uibModalInstance.close($ctrl.item);
                        vm.searchTransportOrder(vm.search);
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.confirmSave = confirmSave;
        function confirmSave(list) {
            var flag = 0;
            var select = [];
            for(var i=0;i<list.length;i++){

                if(list[i].tableParam.getSelected().length>0){
                    flag = parseInt(flag)+1;
                    select = list[i].tableParam.getSelected();
                }
            }
            if(flag==0){
                toastr.error("请先选择运单");
                return;
            }
            if(flag>1){
                toastr.error("只有相同线路的运单才能生成同一运输计划");
                return;
            }

            var numString = "";
            for(var i=0;i<select.length;i++){
                numString =numString + select[i].transportNum+",";
            }
            numString = numString.substring(0,numString.length-1);

            vm.modalInstance = $uibModal.open({
                templateUrl: 'createConfirm.html',
                controller: createModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : select,
                        numString : numString
                    }
                }
            });
        }
        createModal.$inject = ['$uibModalInstance', 'item'];
        function createModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item.t;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.save = save;
            $ctrl.num = item.length;
            $ctrl.numString = item.numString;

            function save() {
                var vo = {
                    transportOrderList : $ctrl.item

                }
                vm.roadDetail = [];
                TransportDispatch.create({}, vo, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("创建成功！");
                        $uibModalInstance.close($ctrl.item);
                        vm.searchTransportOrder(vm.search);
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.confirmAddToTransport = confirmAddToTransport;
        function confirmAddToTransport(list) {
            var flag = 0;
            var select = [];
            for(var i=0;i<list.length;i++){

                if(list[i].tableParam.getSelected().length>0){
                    flag = parseInt(flag)+1;
                    select = list[i].tableParam.getSelected();
                }
            }
            if(flag==0){
                toastr.error("请先选择运单");
                return;
            }
            if(flag>1){
                toastr.error("只有相同线路的运单");
                return;
            }
            if(vm.roadParam.getSelected().length==0||vm.roadParam.getSelected().length>1){
                toastr.error("请先选择一个运输计划");
                return;
            }
            var road = vm.roadParam.getSelected();
            if(select[0].lineCode!=road[0].lineCode){
                toastr.error("只能添加到相同线路的运输计划");
                return;
            }
            var transportIds = [];
            for(var i=0;i<select.length;i++){
                transportIds.push(select[i].id);
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addToTransportConfirm.html',
                controller: addToTransportModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        transportIds : transportIds,
                        roadNum : road[0].roadNum
                    }
                }
            });
        }
        addToTransportModal.$inject = ['$uibModalInstance', 'item'];
        function addToTransportModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.addToTransport = addToTransport;
            function addToTransport() {
            	vm.roadDetail = [];
                TransportDispatch.addTransportToRoad({}, $ctrl.item, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("添加成功！");
                        $uibModalInstance.close($ctrl.item);
                        vm.searchTransportOrder(vm.search);
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.confirmSubmit = confirmSubmit;
        function confirmSubmit(t) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'submitConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : t,
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
                console.log(item);
                if(!item.t.vehicleCode){
                    toastr.error("请先安排好车辆！");
                    return;
                }
                if (!item.planStartTime) {
                    toastr.error("请先现在计划运输时间！");
                    return;
                }
                vm.roadDetail = [];
                TransportDispatch.submit({}, {id: item.t.id, planStartTime: item.planStartTime}, function(resp) {
                    if(resp.content=="success"){
                        toastr.success("提交成功！");
                        vm.searchTransportOrder(vm.search);
                    }
                });
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
