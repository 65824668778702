(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('DeliveryOrderBySoNumController', DeliveryOrderController);

    DeliveryOrderController.$inject = ['$rootScope', 'DeliveryOrder', 'ShipOrder','$timeout', 'NgTableParams','enums','toastr','scModal','$filter'];

    function DeliveryOrderController ($rootScope, DeliveryOrder, ShipOrder, $timeout, NgTableParams,enums,toastr,scModal,$filter) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];

        vm.params = {
            soNum: '',
            packMaterial: '',
            waybillNum: '',
            carrierName:''
        };
        vm.checkStatus = {
            status: 'SUCCESS',
            msg: '扫描成功'
        };
        vm.shipOrder = {};
        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'}
        };

        vm.getShipOrder = getShipOrder;
        vm.inputPackMaterial = inputPackMaterial;
        vm.inputWayBillNum = inputWayBillNum;
        vm.getCurrentShipOrder = getCurrentShipOrder;
        vm.deliverySO  = deliverySO ;
        vm.deliverySOPick = deliverySOPick;
        vm.inputCarrierName=inputCarrierName;

        function getShipOrder($event, soNum) {
            if ($event.key === 'Enter' && soNum) {
                vm.checkStatus = {
                    status: 'SUCCESS',
                    msg: '扫描成功'
                };
                vm.shipOrder = {};
                success();
                ShipOrder.soWithPickDetail({soNum: soNum}, {}, function(data) {
                    if (data.content && data.content.pickDetails && data.content.pickDetails.length > 0) {
                        data.content.pickDetails = _sortPickDetails(data.content.pickDetails);
                        angular.forEach(data.content.pickDetails, function(detail, idx) {
                            if (detail.shipQty > 0) {
                                detail.style = vm.alertColor.done;
                            }
                        });
                        vm.shipOrder = data.content;

                        $timeout(function () {
                        	jQuery('#carrierName').focus();
                        	jQuery('#carrierName').select();
                        });
                    } else {
                        vm.checkStatus = {
                            status: 'ERROR',
                            msg: '出库单或拣货明细不存在'
                        };
                    }
                }, function(data) {
                	fail();
                });
            }
        }

        function inputPackMaterial($event) {
//            if ($event.key === 'Enter') {
//                jQuery('#wayBillNum').select();
//            }
        }

        function inputWayBillNum($event) {
            if ($event.key === 'Enter' && vm.shipOrder.pickDetails && vm.shipOrder.pickDetails.length > 0) {
            	jQuery('#packMaterial').focus();
            	jQuery('#packMaterial').select();
            }
        }
        function inputCarrierName($event) {
            if ($event.key === 'Enter' && vm.shipOrder.pickDetails && vm.shipOrder.pickDetails.length > 0) {
            	jQuery('#wayBillNum').focus();
            	jQuery('#wayBillNum').select();
            }
        }
        function getCurrentShipOrder() {
            return vm.shipOrder;
        }
        function deliverySOPick(shipOrder) {
            if (shipOrder && shipOrder.pickDetails.length > 0) {
            	success();
            	vm.params.onlyShipPickSku = true;
                DeliveryOrder.deliveryBySoNum({}, vm.params, function() {
                    $timeout(function() {
                        toastr.success('发货成功');

                        reset();
                        jQuery('#code').select();
                    });
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                    	toastr.error(resp.data.errorMsg);
                        fail();
                    }
                });
            }
        }
        function deliverySO(shipOrder) {
            if (shipOrder && shipOrder.pickDetails.length > 0) {
            	success();
            	vm.params.onlyShipPickSku = false;
                DeliveryOrder.deliveryBySoNum({}, vm.params, function() {
                    $timeout(function() {
                        toastr.success('发货成功');

                        reset();
                        jQuery('#code').select();
                    });
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                    	toastr.error(resp.data.errorMsg);
                        fail();
                    }
                });
            }
        }

        function _sortPickDetails(details) {
            return $filter('orderBy')(
                details,
                function (data){
                    console.log(data);
                    return {
                        'boxNum': data.boxNum,
                        'skuCode': data.skuCode,
                        'loc': data.loc,
                        'pickQty': data.pickQty,
                        'packQty': data.packQty,
                        'shipQty': data.shipQty
                    };
                },
                false,
                function (obj1, obj2) {
                    var d1 = obj1.value;
                    var d2 = obj2.value;

                    if (!(d1.shipQty > 0 && d2.shipQty > 0)) {
                        if (d1.shipQty > 0) return 1;
                        if (d2.shipQty > 0) return -1;
                    }
                    if (!(d1.packQty > 0 && d2.packQty > 0)) {
                        if (d1.packQty > 0) return -1;
                        if (d2.packQty > 0) return 1;
                    }
                    if (!(d1.pickQty > 0 && d2.pickQty > 0)) {
                        if (d1.pickQty > 0) return -1;
                        if (d2.pickQty > 0) return 1;
                    }

                    if (d1.boxNum !== d2.boxNum) {
                        return d1.boxNum > d2.boxNum ? 1: -1;
                    }
                    if (d1.skuCode !== d2.skuCode) {
                        return d1.skuCode > d2.skuCode ? 1: -1;
                    }
                    if (d1.loc !== d2.loc) {
                        return d1.loc > d2.loc ? 1: -1;
                    }

                    if (d1.index < d2.index) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            );
        }

        function reset() {
            vm.params = {
                soNum: '',
                packMaterial: '',
                waybillNum: '',
                carrierName:''
            };
            vm.checkStatus = {
                status: 'SUCCESS',
                msg: '扫描成功'
            };
            vm.shipOrder = {};
        }
        
        function fail() {
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }
    }
})();
