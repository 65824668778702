(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('SOBlockController', SOBlockController);

    SOBlockController.$inject = ['$timeout', 'NgTableParams', 'enums', 'ShipOrder', 'toastr', 'iServiceConfig', 'ApiURI', 'BaseCode'];

    function SOBlockController ($timeout, NgTableParams, enums, ShipOrder, toastr, iServiceConfig, ApiURI, BaseCode) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];

        vm.comeFroms = [{name:'WMS', code: 'WMS'}];
        vm.lackShip = enums('ship.LackShip');
        vm.deliveryOptions = vm.lackShip.list('name', 'id');
        vm.orderSource = enums('ship.ComeFrom');
        vm.soStatus = enums('ship.SOStatus');
        vm.soDetailStatus = enums('ship.SODetailStatus');
        vm.pickDetailStatus = enums('ship.PickDetailStatus');
        vm.blockStatus = enums('ship.BlockStatus');
        vm.stockoutStatus = enums('ship.StockOutStatus');
        vm.soOpStatus = enums('ship.SOOpStatus');
        vm.processStatus = enums('ship.ProcessStatus');
        vm.soTypes = {};
        vm.transportMethods = {};
        vm.payMethods = {};
        vm.containerGroupHost = iServiceConfig.iwhDataHost + ApiURI.getContainerGroupList;

        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count(), blockStatus: vm.blockStatus.value('CONFIRM_BLOCK')}, params.filter());
                return ShipOrder.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        vm.searchSo = searchSo;
        vm.handleBlock = handleBlock;
        vm.removeTab = removeTab;

        vm.baseCode = BaseCode.codes([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TRANSPORT_METHOD', keyField: 'WMS_SO_TRANSPORT_METHOD'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_PAY_METHOD', keyField: 'WMS_SO_PAY_METHOD'}
        ]);

        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });
        vm.baseCode('WMS_SO_TRANSPORT_METHOD').then(function(data) {
            vm.transportMethods = data;
        });
        vm.baseCode('WMS_SO_PAY_METHOD').then(function(data) {
            vm.payMethods = data;
        });

        function searchSo() {
            vm.tableParams.reload();
        }

        function handleBlock(so) {
            if (so && so.soNum) {
                var sos = [so];
                handleBlocks(sos);
            }
        }

        function handleBlocks(sos) {
            if (!sos || sos.length === 0) {
                toastr.error('请选择出库单');
                return;
            }

            var soNums = [];
            angular.forEach(sos, function(item) {
               soNums.push(item.soNum);
            });
            ShipOrder.handleBlocks({}, soNums, function(resp) {
                var result = resp.content;
                var msg = '处理成功个数: ' + result.successItems.length + '，失败个数：' + result.failItems.length + '<br>';
                for (var i=0; i<result.failItems.length; i++) {
                    msg += result.failItems[i].reason;
                    if (i < (result.failItems.length - 1)) {
                        msg += '<br>';
                    }
                }

                if (result.failItems.length === 0) {
                    toastr.success(msg);
                } else if (result.successItems.length > 0) {
                    toastr.warning(msg);
                } else {
                    toastr.error(msg);
                }

                $timeout(function() {
                    vm.searchSo();
                });
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
                vm.curTab = vm.tabs[vm.active - 1];
            }, 0);
        }

        vm.toggle = toggle;
        function toggle(row,o){
            row[o] = !row[o];
        }
    }
})();
