(function () {
    'use strict';

    angular.module('doveModule')
        .controller('iBusScheduleController', iBusScheduleController);

    iBusScheduleController.$inject = ['iBusScheduleTaskParam','iBusSchedule', 'NgTableParams', '$uibModal', 'iBusTaskRunning', 'iBusProcess', 'iBusProject', '$q', '$timeout','toastr','$filter'];

    function iBusScheduleController(iBusScheduleTaskParam, iBusSchedule, NgTableParams, $uibModal, iBusTaskRunning, iBusProcess, iBusProject, $q, $timeout,toastr,$filter) {
        //debugger;
        var vm = this;
        vm.tabs = [];
        vm.projects = [];
        vm.projectMap = {};
        vm.status = [{'id': null, 'title': ''}, {'id': 0, 'title': '新建'}, {'id': 1, 'title': '启用'}, {
            'id': -1,
            'title': '禁用'
        }];
        vm.modalInstance = null;
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.update = update;
        vm.deleteSchedule = deleteSchedule;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.changeState = changeState;
        vm.stopAll = stopAll;
        vm.startAll = startAll;
        vm.searchProcess = searchProcess;
        vm.selectProcess = selectProcess;
        vm.searchProjects = searchProjects;
        vm.scheduleSelect = scheduleSelect;
        vm.stopSelect = stopSelect;
        vm.active = 0;

        vm.tableParams = new NgTableParams({count: 25}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusSchedule.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        function scheduleSelect() {
            var selected = vm.tableParams.getSelected();
            var codes = jQuery.map(selected, function (item) {
                return item.code;
            });

            iBusTaskRunning.scheduleSelect({}, codes, function (resp) {
                vm.search();
                alert("共 " + resp.content.total + " 条任务，成功启动 " + resp.content.start + " 条任务");
            });
        }

        function stopSelect() {
            var selected = vm.tableParams.getSelected();
            var codes = jQuery.map(selected, function (item) {
                return item.code;
            });

            iBusTaskRunning.stopSelect({}, codes, function (resp) {
                vm.search();
                alert("共 " + resp.content.total + " 条任务，成功停止 " + resp.content.stop + " 条任务！\n正在运行中的任务是不能被停止；如有未停止完成的任务，请重试");
            });
        }

        function searchProjects() {
            var deferred = $q.defer();

            var postParams = {pn: 1, ps: 999};
            iBusProject.search({}, postParams, function (resp) {
                var projects = jQuery.map(resp.content.datas, function (project) {
                    return {'id': project.code, 'title': project.name};
                });

                angular.forEach(projects, function (project) {
                    vm.projectMap[project.id] = project.title;
                });

                vm.projects = projects;

                deferred.resolve(vm.projects);
            });

            return deferred;
        }

        function selectProcess($item, $model, $label, $event, t) {
            if (!!$item.id) {
                t.data.iProcessBean.id = $item.id;
                t.data.iProcessBean.code = $item.code;
                t.data.iProcessBean.name = $item.name;
                t.data.iProcessBean.configData = $item.configData;
            } else {
                t.data.iProcessBean.id = null;
                t.data.iProcessBean.code = null;
                t.data.iProcessBean.name = null;
                t.data.iProcessBean.configData = null;
            }
        }

        function searchProcess(code, name, projectCode) {
            var postParams = {
                code: code,
                name: name,
                projectCode: projectCode,
                ps: 10,
                pn: 1
            };

            return iBusProcess.search({}, postParams).$promise.then(function (resp) {
                return resp.content.datas;
            });
        }

        function startAll(data) {
            iBusTaskRunning.scheduleAll({}, {}, function (resp) {
                vm.search();
                alert("共 " + resp.content.total + " 条任务，成功启动 " + resp.content.start + " 条任务");
            });
        }

        function stopAll(data) {
            iBusTaskRunning.cancelAll({}, {}, function (resp) {
                vm.search();
                alert("共 " + resp.content.total + " 条任务，成功停止 " + resp.content.stop + " 条任务！\n正在运行中的任务是不能被停止；如有未停止完成的任务，请重试");
            });
        }

        function changeState(data) {
            (data.scheduleStatus ? iBusTaskRunning.cancel : iBusTaskRunning.schedule)({}, {code: data.code}, function () {
                vm.search();
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function deleteSchedule(row) {
            iBusSchedule.delete({id: row.id}, {}, function () {
                vm.search();
            });
        }

        function edit(row) {
            iBusSchedule.get({id: row.id}, {}, function (data) {
                iBusScheduleTaskParam.list({taskCode: data.content.code}, {}, function (response) {
                    var entity = {
                        heading: '编辑',
                        contentId: "schedule-eidt.html",
                        data: data.content,
                        scheduleParams: response.content,
                        roles: [],
                        active: true,
                        tableParams: new NgTableParams({}, {
                            counts: [], getData: function () {
                                return entity.scheduleParams
                            }
                        }),
                        newSchedule: false
                    };
                    vm.tabs.push(entity);
                    $timeout(function () {
                        vm.active = vm.tabs.length;
                    }, 0);

                });
            });
        }

        function create() {
            var entity = {
                heading: '创建',
                contentId: "schedule-eidt.html",
                data: {},
                scheduleParams:[],
                roles: [],
                active: true,
                tableParams: new NgTableParams({}, {counts:[], getData:function(){return entity.scheduleParams}}),
                newSchedule:true
            };

            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function update(tab, idx, form) {
            var data = tab.data;
            form.passwordUnmatch = "";

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            if (!!!data.id) {
                data.scheduleParams = tab.scheduleParams;
                iBusSchedule.add({}, data, function () {
                    vm.removeTab(idx);
                });
            } else {
                iBusSchedule.update({}, data, function () {
                    vm.removeTab(idx);
                });
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            var modalInstance = $uibModal.open({
                templateUrl: 'deleteModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            modalInstance.result.then(function (item) {
                vm.deleteSchedule(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        /********************************************************************************/
        /********************************************************************************/
        vm.createScheduleParam = createScheduleParam;
        vm.deleteScheduleParam = deleteScheduleParam;
        vm.updateTaskParam = updateTaskParam;
        vm.scheduleTaskDetail = scheduleTaskDetail;

        function scheduleTaskDetail(row) {
            iBusScheduleTaskParam.list({taskCode: row.code}, {}, function (response) {
                var entity = {
                    heading: '任务信息详情',
                    contentId: "task-detail.html",
                    data: row,
                    scheduleParams: response.content,
                    roles: [],
                    active: true
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);

            });
        }
        function createScheduleParam(entity) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'scheduleParam-new.html',
                controller: newParam,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        tab: entity,
                        scheduleParam: {
                            id: null,
                            taskCode: entity.data.code,
                            paramName: null,
                            paramValue: null,
                            paramType: null
                        },
                        NewParam: true
                    }
                }

            });
            vm.modalInstance.result.then(function (item) {
                item.tab.tableParams.reload();
            });
        }

        function updateTaskParam(row, entity) {
            var value = null;
            if (row.paramType == 4 && !entity.newSchedule && !!row.paramValue) {
                value = $filter('date')(new Date(Number(row.paramValue)), "yyyy-MM-dd HH:mm:ss.sss");
            } else {
                value = row.paramValue;
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'scheduleParam-new.html',
                controller: newParam,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        tab: entity,
                        scheduleParam: {
                            id: row.id,
                            taskCode: entity.data.code,
                            paramName: row.paramName,
                            paramValue: value,
                            paramType: row.paramType
                        },
                        oldParamName:row.paramName,
                        NewParam: false
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                item.tab.tableParams.reload();
            });
        }
        function deleteScheduleParam(entity) {
            var paramBeans = entity.tableParams.getSelected();
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }

            if (entity.newSchedule) {
                angular.forEach(paramBeans, function (bean) {
                    var idx = entity.scheduleParams.indexOf(bean);
                    entity.scheduleParams.splice(idx, 1);
                });
                entity.tableParams.reload();
            } else {
                //todo 编辑任务时删除参数
                var ids = [];
                angular.forEach(paramBeans, function (bean) {
                    ids.push(bean.id);
                });
                iBusScheduleTaskParam.deleteByIds({}, {ids: ids}, function (resp) {
                    angular.forEach(paramBeans, function (bean) {
                        var idx = entity.scheduleParams.indexOf(bean);
                        entity.scheduleParams.splice(idx, 1);
                    });
                    entity.tableParams.reload();
                });
            }
        }
        //新建或者编辑参数时弹出框所在域的Controller
        newParam.$inject = ['$uibModalInstance', 'item', 'iBusScheduleTaskParam', 'toastr'];

        function newParam($uibModalInstance, item, iBusScheduleTaskParam, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.paramType = [
                {'id': 1, 'title': '字符串'},
                {'id': 2, 'title': '整型'},
                {'id': 3, 'title': '浮点数'},
                {'id': 4, 'title': '日期'}];
            $ctrl.add = add;
            $ctrl.update = update;
            $ctrl.cancel = cancel;
            function validateFormate(scheduleParam) {
                if (!!!scheduleParam.paramName) {
                    toastr.warning("参数名不能为空", "警告");
                    return false;
                }
                if (!!!scheduleParam.paramType) {
                    toastr.warning("参数类型不能为空", "警告");
                    return false;
                }
                if (!!scheduleParam.paramValue) {
                    var value = scheduleParam.paramValue;
                    if (scheduleParam.paramType == 4) {
                        if (!/^\d{4}-\d{2}-\d{2}$|^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$|^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{1,3}$/.test(value)) {
                            toastr.error("日期格式不正确", "错误");
                            return false;
                        }
                    }
                    if (scheduleParam.paramType == 3) {
                        if (!/^\d+\.\d+$/.test(value)) {
                            toastr.error("浮点数格式不正确", "错误");
                            return false;
                        }
                    }
                    if (scheduleParam.paramType == 2) {
                        if (!/^\d*$/.test(value)) {
                            toastr.error("整型格式不正确", "错误");
                            return false;
                        }
                    }
                }
                return true;
            }
            function add(form) {
                if (!validateFormate($ctrl.item.scheduleParam)) {
                    return;
                }
                if (!!$ctrl.item.tab.scheduleParams) {
                    var ps = $ctrl.item.tab.scheduleParams;
                    for (var i = 0; i < $ctrl.item.tab.scheduleParams.length; i++) {
                        if (ps[i].paramName == $ctrl.item.scheduleParam.paramName) {
                            toastr.error("此参数名已存在", "错误");
                            return;
                        }
                    }
                }
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                if ($ctrl.item.tab.newSchedule) {
                    $ctrl.item.tab.scheduleParams.push($ctrl.item.scheduleParam);
                    $uibModalInstance.close($ctrl.item);
                } else {
                    //todo 若不是新建调度任务则直接发送后台请求保存
                    iBusScheduleTaskParam.add({}, $ctrl.item.scheduleParam, function (data) {
                        if (!!data.content) {
                            $ctrl.item.tab.scheduleParams.push(data.content);
                        } else {
                            toastr.error("保存失败", "错误");
                        }
                        $uibModalInstance.close($ctrl.item);
                    }, function (error) {
                        toastr.error("保存失败", "错误");
                        $uibModalInstance.close($ctrl.item);
                    });
                }
            }

            function update(form) {
                if (!validateFormate($ctrl.item.scheduleParam)) {
                    return;
                }
                if ($ctrl.item.oldParamName != $ctrl.item.scheduleParam.paramName) {
                    var ps = $ctrl.item.tab.scheduleParams;
                    for (var i = 0; i < $ctrl.item.tab.scheduleParams.length; i++) {
                        if (ps[i].paramName == $ctrl.item.scheduleParam.paramName) {
                            toastr.error("此参数名已存在", "错误");
                            return;
                        }
                    }
                }
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                var oldBean = null;
                var newBean = $ctrl.item.scheduleParam;
                var sp = $ctrl.item.tab.scheduleParams;
                for (var i = 0; i < sp.length; i++) {
                    if (sp[i].paramName == $ctrl.item.oldParamName) {
                        oldBean = sp[i];
                        break;
                    }
                }
                if ($ctrl.item.tab.newSchedule) {
                    if (oldBean != null) {
                        oldBean.paramName = newBean.paramName;
                        oldBean.paramValue = newBean.paramValue;
                        oldBean.paramType = newBean.paramType;
                    }
                    $uibModalInstance.close($ctrl.item);
                } else {
                    //todo 若不是新建调度任务则直接发送后台请求更新
                    iBusScheduleTaskParam.update({}, newBean, function (data) {
                        if (!!data.content && oldBean != null) {
                            oldBean.paramName = data.content.paramName;
                            oldBean.paramValue = data.content.paramValue;
                            oldBean.paramType = data.content.paramType;
                        } else {
                            toastr.error("更新失败", "错误");
                        }
                        $uibModalInstance.close($ctrl.item);
                    }, function (error) {
                        toastr.error("更新失败", "错误");
                        $uibModalInstance.close($ctrl.item);
                    });
                }
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
