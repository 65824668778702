(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Customer', Customer);

    Customer.$inject = ['$resource', 'iServiceConfig'];

    function Customer ($resource, iServiceConfig) {
        var service = $resource('web/customer', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/customer/list", method: 'POST'},
            'delete': {url: iServiceConfig.idataHost + "/web/customer/delete", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/customer/detail", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/customer/save", method: 'POST'},
            'disable' :{url: iServiceConfig.idataHost + "/web/customer/disable", method: 'POST'},
            'enable' :{url: iServiceConfig.idataHost + "/web/customer/enable", method: 'POST'},
            
        });

        return service;
    }
})();
