(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleTrialApplyController', UserModuleTrialApplyController);

    UserModuleTrialApplyController.$inject = ['UserModuleTrialApply', 'NgTableParams','$uibModal', 'Principal','$timeout','NgTree','toastr','NgSelect'];

    function UserModuleTrialApplyController(UserModuleTrialApply, NgTableParams, $uibModal, Principal,$timeout,NgTree,toastr,NgSelect) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        
        vm.tree = null;
        vm.account = {};
        vm.tabs = [];
        vm.active = 0;
        vm.status = [{'id':null, 'title':'全部'}, {'id':0, 'title':'新建'},{'id':10, 'title':'已处理'},{'id':-10, 'title':'已拒绝'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.update = update;
        vm.removeTab = removeTab;
        vm.show =false;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return UserModuleTrialApply.list({}, postParams, function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.datas;

                    return datas;
                });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        
        function edit(row) {
        	UserModuleTrialApply.detail({}, {id: row.id}, function(resp) {
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,"错误");
         			return;
         		}
        		 var data = resp.content;
        		 var entity = {
                     heading: '编辑'+data.companyName,
                     contentId: "trialApply-eidt.html",
                     statusList: vm.status,
                     data: data,
                     active: true
                 };
         	
     			vm.tabs.push(entity);
     			$timeout(function(){
                     vm.active = vm.tabs.length;
                 },0);
        	});
        }
        function create() {
        	var entity = {
                heading: '新建',
                contentId: "trialApply-eidt.html",
                statusList: vm.status,
                data: {
                    companyName: "",
                    companyFullName: "",
                    industry: "",
                    contactName: "",
                    contactMobile: "",
                    warehouseCount: "",
                    warehouseArea: "",
                    warehouseDailyHandle: "",
                    status: 0,
                    remark: "",
                },
                active: true
            };
        
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        
        function update(tab, idx, form) {
            var trialApply = tab.data;
            console.log(trialApply);

            var valid = form.$valid;
            if (trialApply.companyName==null||trialApply.companyName=="") {
                toastr.error("请输入公司简称!", '错误');
                return;
            }
            if (trialApply.companyFullName==null||trialApply.companyFullName=="") {
                toastr.error("请输入公司全称!", '错误');
                return;
            }
            if (trialApply.industry==null||trialApply.industry=="") {
                toastr.error("请输入所属行业!", '错误');
                return;
            }
            if (trialApply.contactName==null||trialApply.contactName=="") {
                toastr.error("请输入联系人!", '错误');
                return;
            }
            if (trialApply.contactMobile==null||trialApply.contactMobile=="") {
                toastr.error("请输入联系方式!", '错误');
                return;
            }
            if (trialApply.id && trialApply.id > 0) {
            	UserModuleTrialApply.update({}, trialApply, function(resp) {
                	if(resp.status === 'ERROR') {
            			toastr.error(resp.errorMsg,'错误');
            			return;
            		}
            		toastr.success("修改成功！");
                    vm.search();
                    vm.removeTab(idx);
                });
            } else {
            	UserModuleTrialApply.save({}, trialApply, function(resp) {
                	if(resp.status === 'ERROR') {
            			toastr.error(resp.errorMsg,'错误');
            			return;
            		}
            		toastr.success("保存成功！");
                    vm.search();
                    vm.removeTab(idx);
                });
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
    }
})();
