(function()  {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('InvPendPaOccupyController', InvPendPaOccupyController);

    InvPendPaOccupyController.$inject = ['$rootScope', 'InvPendPaOccupy', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','enums'];

    function InvPendPaOccupyController ($rootScope,  InvPendPaOccupy, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,enums) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.InvTranSourceTypeObj = enums('inventory.InvTranSourceType');
        vm.InvTranSourceType = vm.InvTranSourceTypeObj.list('title', 'id');
        function pageTitle() {
            return $window.document.title;
        }

      //查询
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return InvPendPaOccupy.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();
		}

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
