(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhWarehouseUserAuth', WarehouseUserAuth);

    WarehouseUserAuth.$inject = ['$resource', 'iServiceConfig'];

    function WarehouseUserAuth ($resource, iServiceConfig) {
        var service = $resource('web/warehouseUserAuth', {}, {
            'authList': { url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/authList", method: 'POST'},
            'equipmentList': { url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/equipmentList", method: 'POST'},
            'equipmentCodeList': { url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/equipmentCodeList", method: 'POST'},
            'saveWarehouseUserAuth': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/saveWarehouseUserAuth", method: 'POST'},
            'saveUserEquipment': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/saveUserEquipment", method: 'POST'},
            'deleteAuth': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/deleteWarehouseUserAuth", method: 'POST'},
            'deleteEquipment': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/deleteUserEquipment", method: 'POST'},
            'addWarehouseUserAuth': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/addWarehouseUserAuth", method: 'POST'},
            'addWarehouseUserEquipment': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/addWarehouseUserEquipment", method: 'POST'},
            'validateSpecCode': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/validateSpecCode", method: 'POST'},
            'authEnable': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/authEnable", method: 'POST'},
            'equipmentEnable': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/enableUserEquipment", method: 'POST'},
            'findJobType': {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/findJobType", method: 'POST'},
            'getUserList' : {url: iServiceConfig.iwhDataHost+ "/web/warehouseUserAuth/getUserList", method: 'GET'}
        });
        return service;
    }
})();
