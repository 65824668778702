(function () {
    'use strict';
    angular.module('iwh-dataModule').config(config);
    config.$inject=['$stateProvider'];
    function config($stateProvider) {
        $stateProvider
            .state('iwh-platform-maintenance',{
                parent:'main',
                url:'iwh-data/platform-maintenance',
                params: {'postParams' : null},
                data: {
                    'pageTitle': '月台维护',
                    'postParams': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-data-module/platform/platform.html',
                        controller: 'IwhPlatformController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();