(function () {
    'use strict';

    angular.module('idashboardModule')
        .factory('Dashboard', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/dashboard', {}, {
                'getReceiptRate': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getReceiptRate',
                    method: 'GET'
                },
                'getLocState': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getLocState',
                    method: 'GET'
                },
                'getShipRate': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getShipRate',
                    method: 'GET'
                },
                'getReceiptAndShipRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getReceiptAndShipRecent',
                    method: 'GET'
                },
                'getReceiptRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getReceiptRecent',
                    method: 'GET'
                },
                'getWarehouseRateInUse': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getWarehouseRateInUse',
                    method: 'GET'
                },
                'getShipRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getShipRecent',
                    method: 'GET'
                },
                'getWHReceiptRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getWHReceiptRecent',
                    method: 'GET'
                },
                'getWHShipRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getWHShipRecent',
                    method: 'GET'
                }
            });
        }]);
})();