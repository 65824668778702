(function () {
    'use strict';
    angular.module('idataModule').config(config);
    config.$inject=['$stateProvider'];
    function config($stateProvider) {
        $stateProvider
            .state('park-maintenance',{
                parent:'main',
                url:'data/park-maintenance',
                params: {'postParams' : null},
                data: {
                    'pageTitle': '园区维护',
                    'postParams': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'data-module/park/park.html',
                        controller: 'ParkController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();