(function() {
	'use strict';

	angular.module('idataModule').controller('SkuController',
			SkuController);

	SkuController.$inject = [ 'Sku', 'NgTableParams', '$uibModal',
			 '$timeout', 'toastr','$state','enums'];

	function SkuController(Sku, NgTableParams, $uibModal,
			 $timeout, toastr,$state,enums) {
		var vm = this;
		vm.modalInstance = null;
		vm.tableParams = null;
		vm.tenantDetail = null;
		vm.tabs = [];
		vm.active = 0;
		vm.search = search;
		vm.removeTab = removeTab;

		vm.create = create;

		vm.showAddPack=showAddPack;

		vm.showAddBar=showAddBar;

		vm.confirmOp=confirmOp;

		vm.saveBar=saveBar;

		vm.skuBarEdit=skuBarEdit;

		vm.updataBar=updataBar;

		vm.delBar=delBar;

		vm.setDefBar=setDefBar;

		vm.savePack=savePack;

		vm.skuEdit=skuEdit;

		vm.skuDel=skuDel;

		vm.skuDetail=skuDetail;

		vm.showPackDetail=showPackDetail;

		vm.skuPackEdit=skuPackEdit;

		vm.delPack=delPack;

		vm.setDefPack=setDefPack;

		vm.updataPack=updataPack;

		vm.saveNewSku=saveNewSku;
		vm.saveNewSku2=saveNewSku2;

		vm.updataSku=updataSku;
		vm.updataSku2=updataSku2;

		vm.validate=validate;
		vm.bomTypeEnums = enums('data.SkuBomType');
		vm.bomType= vm.bomTypeEnums.list('name', 'id');
//		--------------------------------------------------

// 通用方法
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
//列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				if($state.params.ownerCode!=null){
					params.filter().ownerCodeLike=$state.params.ownerCode;
					$state.params.ownerCode=null;
					}
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count(),
                    orderBy : 'code'
				}, params.filter());
				return Sku.skuList({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});
		vm.ok = ok;
		function ok(item){
			console.log(item);
		}
//		代码检查
		function validate(t){
			if(t.data.sku.ownerCode===null){
				toastr.warning("请选择商品的货主", "警告");
				return;
			}
			if(t.data.sku.code==null||t.data.sku.code==""){
				return;
			}
			Sku.skuCheckCode({},{code:t.data.sku.code,ownerCode:t.data.sku.ownerCode},
	            	function(resp){
	            		if(resp.content){
	            			toastr.warning("编码已经被使用", "警告");
	            		}
	            });

		}

//		新建商品保存
		function saveNewSku(t,idx,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.data.sku.ownerCode===null){
            	toastr.warning("请先选择商品的货主", "警告");
            	return;
            }
            if(t.data.sku.barcode===null){
            	toastr.warning("默认条码不能为空", "警告");
            	return;
            }
            if(t.data.sku.packCode===null){
            	toastr.warning("默认包装不能为空", "警告");
            	return;
            }
            Sku.skuCheckCode({},{code:t.data.sku.code,ownerCode:t.data.sku.ownerCode},
            	function(resp){
            		if(resp.content){
            			toastr.warning("商品编码已经被使用", "警告");
            			return ;
            		}
            		vm.confirmOp('save',function(){
            			Sku.skuSaveNew({},t.data.sku,function(res){
            			toastr.success("新增成功", "成功");
            			removeTab(idx);
            			});
            		});

            });
		}
		function updataSku(t,idx,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            console.log(t.data.sku);
            vm.confirmOp('updata',function(){
    			Sku.skuUpdata({},t.data.sku,function(res){
    			toastr.success("更新成功", "成功");
    			removeTab(idx);
    			});
    		});
		}

        function saveNewSku2(t,idx){
            if(t.data.sku.ownerCode===null){
                toastr.warning("请先选择商品的货主", "警告");
                return;
            }
            if(t.data.sku.barcode===null){
                toastr.warning("默认条码不能为空", "警告");
                return;
            }
            if(t.data.sku.packCode===null){
                toastr.warning("默认包装不能为空", "警告");
                return;
            }
            Sku.skuCheckCode({},{code:t.data.sku.code,ownerCode:t.data.sku.ownerCode},
                function(resp){
                    if(resp.content){
                        toastr.warning("商品编码已经被使用", "警告");
                        return ;
                    }
                    vm.confirmOp('save',function(){
                        //BOM字段设置为当前商品的字段
                        t.data.sku.bom.skuCode =  t.data.sku.code;
                        t.data.sku.bom.skuName =  t.data.sku.name;
                        t.data.sku.bom.ownerCode =  t.data.sku.ownerCode;
                        t.data.sku.bom.ownerName =  t.data.sku.ownerName;

                        Sku.skuSaveNew({},t.data.sku,function(res){
                            toastr.success("新增成功", "成功");
                            removeTab(idx);
                        });
                    });

                });
        }
        function updataSku2(t,idx){
            console.log(t.data.sku);
            vm.confirmOp('updata',function(){
                Sku.skuUpdata({},t.data.sku,function(res){
                    toastr.success("更新成功", "成功");
                    removeTab(idx);
                });
            });
        }

//		查看商品详细
		function skuDetail(row){
			Sku.skuDetail({},{id:row.id},
					function(resp){
						var sk = resp.content
						var entity = {
							skuTemplate:"sku-skudetail.html",
							barTemplate:"sku-bardetail.html",
							packTemplate:"sku-packview.html",
							packHtml:"sku-packhead.html",
							packBody:"sku-packdetail.html",
							heading : '查看'+row.name,
							contentId : "sku-edit.html",
							data : {
								sku:sk,
							},
							active : true
						};

						vm.tabs.push(entity);
						$timeout(function(){
			                vm.active = vm.tabs.length;
				            entity.active = 0;
			           },0);
					});
		}

//		删除商品
		function skuDel(row){
			vm.confirmOp('del',function(){
        		Sku.skuDel({},{id:row.id},function(resp){
				toastr.success("删除成功", "成功");
				vm.search();
			   });
       	   });
		}

//		编辑商品
		function skuEdit(row){
            Sku.skuDetail({},{id:row.id},
			function(resp){
				var sku = resp.content
				console.log(sku);
				var entity = {
					skuTemplate:"sku-skuedite.html",
					barTemplate:"sku-baredite.html",
					packTemplate:"sku-packedite.html",
                    bomTemplate:"sku-bomview.html",
                    packHtml:"sku-packhead.html",
					packBody:"sku-packdetail.html",
					packEdite:"sku-pack.html",
					owner : [{'id':'1001', 'title':'张三'},
					         {'id':'1002', 'title':'京东'}],
				    storageType : [{'id':'', 'title':''},
							    {'id':'storageType1', 'title':'存储分类1'},
							    {'id':'storageType2', 'title':'存储分类2'},
							    {'id':'storageType3', 'title':'存储分类3'}],
					formType : [{'id':'', 'title':''},
								{'id':'sku-form-square', 'title':'方形'},
								{'id':'sku-form-circular', 'title':'圆形'}],
					storageCondition : [{'id':'', 'title':''},
									   {'id':'storage-condition-frozen', 'title':'冰冻'},
									   {'id':'storage-condition-dry', 'title':'干燥'}],
					category : [{'id':'', 'title':''},
							   {'id':'category1', 'title':'商品分类1'},
							   {'id':'category2', 'title':'商品分类2'}],
					abcType	: [{'id':'', 'title':''},
							   {'id':'sku-adc-A', 'title':'商品A型'},
							   {'id':'sku-abc-B', 'title':'商品B型'},
							   {'id':'sku-abc-C', 'title':'商品C型'}],
					dangerCode : [{'id':'', 'title':''},
								  {'id':'sku-danger-danger', 'title':'危险'},
								  {'id':'sku-danger-safe', 'title':'安全'}],
						freightType : [{'id':'', 'title':''},
					               {'id':'sku-freight-flight', 'title':'空运'},
					               {'id':'sku-freight-car', 'title':'陆运'}],
					heading : '编辑'+row.code,
					contentId : "sku-edit.html",
                    requireType:[{'id':'1','title':'是'},
                        {'id':'0','title':'否'}],
					data : {
						sku:sku,
						tempBar:null,
						tempPack:null,
						showBar : false,
						editBar : false,
						skuBar : {},
						showPack:false,
						editPack : false,
						skuPack : {},
						tempPackStatus:null
					},
                    tableCompParams:new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                bomCode: entity.data.sku.bom===null?null:entity.data.sku.bom.bomCode,
                                pn: params.page(),
                                ps: params.count(),
                            }, params.filter());
                            vm.postParams = postParams;
                            return Sku.compList({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function (data) {
                                var datas = data.content.datas;
                                console.info(datas);
                                return datas;
                            });
                        }
                    }),
					newSku : false,
					active : true
				};
				entity.newBom = entity.data.sku.bom === null;
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
		            entity.active = 0;
	           },0);
			});

		}
//     新建商品
		function create(){
			var data = {
                sku:{
                    category: null,
                        ownerCode : null,
                        packCode:null,
                        barcode:null,
                        qualityCheckFlag : 0,
                        skuBars : [],
                        skuPacks : [],
                        length: 1,
                        width: 1,
                        height: 1,
                        cube: 1,
                        grosswgt: 1,
                        netwgt: 1,
                        skinwgt: 0,
                        bom:{
                        type:10,
                            compList:[]
                    }
                },
                tempBar:null,
                    tempPack:null,
                    showBar : false,
                    editBar : false,
                    skuBar : {},
                showPack:false,
                    editPack : false,
                    skuPack : {
                    eachQty:1
                },
                tempPackStatus:null
            }
			var entity = {
					skuTemplate:"sku-skuedite.html",
					barTemplate:"sku-baredite.html",
                    bomTemplate:"sku-bomview.html",
                    packTemplate:"sku-packedite.html",
					packHtml:"sku-packhead.html",
					packBody:"sku-packdetail.html",
					packEdite:"sku-pack.html",
					owner : [{'id':'1001', 'title':'张三'},
					         {'id':'1002', 'title':'京东'}],
				    storageType : [{'id':'', 'title':''},
							    {'id':'storageType1', 'title':'存储分类1'},
							    {'id':'storageType2', 'title':'存储分类2'},
							    {'id':'storageType3', 'title':'存储分类3'}],
					formType : [{'id':'', 'title':''},
								{'id':'sku-form-square', 'title':'方形'},
								{'id':'sku-form-circular', 'title':'圆形'}],
					storageCondition : [{'id':'', 'title':''},
									   {'id':'storage-condition-frozen', 'title':'冰冻'},
									   {'id':'storage-condition-dry', 'title':'干燥'}],
					category : [{'id':'', 'title':''},
							   {'id':'category1', 'title':'商品分类1'},
							   {'id':'category2', 'title':'商品分类2'}],
					abcType	: [{'id':'', 'title':''},
							   {'id':'sku-adc-A', 'title':'商品A型'},
							   {'id':'sku-abc-B', 'title':'商品B型'},
							   {'id':'sku-abc-C', 'title':'商品C型'}],
					dangerCode : [{'id':'', 'title':''},
								  {'id':'sku-danger-danger', 'title':'危险'},
								  {'id':'sku-danger-safe', 'title':'安全'}],
					freightType : [{'id':'', 'title':''},
					               {'id':'sku-freight-flight', 'title':'空运'},
					               {'id':'sku-freight-car', 'title':'陆运'}],

					requireType:[{'id':'1','title':'是'},
						        {'id':'0','title':'否'}],
					heading : '新建',
					contentId : "sku-edit.html",
					data : data,
                	tableCompParams:[],
					newSku : true,
					newBom : true,
					active : true
				};

				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	                entity.active = 0;
	            },0);
		}



//		改变包装隐藏状态
		function showAddPack(t){
			//-----------------------------------------------------------
			if(t.data.editPack){
				t.data.tempPackStatus.edit=false;
			}
			t.data.skuPack={eachQty:1,eachName:"个",innerName:"袋/打",caseName:"箱/包"};
			t.data.showPack=!t.data.showPack;
			t.data.editPack=false;
		}
		vm.showAddPackCreat=showAddPackCreat;
		function showAddPackCreat(t){
			if(t.data.editPack){
				t.data.tempPackStatus.edit=false;
				t.data.showPack=!t.data.showPack;
			}
			t.data.skuPack={eachQty:1,eachName:"个",innerName:"袋/打",caseName:"箱/包",
					eachLength: 1,
					eachWidth: 1,
					eachHeight: 1,
					eachCube: 1,
					eachGrosswgt: 1,
					eachNetwgt: 1,
					eachSkinwgt: 0};
			t.data.showPack=!t.data.showPack;
			t.data.editPack=false;
		}

//		保存包装
		function savePack(t,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.data.sku.ownerCode==null){
            	toastr.warning("请先选择商品的货主", "警告");
            	return;
            }
            if(t.newSku){
            	var used = false;
            	angular.forEach(t.data.sku.skuPacks, function(data) {
            			if(data.packCode===t.data.skuPack.code){
	            		toastr.warning("编码已经被使用", "警告");
	            		used=true;
	            		return;

            		}
	            });
            	if(used){
	            	return;
	            }
            	Sku.packCheckCode({},{code:t.data.skuPack.code,ownerCode:t.data.sku.ownerCode},
                		function(resp){
                			if(resp.content){
                				toastr.warning("编码已经被使用", "警告");
                				return ;
                			}
                		vm.confirmOp('save',function(){
                			t.data.skuPack.eachCode="EA";
                			t.data.skuPack.innerCode="IN";
                			t.data.skuPack.caseCode="CS";
                      	    t.data.sku.skuPacks.push(t.data.skuPack);
                      	    if(t.data.sku.packCode===null){
                      	       	t.data.sku.packCode=t.data.skuPack.code;
                      	    }
                      	      vm.showAddPack(t);
                            var valid = form.$valid;
                            if (!valid) {
                                form.showMsg = true;
                                return;
                            }
                            if(t.data.sku.barcode===null){
                                toastr.info("请去填写条码信息", "提示");
                                return;
                            }
                      	   }
                         );
                	})
            }else{
            	Sku.packCheckCode({},{code:t.data.skuPack.code,ownerCode:t.data.sku.ownerCode},
                		function(resp){
                			if(resp.content){
                				toastr.warning("编码已经被使用", "警告");
                				return ;
                			}

                		vm.confirmOp('save',function(){
                      	    t.data.skuPack.skuCode=t.data.sku.code;
                      	    t.data.skuPack.ownerCode=t.data.sku.ownerCode;
                      	    t.data.skuPack.eachCode="EA";
              			    t.data.skuPack.innerCode="IN";
              			    t.data.skuPack.caseCode="CS";
                      	      vm.saveNewPackVO(t);
                      	   }
                         );
                	});
            }

		}
		vm.saveNewPackVO=saveNewPackVO;
		function saveNewPackVO(t){
			Sku.packSaveNew({},t.data.skuPack,function(resp){
				toastr.success("保存成功", "成功");
				vm.showAddPack(t);
				Sku.packList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
					t.data.sku.skuPacks=res.content;
				});
			});
		}
//		更新包装
		function updataPack(t,form){

			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.data.sku.ownerCode==null){
            	toastr.warning("请先选择商品的货主", "警告");
            	return;
            }
            if(t.newSku){
            	var dex=t.data.sku.skuPacks.indexOf(t.data.tempPack);
            	for (var int = 0; int < t.data.sku.skuPacks.length; int++) {
            		if(int===dex){
            			continue;
            		}
            		if(t.data.skuPack.code===t.data.sku.skuPacks[int].packCode){
	            		toastr.warning("编码已经被使用", "警告");
	            		return;
            		}
				}
            	Sku.packCheckCode({},{code:t.data.skuPack.code,ownerCode:t.data.sku.ownerCode},
                		function(resp){
                			if(resp.content){
                				toastr.warning("编码已经被使用", "警告");
                				return ;
                			}

                		vm.confirmOp('updata',function(){
                			   var pack= t.data.skuPack;
                			   for(var p in pack)
                			   {
                					var name=p;//属性名称
                					var value=pack[p];//属性对应的值
                					t.data.tempPack[name]=pack[p];
                			   }
                			   if(t.data.sku.packCode===null){
                         	       	t.data.sku.packCode=t.data.skuPack.code;
                         	  }
                      	      vm.showAddPack(t);
                      	   }
                         );
                	})
            }else{
            	vm.confirmOp('updata',function(){
            		vm.updataPackVO(t);
           	   }
              );
            }

		}

		vm.updataPackVO=updataPackVO;
		function updataPackVO(t){
			Sku.packUpdata({},t.data.skuPack,function(resp){
				toastr.success("更新成功", "成功");
				vm.showAddPack(t);
				Sku.packList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
					t.data.sku.skuPacks=res.content;
				});
			});
		}

//		编辑包装
		function skuPackEdit(row,t){

				if(t.data.editPack){
					t.data.tempPackStatus.edit=false;
				}
				row.edit=true;
				var temp=new Object();
			     for(var p in row)
			   {
			    var name=p;//属性名称
			    var value=row[p];//属性对应的值
			    temp[name]=row[p];
			   }
				t.data.skuPack=temp;
				t.data.tempPack=row;
				t.data.tempPackStatus=row;

			t.data.showPack=true;
			t.data.editPack=true;
		}
//	    删除包装
		function delPack(row,t){
			if(t.newSku){
				vm.confirmOp('del',function(){
					var idx = t.data.sku.skuPacks.indexOf(row);
					t.data.sku.skuPacks.splice(idx, 1);
				});
			}else{
				vm.confirmOp('del',function(){
					Sku.packDel({},{id:row.id},function(resp){
						toastr.success("删除成功", "成功");
						Sku.packList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
							t.data.sku.skuPacks=res.content;
						});
					});
				});
			}
		}
//		设为默认包装
		function setDefPack(row,t){
			if(t.newSku){
				vm.confirmOp('确认设为默认吗？',function(){
					t.data.sku.packCode=row.code;
				});
			}else{
				vm.confirmOp('确认设为默认吗？',function(){
					Sku.skuChangePack({},{skuId:t.data.sku.id,packCode:row.code},function(resp){
						toastr.success("设置成功", "成功");
						t.data.sku.packCode=row.code;
					});
				});
			}
		}

//		包装展示
		function showPackDetail(row,t){
			row.view=!row.view;
		}






//		改变隐藏状态
		function showAddBar(t){
			t.data.skuBar={};
			t.data.showBar=!t.data.showBar;
			t.data.editBar=false;
		}
//      条码新建保存
		function saveBar(t,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.data.sku.ownerCode==null){
            	toastr.warning("请先选择商品的货主", "警告");
            	return;
            }

            if(t.newSku){
            	var used = false;
            	angular.forEach(t.data.sku.skuBars, function(data) {
            			if(data.barcode===t.data.skuBar.barcode){
	            		toastr.warning("编码已经被使用", "警告");
	            		used=true;
	            		return;
            		}
	            });
            	if(used){
	            	return;
	            	}
            	Sku.barCheckCode({},{code:t.data.skuBar.barcode,ownerCode:t.data.sku.ownerCode},
            		function(resp){
            			if(resp.content){
            				toastr.warning("编码已经被使用", "警告");
            				return ;
            			}

            		vm.confirmOp('save',function(){
                  	       t.data.sku.skuBars.push(t.data.skuBar);
                  	       if(t.data.sku.barcode===null){
                  	       	t.data.sku.barcode=t.data.skuBar.barcode;
                  	       }
                  	      vm.showAddBar(t);
                        var valid = form.$valid;
                        if (!valid) {
                            form.showMsg = true;
                            return;
                        }
                        if(t.data.sku.barcode===null){
                            toastr.info("请去填写条码信息", "提示");
                            return;
                        }
                        if(t.data.sku.packCode===null){
                            toastr.info("请去填写默认包装信息", "提示");
                            return;
                        }
                  	   }
                     );
            	})

            }else{
            	Sku.barCheckCode({},{code:t.data.skuBar.barcode,ownerCode:t.data.sku.ownerCode},
                		function(resp){
                			if(resp.content){
                				toastr.warning("编码已经被使用", "警告");
                				return ;
                			}

                		vm.confirmOp('save',function(){
                      	    var bar={
                      	    	skuCode : t.data.sku.code,
                      	    	ownerCode : t.data.sku.ownerCode,
                      	    	barcode	: t.data.skuBar.barcode,
                      	    	name : t.data.skuBar.name
                      	    }
                      	      vm.saveNewBarVO(bar,t);

                      	   }
                         );
                	});
            }

//            function s(){
//        	   console.log("haha");  
//           }
//            vm.confirmOp('save',s);
		}
//		保存新建条码  后台
		vm.saveNewBarVO=saveNewBarVO;
		function saveNewBarVO(bar,t){
			Sku.barSaveNew({},bar,function(resp){
				vm.showAddBar(t);
				toastr.success("保存成功", "成功");
				Sku.barList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
					t.data.sku.skuBars=res.content;
				});
			});
		}


//		更新条码的保存
		function updataBar(t,form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.data.sku.ownerCode==null){
            	toastr.warning("请先选择商品的货主", "警告");
            	return;
            }
            if(t.newSku){
            	var used = false;
            	var dex=t.data.sku.skuBars.indexOf(t.data.tempBar);
            	for (var int = 0; int < t.data.sku.skuBars.length; int++) {
            		if(int===dex){
            			continue;
            		}
            		if(t.data.skuBar.barcode===t.data.sku.skuBars[int].barcode){
	            		toastr.warning("编码已经被使用", "警告");
	            		used=true;
	            		return;
            		}
				}
            	Sku.barCheckCode({},{code:t.data.skuBar.barcode,ownerCode:t.data.sku.ownerCode},
                		function(resp){
                			if(resp.content){
                				toastr.warning("编码已经被使用", "警告");
                				return ;
                			}

                		vm.confirmOp('updata',function(){
                      	       t.data.tempBar.barcode=t.data.skuBar.barcode;
                      	       t.data.tempBar.name=t.data.skuBar.name;
                      	       if(t.data.sku.barcode===null){
                      	       	t.data.sku.barcode=bar.barcode;
                      	       }
                      	      vm.showAddBar(t);
                      	   }
                         );
                	})
            }else{
            	vm.confirmOp('updata',function(){
            		vm.updataBarVO(t);

           	   }
              );
            }

		}
//		更新条码后台
		vm.updataBarVO=updataBarVO;
		function updataBarVO(t){
			Sku.barUpdata({},t.data.skuBar,function(resp){
				toastr.success("更新成功", "成功");
				vm.showAddBar(t);
				Sku.barList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
					t.data.sku.skuBars=res.content;
				});
			});
		}

//		编辑条码按钮
		function skuBarEdit(row,t){
			if(t.newSku){
				var temp={
					barcode	: row.barcode,
              	    name : row.name
				}
				t.data.skuBar=temp;
				t.data.tempBar=row;
			}else{
				var bar={
					id:row.id,
					tenantId:row.tenantId,
					ownerCode:row.ownerCode,
					skuCode:row.skuCode,
					barcode:row.barcode,
					name:row.name
				}
				t.data.skuBar=bar;
			}
			t.data.showBar=true;
			t.data.editBar=true;
		}



//		设置默认条码
		function setDefBar(row,t){
			if(t.newSku){
				vm.confirmOp('确认设为默认吗？',function(){
					t.data.sku.barcode=row.barcode;
				});
			}else{
				vm.confirmOp('确认设为默认吗？',function(){
					Sku.skuChangeBar({},{skuId:t.data.sku.id,barcode:row.barcode},function(resp){
						toastr.success("设置成功", "成功");
						t.data.sku.barcode=row.barcode;
					});
				});
			}

		}

//		删除条码
		function delBar(row,t){
			if(t.newSku){
				if(t.data.editBar===true && row.barcode==t.data.skuBar.barcode){
					vm.confirmOp('是否放弃编辑并删除该数据',function(){
						var idx = t.data.sku.skuBars.indexOf(row);
						t.data.sku.skuBars.splice(idx, 1);
						vm.showAddBar(t);
					});

				}else{
					vm.confirmOp('del',function(){
						var idx = t.data.sku.skuBars.indexOf(row);
						t.data.sku.skuBars.splice(idx, 1);
					});
				}
			}else{
				if(t.data.editBar===true && row.id==t.data.skuBar.id){
					vm.confirmOp('是否放弃编辑并删除该数据',function(){
						Sku.barDel({},{id:row.id},function(resp){
							toastr.success("删除成功", "成功");
							vm.showAddBar(t);
							Sku.barList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
								t.data.sku.skuBars=res.content;
							});
						});
					});
				}else{
					vm.confirmOp('del',function(){
						Sku.barDel({},{id:row.id},function(resp){
							toastr.success("删除成功", "成功");
							Sku.barList({},{ownerCode:t.data.sku.ownerCode,skuCode:t.data.sku.code},function(res){
							t.data.sku.skuBars=res.content;
							});
						});
					});
				}
			}
		}




		// 确认操作封装
		function confirmOp(p,func){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-op.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						op:p
					}
				}
			});
			vm.modalInstance.result.then(function(item) {
				return func();
			});
		}
		confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;
			function ok() {
				$uibModalInstance.close(true);
			}
			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}


	//==============BOM商品操作===============//

		vm.addComp = addComp;
		vm.editComp = editComp;
		vm.delComp = delComp;

		vm.saveBom = saveBom;
		vm.updateBom = updateBom;

		//添加构件
		function addComp(tab) {
			console.info(tab);
            vm.modalInstance = $uibModal.open({
                templateUrl : 'comp-op.html',
                controller : bomModal,
                controllerAs : '$ctrl',
                resolve : {
                    item : {
                    	tab:tab,
                        comp:{ownerCode: tab.data.sku.ownerCode},
                        newComp: true
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {

            });
        }
		//编辑构件
		function editComp(tab,row,index) {
			console.log("编辑构件");
			console.info(row);
            vm.modalInstance = $uibModal.open({
                templateUrl : 'comp-op.html',
                controller : bomModal,
                controllerAs : '$ctrl',
                resolve : {
                    item : {
                    	index:index,
                    	tab:tab,
                        comp:row,
                        newComp: false
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {

            });
        }
		//删除构件
		function delComp(tab, row) {
			vm.confirmOp("确认要删除该构件?",function () {
				Sku.delComp({},{id:row.id},function (resp) {
					if (resp.content > 0){
						toastr.success("删除成功!","成功");
						tab.tableCompParams.reload();
					}
                })
            })
        }
    	bomModal.$inject = ['$uibModalInstance', 'item','toastr'];
    	function bomModal($uibModalInstance, item,toastr) {
    	    var $ctrl = this;
    	    //静态编辑,保存到数据库
			$ctrl.item = item;
    	    $ctrl.add = add;
    	    $ctrl.edit = edit;
            //保存到数据库
    	    $ctrl.save = save;
    	    $ctrl.update = update;

    	    function add(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                if ($ctrl.item.tab.data.sku.ownerCode === null){
                	toastr.error("请先选择货主","错误");
                	return;
				}
				if ($ctrl.item.tab.data.sku.code === null || $ctrl.item.tab.data.sku.code=== undefined){
                	toastr.error("请先设置商品编码","错误");
                	return;
				}
				if ($ctrl.item.tab.data.sku.bom.bomCode === null || $ctrl.item.tab.data.sku.bom.bomCode=== undefined){
                	toastr.error("请先设置BOM编码","错误");
                	return;
				}
				if ($ctrl.item.comp.sku.code === $ctrl.item.tab.data.sku.code){
                    toastr.error("BOM商品不能选择以自己为构件!", "错误");
                    return
				}
                //构件的字段设置为当前商品的字段
                $ctrl.item.comp.ownerCode =  $ctrl.item.tab.data.sku.ownerCode;
                $ctrl.item.comp.ownerName =  $ctrl.item.tab.data.sku.ownerName;
                $ctrl.item.comp.skuCode = $ctrl.item.comp.sku.code;
                $ctrl.item.comp.skuName = $ctrl.item.comp.sku.name;
                $ctrl.item.comp.bomCode = $ctrl.item.tab.data.sku.bom.bomCode;
                var used = false;
                //判断本地集合中是否存在相同的编码
                angular.forEach($ctrl.item.tab.data.sku.bom.compList, function(data) {
                    if(data.skuCode===$ctrl.item.comp.skuCode && data.ownerCode===$ctrl.item.comp.ownerCode){
                        toastr.warning("不能有两个相同的构件", "警告");
                        used=true;
                    }
                });
                if (used){
                    return;
                }
                //将添加的构件加入本地列表中
                $ctrl.item.tab.data.sku.bom.compList.push($ctrl.item.comp);
                $ctrl.item.tab.tableCompParams = $ctrl.item.tab.data.sku.bom.compList;
                console.info($ctrl.item.tab.tableCompParams);
                $uibModalInstance.close($ctrl.item);
    	    }

    	    function edit(form,index) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                var used = 0;
                //判断本地集合中是否存在相同的编码
                angular.forEach($ctrl.item.tab.data.sku.bom.compList, function(data) {
                    if(data.skuCode===$ctrl.item.comp.skuCode  && data.ownerCode===$ctrl.item.comp.ownerCode){
                        used++;
                    }
                });
                if (used>1){
                    toastr.warning("不能有两个相同的构件", "警告");
                    return;
                }

                $ctrl.item.comp.skuCode = $ctrl.item.comp.sku.code;
                $ctrl.item.comp.skuName = $ctrl.item.comp.sku.name;

                $ctrl.item.tab.data.sku.bom.compList[$ctrl.item.index] = $ctrl.item.comp;
                $uibModalInstance.close($ctrl.item);
                console.log("编辑本地BOM:")
                console.info($ctrl.item.tab.data.sku.bom.compList);
            }

    	    function save(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
				if ($ctrl.item.comp.sku.code=== $ctrl.item.tab.data.sku.code){
                	toastr.error("BOM商品不能选择以自己为构件!", "错误");
                	return
				}
                var temp = {
                    skuCode: $ctrl.item.comp.sku.code,
                    skuName: $ctrl.item.comp.sku.name,
                    ownerCode: $ctrl.item.tab.data.sku.ownerCode,
                    bomCode: $ctrl.item.tab.data.sku.bom.bomCode,
                    ownerName: $ctrl.item.tab.data.sku.ownerName,
                    compCode: $ctrl.item.comp.compCode,
                    compName: $ctrl.item.comp.compName,
                    compPriority: $ctrl.item.comp.compPriority,
                    compQty: $ctrl.item.comp.compQty,
                    processStep: $ctrl.item.comp.processStep,
                    processStepName: $ctrl.item.comp.processStepName,
                    qty: $ctrl.item.comp.qty
                };
                Sku.saveComp({}, temp, function (resp) {
                    if (resp.content) {
                        toastr.success("新增成功", "成功");
                        $ctrl.item.tab.tableCompParams.reload();
                        $uibModalInstance.close($ctrl.item);
                    }
                });
            }

    	    function update(form) {
                console.log("更新数据库");
                console.info($ctrl.item.comp);
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                $ctrl.item.comp.skuCode = $ctrl.item.comp.sku.code;
                $ctrl.item.comp.skuName = $ctrl.item.comp.sku.name;
                Sku.updateComp({}, $ctrl.item.comp, function (resp) {
                    if (resp.content) {
                        toastr.success("修改成功", "成功");
                        $ctrl.item.tab.tableCompParams.reload();
                    }
                });
                $ctrl.item.tab.tableCompParams.reload();
                $uibModalInstance.close($ctrl.item);
            }
    	}

    	//添加BOM商品
        function saveBom(t,form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
    		console.log("保存BOM");
    		console.info(t.data.sku.bom);
    		var temp = {
                skuCode :t.data.sku.code,
                skuName :t.data.sku.name,
                ownerCode :t.data.sku.ownerCode,
                ownerName :t.data.sku.ownerName,
                bomCode :t.data.sku.bom.bomCode,
                baseQty :t.data.sku.bom.baseQty,
                type :t.data.sku.bom.type,
                description :t.data.sku.bom.description
			};
    		Sku.saveBom({},temp,function (resp) {
				if (resp.content){
					toastr.success("添加BOM成功","成功");
					t.newBom = false;
				}
            })
        }
		//更新BOM商品
        function updateBom(t,form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            Sku.updateBom({},t.data.sku.bom,function (resp) {
				if (resp.content){
                    toastr.success("更新BOM成功","成功");
				}
            })
		}
		//删除BOM商品
    }
})();
