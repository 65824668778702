(function () {
    'use strict';

    angular.module('imessageModule')
        .factory('iMessageMessageLog', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/messagelog', {}, {
                'getMessageLogList': {
                    url: iServiceConfig.imessageHost + '/web/messagelog/getMessageLogList',
                    method: 'GET'
                }
            });
        }]);
})();