(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('WmsCarrier', WmsCarrier);

    WmsCarrier.$inject = ['$resource', 'iServiceConfig'];

    function WmsCarrier ($resource, iServiceConfig) {
        var service = $resource('web/carrier', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/carrier/list", method: 'POST'},
            'saveCarrier': {url: iServiceConfig.idataHost + "/web/carrier/saveCarrier", method: 'POST'},
            'showCarrier': {url: iServiceConfig.idataHost + "/web/carrier/showCarrier", method: 'POST'},
            'deleteCarrier': {url: iServiceConfig.idataHost + "/web/carrier/deleteCarrier", method: 'POST'},
            'validateCode': {url: iServiceConfig.idataHost + "/web/carrier/validateCode", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/carrier/enable", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/carrier/disable", method: 'POST'},
        });
        return service;
    }
})();
