(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhOwner', Owner);

    Owner.$inject = ['$resource', 'iServiceConfig'];

    function Owner ($resource, iServiceConfig) {
        var service = $resource('web/owner', {}, {
            'ownerList': { url: iServiceConfig.iwhDataHost + "/web/owner/ownerList", method: 'POST'},
            'ownerUpdate': { url: iServiceConfig.iwhDataHost + "/web/owner/ownerUpdate", method: 'POST'},
            'ownerDetail': { url: iServiceConfig.iwhDataHost + "/web/owner/ownerDetail", method: 'POST'},
            'ownerLocationList': {url: iServiceConfig.iwhDataHost + "/web/owner/ownerLocationList", method: 'POST'},
            'ownerLocationDelete': {url: iServiceConfig.iwhDataHost + "/web/owner/ownerLocationDelete", method: 'POST'},
            'ownerLocationSave': {url: iServiceConfig.iwhDataHost + "/web/owner/ownerLocationSave", method: 'POST'},
            'ownerZoneList': {url: iServiceConfig.iwhDataHost + "/web/owner/ownerZoneList", method: 'POST'},
            'ownerZoneDelete': {url: iServiceConfig.iwhDataHost + "/web/owner/ownerZoneDelete", method: 'POST'},
            'ownerZoneSave': {url: iServiceConfig.iwhDataHost + "/web/owner/ownerZoneSave", method: 'POST'},
            'getOwnerList' : {url: iServiceConfig.iwhDataHost + "/web/owner/getOwnerList", method: 'POST'}
        });

        return service;
    }
})();
