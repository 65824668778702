(function() {
    'use strict';

    angular.module('ireportModule')
        .controller('RptDataSourceController', RptDataSourceController);

    RptDataSourceController.$inject = ['RptDataSource', 'NgTableParams','$uibModal', '$q','NgSelect','$timeout'];

    function RptDataSourceController(RptDataSource, NgTableParams, $uibModal, $q, NgSelect, $timeout) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'新建'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.enable = enable;
        vm.disable = disable;
        vm.active = 0;
        vm.update = update;
        
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return RptDataSource.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });
        function showError(resp) {
        	if(resp.status === 'ERROR') {
        		alert(resp.errorMsg);
        		return true;
        	} 
        	return false;
        }
        function enable(datasource) {
        	RptDataSource.enable({}, {id: datasource.id}, function(resp) {
        		if(showError(resp)) {
        			return;
        		}
                vm.search();
            });
        }

        function disable(datasource) {
        	RptDataSource.disable({}, {id: datasource.id}, function(result) {
        		if(showError(result)) {
        			return;
        		}
                vm.search();
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function edit(row) {
        	RptDataSource.detail({}, {id: row.id}, function(result) {
        		if(showError(result)) {
        			return;
        		}
        		var entity = {
                        heading: '编辑'+row.code,
                        contentId: "datasource-edit.html",
                        data: result.content,
                        roles: [],
                        multiSelect: new NgSelect({
                        	allList:result.content.nodes,
                        	list:result.content.nodeIds,
                            config:{
                                meta:{
                                    label:'name',
                                    id:'id'
                                }
                            }
                        })
                    };
                vm.tabs.push(entity);
                $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
            });
        }

        function create() {
        	RptDataSource.nodeList({}, {}, function(result) {
        		if(showError(result)) {
        			return;
        		}
        		console.log(result.content);
        		var entity = {
                        heading: '创建',
                        contentId: "datasource-edit.html",
                        data: {
                            id: "",
        	            	tenantId: "",
        	            	code: "",
        	            	name: "",
        	            	jdbcUrl: "",
        	            	driverClassName: "",
        	            	userName: "",
        	            	password: "",
        	            	maxActive: 20,
        	            	minIdle: 5,
        	            	validationSql: "",
        	            	status: 0,
        	            	nodeIds:[]
                        },
                        active: true,
                        multiSelect: new NgSelect({
                        	allList:result.content,
                            list:[],
                            config:{
                                meta:{
                                    label:'name',
                                    id:'id'
                                }
                            }
                        })
                    };

                    vm.tabs.push(entity);
                    $timeout(function(){
    	                vm.active = vm.tabs.length;
    	            },0);
            });
        }

        function update(tab, idx, form) {
            var datasource = tab.data;
            var selectedObjs = tab.multiSelect.getSelected();
            datasource.nodeIds = [];
            angular.forEach(selectedObjs, function(data) {
            	datasource.nodeIds.push(data.id);
            });
            console.log(datasource);
            form.passwordUnmatch  = "";

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            RptDataSource.save({}, datasource, function(result) {
            	if(showError(result)) {
        			return;
        		}
            	vm.search();
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
    }
})();
