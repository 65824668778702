(function () {
    'use strict';

    angular.module('iergateModule')
        .controller('ErgateAppCacheController',
            ['ErgateAppCache', 'NgTableParams', '$timeout', 'toastr', 'iServiceConfig', ErgateAppCacheController]);

    function ErgateAppCacheController(ErgateAppCache, NgTableParams, $timeout, toastr, iServiceConfig) {

        var vm = this;

        vm.iergateHost = iServiceConfig.iergateHost;
        vm.getAppModuleList = vm.iergateHost + '/web/appmodule/getAppModuleList';

        vm.warmupLocalOptions = [{
            id: 1,
            title: '是'
        }, {
            id: 0,
            title: '否'
        }];

        vm.warmupRemoteOptions = [{
            id: 1,
            title: '是'
        }, {
            id: 0,
            title: '否'
        }];

        vm.pageTab = {
            tabs: [],
            activeTabIndex: 0,
            removeTab: function (idx) {
                this.tabs.splice(idx, 1);
            },
            addTab: function (tab) {
                var that = this;
                that.tabs.push(tab);
                $timeout(function () {
                    if (tab.autoActive) {
                        that.activeTabIndex = that.tabs.length;
                    }
                }, 0);
            }
        };

        vm.refresh = function () {
            vm.getTableList.reload().then(function () {
                toastr.success("刷新成功");
            }).catch(function () {
                toastr.error("刷新失败");
            });
        };

        vm.getTableList = new NgTableParams({count: 10}, {
            getData: function (params) {
                var data = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    },
                    params.filter()
                );
                return ErgateAppCache.getAppCacheList(data,
                    {},
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        vm.addAppCache = function() {
            vm.pageTab.addTab({
                heading: '新增应用缓存配置',
                contentId: 'appcache-add.html',
                autoActive: true
            });
        };

        vm.editAppCache = function(row) {
            vm.pageTab.addTab({
                heading: '编辑应用缓存配置',
                contentId: 'appcache-add.html',
                autoActive: true,
                data: {
                    id: row.id,
                    appid: row.appid,
                    ownerAppid: row.ownerAppid,
                    warmUpLocal: row.warmUpLocal,
                    warmUpRemote: row.warmUpRemote,
                    descp: row.descp,
                    warmupEventClass: row.warmupEventClass
                }
            });
        };

        vm.deleteAppCache = function (id) {
            if (id) {
                ErgateAppCache.deleteAppCache({}, {id: id}, function (resp) {
                    if (resp.status === "SUCCESS") {
                        $timeout(function () {
                            vm.refresh();
                        }, 0);
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "删除缓存配置失败！"), "错误");
                    }
                });
            }
        };

        vm.saveOrUpdateAppCache = function (tab, idx, form) {
            var data = tab.data;
            if (!form.$valid) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }

            ErgateAppCache.saveOrUpdateAppCache({}, data, function (resp) {
                if (resp.status === "SUCCESS") {
                    vm.pageTab.removeTab(idx);
                    $timeout(function () {
                        vm.refresh();
                    }, 0);
                } else {
                    toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存失败！"), "错误");
                }
            });
        };

        vm.applayAllWarmup = function() {
            ErgateAppCache.applayWarmup({}, {}, function (resp) {
                if (resp.status === "SUCCESS") {
                    $timeout(function () {
                        vm.refresh();
                    }, 0);
                } else {
                    toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存失败！"), "错误");
                }
            });
        }

        vm.applayWarmup = function(id) {
            if (id) {
                ErgateAppCache.applayWarmup({}, {id: id}, function (resp) {
                    if (resp.status === "SUCCESS") {
                        $timeout(function () {
                            vm.refresh();
                        }, 0);
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存失败！"), "错误");
                    }
                });
            }
        }
    }
})();

