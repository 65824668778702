(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleTrialApply', UserModuleTrialApply);

    UserModuleTrialApply.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleTrialApply ($resource, iServiceConfig) {
        var service = $resource('web/apply', {}, {
            'list': { url: iServiceConfig.iuserHost + "/web/apply/list", method: 'POST'},
            'save': {url: iServiceConfig.iuserHost + "/web/apply/save", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/apply/detail", method: 'POST'},
            'update': {url: iServiceConfig.iuserHost + "/web/apply/update", method: 'POST'}
        });

        return service;
    }
})();
