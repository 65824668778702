(function () {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('iEptRequisitionOutDetail', iEptRequisitionOutDetail);

    iEptRequisitionOutDetail.$inject = ['$resource', 'iServiceConfig'];

    function iEptRequisitionOutDetail($resource, iServiceConfig) {
        var service = $resource('web/requisitionOutDetail', {}, {
            'search': {url: iServiceConfig.ienterpriseHost + "/web/requisitionOutDetail/list", method: 'POST'}
        });

        return service;
    }
})();
