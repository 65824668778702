(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('LockInventory', LockInventory);

    LockInventory.$inject = ['$resource', 'iServiceConfig'];

    function LockInventory ($resource, iServiceConfig) {
        var service = $resource('web/lockInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/list", method: 'POST'},
            'save': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/save", method: 'POST'},
            'detail': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/detail", method: 'POST'},
            'lineDetail': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/lineDetail", method: 'POST'},
            'lock': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/lock", method: 'POST'},
            'thaw': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/thaw", method: 'POST'},
            'close': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/close", method: 'POST'},
            'cancel': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/cancel", method: 'POST'},
            'addDetail': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/addDetail", method: 'POST'},
            'deleteDetail': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/deleteDetail", method: 'POST'},
            'cancelDetail': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/cancelDetail", method: 'POST'},
            'lockNum': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/lockNum", method: 'GET'},
            'queryDetail': { url: iServiceConfig.iwhInventoryHost + "/web/lockInventory/queryDetail", method: 'POST'}

        });
        return service;
    }
})();
