(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('BomInventoryQueryController', BomInventoryQueryController);

    BomInventoryQueryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'BomInventory', 'toastr', '$uibModal'];

    function BomInventoryQueryController ($rootScope, $timeout,NgTableParams, enums, BomInventory, toastr,  $uibModal) {
        var vm = this;
        vm.queryParam = {ownerCode:"",skuCodes:[]}
        vm.skuBomStatus = enums('data.SkuBomStatus');
        vm.skuBomType = enums('data.SkuBomType');
        
        vm.search = search;

        vm.tableParams = new NgTableParams({
        	httpParams: {ownerCode:vm.queryParam.ownerCode, skuCodes:vm.queryParam.skuCodes}
        },{
        	getData: function(params) {
        		this.httpParams = {ownerCode:vm.queryParam.ownerCode, skuCodes:vm.queryParam.skuCodes};
                var postParams = angular.merge({pn: params.page(), ps: params.count(),ownerCode:vm.queryParam.ownerCode,skuCodes:vm.queryParam.skuCodes}, params.filter());
                console.log(postParams);
                return BomInventory.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        
        vm.open = function(skuBom) {
        	var modalInstance = $uibModal.open({
                templateUrl: "bomInvDetail.html",
                controller: ctrl,
                controllerAs: '$ctrl',
                size: 'xl',
                resolve: {
                    item:function(){
                        return {skuCode: skuBom.skuCode, skuName: skuBom.skuName};
                    }
                }
            });
            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.bomCompDetailTableParams = new NgTableParams({},{
                    counts:[],getData: function(params) {
                    	params.total(0);
                        return skuBom.invComps;
                    }
                });
                
                $ctrl.bomOrignCompDetailTableParams = new NgTableParams({},{
                    counts:[],getData: function(params) {
                    	params.total(0);
                        return skuBom.orignComps;
                    }
                });
                
                $ctrl.cancel = cancel;

                function cancel() {
                    modalInstance.close();
                }
            }
        };
        
        function search() {
            vm.tableParams.reload();
        };
    }
})();
