(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleOrganzation', UserModuleOrganzation);

    UserModuleOrganzation.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleOrganzation ($resource, iServiceConfig) {
        var service = $resource('web/organization', {}, {
            'save': {url: iServiceConfig.iuserHost + "/web/organization/save", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/organization/detail", method: 'POST'},
            'treeData': {url: iServiceConfig.iuserHost + "/web/organization/tree", method: 'GET'}
        });

        return service;
    }
})();
