(function () {
    'use strict';
    var app = angular.module('iwh-shipModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('shipQueryBySku', {
            parent: 'main',
            url: 'iwh-ship/shipQueryBySku',
            data: {
                'pageTitle': '发货查询【商品】'
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-ship-module/biz/query/sku.html',
                    controller: 'ShipQueryBySkuController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

