(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleOrganzationController', UserModuleOrganzationController);

    UserModuleOrganzationController.$inject = ['UserModuleUser', 'UserModuleOrganzation','$window','$uibModal','$scope','NgTree','NgSelect','$timeout', 'toastr'];

    function UserModuleOrganzationController(UserModuleUser, UserModuleOrganzation, $window, $uibModal, $scope, NgTree, NgSelect, $timeout, toastr) {
		var vm = this;
		vm.modalInstance = null;
		vm.addNode = {};
		vm.tabs = [];
		vm.curNode = {};
		vm.type = [ {
						id : 10,
						title : "公司"
					}, {
						id : 20,
						title : "部门"
					} ];
		vm.employeeList = [];
		vm.selectNode = selectNode;
		vm.contentId = "noSelect.html";
		vm.createChildOrg = createChildOrg;
		vm.createSameOrg = createSameOrg;
		vm.saveOrgz = saveOrgz;
		vm.editeOrganization = editeOrganization;
		vm.createOrganization = createOrganization;
		
		vm.refreshTree = refreshTree;

		vm.addChild = addChild;
		vm.getEmployeeId = function(obj){
			vm.curNode.organizationData.empId = obj.id;
			vm.curNode.organizationData.empNum = obj.empNum;
			vm.curNode.organizationData.empName = obj.name;
		};
		function addChild(node) {
			if (node !== null) {
				vm.tree.addNode(node);
			}
		}

		vm.tree = new NgTree({
			data : [],
			config : {
				meta : {
					titleField : 'title',
					parentIdField : 'parentId',
					nodeIdField : 'nodeId',
					childField : 'children',
				}
			}
		});
		
		function refreshTree() {
			UserModuleOrganzation.treeData({}, {}, function(resp) {
				if (resp.content) {
					vm.tree.data = resp.content.nodes;
					console.log(vm.tree.data);
				}
			});
		}
		
		
		function selectNode(node) {
			console.log(node);
			vm.curNode = node;
			vm.editeOrganization(node);
		}

		function editeOrganization(node) {
			UserModuleOrganzation.detail({}, {
				id : node.data.id
				}, function(resp) {
					vm.contentId = "editeOrganization.html";
					vm.curNode.organizationData = resp.content;
				});
		}
		function saveOrgz(curNode) {
			var temp = save(curNode);
			temp.then(function(resp) {
				vm.curNode.title = resp.content.name;
				vm.curNode.data = resp.content;
				vm.tree.updateNode(vm.curNode, vm.curNode);
			});
		}

		function save(curNode) {
			if(!curNode.organizationData.type){
				toastr.warning("请选择类型！");
				return;
			}
			return UserModuleOrganzation.save({}, curNode.organizationData).$promise
					.then(function(resp) {
						return resp;
					}, function(resp) {
						return resp.data;
					});
		}
		
		function createSameOrg() {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'creatSameOrg.html',
				controller : createSameOrgModle,
				controllerAs : '$ctrl',
				resolve : {}
			});
		}
		createSameOrgModle.$inject = [ '$uibModalInstance' ];
		function createSameOrgModle($uibModalInstance) {
			var $ctrl = this;
			$ctrl.ok = function(){
				$uibModalInstance.close();
			};
			$ctrl.response = {};
			$ctrl.type = vm.type;
			$ctrl.childNode = {};
			$ctrl.childNode.organizationData = {};
			$ctrl.employeeList = vm.employeeList;
			$ctrl.getEmployeeId = function(obj){
				$ctrl.childNode.organizationData.empId = obj.id;
				$ctrl.childNode.organizationData.empNum = obj.empNum;
				$ctrl.childNode.organizationData.empName = obj.name;
				console.log(obj);
			};
			$ctrl.childNode.organizationData.parentOrgId = vm.curNode.organizationData.parentOrgId;
			$ctrl.childNode.organizationData.tenantId = vm.curNode.organizationData.tenantId;
			$ctrl.childNode.organizationData.tenantName = vm.curNode.organizationData.tenantName;
			$ctrl.childNode.organizationData.parentOrganizationName = vm.curNode.organizationData.parentOrganizationName;
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			};
			$ctrl.submitOrg = function(childNode) {
				var temp = save(childNode);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						var orgData = resp.content;
						var nodeData = {title : orgData.name,
										parentId : orgData.parentOrgId,
										nodeId : orgData.id,
										children : [],
										data: orgData};
						vm.tree.addNode(nodeData);
						toastr.success("添加组织成功", '操作结果');
						$ctrl.ok();
					}
				});
			};
		}
		function createChildOrg() {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'creatChildOrg.html',
				controller : createChildOrgModle,
				controllerAs : '$ctrl',
				resolve : {}
			});
		}
		createChildOrgModle.$inject = [ '$uibModalInstance' ];
		function createChildOrgModle($uibModalInstance) {
			var $ctrl = this;
			$ctrl.ok = function(){
				$uibModalInstance.close();
			};
			$ctrl.childNode = {};
			$ctrl.employeeList = vm.employeeList;
			$ctrl.childNode.organizationData = {};
			$ctrl.getEmployeeId = function(obj){
				$ctrl.childNode.organizationData.empId = obj.id;
				$ctrl.childNode.organizationData.empNum = obj.empNum;
				$ctrl.childNode.organizationData.empName = obj.name;
				console.log(obj);
			};
			$ctrl.type = vm.type;
			$ctrl.childNode.organizationData.parentOrgId = vm.curNode.organizationData.id;
			$ctrl.childNode.organizationData.tenantId = vm.curNode.organizationData.tenantId;
			$ctrl.childNode.organizationData.tenantName = vm.curNode.organizationData.tenantName;
			$ctrl.childNode.organizationData.parentOrganizationName = vm.curNode.organizationData.name;
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			};
			$ctrl.submitOrg = function(childNode) {
				console.log(childNode);
				var temp = save(childNode);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						var orgData = resp.content;
						var nodeData = {title : orgData.name,
										parentId : orgData.parentOrgId,
										nodeId : orgData.id,
										children : [],
										data: orgData};
						vm.tree.addNode(nodeData);
						toastr.success("添加组织成功", '操作结果');
						$ctrl.ok();
					}
				});
			};
		}
		function createOrganization() {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'createOrganization.html',
				controller : createOrganizationModle,
				controllerAs : '$ctrl',
				resolve : {}
			});
		}

		createOrganizationModle.$inject = [ '$uibModalInstance' ];
		function createOrganizationModle($uibModalInstance) {
			var $ctrl = this;
			$ctrl.ok = function(){
				$uibModalInstance.close();
			};
			$ctrl.employeeList = vm.employeeList;
			$ctrl.response = {};
			$ctrl.type = vm.type;
			$ctrl.childNode = {};
			$ctrl.childNode.organizationData = {type:10};
			
			$ctrl.getEmployeeId = function(obj){
				$ctrl.childNode.organizationData.empId = obj.id;
				$ctrl.childNode.organizationData.empNum = obj.empNum;
				$ctrl.childNode.organizationData.empName = obj.name;
				console.log(obj);
			};
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			};
			$ctrl.submitOrg = function(childNode) {
				console.log(childNode);
				var temp = save(childNode);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						if(!vm.tree.data || vm.tree.data.length == 0) {
							vm.refreshTree();
						} else {
							var orgData = resp.content;
							var nodeData = {title : orgData.name,
											parentId : orgData.parentOrgId,
											nodeId : orgData.id,
											children : [],
											data: orgData};
							vm.tree.addNode(nodeData);
						}
						toastr.success("添加组织成功", '操作结果');
						$ctrl.ok();
					}
				});
			};
		}
		UserModuleUser.listEmployees({},{},function(re){
        	vm.employeeList = re.content;
        });
		vm.refreshTree();
		
	}
})();
