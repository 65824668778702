(function () {
    'use strict';

    angular.module('idashboardModule')
        .controller('TDLocController', TDLocController);
    TDLocController.$inject = ['toastr'];
    function TDLocController(toastr) {
        var vm = this;

        vm.hasLocked=true;             //当前库位的球球是否被锁定不会滚动

        vm.isLocked=[
            {
                locked:false,
                title:'不固定'
            },
            {
                locked:true,
                title:'固定'
            }
        ];

        //模拟接口返回的最原始的数据
        vm.data=[
            {
                name:'钢管1',
                row:1,
                col:1,
            },
            {
                name:'钢管2',
                row:1,
                col:2,
            },
            {
                name:'钢管2',
                row:2,
                col:1,
            },
            {
                name:'钢管3',
                row:1,
                col:3,
            },
            {
                name:'钢管3',
                row:1,
                col:4,
            },
            {
                name:'钢管3',
                row:1,
                col:5,
            },
            {
                name:'钢管3',
                row:1,
                col:6,
            },
            {
                name:'钢管3',
                row:1,
                col:7,
            },
            // {
            //     name:'钢管4',
            //     row:1,
            //     col:5,
            // },
            // {
            //     name:'钢管4',
            //     row:1,
            //     col:6,
            // },
            // {
            //     name:'钢管5',
            //     row:1,
            //     col:7,
            // },
            // {
            //     name:'钢管6',
            //     row:2,
            //     col:3,
            // },

        ];

        // 这个方法用来储存每个圆圈对象
        function Circle(x, y, radius, color) {
            this.x = x;
            this.y = y;
            this.radius = radius;
            this.color = color;
            this.isSelected = false;
        }

        // 保存画布上所有的圆圈
        var circles = [];
        var canvas;
        var context;
        canvas = document.getElementById("canvas");
        context = canvas.getContext("2d");
        //canvas的一些事件绑定
        canvas.onmousedown = canvasClick;
        canvas.onmouseup = stopDragging;
        canvas.onmouseout = stopDragging;
        // canvas.onmousemove = dragCircle;

        //园的一些基本属性
        var radius=30;                  //半径
        var x=60,y=canvas.height-30;    //画园的起点

        function initalCircles() {
            circles=[];
            //分成三排生成
            //底层
            for(var m=0;m<3;m++){
                for(var i=0;i<7;i++){
                    circles.push({
                        x:x+radius*i*2,
                        y:y-200*m,
                        radius:radius,
                        isSelected:false,
                        color:'transparent',
                        dotted:true,
                        row:1+m*3,
                        col:i+1
                    });
                }
                //中层
                for(var j=0;j<6;j++){
                    circles.push({
                        x:x+radius*j*2+radius,
                        y:(y-radius*2+radius/4)-200*m,
                        radius:radius,
                        isSelected:false,
                        color:'transparent',
                        dotted:true,
                        row:2+m*3,
                        col:j+1
                    });
                }
                //高层
                for(var k=0;k<5;k++){
                    circles.push({
                        x:x+radius*k*2+radius*2,
                        y:(y-radius*4+radius/2)-200*m,
                        radius:radius,
                        isSelected:false,
                        color:'transparent',
                        dotted:true,
                        row:3+m*3,
                        col:k+1
                    });
                }
                vm.data.forEach(function (v) {
                    if(v.row===1*(m+1)){
                        circles[v.col-1].dotted=false;
                        circles[v.col-1].color='orange';
                    }else if(v.row===2*(m+1)){
                        circles[6+v.col].dotted=false;
                        circles[6+v.col].color='orange';
                    }else if(v.row===3*(m+1)){
                        circles[12+v.col].dotted=false;
                        circles[12+v.col].color='orange';
                    }
                });
            }
            console.log(circles)
            drawCircles();
        }
        initalCircles();

        //选中要移动的某个球面
        vm.chooseMove=chooseMove;
        //确认移动到该位置
        vm.sureMove=sureMove;
        //取消移动
        vm.cancelMove=cancelMove;

        var row='',col='';            //用来存放选中的和要移动到位置的index

        function chooseMove() {
            for(var i=0;i<circles.length;i++){
                if(circles[i].isSelected && circles[i].color==='transparent'){
                    resetCircles();
                    toastr.error('请选择有商品的位置');
                    return;
                }
            }
            //选中移动根据情况不同有两套解决方案
            if(vm.hasLocked){
                //所有球球都被固定时
                var selectCircle='';
                circles.forEach(function (v) {
                    if(v.isSelected){
                        selectCircle=v;
                    }
                });
                if(selectCircle.row===3){
                    //表示选中的圈在第三行，则无需顾虑，可以随意被选中
                }else{
                    //如果不在第三行，因为球球是被固定的，所以只需要关心该球球的左上和右上方是否有球
                    var nowRow=selectCircle.row,
                        nowCol=selectCircle.col,
                        hasCircle=false;
                    vm.data.forEach(function (v) {
                        if(v.row===nowRow+1 && v.col===nowCol){
                            hasCircle=true;
                        }else if(v.row===nowRow+1 && v.col===nowCol-1){
                            hasCircle=true;
                        }
                    });
                    if(hasCircle){
                        //表示上方有球，则该球无法移动
                        toastr.error('该商品无法移动,请选择其他商品');
                        circles.forEach(function (v) {
                            v.isSelected=false;
                        });
                        drawCircles();
                    }else{
                        row='';
                        col='';
                        circles.forEach(function (v) {
                            if(v.isSelected){
                                row=v.row;
                                col=v.col;
                                v.color='red';
                            }
                        });
                        drawCircles();
                    }
                }
            }else{
                //所有球球都松散时,必须要上一排的球球都没有了才能被选中并移动
                var selectedCircle='';
                circles.forEach(function (v) {
                    if(v.isSelected){
                        selectedCircle=v;
                    }
                });
                if(selectedCircle.row===3){
                    //表示选中的圈在第三行，则无需顾虑，可以随意被选中
                }else{
                    var selectRow=selectedCircle.row,
                        selectCol=selectedCircle.col,
                        hadCircle=false;
                    vm.data.forEach(function (v) {
                        if(v.row===selectRow+1){
                            hadCircle=true;
                        }
                    });
                    if(hadCircle){
                        toastr.error('该商品无法移动,请选择其他商品');
                        circles.forEach(function (v) {
                            v.isSelected=false;
                        });
                        drawCircles();
                    }else{
                        row='';
                        col='';
                        circles.forEach(function (v) {
                            if(v.isSelected){
                                row=v.row;
                                col=v.col;
                                v.color='red';
                            }
                        })
                        drawCircles();
                    }
                }
            }
        }

        function sureMove() {
            if(!row || !col){
                resetCircles();
                toastr.error('请选择一个要移动的商品');
                return;
            }
            if(vm.hasLocked){
                //如果是被锁住的，只需要考虑放到的位置左下和右下是否有球球
                var chooseCircle='';
                circles.forEach(function (v) {
                    if(v.isSelected){
                        chooseCircle=v;
                    }
                });
                if(chooseCircle.row===1){
                    //表示在底层，可以随便放
                    var chooseRow='',chooseCol='';
                    circles.forEach(function (v) {
                        if(v.isSelected){
                            chooseRow=v.row;
                            chooseCol=v.col;
                        }
                    });
                    vm.data.forEach(function (v) {
                        if(row===v.row && col===v.col){
                            v.row=chooseRow;
                            v.col=chooseCol;
                        }
                    });
                    initalCircles();
                    row='';
                    col='';
                }else if(chooseCircle.row>1){
                    //移动到的位置不在最底层,则要进行考虑
                    //还要考虑这个位置移开后带来的影响
                    var chooseRows='',chooseCols='';
                    var allowMove=0;
                    circles.forEach(function (v) {
                        if(v.isSelected){
                            chooseRows=v.row;
                            chooseCols=v.col;
                        }
                        if(v.row===row && v.col===col){
                            v.color='transparent';
                            v.speHandle=true;
                        }
                    });

                    circles.forEach(function (v) {
                        if(v.row===chooseRows-1 && v.col===chooseCols && v.color!=='transparent'){
                            allowMove++;
                        }
                        if(v.row===chooseRows-1 && v.col===chooseCols+1 && v.color!=='transparent'){
                            allowMove++;
                        }
                    });
                    //判断allowMove是否加了两次
                    if(allowMove===2){
                        //代表选中的位置下方有两个球，可以移动
                        vm.data.forEach(function (v) {
                            if(row===v.row && col===v.col){
                                v.row=chooseRows;
                                v.col=chooseCols;
                            }
                        });
                        initalCircles();
                        row='';
                        col='';
                    }else{
                        //下方没有两个球，不能移动，报错提示
                        circles.forEach(function (v) {
                            v.isSelected=false;
                            if(v.speHandle){
                                v.speHandle=false;
                                v.color='orange';
                            }
                        });
                        initalCircles();
                        toastr.error('不能移动到该位置，请重新选择');
                        row='';
                        col='';
                    }
                }
            }else{
                //球球没被锁住，则要看移动位置的下一层是否是满的
                //是满的，可以移动，不是满的则报错进行提示无法移动(不然会滚动)
                var chooseCircle1='';
                circles.forEach(function (v) {
                    if(v.isSelected){
                        chooseCircle1=v;
                    }
                });
                //如果选的落点位置是第一行，那么可以随便放
                if(chooseCircle1.row===1){
                    var chooseRow1='',chooseCol1='';
                    chooseRow1=chooseCircle1.row;
                    chooseCol1=chooseCircle1.col;
                    vm.data.forEach(function (v) {
                        if(row===v.row && col===v.col){
                            v.row=chooseRow1;
                            v.col=chooseCol1;
                        }
                    });
                    initalCircles();
                    row='';
                    col='';
                }else{
                    //代表不是第一行，则要考虑更多情况
                    var chooseRow1s='',chooseCol1s='';
                    var canMove=true;
                    chooseRow1s=chooseCircle1.row;
                    chooseCol1s=chooseCircle1.col;
                    circles.forEach(function (v) {
                        if(v.row===chooseRow1s-1 && v.color==='transparent'){
                            //这样代表下一行有空位，则不能移动
                            canMove=false;
                        }
                    });
                    if(canMove){
                        vm.data.forEach(function (v) {
                            if(row===v.row && col===v.col){
                                v.row=chooseRow1s;
                                v.col=chooseCol1s;
                            }
                        });
                        initalCircles();
                        row='';
                        col='';
                    }else{
                        toastr.error('无法移动到该位置');
                        resetCircles();
                        row='';
                        col='';
                    }
                }
            }
        }

        function resetCircles() {
            circles.forEach(function (v) {
                v.isSelected=false;
            });
            initalCircles();
        }

        function cancelMove() {
            row='';
            col='';
            circles.forEach(function (v) {
                v.isSelected=false;
                if(v.color==='red'){
                    v.color='orange';
                }
            });
            drawCircles();
        }

        function drawCircles() {
            // 清除画布，准备绘制
            // console.log(circles);
            context.clearRect(0, 0, canvas.width, canvas.height);
            // 遍历所有圆圈
            for(var i=0; i<circles.length; i++) {
                var circle = circles[i];
                // 绘制圆圈
                context.globalAlpha = 0.85;
                if(circle.dotted){
                    context.setLineDash([8, 4])
                }else{
                    context.setLineDash([8, 0])
                }
                context.beginPath();
                context.arc(circle.x, circle.y, circle.radius, 0, Math.PI*2);
                context.fillStyle = circle.color;
                context.strokeStyle = "black";

                if (circle.isSelected) {
                    context.lineWidth = 4;
                }
                else {
                    context.lineWidth = 2;
                }

                context.fill();
                context.stroke();
            }
        }

        var previousSelectedCircle;

        function canvasClick(e) {
            // 取得画布上被单击的点
            // console.log(canvas.offsetTop,'top')
            // console.log(e,'pageY')

            var clickX = e.pageX - canvas.offsetLeft-144;
            var clickY = e.pageY - canvas.offsetTop-54;

            var isIn=false;

            // 查找被单击的圆圈
            for(var i=circles.length-1; i>=0; i--) {
                var circle = circles[i];
                //使用勾股定理计算这个点与圆心之间的距离
                var distanceFromCenter = Math.sqrt(Math.pow(circle.x - clickX, 2)
                    + Math.pow(circle.y - clickY, 2));
                // 判断这个点是否在圆圈中
                if (distanceFromCenter <= circle.radius) {

                    // 清除之前选择的圆圈
                    if (previousSelectedCircle != null) previousSelectedCircle.isSelected = false;
                    previousSelectedCircle = circle;

                    //选择新圆圈
                    circle.isSelected = true;

                    isIn=true;              //表示鼠标点击的地方在某个园之内

                    // 使圆圈允许拖拽
                    isDragging = true;

                    //更新显示
                    drawCircles();

                    //停止搜索
                    return;
                }
            }

            //如果点的地方不在任何一个园之内，取消所有园的selected状态
            if(!isIn){
                circles.forEach(function (v) {
                    v.isSelected=false;
                });
                drawCircles();
            }
        }

        //在某个范围内生成随机数
        function randomFromTo(from, to) {
            return Math.floor(Math.random() * (to - from + 1) + from);
        }

        var isDragging = false;

        function stopDragging() {
            isDragging = false;
        }

        function dragCircle(e) {
            // 判断圆圈是否开始拖拽
            if (isDragging == true) {
                // 判断拖拽对象是否存在
                if (previousSelectedCircle != null) {
                    // 取得鼠标位置
                    var x = e.pageX - canvas.offsetLeft;
                    var y = e.pageY - canvas.offsetTop;

                    // 将圆圈移动到鼠标位置
                    previousSelectedCircle.x = x;
                    previousSelectedCircle.y = y;

                    // 更新画布
                    drawCircles();
                }
            }
        }



        document.body.onmousewheel=function () {
            console.log(1)
        }



    }
})();


