(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.factory('ModuleUtils', [function () {
        /**
         * 数组去重
         * @param arr 数组
         * @returns {Array} 去重后的数组
         */
        this.uniqueArray = function (arr) {
            var result = [], hash = {};
            for (var i = 0; i < arr.length; i++) {
                if (!hash[arr[i]]) {
                    result.push(arr[i]);
                    hash[arr[i]] = true;
                }
            }
            return result;
        };


        /**
         * 字符串模式数组去重
         * @param str 以某种分隔符分隔组成的数组字符串，如：'abc,def,ghi'
         * @param slt 分隔符
         * @returns {Array} 去重后的数组
         */
        this.uniqueArrayString = function (str, slt) {
            if (!slt) slt = ',';
            var arr = str.split(slt);
            return this.uniqueArray(arr);
        };

        /**
         * 根据自定义过滤器规则获取匹配项的第一个
         * @param arr 需要过滤的数组
         * @param call 匹配规则回调函数
         * @returns {object|null} 第一个匹配项，无匹配则返回null
         */
        this.filterOne = function (arr, call) {
            return arr instanceof Array && typeof call === 'function' && arr.filter(call)[0] || null;
        };

        return this;
    }]);
})();
