(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('NosoPickByScanSkuController', NosoPickByScanSkuController);

    NosoPickByScanSkuController.$inject = ['$rootScope','enums','$state', '$timeout','ApiURI','iServiceConfig', 'ShipOrder', 'ShipOrderDetail', '$filter', '$uibModal', 'toastr','BaseCode'];

    function NosoPickByScanSkuController ($rootScope,enums,$state, $timeout,ApiURI,iServiceConfig, ShipOrder, ShipOrderDetail, $filter, $uibModal, toastr,BaseCode) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.soDetailStatus = enums('ship.SODetailStatus');
        vm.baseCode = BaseCode.codes([
                                      {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'}
                                  ]);
        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });
        vm.isNeedConfigParam = true;
        vm.isNeedRemark = false;
        vm.isNeedAmount = true;
        vm.isNeedBatchQty = false;
        vm.isDeliveryLack = false;
        vm.isNeedConfigParam
        if ($state.params.isNeedConfigParam && $state.params.isNeedConfigParam <= 0) {
        	vm.isNeedConfigParam = false;
        } 
        if ($state.params.isNeedRemark && $state.params.isNeedRemark > 0) {
        	vm.isNeedRemark = true;
        }
        if ($state.params.isNeedAmount && $state.params.isNeedAmount <= 0) {
        	vm.isNeedAmount = false;
        }
        if ($state.params.isNeedBatchQty && $state.params.isNeedBatchQty > 0) {
        	vm.isNeedBatchQty = true;
        }
        if ($state.params.isDeliveryLack && $state.params.isDeliveryLack <= 0) {
            vm.isDeliveryLack = false;
        }
        vm.getRowSkuDetail = function(orderLine, index) {
        	var data = {
        		lineNum: vm.shipOrder.details.length - index,
        		orderLine: orderLine
        	};
        	return data;
        }
        vm.checkStatus = {
            status: 'SUCCESS',
            message: '扫描成功'
        };
        vm.resetOrder = function() {
        	vm.resetSku();
        	vm.soNum = '';
        	vm.shipOrder = null;
        	jQuery('#soNum').focus();
        }
        vm.resetSku = function() {
        	vm.skuBarcode = '';
        	vm.orderQty = null;
        	if (!vm.isNeedBatchQty) {
        		vm.orderQty = 1;
        	} 
        	vm.skuRemark = '';
        	vm.orderRemark = '';
        	vm.skuObject = null;
        	jQuery('#skuBarcodeManual').focus();
        };
        vm.openCreateOrderWindow = function($event) {
        	vm.resetOrder();
        	$uibModal.open({
                templateUrl: 'addSo.html',
                controller: createShipOrderCtrl,
                controllerAs: '$ctrl',
            })
        };
        vm.openInitParamWindow = openInitParamWindow;
        function openInitParamWindow() {
            $uibModal.open({
                templateUrl: 'initParam.html',
                controller: openInitParamCtrl,
                controllerAs: '$ctrl',
            })
        }
        openInitParamCtrl.$inject=['$uibModalInstance','$scope'];
        function openInitParamCtrl($uibModalInstance,$scope) {
            var me=this;
            me.isNeedRemark=vm.isNeedRemark;
            me.isNeedAmount=vm.isNeedAmount;
            me.isNeedBatchQty = vm.isNeedBatchQty;
            me.isDeliveryLack = vm.isDeliveryLack;
            
            me.entryInMainPage=entryInMainPage;
            function entryInMainPage() {
            	vm.isNeedRemark = me.isNeedRemark;
            	vm.isNeedAmount = me.isNeedAmount;
            	vm.isNeedBatchQty = me.isNeedBatchQty;
                vm.isDeliveryLack = me.isDeliveryLack;
            	$uibModalInstance.dismiss('close');
            	vm.resetOrder();
            }
        }
        
        vm.openConfirmSubmitWindow = openConfirmSubmitWindow;
        function openConfirmSubmitWindow() {
        	if (!vm.shipOrder) {
        		openModal('error-confirm.html', {message: '当前没有出库订单'}, 'skuBarcodeManual');
                return;
        	}
        	if (!vm.shipOrder.details || vm.shipOrder.details.length <= 0) {
        		openModal('error-confirm.html', {message: '当前出库订单没有需要出库的明细商品'}, 'skuBarcodeManual');
                return;
        	}
            $uibModal.open({
                templateUrl: 'confirmSubmit.html',
                controller: openConfirmSubmitCtrl,
                controllerAs: '$ctrl',
            })
        }
        openConfirmSubmitCtrl.$inject=['$uibModalInstance','$scope'];
        function openConfirmSubmitCtrl($uibModalInstance,$scope) {
            var me=this;
            me.submitProcessing = false;
            me.isNeedAmount=vm.isNeedAmount;
            me.shipOrder = {
            		soNum: vm.shipOrder.soNum,
            		skuCount: vm.shipOrder.skuCount,
            		orderQty: vm.shipOrder.orderQty,
            		totalAmount: vm.shipOrder.totalAmount,
            		paidAmount: vm.shipOrder.paidAmount,
            		discountAmount: vm.shipOrder.discountAmount,
            		needPayAmount: vm.shipOrder.needPayAmount,
            		remark: vm.shipOrder.remark,
            		needAmount: me.isNeedAmount
            }
            me.submitOrder=submitOrder;
            me.cancel=cancel;
            me.submitShipOrderForOut = submitShipOrderForOut
            me.proccessDatas = [];
            
            function submitOrder() {
            	me.submitProcessing = true;
            	me.submitShipOrderForOut();
            }
            function cancel() {
            	$uibModalInstance.dismiss('cancel');
            }
            
            function submitShipOrderForOut() {
            	ShipOrder.shipInOneStep({}, me.shipOrder, function (resp) {
            		toastr.success("发货成功！");
            		$uibModalInstance.dismiss('close');
            		getShipOrder(me.shipOrder.soNum);
                }, function(err) {
                	toastr.error(err.data.errorMsg);
                	me.submitProcessing = false;
                });
            };
        }
        
        vm.shipInOneStep = function() {
        	ShipOrder.shipInOneStep({}, {soNum: vm.shipOrder.soNum}, function (resp) {
        		toastr.success("发货成功！");
        		getShipOrder(vm.shipOrder.soNum);
            }, function(err) {
            	toastr.error(err.data.errorMsg);
            });
        }
        
        createShipOrderCtrl.$inject=['$uibModalInstance','$scope'];
        function createShipOrderCtrl($uibModalInstance,$scope) {
            var me=this;
            me.data={};
            
            me.createOrder=createOrder;
            me.cancel = cancel;
            
            function createOrder() {
            	if (!me.data.type) {
            		toastr.error("请订单类型");
            		return;
            	}
            	if (!me.data.ownerObject) {
            		toastr.error("请选择货主");
            		return;
            	}
            	if (!me.data.customerObject) {
            		toastr.error("请选择客户");
            		return;
            	}
            	if (!me.data.carrierObject) {
            		me.data.carrierObject = {};
            	}
            	ShipOrder.createFromPick({},{
            		ownerCode: me.data.ownerObject.code,
            		customerCode: me.data.customerObject.code,
            		customerName: me.data.customerObject.name,
            		customerReceiver: me.data.customerObject.receiver,
            		customerReceiverPhone: me.data.customerObject.receiverPhone,
            		customerAddress: me.data.customerObject.address,
            		type: me.data.type,
            		extNum: me.data.extNum,
            		planShipTime: me.data.planShipTime,
            		carrierCode: me.data.carrierObject.code,
            		carrierName: me.data.carrierObject.name,
            		remark: me.data.remark,
                    deliveryLack: vm.isDeliveryLack
                },function (res) {
                	var soNum = res.content;
                	getShipOrder(soNum);
                	$uibModalInstance.dismiss('close');
                },function (err) {
                	toastr.error(err.data.errorMsg);
                    fail();
                });
            }
            function cancel() {
            	$uibModalInstance.dismiss('cancel');
            	jQuery('#soNum').focus();
            	jQuery('#soNum').select();
            }
        }
        
        vm.inputSoNum = inputSoNum;
        vm.inputSkuBarcode = inputSkuBarcode;
        vm.inputOrderQty = inputOrderQty;
        vm.inputSkuRemark = inputSkuRemark;
        vm.inputOrderRemark = inputOrderRemark;
        
        vm.addOrderDetail = function() {
        	if (!vm.shipOrder) {
                openModal('error-confirm.html', {message: '当前没有出库订单'}, 'soNum');
                return;
            }
            if (!vm.skuObject) {
                openModal('error-confirm.html', {message: '请扫描商品条码'}, 'skuBarcodeManual');
                return;
            }
            if (!vm.orderQty || vm.orderQty <= 0) {
                openModal('error-confirm.html', {message: '请输入数量'}, 'orderQtyManual');
                return;
            }
            vm.success();
            var paramData = {
            		soNum: vm.shipOrder.soNum,
            		ownerCode: vm.shipOrder.ownerCode,
            		skuCode: vm.skuObject.code,
            		skuBarcode: vm.skuBarcode,
            		orderQty: vm.orderQty,
            		skuRemark: vm.skuRemark,
            		orderRemark: vm.orderRemark
            };
            ShipOrderDetail.createFromPick({}, paramData, function (resp) {
            	var orderDetail  = resp.content;
                toastr.success("添加成功，商品："+orderDetail.skuCode +", "+orderDetail.skuName);
                success();
                vm.resetSku();
                vm.pushOrderDetail(orderDetail);
                vm.shipOrder.totalAmount = vm.shipOrder.totalAmount + (orderDetail.orderQty * orderDetail.sellPrice);
                vm.shipOrder.needPayAmount = vm.shipOrder.needPayAmount + (orderDetail.orderQty * orderDetail.sellPrice);
            }, function(err) {
                if (err.data && err.data.errorMsg) {
                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcodeManual');
                    fail(resp.data.errorMsg);
                }
            });
        };
        vm.pushOrderDetail = function(orderDetail) {
        	var orderDetails = [];
        	orderDetails.push(orderDetail);
        	if (vm.shipOrder.details) {
            	angular.forEach(vm.shipOrder.details, function(item) {
            		orderDetails.push(item);
            	});
            }
        	vm.shipOrder.details = orderDetails;
        	initShipOrder();
        }
        vm.removeOrderDetail = function(orderDetail) {
        	orderDetail.ids = [orderDetail.id];
        	ShipOrderDetail.delete({}, orderDetail, function (resp) {
                toastr.success("删除成功，商品："+orderDetail.skuCode +", "+orderDetail.skuName);
                success();
                getShipOrder(vm.shipOrder.soNum);
            }, function(err) {
                if (err.data && err.data.errorMsg) {
                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcodeManual');
                    fail(resp.data.errorMsg);
                }
            });
        };
        
        vm.removeOrderDetailPart = function(orderDetail) {
        	if (!orderDetail.cancelQty || orderDetail.cancelQty < 0 || orderDetail.cancelQty > orderDetail.orderQty) {
        		toastr.error("输入的扣减量无效，必须大于 0 ，小于等于 "+orderDetail.orderQty);
        		return;
        	}
        			
        	if (orderDetail.cancelQty && orderDetail.cancelQty == orderDetail.orderQty) {
        		vm.removeOrderDetail(orderDetail);
        		return;
        	} else {
        		var dataParam = angular.copy(orderDetail);
        		dataParam.orderQty =orderDetail.orderQty - orderDetail.cancelQty;
        		
            	ShipOrderDetail.update({}, dataParam, function (resp) {
                    toastr.success("调整数量成功，商品："+orderDetail.skuCode +", "+orderDetail.skuName + ", 扣减数量："+orderDetail.cancelQty);
                    success();
                    getShipOrder(vm.shipOrder.soNum);
                }, function(err) {
                    if (err.data && err.data.errorMsg) {
                        openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcodeManual');
                        fail(resp.data.errorMsg);
                    }
                });
        	}
        };
        
        vm.editOrderDetailPrice = function(orderDetail) {
        	if (!orderDetail.actualSellPrice || orderDetail.actualSellPrice < 0) {
        		toastr.error("输入的价格无效，必须大于 0 ");
        		return;
        	}
        			
        	if (orderDetail.actualSellPrice && orderDetail.actualSellPrice == orderDetail.sellPrice) {
        		orderDetail.actualSellPrice = orderDetail.sellPrice; 
        		orderDetail.editingPrice = false;
        		return;
        	} else {
        		var dataParam = angular.copy(orderDetail);
        		dataParam.sellPrice =orderDetail.actualSellPrice;
        		
            	ShipOrderDetail.update({}, dataParam, function (resp) {
                    toastr.success("调整价格成功，商品："+orderDetail.skuCode +", "+orderDetail.skuName + ", 调整后价格："+dataParam.sellPrice);
                    success();
                    getShipOrder(vm.shipOrder.soNum);
                }, function(err) {
                    if (err.data && err.data.errorMsg) {
                        openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcodeManual');
                        fail(resp.data.errorMsg);
                    }
                });
        	}
        };
        
        vm.cancelShipOrder = function() {
        	if (!vm.shipOrder) {
        		openModal('error-confirm.html', {message: '当前没有出库订单'}, 'skuBarcodeManual');
                return;
        	}
        	var soNums = [vm.shipOrder.soNum];

        	ShipOrder.cancel({}, {soNums: soNums}, function(data) {
            	vm.resetOrder();
                toastr.success("取消成功");
            }, function(err) {
            	openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcodeManual');
            	fail();
            });
        };
        
        vm.success = success;
        vm.fail = fail;
        vm.removeTab = removeTab;
        function inputSoNum($event) {
            if ($event.which === 13) {
                if (!vm.soNum) {
                	jQuery('#soNum').focus();
                    jQuery('#soNum').select();
                } else {
                	getShipOrder(vm.soNum);
                }
            }
        }
        function inputSkuBarcode($event) {
        	if ($event.which === 13) {
                if (!vm.skuBarcode) {
                	jQuery('#skuBarcodeManual').focus();
                    jQuery('#skuBarcodeManual').select();
                } else {
                	getSku();
                }
            }
        }

        function inputOrderQty($event) {
            if ($event.which === 13) {
                if (!vm.orderQty || vm.orderQty <= 0) {
                	jQuery('#orderQtyManual').select();
                    jQuery('#orderQtyManual').select();
                } else {
                	if (vm.isNeedRemark) {
                		jQuery('#skuRemarkManual').select();
                        jQuery('#skuRemarkManual').select();
                	} else {
                		vm.addOrderDetail();
                	}
                }
            }
        }
        
        function inputSkuRemark($event) {
        	if ($event.which === 13) {
        		jQuery('#orderRemarkManual').select();
                jQuery('#orderRemarkManual').select();
            }
        }
        function inputOrderRemark($event) {
            if ($event.which === 13) {
            	vm.addOrderDetail();
            }
        }
        function getShipOrder(soNum, isProcessed) {
        	vm.resetOrder();
        	success();
            ShipOrder.getShipOrder({soNum: soNum}, {}, function(data) {
                if (data.content) {
                	vm.shipOrder = data.content;
                	if (data.content.status < 0) {
                		toastr.warning('出库订单 ' + soNum + ' 已被取消，不能再添加明细');
                		vm.shipOrder.isCancelled = true;
                	}
                	if (data.content.status > 2 && data.content.status < 60) {
                		toastr.warning('出库订单 ' + soNum + ' 已提交执行，不能再添加明细');
                		vm.shipOrder.isProcessed = true;
                	}
                	if (data.content.status >= 60) {
                		vm.shipOrder.isShiped = true;
                	}
                	if (isProcessed) {
                		vm.shipOrder.isProcessed = true;
                	}
                	initShipOrder();
                    $timeout(function () {
                    	jQuery('#skuBarcodeManual').focus();
                        jQuery('#skuBarcodeManual').select();
                    });
                } else {
                	openModal('error-confirm.html', {message: '出库订单 ' + soNum + ' 不存在'}, 'soNum');
                }
            }, function(err) {
            	openModal('error-confirm.html', {message: err.data.errorMsg}, 'soNum');
            	fail();
            });
        }
        
        function initShipOrder() {
        	var orderQty = 0;
    		var skuCount = 0;
    		vm.soNum = vm.shipOrder.soNum;
    		vm.shipOrder.typeName = vm.soTypes[vm.shipOrder.type];
        	if (vm.shipOrder.details) {
        		
        		var skuCodeMap = {};
        		var orderQty = 0;
        		var skuCount = 0;
        		angular.forEach(vm.shipOrder.details, function(item) {
        			orderQty = orderQty + item.orderQty;
        			if (!skuCodeMap[item.skuCode]) {
        				skuCodeMap[item.skuCode] = 1;
        				skuCount = skuCount + 1;
        			}
            	});
        		vm.shipOrder.orderQty = orderQty;
        		vm.shipOrder.skuCount = skuCount;
        	}
        	vm.shipOrder.orderQty = orderQty;
    		vm.shipOrder.skuCount = skuCount;
        }
        
        function getSku() {
        	success();
            ShipOrder.getSkuByBarcode({}, {ownerCode: vm.shipOrder.ownerCode, skuBarcode: vm.skuBarcode}, function(data) {
                if (data.content) {
                	vm.skuObject = data.content;
                	vm.orderQty = 1;
                	if (vm.isNeedBatchQty) {
                		$timeout(function () {
                        	jQuery('#orderQtyManual').focus();
                            jQuery('#orderQtyManual').select();
                        });
                	} else {
                		if (vm.isNeedRemark) {
                    		jQuery('#skuRemarkManual').select();
                            jQuery('#skuRemarkManual').select();
                    	} else {
                    		vm.addOrderDetail();
                    	}
                	}
                } else {
                	openModal('error-confirm.html', {message: '商品条码 ' + vm.skuBarcode + ' 不存在'}, 'skuBarcodeManual');
                }
            }, function(err) {
            	openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcodeManual');
            	fail();
            });
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
        
     // 打开控制弹出框
        function openModal(templateUrl, model, elemId) {
            var paramItem = angular.extend({errorCtrlCmd: vm.errorCtrlCmd}, model);
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return paramItem;
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.execCmd = execCmd;

                function execCmd() {
                	modalInstance.close();
                    vm.success();
                }
            }
        }

        
        vm.success();
        vm.resetOrder();
        if (vm.isNeedConfigParam) {
        	vm.openInitParamWindow();
        }
    }
})();
