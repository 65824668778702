(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('SnInventoryQueryController', SnInventoryQueryController);

    SnInventoryQueryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'SnInventory', 'toastr', '$uibModal'];

    function SnInventoryQueryController ($rootScope, $timeout,NgTableParams, enums, SnInventory, toastr,  $uibModal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.snInventoryStatus = enums('inventory.SnInventoryStatus');
        vm.snInventoryStatusList = vm.snInventoryStatus.list('title', 'id');
        
        vm.search = search;

        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return SnInventory.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        }
    }
})();
