(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('PickRegEndController', PickRegEndController);

    PickRegEndController.$inject = ['$rootScope', '$timeout','PickOrder','enums','toastr'];

    function PickRegEndController ($rootScope, $timeout, PickOrder, enums,toastr) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;
        vm.scanPickerCode = scanPickerCode;
        vm.regEnd = regEnd;

        function scanPickerCode($event) {
            if ($event.which === 13) { // enter key
                if (!vm.registerPicker) {
                    toastr.error("员工编号不能为空");
                    return;
                }

                jQuery('#pickNum').select();
            }
        }

        function regEnd($event) {
            if ($event.which === 13) { // enter key
                var vo = {
                    registerPicker: vm.registerPicker,
                    pickNum: vm.pickNum
                };
                return PickOrder.endPick({}, vo, function(resp) {
                    vm.result = resp.content;

                    return resp.content==="success";
                }, function() {
                    jQuery('#pickNum').select();
                });
            }
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
    }
})();
