(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('MoveTaskExecuteController',
        MoveTaskExecuteController);

    MoveTaskExecuteController.$inject = [ 'MovePlanTask', 'NgTableParams', '$uibModal',
        '$timeout', 'toastr','$state'];

    function MoveTaskExecuteController(MovePlanTask, NgTableParams, $uibModal,
                                $timeout, toastr,$state) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.planCount= null;//移动计划单号，用于整单移动时传入参数
        vm.task =null;
        vm.data = [];
        vm.chooseAll = false;//判断是否全部选中

        vm.search = search;

        vm.removeTab = removeTab;

        vm.formatDateTime =formatDateTime;//日期转换

        vm.move = move;//移动

        vm.moveAll = moveAll;//整单移动

        vm.myKeyup =myKeyup;//监听回车事件

        vm.reload = reload;//重新请求数据

        vm.confirmOp=confirmOp;

        vm.getSelectedObj = getSelectedObj;//获取已经选中的对象

        vm.chooseAllTask = chooseAllTask;//选中或取消选中所有

        vm.getMoveList = getMoveList;//点击每一行加入已选中数组中

        // 通用方法
        function search() {
            vm.reload();
        }

        function removeTab(tabs,idx) {
            tabs.splice(idx, 1);
        }

        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        //移动
        function move(row) {
            var data = [] ;
            if(row){
                data.push(row);
            }else{
                if(angular.isFunction(vm.getSelectedObj)){
                    data = vm.getSelectedObj();
                }
            }
            var vo={
                tasks : data,
                validate : true
            }
            if(angular.isArray(data) && data.length>0){
            	success();
                MovePlanTask.move({},vo,function (resp) {
                }, function(resp) {
                	fail();
                }).$promise.then(function (data) {
                    if(data.content!=null){
                        var s1 = "";
                        var s2="以下任务：";
                        for(var i=0;i<data.content.length;i++){
                            s1+=i+1+'. '+data.content[i]+' ';
                        }
                        console.log(s1);
                        console.log(s2);
                        s2 = s2+s1+" 混放验证未通过,是否强制上架？";

                        vm.confirmOp(s2,function(){
                            vo.validate=false;
                            MovePlanTask.move({},vo,function (resp) {
                            }).$promise.then(function (data) {
                                toastr.success("移动成功", "成功");
                                vm.reload();
                            });
                        });
                    }else {
                        toastr.success("移动成功", "成功");
                        vm.reload();
                    }
                });
            }else{
                toastr.info("请先选择移动任务", "提示");
            }
        }

        //整单移动
        function moveAll() {

            var vo={
                tasks : vm.datas,
                validate : true
            }
            if(angular.isArray(vm.datas) && vm.datas.length>0){
            	success();
                MovePlanTask.move({},vo,function (resp) {
                }, function(resp) {
                	fail();
                }).$promise.then(function (data) {
                    if(data.content!=null){
                        var s1 = "";
                        var s2="以下任务：";
                        for(var i=0;i<data.content.length;i++){
                            s1+=i+1+'. '+data.content[i]+' ';
                        }
                        console.log(s1);
                        console.log(s2);
                        s2 = s2+s1+" 混放验证未通过,是否强制上架？";

                        vm.confirmOp(s2,function(){
                            vo.validate=false;
                            MovePlanTask.move({},vo,function (resp) {
                            }).$promise.then(function (data) {
                                toastr.success("移动成功", "成功");
                                vm.reload();
                            });
                        });
                    }else {
                        toastr.success("移动成功", "成功");
                        vm.reload();
                    }
                });
            }else{
                toastr.info("请先选择移动任务", "提示");
            }
        }

        //监听回车事件
        function myKeyup(e){
            if(e){
                var keycode = window.event?e.keyCode:e.which;
                if(keycode==13 && vm.planCount){
                	success();
                    MovePlanTask.scanMovePlanNum({}, vm.planCount, function(resp) {
                    }, function(resp) {
                    	fail();
                    }).$promise.then(function(data) {
                        vm.datas = data.content.taskList;
                        vm.task = data.content;
                        if(vm.task.remainTask==0){
                            toastr.info("此单剩余任务数量为0","提示");
                        }
                    });
                }
            }else{
                if(vm.planCount){
                	success();
                    MovePlanTask.scanMovePlanNum({}, vm.planCount, function(resp) {
                    }, function(resp) {
                    	fail();
                    }).$promise.then(function(data) {
                        vm.datas = data.content.taskList;
                        vm.task = data.content;
                        if(vm.task.remainTask==0){
                            toastr.info("此单剩余任务数量为0","提示");
                        }
                    });
                }
            }
        }

        //重载数据
        function reload(){
            if(vm.planCount){
            	success();
                MovePlanTask.scanMovePlanNum({}, vm.planCount, function(resp) {
                }, function(resp) {
                	fail();
                }).$promise.then(function(data) {
                    if(!data.content.taskList){
                        toastr.info(data.content,"提示");
                        return;
                    }
                    vm.datas = data.content.taskList;
                    vm.task = data.content;
                    vm.chooseAll = false;
                });
            }
        }

        //获取已选中的对象
        function getSelectedObj() {
            var data = vm.datas?vm.datas:[];
            var temp = [];
            for(var i=0;i<data.length;i++){
                if(data[i]['selected']){
                    temp.push(data[i]);
                }
            }
            return temp;
        }

        //选中或取消选中所有
        function chooseAllTask() {
            for(var i=0;i<vm.datas.length;i++){
                vm.datas[i]['selected'] = vm.chooseAll;
            }
        }

        //选中行
        function getMoveList(row){
            if(row.status === 30){
                return ;
            }
            row.selected = !row.selected;
        }

        // 确认操作封装
        function confirmOp(p,func){
            vm.modalInstance = $uibModal.open({
                templateUrl : 'confirm-op.html',
                controller : confirmOperation,
                controllerAs : '$ctrl',
                resolve : {
                    item : {
                        op:p
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
                return func();
            });
        }
        confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                $uibModalInstance.close(true);
            }
            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        
        function fail() {
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }
        success();
    }
})();
