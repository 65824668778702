(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleEditionController', UserModuleEditionController);

    UserModuleEditionController.$inject = ['UserModuleEdition', 'NgTableParams','$uibModal', 'Principal','NgTree','$timeout', 'toastr'];

    function UserModuleEditionController(UserModuleEdition, NgTableParams, $uibModal, Principal,NgTree,$timeout, toastr) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.canSellStatus = [{'id':null, 'title':''}, {'id':1, 'title':'可售'}, {'id':0, 'title':'不可销'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.view = view;
        vm.update = update;
        vm.deleteData = deleteData;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.changeSellStatus = changeSellStatus;
        vm.active = 0;

        vm.enableSell=enableSell;
        vm.disableSell=disableSell;
        vm.checktab = checktab;
        
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return UserModuleEdition.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }

        function view(row) {
        	if(vm.checktab('查看 '+row.edtCode)) {
        		return;
        	}
        	UserModuleEdition.view({}, {id: row.edtId}, function(resp) {
        		var data = resp.content;
        		console.log(data);
        		var entity = {
                    heading: '查看 '+row.edtCode,
                    contentId: "edition-view.html",
                    data: data,
                    roles: [],
                    active: true
                };

                vm.tabs.push(entity);
                $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
            });
        }
        function edit(row) {
        	if(vm.checktab('编辑 '+row.edtCode)) {
        		return;
        	}
        	UserModuleEdition.detail({}, {id: row.edtId}, function(resp) {
                var data = resp.content;
        		var entity = {
                    heading: '编辑 '+row.edtCode,
                    contentId: "edition-eidt.html",
                    data: data,
                    roles: [],
                    active: true,
                    treeModel: new NgTree({
		       	             data :data.treeList,
		    	             selectedNodeIds : data.selectNodeIds,
		    	             config:{
		    	                 meta:{
		    	                     titleField : 'title',
		    	                     parentIdField : 'parentId',
		    	                     nodeIdField : 'nodeId',
		    	                     childField : 'children',
		    	                     needCheck: true
		    	                 }
		    	        
		    	             }
		    	         })
                };

                vm.tabs.push(entity);
                $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
            });
        }
        
        function create() {
        	if(vm.checktab('新建')) {
        		return;
        	}
        	UserModuleEdition.authTreeData({}, {}, function(resp) {
                var treeData = resp.content;
                var entity = {
                    heading: '新建',
                    contentId: "edition-eidt.html",
                    data: {
                    	edtId: null,
                    	edtCode: null,
                    	edtName: null,
                    	remark: null,
                    	canSell: 0
                    },
                    roles: [],
                    active: true,
                    treeModel: new NgTree({
	       	             data :treeData,
	    	             config:{
	    	                 meta:{
	    	                     titleField : 'title',
	    	                     parentIdField : 'parentId',
	    	                     nodeIdField : 'nodeId',
	    	                     childField : 'children',
	    	                     needCheck: true
	    	                 }
	    	        
	    	             }
	    	         })
                };

                vm.tabs.push(entity);
                $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
            });
        }
        function checktab(headname) {
        	var i = 0;
        	console.log(vm.tabs.length);
        	for(i; i< vm.tabs.length ; i++) {
        		console.log(vm.tabs[i]);
        		if(vm.tabs[i].heading === headname) {
        			console.log(i);
        			$timeout(function(){
    	                vm.active = i+1;
    	            },0);
        			return true;
        		}
        	}
        	return false;
        }
        function update(tab, idx, form) {
        	tab.needSelectTreeError = false;
            tab.saveError = false;
            
            var edition = tab.data;
            var treeSelectedIds = tab.treeModel.getSelectedIds();
            var valid = form.$valid;
            if (!valid ) {
                tab.needSelectTreeError = true;
                return;
            }
            edition.newNodeIds = treeSelectedIds;
            UserModuleEdition.save({}, edition, function(resp) {
            	if(resp.status === 'ERROR') {
        			tab.saveError = true;
        			tab.saveErrorMsg = resp.errorMsg;
        			return;
        		}
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function deleteData(row){
        	UserModuleEdition.delete({}, {id: row.edtId}, function(resp) {
                vm.search();
            });
        }
        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteEditionModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteData(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        
        function enableSell(row){
        	UserModuleEdition.openSell({}, {id: row.edtId}, function() {
                vm.search();
            });
        }
        function disableSell(row){
        	UserModuleEdition.closeSell({}, {id: row.edtId}, function() {
                vm.search();
            });
        }
        function changeSellStatus(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'changeStatusUserModal.html',
                controller: changeSellModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
            	if(item.canSell == 1) {
            		vm.disableSell(item);
            	} else {
            		vm.enableSell(item);
            	}
            });
        }

        changeSellModal.$inject = ['$uibModalInstance', 'item'];
        function changeSellModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }
            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
