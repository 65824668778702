(function () {
    'use strict';

    angular.module('ivendorModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('vendor-mod-sodetail-query', {
                parent: 'main',
                url: 'vendor-wms/sodetail-query',
                data: {
                    'pageTitle': '发货明细查询'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'vendor-module/sodetail/sodetail.html',
                        controller: 'VendorModSoDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();

