(function() {
    'use strict';

    angular.module('itaskModule')
        .controller('TaskQueryController', TaskQueryController);

    TaskQueryController.$inject = ['$timeout'];

    function TaskQueryController ($timeout) {
        var vm = this;
        vm.tabs=[];
        vm.active=0;

        //table假数据
        vm.tableParams=[
            {
                taskNum:'209124125125',
                status:'已生成',
                taskType:'ASN收货',
                subTaskNum:'2',             //子任务数目
                businessType:'仓库业务',     //业务类型
                businessData:'JSON结果数据',    //业务数据
                businessDocNum:'110120119', //业务单据号
                businessDocLineNum:'1',     //业务单据行号
                taskDesc:'这是一个神奇的任务',   //任务描述
                processTime:'10小时',         //处理耗时
                currentOwner:'张三',          //当前所有人
                generationTime:'2017-01-01 12:30:00',  //生成时间
                generator:'麻花藤',            //生成人
                distributionTime:'2017-01-01 12:30:00',     //分配时间
                distributor:'马云',            //分配人
                requestProcessingTime:'2017-01-01 12:30:00',        //要求处理事件
                processingStartTime:'2017-01-01 12:30:00',          //处理开始时间
                processingEndTime:'2017-01-01 12:30:00',          //处理结束时间
                handler:'小火锅',              //处理人
                cancelTime:'2017-01-01 12:30:00',               //取消时间
                canceler:'小烧烤',             //取消人

            }
        ];




        vm.edit = edit;//编辑
        vm.removeTab = removeTab;       //移除tab


        function edit(row) {
            var entity = {
                isCreate:false,
                heading : '任务操作日志',
                contentId : "editTaskHandler.html",
                data:[
                    {
                        operateTime:'2017-01-02 12:00:00',      //操作时间
                        operator:'东方不败',                      //操作人
                        operateType:'取消',                   //操作类型
                        operateDetail:'今天早晨，天气晴朗，阳光明媚',     //操作内容
                    },
                    {
                        operateTime:'2017-01-02 12:20:00',      //操作时间
                        operator:'西方不胜',                      //操作人
                        operateType:'处理',                   //操作类型
                        operateDetail:'今天早晨，天气晴朗，阳光明媚啊啊啊',     //操作内容
                    },

                ],
                // tableParams:[
                //     {
                //         businessType:'仓库收货',
                //         warehouse:'仓库1',
                //         platform:'月台1',
                //         ownerCode:'货主1',
                //         ownerName:'货主1',
                //         docNumber:'112553262',          //单据号
                //         totalQty:'22',                  //总数量
                //         totalWeight:'457',              //总重量
                //     },
                //     {
                //         businessType:'仓库发货',
                //         warehouse:'仓库3',
                //         platform:'月台6',
                //         ownerCode:'货主4',
                //         ownerName:'货主4',
                //         docNumber:'746745458',          //单据号
                //         totalQty:'47',                  //总数量
                //         totalWeight:'13',              //总重量
                //     },
                // ],
                // newTableParams:[
                //     {
                //         operateTime:'2017-01-02 12:00:00',
                //         operatorName:'长者',
                //         operateType:'取消',
                //         operateContent:'今天早上，天气晴朗，阳光明媚'
                //     },
                //     {
                //         operateTime:'2017-06-01 13:22:16',
                //         operatorName:'地底世界',
                //         operateType:'停泊',
                //         operateContent:'哇哈哈啊，阿佛按时发货'
                //     },
                // ],
                active : true
            };
            vm.tabs.push(entity);
            console.log(vm.tabs);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }
        function removeTab(idx) {
            vm.active=idx;
            vm.tabs.splice(idx, 1);
        }




    }
})();
