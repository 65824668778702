(function() {
    'use strict';

    angular.module('iwh-receiptModule')
        .controller('PcScanReceiveController', PcScanReceiveController);

    PcScanReceiveController.$inject = ['$rootScope', '$state', '$timeout', '$filter', '$uibModal', 'toastr','PcScanReceive','$scope'];

    function PcScanReceiveController ($rootScope, $state, $timeout, $filter, $uibModal, toastr,PcScanReceive,$scope) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'},
            off: {'background-color': '#868e96', 'color': '#fff'}
        };
        vm.charArray = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        vm.boxMaxWidth = 120;
        vm.checkResult = {
            status: 'SUCCESS',
            message: ''
        };
        vm.params = {
            useScanGun: true,
            pickerCode: '',
            sowRow: '',
            sowCol: '',
            pickNum: '',
            skuBarcode: ''
        };
        vm.rows = [];
        vm.positionMap = {};
        vm.pickOrder = {};
        vm.boxMap = {};
        vm.sku2Boxes = {};
        vm.barcode2SkuCode = {};
        vm.box = null;
        vm.skuCode = null;
        vm.hasShowLot=false;            //批次组件外层，是否显示，默认为false
        vm.changeOrderCmd = '$CHANGEPKO$';
        vm.errorCtrlCmd = '$ERRORCTRL$';            //报错提示框时输入这个能关闭提示框

        var batchNumInput=jQuery('#batchNum');
        var noticeNumInput=jQuery('#noticeNum');
        var skuBarcodeInput=jQuery('#skuBarcode');
        vm.inputReceiveLPN = "";
        vm.isNeedLPN=false;  
        vm.isNeedLoc=false;  
        vm.isBatchReceive = true;
        vm.isCanCreateSkuAndAsn=false;   
        vm.useSameLot=true;                    //是否沿用相同批次属性
        vm.lotSkuData={};                       //新建批次后返回的有关商品信息的对象
        vm.containerLists=[];                   //容器组
        vm.lotRule={};
        vm.batchNum='';                         //收货批次编码，在新建批次之后可以获得
        vm.isSettingToNoAsnReceive = false;
        if ($state.params.isNoAsnReceive > 0) {
        	vm.isSettingToNoAsnReceive = true;
        	vm.isCanCreateSkuAndAsn = true;
        }
        if ($state.params.isBatchReceive <= 0) {
        	vm.isBatchReceive = false;
        }
        
        vm.openInitParamWindow = openInitParamWindow;
        vm.inputReceiveQty = '';
        
        

        //这个rule还用来暂存商品对应的批次属性
        vm.rule={
            bizType:'RECEIPT',
            needDefault:true,
            needRequire:true,
            lotRule:'',
            ownerCode:'',
            skuCode:'',
            tenantId:'',
            warehouseCode:'',
            skuBarCode:''
        };      //用于批次组件生成内容的规则
        vm.surplusQty='';                       //已收量

        vm.locationTotalQty='';
        vm.lpnTotalQty='';
        vm.skuSeq='';                           //序列号(看是否需要采集)

        vm.lotStorage=[];                       //用来缓存页面上的批次属性数据
        vm.tenantId='';
        vm.warehouseCode='';
        vm.hasSpeContainer=false;               //是否有特殊容器，默认为没有
        vm.isNeedSow=false;               //
        vm.showBox={};                          //用来展示收货结果的对象，放在绿色方块里

        vm.errorCtrlCmd = '$ERRORCTRL$';

        //新增的方法
        vm.loadBatchInfo=loadBatchInfo;     //根据批次号加载批次信息
        vm.handleLot=handleLot;             //处理输入收货批次号
        vm.createLot=createLot;             //新建批次
        vm.completeLot=completeLot;             //完成批次
        vm.getAsnInfo=getAsnInfo;           //获取ASN单信息
        vm.scanSkuBarCode=scanSkuBarCode;   //扫描商品条码
        vm.scanLpn=scanLpn;   //
        vm.scanLoc=scanLoc;   //
        vm.checkStatus=checkStatus;         //检查批次组件的验证返回值，如果正确才可以进行下一步操作
        vm.receipt=receipt;                 //收货方法
        vm.changeAsnOrder = changeAsnOrder;
        vm.createAsnOrder = createAsnOrder;
        vm.changeLPN = changeLPN;
        vm.changeLoc = changeLoc;
        vm.createSku = createSku;
        vm.inputReceiveQtyEnter = inputReceiveQtyEnter;
        vm.subCaseNum = function(caseNum) {
        	var c = caseNum;
        	if (caseNum.length > 6) {
        		c = caseNum.substring(caseNum.length-6, caseNum.length);
        	}
        	return c;
        }

        function loadBatchInfo(e) {
            if(e.which===13){
            	success();
                PcScanReceive.loadReceiveBatchInfo({},{
                    batchNum:vm.batchNum,
                },function (res) {
                    clearData();
                    vm.tenantId=res.content.tenantId;
                    vm.warehouseCode=res.content.warehouseCode;

                    vm.containerLists=[];            //先清空容器数组
                    vm.batchNum=res.content.batchNum;

                    vm.lotSkuData.skuCount=res.content.skuCount;
                    vm.lotSkuData.EACount=res.content.eacount;
                    vm.lotSkuData.asnCount=res.content.asnCount;
                    vm.lotSkuData.containerCount=res.content.containerCount;

                    var caseNums=res.content.caseNums;
                    vm.containerLists=caseNums ? caseNums : [];
                    if (vm.containerLists.length > 0) {
                    	vm.isNeedSow = true;
                    	calcBox(vm.containerLists.length,vm.containerLists[0].length);

                        if(res.content.specialContainer!==null){
                            vm.hasSpeContainer=true;
                            vm.speContainer=res.content.specialContainer;
                        }
                    } else {
                    	vm.isNeedSow = false;
                    }

                    //回调处理成功后获得焦点
                    noticeNumInput.focus();
                    noticeNumInput.select();


                },function (err) {
                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                    fail();
                    // batchNumInput.focus();
                    // batchNumInput.select();
                });
            }
        }

        function handleLot(e) {
            if(e.which===13){
                $uibModal.open({
                    templateUrl: 'catchSeqNum.html',
                    controller: catchSeqNumCtrl,
                    controllerAs: '$ctrl',
                    // backdrop: 'static',
                    // resolve: {
                    //
                    // }
                })
            }
        }
        function openInitParamWindow() {
            //新建批次时要清除页面的数据
            $uibModal.open({
                templateUrl: 'initParam.html',
                controller: openInitParamCtrl,
                controllerAs: '$ctrl',
            })
        }
      //新建批次弹出框控制器
        openInitParamCtrl.$inject=['$uibModalInstance','$scope'];
        function openInitParamCtrl($uibModalInstance,$scope) {
            var me=this;
            me.isNeedSow=vm.isNeedSow;
            me.isNeedLPN=vm.isNeedLPN;
            me.isNeedLoc=vm.isNeedLoc;
            me.isBatchReceive = vm.isBatchReceive;
            me.isCanCreateSkuAndAsn = vm.isCanCreateSkuAndAsn;
            me.isSettingToNoAsnReceive = vm.isSettingToNoAsnReceive;
            me.useSameLot = vm.useSameLot;
            
            me.entryInMainPage=entryInMainPage;
            function entryInMainPage() {
            	vm.isNeedSow = me.isNeedSow;
            	vm.isNeedLPN = me.isNeedLPN;
            	vm.isNeedLoc = me.isNeedLoc;
            	vm.isBatchReceive = me.isBatchReceive;
            	vm.isCanCreateSkuAndAsn = me.isCanCreateSkuAndAsn;
            	vm.useSameLot = me.useSameLot;
            	clearData();
            	$uibModalInstance.dismiss('close');
            	jQuery('#batchNum').focus();
            	jQuery('#batchNum').select();
            }
        }
        function createLot() {
            //新建批次时要清除页面的数据
            $uibModal.open({
                templateUrl: 'addLot.html',
                controller: createLotCtrl,
                controllerAs: '$ctrl',
                resolve: {
                    item : {isNeedSow : vm.isNeedSow}
                }
            })
        }
        function completeLot() {
        	PcScanReceive.completeLot({},{ batchNum:vm.batchNum },function (res) {
                //收货成功
            	toastr.success('收货批次：'+ vm.batchNum + ' 已完成');
                success();
                clearData();
            },function (err) {
            	toastr.error(err.data.errorMsg);
            });
        }
        //新建批次弹出框控制器
        createLotCtrl.$inject=['$uibModalInstance','$scope', 'item'];
        function createLotCtrl($uibModalInstance,$scope, item) {
            var me=this;
            me.autoCaseNum=false;             //是否自动生成容器号,默认为false
            me.specialReceive=false;          //是否开始特殊容器收货,默认为false
            me.item = item;

            vm.lotRule=me.groupRule;
            var groupRuleWatch=$scope.$watch('$ctrl.groupRule',function (n) {
                if(n){
                    vm.rule.tenantId=n.tenantId;
                    vm.rule.warehouseCode=n.warehouseCode;
                    vm.tenantId=n.tenantId;
                    vm.warehouseCode=n.warehouseCode;
                }
            },true);

            me.checkRow=checkRow;               //检测行号
            me.checkColumn=checkColumn;         //列号检测
            me.nextCreateLot=nextCreateLot;     //点击下一步按钮
            me.justCreateLot=justCreateLot;
            me.cancel = cancel;
            function cancel() {
            	$uibModalInstance.dismiss('cancel');
            }

            //行号检测，检测是否有值，值是否正确
            function checkRow(e) {
                var caseColumnInput=jQuery('#caseColumn');
                var caseRowInput=jQuery('#caseRow');
                //敲回车
                if(e.which===13){
                    if(me.caseRow<=26 && me.caseRow>=1){
                        caseColumnInput.focus();
                        caseColumnInput.select();
                    }else{
                        toastr.error("行号请输入1-26的数字");
                        caseRowInput.focus();
                        caseRowInput.select();
                    }
                }
            }

            //列号检测
            function checkColumn(e) {
                var caseColumnInput=jQuery('#caseColumn');
                if(e.which===13){
                    if(me.caseColumn<=99 && me.caseColumn>=1){
                        nextCreateLot();
                    }else{
                        toastr.error("列号请输入1-99的数字");
                        caseColumnInput.focus();
                        caseColumnInput.select();
                    }
                }
            }
            function justCreateLot() {
            	clearData();
            	PcScanReceive.saveBatchForNoSow({},{},function (res) {
                	$uibModalInstance.dismiss('close');
                    vm.containerLists=[];            //先清空容器数组
                    vm.batchNum=res.content.batchNum;

                    vm.lotSkuData.skuCount=res.content.skuCount;
                    vm.lotSkuData.EACount=res.content.eacount;
                    vm.lotSkuData.asnCount=res.content.asnCount;
                    vm.lotSkuData.containerCount=res.content.containerCount;

                    //回调处理成功后获得焦点
                    noticeNumInput.focus();
                    noticeNumInput.select();
                },function (err) {
                    toastr.error(err.data.errorMsg);
                    fail();
                });
            }
            function nextCreateLot() {
                var caseRowInput=jQuery('#caseRow');
                var caseColumnInput=jQuery('#caseColumn');
                if(!me.groupRule){
                    toastr.error("请选择分组规则");
                }else if(!me.caseRow){
                    toastr.error("请输入行号");
                    caseRowInput.focus();
                }else if(me.caseRow>26 || me.caseRow<1){
                    toastr.error("行号请输入小于等于26的数字");
                    caseRowInput.focus();
                    caseRowInput.select();
                }else if(!me.caseColumn){
                    toastr.error("请输入列号");
                    caseColumnInput.focus();
                }else if(me.caseColumn>99 || me.caseColumn<1){
                    toastr.error("列号请输入1-99的数字");
                    caseColumnInput.focus();
                    caseColumnInput.select();
                }
                else{
                    $timeout(function () {
                        $uibModalInstance.dismiss('cancel');
                    },0);
                    //勾了自动生成容器号并且没有勾选开启特殊容器收货才能直接完成
                    if(me.autoCaseNum && !me.specialReceive){
                        //代表勾选了自动生成容器
                        clearData();
                        PcScanReceive.saveBatchExtInfo({},{
                            groupRuleCode:me.groupRule.code,           //规则编码
                            caseRow:me.caseRow,
                            caseColumn:me.caseColumn,
                            //开启特殊容器收货（0-不开启；1-开启）
                            specialReceive:0,
                            autoGenerator:1             //自动生成容器号（0-手动；1-自动）
                        },function (res) {
                            vm.containerLists=[];            //先清空容器数组
                            vm.batchNum=res.content.batchNum;

                            vm.lotSkuData.skuCount=res.content.skuCount;
                            vm.lotSkuData.EACount=res.content.eacount;
                            vm.lotSkuData.asnCount=res.content.asnCount;
                            vm.lotSkuData.containerCount=res.content.containerCount;

                            var caseNums=res.content.caseNums;
                            vm.containerLists=caseNums;
                            calcBox(vm.containerLists.length,vm.containerLists[0].length);

                            //回调处理成功后获得焦点
                            noticeNumInput.focus();
                            noticeNumInput.select();
                        },function (err) {
                            openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                            fail();
                        });
                    }else{
                        //这里代表没够勾选自动生成容器号，但是不能确定是否开启了特殊容器收货
                        $uibModal.open({
                            templateUrl: 'bindContainer.html',
                            controller: nextCreateLotCtrl,
                            controllerAs: '$ctrl',
                            backdrop: 'static',
                            resolve: {
                                // item:function(){
                                //     return paramItem;
                                // }
                                caseRow:function () {
                                    return me.caseRow;
                                },
                                caseColumn:function () {
                                    return me.caseColumn;
                                },
                                specialReceive:function () {
                                    return me.specialReceive;
                                },
                                groupRuleCode:function () {
                                    return me.groupRule.code;
                                },
                                isOpenSpe:function () {
                                    return me.specialReceive;
                                },
                                isAuto:function () {
                                    return me.autoCaseNum;
                                }
                            }
                        })
                    }
                }
            }

            $scope.$on('$destroy',function () {
                groupRuleWatch();
            });

        }

        //新建批次弹出框之下一步的控制器
        nextCreateLotCtrl.$inject=['caseRow','caseColumn','specialReceive','groupRuleCode','$uibModalInstance','isOpenSpe','isAuto'];
        function nextCreateLotCtrl(caseRow,caseColumn,specialReceive,groupRuleCode,$uibModalInstance,isOpenSpe,isAuto) {
            var me=this;
            //isOpenSpe，如果开启了才让用户扫描特殊容器的箱号，如果没有开启，则不用
            me.isOpenSpe=isOpenSpe;
            me.isAuto=isAuto;           //是否自动生成容器号
            me.conLists=[];
            for(var i=1;i<=caseRow;i++){
                for(var j=1;j<=caseColumn;j++){
                    me.conLists.push(fillPosition(i-1, j, caseColumn));
                }
            }
            var contCodeInput,speCodeInput;
            $timeout(function () {
                speCodeInput=jQuery('#speCode');
                contCodeInput=jQuery('#contCode');
                if(isOpenSpe){
                    speCodeInput.focus();
                    speCodeInput.select();
                }else{
                    contCodeInput.focus();
                    contCodeInput.select();
                }
            },0);

            me.seriNum=me.conLists[0];
            me.containerList=[];                    //存储容器箱号数组

            me.scanSpeCode=scanSpeCode;             //扫描特殊容器箱号
            me.scanCode=scanCode;                   //扫描容器条码
            me.competeAddLot=competeAddLot;         //完成新建批次
            me.resetBind=resetBind;                 //重新绑定


            function scanSpeCode(e) {
                if(e.which===13){
                    if(!me.speCode){
                        toastr.error('请扫描特殊容器箱号');
                    }else{
                        if(isAuto){
                            competeAddLot();
                        }else{
                            contCodeInput.focus();
                            contCodeInput.select();
                        }
                    }
                }
            }

            var index=0;
            function scanCode(e) {
                if(e.which===13){
                	var hasDuplicate = false;
                	angular.forEach(me.containerList, function(item,index,array){  
                	    if (item.caseNum === me.caseNum) {
                	    	hasDuplicate = true;
                	    }
                	});
                	if (hasDuplicate === true) {
                		toastr.error('当前扫描的容器箱号：'+me.caseNum+' 已经被使用，请不要重复绑定！');
                		contCodeInput.focus();
                        contCodeInput.select();
                	} else {
                		if(index<me.conLists.length-1){
                            me.containerList.push({
                                seriNum:me.conLists[index],     //序号
                                caseNum:me.caseNum              //容器条码
                            });
                            index++;
                            me.seriNum=me.conLists[index];
                            me.caseNum='';
                        }else if(index===me.conLists.length-1){
                            me.containerList.push({
                                seriNum:me.conLists[index],     //序号
                                caseNum:me.caseNum              //容器条码
                            });
                            index++;
                            me.seriNum=me.conLists[index];
                            me.caseNum='';
                            me.succInfo='容器条码扫描完成'
                            competeAddLot();
                        }else if(index>me.conLists.length-1){
                            //表示绑定完成了，现在开始完成新建批次的步骤
                            competeAddLot();
                        }
                	}
                }
            }

            function resetBind() {
                index=0;
                me.containerList=[];
                me.seriNum=me.conLists[0];
                me.caseNum='';
                contCodeInput.focus();
                contCodeInput.select();
            }

            function competeAddLot() {
                //先验证在需要扫描特殊容器箱号的情况下，是否扫描了特殊容器的箱号
                if(isOpenSpe && !me.speCode){
                    toastr.error('请扫描特殊容器箱号！');
                    speCodeInput.focus();
                    speCodeInput.select();
                }else{
                    //得先检验扫描的数量是否正确，所要扫描的数量应该与上一页的行数*列数相匹配
                    if(!isAuto && me.containerList.length<me.conLists.length){
                        toastr.error('容器还未绑定完成，请继续扫描!');
                        contCodeInput.focus();
                        contCodeInput.select();
                    }else{
                        $uibModalInstance.dismiss('cancel');
                        //如果开启了特殊容器,需要把特殊容器展示出来
                        clearData();
                        if(isOpenSpe){
                            if(isAuto){
                                PcScanReceive.saveBatchExtInfo({},{
                                    groupRuleCode:groupRuleCode,           //规则编码
                                    caseRow:caseRow,
                                    caseColumn:caseColumn,
                                    //开启特殊容器收货（0-不开启；1-开启）
                                    specialReceive:1,
                                    autoGenerator:1,            //自动生成容器号（0-手动；1-自动）
                                    specialContainer:{
                                        seriNum:'T',
                                        caseNum:me.speCode
                                    }
                                },function (res) {
                                    // $uibModalInstance.dismiss('cancel');
                                    vm.containerLists=[];            //先清空容器数组
                                    vm.batchNum=res.content.batchNum;

                                    vm.lotSkuData.skuCount=res.content.skuCount;
                                    vm.lotSkuData.EACount=res.content.eacount;
                                    vm.lotSkuData.asnCount=res.content.asnCount;
                                    vm.lotSkuData.containerCount=res.content.containerCount;
                                    if(res.content.specialContainer!==null){
                                        vm.hasSpeContainer=true;
                                        vm.speContainer=res.content.specialContainer;
                                    }

                                    var caseNums=res.content.caseNums;

                                    vm.containerLists=caseNums;
                                    calcBox(vm.containerLists.length,vm.containerLists[0].length);

                                    //回调处理成功后获得焦点
                                    noticeNumInput.focus();
                                    noticeNumInput.select();
                                },function (err) {
                                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                                    fail();
                                });
                            }else{
                                PcScanReceive.saveBatchExtInfo({},{
                                    groupRuleCode:groupRuleCode,           //规则编码
                                    caseRow:caseRow,
                                    caseColumn:caseColumn,
                                    //开启特殊容器收货（0-不开启；1-开启）
                                    specialReceive:1,
                                    autoGenerator:0,            //自动生成容器号（0-手动；1-自动）
                                    caseNums: me.containerList,
                                    specialContainer:{
                                        seriNum:'T',
                                        caseNum:me.speCode
                                    }
                                },function (res) {
                                    // $uibModalInstance.dismiss('cancel');
                                    vm.containerLists=[];            //先清空容器数组
                                    vm.batchNum=res.content.batchNum;

                                    vm.lotSkuData.skuCount=res.content.skuCount;
                                    vm.lotSkuData.EACount=res.content.eacount;
                                    vm.lotSkuData.asnCount=res.content.asnCount;
                                    vm.lotSkuData.containerCount=res.content.containerCount;

                                    console.log(res.content,'内容')
                                    if(res.content.specialContainer!==null){
                                        vm.hasSpeContainer=true;
                                        vm.speContainer=res.content.specialContainer;
                                    }

                                    var caseNums=res.content.caseNums;

                                    vm.containerLists=caseNums;
                                    calcBox(vm.containerLists.length,vm.containerLists[0].length);

                                    //回调处理成功后获得焦点
                                    noticeNumInput.focus();
                                    noticeNumInput.select();
                                },function (err) {
                                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                                    fail();
                                });
                            }
                        }else{
                            //没有开启特殊容器，则不需要展示
                            if(isAuto){
                                PcScanReceive.saveBatchExtInfo({},{
                                    groupRuleCode:groupRuleCode,           //规则编码
                                    caseRow:caseRow,
                                    caseColumn:caseColumn,
                                    //开启特殊容器收货（0-不开启；1-开启）
                                    specialReceive:0,
                                    autoGenerator:1,            //自动生成容器号（0-手动；1-自动）
                                },function (res) {
                                    // $uibModalInstance.dismiss('cancel');
                                    vm.containerLists=[];            //先清空容器数组
                                    vm.batchNum=res.content.batchNum;

                                    vm.lotSkuData.skuCount=res.content.skuCount;
                                    vm.lotSkuData.EACount=res.content.eacount;
                                    vm.lotSkuData.asnCount=res.content.asnCount;
                                    vm.lotSkuData.containerCount=res.content.containerCount;

                                    //没有开启特殊容器，要把这个关闭
                                    vm.hasSpeContainer=false;

                                    var caseNums=res.content.caseNums;
                                    vm.containerLists=caseNums;
                                    calcBox(vm.containerLists.length,vm.containerLists[0].length);
                                    //回调处理成功后获得焦点
                                    noticeNumInput.focus();
                                    noticeNumInput.select();
                                },function (err) {
                                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                                    fail();
                                });
                            }else{
                                PcScanReceive.saveBatchExtInfo({},{
                                    groupRuleCode:groupRuleCode,           //规则编码
                                    caseRow:caseRow,
                                    caseColumn:caseColumn,
                                    //开启特殊容器收货（0-不开启；1-开启）
                                    specialReceive:0,
                                    autoGenerator:0,            //自动生成容器号（0-手动；1-自动）
                                    caseNums: me.containerList
                                },function (res) {
                                    // $uibModalInstance.dismiss('cancel');
                                    vm.containerLists=[];            //先清空容器数组
                                    vm.batchNum=res.content.batchNum;

                                    vm.lotSkuData.skuCount=res.content.skuCount;
                                    vm.lotSkuData.EACount=res.content.eacount;
                                    vm.lotSkuData.asnCount=res.content.asnCount;
                                    vm.lotSkuData.containerCount=res.content.containerCount;

                                    //没有开启特殊容器，要把这个关闭
                                    vm.hasSpeContainer=false;

                                    var caseNums=res.content.caseNums;
                                    vm.containerLists=caseNums;
                                    calcBox(vm.containerLists.length,vm.containerLists[0].length);
                                    //回调处理成功后获得焦点
                                    noticeNumInput.focus();
                                    noticeNumInput.select();
                                },function (err) {
                                    openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                                    fail();
                                });
                            }
                        }
                    }
                }
            }
        }
        
        function fillPosition(row,col, totalColCount) {
        	var colStr = col + '';
        	if (totalColCount >= 10) {
        		var colBaseStr = totalColCount + '';
        		if (colBaseStr.length > colStr.length) {
        			var subLength = colBaseStr.length - colStr.length;
        			for (var j=0; j<subLength; j++) {
        				colStr = "0" + colStr;
        			}
        		}
        	}
            return vm.charArray[row] + colStr;
        }

        //绘制容器组
        function calcBox(row,col) {
        	console.log(row,col);
            var rows = [];
            vm.rows=[];
            for (var i=0; i< row; i++) {
                rows.push({columns: []});
                if(vm.containerLists[i].length===0){
                    continue;
                }
                for (var j=0; j<col; j++) {
                	var sowPosition = fillPosition(i, j+1, col);
                    rows[i]['columns'][j] = {
                        sowPosition: sowPosition,
                        receiptedCount: vm.containerLists[i][j].receiptedCount,
                        skuCount: vm.containerLists[i][j].skuCount,
                        status:vm.containerLists[i][j].status,
                        caseNum:vm.containerLists[i][j].caseNum,
                        style: {},
                        css: '',
                        styleFn: styleFn
                    };

                    // if (vm.positionMap[sowPosition]) {
                    //     rows[i]['columns'][j].box = vm.positionMap[sowPosition];
                    // }
                }
            }
            vm.rows=rows;

            $timeout(function() {
                var $sowReion = jQuery('#sowRegion');
                if ($sowReion.length === 1) {
                    var tW = $sowReion.width();
                    tW = tW - col * 5 * 2 - 1;
                    var avgW = tW / col;
                    if (avgW > vm.boxMaxWidth) {
                        avgW = vm.boxMaxWidth;
                    }
                    angular.forEach(vm.rows, function(row) {
                        angular.forEach(row.columns, function(col) {
                            col.style['width'] = avgW + 'px';
                            col.style['height'] = avgW + 'px';
                            if (avgW === vm.boxMaxWidth) {
                                col.css = 'maxSowBox';
                            } else if (avgW >= 80) {
                                col.css = 'mediumerSowBox';
                            } else if (avgW >= 60) {
                                col.css = 'mediumSowBox';
                            } else {
                                col.css = 'minSowBox';
                            }
                        });
                    });
                }
            });

            return true;

            function styleFn() {
                if(this.status===0){
                    this.style = angular.merge(this.style, vm.alertColor.off);
                }else if(this.status===1){
                    this.style = angular.merge(this.style, vm.alertColor.done);
                }else if(this.status===2){
                    this.style = angular.merge(this.style, vm.alertColor.current);
                }

                // if (this.box) {
                //     if (vm.box && this.box.boxNum === vm.box.boxNum) {
                //         this.style = angular.merge(this.style, vm.alertColor.current);
                //     } else if (this.box.sowQty > 0 && this.box.sowQty === this.box.qty) {
                //         this.style = angular.merge(this.style, vm.alertColor.done);
                //     } else if (this.box.sowQty > 0) {
                //         this.style = angular.merge(this.style, vm.alertColor.doing);
                //     } else {
                //         this.style = angular.merge(this.style, vm.alertColor.on);
                //     }
                // } else {
                //
                // }
                return this.style;
            }

        }
        function changeLoc() {
        	vm.showBox = {};
        	vm.skuBarcode='';
        	vm.showLot=false;
        	vm.rule={
                bizType:'RECEIPT',
                needDefault:true,
                needRequire:true,
                lotRule:'',
                ownerCode:'',
                skuCode:'',
                tenantId:'',
                warehouseCode:'',
                skuBarCode:''
            };
            vm.surplusQty='';
            vm.locationTotalQty='';
            vm.lpnTotalQty='';
            vm.hasShowLot=false;
            //回调处理成功后获得焦点
            vm.inputReceiveLPN = '';
            vm.inputReceiveLoc = '';
            jQuery('#inputReceiveLoc').focus();
        	jQuery('#inputReceiveLoc').select();
        }
        function changeLPN() {
        	vm.showBox = {};
        	vm.skuBarcode='';
        	vm.showLot=false;
        	vm.rule={
                bizType:'RECEIPT',
                needDefault:true,
                needRequire:true,
                lotRule:'',
                ownerCode:'',
                skuCode:'',
                tenantId:'',
                warehouseCode:'',
                skuBarCode:''
            };
            vm.surplusQty='';
            vm.locationTotalQty='';
            vm.lpnTotalQty='';

            vm.hasShowLot=false;
            //回调处理成功后获得焦点
            vm.inputReceiveLPN = '';
            jQuery('#inputReceiveLPN').focus();
        	jQuery('#inputReceiveLPN').select();
        }
        function createSku() {
        	$uibModal.open({
                templateUrl: 'addSku.html',
                controller: createSkuCtrl,
                controllerAs: '$ctrl',
            })
        }
        createSkuCtrl.$inject=['$uibModalInstance','$scope'];
        function createSkuCtrl($uibModalInstance,$scope) {
            var me=this;
            me.sku={
            		ownerCode:vm.asnInfo.ownerCode,
            		ownerName:vm.asnInfo.ownerName,
            		code:vm.skuBarcode,
            		barcode:vm.skuBarcode,
            		name:vm.skuBarcode,
            		styleNum:'',
            		color:'',
            		size:'',
            		brand:'',
            		grade:'',
            		standard:'',
            		originPlace:'',
            		length:1,
            		width:1,
            		height:1,
            		grosswgt:1,
            		netwgt:1,
            		skinwgt:1,
            		purchasePrice:0,
            		sellPrice:0,
            		guidancePrice:0,
            		defaultSupplier:""
            };
            
            me.createSku=createSku;
            me.cancel = cancel;
            function createSku() {
            	PcScanReceive.createSku({},me.sku,function (res) {
            		vm.skuBarcode = res.content.code;
                	$uibModalInstance.dismiss('close');
                	jQuery('#skuBarcode').focus();
                	jQuery('#skuBarcode').select();
                	vm.scanSkuBarCode({which: 13});
                },function (err) {
                	toastr.error(err.data.errorMsg);
                    fail();
                });
            }
            function cancel() {
            	$uibModalInstance.dismiss('cancel');
            	jQuery('#skuBarcode').focus();
            	jQuery('#skuBarcode').select();
            }
        }
        function createAsnOrder() {
        	$uibModal.open({
                templateUrl: 'createAsn.html',
                controller: createAsnOrderCtrl,
                controllerAs: '$ctrl',
                size: 'lg'
            })
        }
        createAsnOrderCtrl.$inject=['$uibModalInstance','$scope'];
        function createAsnOrderCtrl($uibModalInstance,$scope) {
            var me=this;
            me.data={};
            
            me.createAsn=createAsn;
            me.cancel = cancel;
            function createAsn() {
            	if (!me.data.supplierObject) {
            		me.data.supplierObject = {};
            	}
            	// if (!me.data.supplierObject.code) {
            	// 	toastr.error("请输入供应商信息！");
            	// 	return;
            	// }
            	PcScanReceive.createAsn({},{
            		ownerCode: me.data.ownerObject.code,
            		ownerName: me.data.ownerObject.name,
            		supplierCode: me.data.supplierObject ? me.data.supplierObject.code : '',
            		supplierName: me.data.supplierObject ? me.data.supplierObject.fullName : '',
            		type: me.data.type,
                    extNum: me.data.extNum,
                    customerOrderNo: me.data.customerOrderNo,
                    shipOrderNo: me.data.shipOrderNo,
                    returnWaybillNum: me.data.returnWaybillNum,
                    customerCode: me.data.customerObject ? me.data.customerObject.code : '',
                    customerName: me.data.customerObject ? me.data.customerObject.name : '',
                    customerPhone: me.data.customerObject ? me.data.customerObject.phone : '',
                    customerAddress:me.data.customerObject ?  me.data.customerObject.address : ''
                },function (res) {
                	vm.asnNum = res.content;
                	getAsnInfoFun();
                	$uibModalInstance.dismiss('close');
                	jQuery('#noticeNum').focus();
                	jQuery('#noticeNum').select();
                },function (err) {
                	toastr.error(err.data.errorMsg);
                    fail();
                });
            }
            function cancel() {
            	$uibModalInstance.dismiss('cancel');
            	jQuery('#noticeNum').focus();
            	jQuery('#noticeNum').select();
            }
        }
        function changeAsnOrder() {
        	vm.asnInfo = {};
        	vm.showBox = {};
        	vm.skuBarcode='';
            vm.useSameLot=true;
            vm.showLot=false;
            vm.rule={
                bizType:'RECEIPT',
                needDefault:true,
                needRequire:true,
                lotRule:'',
                ownerCode:'',
                skuCode:'',
                tenantId:'',
                warehouseCode:'',
                skuBarCode:''
            };
            vm.surplusQty='';
            vm.locationTotalQty='';
            vm.lpnTotalQty='';
            vm.hasShowLot=false;
        	//回调处理成功后获得焦点
            noticeNumInput.focus();
            noticeNumInput.select();
        }
        //加载ASN单信息
        function getAsnInfoFun() {
        	success();
        	vm.showBox = {};
            PcScanReceive.loadAsnInfo({},{
                asnNum:vm.asnNum,
            },function (res) {

                vm.skuBarcode='';
                vm.useSameLot=true;
                vm.showLot=false;
                vm.rule={
                    bizType:'RECEIPT',
                    needDefault:true,
                    needRequire:true,
                    lotRule:'',
                    ownerCode:'',
                    skuCode:'',
                    tenantId:'',
                    warehouseCode:'',
                    skuBarCode:''
                };
                vm.surplusQty='';
                vm.locationTotalQty='';
                vm.lpnTotalQty='';
                vm.hasShowLot=false;

                vm.asnInfo=res.content;
                vm.asnInfo.asnDetails.forEach(function (v) {
                    if(v.receiptQty>0 && v.receiptQty!==v.qty){
                        v.isPart=true;
                    }
                    if(v.receiptQty>=v.qty){
                        v.isFinished=true;
                    }
                });
                $timeout(function () {
                	//处理完成后获取焦点
                    if (vm.isNeedLoc) {
                    	jQuery('#inputReceiveLoc').focus();
                    	jQuery('#inputReceiveLoc').select();
                    } else if (vm.isNeedLPN) {
                    	jQuery('#inputReceiveLPN').focus();
                    	jQuery('#inputReceiveLPN').select();
                    } else {
                    	skuBarcodeInput.focus();
                        skuBarcodeInput.select();
                    }
                },500);
                
            },function (err) {
                openModal('error-confirm.html', {message: err.data.errorMsg}, 'noticeNum');
                fail();
            });
        }
        function getAsnInfo(e) {
            if(e.which===13){
            	getAsnInfoFun();
            }
        }
        function scanLoc(e) {
        	if(e.which===13){
        		if (vm.isNeedLoc && !vm.inputReceiveLoc) {
        			openModal('error-confirm.html', {message: "请扫描库位条码！"}, 'inputReceiveLoc');
                    fail();
                    return;
        		}
        		if (vm.isNeedLPN) {
                	jQuery('#inputReceiveLPN').focus();
                	jQuery('#inputReceiveLPN').select();
                } else {
                	skuBarcodeInput.focus();
            		skuBarcodeInput.select();
                }
        	}
        }
        function scanLpn(e) {
        	if(e.which===13){
        		if (vm.isNeedLPN && !vm.inputReceiveLPN) {
        			openModal('error-confirm.html', {message: "请扫描托盘条码！"}, 'inputReceiveLPN');
                    fail();
                    return;
        		}
        		skuBarcodeInput.focus();
        		skuBarcodeInput.select();
        	}
        }
        function inputReceiveQtyEnter(e) {
        	if(e.which===13){
        		if (!vm.inputReceiveQty || vm.inputReceiveQty <= 0) {
        			openModal('error-confirm.html', {message: "请扫输入要收货的数量！"}, 'inputReceiveQty');
                    fail();
                    return;
        		} else {
        			receipt();
        		}
        	}
        }
        //扫描商品条码
        function scanSkuBarCode(e) {
            if(e.which===13){
            	vm.showBox = {};
            	vm.surplusQty='';
                vm.locationTotalQty='';
                vm.lpnTotalQty='';
            	vm.inputReceiveQty = '';
                vm.hasShowLot=false;
                skuBarcodeInput.blur();
                vm.rows.forEach(function (m) {
                    m.columns.forEach(function (v) {
                        v.status=0;
                    })
                });
                if(!vm.batchNum){
                    openModal('error-confirm.html', {message: "请先新建批次"}, 'batchNum');
                    fail();
                }else if(!vm.asnNum){
                	openModal('error-confirm.html', {message: "请先扫描到货通知单号"}, 'noticeNum');
                	fail();
                } else if (vm.changeOrderCmd === vm.skuBarcode.trim()) {
                	vm.changeAsnOrder();
                }else{
                	//查找满足需求的收货明细
                	findAsndetailObj(vm.skuBarcode);
                    if(vm.asnDetailObj == null) {
                    	if (!vm.isCanCreateSkuAndAsn) {
                    		openModal('error-confirm.html', {message: "扫描的商品条码无效，或者不在当前订单中！"}, 'skuBarcode');
                        	fail();
                        	return;
                    	} else {
                    		//生成收货单
                        	PcScanReceive.createAsnDetail({},{
                        		asnNum:vm.asnNum,
                        		ownerCode:vm.asnInfo.ownerCode,
                                skuCode:vm.skuBarcode
                            },function (res) {
                            	console.log("createAsnDetail res=",res);
                            	if (res.content.hasSku === false || !res.content.asnDetail) {
                            		vm.showLot=false;
                            		vm.createSku();
//                            		openModal('error-confirm.html', {message: '扫描的商品条码在系统中不存在，条码：'+vm.skuBarcode}, 'skuBarcode');
//                                    fail();
                            	} else {
                            		if (!vm.asnInfo.asnDetails || vm.asnInfo.asnDetails.length == 0) {
                            			vm.asnInfo.asnDetails = [];
                            		}
                            		vm.asnInfo.asnDetails.push(res.content.asnDetail);
                            		vm.asnInfo.asnDetails.forEach(function (v) {
                                        if(v.receiptQty>0 && v.receiptQty!==v.qty){
                                            v.isPart=true;
                                        }
                                        if(v.receiptQty>=v.qty){
                                            v.isFinished=true;
                                        }
                                    });
                            		// 继续执行扫描
                            		success();
                            		vm.scanSkuBarCode(e);
                            	}
                            },function (err) {
                            	console.log("err="+vm.err);
                                openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcode');
                                fail();
                                if(err.data.status==='ERROR'){
                                    vm.showLot=false;
                                }
                            });
                        	return;
                    	}
                    }
                    success();
                    PcScanReceive.loadSkuInfo({},{
                        batchNum:vm.batchNum,
                        asnNum:vm.asnNum,
                        asnLineNum:vm.asnDetailObj.asnLineNum,
                        skuNum:vm.skuBarcode,
                        skuCode:vm.asnDetailObj.skuCode,
                        lotattributeRule:vm.asnDetailObj.lotattributeRule,
                        inputReceiveLPN: vm.inputReceiveLPN,
                        inputReceiveLoc: vm.inputReceiveLoc
                    },function (res) {
                        vm.tenantId=vm.asnDetailObj.tenantId;
                        vm.warehouseCode=vm.asnDetailObj.warehouseCode;
                        var asnDetailBean = angular.fromJson(vm.asnDetailObj.asnDetailJsonStr);
                        //重置生成规则组件的规则
                        vm.rule={
                            bizType:'RECEIPT',
                            needDefault:true,
                            needRequire:true,
                            lotRule:vm.asnDetailObj.lotattributeRule,
                            ownerCode:vm.asnDetailObj.ownerCode,
                            skuCode:vm.asnDetailObj.skuCode,
                            tenantId:vm.tenantId,
                            warehouseCode:vm.warehouseCode,
                            skuBarCode:vm.asnDetailObj.skuBarCode,
                            bizNum : vm.asnInfo.asnNum,
                            bizExtNum : vm.asnInfo.extNum,
                            bizLineNum : vm.asnDetailObj.asnLineNum,
                            bizExtLineNum : vm.asnDetailObj.extLineNum,
                            bizJson : vm.asnInfo.asnJsonStr,
                            bizDetailJson : vm.asnDetailObj.asnDetailJsonStr,
                            packCode: vm.asnDetailObj.packCode,
                            lotAttribute01:asnDetailBean.lotAttribute01,
                            lotAttribute02:asnDetailBean.lotAttribute02,
                            lotAttribute03:asnDetailBean.lotAttribute03,
                            lotAttribute04:asnDetailBean.lotAttribute04,
                            lotAttribute05:asnDetailBean.lotAttribute05,
                            lotAttribute06:asnDetailBean.lotAttribute06,
                            lotAttribute07:asnDetailBean.lotAttribute07,
                            lotAttribute08:asnDetailBean.lotAttribute08,
                            lotAttribute09:asnDetailBean.lotAttribute09,
                            lotAttribute10:asnDetailBean.lotAttribute10,
                            lotAttribute11:asnDetailBean.lotAttribute11,
                            lotAttribute12:asnDetailBean.lotAttribute12,
                        };
                        
                        vm.ownerCode=vm.asnDetailObj.ownerCode;
                        vm.skuCode=vm.asnDetailObj.skuCode;
                        vm.surplusQty=vm.asnDetailObj.receiptQty;
                        vm.locationTotalQty=res.content.locationTotalQty;
                        vm.lpnTotalQty=res.content.lpnTotalQty;

                        var speIndex,hasFirst=false;
                        vm.asnInfo.asnDetails.forEach(function (v,k) {
                            v.isActive=false;
                            if(v.receiptQty>0 && v.receiptQty!==v.qty){
                                v.isPart=true;
                            }
                            if(v.receiptQty>=v.qty){
                                v.isFinished=true;
                            }
                            if(v.asnLineNum == vm.asnDetailObj.asnLineNum && !hasFirst){
                                speIndex=k;
                                v.isActive=true;
                                v.isPart=false;
                                v.isFinished=false;
                                hasFirst=true;
                            }
                        });
                        
                        vm.asnInfo.asnDetails.unshift(vm.asnInfo.asnDetails.splice(speIndex,1)[0]);

                        if(vm.asnDetailObj.requireLicenseNum===1){
                            //表明需要采集序列号,则进行弹出框输入
                            $uibModal.open({
                                templateUrl: 'catchSeqNum.html',
                                controller: catchSeqNumCtrl,
                                controllerAs: '$ctrl',
                                backdrop: 'static'
                            })
                        }else{
                            //不需要采集序列号，直接进行后续操作
                            if(vm.useSameLot){
                                if(vm.lotStorage.length>0){
                                	var ruleObj = null;
                                    vm.lotStorage.forEach(function (v) {
                                        if(v.bizLineNum===vm.asnDetailObj.asnLineNum){
                                        	ruleObj=v;
                                            //先给lot01-12的元素(不管存不存在)都赋值v.lot01-12
                                            //再把此时的vm.rule里的lot值同步手动更新一遍
                                        }
                                    });
                                    if(ruleObj) {
                                    	vm.rule = ruleObj;
                                    	if (vm.isBatchReceive) {
                                    		vm.inputReceiveQty = 1;
                                        	jQuery("#inputReceiveQty").focus();
                                        	jQuery("#inputReceiveQty").select();
                                        } else {
                                        	receipt();
                                        }
                                        return;
                                    }
                                }
                            }
                            if(res.content.needLotAttribute) {
                            	vm.showLot=true;
                            	vm.hasShowLot=true;
                            	$timeout(function () {
                                    var inputLists=jQuery('.lotListComponent').find('input');
                                    jQuery(inputLists[0]).focus();
                                    jQuery(inputLists[0]).select();


                                },500);
                            } else {
                            	//如果不存在，则直接进行收货操作
                            	if (vm.isBatchReceive) {
                            		vm.inputReceiveQty = 1;
                                	jQuery("#inputReceiveQty").focus();
                                	jQuery("#inputReceiveQty").select();
                                } else {
                                	receipt();
                                }
                            }
                        }
                    },function (err) {
                    	console.log("err="+vm.err);
                        openModal('error-confirm.html', {message: err.data.errorMsg}, 'skuBarcode');
                        fail();
                        if(err.data.status==='ERROR'){
                            vm.showLot=false;
                        }
                    });
                }
            }
        }

        //采集序列号弹出框
        catchSeqNumCtrl.$inject=['$uibModalInstance'];
        function catchSeqNumCtrl($uibModalInstance) {
            var me=this;
            var squNumInput;
            $timeout(function () {
                squNumInput=jQuery('#squNum');
                squNumInput.focus();
            },0);

            me.catchSqeNum=catchSqeNum;         //扫描序列号
            me.resetScan=resetScan;             //清空值,重新扫描
            me.completeScan=completeScan;         //完成扫描

            function catchSqeNum(e) {
                if(e.which===13){
                    //代表扫码或者敲回车
                    completeScan();
                }
            }

            function resetScan() {
                me.squNum='';
                squNumInput.focus();
            }

            function completeScan() {
                if(!me.squNum){
                    openModal('error-confirm.html', {message: "请先扫描序列号"}, 'skuBarcode');
                    fail();
                    squNumInput.focus();
                }else{
                    vm.skuSeq=me.squNum;
                    $uibModalInstance.dismiss('cancel');
                  //查找满足需求的收货明细
                	findAsndetailObj(vm.skuBarcode);
                    if(vm.asnDetailObj == null && !vm.isCanCreateSkuAndAsn) {
                    	openModal('error-confirm.html', {message: "扫描的商品条码无效，或者不在当前订单中！"}, 'skuBarcode');
                    	fail();
                    	return;
                    }
                    if(vm.useSameLot){
                        if(vm.lotStorage.length>0){
                        	var ruleObj = null;
                            vm.lotStorage.forEach(function (v) {
                                if(v.bizLineNum===vm.asnDetailObj.asnLineNum){
                                	ruleObj=v;
                                    //先给lot01-12的元素(不管存不存在)都赋值v.lot01-12
                                    //再把此时的vm.rule里的lot值同步手动更新一遍
                                }
                            });
                            if(ruleObj) {
                            	vm.rule = ruleObj;
                            	if (vm.isBatchReceive) {
                            		vm.inputReceiveQty = 1;
                                	jQuery("#inputReceiveQty").focus();
                                	jQuery("#inputReceiveQty").select();
                                } else {
                                	receipt();
                                }
                                return;
                            }
                        }
                    }
                    
                    vm.showLot=true;
                    $timeout(function () {
                        if(vm.rule.lotAttribute01Name || vm.rule.lotAttribute02Name || vm.rule.lotAttribute03Name || vm.rule.lotAttribute04Name || vm.rule.lotAttribute05Name || vm.rule.lotAttribute06Name || vm.rule.lotAttribute07Name || vm.rule.lotAttribute08Name || vm.rule.lotAttribute09Name || vm.rule.lotAttribute10Name || vm.rule.lotAttribute11Name || vm.rule.lotAttribute12Name){
                            //只要存在一个Name，就表示该商品有批次属性，需要进行输入
                            vm.hasShowLot=true;
                            $timeout(function () {
                                var inputLists=jQuery('.lotListComponent').find('input');
                                jQuery(inputLists[0]).focus();
                                jQuery(inputLists[0]).select();

                            },100);
                        }else{
                            //如果不存在，则直接进行收货操作
                            if (vm.isBatchReceive) {
                            	vm.inputReceiveQty = 1;
                            	jQuery("#inputReceiveQty").focus();
                            	jQuery("#inputReceiveQty").select();
                            } else {
                            	receipt();
                            }
                        }
                    },500);
                }
            }
        }

        //打开控制弹出框
        function openModal(templateUrl, model, elemId) {
            var paramItem = angular.extend({errorCtrlCmd: vm.errorCtrlCmd}, model);
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return paramItem;
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.execCmd = execCmd;

                function execCmd() {
                	modalInstance.close();
                    vm.success();
                }
            }
        }

        function checkStatus(data) {
            console.log(data,'xxxxxxxx')
            if(data.status==='SUCCESS'){
                //代表批次属性验证通过，可以进行收货
                //只有勾选了"相同商品沿用上一批次属性才添加进存储数组"
            	if (vm.isBatchReceive) {
            		vm.inputReceiveQty = 1;
                	jQuery("#inputReceiveQty").focus();
                	jQuery("#inputReceiveQty").select();
                } else {
                	receipt();
                }
            }else{

            }
        }
        function findAsndetailObj(skuBarCode) {
        	vm.asnDetailObj = null;
            vm.asnInfo.asnDetails.forEach(function (v) {
            	if(!v.isFinished) {
            		if(v.skuCode===skuBarCode){
                        vm.asnDetailObj = v;
                    }else{
                        v.skuBarCode.forEach(function (m) {
                            if(m===skuBarCode){
                                vm.asnDetailObj = v;
                            }
                        })
                    }
            	}
            });
            if(vm.asnDetailObj == null) {
            	vm.asnInfo.asnDetails.forEach(function (v) {
            		if(v.skuCode===skuBarCode){
                        vm.asnDetailObj = v;
                    }else{
                        v.skuBarCode.forEach(function (m) {
                            if(m===skuBarCode){
                                vm.asnDetailObj = v;
                            }
                        })
                    }
                });
            }
        }
        function receipt() {
        	success();
        	// 先确定该商品对应的lineNum(行号)
            if(vm.asnDetailObj == null) {
            	openModal('error-confirm.html', {message: "扫描的商品条码无效，或者不在当前订单中！"}, 'skuBarcode');
            	fail();
            	return;
            }
            if (vm.isBatchReceive && (!vm.inputReceiveQty || vm.inputReceiveQty <= 0)) {
            	openModal('error-confirm.html', {message: "请输入收货数量！"}, 'inputReceiveQty');
            	fail();
            	return;
            }
            var lineNum=vm.asnDetailObj.asnLineNum;
            var newrule = null;
        	
            if(vm.useSameLot){
            	
            	vm.lotStorage.forEach(function (v) {
                    if(v.bizLineNum===vm.asnDetailObj.asnLineNum){
                    	newrule = v;
                    }
                });
            	if(!newrule) {
            		console.log("new");
            		newrule = {
                            bizType: "RECEIPT",
                            needDefault:true,
                            needRequire:true,
                            bizNum : vm.asnInfo.asnNum,
                            bizExtNum : vm.asnInfo.extNum,
                            bizLineNum : vm.asnDetailObj.asnLineNum,
                            bizExtLineNum : vm.asnDetailObj.extLineNum,
                            bizJson : vm.asnInfo.asnJsonStr,
                            bizDetailJson : vm.asnDetailObj.asnDetailJsonStr,
                            
                            lotAttribute01:vm.rule.lotAttribute01,
                            lotAttribute01Name:vm.rule.lotAttribute01Name,
                            lotAttribute02:vm.rule.lotAttribute02,
                            lotAttribute02Name:vm.rule.lotAttribute02Name,
                            lotAttribute03:vm.rule.lotAttribute03,
                            lotAttribute03Name:vm.rule.lotAttribute03Name,
                            lotAttribute04:vm.rule.lotAttribute04,
                            lotAttribute04Name:vm.rule.lotAttribute04Name,
                            lotAttribute05:vm.rule.lotAttribute05,
                            lotAttribute05Name:vm.rule.lotAttribute05Name,
                            lotAttribute06:vm.rule.lotAttribute06,
                            lotAttribute06Name:vm.rule.lotAttribute06Name,
                            lotAttribute07:vm.rule.lotAttribute07,
                            lotAttribute07Name:vm.rule.lotAttribute07Name,
                            lotAttribute08:vm.rule.lotAttribute08,
                            lotAttribute08Name:vm.rule.lotAttribute08Name,
                            lotAttribute09:vm.rule.lotAttribute09,
                            lotAttribute09Name:vm.rule.lotAttribute09Name,
                            lotAttribute10:vm.rule.lotAttribute10,
                            lotAttribute10Name:vm.rule.lotAttribute10Name,
                            lotAttribute11:vm.rule.lotAttribute11,
                            lotAttribute11Name:vm.rule.lotAttribute11Name,
                            lotAttribute12:vm.rule.lotAttribute12,
                            lotAttribute12Name:vm.rule.lotAttribute12Name,
                            lotRule:vm.rule.lotRule,
                            ownerCode:vm.rule.ownerCode,
                            skuBarCode:vm.rule.skuBarCode,
                            skuCode:vm.rule.skuCode,
                            tenantId:vm.rule.tenantId,
                            warehouseCode:vm.rule.warehouseCode
                        };
            		vm.lotStorage.push(newrule);
            	} else {
            		console.log("update");
            		newrule.bizType= "RECEIPT";
            		newrule.needDefault=true;
            		newrule.needRequire=true;
            		newrule.bizNum = vm.asnInfo.asnNum;
            		newrule.bizExtNum = vm.asnInfo.extNum;
            		newrule.bizLineNum = vm.asnDetailObj.asnLineNum;
            		newrule.bizExtLineNum = vm.asnDetailObj.extLineNum;
            		newrule.bizJson = vm.asnInfo.asnJsonStr;
            		newrule.bizDetailJson = vm.asnDetailObj.asnDetailJsonStr;

            		newrule.lotAttribute01=vm.rule.lotAttribute01;
            		newrule.lotAttribute01Name=vm.rule.lotAttribute01Name;
            		newrule.lotAttribute02=vm.rule.lotAttribute02;
            		newrule.lotAttribute02Name=vm.rule.lotAttribute02Name;
            		newrule.lotAttribute03=vm.rule.lotAttribute03;
            		newrule.lotAttribute03Name=vm.rule.lotAttribute03Name;
            		newrule.lotAttribute04=vm.rule.lotAttribute04;
            		newrule.lotAttribute04Name=vm.rule.lotAttribute04Name;
            		newrule.lotAttribute05=vm.rule.lotAttribute05;
            		newrule.lotAttribute05Name=vm.rule.lotAttribute05Name;
            		newrule.lotAttribute06=vm.rule.lotAttribute06;
            		newrule.lotAttribute06Name=vm.rule.lotAttribute06Name;
            		newrule.lotAttribute07=vm.rule.lotAttribute07;
            		newrule.lotAttribute07Name=vm.rule.lotAttribute07Name;
            		newrule.lotAttribute08=vm.rule.lotAttribute08;
            		newrule.lotAttribute08Name=vm.rule.lotAttribute08Name;
            		newrule.lotAttribute09=vm.rule.lotAttribute09;
            		newrule.lotAttribute09Name=vm.rule.lotAttribute09Name;
            		newrule.lotAttribute10=vm.rule.lotAttribute10;
            		newrule.lotAttribute10Name=vm.rule.lotAttribute10Name;
            		newrule.lotAttribute11=vm.rule.lotAttribute11;
            		newrule.lotAttribute11Name=vm.rule.lotAttribute11Name;
            		newrule.lotAttribute12=vm.rule.lotAttribute12;
            		newrule.lotAttribute12Name=vm.rule.lotAttribute12Name;
            		newrule.lotRule=vm.rule.lotRule;
            		newrule.ownerCode=vm.rule.ownerCode;
            		newrule.skuBarCode=vm.rule.skuBarCode;
            		newrule.skuCode=vm.rule.skuCode;
            		newrule.tenantId=vm.rule.tenantId;
            		newrule.warehouseCode=vm.rule.warehouseCode;
            	}
            	console.log(vm.lotStorage);
            }
            var inputLists=jQuery('.lotListComponent').find('input');
            var len=inputLists.length;
            var input=inputLists[len-1];
            jQuery(input).blur();
            
            PcScanReceive.scanReceipt({},{
                batchNum:vm.batchNum,
                asnNum:vm.asnNum,
                lineNum:lineNum,
                ownerCode : vm.ownerCode,
                skuCode : vm.skuCode,
                inputReceiveQty:vm.isBatchReceive ? vm.inputReceiveQty : 1, // 收货数量
                inputReceiveLPN: vm.isNeedLPN ? vm.inputReceiveLPN : '',     // 容器号
                inputReceiveLoc: vm.isNeedLoc ? vm.inputReceiveLoc : vm.asnDetailObj.receiveLoc,     // 收货库位
                inputReceiveCase:newrule.receiveCaseNum,    // 收货箱号
                seriNum:newrule.receiveSeriNum,
                skuSeq:vm.skuSeq,         //序列号，看是否需要采集，如果不需要直接传空
                lotAttribute01:vm.rule.lotAttribute01?vm.rule.lotAttribute01:'',
                lotAttribute01Name:vm.rule.lotAttribute01Name?vm.rule.lotAttribute01Name:'',
                lotAttribute02:vm.rule.lotAttribute02?vm.rule.lotAttribute02:'',
                lotAttribute02Name:vm.rule.lotAttribute02Name?vm.rule.lotAttribute02Name:'',
                lotAttribute03:vm.rule.lotAttribute03?vm.rule.lotAttribute03:'',
                lotAttribute03Name:vm.rule.lotAttribute03Name?vm.rule.lotAttribute03Name:'',
                lotAttribute04:vm.rule.lotAttribute04?vm.rule.lotAttribute04:'',
                lotAttribute04Name:vm.rule.lotAttribute04Name?vm.rule.lotAttribute04Name:'',
                lotAttribute05:vm.rule.lotAttribute05?vm.rule.lotAttribute05:'',
                lotAttribute05Name:vm.rule.lotAttribute05Name?vm.rule.lotAttribute05Name:'',
                lotAttribute06:vm.rule.lotAttribute06?vm.rule.lotAttribute06:'',
                lotAttribute06Name:vm.rule.lotAttribute06Name?vm.rule.lotAttribute06Name:'',
                lotAttribute07:vm.rule.lotAttribute07?vm.rule.lotAttribute07:'',
                lotAttribute07Name:vm.rule.lotAttribute07Name?vm.rule.lotAttribute07Name:'',
                lotAttribute08:vm.rule.lotAttribute08?vm.rule.lotAttribute08:'',
                lotAttribute08Name:vm.rule.lotAttribute08Name?vm.rule.lotAttribute08Name:'',
                lotAttribute09:vm.rule.lotAttribute09?vm.rule.lotAttribute09:'',
                lotAttribute09Name:vm.rule.lotAttribute09Name?vm.rule.lotAttribute09Name:'',
                lotAttribute10:vm.rule.lotAttribute10?vm.rule.lotAttribute10:'',
                lotAttribute10Name:vm.rule.lotAttribute10Name?vm.rule.lotAttribute10Name:'',
                lotAttribute11:vm.rule.lotAttribute11?vm.rule.lotAttribute11:'',
                lotAttribute11Name:vm.rule.lotAttribute11Name?vm.rule.lotAttribute11Name:'',
                lotAttribute12:vm.rule.lotAttribute12?vm.rule.lotAttribute12:'',
                lotAttribute12Name:vm.rule.lotAttribute12Name?vm.rule.lotAttribute12Name:''
            },function (res) {
                //收货成功
                if(res.content.resultType==='success'){
                    vm.skuBarcode='';
                    vm.showLot=false;               //隐藏批次属性输入框
                    vm.surplusQty='';            //隐藏剩余量框框

                    vm.locationTotalQty='';
                    vm.lpnTotalQty='';
                    skuBarcodeInput.focus();

                    vm.showBox=res.content.data.containerInfo;

                    //更新右上角四格小表格数据
                    vm.lotSkuData.skuCount=res.content.data.batchSkuCount;
                    vm.lotSkuData.EACount=res.content.data.batchEACount;
                    vm.lotSkuData.asnCount=res.content.data.batchAsnCount;
                    vm.lotSkuData.containerCount=res.content.data.batchContainerCount;

                    //更新容器组的变化
                    vm.rows.forEach(function (m) {
                        m.columns.forEach(function (v) {
                            v.status=0;
                            if(v.caseNum===res.content.data.containerInfo.caseNum){
                                v.skuCount=res.content.data.containerInfo.skuCount;
                                v.receiptedCount=res.content.data.containerInfo.receiptedCount;
                                v.status=res.content.data.containerInfo.status;
                            }
                        })
                    });
                    
                    //更新底部表格已收数量的数据
                    vm.asnInfo.receiptedEACount=res.content.data.asnReceiptedEACount;
                    vm.asnDetailObj.receiptQty=res.content.data.asnDetail.receiptQty;
                    vm.asnDetailObj.lpnTotalQty=res.content.data.asnDetail.lpnTotalQty;
                    vm.asnDetailObj.locationTotalQty=res.content.data.asnDetail.locationTotalQty;
                    if (res.content.data.containerInfo) {
                    	newrule.receiveSeriNum = res.content.data.containerInfo.seriNum;
                        newrule.receiveCaseNum = res.content.data.containerInfo.caseNum;
                    }
//                    if(vm.asnDetailObj.receiptQty>0 && vm.asnDetailObj.receiptQty!==vm.asnDetailObj.qty){
//                    	vm.asnDetailObj.isPart=true;
//                    }
//                    if(vm.asnDetailObj.receiptQty>=vm.asnDetailObj.qty){
//                    	vm.asnDetailObj.isFinished=true;
//                    }
                    vm.surplusQty=vm.asnDetailObj.receiptQty;
                    vm.locationTotalQty=res.content.locationTotalQty;
                    vm.lpnTotalQty=res.content.lpnTotalQty;
//                    vm.asnDetailObj = null;
                    success();
                }
            },function (err) {
                openModal('error-confirm.html', {message: err.data.errorMsg}, 'batchNum');
                fail();
            });
        }

        //重置批次属性规则
        function resetRule() {
            if(vm.rule.lotAttribute01){
                vm.rule.lotAttribute01='';
            }
            if(vm.rule.lotAttribute01Name){
                vm.rule.lotAttribute01Name='';
            }
            if(vm.rule.lotAttribute02){
                vm.rule.lotAttribute02='';
            }
            if(vm.rule.lotAttribute02Name){
                vm.rule.lotAttribute02Name='';
            }
            if(vm.rule.lotAttribute03){
                vm.rule.lotAttribute03='';
            }
            if(vm.rule.lotAttribute03Name){
                vm.rule.lotAttribute03Name='';
            }
            if(vm.rule.lotAttribute04){
                vm.rule.lotAttribute04='';
            }
            if(vm.rule.lotAttribute04Name){
                vm.rule.lotAttribute04Name='';
            }
            if(vm.rule.lotAttribute05){
                vm.rule.lotAttribute05='';
            }
            if(vm.rule.lotAttribute05Name){
                vm.rule.lotAttribute05Name='';
            }
            if(vm.rule.lotAttribute06){
                vm.rule.lotAttribute06='';
            }
            if(vm.rule.lotAttribute06Name){
                vm.rule.lotAttribute06Name='';
            }
            if(vm.rule.lotAttribute07){
                vm.rule.lotAttribute07='';
            }
            if(vm.rule.lotAttribute07Name){
                vm.rule.lotAttribute07Name='';
            }
            if(vm.rule.lotAttribute08){
                vm.rule.lotAttribute08='';
            }
            if(vm.rule.lotAttribute08Name){
                vm.rule.lotAttribute08Name='';
            }
            if(vm.rule.lotAttribute09){
                vm.rule.lotAttribute09='';
            }
            if(vm.rule.lotAttribute09Name){
                vm.rule.lotAttribute09Name='';
            }
            if(vm.rule.lotAttribute10){
                vm.rule.lotAttribute10='';
            }
            if(vm.rule.lotAttribute10Name){
                vm.rule.lotAttribute10Name='';
            }
            if(vm.rule.lotAttribute11){
                vm.rule.lotAttribute11='';
            }
            if(vm.rule.lotAttribute11Name){
                vm.rule.lotAttribute11Name='';
            }
            if(vm.rule.lotAttribute12){
                vm.rule.lotAttribute12='';
            }
            if(vm.rule.lotAttribute12Name){
                vm.rule.lotAttribute12Name='';
            }
        }

        function fail() {
        	_PlayErrorAudio();
            vm.checkResult = {status: 'ERROR', message: '处理错误'};
        }

        function success() {
            vm.checkResult = {status: 'SUCCESS', message: '处理成功'};
        }

        //清除页面数据的方法
        function clearData() {
            vm.lotSkuData={};
            vm.asnNum='';
            vm.asnInfo={};
            
            vm.skuBarcode='';
            vm.rows=[];
            vm.surplusQty='';
            vm.locationTotalQty='';
            vm.lpnTotalQty='';
            vm.showLot=false;
            vm.hasShowLot=false;
            vm.rule={
                bizType:'RECEIPT',
                needDefault:true,
                needRequire:true,
                lotRule:'',
                ownerCode:'',
                skuCode:'',
                tenantId:'',
                warehouseCode:'',
                skuBarCode:''
            };
            // console.log(vm.lotSkuData)
        }
      batchNumInput.focus();
      batchNumInput.select();
      vm.openInitParamWindow();
    }
})();
