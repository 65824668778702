(function() {
    "use strict";
    angular.module("itms-dataModule").factory("TmsPartner", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsDataHost + "/web/partner", {}, {
        	'list': { url: iServiceConfig.itmsDataHost + "/web/partner/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsDataHost + "/web/partner/saveAndUpdate", method: 'POST'},
            'show': {url: iServiceConfig.itmsDataHost + "/web/partner/show", method: 'POST'},
            'delete': {url: iServiceConfig.itmsDataHost + "/web/partner/delete", method: 'POST'},
            'enable': {url: iServiceConfig.itmsDataHost + "/web/partner/enable", method: 'POST'},
            'create': {url: iServiceConfig.itmsDataHost + "/web/partner/create", method: 'POST'},
            'validateCode': {url: iServiceConfig.itmsDataHost + "/web/partner/validateCode", method: 'POST'}
        });
    }]);
})();