(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('CheckWholeCasePackController', CheckWholeCasePackController);

    CheckWholeCasePackController.$inject = ['$rootScope', '$timeout', 'NgTableParams', 'BoxBiz', 'toastr', '$filter','$uibModal'];

    function CheckWholeCasePackController ($rootScope, $timeout, NgTableParams, BoxBiz, toastr, $filter,$uibModal) {
        var vm = this;

        vm.confirmStatus = {status: 'SUCCESS', message: '成功'}; // SUCCESS|ERROR
        vm.params = {soNum:"",boxNum:""};
        vm.packSoObj =  null;
        vm.boxObj =  null;
        vm.needPrintList = false;
        vm.needPrintWaybill = false;
        vm.params = {
                listPrinter:"",
                waybillNumPrinter:"",
            }
        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'},
            off: {'background-color': '#868e96', 'color': '#fff'}
        };

        vm.loadBoxBySoNum = loadBoxBySoNum; 
        vm.inputBoxNum = inputBoxNum; 
        vm.packAndClose = packAndClose;
        vm.submitCloseBox = submitCloseBox;
        vm.takeWeight = takeWeight;
        vm.packMaterialCode = packMaterialCode;
        vm.waybillNum = waybillNum;
        
        vm.styleFn = styleFn; //明细样式
        vm.success = success;
        vm.fail = fail;

        vm.openInitParamWindow = function openInitParamWindow() {
            $uibModal.open({
                templateUrl: 'initParam.html',
                controller: openInitParamCtrl,
                controllerAs: '$ctrl',
            })
        }
        
        openInitParamCtrl.$inject=['$uibModalInstance','$scope'];
        function openInitParamCtrl($uibModalInstance,$scope) {
            var me=this;
            me.listPrinter=vm.params.listPrinter;
            me.waybillNumPrinter=vm.params.waybillNumPrinter;
            me.isPrintList=vm.needPrintList;
            me.isPrintSheet=vm.needPrintWaybill;
            
            me.entryInMainPage=entryInMainPage;
            function entryInMainPage() {
            	vm.params.listPrinter = me.listPrinter;
            	vm.params.waybillNumPrinter = me.waybillNumPrinter;
            	vm.needPrintList = me.isPrintList;
            	vm.needPrintWaybill = me.isPrintSheet;
            	$uibModalInstance.dismiss('close');
            }
            me.close = function close() {
            	$uibModalInstance.dismiss('close');
            }
        }
        
        function packMaterialCode($event,materialCode) {
        	if(materialCode && $event.key === 'Enter') {
        		vm.boxObj.packMaterialCode = materialCode;
        		if (vm.boxObj.needScanWaybillNum) {
            		jQuery('#waybillNum').select();
            	} else if (!vm.boxObj.needScanWaybillNum && vm.boxObj.needWeighPack) {
            		jQuery('#takeWeight').select();
            	} else {
            		$timeout(function() {
                        jQuery('#confirmBtn').click();
                    });
            	}
        	}
        }
        function waybillNum($event,waybill) {
        	if(waybill && $event.key === 'Enter') {
        		vm.boxObj.waybillNum = waybill;
            	
            	if (vm.boxObj.needWeighPack) {
            		jQuery('#takeWeight').select();
            	} else {
            		$timeout(function() {
                        jQuery('#confirmBtn').click();
                    });
            	}
        	}
        }
        function takeWeight($event,takeWeight) {
        	if(takeWeight && $event.key === 'Enter') {
        		vm.boxObj.takeWeight = takeWeight;
        		vm.boxObj.diffWeight = vm.boxObj.takeWeight - vm.boxObj.stdWeight;
     		    if (vm.boxObj.diffWeight < 0) {
     		    	vm.boxObj.diffWeight = -vm.boxObj.diffWeight;
     		    }
     		    vm.boxObj.isOverstep = false;
     		   vm.boxObj.diffWeightPercent =  vm.boxObj.diffWeight/vm.boxObj.stdWeight * 100;
     		    if (vm.boxObj.diffWeightPercent > vm.boxObj.maxAllocWeightDiff) {
     			   vm.boxObj.isOverstep = true;
     			   fail('称重重量超出预期');
     			   return;
     		    } else {
     		    	$timeout(function() {
                        jQuery('#confirmBtn').click();
                    });
     		    }
        	}
        }
        function inputBoxNum($event, boxNum) {
        	vm.boxObj = null;
            if(boxNum && $event.key === 'Enter') {
            	success();
            	angular.forEach(vm.packSoObj.boxList, function(box) {
                    if (box.status < 30 && box.boxNum == boxNum) {
                    	vm.boxObj = box;
                    	vm.boxObj.takeWeight = box.stdWeight;
                    	vm.boxObj.isOverstep = false;
                    	
                    	vm.boxObj.diffWeight = 0;
                    	vm.boxObj.diffWeightPercent = 0;
                    	
                    	vm.boxObj.packMaterialCode = '';
                    	vm.boxObj.waybillNum = '';
                    }
                });
            	if (!vm.boxObj) {
            		fail("未找到对于装箱号："+boxNum);
                    jQuery('#boxNum').select();
            	} else {
            		success();
                	BoxBiz.getPackBoxByNum({num: boxNum}, {}, function(data) {
                        if (data.content) {
                        	vm.boxObj.pickDetails = data.content.pickDetails;
                        	if (vm.boxObj.needScanPackMaterialCode) {
                        		jQuery('#packMaterialCode').select();
                        	} else if (!vm.boxObj.needScanPackMaterialCode && vm.boxObj.needScanWaybillNum) {
                        		jQuery('#waybillNum').select();
                        	} else if (!vm.boxObj.needScanPackMaterialCode && !vm.boxObj.needScanWaybillNum && vm.boxObj.needWeighPack) {
                        		jQuery('#takeWeight').select();
                        	} else {
//                        		$timeout(function() {
//                                    jQuery('#confirmBtn').click();
//                                });
                        	}
                        } else {
                            fail('不存在装箱号'+boxNum);
                            jQuery('#boxNum').select();
                            vm.boxObj = null;
                        }
                    }, function(resp) {
                        if (resp.data && resp.data.errorMsg) {
                            fail(resp.data.errorMsg);
                            jQuery('#boxNum').select();
                            vm.boxObj = null;
                        }
                    });
            	}
            }
            
        }
        
        function loadBoxBySoNum($event, soNum) {
            vm.packSoObj =  null;
            vm.boxObj= null;
            vm.params.boxNum = '';
            if(soNum && $event.key === 'Enter') {
            	success();
            	BoxBiz.getBoxsBySoNum({soNum: soNum}, {}, function(data) {
                    if (data.content) {
                    	vm.packSoObj = data.content;
                        success();
                        $timeout(function() {
                        	jQuery('#boxNum').select();
                        });
                    } else {
                        fail('不存在出库单'+soNum);
                        jQuery('#soNum').select();
                    }
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        fail(resp.data.errorMsg);
                        jQuery('#soNum').select();
                    }
                });
            }
        }
        
        function submitCloseBox(box) {
        	if (box) {
         	   success();
         	   
         	   var closeBox = {
         			   boxNum:box.boxNum,
         			   waybillNum:box.waybillNum,
         			   packMaterialCode:box.packMaterialCode,
         			   printList:vm.needPrintList || box.printList,
         			   printWaybillNum:vm.needPrintWaybill || box.printWaybillNum,
         			   weight:box.takeWeight,
         			   listPrinter: vm.params.listPrinter,
                       waybillNumPrinter: vm.params.waybillNumPrinter
         	   };
         	   BoxBiz.packAndCloseBox({}, closeBox, function(data) {
                    toastr.success('复核确认成功');
                    vm.params.boxNum = "";
                    jQuery('#boxNum').select();
                    vm.boxObj.status = 30;
                    vm.packSoObj.pendingBoxCount = vm.packSoObj.pendingBoxCount - 1;
                    vm.packSoObj.doneBoxCount = vm.packSoObj.doneBoxCount + 1;
                    vm.boxObj = null;
                    if (vm.packSoObj.pendingBoxCount <= 0) {
                 	   toastr.success('出库单:'+vm.params.soNum+' 已全部复核确认完成');
                 	   vm.params.soNum = "";
                 	   vm.params.boxNum = '';
                 	   jQuery('#boxNum').select();
                 	   vm.packSoObj = null;
                    }
                }, function (resp) {
                    if (resp.data && resp.data.errorMsg) {
                        fail(resp.data.errorMsg);
                    }
                });
            } else {
                fail('参数不全');
            }
        }
        function packAndClose(box) {
           if (box) {
        	   success();
        	   
        	   if (box.needWeighPack) {
        		   box.diffWeight = box.takeWeight - box.stdWeight;
        		   if (box.diffWeight < 0) {
        			   box.diffWeight = -box.diffWeight;
        		   }
        		   vm.boxObj.isOverstep = false;
        		   box.diffWeightPercent =  box.diffWeight/box.stdWeight * 100;
        		   if (box.diffWeightPercent > box.maxAllocWeightDiff) {
        			   vm.boxObj.isOverstep = true;
        			   fail('称重重量超出预期');
        			   jQuery('#takeWeight').select();
        			   return;
        		   }
        	   }
        	   
        	   vm.submitCloseBox(box);
           } else {
               fail('参数不全');
           }
        }

        function styleFn(item) {
            if (item.status >= 30) {
                return vm.alertColor.done;
            }

            return {};
        }

        function fail(msg) {
        	_PlayErrorAudio();
        	toastr.error(msg);
            vm.checkStatus = {status: 'ERROR', message: msg};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }
        
        vm.success();
        vm.openInitParamWindow();
    }
})();
