(function () {
    'use strict';

    angular.module('doveModule')
        .controller('iBusImportTaskController', iBusImportTaskController);

    iBusImportTaskController.$inject = ['iBusFileImportTask', 'NgTableParams', '$timeout', 'toastr', 'enums','iServiceConfig','$localStorage'];

    function iBusImportTaskController(iBusFileImportTask, NgTableParams, $timeout, toastr, enums,iServiceConfig,$localStorage) {
        //-----------------------------------------------------------------------//
        //----------------START--------------------------------------------------//
        //-----------------------------------------------------------------------//
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = enums("bus.ExecuteTaskStatus").list("title", "id");
        vm.executeTaskStatus = enums("bus.ExecuteTaskStatus");
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusFileImportTask.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.removeTab = removeTab;
        vm.preSave = preSave;
        vm.save = save;
        vm.search = search;
        vm.cancel = cancel;
        vm.getLogs = getLogs;
        vm.downloadTemplate = downloadTemplate;
        vm.downloadProMsgFile = downloadProMsgFile;
        vm.executeFile = executeFile;
        vm.downloadTaskFile = downloadTaskFile;
        vm.active = 0;

        function preSave() {
            iBusFileImportTask.preSave({}, {}, function (resp) {
                var entity = {
                    heading: "导入",
                    contentId: "importTask-eidt.html",
                    data: null,
                    templates: resp.content,
                    template: null,
                    taskFile: null,
                    roles: [],
                    active: true
                };
                vm.tabs.push(entity);
                //跳转到指定选项卡
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        }

        function save(tab, idx, form) {
            form.passwordUnmatch = "";
            var valid = form.$valid;
            if (!valid) {
                toastr.info("信息未填完");
                form.showMsg = true;
                return;
            }


            var data = {
                templateCode: tab.template.code,
                templateName: tab.template.name,
                domainCode: tab.template.domainCode,
                domainName: tab.template.domainName,
                templateType: tab.template.templateType,
                processCode: tab.template.processCode,
                processName: tab.template.processName,
                fileMaxSize: tab.template.fileMaxSize,
                taskFile: tab.taskFile
            };
            //校验
            //检测用户文件是否选择并且文件类型是否正确
            if (!!!data.taskFile || !!!data.taskFile[0]) {
                toastr.info("文件尚未选择");
                return;
            } else {
                var filename = data.taskFile[0].name;
                var subfix = filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
                var type = data.templateType.toUpperCase();
                var flag = false;
                if (type == "EXCEL") {
                    flag = (subfix == "xls" || subfix == "xlsx");
                } else if (type == "XML") {
                    flag = (subfix == "xml");
                } else if (type == "JSON") {
                    flag = (subfix == "json");
                } else if (type == "CSV") {
                    flag = (subfix == "csv");
                }
                if (!flag) {
                    toastr.error("上传的文件必须是" + type);
                    return;
                }
                var fileSize = data.taskFile[0].size;
                //检测文件大小
                if (fileSize <= 0) {
                    toastr.error("你不能上传空文件");
                    return;
                }
                var fileMaxSize = data.fileMaxSize * 1024 * 1024;
                if (fileMaxSize < fileSize) {
                    toastr.error("上传的文件必须小于" + data.fileMaxSize + "MB");
                    return;
                }
            }
            iBusFileImportTask.save({}, data, function (resp) {
                // console.log("resp.content");
                // console.log(resp.content);
                if (!!resp.content.taskNum) {
                    toastr.success("上传成功,任务号为" + resp.content.taskNum);
                } else {
                    toastr.error("上传失败");
                }
                vm.removeTab(idx);
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function cancel(row) {
            iBusFileImportTask.cancel({
                id: row.id
            }, {}, function (resp) {
                row.status = resp.content.status;
                row.uploadName = resp.content.uploadName;
                row.uploadDt = resp.content.uploadDt;
            });
        }

        function getLogs(row) {
            vm.tableParamsLog = new NgTableParams({count: 10}, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    }, params.filter());
                    return iBusFileImportTask.showLogs({taskNum: row.taskNum}, postParams, function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            });
            //加载
            return vm.tableParamsLog;
        }

        function downloadTemplate(template) {
        	var url = iServiceConfig.ibusHost + "/web/importTemplate/download/"+template.id+"?token="+$localStorage.authenticationToken;
        	var a = document.createElement('a');
            document.body.appendChild(a); //此处增加了将创建的添加到body当中
            a.href = url;
            a.target = '_blank';
            a.click();
            a.remove();
        }

        function downloadTaskFile(id, taskNum) {
        	var url = iServiceConfig.ibusHost + "/web/importTask/downloadTaskFile/"+id+"?token="+$localStorage.authenticationToken;
        	var a = document.createElement('a');
            document.body.appendChild(a); //此处增加了将创建的添加到body当中
            a.href = url;
            a.target = '_blank';
            a.click();
            a.remove();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function downloadProMsgFile(row,taskNum) {
        	var url = iServiceConfig.ibusHost + "/web/importTask/downloadMsgFile/"+row.id+"?token="+$localStorage.authenticationToken;
        	var a = document.createElement('a');
            document.body.appendChild(a); //此处增加了将创建的添加到body当中
            a.href = url;
            a.target = '_blank';
            a.click();
            a.remove();
        }

        function executeFile(row) {
            iBusFileImportTask.executeFile({
                id: row.id
            }, {}, function (resp) {
            	toastr.success("已成功开始执行导入, 请耐心等待执行完成, 任务号:" + row.taskNum);
            	vm.tableParams.reload();
            });
        }
    }
})();
