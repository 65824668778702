(function () {
    'use strict';
    angular.module('iergateModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('ergatedsdatasource', {
                parent: 'main',
                url: 'ergate/dsdatasource',
                data: {
                    'pageTitle': '配置中心-动态数据源配置'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'ergate-module/dsdatasource/dsdatasource.html',
                        controller: 'ErgateDsDataSourceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();

