(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('ReplenishTask', ReplenishTask);

    ReplenishTask.$inject = ['$resource', 'iServiceConfig'];

    function ReplenishTask ($resource, iServiceConfig) {
        var service = $resource('web/replenishTask', {}, {
            'scanReplenishNum': { url: iServiceConfig.iwhInnerHost + "/web/replenishTask/scanReplenishNum", method: 'POST'},
            'replenish': {url: iServiceConfig.iwhInnerHost + "/web/replenishTask/replenish", method: 'POST'},
        });

        return service;
    }
})();
