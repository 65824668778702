(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('automation', {
            parent: 'main',
            url: 'iwh-strategy/automation',
            data: {'pageTitle': '出库流程自动化'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/auto/auto.html',
                    controller: 'AutomationController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('Automation', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/shipConfig', {}, {
            'task': {url: iServiceConfig.iwhStrategyHost + '/web/shipConfig/task', method: 'POST'},
            'save': {url: iServiceConfig.iwhStrategyHost + '/web/shipConfig/task/save', method: 'POST'}
        });
    }]);

    app.controller('AutomationController', [
        '$scope', 'Automation', 'NgTableParams', '$timeout', 'toastr','enums','$uibModal','iServiceConfig',
        function ($scope, Automation, NgTableParams, $timeout, toastr,enums,$uibModal,iServiceConfig) {

            $scope.active = 0;
            $scope.strategyHost = iServiceConfig.iwhStrategyHost;

            //枚举
            $scope.allocTask = enums('strategy.AllocTaskType');//状态
            $scope.allocTaskType = $scope.allocTask.list();//状态
            $scope.pkoTask = enums('strategy.PKOTaskType');//状态
            $scope.pkoTaskType = $scope.pkoTask.list();//状态
            $scope.printTask = enums('strategy.PrintTaskType');//状态
            $scope.printTaskType = $scope.printTask.list();//状态

            $scope.task = {
                allocType:null,
                allocRuleCode:null,
                allocCron:null,
                pkoType:null,
                pkoRuleCode:null,
                pkoCron:null,
                printType:null,
                printRuleCode:null,
                printCron:null
            };

            //获取任务数据
            $scope.getDataFun = function () {
                Automation.task({},{},function (res) {
                    $timeout(function () {
                        if(res.content){
                            $timeout(function () {
                                $scope.task.allocType = res.content.allocType;
                                $scope.task.allocRuleCode = res.content.allocRuleCode;
                                $scope.task.allocCron = res.content.allocCron;
                                $scope.task.pkoType = res.content.pkoType;
                                $scope.task.pkoRuleCode = res.content.pkoRuleCode;
                                $scope.task.pkoCron = res.content.pkoCron;
                                $scope.task.printType = res.content.printType;
                                $scope.task.printRuleCode = res.content.printRuleCode;
                                $scope.task.printCron = res.content.printCron;
                            });
                        }
                    });
                });
            };
            $scope.getDataFun();

            //保存数据
            $scope.save = function () {
                var obj = angular.copy($scope.task);

                obj.allocRuleCode = 'NOTSUPPORT';
                Automation.save({},obj,function (res) {
                    toastr.success('保存成功','成功');
                });
            };

            //改变状态时，对某些属性置空
            $scope.resetConfig = function (field,status,rule,cron) {
                if(field == 'alloc'){
                    if(status === 0){
                        $scope.task[rule] = null;
                        $scope.task[cron] = null;
                    }else if(status === 10){
                        $scope.task[cron] = null;
                    }
                }else{
                    if(status === 0){
                        $scope.task[rule] = null;
                        $scope.task[cron] = null;
                    }
                }
            }
        }
    ]);
})();
