(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('TransferController', TransferController);

    TransferController.$inject = ['$rootScope', '$timeout', 'TransferBatch', 'Box','$uibModal', 'toastr', 'enums'];

    function TransferController ($rootScope, $timeout, TransferBatch, Box,$uibModal, toastr, enums) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];

        vm.changeOrderCmd = '$CHANGEPKO$';
        vm.errorCtrlCmd = '$ERRORCTRL$';
        vm.endScanCmd = '$ENDSCAN$';
        vm.tb = null;
        vm.box = null;
        vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        vm.params = {};
        vm.removeBox = false;
        vm.carrier2PutinLoc = {};
        vm.putinLocs = [];
        vm.weightDone = false;
        vm.TransferBatchStatusObj = enums('ship.TransferBatchStatus');


        vm.createNewBatch = createNewBatch;
        vm.scanTransferBatchNum = scanTransferBatchNum;
        vm.scanBoxNum = scanBoxNum;
        vm.weightChange = weightChange;
        vm.completeTransfe = completeTransfe;
        vm.removeTab = removeTab;
        vm.addBoxToTransferBatch = _addBoxToTransferBatch;
        vm.success = success;
        vm.fail = fail;

        function createNewBatch(createBatchVo) {
            if (createBatchVo) {
            	vm.success();
                TransferBatch.saveTransferBatch(createBatchVo, function(data) {
                    if (data) {
                        _reset();
                        vm.params.transferNum = "";
                        vm.params.boxNum = "";

                        vm.tb = data.content;
                        vm.params.transferNum = vm.tb.transferNum;

                        vm.putinLocs = [];
                        vm.carrier2PutinLoc = {};
                        for (var i=0; i<vm.tb.carrierCount; i++) {
                            vm.putinLocs.push(('00' + (i+1)).slice(-3));
                        }

                        $timeout(function() {
                            success();
                            jQuery('#scanBoxNum').select();
                        });
                    }
                },function(data) {
                	fail("错误");
                });
            }
        }

        function scanTransferBatchNum(transferNum, $event) {
            if ($event && $event.which === 13) {
                if (transferNum === vm.changeOrderCmd || transferNum === vm.endScanCmd) {
                    _execCmd(transferNum);
                } else {
                    if (transferNum) {
                    	vm.success();
                        TransferBatch.getTransferBatch({}, {
                            transferNum: transferNum
                        }, function(data) {
                            _reset();
                            vm.params.boxNum = "";

                            if (vm.TransferBatchStatusObj.value('DONE') <= data.content.status) {
                                openModal('error-confirm.html'
                                    , {message: '批次号' + data.content.transferNum + '已经完成交接'}, 'scanTransferNum');
                                return;
                            }

                            vm.tb = data.content;
                            vm.putinLocs = [];
                            vm.carrier2PutinLoc = {};
                            for (var i=0; i<vm.tb.carrierCount; i++) {
                                vm.putinLocs.push(('00' + (i+1)).slice(-3));
                            }
                            angular.forEach(vm.tb.details, function(item) {
                                if (item.putinLoc) {
                                    if (vm.putinLocs.indexOf(item.putinLoc) === - 1) {
                                        openModal('error-confirm.html'
                                            , {message: '箱号' + item.boxNum + '投放位' + item.putinLoc + '不能发现'}, 'scanTransferNum');
                                        return;
                                    }

                                    vm.carrier2PutinLoc[item.carrierCode] = item.putinLoc;
                                }
                            });
                            for (var prop in vm.carrier2PutinLoc) {
                                var idx = vm.putinLocs.indexOf(vm.carrier2PutinLoc[prop]);
                                if (idx > -1) {
                                    vm.putinLocs.splice(idx, 1);
                                }
                            }

                            success();
                            jQuery('#scanBoxNum').select();
                        }, function(resp) {
                            toastr.error(resp.data.errorMsg);
                            jQuery('#scanTransferNum').select();
                            fail("错误");
                        });
                    }
                }

            }

        }

        function scanBoxNum(boxNum, $event) {
            if ($event && $event.which === 13) {
                if (boxNum === vm.changeOrderCmd || boxNum === vm.endScanCmd) {
                    _execCmd(boxNum);
                } else {
                    if (!boxNum || boxNum === '') {
                    	_PlayErrorAudio();
                        openModal('error-confirm.html', {message: '扫描箱号不能为空'}, 'scanBoxNum');
                        return;
                    }

                    vm.box = {};
                    if (vm.removeBox) {
                        _removeBox(boxNum);
                    } else {
                        _getBox(boxNum);
                    }
                }
            }
        }

        function completeTransfe(transferNum) {
            if (transferNum) {
            	vm.success();
                TransferBatch.completeTransferBatch({}, [transferNum], function() {
                    _reset();
                    success();

                    vm.params = {};
                    jQuery('#scanTransferNum').select();
                }, function(resp) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'scanBoxNum');
                    fail(resp.data.errorMsg);
                });
            } else {
            	_PlayErrorAudio();
                openModal('error-confirm.html', {message: '请先扫描/创建交接批次'}, 'scanTransferNum');
            }
        }
        
        function _reset() {
            vm.tb = null;
            vm.box = null;
        }

        function _addBoxToTransferBatch(box) {
            if (!vm.params || !vm.params.transferNum) {
                _recoverPutinLoc(box.carrierCode, box.putinLoc);
                openModal('error-confirm.html', {message: '请先扫描/创建交接批次'}, 'scanTransferNum');
                return;
            }
            if (!box || !box.boxNum) {
                _recoverPutinLoc(box.carrierCode, box.putinLoc);
                openModal('error-confirm.html', {message: '请先扫描箱号'}, 'scanBoxNum');
                return;
            }
            if (box.calWeight) {
                box.weight = box.calWeight;
                box.calWeight = 0;
                box.overWeight = false;
                vm.weightDone = false;
            }
            vm.success();
            return TransferBatch.addBoxToTransferBatch({}, {
                boxNum: box.boxNum,
                weight: box.weight,
                putinLoc: box.putinLoc,
                transferNum: vm.tb.transferNum
            }, function(data) {
                if (!vm.carrier2PutinLoc[box.carrierCode]) {
                    vm.carrier2PutinLoc[box.carrierCode] = box.putinLoc;
                }

                vm.tb = data.content;

                success();
                jQuery('#scanBoxNum').select();
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    _recoverPutinLoc(box.carrierCode, box.putinLoc);
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'scanBoxNum');
                    fail(resp.data.errorMsg);
                }
            });
        }

        function _removeBox(boxNum) {
            if (!vm.params || !vm.params.transferNum) {
                openModal('error-confirm.html', {message: '请先扫描/创建交接批次'}, 'scanTransferNum');
                return;
            }
            if (!vm.tb.details) {
                openModal('error-confirm.html', {message: '交接批次中没有该箱号' + boxNum}, 'scanBoxNum');
                return;
            }
            var existedIndex = -1;
            angular.forEach(vm.tb.details, function(item, idx) {
               if (item.boxNum === boxNum) {
                   existedIndex = idx;
               }
            });
            if (existedIndex < 0) {
                openModal('error-confirm.html', {message: '交接批次中没有该箱号' + boxNum}, 'scanBoxNum');
                return;
            }
            vm.success();
            return TransferBatch.removeBoxFromTransferBatch({}, {
                boxNum: boxNum,
                transferNum: vm.tb.transferNum
            }, function(data) {
                vm.params.boxNum = "";
                vm.box.boxNum = boxNum;
                vm.tb = data.content;

                success();
                jQuery('#scanBoxNum').select();
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'scanBoxNum');
                    fail(resp.data.errorMsg);
                }
            });
        }

        function _getBox(boxNum) {
            var added = false;
            angular.forEach(vm.tb.details, function(detail) {
                if (detail.boxNum === boxNum) {
                    added = true;
                }
            });
            if (added) {
                openModal('error-confirm.html', {message: "装箱号" + boxNum + "已加入批次"}, 'scanBoxNum');
                return;
            }
            vm.success();
            Box.getBoxByBoxNum({boxNum: boxNum}, {}, function(data) {
                if (data) {
                    vm.params.boxNum = "";
                    vm.weightDone = true;
                    var carrierCode = data.content.carrierCode;

                    if (!carrierCode) {
                        openModal('error-confirm.html', {message: "承运商编码为空,不能分配投放位"}, 'scanBoxNum');
                        return;
                    }


                    var putinLoc = _fetchPutinLoc(carrierCode);
                    if (!putinLoc) {
                        _recoverPutinLoc(carrierCode, putinLoc);
                        openModal('error-confirm.html', {message: "不能分配承运商" + carrierCode + "投放位"}, 'scanBoxNum');
                    } else {
                        vm.box = data.content;
                        vm.box.putinLoc = putinLoc;
                        if (vm.box.needWeighHandover) {
                            $timeout(function() {
                                jQuery('#weight').select();
                            });
                        } else {
                            _addBoxToTransferBatch(vm.box);
                        }
                    }
                }
            }, function(data) {
            	fail("错误");
            });
        }

        function _fetchPutinLoc(carrierCode) {
            if (vm.carrier2PutinLoc && vm.carrier2PutinLoc[carrierCode]) {
                return vm.carrier2PutinLoc[carrierCode];
            }
            if (vm.putinLocs.length > 0) {
                vm.carrier2PutinLoc[carrierCode] = vm.putinLocs.shift();
                return vm.carrier2PutinLoc[carrierCode];
            }

            return null;
        }

        function _recoverPutinLoc(carrierCode, putinLoc) {
            if (vm.box && vm.box.putinLoc) {
                vm.box.putinLoc = null;
            }

            if (vm.tb) {
                var recover = true;
                angular.forEach(vm.tb.details, function (item) {
                    if (item.carrierCode === carrierCode) {
                        recover = false;
                    }
                });

                if (recover) {
                    vm.carrier2PutinLoc[carrierCode] = undefined;

                    if (putinLoc) {
                        vm.putinLocs = [putinLoc].concat(vm.putinLocs);
                    }
                }
            }
        }

        function _execCmd(cmd) {
            if (cmd) {
                if (cmd === vm.changeOrderCmd) {
                    vm.params = {};
                    _reset();
                    jQuery('#scanTransferNum').select();

                    return true;
                } else if (cmd === vm.endScanCmd) {

                    jQuery('#completeBtn').click();
                } else {
                    toastr.error('不支持的命令');
                }
            }

            return false;
        }

        function weightChange(box, $event) {
            if ($event.which === 13) {
                if (box) {
                    var needWeighHandover = box.needWeighHandover;
                    var stdWeight = box.stdWeight;
                    var weight = box.calWeight;
                    var maxAllocWeightDiff = box.maxAllocWeightDiff;

                    if (needWeighHandover && maxAllocWeightDiff > 0) {
                        box.overWeight = false;
                        box.weightDiff = 0;
                        box.weightDiffRate = 0;

                        box.weightDiff = (stdWeight - weight);
                        box.weightDiff = box.weightDiff < 0? (0 - box.weightDiff): box.weightDiff;
                        box.weightDiffRate = (box.weightDiff * 1.0 / (stdWeight?stdWeight:1)) * 100.0;
                        if (box.weightDiffRate <= maxAllocWeightDiff) {
                            _addBoxToTransferBatch(box);
                        } else {
                            box.overWeight = true;

                            fail('重量差异超 ' + maxAllocWeightDiff + '%，请检查箱内商品')
                            // openModal('error-confirm.html'
                            //     , {message: '重量差异超 ' + maxAllocWeightDiff + '%，请检查箱内商品'}
                            //     , 'scanBoxNum'
                            //     , function () {
                            //         return _addBoxToTransferBatch(box);
                            //     });
                        }
                    }
                }
            }
        }
        
        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }


        // 打开控制弹出框
        function openModal(templateUrl, model, elemId, func) {
            var paramItem = angular.extend({errorCtrlCmd: vm.errorCtrlCmd}, model);
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return paramItem;
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.execCmd = execCmd;

                function execCmd($event, cmd) {
                    var key = $event.key;
                    if (key === 'Enter') {
                        if (cmd === vm.errorCtrlCmd) {
                            var promise = null;
                            if (func && angular.isFunction(func)) {
                                promise = func();
                            } else {
                                promise = {
                                    then: function(actualFn) {
                                        actualFn();
                                    }
                                };
                            }

                            promise.then(function() {
                                modalInstance.close();

                                vm.success();
                                if (elemId) {
                                    jQuery('#' + elemId).select();
                                }
                            });
                        } else {
                            jQuery('#errorCode').select();
                        }
                    }
                }
            }
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }

    }
})();
