(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('SysConfig', SysConfig);

    SysConfig.$inject = ['$resource', 'iServiceConfig'];

    function SysConfig ($resource, iServiceConfig) {
        var service = $resource('web/sysConfig', {}, {
        	
            'list': { url: iServiceConfig.idataHost + "/web/sysConfig/list", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/sysConfig/detail", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/sysConfig/save", method: 'POST'},
            'delete':{url:iServiceConfig.idataHost + "/web/sysConfig/delete",method:'POST'},
            'checkCode': {url: iServiceConfig.idataHost + "/web/sysConfig/checkcode", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/sysConfig/disable", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/sysConfig/enable", method: 'POST'},
        });
        return service;
    }
})();
