(function () {
    'use strict';

    angular.module('iplanModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config ($stateProvider) {
        $stateProvider
            .state('iPlanwarehouseOrder', {
                parent: 'main',
                url: 'plan/warehouseOrder',
                data: {
                    'pageTitle': '仓库预约单'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'plan-module/warehouseOrder/warehouseOrder.html',
                        controller: 'iPlanWarehouseOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
