(function () {
    'use strict';

    angular
        .module('iplanModule')
        .factory('iPlanWarehousePlanDetail', iPlanWarehousePlanDetail);

    iPlanWarehousePlanDetail.$inject = ['$resource', 'iServiceConfig'];

    function iPlanWarehousePlanDetail($resource, iServiceConfig) {
        var service = $resource('web/warehousePlanDetail', {}, {
            'search': {url: iServiceConfig.iplanHost + "/web/warehousePlanDetail/list", method: 'POST'},
            'save': {url: iServiceConfig.iplanHost + "/web/warehousePlanDetail/save", method: 'POST'},
            'delete': {url: iServiceConfig.iplanHost + "/web/warehousePlanDetail/delete/:id", method: 'POST'},
            'getCountByPlanNum': {url: iServiceConfig.iplanHost + "/web/warehousePlanDetail/getCountByPlanNum", method: 'GET'},
            'isBizOrderUsed': {url: iServiceConfig.iplanHost + "/web/warehousePlanDetail/isBizOrderUsed", method: 'GET'}
        });

        return service;
    }
})();
