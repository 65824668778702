(function () {
    'use strict';

    angular.module('idashboardModule')
        .factory('LogisticsBigScreen', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/logisticsBigScreen', {}, {
                'warehouseMap': {
                    url: iServiceConfig.idashboardHost + '/web/largeScreen/warehouseMap',
                    method: 'GET'
                },
                'censusInventoryUseAmount': {
                    url: iServiceConfig.idashboardHost + '/web/largeScreen/censusInventoryUseAmount',
                    method: 'GET'
                },
                'censusWarehouseUseRatio': {
                    url: iServiceConfig.idashboardHost + '/web/largeScreen/censusWarehouseUseRatio',
                    method: 'GET'
                },
                'nearestReceiptSendSituation': {
                    url: iServiceConfig.idashboardHost + '/web/largeScreen/nearestReceiptSendSituation',
                    method: 'GET'
                },
                'warehouseThruput': {
                    url: iServiceConfig.idashboardHost + '/web/largeScreen/warehouseThruput',
                    method: 'GET'
                }
            });
        }]);
})();