(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsStationController', TmsStationController);

    TmsStationController.$inject = ['$rootScope', 'TmsStation', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsStationController ($rootScope,  Station, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
                       	{id : null, title : "不限"},
                        {id : 0, title : "新建"},
                        {id : 10, title : "启用"},
                        {id : -10, title : "禁用"},
                    ];

        vm.yesOrNo = [
            {id : 1, title : "是"},
            {id : 0, title : "否"},
        ];
        vm.geoCode = function(address, lnglatObj, lngF, latF, geoContainer) {
            window.geoCode1(address, lnglatObj, lngF, latF, geoContainer);
            return false;
        }
        vm.priority = [
            {id : 1, title : "1 优先级最高"},
            {id : 2, title : "2"},
            {id : 3, title : "3"},
            {id : 4, title : "4"},
            {id : 5, title : "5"},
            {id : 6, title : "6"},
            {id : 7, title : "7"},
            {id : 8, title : "8"},
            {id : 9, title : "9"},
            {id : 10, title : "10 优先级最低"},
        ];


        function pageTitle() {
            return $window.document.title;
        }

      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
                if($state.params.carrierCode!=null){
                    params.filter().carrierCode = $state.params.carrierCode;
                    $state.params.carrierCode=null;
                }
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Station.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.validate = validate;
        function validate(code,carrierCode){
            if(code==null ||code==""){
                return
            }else{
            	var vo = {};
            	vo.code = code;
            	vo.carrierCode = carrierCode;
                Station.validateCode({}, vo, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }
        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            Station.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }

        vm.defaultChoose = null;
		vm.createStation = createStation;
		function createStation() {
                var entity = {
                    heading : '创建',
                    contentId : "createStation.html",
                    active : true,
                    data: {
                        status : 0,
                    },
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
		}

		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
            if(t.data.distributeCenter==null){
                toastr.error("请选择集散中心！");
                return;
            }else if(t.data.dockPoint==null){
                toastr.error("请选择停靠点！");
                return;
            }
            else if(t.data.loadPoint==null){
                toastr.error("装卸点！");
                return;
            } else if(t.data.priority==null){
                toastr.error("请选择优先级！");
                return;
            } else if(t.data.level==null){
                toastr.error("请选择级别！");
                return;
            }else if(t.data.status==null){
                toastr.error("请选择状态！");
                return;
            }
			 var saveVO = t.data;
			 if(t.data.id){
				 Station.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 vm.tableParams.reload();
					 }
				 });
			}else{
                 Station.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
                         vm.tableParams.reload();
					 }
				 });
			}
		}

		vm.show = show;
		function show(row) {
            Station.show({}, row.id, function(resp) {
				var Station = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showStation.html",
						active : true,
						data : Station,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

		vm.edite = edite;
		function edite(row) {
            Station.show({}, row.id, function(resp) {
				var Station = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createStation.html",
						active : true,
						data : Station,
                    	carrierCode : Station.carrierCode,
                    	carrierSelection : function carrierSelection(obj){
                        entity.carrierCode = obj.code;
                    },
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}



		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                Station.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }

		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }

		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
