(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('InvAllocOccupy', InvAllocOccupy);

    InvAllocOccupy.$inject = ['$resource', 'iServiceConfig'];

    function InvAllocOccupy ($resource, iServiceConfig) {
        var service = $resource('web/invAllocOccupy', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/invAllocOccupy/list", method: 'POST'},

        });
        return service;
    }
})();
