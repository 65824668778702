(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('SsPackController', SsPackController);

    SsPackController.$inject = ['$rootScope','$state', '$timeout','enums','toastr','CheckSerial','Pack','$uibModal', '$filter'];

    function SsPackController ($rootScope,$state, $timeout,enums,toastr,CheckSerial,Pack,$uibModal,$filter) {
        var vm = this;

        vm.changeOrderCmd = '$CHANGEPKO$';
        vm.errorCtrlCmd = '$ERRORCTRL$';
        vm.endScanCmd = '$ENDSCAN$';
        
        vm.needPrintList = false;
        vm.needPrintWaybill = false;
        vm.params = {
                listPrinter:"",
                waybillNumPrinter:"",
            }
        
        if ($state.params.needPrintList && $state.params.needPrintList > 0) {
        	vm.needPrintList = true;
        }
        if ($state.params.needPrintWaybill && $state.params.needPrintWaybill > 0) {
        	vm.needPrintWaybill = true;
        }

        vm.pickNum = null;//拣选单
        vm.skuBarcode = null;//商品条码
        vm.data = {};//拣选单信息
        vm.skuDatas = [];//商品明细
        vm.currentSku = {};//当前所扫描的商品条码对应的商品
        vm.skuMap = {};//存储商品明细中的商品条码和编码，以vm.skuDatas数组下标+1 为key  每一种商品的商品条码为value
        vm.serialNum = null;//序列号
        vm.weight = null;//称重重量

        vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'}; // SUCCESS|ERROR

        $timeout(function () {
            $('#pickNumInput').focus();
        });

        vm.success = success;
        vm.scanPickOrder = scanPickOrder;//扫描拣选单号
        vm.scanSkuBarcode = scanSkuBarcode;//扫描商品条码
        vm.recheck = recheck;//复核
        vm.suppleList = suppleList;//补打清单
        vm.suppleWaybill = suppleWaybill;//补打面单

        vm.openInitParamWindow = function openInitParamWindow() {
            $uibModal.open({
                templateUrl: 'initParam.html',
                controller: openInitParamCtrl,
                controllerAs: '$ctrl',
            })
        }
        
        openInitParamCtrl.$inject=['$uibModalInstance','$scope'];
        function openInitParamCtrl($uibModalInstance,$scope) {
            var me=this;
            me.listPrinter=vm.params.listPrinter;
            me.waybillNumPrinter=vm.params.waybillNumPrinter;
            me.isPrintList=vm.needPrintList;
            me.isPrintSheet=vm.needPrintWaybill;
            
            me.entryInMainPage=entryInMainPage;
            function entryInMainPage() {
            	vm.params.listPrinter = me.listPrinter;
            	vm.params.waybillNumPrinter = me.waybillNumPrinter;
            	vm.needPrintList = me.isPrintList;
            	vm.needPrintWaybill = me.isPrintSheet;
            	$uibModalInstance.dismiss('close');
            }
            me.close = function close() {
            	$uibModalInstance.dismiss('close');
            }
        }
        //补打清单
        function suppleList() {
            Pack.printList({},{
                    boxNum:vm.currentSku.boxNum
                },
                function (res) {
                    toastr.success('补打清单成功','成功');
                },function (res) {
                    if (res.data && res.data.errorMsg) {
                        fail(res.data.errorMsg);
                        toastr.error(res.data.errorMsg);
                    }
                }
            );
        }

        //补打面单
        function suppleWaybill() {
            Pack.printWaybill({},{
                    boxNum:vm.currentSku.boxNum
                },
                function (res) {
                    toastr.success('补打面单成功','成功');
                },function (res) {
                    if (res.data && res.data.errorMsg) {
                        fail(res.data.errorMsg);
                        toastr.error(res.data.errorMsg);
                    }
                }
            );
        }

        //扫描拣选单号
        function scanPickOrder(event) {
            var keyCode = event.keyCode;
            if (keyCode === 13) {
                if(!vm.pickNum){
                    openModal('error-confirm.html', {message: '请扫描有效的拣选单号'}, 'asnNum');
                    fail();
                    return;
                }
                //每一次重新扫描  清空数据
                vm.skuBarcode = null;
                vm.data = {};
                vm.skuDatas = [];
                vm.currentSku = {};
                vm.skuMap = {};
                vm.serialNum = null;
                vm.weight = null;
                scanPickOrderWay();
            }
        }

        //扫描拣选单号--方法
        function scanPickOrderWay() {
            if(vm.pickNum){
                Pack.getPackPickOrder({},{
                    pickNum:vm.pickNum
                },function(res){
                    vm.data = res.content;
                    if(angular.isArray(vm.data.details) && vm.data.details.length>0){
                        vm.skuDatas = vm.data.details;
                        initSkuMap();
                        $('#skuBarcode').focus();
                    }
                    success();
                },function(res){
                    if (res.data && res.data.errorMsg) {
                        openModal('error-confirm.html', {message: res.data.errorMsg}, 'asnNum');
                        fail(res.data.errorMsg);
                    }
                });
            }
        }

        //生成skuMap
        function initSkuMap() {
        	vm.skuMap = {};
            if(angular.isArray(vm.skuDatas) && vm.skuDatas.length>0){
            	angular.forEach(vm.skuDatas, function(item){
            		if (item.remainQty <= 0) {
            			item.priority = 1000;
            		} else {
            			item.priority = 1;
            		}
            	});
            	vm.skuDatas = $filter('orderBy')(vm.skuDatas, 'priority');
            	
                for(var i=0;i<vm.skuDatas.length;i++){
                    vm.skuMap[i] =vm.skuDatas[i].skuBarcodes;
                    vm.skuMap[i].push(vm.skuDatas[i].skuCode);
                }
            }
        }

        //扫描商品条码
        function scanSkuBarcode(event) {
            var keyCode = event.keyCode;
            if (keyCode === 13) {
                if(!vm.skuBarcode){
                    openModal('error-confirm.html', {message: '请扫描有效的商品条码'}, 'skuBarcode');
                    fail();
                    return;
                }
                searchSkuBarcode();
            }
        }

        //查询商品条码
        function searchSkuBarcode() {
            var hasFind = false;
            var idx = -1;
            for(var key in vm.skuMap){
                var id = vm.skuMap[key].indexOf(vm.skuBarcode);
                if(id>-1){
                    idx = key;//标识在vm.skuDatas数组中的下标
                    hasFind = true;
                    break;
                }
            }

            if(!hasFind){
                vm.currentSku = {};
                openModal('error-confirm.html', {message: '请扫描有效的商品条码'}, 'skuBarcode');
                fail();
            }else{
                vm.currentSku = vm.skuDatas[idx];
                //复核
                recheck(vm.currentSku);
            }
        }

        //检查序列号--方法
        function checkSerialWay(num) {
            return new Promise(function(resolve,reject){
                CheckSerial.checkSerialNum({},{
                    boxNum:vm.currentSku.boxNum,
                    ownerCode:'A02',
                    skuCode:vm.currentSku.skuCode,
                    serialNum:num
                },function(res){
                    resolve(res);
                },function(res){
                    reject(res);
                });
            });
        }

        //复核
        function recheck(sku) {
            vm.serialNum = null;
            vm.weight = null;
            //如果需要采集序列号
            if(sku.needSerialNum){
                openModalSerial('takeSerialNum.html','skuBarcode',sku);
                return;
            }
            //如果需要称重
            if(sku.needWeigh){
                openModalWeight('takeWeight.html','skuBarcode',sku);
                return;
            }

            //如果不需要采集序列号 和称重  则直接复核
            startRecheck();
        }

        //开始复核
        function startRecheck() {
            var params = {
                boxNum: vm.currentSku.boxNum,
                skuCode: vm.currentSku.skuCode,
                serialNum: vm.serialNum,
                weight: vm.weight,
                needPrintList: vm.needPrintList || vm.currentSku.needPrintList == true,
                needPrintWaybill: vm.needPrintWaybill || vm.currentSku.needPrintWaybill == true,
                listPrinter: vm.params.listPrinter,
                waybillNumPrinter: vm.params.waybillNumPrinter
            };
            recheckSkuWay(params).then(function(res){
            	_PlaySuccessAudio();
                vm.data.packedQty = vm.data.packedQty + 1;
                vm.data.remainQty = vm.data.remainQty - 1;
                if (vm.data.remainQty <= 0) {
                	toastr.success("复核成功，整单已复核完成！", "成功");
                	//每一次重新扫描  清空数据
                    vm.skuBarcode = null;
                    vm.data = {};
                    vm.skuDatas = [];
                    vm.currentSku = {};
                    vm.skuMap = {};
                    vm.serialNum = null;
                    vm.weight = null;
                    vm.pickNum = null;
                    $('#pickNumInput').focus();
                    $('#pickNumInput').select();
                    return;
                } else {
                	toastr.success("复核成功，还需继续复核", "成功");
                }
                $('#skuBarcode').focus();
                $('#skuBarcode').select();
                vm.currentSku.packedQty = vm.currentSku.packedQty + 1;
                vm.currentSku.remainQty = vm.currentSku.remainQty - 1;
                initSkuMap();
            }).catch(function(res){
                if (res.data && res.data.errorMsg) {
                    fail(res.data.errorMsg);
                    toastr.error(res.data.errorMsg);
                }
            });
            
        }

        //复核---方法
        function recheckSkuWay(params) {
            return new Promise(function (resolve, reject) {
                Pack.packSingleSku({},params,
                    function (res) {
                        resolve(res);
                    },function (res) {
                        reject(res);
                    }
                );
            });
        }

        //成功提示
        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }

        //失败提示
        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        // 打开控制弹出框
        function openModal(templateUrl, model, elemId) {
            var paramItem = angular.extend({errorCtrlCmd: vm.errorCtrlCmd}, model);
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return paramItem;
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.execCmd = execCmd;
                $ctrl.execClose = execClose;
                function execClose() {
                	modalInstance.close();

                    vm.success();
                    if (elemId) {
                    	jQuery('#' + elemId).focus();
                        jQuery('#' + elemId).select();
                    }
                }
                function execCmd($event, cmd) {
                    var key = $event.key;
                    if (key === 'Enter') {
                        if (cmd === vm.errorCtrlCmd) {
                            modalInstance.close();

                            vm.success();
                            if (elemId) {
                            	jQuery('#' + elemId).focus();
                                jQuery('#' + elemId).select();
                            }
                        } else {
                            jQuery('#errorCode').select();
                        }
                    }
                }
            }
        }

        // 打开采集序列号弹出框
        function openModalSerial(templateUrl,elemId,sku) {
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return {
                            target:elemId,
                            sku:sku
                        }
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.item.errorSerial = false;
                $ctrl.checkSerial = checkSerial;
                $ctrl.ok = ok;
                $ctrl.cancel = cancel;

                function checkSerial($event, serialNum) {
                    var key = $event.key;
                    if (key === 'Enter') {
                        //检查序列号
                        checkSerialWay(serialNum).then(function(res){
                            vm.serialNum = serialNum;

                            //如果需要称重
                            if($ctrl.item.sku.needWeigh){
                                openModalWeight('takeWeight.html','skuBarcode',$ctrl.item.sku);
                            }else{
                                //如果不需要称重  就复核
                                startRecheck();
                            }
                            modalInstance.close();
                        }).catch(function(res){
                            if (res.data && res.data.errorMsg) {
                                $ctrl.item.errorSerial = true;
                                $ctrl.item.errorMsg =res.data.errorMsg;
                            }
                        });
                    }
                }

                function ok(item) {
                    checkSerial({key:'Enter'},item.serialNum);
                }
                function cancel(item) {
                    modalInstance.close();
                }
            }
        }

        // 打开称重弹出框
        function openModalWeight(templateUrl,elemId,sku) {
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return {
                            target:elemId,
                            sku:sku
                        }
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.item.diffPercent = null;
                $ctrl.item.diff = null;
                $ctrl.item.isOverstep = false;
                $ctrl.takeWeight = takeWeight;
                $ctrl.submit =submit;//提交

                function takeWeight($event, weight) {
                    var key = $event.key;
                    if (key === 'Enter') {
                        vm.weight = weight*1;
                        var diff = (weight*1-$ctrl.item.sku.stdWeight).toFixed(2);
                        if(diff > 0){
                            var diffPercent = (diff/$ctrl.item.sku.stdWeight)*100;
                            if(diffPercent>$ctrl.item.sku.maxAllowDiff){
                                $ctrl.item.diff = diff;
                                $ctrl.item.diffPercent = diffPercent;
                                $ctrl.item.isOverstep = true;
                                $('#submitWeight').focus();
                                return;
                            }
                        }
                        $ctrl.item.isOverstep = false;
                        startRecheck();
                        modalInstance.close();
                    }
                }

                function submit(item) {
                    startRecheck();
                    modalInstance.close();
                }
            }
        }
        vm.openInitParamWindow();
    }
})();
