(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('CarrierDriver', CarrierDriver);

    CarrierDriver.$inject = ['$resource', 'iServiceConfig'];

    function CarrierDriver ($resource, iServiceConfig) {
        var service = $resource('web/carrierDriver', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/carrierDriver/list", method: 'POST'},
            'saveCarrierDriver': {url: iServiceConfig.idataHost + "/web/carrierDriver/saveCarrierDriver", method: 'POST'},
            'showCarrierDriver': {url: iServiceConfig.idataHost + "/web/carrierDriver/showCarrierDriver", method: 'POST'},
            'deleteCarrierDriver': {url: iServiceConfig.idataHost + "/web/carrierDriver/deleteCarrierDriver", method: 'POST'},
            'getCarrierCodes': {url: iServiceConfig.idataHost + "/web/carrierDriver/getCarrierCodes", method: 'POST'},
            'validateCode': {url: iServiceConfig.idataHost + "/web/carrierDriver/validateCode", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/carrierDriver/enable", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/carrierDriver/disable", method: 'POST'},
        });
        return service;
    }
})();
