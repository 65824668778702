(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('ReplenishPlan', ReplenishPlan);

    ReplenishPlan.$inject = ['$resource', 'iServiceConfig'];

    function ReplenishPlan ($resource, iServiceConfig) {
        var service = $resource('web/replenishPlan', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/list", method: 'POST'},
            'logList': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/logList", method: 'POST'},
            'getPlanNum': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/getPlanNum", method: 'POST'},
            'saveAndUpdate': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/saveAndUpdate", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/cancel", method: 'POST'},
            'closed': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/closed", method: 'POST'},
            'execute': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/execute", method: 'POST'},
            'begin': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/begin", method: 'POST'},
            'edit': {url: iServiceConfig.iwhInnerHost + "/web/replenishPlan/edit", method: 'POST'},
        });

        return service;
    }
})();
