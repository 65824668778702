(function () {
    'use strict';
    var app = angular.module('iwh-dataModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('iwh-roadway', {
            parent: 'main',
           url: 'iwh-data/roadway',
            params: {
                'ownerCode': null,
                'postParams': null
            },
            data: {
                'pageTitle': '巷道维护',
                'ownerCode' : null,
                'postParams': null,
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-data-module/roadway/roadway.html',
                    controller: 'IwhRoadwayController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

