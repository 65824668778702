(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptRequisitionController', iEptRequisitionController);

    iEptRequisitionController.$inject = ['iEptRequisition', 'iEptRequisitionDetail', 'iEptRequisitionLog', 'iEptRequisitionOutDetail', 'iEptRequisitionInDetail', 'NgTableParams', '$timeout', 'toastr', 'enums', '$uibModal'];

    function iEptRequisitionController(iEptRequisition, iEptRequisitionDetail, iEptRequisitionLog, iEptRequisitionOutDetail, 
    		iEptRequisitionInDetail, NgTableParams, $timeout, toastr, enums, $uibModal) {
        //-----------------------------------------------------------------------//
        //----------------FIELD---------------------------FIELD------------------//
        //-----------------------------------------------------------------------//
        var vm = this;
        vm.modalInstance = null;
        vm.tabs = [];
        vm.search = search;
        vm.requisitionStatus = enums("enterprise.RequisitionStatus").list("title", "id");
        vm.requisitionStatusEnum = enums("enterprise.RequisitionStatus");
        vm.requisitionType = enums("enterprise.RequisitionType").list("title", "id");
        vm.requisitionTypeEnum = enums("enterprise.RequisitionType");
        vm.requisitionDetailStatus = enums("enterprise.RequisitionDetailStatus").list("title", "id");
        vm.requisitionDetailStatusEnum = enums("enterprise.RequisitionDetailStatus");
        vm.requisitionOpLogType = enums("enterprise.RequisitionOpLogType").list("title", "id");
        vm.requisitionOpLogTypeEnum = enums("enterprise.RequisitionOpLogType");
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iEptRequisition.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.removeTab = removeTab;
        vm.refreshTable = refreshTable;
        vm.cancelSelected = cancelSelected;
        vm.planSelected = planSelected;
        vm.closeSelected = closeSelected;
        vm.create = create;
        vm.deleteDetailSelected = deleteDetailSelected;
        vm.createDetail = createDetail;
        vm.saveRequisition = saveRequisition;
        vm.planRequisition = planRequisition;
        vm.outWh = outWh;
        vm.inWh = inWh;
        vm.pullOutWh = pullOutWh;
        vm.pullInWh = pullInWh;
        vm.printRequisition = printRequisition;
        vm.cancelRequisition = cancelRequisition;
        vm.closeRequisition = closeRequisition;
        vm.createEditPage = createEditPage;
        vm.createEditDetailPage = createEditDetailPage;

        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function refreshTable(table) {
            table.reload();
        }

        function search() {
            vm.tableParams.reload();
        }

        function cancelSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var roNums = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.requisitionStatusEnum.value("PLANNED") != paramBeans[i].status &&
                		vm.requisitionStatusEnum.value("NEW") != paramBeans[i].status
                    && vm.requisitionStatusEnum.value("FAILED") != paramBeans[i].status
                    && vm.requisitionStatusEnum.value("OUT_WH_ING") != paramBeans[i].status) {
                    toastr.warning("该状态下的调拨单不能取消, 只有【新建、已计划、出库中、调拨失败】的才能取消", "警告");
                    return;
                }
                roNums.push(paramBeans[i].roNum);
            }
            doCancelRequisition(roNums, function () {
                vm.search();
            });
        }

        function planSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.requisitionStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("所选调拨单状态为新建才能修改计划时间", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }

            vm.modalInstance = $uibModal.open({
                templateUrl: 'requisition-updatePlanTime.html',
                controller: updatePlanTimeRequisition,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        entity: {
                            planOutTime: null,
                            planInTime: null,
                            ids: ids
                        }
                    }
                }

            });
            vm.modalInstance.result.then(function (item) {
                vm.tableParams.reload();
            });
        }

        function closeSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.requisitionStatusEnum.value("FINISHED") != paramBeans[i].status) {
                    toastr.warning("所选调拨单状态为已完成才能关闭", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptRequisition.close({
                ids: ids
            }, {}, function (resp) {
                toastr.success("成功关闭", "成功");
                vm.tableParams.reload();
            }, function (err) {
                toastr.error("关闭失败", "错误");
            });
        }

        function deleteDetailSelected(entity) {
            var paramBeans = entity.requisitionDetails.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.requisitionDetailStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("所选调拨单明细状态为新建才能删除", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptRequisitionDetail.deleteBatch({ids: ids}, {}, function (resp) {
                toastr.success("成功删除" + resp.content + "条调拨单明细", "提示");
                entity.requisitionDetails.reload();
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean
                });
            }, function (err) {
                toastr.error("删除失败", "错误");
            });
        }

        function createEditDetailPage(entity, row) {
            iEptRequisitionDetail.preUpdate({
                id: row.id,
                outWh: entity.data.whOutCode,
                inWh: entity.data.whInCode
            }, {}, function (resp) {
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'requisition-detail-edit.html',
                    controller: newDetail,
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        item: {
                            tab: entity,
                            requisitionDetail: resp.content.bean,
                            outLotAttrs: resp.content.outLotAttrs,
                            inLotAttrs: resp.content.inLotAttrs,
                            outOwner: {
                                name: resp.content.bean.outOwnerName,
                                code: resp.content.bean.outOwner
                            },
                            inOwner: {
                                name: resp.content.bean.inOwnerName,
                                code: resp.content.bean.inOwner
                            },
                            outOwnerCode: resp.content.bean.outOwner,
                            inOwnerCode: resp.content.bean.inOwner,
                            outSku: {
                                name: resp.content.bean.outSkuName,
                                code: resp.content.bean.outSku,
                                ownerCode: resp.content.bean.outOwner
                            },
                            inSku: {
                                name: resp.content.bean.inSkuName,
                                code: resp.content.bean.inSku,
                                ownerCode: resp.content.bean.inOwner
                            },
                            outSkuCode: resp.content.bean.outSku,
                            inSkuCode: resp.content.bean.inSku,
                            oldOutLotAttrs: resp.content.outLotAttrs,
                            oldInLotAttrs: resp.content.inLotAttrs,
                            oldOutSku: {
                                code: resp.content.bean.outSku,
                                ownerCode: resp.content.bean.outOwner,
                                warehouseCode: entity.data.whOutCode
                            },
                            oldInSku: {
                                code: resp.content.bean.inSku,
                                ownerCode: resp.content.bean.inOwner,
                                warehouseCode: entity.data.whInCode
                            },
                            NewDetail: false,
                            readOnly: resp.content.bean.status != vm.requisitionDetailStatusEnum.value("NEW"),
                            changeFlag: 4
                        }
                    }

                });
                vm.modalInstance.result.then(function (item) {
                    item.tab.requisitionDetails.reload();
                });
            });

        }

        function createDetail(entity) {
            iEptRequisitionDetail.preSave({}, {}, function (response) {
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'requisition-detail-edit.html',
                    controller: newDetail,
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        item: {
                            tab: entity,
                            requisitionDetail: {
                                roLineNum: response.content.roLineNum
                            },
                            outLotAttrs: null,
                            inLotAttrs: null,
                            outOwner: null,
                            outOwnerCode: null,
                            inOwner: null,
                            inOwnerCode: null,
                            outSku: null,
                            inSku: null,
                            outSkuCode: null,
                            inSkuCode: null,
                            NewDetail: true,
                            readOnly: false,
                            changeFlag: 0
                        }
                    }

                });
                vm.modalInstance.result.then(function (item) {
                    item.tab.requisitionDetails.reload();
                });
            }, function (error) {
                toastr.error("创建失败", "错误");
            });


        }

        function saveRequisition(entity, tabIndex) {
            if (validateRequisition(entity)) {
                entity.data.whOutCode = entity.warehouseOut.warehouseCode;
                entity.data.whOutName = entity.warehouseOut.warehouseName;
                entity.data.whInCode = entity.warehouseIn.warehouseCode;
                entity.data.whInName = entity.warehouseIn.warehouseName;
                iEptRequisition.save({}, entity.data, function (response) {
                    toastr.success((!!entity.data.id ? "更新" : "创建") + "成功");
                    vm.removeTab(tabIndex);
                    vm.createEditPage(response.content);
                }, function (error) {
                    toastr.error("保存失败", "错误");
                });
            }
        }

        function planRequisition(entity, tabIndex) {
            updatePlanTimeThenPlan(
                entity.data.planOutTime,
                entity.data.planInTime,
                [entity.data.id],
                function () {
                    iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                        entity.data = response.content.bean;
                        entity.warehouseOut = {
                            warehouseCode: response.content.bean.whOutCode,
                            warehouseName: response.content.bean.whOutName
                        };
                        entity.warehouseIn = {
                            warehouseCode: response.content.bean.whInCode,
                            warehouseName: response.content.bean.whInName
                        };
                    }, function (error) {
                        toastr.error("获取调拨单信息失败", "错误");
                    });
                });
        }

        function outWh(entity, tabIndex) {
            iEptRequisition.outWh({roNum: entity.data.roNum}, {}, function (resp) {
                toastr.success("操作成功", "成功");
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean;
                    entity.requisitionDetails.reload();
                });
            }, function (err) {
                toastr.error("操作失败", "错误");
            });
        }

        function inWh(entity, tabIndex) {
            iEptRequisition.inWh({roNum: entity.data.roNum}, {}, function (resp) {
                toastr.success("操作成功", "成功");
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean;
                    entity.requisitionDetails.reload();
                });
            }, function (err) {
                toastr.error("操作失败", "错误");
            });
        }
        
        function pullOutWh(entity, tabIndex) {
            iEptRequisition.pullOutWh({roNum: entity.data.roNum}, {}, function (resp) {
                toastr.success("操作成功", "成功");
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean;
                    entity.requisitionDetails.reload();
                });
            }, function (err) {
                toastr.error("操作失败", "错误");
            });
        }

        function pullInWh(entity, tabIndex) {
            iEptRequisition.pullInWh({roNum: entity.data.roNum}, {}, function (resp) {
                toastr.success("操作成功", "成功");
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean;
                    entity.requisitionDetails.reload();
                });
            }, function (err) {
                toastr.error("操作失败", "错误");
            });
        }

        function printRequisition(entity, tabIndex) {
            //todo 打印调拨单
            toastr.info("此功能尚未实现", "提示");
        }

        function cancelRequisition(entity, tabIndex) {
            if (vm.requisitionStatusEnum.value("NEW") != entity.data.status
                    && vm.requisitionStatusEnum.value("PLANNED") != entity.data.status
                && vm.requisitionStatusEnum.value("FAILED") != entity.data.status
                && vm.requisitionStatusEnum.value("OUT_WH_ING") != entity.data.status) {
                toastr.warning("该状态下的调拨单不能取消, 只有【新建、已计划、出库中、调拨失败】的才能取消", "警告");
                return;
            }
            doCancelRequisition([entity.data.roNum], function () {
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean;
                    entity.requisitionDetails.reload();
                });
            });
        }

        function closeRequisition(entity, tabIndex) {
            if (vm.requisitionStatusEnum.value("FINISHED") != entity.data.status) {
                toastr.warning("调拨单状态为已完成才能关闭", "警告");
                return;
            }
            iEptRequisition.close({
                ids: [entity.data.id]
            }, {}, function (resp) {
                toastr.success("成功关闭", "成功");
                iEptRequisition.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content.bean;
                });
            }, function (err) {
                toastr.error("关闭失败", "错误");
            });
        }

        function validateRequisition(entity) {
            if (!!!entity.warehouseOut || !!!entity.warehouseIn || !!!entity.data.type) {
                toastr.info("信息未填完", "提示");
                return false;
            }
            if (entity.warehouseIn.warehouseCode == entity.warehouseOut.warehouseCode) {
                toastr.info("调出仓库与调入仓库不能为同一仓库", "提示");
                return false;
            }
            if (!!entity.data.planOutTime && !!entity.data.planInTime && entity.data.planOutTime > entity.data.planInTime) {
                toastr.info("计划入库时间必须大于计划出库时间", "提示");
                return false;
            }
            return true;
        }

        function createEditPage(row) {
            iEptRequisition.getById({id: row.id}, {}, function (response) {
                var entity = {
                    heading: '调拨单详情',
                    contentId: "requisition-edit.html",
                    data: response.content.bean,
                    warehouseOut: {
                        warehouseCode: response.content.bean.whOutCode,
                        warehouseName: response.content.bean.whOutName
                    },
                    warehouseIn: {
                        warehouseCode: response.content.bean.whInCode,
                        warehouseName: response.content.bean.whInName
                    },
                    warehouses: response.content.warehouses,
                    requisitionDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {roNum: response.content.bean.roNum}, params.filter());
                            return iEptRequisitionDetail.search({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    requisitionInDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {roNum: response.content.bean.roNum}, params.filter());
                            return iEptRequisitionInDetail.search({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    requisitionOutDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {roNum: response.content.bean.roNum}, params.filter());
                            return iEptRequisitionOutDetail.search({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    requisitionLogDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {roNum: response.content.bean.roNum}, params.filter());
                            return iEptRequisitionLog.search({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    roles: [],
                    active: 0,
                    newRequisition: false
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);

            }, function (error) {
                toastr.error("创建失败", "错误");
            });
        }

        function create() {
            iEptRequisition.preSave({}, {}, function (response) {
                var preData = {};
                preData.roNum = response.content.roNum;
                preData.status = response.content.status;
                preData.warehouses = response.content.warehouses;
                var entity = {
                    heading: '创建调拨单',
                    contentId: "requisition-edit.html",
                    data: {
                        roNum: preData.roNum,
                        status: preData.status
                    },
                    requisitionDetails: [],
                    warehouseOut: null,
                    warehouseIn: null,
                    warehouses: preData.warehouses,
                    roles: [],
                    active: 0,
                    newRequisition: true
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);

            }, function (error) {
                toastr.error("创建失败", "错误");
            });
        }

        function doCancelRequisition(roNums, callback) {
            iEptRequisition.cancelWh({roNums: roNums}, {}, function (resp) {
                var fail = resp.content.fail;
                var success = resp.content.success;
                var msg = "";
                if (success.length != 0) {
                    msg += "调拨单(";
                    for (var index in success) {
                        msg += (success[index] + ",");
                    }
                    msg = (msg.substring(0, msg.length - 1) + ")取消成功,共" + success.length + "个");
                    toastr.success(msg, "成功");
                }
                if (fail.length != 0) {
                    if (success.length != 0) {
                        msg += ";"
                    }
                    msg += "调拨单(";
                    for (var index in fail) {
                        msg += (fail[index] + ",");
                    }
                    msg = (msg.substring(0, msg.length - 1) + ")取消失败,共" + fail.length + "个");
                    toastr.error(msg, "成功");
                }
                
                callback();
            }, function (err) {
                toastr.error("操作失败", "失败");
            });
        }

        function updatePlanTimeThenPlan(planOutTime, planInTime, ids, callback) {
            if (!!!planOutTime || !!!planInTime) {
                toastr.info("计划时间信息未填完", "提示");
                return;
            } else if (planOutTime > planInTime) {
                toastr.info("计划入库时间必须大于计划出库时间", "提示");
                return;
            }
            if (planOutTime < new Date().getTime()) {
                toastr.warning("计划出库时间不能为过去时间", "警告");
                return;
            }
            
            iEptRequisition.plan({}, {
                planOutTime: planOutTime,
                planInTime: planInTime,
                ids: ids
            }, function (response) {
                toastr.success("计划成功", "成功");
                callback();
            }, function (error) {
                toastr.error("计划时间更新错误", "错误");
            });
        }

        //-----------------------------------------------------------------------//
        //---------newDetailController------------newDetailController------------//
        //-----------------------------------------------------------------------//
        newDetail.$inject = ['$uibModalInstance', 'item', 'iEptRequisitionDetail', 'toastr'];

        function newDetail($uibModalInstance, item, iEptRequisitionDetail, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.cancel = cancel;
            $ctrl.save = save;
            $ctrl.change = change;

            function change(outOrIn, ownerOrSku) {
                if (!$ctrl.item.NewDetail && $ctrl.item.changeFlag > 0) {
                    $ctrl.item.changeFlag--;
                    return;
                }
                if (ownerOrSku == 1) {
                    if (outOrIn == 1) {
                        $ctrl.item.outSku = null;
                        $ctrl.item.outSkuCode = null;
                        $ctrl.item.requisitionDetail.outSkuPack = null;
                    } else if (outOrIn == 2) {
                        $ctrl.item.inSku = null;
                        $ctrl.item.inSkuCode = null;
                        $ctrl.item.requisitionDetail.inSkuPack = null;
                    }
                } else if (ownerOrSku == 2) {
                    if (outOrIn == 1) {
                        $ctrl.item.requisitionDetail.outSkuPack = null;
                        showLotAttrs(true);
                    } else if (outOrIn == 2) {
                        $ctrl.item.requisitionDetail.inSkuPack = null;
                        showLotAttrs(false);
                    }
                }
                if (!!!$ctrl.item.outSku) {
                    $ctrl.item.outLotAttrs = null;
                }
                if (!!!$ctrl.item.inSku) {
                    $ctrl.item.inLotAttrs = null;
                }
            }

            function showLotAttrs(flag) {

                if (flag && !!$ctrl.item.outOwner && !!$ctrl.item.outSku) {
                    if (!$ctrl.item.NewDetail &&
                        $ctrl.item.outOwner.code == $ctrl.item.oldOutSku.ownerCode &&
                        $ctrl.item.outSku.code == $ctrl.item.oldOutSku.code &&
                        $ctrl.item.tab.data.whOutCode == $ctrl.item.oldOutSku.warehouseCode) {
                        $ctrl.item.outLotAttrs = $ctrl.item.oldOutLotAttrs;
                        return;
                    }
                    var data = {
                        warehouseCode: $ctrl.item.tab.data.whOutCode,
                        ownerCode: $ctrl.item.outOwner.code,
                        skuCode: $ctrl.item.outSku.code,
                        bizType: "SHIP"
                    };
                    iEptRequisitionDetail.getLotRule(data, {}, function (resp) {
                        $ctrl.item.outLotAttrs = resp.content;
                    }, function (err) {
                        toastr.error("出库批次信息获取失败", "错误");
                    });
                }
                if (!flag && !!$ctrl.item.inOwner && !!$ctrl.item.inSku) {
                    if (!$ctrl.item.NewDetail &&
                        $ctrl.item.inOwner.code == $ctrl.item.oldInSku.ownerCode &&
                        $ctrl.item.inSku.code == $ctrl.item.oldInSku.code &&
                        $ctrl.item.tab.data.whInCode == $ctrl.item.oldInSku.warehouseCode) {
                        $ctrl.item.inLotAttrs = $ctrl.item.oldInLotAttrs;
                        return;
                    }
                    var data = {
                        warehouseCode: $ctrl.item.tab.data.whInCode,
                        ownerCode: $ctrl.item.inOwner.code,
                        skuCode: $ctrl.item.inSku.code,
                        bizType: "RECEIPT"
                    };
                    iEptRequisitionDetail.getLotRule(data, {}, function (resp) {
                        $ctrl.item.inLotAttrs = resp.content;
                    }, function (err) {
                        toastr.error("入库批次信息获取失败", "错误");
                    });
                }
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function save(form) {
                if (!form.$valid) {
                    toastr.info("信息未填完", "提示");
                    return;
                }
                if (!!!$ctrl.item.outSku || !!!$ctrl.item.inSku) {
                    toastr.info("商品未选择", "提示");
                    return;
                }
                var detail = $ctrl.item.requisitionDetail;
                detail.roNum = $ctrl.item.tab.data.roNum;
                detail.outOwner = $ctrl.item.outOwner.code;
                detail.outOwnerName = $ctrl.item.outOwner.name;
                detail.inOwner = $ctrl.item.inOwner.code;
                detail.inOwnerName = $ctrl.item.inOwner.name;
                detail.outSku = $ctrl.item.outSku.code;
                detail.outSkuName = $ctrl.item.outSku.name;
                detail.inSku = $ctrl.item.inSku.code;
                detail.inSkuName = $ctrl.item.inSku.name;
                detail.outLotAttrs = JSON.stringify($ctrl.item.outLotAttrs);
                detail.inLotAttrs = JSON.stringify($ctrl.item.inLotAttrs);
                iEptRequisitionDetail.save({}, detail, function (response) {
                    toastr.success("保存成功", "提示");
                    $uibModalInstance.close($ctrl.item);
                    iEptRequisition.getById({id: $ctrl.item.tab.data.id}, {}, function (response) {
                        $ctrl.item.tab.data = response.content.bean
                    });
                }, function (error) {
                    toastr.error("保存失败", "错误");
                    return;
                })
            }
        }

        //-----------------------------------------------------------------------//
        //---------updatePlanTimeRequisitionController------------------------//
        //-----------------------------------------------------------------------//
        updatePlanTimeRequisition.$inject = ['$uibModalInstance', 'item', 'iEptRequisition', 'toastr'];

        function updatePlanTimeRequisition($uibModalInstance, item, iEptRequisition, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.cancel = cancel;
            $ctrl.updatePlanTime = updatePlanTime;

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function updatePlanTime() {
                updatePlanTimeThenPlan($ctrl.item.entity.planOutTime,
                    $ctrl.item.entity.planInTime,
                    $ctrl.item.entity.ids,
                    function () {
                        $uibModalInstance.close($ctrl.item);
                    });
            }
        }
    }
})();
