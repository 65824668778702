(function() {
    'use strict';

    angular.module('doveModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('iBusschedule', {
                parent: 'main',
                url: 'bus/schedule',
                data: {
                    'pageTitle': '调度配置'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/schedule/schedule.html',
                        controller: 'iBusScheduleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
