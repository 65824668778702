(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("PcScanReceive", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhReceiptHost + "/web/pcScanReceipt", {}, {
            'saveBatchExtInfo':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/saveBatchExtInfo',method:'POST'},
            'saveBatchForNoSow':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/saveBatchForNoSow',method:'GET'},
            'loadReceiveBatchInfo':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/loadReceiveBatchInfo',method:'POST'},
            'loadAsnInfo':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/loadAsnInfo',method:'POST'},
            'loadSkuInfo':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/loadSkuInfo',method:'POST'},
            'scanReceipt':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/scanReceipt',method:'POST'},
            'completeLot':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/completeLot',method:'POST'},
            'createAsn':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/createAsn',method:'POST'},
            'createAsnDetail':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/createAsnDetail',method:'POST'},
            'createSku':{url: iServiceConfig.iwhReceiptHost + '/web/pcScanReceipt/createSku',method:'POST'}
        });
    }]);
})();

