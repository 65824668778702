(function(){
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleH5Controller',UserModuleH5Controller);

    UserModuleH5Controller.$inject = ['UserModuleH5', 'NgTableParams', '$timeout', 'toastr'];

    function UserModuleH5Controller(UserModuleH5, NgTableParams, $timeout, toastr) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.account = {};
        vm.status = [{'id': 0, 'title': '已禁用'},
                     {'id': 1, 'title': '新建'},
                     {'id': 10, 'title': '已启用'}];
        vm.statu = "";

        vm.removeTab = removeTab;
        vm.search = search;
        vm.create = create;
        vm.edit = edit;
        vm.changeData = changeData;
        vm.update = update;

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return UserModuleH5.list({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }

        // 删除分页
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        // 新增租户
        function create() {
            UserModuleH5.listByTenantId({},{},function(resp){
            }).$promise.then(function(data){
                console.log(data.content);
                var entity = {
                    heading : '新增',
                    contentId : "h5-add.html",
                    tenantName:data.content,
                    data : {
                        tenantId:null,
                        name:null,
                        remark:null,
                        iconUrl:null,
                        h5Url:null,
                        disableKeyboard:1,
                        authorizeLogon:0
                    },
                    roles : [],
                    active : true
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });


        }

        // 编辑租户
        function edit(row) {
                // var data = resp.content;
                var entity = {
                    heading : '编辑',
                    contentId : "h5-edit.html",
                    tenantName:null,
                    data : row,
                    roles : [],
                    active : true
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
        }

        // 提交
        function update( tab, idx, form) {
            var data = tab.data;
            // data.tenantId = 1;
            UserModuleH5.save({}, data, function(resp) {
                if (resp.status=="SUCCESS"){
                    toastr.success("保存成功！");
                    vm.removeTab(idx);
                }
            });
        }

        // 禁用/启动切换
        function changeData(row){
            if(row.status === 10) {
                UserModuleH5.disabled({}, {
                    id: row.id
                },function(resp){
                    if(resp.status=="SUCCESS"){
                        // row.status =0;
                        toastr.success("禁用成功！");
                        $timeout(function(){
                            vm.search();
                        });
                    }
                });
            }
            else {
                UserModuleH5.enable({}, {
                    id: row.id
                },function(resp){
                    if(resp.status=="SUCCESS"){
                        // row.status =10;
                        toastr.success("启用成功！");
                        vm.search();
                    }
                });
            }
        }
    }
})();