(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("ReceiptLot", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhReceiptHost + "/web/receiptLot", {}, {
            'getReceiptLotList'     : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/getReceiptLotList", method: 'POST'},
            'getReceiptDetailList'  : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/getReceiptDetailList", method: 'POST'},
            'getReceiptLotDetail'   : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/getReceiptLotDetail", method: 'POST'},
            'batchDeleteLot'        : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/batchDeleteLot", method: 'POST'},
            'batchCompleteLot'      : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/batchCompleteLot", method: 'POST'},
            'generatePutaway'       : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/generatePutaway", method: 'POST'},
            'rotationReceiptLot'    : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/rotationReceiptLot", method:"POST"},
            'rotationReceiptLotByQty'    : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/rotationReceiptLotByQty", method:"POST"},
            'rotationReceiptLotByBatch' : { url: iServiceConfig.iwhReceiptHost + "/web/receiptLot/rotationReceiptLotByBatch", method:"POST"}
        });
    }]);
})();