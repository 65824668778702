(function () {
    'use strict';

    angular.module('itaskModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config ($stateProvider) {
        $stateProvider
            .state('taskConfig', {
                parent: 'main',
                url: 'task/taskConfig',
                data: {
                    'pageTitle': '任务配置'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'task-module/taskConfig/taskConfig.html',
                        controller: 'TaskConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
