(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('MovePlan', MovePlan);

    MovePlan.$inject = ['$resource', 'iServiceConfig'];

    function MovePlan ($resource, iServiceConfig) {
        var service = $resource('web/movePlan', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/movePlan/list", method: 'POST'},
            'generateMoveTask': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/generateMoveTask", method: 'POST'},
            'findMovePlanDetailPage': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/findMovePlanDetailPage", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/cancel", method: 'POST'},
            'handOut': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/handOut", method: 'POST'},
            'getMoveNum': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/getMoveNum", method: 'POST'},
            'saveAndUpdate': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/saveAndUpdate", method: 'POST'},
            'saveAndUpdateDetail': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/saveAndUpdateDetail", method: 'POST'},
            'delDetail': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/delDetail", method: 'POST'},
            'findMovePlanTaskPage': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/findMovePlanTaskPage", method: 'POST'},
            'cancelTask': {url: iServiceConfig.iwhInnerHost + "/web/movePlan/cancelTask", method: 'POST'}
        });

        return service;
    }
})();
