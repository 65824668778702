(function()  {
	'use strict';

	angular.module('iwh-dataModule')
		.controller('IwhStorageTypeZoneController', StorageTypeZoneController);

	// 注入依赖
	StorageTypeZoneController.$inject = ['IwhStorageTypeZone','$rootScope', '$scope', 'NgTableParams',
		'$uibModal', 'NgSelect', 'NgTree','toastr', '$state', '$timeout'];

	/**
	 * 控制器
	 */
	function StorageTypeZoneController (StorageTypeZone,$rootScope, $scope, NgTableParams, $uibModal,
										NgSelect, NgTree, toastr, $state, $timeout) {
		var vm = this;
		vm.tabs = [];
		vm.contentId = "noSelect.html";
		vm.data = null;
		vm.update = update;
		vm.search = search;
		vm.removeTab = removeTab;
		vm.showStorageTypeZone = showStorageTypeZone;
		vm.deleteData = deleteData;
		vm.create = create;
		vm.confirmDelete = confirmDelete;
		vm.type = null;
		vm.select = null;

		 function search() {
			 vm.tableParams.reload();
			}

			function removeTab(idx) {
				vm.tabs.splice(idx, 1);
			}

		//菜单
		// 	StorageTypeZone.storageList({}, {}, function(resp) {
		// 		if (resp.content) {
		// 			vm.data = resp.content;
		// 		}
		// 	});

		vm.tree = new NgTree({
			data : [],
			config : {
				meta : {
					titleField : 'title',
					parentIdField : 'parentId',
					nodeIdField : 'nodeId',
					childField : 'children',
				}
			}
		});
		// 刷新树
		vm.refreshTree = function() {
			StorageTypeZone.getSkuStorageType({}, {}, function(resp) {
				if ( "SUCCESS" == resp.status ) {
					vm.tree.data = resp.content || [];
				} else {
					toastr.error(resp.errorMsg || "加载树失败！", "错误");
				}
			});
		};
		vm.refreshTree();
		// 树节点点击事件
		vm.selectNode = function(node){
			if ( node == undefined || node.data == null) {
				vm.contentId = "noSelect.html";
				vm.type="-1";
				vm.select = "-1";
				vm.search();
				return;
			}
			vm.showStorageTypeZone(node.data.code);
		};


		vm.tableParams = new NgTableParams({count: 10}, {
			getData: function(params) {
				var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'zoneCode'}, params.filter());
				if($state.params.postParams!=null) {
					var  temp = new Object();
				}
				postParams.storageType = vm.type;

				return StorageTypeZone.list({}, postParams,
					function(resp) {
						params.total(resp.content.tc);
					}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});


		//选中分类类型的列表
		function showStorageTypeZone(row) {
			vm.contentId = "showStorageTypeZone.html";
			vm.type=row;
			vm.select = row;
			vm.search();
		}

		function update(data) {
            if (!(/(^[1-9]\d*$)/.test(data.sortOrder))) {
                toastr.error("","顺序号只能为正整数！");
                return;
            }
			var storage = data;
			return StorageTypeZone.save({}, storage).$promise.then(function(resp) {
				if(resp.status === 'SUCCESS') {
					return resp;
				}
			});
		}


		function create(){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'storageTypeZoneeidt.html',
				controller : createStorageTypeZoneModle,
				controllerAs : '$ctrl',
				resolve : {}
			});
		}


		createStorageTypeZoneModle.$inject = [ '$uibModalInstance' ];
		function createStorageTypeZoneModle($uibModalInstance) {
			var $ctrl = this;
			$ctrl.ok = function(){
				$uibModalInstance.close();
			};
			$ctrl.response = {};
			$ctrl.type = vm.type;
			$ctrl.data = {};
			$ctrl.data.sortOrder = "";
			$ctrl.data.storageName = "";
			$ctrl.data.storageType = vm.select;
			$ctrl.data.zoneCode= "";
			$ctrl.data.zoneName = "";
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			};
			$ctrl.submitstorage = function(data) {
				var temp = update(data);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						toastr.success("添加成功", '操作结果');
						vm.search();
						$ctrl.ok();
					} else {
						toastr.error(resp.errorMsg, '错误');
					}
				});
			};
		}


		function deleteData(row){
			console.log(row.id);
			StorageTypeZone.delete({}, {id: row.id}, function() {
				vm.search();
			});
		}


		function confirmDelete(row) {
			vm.modalInstance = $uibModal.open({
				templateUrl: 'deleteStorageTypeZoneModal.html',
				controller: deleteModal,
				controllerAs: '$ctrl',
				resolve: {
					item: function () {
						return row;
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.deleteData(item);
			});
		}

		deleteModal.$inject = ['$uibModalInstance', 'item'];
		function deleteModal($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;

			function ok() {
				$uibModalInstance.close($ctrl.item);
			}

			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}

	}
})();

