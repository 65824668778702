(function () {
    'use strict';

    angular.module('idashboardModule')
        .controller('ThreeDEarthController', ThreeDEarthController);
    ThreeDEarthController.$inject = ['Warehouse3D','$state','$scope','$stateParams','$timeout','cfpLoadingBar'];
    function ThreeDEarthController(Warehouse3D,$state,$scope,$stateParams,$timeout,cfpLoadingBar) {
        var vm=this;
        // vm.title='哇哈哈';

        // 创建地图实例
        var map = new BMap.Map("container");
        // 创建点坐标
        var point1 = new BMap.Point(104.06, 30.67);
        // var point2 = new BMap.Point(118.404, 39.915);
        // 初始化地图，设置中心点坐标和地图级别
        map.centerAndZoom(point1, 15);


        var placeData=[];


        Warehouse3D.getWarehouseList({},{},
            function (res) {
                res.content.forEach(function (v) {
                    placeData.push([v.lng,v.lat,v.name,v]);
                });
                inital();
            },
            function (res) {
                console.log(res,'错误')
            }
        );

        function inital() {
            var pointArray=[];
            var marker;

            for(var i=0;i<placeData.length;i++){
                var data=placeData[i][3];
                marker=new BMap.Marker(new BMap.Point(placeData[i][0],placeData[i][1]));
                map.addOverlay(marker);

                var label = new BMap.Label(placeData[i][2],{
                    offset:new BMap.Size(20,-10)
                });
                label.setStyle({
                    color : "red",
                    fontSize : "12px",
                    height : "20px",
                    lineHeight : "20px",
                    fontFamily:"微软雅黑"
                });
                marker.setLabel(label);


                // marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
                pointArray[i] = new BMap.Point(placeData[i][0],placeData[i][1]);
                var exTitle=placeData[i][2];

                addClickHandler(marker,exTitle,data);

                marker.addEventListener("dblclick",(function (data) {
                    return function () {
                        $state.go('3dZone',{
                            whCode:data.code,
                            whName:data.name
                        });
                    }
                })(data),false);
            }

            function addClickHandler(marker,exTitle,data) {
                marker.addEventListener("click",function () {
                    console.log(data)
                    var me=this;
                    vm.title=exTitle;

                    //弹出框显示内容
                    var sContent =
                        "<div>"+
                            "<h4 style='margin:0 0 5px 0;padding:0.2em 0'>"+vm.title+"</h4>" +
                            "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>仓库名:"+data.name+"</p>" +
                            "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>仓库编码:"+data.code+"</p>" +
                            "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>仓库品种数:"+data.breedCount+"</p>" +
                            "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>仓库总库存:"+data.qtyTotal+"</p>" +
                            "<p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>仓库容量百分比:"+data.percent+"</p>" +
                            "<div><button id="+data.code+" style='width: 50px;height:25px;line-height: 25px;background-color: #e0e0e0;border:1px solid #e0e0e0'>库区</button><button id="+data.code+'loc'+" style='width: 50px;height:25px;line-height: 25px;background-color: #e0e0e0;border:1px solid #e0e0e0;margin-left: 5px'>库位</button></div>"+
                        "</div>";

                    me.openInfoWindow(new BMap.InfoWindow(sContent));
                    $timeout(function () {
                        document.getElementById(data.code).addEventListener('click',function (e) {
                            $state.go('3dZone',{
                                whCode:data.code,
                                whName:data.name
                            });
                        })
                        document.getElementById(data.code+'loc').addEventListener('click',function (e) {
                            $state.go('3dLoc',{
                                whCode:data.code,
                                whName:data.name,
                                zoneCode:'null',
                                zoneName:''
                            });
                        })
                    },0);

                });
            }

            function handledblClick(data) {
                //双击处理
                console.log(data)
            }

            //让所有点在视野范围内
            map.setViewport(pointArray);
            //为了避免出现点在边边的情况再缩小一点
            map.setZoom(map.getZoom()-1);

            // //给红标点加点击事件
            // var marker1 = new BMap.Marker(point1);
            // marker1.addEventListener('click',function () {
            //     console.log(124124)
            // })
            // map.addOverlay(marker1);
            //
            //
            // var marker2 = new BMap.Marker(point2);
            // marker2.addEventListener('click',function () {
            //     console.log('丢雷螺母')
            // })
            // map.addOverlay(marker2);
            map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放

        }

    }
})();


