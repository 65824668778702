(function () {
    'use strict';

    angular
        .module('iwh-shipModule')
        .constant('ApiURI',{
                'getSkuCodeBySkuBarcode': '/web/skuRest/getSkuCodeBySkuBarcode',
                'getContainerGroupList': '/web/containerGroupRest/getContainerGroupList',
                'getCodeList': '/web/codeRest/getCodeList/',
                'jsonpParam': 'callback=JSON_CALLBACK&token='
            }
        )
;
})();
