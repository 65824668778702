(function() {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('SaleRecord', SaleRecord);

    SaleRecord.$inject = ['$resource', 'iServiceConfig'];

    function SaleRecord ($resource, iServiceConfig) {
        var service = $resource('/web/saleRecord', {}, {
            'page': { url: iServiceConfig.ienterpriseHost + "/web/saleRecord/page", method: 'POST'},

        });
        return service;
    }
})();
