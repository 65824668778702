(function () {
    'use strict';

    angular.module('iwh-inventoryModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('ageInventoryQuery', {
                parent: 'main',
                url: 'iwh-inventory/ageInventoryQuery',
                data: {
                    'pageTitle': '库龄预警'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/age/ageInventoryQuery.html',
                        controller: 'AgeInventoryQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
        ;
    }
})();
