(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .config(iServiceConfig);

    iServiceConfig.$inject = ['iServiceConfig', 'APPID'];

    function iServiceConfig(iServiceConfig, APPID) {
        angular.extend(iServiceConfig, {
            appid: APPID
        });
    }
})();
