(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateDsDataSource', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/dsdatasource', {}, {
                'getDsDataSourceList': {
                    url: iServiceConfig.iergateHost + '/web/dsdatasource/getDsDataSourceList',
                    method: 'GET'
                },
                'updateOrSaveDsDataSource': {
                    url: iServiceConfig.iergateHost + '/web/dsdatasource/updateOrSaveDsDataSource',
                    method: 'POST'
                },
                'deleteDsDataSource': {
                    url: iServiceConfig.iergateHost + '/web/dsdatasource/deleteDsDataSource',
                    method: 'POST'
                }
            });
        }]);
})();