(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('InnerTransfer', InnerTransfer);

    InnerTransfer.$inject = ['$resource', 'iServiceConfig'];

    function InnerTransfer ($resource, iServiceConfig) {
        var service = $resource('web/transfer', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/transfer/list", method: 'POST'},
            'detailList': { url: iServiceConfig.iwhInnerHost + "/web/transfer/detailList", method: 'POST'},
            'allocateList': { url: iServiceConfig.iwhInnerHost + "/web/transfer/allocateList", method: 'POST'},
            'receiptList': { url: iServiceConfig.iwhInnerHost + "/web/transfer/receiptList", method: 'POST'},
            'logList': { url: iServiceConfig.iwhInnerHost + "/web/transfer/logList", method: 'POST'},
            'edit': { url: iServiceConfig.iwhInnerHost + "/web/transfer/edit", method: 'POST'},
            'getTransferNum': {url: iServiceConfig.iwhInnerHost + "/web/transfer/getTransferNum", method: 'POST'},
            'getTransferLineNum': {url: iServiceConfig.iwhInnerHost + "/web/transfer/getTransferLineNum", method: 'POST'},
            'saveAndUpdateDetail': {url: iServiceConfig.iwhInnerHost + "/web/transfer/saveAndUpdateDetail", method: 'POST'},
            'saveAndUpdate': {url: iServiceConfig.iwhInnerHost + "/web/transfer/saveAndUpdate", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/transfer/cancel", method: 'POST'},
            'plan': {url: iServiceConfig.iwhInnerHost + "/web/transfer/plan", method: 'POST'},
            'close': {url: iServiceConfig.iwhInnerHost + "/web/transfer/close", method: 'POST'},
            'delDetails': {url: iServiceConfig.iwhInnerHost + "/web/transfer/delDetails", method: 'POST'},
            'allocate': {url: iServiceConfig.iwhInnerHost + "/web/transfer/allocate", method: 'POST'},
            'executeTransfer': {url: iServiceConfig.iwhInnerHost + "/web/transfer/executeTransfer", method: 'POST'},
            'getSkuRuleInfo': {url: iServiceConfig.iwhInnerHost + "/web/transfer/getSkuRuleInfo", method: 'POST'},
        });

        return service;
    }
})();
