(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateDsRouteKey', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/dsroutekey', {}, {
                'getDsRouteKeyList': {
                    url: iServiceConfig.iergateHost + '/web/dsroutekey/getDsRouteKeyList',
                    method: 'GET'
                },
                'updateOrSaveDsRouteKey': {
                    url: iServiceConfig.iergateHost + '/web/dsroutekey/updateOrSaveDsRouteKey',
                    method: 'POST'
                },
                'deleteDsRouteKey': {
                    url: iServiceConfig.iergateHost + '/web/dsroutekey/deleteDsRouteKey',
                    method: 'POST'
                }
            });
        }]);
})();