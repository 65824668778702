(function() {
    'use strict';

    angular.module('iprintModule')
        .controller('PrinterController', PrinterController);

    PrinterController.$inject = ['Printer', 'NgTableParams','$uibModal'];

    function PrinterController(Printer, NgTableParams, $uibModal) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'故障'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.search = search;
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return Printer.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }

    }
})();
