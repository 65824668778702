(function() {
    'use strict';

    angular.module('doveModule')
        .controller('iBusMainContentController', iBusMainContentController);

    iBusMainContentController.$inject = ['iBusRunLog', 'iBusDataSourceRunning','iBusProcessRunning', 'iBusTaskRunning', 'NgTableParams', '$interval', '$scope'];

    function iBusMainContentController (iBusRunLog, iBusDataSourceRunning,iBusProcessRunning, iBusTaskRunning, NgTableParams, $interval, $scope) {
        var vm = this;
        vm.scheduleParams = null;
        vm.processParams = null;
        vm.logParams = null;
        vm.refreshId = null;
        vm.datasourceCount = {'runningCount':0, 'totalCount':0};
        vm.taskCount = {'runningCount':0, 'totalCount':0};
        vm.processCount = {'runningCount':0, 'totalCount':0};
        vm.exState = {'queueSize':0, 'activeCount':0, 'taskCount':0, 'poolSize':0, 'corePoolSize':0, 'completeCount':0};

        vm.scheduleParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusTaskRunning.list({}, postParams,
                    function(resp) {

                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            },counts: []
        });

        vm.processParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusProcessRunning.list({}, postParams,
                    function(resp) {

                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            },counts: []
        });

        vm.logParams = new NgTableParams({count: 20}, {
            getData: function(params) {
                var postParams = angular.merge({pn: 1, ps: 20}, params.filter());
                return iBusRunLog.search({}, postParams,
                    function(resp) {
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            },counts: []
        });

        refresh();
        searchExState();
        vm.refreshId = $interval(function() {
            refresh();
        }, 3000);
        
        vm.refreshExId = $interval(function() {
        	searchExState();
        }, 1500);

        function refresh() {
            searchDatasourceCount();
            searchTaskCount();
            searchProcessCount();
            searchProcess();
            searchSchedule();
            searchLog();
        }
        
        function searchExState() {
        	iBusTaskRunning.exState(function(data) {
                vm.exState = data.content;
            });
        }
        function searchDatasourceCount() {
            iBusDataSourceRunning.runningCount(function(data) {
                vm.datasourceCount = data.content;
            });
        }

        function searchTaskCount() {
            iBusTaskRunning.runningCount(function(data) {
                vm.taskCount = data.content;
            });
        }

        function searchProcessCount() {
            iBusProcessRunning.runningCount(function(data) {
                vm.processCount = data.content;
            });
        }

        function searchSchedule() {
            vm.scheduleParams.reload();
        }

        function searchProcess() {
            vm.processParams.reload();
        }

        function searchLog() {
            vm.logParams.reload();
        }

        $scope.$on('$destroy', function() {
            if(vm.refreshId) {
                $interval.cancel(vm.refreshId);
                vm.refreshId = null;
            }
            if(vm.refreshExId) {
            	$interval.cancel(vm.refreshExId);
                vm.refreshExId = null;
            }
        });
    }
})();
