(function() {
    'use strict';

    angular
        .module('iwh-strategyModule')
        .factory('StrategyReceiptConfig', StrategyReceiptConfig);

    StrategyReceiptConfig.$inject = ['$resource', 'iServiceConfig'];

    function StrategyReceiptConfig ($resource, iServiceConfig) {
        var service = $resource('web/receiptConfig', {}, {
            'getReceiptConfig': { url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/getReceiptConfig", method: 'POST'},
            'saveReceiptConfig': { url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/saveReceiptConfig", method: 'POST'},
            'saveDevice': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/saveDevice", method: 'POST'},
            'editeDevice': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/editeDevice", method: 'POST'},
            'deleteDevice': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/deleteDevice", method: 'POST'},
            'devicePage': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/devicePage", method: 'POST'},
            'ownerPage': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/ownerPage", method: 'POST'},
            'editeReceiptConfigOwner': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/editeReceiptConfigOwner", method: 'POST'},
            'ownerDevicePage': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/ownerDevicePage", method: 'POST'},
            'receiptConfigOwnerSave': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/receiptConfigOwnerSave", method: 'POST'},
            'saveReceiptConfigOwnerDevice': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/saveReceiptConfigOwnerDevice", method: 'POST'},
            'editeOwnerDevice': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/editeOwnerDevice", method: 'POST'},
            'deleteOwnerDevice': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/deleteOwnerDevice", method: 'POST'},
            'deleteOwner': {url: iServiceConfig.iwhStrategyHost + "/web/receiptConfig/deleteOwner", method: 'POST'},
        });
        return service;
    }
})();
