(function () {
    'use strict';

    angular.module('iplanModule')
        .controller('iPlanWarehouseIndexController', iPlanWarehouseIndexController);

    iPlanWarehouseIndexController.$inject = ['$scope', 'iPlanWarehousePlan', 'toastr', '$timeout','NgTableParams'];

    function iPlanWarehouseIndexController($scope, iPlanWarehousePlan, toastr, $timeout,NgTableParams) {
        var vm = this;
        vm.tabs = [
            {
                heading: '按天查看预约'
            }
        ];
        vm.active = 0;
        vm.years = [];

        vm.selectChange = selectChange;
        vm.selectChangePark = selectChangePark;
        vm.changeDayPark=changeDayPark;         //改变按天页面的园区时触发的事件
        vm.changeDayWarehouse=changeDayWarehouse;   //改变按天页面的仓库时触发的事件

        var time = new Date();
        var year = time.getFullYear();            //年
        var month = time.getMonth() + 1;            //月
        var date = time.getDate();                 //日
        for (var j = 2015; j <= year; j++) {
            vm.years.push({
                id: j,
                title: j
            });
        }
        vm.months = [];
        for (var i = 1; i <= 12; i++) {
            vm.months.push({
                id: i,
                title: i
            });
        }

        iPlanWarehousePlan.getParkList({}, {}, function (resp) {
            if (resp.content) {
                vm.parks = resp.content;
                //将第一个元素添加成 全部
                vm.parks.splice(0, 0, {code: 'NULL', name: '全部'});
                $timeout(function () {
                    vm.data.park = vm.parks[0];
                    vm.datas.park = vm.parks[0];
                    getWarehouseList();
                    getWarehousePlanByMonth();
                    getDayWarehouseList();
                });
            }
        });

        vm.data = {
            park: '',
            warehouse: '',
            year: year,
            month: month,
            day:'',
            //来判断是月份双击改变时间的还是在按天页面自行改变时间的。默认是按天页面自行改变
            monthChangeTime:false,
            mustChangeData:0       //通过改变他来触发calneder组件的watch方法
        };
        vm.datas = {
            park: '',
            warehouse: '',
            date: new Date()
        };
        vm.allowDayQuery=true;              //是否允许天预约单独请求，默认可以，在请求过一次数据之后置为false
        vm.data.allowDateChangeQuery=true;

        /**
         * 园区改变
         */
        function selectChangePark() {
            //请求仓库列表
            getWarehouseList();
            //请求预约
            getWarehousePlanByMonth();
        }

        function changeDayPark() {
            iPlanWarehousePlan.getWarehouseList({parkCode: vm.datas.park.code}, {}, function (resp) {
                if (resp.content) {
                    vm.dayWarehouses = resp.content;
                    //将第一个元素添加成 全部
                    vm.dayWarehouses.splice(0, 0, {code: 'NULL', name: '全部'});
                    vm.datas.warehouse = vm.dayWarehouses[0];
                }
            });

            var date=vm.datas.date;
            var param = {
                day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                parkCode: vm.datas.park.code,
                warehouseCode: vm.datas.warehouse.code?vm.datas.warehouse.code:'NULL'
            };
            iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                if (resp.content) {
                    //天页面展示所需的数据
                    vm.data.plans.length=0;
                    vm.data.plans=resp.content;
                }
            });

            var parseTime=new Date(vm.datas.date);
            //事先获取点击的年月日
            var speYear=parseTime.getFullYear();
            var speMonth=parseTime.getMonth();
            var speDate=parseTime.getDate();

            var todayStartTime=new Date(speYear+'-'+(speMonth+1)+'-'+speDate);
            var todayEndTime=new Date(speYear+'-'+(speMonth+1)+'-'+speDate+' 23:59:59');


            vm.tableParams = new NgTableParams({count: 1000}, {
                getData: function (params) {
                    var postParams={
                        pn:1,
                        ps:1000,
                        start:0,
                        // planStartTimeStart:Date.parse(date)
                    };
                    // postParams.planStartTimeStart=Date.parse(date);
                    return iPlanWarehousePlan.search({}, postParams,
                        function (resp) {

                            allowAgainAsk=true;
                            params.total(resp.content.tc);
                            var result=[];
                            resp.content.datas.forEach(function (v) {

                                if(v.planType===10){
                                    v.planTypeInfo='卸货';
                                }else if(v.planType===20){
                                    v.planTypeInfo='装货';
                                }

                                if(v.status===1){
                                    v.statusInfo='新建';
                                }else if(v.status===2){
                                    v.statusInfo='已预约';
                                }else if(v.status===3){
                                    v.statusInfo='已检入';
                                }else if(v.status===4){
                                    v.statusInfo='已停泊';
                                }else if(v.status===5){
                                    v.statusInfo='装卸中';
                                }else if(v.status===6){
                                    v.statusInfo='已检出';
                                }else if(v.status===7){
                                    v.statusInfo='已关闭';
                                }else if(v.status===8){
                                    v.statusInfo='已取消';
                                }



                                var planStartTime=new Date(v.planStartTime);
                                var planEndTime=new Date(v.planEndTime);
                                if(planStartTime<=todayEndTime && planEndTime>=todayStartTime){
                                    //表示起始时间刚好是这一天
                                    //确保时间是同一天之后，还要确保如果选了园区和仓库之后数据也要对应
                                    if(vm.datas.park && vm.datas.park.code && vm.datas.park.code!=='NULL'){
                                        //这里代表选择了园区,然后还要考虑是否选了仓库
                                        if(vm.datas.warehouse && vm.datas.warehouse.code && vm.datas.warehouse.code!=='NULL'){
                                            //如果选了仓库,那园区和仓库都要对应相等才行
                                            if(v.packCode===vm.datas.park.code && v.warehouseCode===vm.datas.warehouse.code){
                                                if(v.status!==8){
                                                    result.push(v);
                                                }
                                            }

                                        }else{
                                            //没有选仓库,只需要园区号相等即可
                                            if(v.packCode===vm.datas.park.code){
                                                if(v.status!==8){
                                                    result.push(v);
                                                }
                                            }
                                        }

                                    }else{
                                        if(v.status!==8){
                                            result.push(v);
                                        }
                                    }
                                }
                            });
                            // console.log(result)
                            return result;
                        }).$promise.then(function (data) {

                        var result=[];
                        data.content.datas.forEach(function (v) {
                            if(v.planType===10){
                                v.planTypeInfo='卸货';
                            }else if(v.planType===20){
                                v.planTypeInfo='装货';
                            }
                            if(v.status===1){
                                v.statusInfo='新建';
                            }else if(v.status===2){
                                v.statusInfo='已预约';
                            }else if(v.status===3){
                                v.statusInfo='已检入';
                            }else if(v.status===4){
                                v.statusInfo='已停泊';
                            }else if(v.status===5){
                                v.statusInfo='装卸中';
                            }else if(v.status===6){
                                v.statusInfo='已检出';
                            }else if(v.status===7){
                                v.statusInfo='已关闭';
                            }else if(v.status===8){
                                v.statusInfo='已取消';
                            }
                            var planStartTime=new Date(v.planStartTime);
                            var planEndTime=new Date(v.planEndTime);
                            if(planStartTime<=todayEndTime && planEndTime>=todayStartTime){
                                //表示起始时间刚好是这一天
                                if(vm.datas.park && vm.datas.park.code && vm.datas.park.code!=='NULL'){
                                    //这里代表选择了园区,然后还要考虑是否选了仓库
                                    if(vm.datas.warehouse && vm.datas.warehouse.code && vm.datas.warehouse.code!=='NULL'){
                                        //如果选了仓库,那园区和仓库都要对应相等才行
                                        if(v.packCode===vm.datas.park.code && v.warehouseCode===vm.datas.warehouse.code){
                                            if(v.status!==8){
                                                result.push(v);
                                            }
                                        }
                                    }else{
                                        //没有选仓库,只需要园区号相等即可
                                        if(v.packCode===vm.datas.park.code){
                                            if(v.status!==8){
                                                result.push(v);
                                            }
                                        }
                                    }
                                }else{
                                    if(v.status!==8){
                                        result.push(v);
                                    }
                                }
                            }
                        });
                        return result;
                    });
                }
            });
        }

        function changeDayWarehouse() {
            var date=vm.datas.date;
            var param = {
                day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                parkCode: vm.datas.park.code,
                warehouseCode: vm.datas.warehouse.code?vm.datas.warehouse.code:'NULL'
            };

            iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                if (resp.content) {
                    //天页面展示所需的数据
                    vm.data.plans=resp.content;
                }
            });

            var parseTime=new Date(vm.datas.date);
            //事先获取点击的年月日
            var speYear=parseTime.getFullYear();
            var speMonth=parseTime.getMonth();
            var speDate=parseTime.getDate();

            var todayStartTime=new Date(speYear+'-'+(speMonth+1)+'-'+speDate);
            var todayEndTime=new Date(speYear+'-'+(speMonth+1)+'-'+speDate+' 23:59:59');


            vm.tableParams = new NgTableParams({count: 1000}, {
                getData: function (params) {
                    var postParams={
                        pn:1,
                        ps:1000,
                        start:0,
                        // planStartTimeStart:Date.parse(date)
                    };
                    // postParams.planStartTimeStart=Date.parse(date);
                    return iPlanWarehousePlan.search({}, postParams,
                        function (resp) {
                            allowAgainAsk=true;
                            params.total(resp.content.tc);
                            var result=[];
                            resp.content.datas.forEach(function (v) {
                                if(v.planType===10){
                                    v.planTypeInfo='卸货';
                                }else if(v.planType===20){
                                    v.planTypeInfo='装货';
                                }

                                if(v.status===1){
                                    v.statusInfo='新建';
                                }else if(v.status===2){
                                    v.statusInfo='已预约';
                                }else if(v.status===3){
                                    v.statusInfo='已检入';
                                }else if(v.status===4){
                                    v.statusInfo='已停泊';
                                }else if(v.status===5){
                                    v.statusInfo='装卸中';
                                }else if(v.status===6){
                                    v.statusInfo='已检出';
                                }else if(v.status===7){
                                    v.statusInfo='已关闭';
                                }else if(v.status===8){
                                    v.statusInfo='已取消';
                                }


                                var planStartTime=new Date(v.planStartTime);
                                var planEndTime=new Date(v.planEndTime);
                                if(planStartTime<=todayEndTime && planEndTime>=todayStartTime){
                                    //表示起始时间刚好是这一天
                                    //确保时间是同一天之后，还要确保如果选了园区和仓库之后数据也要对应
                                    if(vm.datas.park && vm.datas.park.code && vm.datas.park.code!=='NULL'){
                                        //这里代表选择了园区,然后还要考虑是否选了仓库
                                        if(vm.datas.warehouse && vm.datas.warehouse.code && vm.datas.warehouse.code!=='NULL'){
                                            //如果选了仓库,那园区和仓库都要对应相等才行
                                            if(v.packCode===vm.datas.park.code && v.warehouseCode===vm.datas.warehouse.code){
                                                if(v.status!==8){
                                                    result.push(v);
                                                }
                                            }

                                        }else{
                                            //没有选仓库,只需要园区号相等即可
                                            if(v.packCode===vm.datas.park.code){
                                                if(v.status!==8){
                                                    result.push(v);
                                                }
                                            }
                                        }

                                    }else{
                                        if(v.status!==8){
                                            result.push(v);
                                        }
                                    }
                                }
                            });
                            // console.log(result)
                            return result;
                        }).$promise.then(function (data) {
                        var result=[];
                        data.content.datas.forEach(function (v) {
                            if(v.planType===10){
                                v.planTypeInfo='卸货';
                            }else if(v.planType===20){
                                v.planTypeInfo='装货';
                            }

                            if(v.status===1){
                                v.statusInfo='新建';
                            }else if(v.status===2){
                                v.statusInfo='已预约';
                            }else if(v.status===3){
                                v.statusInfo='已检入';
                            }else if(v.status===4){
                                v.statusInfo='已停泊';
                            }else if(v.status===5){
                                v.statusInfo='装卸中';
                            }else if(v.status===6){
                                v.statusInfo='已检出';
                            }else if(v.status===7){
                                v.statusInfo='已关闭';
                            }else if(v.status===8){
                                v.statusInfo='已取消';
                            }
                            var planStartTime=new Date(v.planStartTime);
                            var planEndTime=new Date(v.planEndTime);
                            if(planStartTime<=todayEndTime && planEndTime>=todayStartTime){
                                //表示起始时间刚好是这一天
                                if(vm.datas.park && vm.datas.park.code && vm.datas.park.code!=='NULL'){
                                    //这里代表选择了园区,然后还要考虑是否选了仓库
                                    if(vm.datas.warehouse && vm.datas.warehouse.code && vm.datas.warehouse.code!=='NULL'){
                                        //如果选了仓库,那园区和仓库都要对应相等才行
                                        if(v.packCode===vm.datas.park.code && v.warehouseCode===vm.datas.warehouse.code){
                                            if(v.status!==8){
                                                result.push(v);
                                            }
                                        }
                                    }else{
                                        //没有选仓库,只需要园区号相等即可
                                        if(v.packCode===vm.datas.park.code){
                                            if(v.status!==8){
                                                result.push(v);
                                            }
                                        }
                                    }
                                }else{
                                    if(v.status!==8){
                                        result.push(v);
                                    }
                                }
                            }
                        });
                        return result;
                    });
                }
            });
        }

        /**
         * 其它下拉框改变
         */
        function selectChange() {
            //请求预约
            getWarehousePlanByMonth();
        }

        /**
         * 获取园区
         */
        function getWarehouseList() {
            iPlanWarehousePlan.getWarehouseList({parkCode: vm.data.park.code}, {}, function (resp) {
                if (resp.content) {
                    vm.warehouses = resp.content;
                    //将第一个元素添加成 全部
                    vm.warehouses.splice(0, 0, {code: 'NULL', name: '全部'});
                    vm.data.warehouse = vm.warehouses[0];
                    // vm.datas.warehouse = vm.warehouses[0];
                }
            });
        }

        function getDayWarehouseList() {
            iPlanWarehousePlan.getWarehouseList({parkCode: vm.datas.park.code}, {}, function (resp) {
                if (resp.content) {
                    vm.dayWarehouses = resp.content;
                    //将第一个元素添加成 全部
                    vm.dayWarehouses.splice(0, 0, {code: 'NULL', name: '全部'});
                    vm.datas.warehouse = vm.dayWarehouses[0];
                    // vm.datas.warehouse = vm.warehouses[0];
                }
            });
        }

        /**
         * 按月获取仓库预约
         */
        function getWarehousePlanByMonth() {
            var param = {
                month: new Date(vm.data.year, vm.data.month - 1),
                parkCode: vm.data.park.code,
                warehouseCode: vm.data.warehouse.code?vm.data.warehouse.code:'NULL'
            };
            iPlanWarehousePlan.getWarehousePlanByMonth({}, param, function (resp) {
                if (resp.content) {
                    vm.data.someday = resp.content;
                }
            });
        }

        vm.showMonth = true;              //是否显示月份，默认为true
        vm.showDay = true;                //是否显示天
        vm.hideMonth = false;

        vm.backToToday = backToToday;         //返回今天
        vm.backToTodays = backToTodays;         //返回今天
        vm.changeMonth = changeMonth;           //选择月预约
        vm.changeDay=changeDay;                 //选择天预约


        function backToToday() {
            var date=new Date();
            if(vm.data.year!==date.getFullYear() || vm.data.month!==date.getMonth()+1){
                vm.data.year=date.getFullYear();
                vm.data.month=date.getMonth()+1;
                getWarehousePlanByMonth();          //重新加载数据
            }
        }

        function backToTodays() {

            var date=new Date();
            if(date.getFullYear()!==vm.datas.date.getFullYear() || date.getMonth()!==vm.datas.date.getMonth() || date.getDate()!==vm.datas.date.getDate()){
                //只有不一样时才发起请求

                vm.datas.date=date;

                var param = {
                    day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                    parkCode: vm.datas.park.code,
                    warehouseCode: vm.datas.warehouse.code?vm.datas.warehouse.code:'NULL'
                };
                iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                    if (resp.content) {
                        //天页面展示所需的数据
                        vm.data.plans=resp.content;
                    }
                });
            }
        }

        function changeMonth() {
            vm.showMonth = true;
        }

        function changeDay() {
            $timeout(function () {
                if(!vm.data.plans && vm.allowDayQuery){
                    var date=new Date();
                    var param = {
                        day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                        parkCode: vm.datas.park.code,
                        warehouseCode: vm.datas.warehouse.code?vm.datas.warehouse.code:'NULL'
                    };
                    iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                        if (resp.content) {
                            //天页面展示所需的数据
                            vm.data.plans=resp.content;
                        }
                    });
                }
            },100);
        }

        // $scope.$watch('vm.hideMonth',function (n) {
        //     console.log(n)
        // });


        var monthWatch=$scope.$watch('vm.showMonth', function (n) {
            if (!n) {
                vm.allowDayQuery=false;
                vm.active = 1;
                var param = {
                    day: new Date(vm.data.year, vm.data.month-1,vm.data.day),
                    parkCode: vm.data.park.code,
                    warehouseCode: vm.data.warehouse.code?vm.data.warehouse.code:'NULL'
                };
                iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                    if (resp.content) {
                        //天页面展示所需的数据
                        vm.data.plans=[];
                        resp.content.forEach(function (v) {
                            if(v.status!==8){
                                vm.data.plans.push(v);
                            }
                        });
                    }
                });

                //请求天页面仓库列表的数据
                iPlanWarehousePlan.getWarehouseList({parkCode: vm.datas.park.code}, {}, function (resp) {
                    if (resp.content) {
                        vm.dayWarehouses = resp.content;
                        //将第一个元素添加成 全部
                        vm.dayWarehouses.splice(0, 0, {code: 'NULL', name: '全部'});
                        var num=vm.warehouses.indexOf(vm.data.warehouse);
                        vm.datas.warehouse = vm.dayWarehouses[num];
                        // $timeout(function () {
                        //     vm.data.allowDateChangeQuery=false;
                        // });
                    }
                });



            } else {
                vm.active = 0;
            }
        });

        var allowAgainAsk=true;

        var dateWatch=$scope.$watch('vm.datas.date',function (n) {
            var date=vm.datas.date;
            if(!date.length && vm.active===1 && vm.data.allowDateChangeQuery){
                var param = {
                    day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                    parkCode: vm.datas.park.code,
                    warehouseCode: vm.datas.warehouse.code?vm.datas.warehouse.code:'NULL'
                };
                iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                    if (resp.content) {
                        //天页面展示所需的数据
                        vm.data.plans=resp.content;
                        vm.data.mustChangeData++;
                    }
                });
            }

            if(allowAgainAsk){  //避免多次请求
                allowAgainAsk=false;
                var parseTime=new Date(vm.datas.date);
                //事先获取点击的年月日
                var speYear=parseTime.getFullYear();
                var speMonth=parseTime.getMonth();
                var speDate=parseTime.getDate();

                var todayStartTime=new Date(speYear+'-'+(speMonth+1)+'-'+speDate);
                var todayEndTime=new Date(speYear+'-'+(speMonth+1)+'-'+speDate+' 23:59:59');

                var finalData='';
                if(vm.data.monthChangeTime){
                    finalData=vm.data;
                }else{
                    finalData=vm.datas;
                }

                vm.tableParams = new NgTableParams({count: 1000}, {
                    getData: function (params) {
                        var postParams={
                            pn:1,
                            ps:1000,
                            start:0,
                            // planStartTimeStart:Date.parse(date)
                        };
                        return iPlanWarehousePlan.search({}, postParams,
                            function (resp) {
                                vm.data.monthChangeTime=false;
                                allowAgainAsk=true;
                                params.total(resp.content.tc);
                                var result=[];
                                resp.content.datas.forEach(function (v) {
                                    if(v.planType===10){
                                        v.planTypeInfo='卸货';
                                    }else if(v.planType===20){
                                        v.planTypeInfo='装货';
                                    }
                                    if(v.status===1){
                                        v.statusInfo='新建';
                                    }else if(v.status===2){
                                        v.statusInfo='已预约';
                                    }else if(v.status===3){
                                        v.statusInfo='已检入';
                                    }else if(v.status===4){
                                        v.statusInfo='已停泊';
                                    }else if(v.status===5){
                                        v.statusInfo='装卸中';
                                    }else if(v.status===6){
                                        v.statusInfo='已检出';
                                    }else if(v.status===7){
                                        v.statusInfo='已关闭';
                                    }else if(v.status===8){
                                        v.statusInfo='已取消';
                                    }
                                    var planStartTime=new Date(v.planStartTime);
                                    var planEndTime=new Date(v.planEndTime);
                                    if(planStartTime<=todayEndTime && planEndTime>=todayStartTime){

                                        //只有满足这个条件才是包含了当天时间的任务
                                        if(finalData.park && finalData.park.code && finalData.park.code!=='NULL'){

                                            //这里代表选择了园区,然后还要考虑是否选了仓库
                                            if(finalData.warehouse && finalData.warehouse.code && finalData.warehouse.code!=='NULL'){
                                                //如果选了仓库,那园区和仓库都要对应相等才行
                                                if(v.packCode===finalData.park.code && v.warehouseCode===finalData.warehouse.code){
                                                    if(v.status!==8){
                                                        result.push(v);
                                                    }
                                                }
                                            }else{
                                                //没有选仓库,只需要园区号相等即可
                                                if(v.packCode===finalData.park.code){
                                                    if(v.status!==8){
                                                        result.push(v);
                                                    }
                                                }
                                            }
                                        }else{
                                            if(v.status!==8){
                                                result.push(v);
                                            }
                                        }
                                    }
                                });
                                return result;
                            }).$promise.then(function (data) {
                                vm.data.monthChangeTime=false;
                                var result=[];
                                data.content.datas.forEach(function (v) {
                                    if(v.planType===10){
                                        v.planTypeInfo='卸货';
                                    }else if(v.planType===20){
                                        v.planTypeInfo='装货';
                                    }
                                    if(v.status===1){
                                        v.statusInfo='新建';
                                    }else if(v.status===2){
                                        v.statusInfo='已预约';
                                    }else if(v.status===3){
                                        v.statusInfo='已检入';
                                    }else if(v.status===4){
                                        v.statusInfo='已停泊';
                                    }else if(v.status===5){
                                        v.statusInfo='装卸中';
                                    }else if(v.status===6){
                                        v.statusInfo='已检出';
                                    }else if(v.status===7){
                                        v.statusInfo='已关闭';
                                    }else if(v.status===8){
                                        v.statusInfo='已取消';
                                    }

                                    var planStartTime=new Date(v.planStartTime);
                                    var planEndTime=new Date(v.planEndTime);
                                    if(planStartTime<=todayEndTime && planEndTime>=todayStartTime){
                                        //只有满足这个条件才是包含了当天时间的任务
                                        if(finalData.park && finalData.park.code && finalData.park.code!=='NULL'){
                                            //这里代表选择了园区,然后还要考虑是否选了仓库
                                            if(finalData.warehouse && finalData.warehouse.code && finalData.warehouse.code!=='NULL'){
                                                //如果选了仓库,那园区和仓库都要对应相等才行
                                                if(v.packCode===finalData.park.code && v.warehouseCode===finalData.warehouse.code){
                                                    if(v.status!==8){
                                                        result.push(v);
                                                    }
                                                }
                                            }else{
                                                //没有选仓库,只需要园区号相等即可
                                                if(v.packCode===finalData.park.code){
                                                    if(v.status!==8){
                                                        result.push(v);
                                                    }
                                                }
                                            }
                                        }else{
                                            if(v.status!==8){
                                                result.push(v);
                                            }
                                        }
                                    }
                                });
                                return result;
                        });
                    }
                });
            }

        });





        $scope.$on('$destroy',function () {
            //删除监听器
            monthWatch();
            dateWatch();
        });

    }
})();
