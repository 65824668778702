(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('WorkController',
        WorkController);

    WorkController.$inject = [ 'Work', 'NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state'];

    function WorkController(Work, NgTableParams, $uibModal,enums,
                                  $timeout, toastr,$state) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.params = {};
        vm.removeTab = removeTab;
        vm.search = search;

        vm.typeObj = enums('inner.WorkType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.WorkOrderStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.materialStatusObj = enums('inner.WorkMaterialStatus');
        vm.materialStatus = vm.materialStatusObj.list('title', 'id');

        vm.productStatusObj = enums('inner.WorkProductStatus');
        vm.productStatus = vm.productStatusObj.list('title', 'id');

        vm.pickStatusObj = enums('inner.WorkMaterialPickStatus');
        vm.pickStatus = vm.pickStatusObj.list('title', 'id');

        vm.upStatusObj = enums('inner.WorkUpStatus');
        vm.upStatus = vm.upStatusObj.list('title', 'id');

        vm.workTypeObj = enums('inner.WorkType');
        vm.workType = vm.workTypeObj.list('title', 'id');

        vm.workMethodObj = enums('inner.WorkMethod');
        vm.workMethod = vm.workMethodObj.list('title', 'id');

        vm.productWorkStatusObj = enums('inner.ProductWorkStatus');
        vm.productWorkStatus = vm.productWorkStatusObj.list('title', 'id');

        vm.upTypeObj = enums('inner.WorkUpType');
        vm.upType = vm.upTypeObj.list('title', 'id');

        vm.workLogTypeObj = enums('inner.WorkOrderLogType');
        vm.workLogType = vm.workLogTypeObj.list('title', 'id');


        vm.create = create;
        vm.save = save;
        vm.edit = edit;
        vm.searchMaterial = searchMaterial;
        vm.searchProduct = searchProduct;
        vm.searchProductWork = searchProductWork;
        vm.searchPick = searchPick;
        vm.searchUp = searchUp;
        vm.searchLog = searchLog;
        vm.searchAll = searchAll;


        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function searchProduct(t) {
            t.productTableParams.reload();
        }

        function searchProductWork(t) {
            t.productWorkTableParams.reload();
        }
        function searchPick(t) {
            t.materialPickTableParams.reload();
        }
        function searchUp(t) {
            t.upTableParams.reload();
        }

        function searchLog(t) {
            t.logTableParams.reload();
        }

        function searchMaterial(t) {
            t.materialTableParams.reload();
        }

        function searchAll(t) {
            t.productTableParams.reload();
            t.productWorkTableParams.reload();
            t.materialPickTableParams.reload();
            t.upTableParams.reload();
            t.logTableParams.reload();
            t.materialTableParams.reload();
            vm.tableParams.reload();
        }

        function removeTab(tabs, idx, isVm) {
            tabs.splice(idx, 1);
            if (isVm) {
                vm.active = idx;
            }
        }

        //列表页功能
        vm.tableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()
                }, params.filter());
                return Work.list({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });


        //新建
        function create() {
            Work.getWorkNum({}, {}, function (resp) {
            }).$promise.then(function (data) {
                var entity = {
                    isCreate: true,
                    heading: '新建',
                    contentId: "edit.html",
                    data: {
                        workNum: data.content,
                        extWorkNum: null,
                        type: 10,
                        status: 0,
                    },
                    active: true
                };
                entity.materialTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = data.content.workNum;
                        return Work.materialList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                 entity.productTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = data.content.workNum;
                        return Work.productList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.materialPickTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = data.content.workNum;
                        return Work.materialPickList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.productWorkTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = data.content.workNum;
                        return Work.productWorkList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.upTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = data.content.workNum;
                        return Work.putAwayList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.logTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = data.content.workNum;
                        return Work.logList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        }

        //编辑
        function edit(row) {
            console.log(row);
            var entity = {
                isCreate: false,
                heading: '编辑【' + row.workNum + '】',
                contentId: "edit.html",
                materialTableParams: new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = row.workNum;
                        return Work.materialList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                productTableParams : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    }, params.filter());
                    postParams.workNum = row.workNum;
                    return Work.productList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
            }),
                productWorkTableParams : new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = row.workNum;
                        return Work.productWorkList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                materialPickTableParams : new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = row.workNum;
                        return Work.materialPickList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                upTableParams : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    }, params.filter());
                    postParams.workNum = data.content.workNum;
                    return Work.putAwayList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
                }),
                logTableParams: new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.workNum = row.workNum;
                        return Work.logList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                tabs: [],
                activeCount: 0,
                data: row,
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }


        //保存
        function save(t, index, form) {
            if (!t.data.ownerCode) {
                toastr.info("请先选择货主", "提示");
                return;
            }
            if (!t.data.workLoc) {
                toastr.info("请先选择加工库位", "提示");
                return;
            }
            if (!t.data.type) {
                toastr.info("请先选择加工类型", "提示");
                return;
            }
            if (!t.data.workMethod) {
                toastr.info("请先选择加工方法", "提示");
                return;
            }
            Work.saveAndUpdate({}, t.data, function (resp) {
            }).$promise.then(function (data) {
                t.isCreate ? toastr.success("新增成功", "成功") : toastr.success("保存成功", "成功");
                t.isCreate = false;
                t.data = data.content;
                vm.tableParams.reload();
            });
        }

        vm.cancel = function (t) {
            var list = [];
            if(t){
                list.push(t.data.id);
            }else {
                var selected = vm.tableParams.getSelected();
                if(selected.length==0){
                    toastr.error("请先选择转移单！","");
                    return
                }
                for (var i = 0; i < selected.length; i++) {
                    list.push(selected[i].id);
                }
            }
            Work.cancel({}, list, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("取消成功!", "成功");
                if(t){
                    t.data=data.content;
                }
                vm.tableParams.reload();
            });
        }

        vm.close = function (t) {
            var list = [];
            if(t){
                list.push(t.data.id);
            }else {
                var selected = vm.tableParams.getSelected();
                if(selected.length==0){
                    toastr.error("请先选择转移单！","");
                    return
                }
                for (var i = 0; i < selected.length; i++) {
                    list.push(selected[i].id);
                }
            }
            Work.close({}, list, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("关闭成功!", "成功");
                if(t){
                    t.data=data.content;
                }
                vm.tableParams.reload();
            });
        }

        vm.delMaterials = function (t,row) {
            var vo = {
                id : t.data.id,
                ids : []
            }
            if(row){
                vo.ids.push(row.id);
            }else {
                var list = t.materialTableParams.getSelected();
                if (list.length == 0) {
                    toastr.error("请先选择原料", "");
                    return;
                }
                for (var i = 0; i < list.length; i++) {
                    vo.ids.push(list[i].id);
                }
            }
            Work.delMaterials({}, vo, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("删除成功", "成功");
                t.materialTableParams.reload();
            });
        }

        vm.delProducts = function (t,row) {
            var vo = {
                id : t.data.id,
                ids : []
            }
            if(row){
                vo.ids.push(row.id);
            }else {
                var list = t.productTableParams.getSelected();
                if (list.length == 0) {
                    toastr.error("请先选择成品", "");
                    return;
                }
                for (var i = 0; i < list.length; i++) {
                    vo.ids.push(list[i].id);
                }
            }
            Work.delProducts({}, vo, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("删除成功", "成功");
                t.productTableParams.reload();
            });
        }

        vm.allocate = function (t) {
            Work.allocate({}, t.data.id, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("分配中请稍候", "");
                t.data=data.content;
                vm.searchAll(t);

            });
        }

        vm.executeWork = function (t) {
            Work.executeWork({}, t.data.id, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("转移成功", "成功");
                t.data=data.content;
                vm.searchAll(t);
            });
        }

        vm.editOrAddMaterial = function (t, row) {
            var d = {};
            var isEdit = false;
            var status = 1;
            var scValueField = ['ownerCode','skuCode','lot','locCode','lpn','caseNum'];
            if(row){
                d = angular.copy(row);
                isEdit = true;
                if (d.byBomConfig==0){
                    d.byBomConfig=false
                }else {
                    d.byBomConfig=true
                }
                console.log(d.byBomConfig)
                var scValue = {
                    ownerCode:t.data.ownerCode,
                    skuCode:d.skuCode,
                    lot:d.originalLot,
                    locCode:d.locCode,
                    lpn:d.lpn,
                    caseNum:d.caseNum
                };
            }else{
                d = {
                    workNum:t.data.workNum,
                    byBomConfig : false,
                }
            }
            Work.getMaterialLineNum({}, {}, function (resp) {
                d.lineNum = resp.content;
            });
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addMaterial.html',
                controller: scConfirmModal,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item: {
                        scValue: scValue,
                        scValueField: scValueField,
                        heading: '添加原料',
                        isEdit: isEdit,
                        storageObj: null,
                        row: t,
                        data: d
                    }
                }
            });
            /*vm.modalInstance.result.then(function (item) {
             $timeout(function () {
             vm.saveAndUpdateDetail(item);
             }, 0);
             });*/
        }

        scConfirmModal.$inject = ['$uibModalInstance', 'item'];
        function scConfirmModal($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.lotAttrObj = {};
            $ctrl.BOMs = null;
            if($ctrl.item.storageObj!=null){

                Work.bomList({}, {
                    ownerCode: $ctrl.item.storageObj.ownerCode,
                    skuCode: $ctrl.item.storageObj.code
                }, function (resp) {
                    $ctrl.BOMs = resp.content;
                });
            }
            //选中行
            $ctrl.getMoveList = function getMoveList(row){
                row.selected = !row.selected;
            }
            //获取已选中的对象
            $ctrl.getSelectedObj = function getSelectedObj() {
                var data = $ctrl.BOMs?$ctrl.BOMs:[];
                var temp = [];
                for(var i=0;i<data.length;i++){
                    if(data[i]['selected']){
                        temp.push(data[i]);
                    }
                }
                return temp;
            }
            $ctrl.eventSkuChangeHandler = function () {
                if(!$ctrl.item.data.id){$ctrl.notFirst=true};
                if($ctrl.item.storageObj!=null){

                    Work.bomList({}, {
                        ownerCode: $ctrl.item.storageObj.ownerCode,
                        skuCode: $ctrl.item.storageObj.code
                    }, function (resp) {
                        $ctrl.BOMs = resp.content;
                    });
                }

                Work.getSkuRuleInfo({}, {
                    ownerCode: $ctrl.item.storageObj.ownerCode,
                    skuCode: $ctrl.item.storageObj.code
                }, function (resp) {
                    var tData = resp.content;
                    if ($ctrl.notFirst) {
                        $ctrl.item.data.pack = tData.defaultPackCode;
                        var lotRef = {
                            tenantId: $ctrl.item.storageObj.tenantId,
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code,
                            warehouseCode: tData.warehouseCode,
                            lotRule: tData.lotattributeRule,
                            bizType: "SHIP",
                            needDefault:false,
                            needRequire:false
                            	
                        };
                        $ctrl.lotAttrObj = angular.copy(lotRef);
                    } else {
                        var lotRef = {
                            tenantId: $ctrl.item.storageObj.tenantId,
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code,
                            warehouseCode: tData.warehouseCode,
                            lotRule: tData.lotattributeRule,
                            bizType: "SHIP",
                            needDefault:false,
                            needRequire:false
                        };
                        if ($ctrl.item.data.lotAttribute01) {
                            lotRef.lotAttribute01 = $ctrl.item.data.lotAttribute01
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute02) {
                            lotRef.lotAttribute02 = $ctrl.item.data.lotAttribute02
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute03) {
                            lotRef.lotAttribute03 = $ctrl.item.data.lotAttribute03
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute04) {
                            lotRef.lotAttribute04 = $ctrl.item.data.lotAttribute04
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute05) {
                            lotRef.lotAttribute05 = $ctrl.item.data.lotAttribute05
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute06) {
                            lotRef.lotAttribute06 = $ctrl.item.data.lotAttribute06
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute07) {
                            lotRef.lotAttribute07 = $ctrl.item.data.lotAttribute07
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute08) {
                            lotRef.lotAttribute08 = $ctrl.item.data.lotAttribute08
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute09) {
                            lotRef.lotAttribute09 = $ctrl.item.data.lotAttribute09
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute10) {
                            lotRef.lotAttribute10 = $ctrl.item.data.lotAttribute10
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute11) {
                            lotRef.lotAttribute11 = $ctrl.item.data.lotAttribute11
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute12) {
                            lotRef.lotAttribute12 = $ctrl.item.data.lotAttribute12
                        }
                        ;
                        $ctrl.lotAttrObj = angular.copy(lotRef);
                        $ctrl.notFirst = true;
                    }
                });
            }

            function ok() {
                    var storageObj = $ctrl.item.storageObj;
                    var data = $ctrl.item.data;
                    if(storageObj==null){
                        toastr.error("请选择商品","");
                        return;
                    }
                    if(data.pack==null){
                        toastr.error("请选择商品包装","");
                        return;
                    }
                    if(data.planQty==null){
                        toastr.error("请填写计划数量！","");
                        return;
                    }
                    if(!(data.planQty%1 === 0)){
                        toastr.error("计划数量必须为正整数", "提示");
                        return;
                    }

                    data.skuCode = storageObj.code;
                    data.skuName = storageObj.name;

                    if($ctrl.lotAttrObj){
                        if($ctrl.lotAttrObj.lotAttribute01){data.lotAttribute01=$ctrl.lotAttrObj.lotAttribute01};
                        if($ctrl.lotAttrObj.lotAttribute02){data.lotAttribute02=$ctrl.lotAttrObj.lotAttribute02};
                        if($ctrl.lotAttrObj.lotAttribute03){data.lotAttribute03=$ctrl.lotAttrObj.lotAttribute03};
                        if($ctrl.lotAttrObj.lotAttribute04){data.lotAttribute04=$ctrl.lotAttrObj.lotAttribute04};
                        if($ctrl.lotAttrObj.lotAttribute05){data.lotAttribute05=$ctrl.lotAttrObj.lotAttribute05};
                        if($ctrl.lotAttrObj.lotAttribute06){data.lotAttribute06=$ctrl.lotAttrObj.lotAttribute06};
                        if($ctrl.lotAttrObj.lotAttribute07){data.lotAttribute07=$ctrl.lotAttrObj.lotAttribute07};
                        if($ctrl.lotAttrObj.lotAttribute08){data.lotAttribute08=$ctrl.lotAttrObj.lotAttribute08};
                        if($ctrl.lotAttrObj.lotAttribute09){data.lotAttribute09=$ctrl.lotAttrObj.lotAttribute09};
                        if($ctrl.lotAttrObj.lotAttribute10){data.lotAttribute10=$ctrl.lotAttrObj.lotAttribute10};
                        if($ctrl.lotAttrObj.lotAttribute11){data.lotAttribute11=$ctrl.lotAttrObj.lotAttribute11};
                        if($ctrl.lotAttrObj.lotAttribute12){data.lotAttribute12=$ctrl.lotAttrObj.lotAttribute12};
                    }
                    var saveVO = {
                        workOrderMaterialBean : data,
                        bomCompDTOList : null
                    }
                    if(data.byBomConfig){
                        saveVO.workOrderMaterialBean.byBomConfig=1;
                        saveVO.bomCompDTOList =  $ctrl.getSelectedObj();
                    }else {
                        saveVO.workOrderMaterialBean.byBomConfig = 0
                    }
                    Work.saveAndUpdateMaterial({},saveVO,function(resp){
                    }).$promise.then(function(data){
                        toastr.success("保存成功","");
                        $ctrl.item.row.materialTableParams.reload();
                        $uibModalInstance.close(item);
                    });


            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.editOrAddProduct = function (t, row) {
            var d = {};
            var isEdit = false;
            var status = 1;
            var scValueField = ['ownerCode','skuCode','lot','locCode','lpn','caseNum'];
            if(row){
                d = angular.copy(row);
                isEdit = true;
                if (d.byBomConfig==0){
                    d.byBomConfig=false
                }else {
                    d.byBomConfig=true
                }
                console.log(d.byBomConfig)
                var scValue = {
                    ownerCode:t.data.ownerCode,
                    skuCode:d.skuCode,
                    lot:d.originalLot,
                    locCode:d.locCode,
                    lpn:d.lpn,
                    caseNum:d.caseNum
                };
            }else{
                d = {
                    workNum:t.data.workNum,
                    byBomConfig : false,
                }
            }
            Work.getProductLineNum({}, {}, function (resp) {
                d.lineNum = resp.content;
            });
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addProduct.html',
                controller: scConfirmModal1,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item: {
                        scValue: scValue,
                        scValueField: scValueField,
                        heading: '添加成品',
                        isEdit: isEdit,
                        storageObj: null,
                        row: t,
                        data: d
                    }
                }
            });
            /*vm.modalInstance.result.then(function (item) {
             $timeout(function () {
             vm.saveAndUpdateDetail(item);
             }, 0);
             });*/
        }

        scConfirmModal1.$inject = ['$uibModalInstance', 'item'];
        function scConfirmModal1($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.lotAttrObj = {};
            $ctrl.BOMs = null;
            if($ctrl.item.storageObj!=null){

                Work.bomList({}, {
                    ownerCode: $ctrl.item.storageObj.ownerCode,
                    skuCode: $ctrl.item.storageObj.code
                }, function (resp) {
                    $ctrl.BOMs = resp.content;
                });
            }
            //选中行
            $ctrl.getMoveList = function getMoveList(row){
                row.selected = !row.selected;
            }
            //获取已选中的对象
            $ctrl.getSelectedObj = function getSelectedObj() {
                var data = $ctrl.BOMs?$ctrl.BOMs:[];
                var temp = [];
                for(var i=0;i<data.length;i++){
                    if(data[i]['selected']){
                        temp.push(data[i]);
                    }
                }
                return temp;
            }
            $ctrl.eventSkuChangeHandler = function () {
                if(!$ctrl.item.data.id){$ctrl.notFirst=true};
                if($ctrl.item.storageObj!=null){

                    Work.bomList({}, {
                        ownerCode: $ctrl.item.storageObj.ownerCode,
                        skuCode: $ctrl.item.storageObj.code
                    }, function (resp) {
                        $ctrl.BOMs = resp.content;
                    });
                }

                Work.getSkuRuleInfo({}, {
                    ownerCode: $ctrl.item.storageObj.ownerCode,
                    skuCode: $ctrl.item.storageObj.code
                }, function (resp) {
                    var tData = resp.content;
                    if ($ctrl.notFirst) {
                        $ctrl.item.data.pack = tData.defaultPackCode;
                        var lotRef = {
                            tenantId: $ctrl.item.storageObj.tenantId,
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code,
                            warehouseCode: tData.warehouseCode,
                            lotRule: tData.lotattributeRule,
                            bizType: "RECEIPT",
                            needDefault:false,
                            needRequire:false
                        };
                        $ctrl.lotAttrObj = angular.copy(lotRef);
                    } else {
                        var lotRef = {
                            tenantId: $ctrl.item.storageObj.tenantId,
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code,
                            warehouseCode: tData.warehouseCode,
                            lotRule: tData.lotattributeRule,
                            bizType: "RECEIPT",
                            needDefault:false,
                            needRequire:false
                        };
                        if ($ctrl.item.data.lotAttribute01) {
                            lotRef.lotAttribute01 = $ctrl.item.data.lotAttribute01
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute02) {
                            lotRef.lotAttribute02 = $ctrl.item.data.lotAttribute02
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute03) {
                            lotRef.lotAttribute03 = $ctrl.item.data.lotAttribute03
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute04) {
                            lotRef.lotAttribute04 = $ctrl.item.data.lotAttribute04
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute05) {
                            lotRef.lotAttribute05 = $ctrl.item.data.lotAttribute05
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute06) {
                            lotRef.lotAttribute06 = $ctrl.item.data.lotAttribute06
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute07) {
                            lotRef.lotAttribute07 = $ctrl.item.data.lotAttribute07
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute08) {
                            lotRef.lotAttribute08 = $ctrl.item.data.lotAttribute08
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute09) {
                            lotRef.lotAttribute09 = $ctrl.item.data.lotAttribute09
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute10) {
                            lotRef.lotAttribute10 = $ctrl.item.data.lotAttribute10
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute11) {
                            lotRef.lotAttribute11 = $ctrl.item.data.lotAttribute11
                        }
                        ;
                        if ($ctrl.item.data.lotAttribute12) {
                            lotRef.lotAttribute12 = $ctrl.item.data.lotAttribute12
                        }
                        ;
                        $ctrl.lotAttrObj = angular.copy(lotRef);
                        $ctrl.notFirst = true;
                    }
                });
            }

            function ok() {
                var storageObj = $ctrl.item.storageObj;
                var data = $ctrl.item.data;
                if(storageObj==null){
                    toastr.error("请选择商品","");
                    return;
                }
                if(data.pack==null){
                    toastr.error("请选择商品包装","");
                    return;
                }
                if(data.planQty==null){
                    toastr.error("请填写计划数量！","");
                    return;
                }
                if(!(data.planQty%1 === 0)){
                    toastr.error("计划数量必须为正整数", "提示");
                    return;
                }

                data.skuCode = storageObj.code;
                data.skuName = storageObj.name;

                if($ctrl.lotAttrObj){
                    if($ctrl.lotAttrObj.lotAttribute01){data.lotAttribute01=$ctrl.lotAttrObj.lotAttribute01};
                    if($ctrl.lotAttrObj.lotAttribute02){data.lotAttribute02=$ctrl.lotAttrObj.lotAttribute02};
                    if($ctrl.lotAttrObj.lotAttribute03){data.lotAttribute03=$ctrl.lotAttrObj.lotAttribute03};
                    if($ctrl.lotAttrObj.lotAttribute04){data.lotAttribute04=$ctrl.lotAttrObj.lotAttribute04};
                    if($ctrl.lotAttrObj.lotAttribute05){data.lotAttribute05=$ctrl.lotAttrObj.lotAttribute05};
                    if($ctrl.lotAttrObj.lotAttribute06){data.lotAttribute06=$ctrl.lotAttrObj.lotAttribute06};
                    if($ctrl.lotAttrObj.lotAttribute07){data.lotAttribute07=$ctrl.lotAttrObj.lotAttribute07};
                    if($ctrl.lotAttrObj.lotAttribute08){data.lotAttribute08=$ctrl.lotAttrObj.lotAttribute08};
                    if($ctrl.lotAttrObj.lotAttribute09){data.lotAttribute09=$ctrl.lotAttrObj.lotAttribute09};
                    if($ctrl.lotAttrObj.lotAttribute10){data.lotAttribute10=$ctrl.lotAttrObj.lotAttribute10};
                    if($ctrl.lotAttrObj.lotAttribute11){data.lotAttribute11=$ctrl.lotAttrObj.lotAttribute11};
                    if($ctrl.lotAttrObj.lotAttribute12){data.lotAttribute12=$ctrl.lotAttrObj.lotAttribute12};
                }
                var saveVO = {
                    workProductBean : data,
                    bomCompDTOList : null
                }
                if(data.byBomConfig){
                    saveVO.workProductBean.byBomConfig=1;
                    saveVO.bomCompDTOList =  $ctrl.getSelectedObj();
                }else {
                    saveVO.workProductBean.byBomConfig = 0
                }
                Work.saveAndUpdateProduct({},saveVO,function(resp){
                }).$promise.then(function(data){
                    toastr.success("保存成功","");
                    $ctrl.item.row.productTableParams.reload();
                    $uibModalInstance.close(item);
                });


            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.plan = function(t){

        vm.modalInstance = $uibModal.open({
            templateUrl: 'plan.html',
            controller: scConfirmModal2,
            controllerAs: '$ctrl',
            backdrop: 'static',
            resolve: {
                item:{
                    heading:'计划',
                    data:t
                }
            }
        });
    };
    scConfirmModal2.$inject = ['$uibModalInstance', 'item'];
    function scConfirmModal2($uibModalInstance,item) {
        var $ctrl = this;
        $ctrl.item = item;
        $ctrl.ok = ok;
        $ctrl.planTime = null;
        $ctrl.cancel = cancel;
        function ok() {
            if($ctrl.planTime == null){
                toastr.error("请选择计划时间！","")
                return;
            }
            var list = [];
            var t = $ctrl.item ;
            if(t.data){
                list.push(t.data.data.id);
            }else {
                var selected = vm.tableParams.getSelected();
                if(selected.length==0){
                    toastr.error("请先选择加工单！","");
                    return
                }
                for (var i = 0; i < selected.length; i++) {
                    list.push(selected[i].id);
                }
            }
            var vo = {
                ids : list,
                planHandleDt : $ctrl.planTime
            }
            Work.plan({}, vo, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("计划成功!", "成功");
                if(t.data){
                    t.data.data.status=5;
                }
                vm.tableParams.reload();
            });
            $uibModalInstance.close(item);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }


    }
})();
