(function() {
    'use strict';

    angular.module('iprintModule')
        .controller('PrintTaskController', PrintTaskController);

    PrintTaskController.$inject = ['PrintTask', 'NgTableParams','$uibModal', 'toastr'];

    function PrintTaskController(PrintTask, NgTableParams, $uibModal, toastr) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = [{'id':null, 'title':'全部'},{'id':0, 'title':'新建'},{'id':5, 'title':'补打中'},{'id':10, 'title':'已提交 '},{'id':20, 'title':'已打印'},{'id':-10, 'title':'打印故障'}];
        vm.statusMap = {'0':'新建','5':'补打中','10':'已提交 ','20':'已打印','-10':'打印故障'};
        vm.search = search;
        vm.reprint = reprint;
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return PrintTask.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }
        
        function reprint(row) {
        	PrintTask.reprint({},{tenantId: row.tenantId, id:row.id},function(resp){
        		if(resp.status == 'SUCCESS') {
        			toastr.success("打印任务已提交成功！","打印提示");
        			vm.search();
        		}
        	});
        }
    }
})();
