(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusRunLog', iBusRunLog);

    iBusRunLog.$inject = ['$resource', 'iServiceConfig'];

    function iBusRunLog ($resource, iServiceConfig) {
        var service = $resource('web/runlog', {}, {
            'search': { url: iServiceConfig.ibusHost + "/web/runlog/list", method: 'POST'}
        });

        return service;
    }
})();
