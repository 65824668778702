(function() {
    'use strict';

    angular.module('iprintModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('printtask', {
                parent: 'main',
                url: 'print/printtask',
                data: {
                    'pageTitle': '打印任务'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'print-module/printtask/printtask.html',
                        controller: 'PrintTaskController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
