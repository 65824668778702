(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('CheckSerial', CheckSerial);

    CheckSerial.$inject = ['$resource', 'iServiceConfig'];

    function CheckSerial ($resource, iServiceConfig) {
        var service = $resource('web/shipSku', {}, {
            'checkSerialNum': { url: iServiceConfig.iwhShipHost + "/web/shipSku/checkSerialNum", method: 'POST'}
        });
        return service;
    }
})();
