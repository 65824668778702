(function() {
    'use strict';

    angular.module('ireportModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('rpt-processnode', {
                parent: 'main',
                url: 'report/processnode',
                data: {
                    'pageTitle': '报表执行节点'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'report-module/node/node.html',
                        controller: 'RptNodeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
