(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("ReceiptPutaway", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhReceiptHost + "/web/putaway", {}, {
            'getPutawayOrderList': { url: iServiceConfig.iwhReceiptHost + "/web/putaway/getPutawayOrderList", method: 'POST'},
            'getPutawayDetailList' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/getPutawayDetailList", method: 'POST'},
            'deletePutawayDetail' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/deletePutawayDetail", method: 'POST'},
            'reGeneratePutaway' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/reGeneratePutaway", method: 'POST'},
            'canclePutaway' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/canclePutaway", method: 'POST'},
            'canclePutawayBatch' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/canclePutawayBatch", method: 'POST'},
            'canclePutawayDetail' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/canclePutawayDetail", method: 'POST'},
            'grantPutway' : { url: iServiceConfig.iwhReceiptHost + "/web/putaway/grantPutway", method: 'POST'}
        });
    }]);
})();