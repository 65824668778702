(function() {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('SupplySettlement', SupplySettlement);

    SupplySettlement.$inject = ['$resource', 'iServiceConfig'];

    function SupplySettlement ($resource, iServiceConfig) {
        var service = $resource('/web/supplySettle', {}, {
            'page': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/page", method: 'POST'},
            'detail': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/detail", method: 'POST'},
            'settle': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/settle", method: 'POST'},
            'adjust': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/adjust", method: 'POST'},
            'gen': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/gen", method: 'POST'},
            'preGen': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/preGen", method: 'POST'},
            'cancel': { url: iServiceConfig.ienterpriseHost + "/web/supplySettle/cancel", method: 'POST'},
        });
        return service;
    }
})();
