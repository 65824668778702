(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('WholePickController', WholePickController);

    WholePickController.$inject = ['$rootScope', '$timeout', 'NgTableParams', 'PickerOrderBiz', 'toastr', '$filter'];

    function WholePickController ($rootScope, $timeout, NgTableParams, PickerOrderBiz, toastr, $filter) {
        var vm = this;

        vm.confirmStatus = {status: 'SUCCESS', message: '成功'}; // SUCCESS|ERROR
        vm.params = {};
        vm.pickOrder = {};
        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'},
            off: {'background-color': '#868e96', 'color': '#fff'}
        };

        vm.pickWhole = pickWhole;  // 整单拣货
        vm.getPickOrder = getPickOrder;  // 获取拣货单
        vm.inputEmployeeCode = inputEmployeeCode; //输入员工号
        vm.styleFn = styleFn; //明细样式
        vm.success = success;
        vm.fail = fail;

        function inputEmployeeCode($event, pickerCode) {
            if (pickerCode && $event.key === 'Enter') {
                jQuery('#pickNum').select();
            }
        }

        function pickWhole(pickOrder, pickerCode) {
           if (pickOrder) {
        	   success();
               PickerOrderBiz.wholePick({pickNum:pickOrder.pickNum, pickerCode: pickerCode}, {}, function(data) {
                   toastr.success('拣选确认成功');
                   vm.params = {};
                   vm.pickOrder = {};
                   jQuery('#pickerCode').select();
               }, function (resp) {
                   if (resp.data && resp.data.errorMsg) {
                       fail(resp.data.errorMsg);
                   }
               });
           } else {
               fail('参数不全');
           }
        }

        function getPickOrder($event, pickNum) {
            $event.preventDefault();
            vm.pickOrder = {};
            if(pickNum && $event.key === 'Enter') {
            	success();
                PickerOrderBiz.getByPickNum({pickNum: pickNum}, {}, function(data) {
                    if (data.content) {
                        data.content.pickDetails = $filter('orderBy')(data.content.pickDetails, function(data) {
                            return {
                                'boxNum': data.boxNum,
                                'skuCode': data.skuCode,
                                'qty': data.pickQty
                            };
                        }, false, function(obj1, obj2) {
                            var boxNum1 = obj1.value.boxNum;
                            var skuCode1 = obj1.value.skuCode;
                            var qty1 = obj1.value.qty;
                            var boxNum2 = obj2.value.boxNum;
                            var skuCode2 = obj2.value.skuCode;
                            var qty2 = obj2.value.qty;

                            if (qty1 === 0 || qty2 === 0) {
                                if (qty1 === 0) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            }

                            if (boxNum1 !== boxNum2) {
                                return boxNum1 > boxNum2 ? 1: -1;
                            }

                            if (skuCode1 !== skuCode2) {
                                return skuCode1 > skuCode2 ? 1: -1;
                            }

                            if (obj1.index < obj2.index) {
                                return -1;
                            } else {
                                return 1;
                            }
                        });

                        angular.forEach(data.content.pickDetails, function(item) {
                            if (item.pickQty > 0) {
                                item.picked = true;
                            }
                        });

                        vm.pickOrder = data.content;
                        success();

                    } else {
                        fail('不存在拣选单'+pickNum);
                        jQuery('#pickNum').select();
                    }
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        fail(resp.data.errorMsg);
                        jQuery('#pickNum').select();
                    }
                });
            }
        }

        function styleFn(item) {
            if (item.picked) {
                return vm.alertColor.done;
            }

            return {};
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: msg};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }
        
        vm.success();
    }
})();
