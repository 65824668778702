(function () {
    'use strict';

    angular.module('imessageModule')
        .controller('iMessageMessageLogController',
            ['iMessageMessageLog', 'NgTableParams', '$timeout', 'toastr', iMessageMessageLogController]);

    function iMessageMessageLogController(iMessageMessageLog, NgTableParams, $timeout, toastr) {
        var vm = this;
        vm.active = 0;

        vm.refresh = function () {
            vm.getTableList.reload().then(function () {
                toastr.success("刷新成功");
            }).catch(function () {
                toastr.error("刷新失败");
            });
        };

        vm.getTableList = new NgTableParams({count: 10}, {
            getData: function (params) {
                var data = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    },
                    params.filter()
                );

                return iMessageMessageLog.getMessageLogList(data,
                    {},
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
    }
})();

