(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptOrderController', iEptOrderController);

        iEptOrderController.$inject = ['iEptOrder', 'InventoryQuery', 'NgTableParams', '$timeout', 'toastr', 'enums', '$uibModal'];

    function iEptOrderController(iEptOrder, InventoryQuery, NgTableParams, $timeout, toastr, enums, $uibModal) {
        //-----------------------------------------------------------------------//
        //----------------FIELD---------------------------FIELD------------------//
        //-----------------------------------------------------------------------//
        var vm = this;
        vm.modalInstance = null;
        vm.tabs = [];
        vm.search = search;
        vm.orderStatus = enums("enterprise.OrderStatus").list("title", "id");
        vm.orderStatusEnum = enums("enterprise.OrderStatus");

        vm.orderTypeList = [
            {id: 100, title: "销售出库"},
            {id: 101, title: "销售B2B出库"},
            {id: 102, title: "销售B2C出库"},
            {id: 110, title: "租用出库"},
            {id: 130, title: "退货出库"},
            {id: 140, title: "报废出库"},
            {id: 500, title: "生产领料"},
            {id: 400, title: "采购退货"},
            {id: 300, title: "调拨出库"},
            {id: 200, title: "其他出库"},
            {id: 120, title: "领用出库"}
        ];
        vm.orderTypeMap = {100:"销售出库",
                            101:"销售B2B出库",
                            102:"销售B2C出库",
                            110:"租用出库",
                            130:"退货出库",
                            140:"报废出库",
                            500:"生产领料",
                            400:"采购退货",
                            300:"调拨出库",
                            200:"其他出库",
                            120:"领用出库"}

        vm.yesNoList = [
            {title:"是", id: 1}, {title:"否", id: 0}
        ];
        vm.yesNoMap = {1:"是", 0:"否"}

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iEptOrder.list({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.warehouses = [];
        iEptOrder.getWarehouseList({},{}, function(resp) {
            vm.warehouses = resp.content.warehouses;
        });
        vm.removeTab = removeTab;
        vm.refreshTable = refreshTable;
        vm.confirmSelected = confirmSelected;
        vm.confirmOrder = confirmOrder;

        vm.create = create;
        vm.deleteDetailSelected = deleteDetailSelected;
        vm.createDetail = createDetail;
        vm.saveOrder = saveOrder;
        vm.cancelSelected = cancelSelected;
        vm.createEditPage = createEditPage;
        vm.createEditDetailPage = createEditDetailPage;

        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function refreshTable(table) {
            table.reload();
        }

        function search() {
            vm.tableParams.reload();
        }
        vm.allocateSelected = function() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要分配的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("CONFIRMED") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【已确认】才能被分配", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptOrder.allocateBatch({}, ids, function (resp) {
                if(resp.status == "ERROR") {
                    toastr.error(resp.errorMsg, "错误");
                } else {
                    toastr.success("分配成功", "成功");
                }
                vm.search();
            }, function (err) {
                toastr.error("分配失败", "错误");
            });
        }
        function cancelSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要确认的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【新建】才能被取消", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptOrder.cancelBatch({}, ids, function (resp) {
                toastr.success("取消成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("取消失败", "错误");
            });
        }
        function confirmSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要确认的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【新建】才能被确认", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptOrder.confirmBatch({}, ids, function (resp) {
                toastr.success("确认成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("确认失败", "错误");
            });
        }
        function confirmOrder(entity) {
            iEptOrder.confirm({id: entity.data.id}, {}, function (resp) {
                toastr.success("确认成功", "成功");
                iEptOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("确认失败", "错误");
            });
        }
        vm.allocateOrder = function(entity) {
            iEptOrder.allocate({id: entity.data.id}, {}, function (resp) {
                if(resp.status == "ERROR") {
                    toastr.error(resp.errorMsg, "错误");
                } else {
                    toastr.success("分配成功", "成功");
                }
                iEptOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                    entity.orderDetails.reload();
                });
            }, function (err) {
                toastr.error("分配失败", "错误");
            });
        }
        vm.cancelOrder = function(entity) {
            iEptOrder.cancel({id: entity.data.id}, {}, function (resp) {
                toastr.success("取消订单成功", "成功");
                iEptOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("取消订单失败", "错误");
            });
        }
        function deleteDetailSelected(entity) {
            if (vm.orderStatusEnum.value("NEW") != entity.data.status) {
                toastr.warning("订单状态为新建才能删除明细", "警告");
                return;
            }
            var paramBeans = entity.orderDetails.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                ids.push(paramBeans[i].id);
            }
            iEptOrder.deleteDetailBatch({}, ids, function (resp) {
                toastr.success("成功删除明细", "提示");
                entity.orderDetails.reload();
                iEptOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("删除失败", "错误");
            });
        }
        function saveOrder(entity, tabIndex) {
            if (validateOrder(entity)) {
                if (entity.data.warehouseObj) {
                    entity.data.warehouseCode = entity.data.warehouseObj.code;
                }
                
                iEptOrder.saveHeader({}, entity.data, function (response) {
                    toastr.success((!!entity.data.id ? "更新" : "创建") + "成功");
                    vm.removeTab(tabIndex);
                    vm.createEditPage(response.content);
                }, function (error) {
                    toastr.error("保存失败", "错误");
                });
            }
        }

        function validateOrder(entity) {
            if (!!!entity.data.ownerCode) {
                toastr.info("信息未填完，请选择货主", "提示");
                return false;
            }
            if (!!!entity.data.type) {
                toastr.info("信息未填完，请选择订单类型", "提示");
                return false;
            }
            if (!entity.data.customer || !entity.data.customer.code) {
                toastr.info("信息未填完，请选择客户信息", "提示");
                return false;
            }
            return true;
        }
        vm.changeOwner = function(model, t) {
            if (model) {
                t.data.ownerCode = model.code;
                t.data.ownerName = model.name;
            } else {
                t.data.ownerVo = null;
            }
        }
        vm.changeCustomer = function(model, t) {
            if (model) {
                if (!t.data.customer.id) {
                    t.data.customer.code= model.code;
                    t.data.customer.name= model.name;
                    t.data.customer.phone= model.phone;
                    t.data.customer.email= model.email;
                    t.data.customer.address= model.address;
                    t.data.customer.receiver= model.receiver;
                    t.data.customer.receiverPhone= model.receiverPhone;
                    t.data.customer.bakAddress= model.bakAddress;
                }
            } else {
                t.data.customerVo = null;
            }
        }

        vm.changeCarrier = function(model, t) {
            if (model) {
                if (!t.data.carrier.id) {
                    t.data.carrier.code= model.code;
                    t.data.carrier.name= model.name;
                    t.data.carrier.phone= model.phone;
                    t.data.carrier.address= model.address;
                    t.data.carrier.carrierContact= model.contact;
                }
            } else {
                t.data.carrierVo = null;
            }
        }
        function create() {
            var entity = {
                heading: '创建订单',
                contentId: "order-edit.html",
                data: {
                    status: 100,
                    priority:10,
                    type: 100,
                    carrier:{},
                    customer:{}
                },
                active: 0,
                newOrder: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }
        function createEditPage(row) {
            iEptOrder.getById({id: row.id}, {}, function (response) {
                var entity = {
                    heading: '订单详情',
                    contentId: "order-edit.html",
                    data: response.content,
                    orderDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {orderNum: response.content.orderNum}, params.filter());
                            return iEptOrder.listDetail({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    orderLogDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {orderNum: response.content.orderNum}, params.filter());
                            return iEptOrder.listLog({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    active: 0,
                    newOrder: false
                };
                entity.data.warehouseObj = {code: entity.data.warehouseCode}
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);

            }, function (error) {
                toastr.error("加载失败", "错误");
            });
        }

        function createEditDetailPage(entity, row) {
            iEptOrder.getDetailById({ id: row.id }, {}, function (resp) {
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'order-detail-edit.html',
                    controller: detailController,
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        item: {
                            tab: entity,
                            orderDetail: resp.content,
                            newDetail: false,
                            readOnly: entity.data.status != vm.orderStatusEnum.value("NEW"),
                            warehouseObj: {code: resp.content.warehouseCode ? resp.content.warehouseCode : entity.data.warehouseCode},
                            warehouses: vm.warehouses
                        }
                    }
                });
                vm.modalInstance.result.then(function (item) {
                    item.tab.orderDetails.reload();
                });
            });

        }

        function createDetail(entity) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'order-detail-edit.html',
                controller: detailController,
                controllerAs: '$ctrl',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    item: {
                        tab: entity,
                        orderDetail: { },
                        newDetail: true,
                        readOnly: false,
                        warehouseObj: {code: entity.data.warehouseCode},
                        warehouses: vm.warehouses,
                        skuInventory: {},
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                item.tab.orderDetails.reload();
            });
        }
        //-----------------------------------------------------------------------//
        //---------newDetailController------------newDetailController------------//
        //-----------------------------------------------------------------------//
        detailController.$inject = ['$uibModalInstance', 'item', 'iEptOrder', 'InventoryQuery', 'toastr'];

        function detailController($uibModalInstance, item, iEptOrder,InventoryQuery, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.cancel = cancel;
            $ctrl.save = save;
            $ctrl.changeSku = changeSku;

            function changeSku(model) {
                if (model) {
                    InventoryQuery.skuInventory({}, {ownerCode: $ctrl.item.tab.data.ownerCode, skuCode: model.code}, function (resp) {
                        $ctrl.item.skuInventory = resp.content;
                    });
                }
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function save(form) {
                if (!$ctrl.item.orderDetail.skuCode) {
                    toastr.info("信息未填完，请选择商品", "提示");
                    return;
                }
                if (!$ctrl.item.orderDetail.packCode) {
                    toastr.info("信息未填完，请选择包装", "提示");
                    return;
                }
                if (!$ctrl.item.orderDetail.orderQty || $ctrl.item.orderDetail.orderQty <= 0) {
                    toastr.info("信息未填完，请填写数量，数量必须大于 0 ", "提示");
                    return;
                }
                var detail = $ctrl.item.orderDetail;
                detail.skuName = $ctrl.item.skuObj.name;
                detail.uomBaseQty = 1;
                detail.uom = 'EA';
                detail.orderId = $ctrl.item.tab.data.id;
                detail.warehouseCode = $ctrl.item.warehouseObj.code;
                detail.warehouseName = $ctrl.item.warehouseObj.name;

                console.log(detail);
                iEptOrder.saveDetail({}, detail, function (response) {
                    toastr.success("保存成功", "提示");
                    $uibModalInstance.close($ctrl.item);
                    iEptOrder.getById({id: $ctrl.item.tab.data.id}, {}, function (response) {
                        $ctrl.item.tab.data = response.content;
                        $ctrl.item.tab.data.warehouseObj = {code: $ctrl.item.tab.data.warehouseCode}
                        $ctrl.item.tab.orderDetails.reload();
                    });
                }, function (error) {
                    toastr.error("保存失败", "错误");
                    return;
                })
            }
        }
    }
})();
