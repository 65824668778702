(function () {
    'use strict';

    angular.module('ivendorModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('vendor-mod-so-maintenance', {
                parent: 'main',
                url: 'vendor-wms/so-maintenance',
                params: {'sono': null},
                data: {
                    'pageTitle': '发货单维护',
                    'sono' : null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'vendor-module/so/so.html',
                        controller: 'VendorModSoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
