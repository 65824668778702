(function () {
    'use strict';

    angular.module('iportalModule')
        .controller('iPortalSystemNoticeController', iPortalSystemNoticeController);

    iPortalSystemNoticeController.$inject = ['NgTableParams', '$timeout', 'toastr', '$uibModal', '$scope', 'enums', 'iPortalSystemNotice'];

    function iPortalSystemNoticeController(NgTableParams, $timeout, toastr, $uibModal, $scope, enums, iPortalSystemNotice) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.modalInstance = null;
        vm.sysNoticeTypeEnum = enums("portal.SysNoticeType");
        vm.sysNoticeType = enums("portal.SysNoticeType").list("title", "id");
        vm.sysNoticeStatusEnum = enums("portal.SysNoticeStatus");
        vm.sysNoticeStatus = enums("portal.SysNoticeStatus").list("title", "id");
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iPortalSystemNotice.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.search = search;
        vm.removeTab = removeTab;
        vm.create = create;
        vm.releaseNotice = releaseNotice;
        vm.recallNotice = recallNotice;
        vm.noticeDetail = noticeDetail;
        vm.saveNotice = saveNotice;
        vm.createEditPage = createEditPage;
        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx, entity) {
            tabClose(entity);
            vm.tabs.splice(idx, 1);
        }

        function tabClose(entity) {
            if (!!entity.fn) {
                entity.fn(entity);
            }
        }

        function create() {
            //todo 创建系统公告
            var entity = {
                heading: '创建公告',
                contentId: "notice-edit.html",
                data: {},
                ueditorId: "ueditor" + (new Date().getTime()),
                readOnly: false,
                ue: null,
                fn: function (entity) {
                    if (!!entity.ue) {
                        entity.ue.destroy();
                    }
                },
                roles: [],
                active: 0
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                $timeout(function () {
                    entity.ue = UE.getEditor(entity.ueditorId, {
                        initialFrameHeight: 250
                    });
                }, 0);
            }, 0);
        }

        function createEditPage(row) {
            //todo 创建公告编辑页面
            var entity = {
                heading: '创建公告',
                contentId: "notice-edit.html",
                data: row,
                ueditorId: "ueditor" + (new Date().getTime()),
                ue: null,
                readOnly: false,
                fn: function (entity) {
                    if (!!entity.ue) {
                        entity.ue.destroy();
                    }
                },
                roles: [],
                active: 0
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                $timeout(function () {
                    entity.ue = UE.getEditor(entity.ueditorId, {
                        initialFrameHeight: 250
                    });
                    entity.ue.ready(function () {
                        entity.ue.execCommand('inserthtml', row.content);
                    });
                }, 0);
            }, 0);
        }

        function releaseNotice(row) {
            //todo 发布系统公告
            iPortalSystemNotice.release({id: row.id}, {}, function (resp) {
                toastr.success("发布成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("发布失败", "错误");
            });
        }

        function recallNotice(row) {
            //todo 撤销系统公告
            iPortalSystemNotice.recall({id: row.id}, {}, function (resp) {
                toastr.success("撤销成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("撤销失败", "错误");
            });
        }

        function noticeDetail(row) {
            //todo 系统公告详情
            var entity = {
                heading: '公告详情',
                contentId: "notice-edit.html",
                data: row,
                ueditorId: "ueditor" + (new Date().getTime()),
                ue: null,
                fn: function (entity) {
                    if (!!entity.ue) {
                        entity.ue.destroy();
                    }
                },
                readOnly: true,
                roles: [],
                active: 0
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                $timeout(function () {
                    entity.ue = UE.getEditor(entity.ueditorId, {
                        toolbars: [[]],
                        readonly: true,
                        enableAutoSave: false,
                        initialFrameHeight: 350,
                        enableContextMenu: false,
                        elementPathEnabled: false,
                        wordCount: false,
                        autoSyncData: false
                    });
                    entity.ue.ready(function () {
                        entity.ue.execCommand('inserthtml', row.content);
                    });
                }, 0);
            }, 0);
            iPortalSystemNotice.read({id: row.id}, {});
        }

        function validate(data) {
            if (!!!data.title || !!!data.title.trim() || !!!data.type) {
                toastr.info("信息未填完");
                return false;
            }
            return true;
        }

        function saveNotice(entity, tabIndex, isRelease) {
            //todo 保存公告
            if (validate(entity.data)) {
                entity.data.content = entity.ue.getContent();
                iPortalSystemNotice.save({isRelease: isRelease}, entity.data, function (resp) {
                    toastr.success("保存成功", "成功");
                    vm.removeTab(tabIndex, entity);
                }, function (err) {
                    toastr.error("保存失败", "错误");
                });
            }
        }

    }
})();
