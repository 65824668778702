(function () {
    'use strict';

    angular
        .module('idataModule')
        .factory('WarehouseUser', WarehouseUser);

    WarehouseUser.$inject = ['$resource', 'iServiceConfig'];

    function WarehouseUser($resource, iServiceConfig) {
        var service = $resource('web/warehouseuser', {}, {
            'getUserList': {url: iServiceConfig.idataHost + "/web/warehouseuser/getUserList", method: 'POST'},
            'addBacth': {url: iServiceConfig.idataHost + "/web/warehouseuser/addBacth", method: 'POST'},
            'getWarehouseList': {url: iServiceConfig.idataHost + "/web/warehouseuser/getWarehouseList", method: 'GET'},
            'list': {url: iServiceConfig.idataHost + "/web/warehouseuser/list", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/warehouseuser/save", method: 'POST'},
            'delete': {url: iServiceConfig.idataHost + "/web/warehouseuser/delete", method: 'POST'},
            'deleteByUser': {url: iServiceConfig.idataHost + "/web/warehouseuser/deleteByUser", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/warehouseuser/disable", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/warehouseuser/enable", method: 'POST'},
            'checkCode': {url: iServiceConfig.idataHost + "/web/warehouseuser/checkcode", method: 'POST'}
        });
        return service;
    }
})();
