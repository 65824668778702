(function () {
    'use strict';

    angular.module('idashboardModule')
        .controller('ThreeDInventoryController', ThreeDInventoryController);
    ThreeDInventoryController.$inject = ['Warehouse3D','$state','$scope'];
    function ThreeDInventoryController(Warehouse3D,$state,$scope) {
        var vm = this;
        var requestTimer;
        var camera, scene, raycaster, renderer;
        var mouse = new THREE.Vector2(), INTERSECTED;
        var isIn=false;
        var infoObject={};
        var controls;
        var mesh, curveSegments = 5, bevelThickness = 1, bevelSize = 1.5;
        //字体部分变量
        var materials,group,textGeo,bevelEnabled = true,textMesh1,font = undefined;
        var size=30,height=5;
        var sphericalDelta = new THREE.Spherical();         //用于手动旋转
        var loader = new THREE.FontLoader();


        vm.info='';
        vm.loadDone=false;          //是否加载完成，默认为false

        Warehouse3D.getWarehouseList({},{},
            function (res) {
                //字体部分
                if ( ! Detector.webgl ) Detector.addGetWebGLMessage();
                THREE.Cache.enabled = true;
                vm.loadDone=true;
                console.log(res,'data')
                init(res);
            },
            function (res) {
                console.log(res,'错误')
            }
        );
        function init(res) {

            vm.search=search;
            vm.reset=reset;

            vm.inputClick=function (e) {
                if(e.keyCode===13){
                    search();
                }
            };
            //查询方法
            function search() {
                if(!vm.info){
                    //表示没搜索到
                    alert('没有对应仓库，请重新输入!');
                    vm.info='';
                    return;
                }
                vm.searchResult=[];
                vm.boxData.forEach(function (v) {
                    if(v.code.indexOf(vm.info)!==-1 || v.name.indexOf(vm.info)!==-1){
                        v.color='#0000FF';
                        vm.searchResult.push(v);
                        v.hideInner=false;
                    }else{
                        v.color='';
                        v.hideInner=true;
                    }
                });
                if(vm.searchResult.length===0){
                    alert('没有对应仓库，请重新输入');
                    vm.info='';
                    return;
                }
                draw();

                vm.move(vm.searchResult);
            }
            //重置搜索结果
            function reset() {
                vm.boxData.forEach(function (v) {
                    v.color='';
                    v.hideInner=false;
                });
                draw();
                vm.info='';
                controls.reset();
            }

            //数据不要name就不会触发鼠标hover变色
            vm.boxLength=Math.ceil(Math.sqrt(res.content.length));
            res.content.forEach(function (v,k) {
                v.depth=380;
                v.width=200;
                v.height=320;
                v.x=450*(k%vm.boxLength);
                v.y=0;
                v.z=-550*Math.floor(k/vm.boxLength);
                // v.fontX=v.x-helpWidth/2;
                // v.fontY=v.y+120;
                // v.fontZ=v.z+1000;
            });

            var totalDepth=0;
            var totalWidth=0;
            //计算底盘的大小
            res.content.forEach(function (v) {
                if(v.x+v.depth>totalDepth){
                    totalDepth=v.x+v.depth;
                }
                if(Math.abs(v.z)+v.width>totalWidth){
                    totalWidth=Math.abs(v.z)+v.width;
                }
            });
            //根据长度来计算底盘的大小,也要用于camera相关大小显示
            var helpWidth=totalDepth>totalWidth?totalDepth:totalWidth;

            res.content.forEach(function (v,k) {
                v.fontX=v.x-helpWidth/2;
                v.fontY=v.y+v.height;
                v.fontZ=v.z-v.width/2;
            });
            vm.boxData=res.content;
            camera = new THREE.CinematicCamera( 60, jQuery('#3DZone').width() / jQuery('#3DZone').height(), 1, 100000 );

            camera.position.x = helpWidth/2;
            camera.position.y = helpWidth*2;
            camera.position.z = helpWidth*2/3;
            camera.lookAt({
                x : 0,
                y : 0,
                z : 0
            });
            camera.updateMatrixWorld();
            // camera.position.x = (helpWidth*deep)/2;
            // camera.position.y = (helpWidth*deep)/2;
            // camera.position.z = (helpWidth*deep)/2;
            // camera.position.x = (helpWidth*deep)/2;
            // camera.position.y = (helpWidth*deep);
            // camera.position.z = (helpWidth*deep)*2/3;
            // camera.up.x = 0;
            // camera.up.y = 1;
            // camera.up.z = 0;
            // camera.lookAt({x : 1000 ,y : 0, z : 0});

            // camera.updateMatrixWorld();
            controls = new THREE.OrbitControls( camera );




            draw();

            function draw() {
                vm.boxDetail=[];       //用于存放内部方块的数组
                controls.reset();
                scene = new THREE.Scene();
                scene.background = new THREE.Color( 0x282828 );
                scene.add( new THREE.AmbientLight( 0xffffff, 0.3 ) );   //设置方块的透明度
                var light = new THREE.DirectionalLight( 0xffffff, 1);
                light.position.set( 1, 1, 1 ).normalize();
                scene.add( light );

                vm.boxData.forEach(function (v,k) {
                    mesh = new THREE.Mesh( new THREE.BoxGeometry( v.depth, v.height, v.width ) , new THREE.MeshLambertMaterial({
                        color: v.color?v.color:'#808080',
                        transparent:true,
                        opacity:0.4,
                        side: THREE.DoubleSide
                    }));
                    // mesh.position = new THREE.Vector3(0,0,0);
                    mesh.position.x=v.x+v.depth/2-helpWidth/2;
                    mesh.position.y=v.y+v.height/2;
                    mesh.position.z=v.z-v.width/2+helpWidth/2;
                    mesh.name=v.name;
                    mesh.code=v.code;
                    mesh.percent=v.percent;
                    mesh.breedCount=v.breedCount;       //品种数
                    mesh.qtyTotal=v.qtyTotal;           //总库存
                    scene.add(mesh);
                    //生成内部方块数组
                    //其中，|y|*2+per=100;如果per>80,y*2+per=100;
                    if(!v.hideInner && v.percent!==0){
                        vm.boxDetail.push(
                            {
                                x:v.x,
                                y:v.y-v.height/2,
                                // y:-(100-v.percent)/2,
                                z:v.z,
                                per:v.percent,
                                depth:v.depth,
                                height:v.height,
                                width:v.width
                            }
                        );
                    }

                    //边框
                    var edges = new THREE.EdgesHelper( mesh, 0x808080 );//设置边框，可以旋转
                    edges.position.x=v.x+v.depth/2-helpWidth/2;
                    edges.position.y=v.y+v.height/2;
                    edges.position.z=v.z-v.width/2+helpWidth/2;
                    scene.add( edges );
                });
                //生成内部方块
                vm.boxDetail.forEach(function (v,k) {
                    var per=0;
                    if(v.per<5){
                        per=5;
                    }else{
                        per=v.per;
                    }
                    mesh = new THREE.Mesh( new THREE.BoxGeometry( v.depth-3, per/100*v.height*0.9, v.width-3 ) , new THREE.MeshPhongMaterial({
                        color:getColorByBaiFenBi(per),
                        specular:getColorByBaiFenBi(per),
                        shininess:30
                    }));
                    // mesh.position = new THREE.Vector3(0,0,0);
                    mesh.position.x=v.x+v.depth/2-helpWidth/2;     //x,y,z调整位置,z-60是因为z的正向为视角的负方向
                    mesh.position.y=v.y+v.height/2+per/100*v.height*0.45;
                    mesh.position.z=v.z-v.width/2+helpWidth/2;
                    mesh.name=v.name;
                    scene.add(mesh);

                    //边框
                    var edges = new THREE.EdgesHelper( mesh, 0x228B22 );//设置边框，可以旋转
                    edges.position.x=v.x+v.depth/2-helpWidth/2;
                    edges.position.y=v.y+v.height/2+per/100*v.height*0.45;
                    edges.position.z=v.z-v.width/2+helpWidth/2;
                    scene.add( edges );
                });

                var helper = new THREE.GridHelper( helpWidth+100, 90 );
//        helper.setColors( 0x0000ff, 0x808080 ); // blue central line, gray grid
                helper.position.x = 0;
                helper.position.y = 5;
                helper.position.z = 0;
                scene.add( helper );

                var ground = new THREE.Mesh(
                    new THREE.PlaneBufferGeometry( helpWidth*1.1, helpWidth*1.1, 1, 1 ),
                    new THREE.MeshPhongMaterial( {
                        color: '#CAE1FF',
                        // shininess: 150,
                        // transparent:true,
                        // opacity:0.9,
                    } )
                );
                ground.rotation.x = - Math.PI / 2; // rotates X/Y to X/Z
                ground.receiveShadow = true;
                scene.add( ground );

                //字体group加载
                materials = [
                    new THREE.MeshPhongMaterial( { color: '#F5F5F5', flatShading: true } ),
                    new THREE.MeshPhongMaterial( { color: '#F5F5F5',flatShading: true } ) // side
                ];
                group = new THREE.Group();
                group.position.y = 0;
                scene.add( group );

                if(!font){
                    loader.load( 'http://static.56start.com/fonts/Microsoft.json', function ( response ) {
                        font = response;
                        group.remove( textMesh1 );
                        vm.boxData.forEach(function (v) {
                            createText(v,helpWidth);
                        });
                    });
                }else{
                    group.remove( textMesh1 );
                    vm.boxData.forEach(function (v) {
                        createText(v,helpWidth);
                    });
                }
            }

            /*
            *求由绿到红的渐变色值,百分比 bili 取值 1...100
            */
            function getColorByBaiFenBi(bili){
                //var 百分之一 = (单色值范围) / 50;  单颜色的变化范围只在50%之内
                var one = (255+255) / 100;
                var r=0;
                var g=0;
                var b=0;

                if ( bili < 50 ) {
                    // 比例小于50的时候红色是越来越多的,直到红色为255时(红+绿)变为黄色.
                    r = one * bili;
                    g=255;
                }
                if ( bili >= 50 ) {
                    // 比例大于50的时候绿色是越来越少的,直到0 变为纯红
                    g =  255 - ( (bili - 50 ) * one) ;
                    r = 255;
                }
                r = parseInt(r);// 取整
                g = parseInt(g);// 取整
                b = parseInt(b);// 取整

                //返回16进制颜色
                var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
                return hex;
            }

            raycaster = new THREE.Raycaster();
            renderer = new THREE.WebGLRenderer( { antialias: true } );
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( jQuery('#3DZone').width(), jQuery('#3DZone').height() );
            document.getElementById('3DZone').appendChild(renderer.domElement);
            document.addEventListener( 'mousemove', onDocumentMouseMove, false );
            document.addEventListener('mousedown',onDocumentMouseDown,false);
            document.addEventListener('dblclick',onDblClick,false);
            window.addEventListener( 'resize', onWindowResize, false );
            var effectController  = {
                focalLength: 10,
                // jsDepthCalculation: true,
                // shaderFocus: false,
                //
                fstop: 2.8,
                // maxblur: 1.0,
                //
                showFocus: false,
                focalDepth: 3,
                // manualdof: false,
                // vignetting: false,
                // depthblur: false,
                //
                // threshold: 0.5,
                // gain: 2.0,
                // bias: 0.5,
                // fringe: 0.7,
                //
                // focalLength: 35,
                // noise: true,
                // pentagon: false,
                //
                // dithering: 0.0001
            };
            var matChanger = function( ) {
                for (var e in effectController) {
                    if (e in camera.postprocessing.bokeh_uniforms)
                        camera.postprocessing.bokeh_uniforms[ e ].value = effectController[ e ];
                }
                // camera.postprocessing.bokeh_uniforms[ 'znear' ].value = camera.near;
                camera.postprocessing.bokeh_uniforms[ 'zfar' ].value = camera.far;
                // camera.setLens(effectController.focalLength, camera.frameHeight ,effectController.fstop, camera.coc);
                // effectController['focalDepth'] = camera.postprocessing.bokeh_uniforms["focalDepth"].value;
            };
            matChanger();
            window.addEventListener( 'resize', onWindowResize, false );

            //查询按钮，查询到结果之后通过这个方法移动到目标块
            vm.move = function (v)  {
                controls.reset();
                camera.position.x = 0;
                camera.position.y = helpWidth*2;
                camera.position.z = 0;
                controls.update();

                if(v.length>1){
                    //如果搜索结果大于1个，就不移动了，直接高亮
                    return;
                }else{
                    camera.position.x=v[0].x-helpWidth/2;
                    camera.position.z=v[0].z-(-helpWidth/2);
                    controls.target=new THREE.Vector3(camera.position.x,0,camera.position.z)

                    sphericalDelta.phi = 0.9;
                    controls.update('','',sphericalDelta.theta,sphericalDelta.phi);
                }
            };
            animate();
        }
        //生成字体
        function createText(v,helpwidth) {
            textGeo = new THREE.TextBufferGeometry( v.name, {
                font: font,
                size: size,
                height: height,
                curveSegments: curveSegments,
                // bevelThickness: bevelThickness,
                // bevelSize: bevelSize,
                // bevelEnabled: bevelEnabled,
                // material: 0,
                // extrudeMaterial: 1
            });
            textGeo.computeBoundingBox();
            textGeo.computeVertexNormals();
            // "fix" side normals by removing z-component of normals for side faces
            // (this doesn't work well for beveled geometry as then we lose nice curvature around z-axis)
            // if ( ! bevelEnabled ) {
            //     var triangleAreaHeuristics = 0.1 * ( height * size );
            //     for ( var i = 0; i < textGeo.faces.length; i ++ ) {
            //         var face = textGeo.faces[ i ];
            //         if ( face.materialIndex == 1 ) {
            //             for ( var j = 0; j < face.vertexNormals.length; j ++ ) {
            //                 face.vertexNormals[ j ].z = 0;
            //                 face.vertexNormals[ j ].normalize();
            //             }
            //             var va = textGeo.vertices[ face.a ];
            //             var vb = textGeo.vertices[ face.b ];
            //             var vc = textGeo.vertices[ face.c ];
            //             var s = THREE.GeometryUtils.triangleArea( va, vb, vc );
            //             if ( s > triangleAreaHeuristics ) {
            //                 for ( var j = 0; j < face.vertexNormals.length; j ++ ) {
            //                     face.vertexNormals[ j ].copy( face.normal );
            //                 }
            //             }
            //         }
            //     }
            // }
//        var centerOffset = -0.5 * ( textGeo.boundingBox.max.x - textGeo.boundingBox.min.x );
            textMesh1 = new THREE.Mesh( textGeo, materials );
            textMesh1.position.x = v.fontX;
            textMesh1.position.y = v.fontY;
            textMesh1.position.z = v.fontZ+helpwidth/2;
            //旋转角度
            textMesh1.rotation.x = Math.PI /2;
            textMesh1.rotation.y = Math.PI;
            textMesh1.rotation.z = Math.PI ;
            group.add( textMesh1 );
        }
        function onWindowResize() {
            camera.aspect = jQuery('#3DZone').width() / jQuery('#3DZone').height();
            camera.updateProjectionMatrix();
            renderer.setSize( jQuery('#3DZone').width(), jQuery('#3DZone').height() );
        }
        //单机事件
        function onDocumentMouseDown() {
            if(isIn){
                //鼠标点击事件
                // console.log(infoObject,'info')
            }
        }
        //双击事件
        function onDblClick() {
            if(isIn){
                //鼠标点击事件
                $state.go('3dZone',{
                    whCode:infoObject.code
                });
            }
        }
        function onDocumentMouseMove( event ) {
            event.preventDefault();
            if(isIn){
                $('#testDiv').css({
                    left:event.clientX-140,
                    top:event.clientY-60,
                    display:'block'
                });
            }else{
                $('#testDiv').css({
                    display:'none'
                });
            }
            mouse.x = ( (event.clientX-150) / jQuery('#3DZone').width() ) * 2 - 1;
            mouse.y = - ( (event.clientY-80) / jQuery('#3DZone').height() ) * 2 + 1;
        }
        function animate() {
            requestTimer=requestAnimationFrame( animate, renderer.domElement );
            render();
        }
        function render() {
            // find intersections
            raycaster.setFromCamera( mouse, camera );
            var intersects = raycaster.intersectObjects( scene.children );
            if ( intersects.length > 0) {
                var inBox=false;
                intersects.forEach(function (v) {
                    if(v.object.name){
                        inBox=true;
                    }
                });
                if(intersects[0].object.name && inBox){
                    isIn=true;
                    jQuery('#testDiv').html(
                        '<div>仓库名:'+intersects[0].object.name+'</div>'+
                        '<div>仓库编码:'+intersects[0].object.code+'</div>'+
                        '<div>仓库品种数:'+intersects[0].object.breedCount+'</div>'+
                        '<div>仓库总库存:'+intersects[0].object.qtyTotal+'</div>'+
                        '<div>仓库容量百分比:'+intersects[0].object.percent+'</div>'
                        // `<div>姓名:${intersects[0].object.name}</div>`
                    );
                    infoObject.name=intersects[0].object.name;
                    infoObject.code=intersects[0].object.code;
                    infoObject.percent=intersects[0].object.percent;
                    var targetDistance = intersects[ 0 ].distance;
                    //Using Cinematic camera focusAt method
                    camera.focusAt(targetDistance);
                    if ( INTERSECTED != intersects[ 0 ].object ) {
                        if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
                        INTERSECTED = intersects[ 0 ].object;
                        INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
                        INTERSECTED.material.emissive.setHex( 0xff0000 );
                    }
                }else{
                    isIn=false;
                }
            } else {
                isIn=false;
                infoObject={};
                if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
                INTERSECTED = null;
            }
            if(camera.postprocessing.enabled){
                //rendering Cinematic Camera effects
                camera.renderCinematic(scene, renderer,camera);
            }
            else {
                scene.overrideMaterial = null;
                renderer.clear();
                renderer.render( scene, camera );
            }
        }

        $scope.$on('$destroy',function () {
            cancelAnimationFrame(requestTimer);
        });
    }
})();