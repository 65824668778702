(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('BomInventory', BomInventory);

    BomInventory.$inject = ['$resource', 'iServiceConfig'];

    function BomInventory ($resource, iServiceConfig) {
        var service = $resource('web/bomInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/bomInventory/list", method: 'POST'},
            'bomCompDetail': { url: iServiceConfig.iwhInventoryHost + "/web/bomInventory/bomCompDetail", method: 'POST'},
        });
        return service;
    }
})();
