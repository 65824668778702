(function () {
    'use strict';

    angular
        .module('iportalModule')
        .factory('iPortalSystemMessage', iPortalSystemMessage);

    iPortalSystemMessage.$inject = ['$resource', 'iServiceConfig'];

    function iPortalSystemMessage($resource, iServiceConfig) {
        var service = $resource('web/systemMessage', {}, {
            'search': {url: iServiceConfig.iportalHost + "/web/systemMessage/list", method: 'POST'},
            'getUserAndRole': {url: iServiceConfig.iportalHost + "/web/systemMessage/getUserAndRole", method: 'GET'},
            'save': {url: iServiceConfig.iportalHost + "/web/systemMessage/save", method: 'POST'},
            'release': {url: iServiceConfig.iportalHost + "/web/systemMessage/release/:id", method: 'POST'}
        });
        return service;
    }
})();
