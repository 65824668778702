(function() {
    'use strict';

    angular
        .module('ireportModule')
        .factory('RptReport', RptReport);

    RptReport.$inject = ['$resource', 'iServiceConfig'];

    function RptReport ($resource, iServiceConfig) {
        var service = $resource('web/report', {}, {
            'search': { url: iServiceConfig.ireportHost + "/web/report/list", method: 'POST'},
            'changeStatus': { url: iServiceConfig.ireportHost + "/web/report/changestatus", method: 'POST'},
            'datasource': { url: iServiceConfig.ireportHost + "/web/report/datasource", method: 'GET'},
            'modules': { url: iServiceConfig.ireportHost + "/web/report/modules", method: 'GET'},
            'getReportDetail': { url: iServiceConfig.ireportHost + "/web/report/getreportdetail", method: 'POST'},
            'getReportNodeList': { url: iServiceConfig.ireportHost + "/web/report/getreportnodelist", method: 'POST'},
            'syncReportNode': { url: iServiceConfig.ireportHost + "/web/report/syncreportnode", method: 'POST'},
            'save': { url: iServiceConfig.ireportHost + "/web/report/saveo", method: 'POST'}
        });

        return service;
    }
})();
