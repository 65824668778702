(function () {
        'use strict';

        angular
            .module('iuserModule')
            .factory('UserModuleH5',UserModuleH5);

        UserModuleH5.$inject = ['$resource', 'iServiceConfig'];

        function UserModuleH5($resource, iServiceConfig){
            var service = $resource('web/h5app',{},{
                'list': {url: iServiceConfig.iuserHost + "/web/h5app/list",method:'POST'},
                'save': {url: iServiceConfig.iuserHost + "/web/h5app/save",method:'POST'},
                'enable': {url: iServiceConfig.iuserHost + "/web/h5app/enable",method:'POST'},
                'disabled': {url: iServiceConfig.iuserHost + "/web/h5app/disabled",method:'POST'},
                'listByTenantId':{url: iServiceConfig.iuserHost + "/web/h5app/listByTenantId",method:'GET'}
            });
            return service;
        }
})();