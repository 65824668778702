(function () {
    'use strict';

    angular.module('iergateModule')
        .controller('ErgateAppModuleController',
            ['ErgateAppModule', 'NgTableParams', '$timeout', 'toastr', ErgateAppModuleController]);

    function ErgateAppModuleController(ErgateAppModule, NgTableParams, $timeout, toastr) {

        var vm = this;

        vm.dbTypes = ['', 'MYSQL', 'ORACLE', 'SQLSERVER'];
        vm.useAppDBTypeOptions = [{
            id: 0,
            title: '不使用'
        }, {
            id: 1,
            title: '使用'
        }];

        vm.isSubscriberOptions = [{
            id: 0,
            title: '不订阅'
        }, {
            id: 1,
            title: '订阅'
        }];

        vm.eventId = 0;
        vm.addEvent = function (t) {
            vm.eventId = t.data.eventList ? t.data.eventList.length : 0;
            t.data.eventList.push({
                eventName: '',
                id: vm.eventId
            });
            vm.eventId++;
        };

        function removeById(arr, id) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === id) {
                    arr.splice(i, 1);
                    break;
                }
            }
        }

        vm.removeEvent = function(t, id) {
            removeById(t.data.eventList, id);
        };

        var pageTab = {
            tabs: [],
            activeTabIndex: 0,
            removeTab: function (idx) {
                this.tabs.splice(idx, 1);
            },
            addTab: function (tab) {
                var that = this;
                var tabs = that.tabs;
                var needAdd = true;

                for (var i = 0; i < tabs.length; i++) {
                    var v = tabs[i];
                    if ((tab.data === undefined && v.data === undefined)
                        || (tab.data && v.data && (v.data.appid === tab.data.appid))) {
                        needAdd = false;
                        that.activeTabIndex = i + 1;
                        break;
                    }
                }

                if (needAdd) {
                    that.tabs.push(tab);
                    $timeout(function () {
                        if (tab.autoActive) {
                            that.activeTabIndex = that.tabs.length;
                        }
                    }, 0);
                }
            }
        };

        vm.pageTab = pageTab;

        vm.refresh = function () {
            vm.getTableList.reload().then(function () {
                toastr.success("刷新成功");
            }).catch(function () {
                toastr.error("刷新失败");
            });
        };

        vm.getTableList = new NgTableParams({count: 10}, {
            getData: function (params) {
                var data = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    },
                    params.filter()
                );

                return ErgateAppModule.getAppModuleList(data,
                    {},
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        vm.editAppModule = function (row) {
            if (!row.eventList) row.eventList = [];
            if (row.eventNames) {
                var eNames = row.eventNames.split(',');
                for (var i = 0; i < eNames.length; i++) {
                    row.eventList.push({
                        id: i,
                        eventName: eNames[i]
                    });
                }
            }
            pageTab.addTab({
                heading: '应用模块编辑',
                contentId: 'appmodule-edit.html',
                data: row,
                autoActive: true
            });
        };

        vm.newAppModule = function() {
            pageTab.addTab({
                heading: '应用模块新增',
                contentId: 'appmodule-edit.html',
                autoActive: true,
                data: {
                    eventList: []
                }
            });
        };

        vm.deleteAppModule = function (row) {
            if (row && row.id) {
                ErgateAppModule.deleteAppModule({}, row, function (resp) {
                    if (resp.status === "SUCCESS") {
                        $timeout(function() {
                            vm.refresh();
                        }, 0);
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "删除应用模块失败！"), "错误");
                    }
                });
            }
        };

        vm.updateOrSaveAppModule = function (tab, idx, form) {
            var data = tab.data;
            if (!form.$valid) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }

            if (data.isSubscriber === 1) {
                if (data.eventList && data.eventList.length > 0) {
                    data.eventNames = '';
                    for (var i = 0; i < data.eventList.length; i++) {
                        data.eventNames = data.eventList[i].eventName + ',' + data.eventNames;
                    }
                    data.eventNames = data.eventNames.substring(0, data.eventNames.length - 1);
                }
            } else {
                data.eventList = [];
            }

            ErgateAppModule.updateOrSaveAppModule({}, data, function (resp) {
                if (resp.status === "SUCCESS") {
                    pageTab.removeTab(idx);
                    $timeout(function() {
                        vm.refresh();
                    }, 0);
                } else {
                    toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存应用模块失败！"), "错误");
                }
            });
        };

        vm.applyConfigChange = function(row) {
            var appid = null;
            if (row && row.appid) {
                appid = row.appid;
            }
            ErgateAppModule.applyConfigChange({selectAppid: appid}, {}, function(resp) {
                if (resp.status === "SUCCESS") {
                    toastr.success("应用配置成功");
                } else {
                    toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "应用配置失败！"), "错误");
                }
            });
        }
    }
})();

