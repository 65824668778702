(function() {
    'use strict';

    angular.module('iwh-innerModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('replenishPlan', {
                parent: 'main',
                url: 'iwh-inner/replenishPlan',
                data: {
                    'pageTitle': '补货计划维护'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inner-module/replenish/replenishPlan/replenishPlan.html',
                        controller: 'ReplenishPlanController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
