(function () {
    'use strict';

    angular.module('iportalModule')
        .controller('iPortalSystemMessageController', iPortalSystemMessageController);

    iPortalSystemMessageController.$inject = ['NgTableParams', 'NgSelect', '$timeout', 'toastr', '$uibModal', '$scope', 'enums', 'iPortalSystemMessage'];

    function iPortalSystemMessageController(NgTableParams, NgSelect, $timeout, toastr, $uibModal, $scope, enums, iPortalSystemMessage) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.modalInstance = null;
        vm.modalInstance = null;
        vm.sysMsgTypeEnum = enums("portal.SysMsgType");
        vm.sysMsgType = enums("portal.SysMsgType").list("title", "id");
        vm.sysMsgStatusEnum = enums("portal.SysMsgStatus");
        vm.sysMsgStatus = enums("portal.SysMsgStatus").list("title", "id");
        vm.receiveTypeEnum = enums("portal.ReceiveType");
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iPortalSystemMessage.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.userList = null;
        vm.roleList = null;
        getRoleAndUsers();
        vm.search = search;
        vm.removeTab = removeTab;
        vm.create = create;
        vm.releaseMsg = releaseMsg;
        vm.msgDetail = msgDetail;
        vm.saveMsg = saveMsg;
        vm.createEidtMsgPage = createEidtMsgPage;
        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function getRoleAndUsers() {
            iPortalSystemMessage.getUserAndRole({}, {}, function (resp) {
                vm.userList = resp.content.users;
                vm.roleList = resp.content.roles;
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function create() {
            //todo 创建系统消息
            var entity = {
                heading: '创建消息',
                contentId: "msg-edit.html",
                data: {},
                multiUserSelect: new NgSelect({
                    allList: vm.userList,
                    list: [],
                    config: {
                        meta: {
                            label: 'userName',
                            id: 'userId'
                        }
                    }
                }),
                multiRoleSelect: new NgSelect({
                    allList: vm.roleList,
                    list: [],
                    config: {
                        meta: {
                            label: 'roleName',
                            id: 'roleId'
                        }
                    }
                }),
                readOnly: false,
                roles: [],
                active: 0
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function createEidtMsgPage(row) {
            var entity = {
                heading: '编辑消息',
                contentId: "msg-edit.html",
                data: row,
                multiUserSelect: new NgSelect({
                    allList: vm.userList,
                    list: row.receiverType == vm.receiveTypeEnum.value("USER") ? JSON.parse(row.receivers) : [],
                    config: {
                        meta: {
                            label: 'userName',
                            id: 'userId'
                        }
                    }
                }),
                multiRoleSelect: new NgSelect({
                    allList: vm.roleList,
                    list: row.receiverType == vm.receiveTypeEnum.value("ROLE") ? JSON.parse(row.receivers) : [],
                    config: {
                        meta: {
                            label: 'roleName',
                            id: 'roleId'
                        }
                    }
                }),
                readOnly: false,
                roles: [],
                active: row.receiverType == vm.receiveTypeEnum.value("USER") ? 0 : 1
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function validate(entity) {
            if (!!!entity.data.title || !!!entity.data.type || !!!entity.data.content) {
                toastr.info("信息未填完", "提示");
                return false;
            }
            var users = entity.multiUserSelect.getSelected();
            var roles = entity.multiRoleSelect.getSelected();
            if (!!users && users.length > 0 && !!roles && roles.length > 0) {
                toastr.info("您只能按人员或者按角色选择,不能同时选择", "提示");
                return false;
            }
            if ((!!!users || users.length <= 0) && (!!!roles || roles.length <= 0)) {
                toastr.info("您必须选择按人员或者按角色选择权限", "提示");
                return false;
            }

            return true;
        }

        function saveMsg(entity, tabIndex, isRelease) {
            //todo 保存消息
            if (validate(entity)) {
                entity.data.receiverIds = [];
                var receivers = null;
                var users = entity.multiUserSelect.getSelected();
                var roles = entity.multiRoleSelect.getSelected();
                if (!!users && users.length > 0) {
                    receivers = users;
                    entity.data.receiverType = vm.receiveTypeEnum.value("USER");
                } else {
                    receivers = roles;
                    entity.data.receiverType = vm.receiveTypeEnum.value("ROLE");
                }
                angular.forEach(receivers, function (item) {
                    if (entity.data.receiverType == vm.receiveTypeEnum.value("USER")) {
                        entity.data.receiverIds.push(item.userId);
                    } else {
                        entity.data.receiverIds.push(item.roleId);
                    }
                });
                iPortalSystemMessage.save({isRelease: isRelease}, entity.data, function (resp) {
                    toastr.success(isRelease ? "保存并发布成功" : "保存成功");
                    vm.removeTab(tabIndex);
                }, function (err) {
                    toastr.error("操作失败");
                });
            }
        }


        function releaseMsg(row) {
            //todo 发布系统消息
            iPortalSystemMessage.release({id: row.id}, {}, function (resp) {
                if (resp.content) {
                    toastr.success("发布成功", "成功");
                    vm.search();
                } else {
                    toastr.error("发布失败", "错误");
                }
            }, function (err) {
                toastr.error("发布失败", "错误");
            });
        }


        function msgDetail(row) {
            //todo 系统消息详情
            var entity = {
                heading: '消息详情',
                contentId: "msg-edit.html",
                data: row,
                multiUserSelect: new NgSelect({
                    allList: vm.userList,
                    list: row.receiverType == vm.receiveTypeEnum.value("USER") ? JSON.parse(row.receivers) : [],
                    config: {
                        meta: {
                            label: 'userName',
                            id: 'userId'
                        }
                    }
                }),
                multiRoleSelect: new NgSelect({
                    allList: vm.roleList,
                    list: row.receiverType == vm.receiveTypeEnum.value("ROLE") ? JSON.parse(row.receivers) : [],
                    config: {
                        meta: {
                            label: 'roleName',
                            id: 'roleId'
                        }
                    }
                }),
                readOnly: true,
                roles: [],
                active: row.receiverType == vm.receiveTypeEnum.value("USER") ? 0 : 1
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }
    }
})();
