(function() {
    'use strict';

    angular.module('ivendorModule')
        .controller('VendorModInventoryController', VendorModInventoryController);

    VendorModInventoryController.$inject = ['Inventory', 'NgTableParams','Report', 'toastr'];

    function VendorModInventoryController ( Inventory, NgTableParams,Report, toastr) {
        var vm = this;
        vm.active = 0;
        vm.search = search;
        vm.reports = [];
        vm.loadReport = loadReport;
        function loadReport() {
        	Report.list({functionCode:"VENDOR_INVENTORY_LIST"}, {}, function(resp) {
				vm.reports = resp.content;
			});
        };
        vm.loadReport();
      //列表页功能
       
       vm.whcode=[];
       
       Inventory.allwhouse({},{},function(resp){
			for(var key in resp.content){
				 var check = new Object();
				 check.id = key;
				 check.title = resp.content[key];
				 vm.whcode.push(check);
			}
			
		},function(resp){
			toastr.error(resp.data.errorMsg,"错误");
			return ;
		});
//       vm.listdata=null;
       vm.filterdata=filterdata;
       
       vm.allQty = null;
       vm.allAvailableQty = null;
       
       
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Inventory.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					var listdata = data.content.datas;
					vm.filterdata(listdata);
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();
			
		}
		
		function filterdata(listdata){
			
			if(listdata != null && listdata.length>0){
//				var leng=vm.whcode.length;
//				vm.whcode.splice(1, leng-1);
				
				var bean= listdata[0];
				vm.allQty=bean.allQty;
				vm.allAvailableQty = bean.allAvailableQty;
				
//				for(var key in bean.whcodeMap){
//					 var check = new Object();
//					 check.id = key;
//					 check.title = bean.whcodeMap[key];
//					 vm.whcode.push(check);
//				}
				
//				
//				console.log(listdata);
//				
//				var whcodes=[];
//				
////				for(var key in jsonData)  
////				    console.log("属性：" + key + ",值："+ jsonData[key]);  
////				}
				
//				angular.forEach(listdata , function(data) {
//					var whcode=data.whcode;
//					var whname=data.whName;
////					console.log(whcode);
//					
//					if(whcodes.indexOf(whcode) == -1){
////						console.log(whcodes);
//						 whcodes.push(whcode);
//						 var check = new Object();
//						 check.id = whcode;
//						 check.title = whname;
//						 vm.whcode.push(check);
//	            	}
//				});
			
				}else{
					vm.allQty=null;
					vm.allAvailableQty = null;
				}
			return;
		}
    }
})();

