(function () {
    'use strict';

    angular.module('idataModule')
        .controller('ParkController', ParkController);

    ParkController.$inject = ['Park', 'NgTableParams', '$uibModal', '$state', 'Principal', '$timeout', 'toastr'];

    function ParkController(Park, NgTableParams, $uibModal, $state, Principal, $timeout, toastr) {
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.active = 0;
        vm.removeTab = removeTab;

        //园区操作
        vm.addPark = addPark;
        vm.newPark = newPark;
        vm.editPark = editPark;
        vm.updatePark = updatePark;
        vm.confirmDelete = confirmDelete;


        //出入口操作
        vm.addGateway = addGateway;
        vm.editGateway = editGateway;
        vm.removeGateway = removeGateway;

        //停车场操作
        vm.addParkingLot = addParkingLot;
        vm.editParkingLot = editParkingLot;
        vm.removeParkingLot = removeParkingLot;
        //
        vm.changeStatus = changeStatus;

        vm.status = [
            {id: 1, title: "启用"},
            {id: 0, title: "禁用"},
        ];

        vm.postParams = {};
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()
                }, params.filter());

                if ($state.params.postParams !== null) {
                    var temp = {};
                    for (var p in $state.params.postParams) {
                        var name = p;//属性名称
                        var value = $state.params.postParams[p];//属性对应的值
                        temp[name] = $state.params.postParams[p];
                    }
                    $state.params.postParams = null;
                }

                vm.postParams = postParams;
                return Park.list({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        // 出入口类型
        vm.gatewayType = [
            {'id': '0', 'title': '出入口'},
            {'id': '1', 'title': '出口'},
            {'id': '2', 'title': '入口'}];

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        //#region园区操作
        function addPark() {
            var entity = {
                heading: '新建',
                gatewayTemplate: "gateway-list.html",
                parkTemplate: "parkingLot-list.html",
                platformTemplate: "platform-list.html",
                contentId: "sku-edit.html",
                active: true,
                data: {
                    park: {
                        code: null,
                        name: null,
                        remark: null
                    }
                },
                newPark: true,
                tableGatewayParams: new NgTableParams({count: 10}, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            parkCode: entity.data.park.code,
                            pn: params.page(),
                            ps: params.count(),
                            orderBy: 'code'
                        }, params.filter());
                        vm.postParams = postParams;
                        return Park.gatewayList({}, postParams,
                            function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                tableParkingLotParams: new NgTableParams({count: 10}, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            parkCode: entity.data.park.code,
                            pn: params.page(),
                            ps: params.count(),
                            orderBy: 'code'
                        }, params.filter());
                        vm.postParams = postParams;
                        return Park.parkingLotList({}, postParams,
                            function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                tablePlatformParams: new NgTableParams({count: 10}, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            parkCode: entity.data.park.code,
                            pn: params.page(),
                            ps: params.count(),
                            orderBy: 'code'
                        }, params.filter());
                        vm.postParams = postParams;
                        return Park.platformList({}, postParams,
                            function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                })
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                entity.active = 0;
            }, 0);
        }

        function newPark(t, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            Park.savePark({}, t.data.park, function (resp) {
                if (resp.content) {
                    toastr.success("新增成功", "成功");
                    t.newPark = false;
                    t.data.park = resp.content;
                    console.log(t.data.park, "新建园区");
                    // vm.refreshEditUI(resp.content.id);
                }
            })

        }

        function editPark(row) {
            Park.detail({}, {id: row.id}, function (resp) {
                var park = resp.content;
                var entity = {
                    heading: '编辑' + row.code,
                    gatewayTemplate: "gateway-list.html",
                    parkTemplate: "parkingLot-list.html",
                    platformTemplate: "platform-list.html",
                    contentId: "sku-edit.html",
                    active: true,
                    data: {
                        park: park
                    },
                    newPark: false,
                    tableGatewayParams: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                parkCode: entity.data.park.code,
                                pn: params.page(),
                                ps: params.count(),
                                orderBy: 'code'
                            }, params.filter());
                            vm.postParams = postParams;
                            return Park.gatewayList({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function (data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                        }
                    }),
                    tableParkingLotParams: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                parkCode: entity.data.park.code,
                                pn: params.page(),
                                ps: params.count(),
                                orderBy: 'code'
                            }, params.filter());
                            vm.postParams = postParams;
                            return Park.parkingLotList({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function (data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                        }
                    }),
                    platformParams: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                parkCode: entity.data.park.code,
                                pn: params.page(),
                                ps: params.count(),
                                orderBy: 'code'
                            }, params.filter());
                            vm.postParams = postParams;
                            return Park.platformList({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function (data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                        }
                    })
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                    entity.active = 0;
                }, 0);
            });
        }

        function updatePark(row, form) {

            console.log(row, "更新园区");


            if (form !== null) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
            }

            Park.updatePark({}, row, function (resp) {
                if (resp.content) {
                    toastr.success("修改成功", "成功");
                }
            })
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: 'ctrl',
                resolve: {
                    item: row
                }
            });
            vm.modalInstance.result.then(function (item) {
                Park.delPark({}, {id: item.id, code: item.code}, function (resp) {
                    if (resp.content === "success") {
                        toastr.success("删除成功");
                        vm.search();
                    }
                });
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];

        function deleteModal($uibModalInstance, item) {
            var ctrl = this;
            ctrl.item = item;
            ctrl.ok = ok;
            ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close(ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


        //#endregion

        //#region出入口操作

        function addGateway(tab) {
            if (tab.data.code === '' || tab.data.code === null) {
                toastr.error("园区编码为空!", "错误");
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'edit-gateway.html',
                controller: gatewayCtrl,
                controllerAs: 'ctrl',
                resolve: {
                    item: {
                        tab: tab,
                        gatewayType: vm.gatewayType,
                        gateway: {},
                        NewGateway: true
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                Park.detail({}, {id: item.tab.data.park.id}, function (resp) {
                    item.tab.data.park = resp.content;
                });
                item.tab.tableGatewayParams.reload();
                item.tab.tableParkingLotParams.reload();
            });
        }

        function editGateway(tab, row) {
            if (tab.data.code === '' || tab.data.code === null) {
                toastr.error("园区编码为空!", "错误");
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'edit-gateway.html',
                controller: gatewayCtrl,
                controllerAs: 'ctrl',
                resolve: {
                    item: {
                        tab: tab,
                        gatewayType: vm.gatewayType,
                        gateway: row,
                        NewGateway: false
                    }

                }
            });
            vm.modalInstance.result.then(function (item) {
                Park.detail({}, {id: item.tab.data.park.id}, function (resp) {
                    item.tab.data.park = resp.content;
                });
                item.tab.tableGatewayParams.reload();
                item.tab.tableParkingLotParams.reload();
            });
        }

        function removeGateway(tab, row) {
            Park.delParkGateway({}, {id: row.id, parkCode: row.parkCode}, function (resp) {
                if (resp.content) {
                    toastr.success("删除成功", "成功");
                    //更新园区信息
                    Park.detail({}, {id: tab.data.park.id}, function (resp) {
                        tab.data.park = resp.content;
                    });
                    //刷新出入口列表
                    tab.tableGatewayParams.reload();
                }
            })
        }

        //新建出入口弹出框
        gatewayCtrl.$inject = ['$uibModalInstance', 'item', 'Park', 'toastr'];

        function gatewayCtrl($uibModalInstance, item, Park, toastr) {
            var ctrl = this;
            ctrl.item = item;
            //保存
            ctrl.save = save;
            //更新
            ctrl.update = update;
            //取消
            ctrl.cancel = cancel;

            function save(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                if (!ctrl.item.gateway.type) {
                    toastr.warning("请选择出入口的类型", "警告");
                    return;
                }

                var temp = {
                    parkCode: ctrl.item.tab.data.park.code,
                    code: ctrl.item.gateway.code,
                    name: ctrl.item.gateway.name,
                    type: ctrl.item.gateway.type,
                    remark: ctrl.item.gateway.remark,
                    status: 1
                };

                Park.saveParkGateway({}, temp, function (resp) {
                    if (resp.content) {
                        toastr.success("新增成功", "成功");
                        $uibModalInstance.close(ctrl.item);
                    }
                })
            }

            function update(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                console.log(ctrl.item.gateway,"出入口");

                if (!ctrl.item.gateway.type) {
                    toastr.warning("请选择出入口的类型", "警告");
                    return;
                }

                console.log("修改信息:");
                Park.updateParkGateway({}, ctrl.item.gateway, function (resp) {
                    if (resp.content) {
                        toastr.success("修改成功", "成功");
                        $uibModalInstance.close(ctrl.item);
                    }
                });
            }

            function cancel() {
                $uibModalInstance.close(ctrl.item);
            }
        }

        //#endregion

        //#region停车场操作
        function addParkingLot(tab) {
            if (tab.data.code === '' || tab.data.code === null) {
                toastr.error("园区编码为空!", "错误");
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'edit-parkingLot.html',
                controller: parkingLotCtrl,
                controllerAs: 'ctrl',
                resolve: {
                    item: {
                        tab: tab,
                        parkingLot: {},
                        NewParkingLot: true
                    }

                }
            });

            vm.modalInstance.result.then(function (item) {
                Park.detail({}, {id: item.tab.data.park.id}, function (resp) {
                    item.tab.data.park = resp.content;
                });
                item.tab.tableGatewayParams.reload();
                item.tab.tableParkingLotParams.reload();
            });
        }

        function editParkingLot(tab, row) {
            if (tab.data.code === '' || tab.data.code === null) {
                toastr.error("园区编码为空!", "错误");
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'edit-parkingLot.html',
                controller: parkingLotCtrl,
                controllerAs: 'ctrl',
                resolve: {
                    item: {
                        tab: tab,
                        parkingLot: row,
                        NewParkingLot: false
                    }

                }
            });
            vm.modalInstance.result.then(function (item) {
                Park.detail({}, {id: item.tab.data.park.id}, function (resp) {
                    item.tab.data.park = resp.content;
                });
                item.tab.tableGatewayParams.reload();
                item.tab.tableParkingLotParams.reload();
            });
        }

        function removeParkingLot(tab, row) {
            Park.delParkParkingLot({}, {id: row.id, parkCode: row.parkCode}, function (resp) {
                if (resp.content) {
                    toastr.success("删除成功", "成功");
                    //更新园区信息
                    Park.detail({}, {id: tab.data.park.id}, function (resp) {
                        tab.data.park = resp.content;
                    });
                    //刷新出入口列表
                    tab.tableParkingLotParams.reload();
                }
            })
        }

        //新建出入口弹出框
        parkingLotCtrl.$inject = ['$uibModalInstance', 'item', 'Park', 'toastr'];

        function parkingLotCtrl($uibModalInstance, item, Park, toastr) {
            var ctrl = this;
            ctrl.item = item;
            //保存
            ctrl.save = save;
            //更新
            ctrl.update = update;
            //取消
            ctrl.cancel = cancel;

            function save(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                var temp = {
                    parkCode: ctrl.item.tab.data.park.code,
                    code: ctrl.item.parkingLot.code,
                    name: ctrl.item.parkingLot.name,
                    parkingSpaces: ctrl.item.parkingLot.parkingSpaces,
                    remark: ctrl.item.parkingLot.remark,
                    status: 1
                };
                Park.saveParkParkingLot({}, temp, function (resp) {
                    if (resp.content) {
                        toastr.success("新增成功", "成功");
                        $uibModalInstance.close(ctrl.item);
                    }
                })
            }

            function update(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                Park.updateParkParkingLot({}, ctrl.item.parkingLot, function (resp) {
                    if (resp.content) {
                        toastr.success("修改成功", "成功");
                        $uibModalInstance.close(ctrl.item);
                    }
                })
            }

            function cancel() {
                $uibModalInstance.close(ctrl.item);
            }
        }

        //#endregion


        function changeStatus(tab, row, event) {
            row.status = row.status === 1 ? 0 : 1;
            switch (event) {
                case 'Park':
                    console.log("改变园区状态");
                    Park.updatePark({}, row, function (resp) {
                        if (resp.content) {
                            toastr.success("修改成功", "成功");
                            vm.search();
                        }
                    });
                    break;
                case 'Gateway':
                    console.log("改变出入口状态");
                    Park.updateParkGateway({}, row, function (resp) {
                        if (resp.content) {
                            toastr.success("修改成功", "成功");
                            tab.tableGatewayParams.reload();
                        }
                    });
                    break;
                case 'ParkingLot':
                    console.log("改变停车场状态");
                    Park.updateParkParkingLot({}, row, function (resp) {
                        if (resp.content) {
                            toastr.success("修改成功", "成功");
                            tab.tableParkingLotParams.reload();
                        }
                    });
                    break;
                case 'Platform':
                    console.log("改变月台状态");
                    Park.updateParkPlatform({}, row, function (resp) {
                        if (resp.content) {
                            toastr.success("修改成功", "成功");
                            tab.tablePlatformParams.reload();
                        }
                    });
                    break;
            }
        }


        //#region刷新编辑界面
        // 通用方法
        vm.search = function search() {
            vm.tableParams.reload();
        }
    }

})();
