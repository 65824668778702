(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('CodeType', CodeType);

    CodeType.$inject = ['$resource', 'iServiceConfig'];

    function CodeType ($resource, iServiceConfig) {
        var service = $resource('web/codetype', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/codetype/list", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/codetype/detail", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/codetype/save", method: 'POST'},
            'checkCode': {url: iServiceConfig.idataHost + "/web/codetype/checkcode", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/codetype/disable", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/codetype/enable", method: 'POST'},
        });
        return service;
    }
})();
