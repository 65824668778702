(function() {
    'use strict';

    angular
        .module('ireportModule')
        .factory('RptProcessNode', RptProcessNode);

    RptProcessNode.$inject = ['$resource', 'iServiceConfig'];

    function RptProcessNode ($resource, iServiceConfig) {
        var service = $resource('web/node', {}, {
            'list': { url: iServiceConfig.ireportHost + "/web/node/list", method: 'POST'},
            'detail': { url: iServiceConfig.ireportHost + "/web/node/detail", method: 'POST'},
            'save': { url: iServiceConfig.ireportHost + "/web/node/save", method: 'POST'},
            'enable': { url: iServiceConfig.ireportHost + "/web/node/enable", method: 'POST'},
            'disable': { url: iServiceConfig.ireportHost + "/web/node/disable", method: 'POST'}
        });

        return service;
    }
})();
