(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('rotate-rule', {
            parent: 'main',
            url: 'iwh-strategy/rotate-rule',
            data: {'pageTitle': '周转规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/rotateRule/rotateRule.html',
                    controller: 'RotateRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('RotateRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/rotate-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/rotate-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/rotate-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/rotate-rule/:id', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/rotate-rule/detail/save', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/rotate-rule/detail/delete/:id', params: {id: '@id'}, method: 'POST'},
            'getRotateFields': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.RotateFields.json', method: 'GET'},
        });
    }]);

    app.controller('RotateRuleController', [
        '$scope', 'RotateRule', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, RotateRule, NgTableParams, $timeout, toastr) {
            $scope.ynOptions = [{k: '10', v: '升序'}, {k: '20', v: '降序'}];

            $scope.modalInstance = null;
            $scope.tableParams = null;
            $scope.tenantDetail = null;
            $scope.tabs = [];
            $scope.active = 0;
            $scope.rotateFieldsMap = {};
            RotateRule.getRotateFields({}, function (result) {
                $scope.rotateFields = result.content;
                angular.forEach($scope.rotateFields, function(data,index,array){
                	$scope.rotateFieldsMap[data.value] = data.label;
                });
            });
            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return RotateRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.rotateRuleContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {rotateRuleDetails: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "rotateRuleDetail.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    RotateRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.rotateRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {rotateRuleDetails: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "rotateRuleDetail.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    RotateRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.saveRotateRule = function (tab,index) {
                RotateRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };

            $scope.rotateRuleDetail = function (tab, detail) {
                var rule = tab.data;
                var ddetail = angular.copy(detail);
                tab.currentDetail = ddetail || {
                        tenantId: rule.tenantId,
                        warehouseCode: rule.warehouseCode,
                        rotationCode: rule.code
                    };
            };

            $scope.saveRotateRuleDetail = function (tab) {
                if (!tab.currentDetail) return;
                var isNew = !tab.currentDetail.id;
                RotateRule.detailSavePost({}, tab.currentDetail, function (resp) {
                	tab.currentDetail = null;
                	RotateRule.contentGet({id: tab.data.id}, function (item) {
                        tab.data = item.content;
                        toastr.success('保存成功！', '成功');
                    });
                });
            };

            $scope.rotateRuleDetailDel = function (tab, detail) {
                if (!detail || !detail.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + $scope.rotateFieldsMap[detail.rotationField] + '】吗？',
                    ok: function () {
                        RotateRule.detailDeletePost({id: detail.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentDetail && tab.currentDetail.id === detail.id) {
                                    delete tab.currentDetail;
                                }
                                tab.data.rotateRuleDetails.splice(tab.data.rotateRuleDetails.indexOf(detail), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };
        }
    ]);
})();