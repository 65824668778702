(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('Work', Work);

    Work.$inject = ['$resource', 'iServiceConfig'];

    function Work ($resource, iServiceConfig) {
        var service = $resource('web/work', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/work/list", method: 'POST'},
            'materialList': { url: iServiceConfig.iwhInnerHost + "/web/work/materialList", method: 'POST'},
            'productList': { url: iServiceConfig.iwhInnerHost + "/web/work/productList", method: 'POST'},
            'materialPickList': { url: iServiceConfig.iwhInnerHost + "/web/work/materialPickList", method: 'POST'},
            'productWorkList': { url: iServiceConfig.iwhInnerHost + "/web/work/productWorkList", method: 'POST'},
            'putAwayList': { url: iServiceConfig.iwhInnerHost + "/web/work/putAwayList", method: 'POST'},
            'logList': {url: iServiceConfig.iwhInnerHost + "/web/work/logList", method: 'POST'},
            'bomList': {url: iServiceConfig.iwhInnerHost + "/web/work/bomList", method: 'POST'},
            'getWorkNum': {url: iServiceConfig.iwhInnerHost + "/web/work/getWorkNum", method: 'POST'},
            'saveAndUpdateProduct': {url: iServiceConfig.iwhInnerHost + "/web/work/saveAndUpdateProduct", method: 'POST'},
            'saveAndUpdateMaterial': {url: iServiceConfig.iwhInnerHost + "/web/work/saveAndUpdateMaterial", method: 'POST'},
            'saveAndUpdate': {url: iServiceConfig.iwhInnerHost + "/web/work/saveAndUpdate", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/work/cancel", method: 'POST'},
            'plan': {url: iServiceConfig.iwhInnerHost + "/web/work/plan", method: 'POST'},
            'close': {url: iServiceConfig.iwhInnerHost + "/web/work/close", method: 'POST'},
            'delMaterials': {url: iServiceConfig.iwhInnerHost + "/web/work/delMaterials", method: 'POST'},
            'delProducts': {url: iServiceConfig.iwhInnerHost + "/web/work/delProducts", method: 'POST'},
            'allocate': {url: iServiceConfig.iwhInnerHost + "/web/work/allocate", method: 'POST'},
            'begin': {url: iServiceConfig.iwhInnerHost + "/web/work/begin", method: 'POST'},
            'getMaterialLineNum': {url: iServiceConfig.iwhInnerHost + "/web/work/getMaterialLineNum", method: 'POST'},
            'getProductLineNum': {url: iServiceConfig.iwhInnerHost + "/web/work/getProductLineNum", method: 'POST'},
            'getSkuRuleInfo': {url: iServiceConfig.iwhInnerHost + "/web/work/getSkuRuleInfo", method: 'POST'},
        });

        return service;
    }
})();
