(function()  {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('LotLocLpnInventorySearchController', LotLocLpnInventorySearchController);

    LotLocLpnInventorySearchController.$inject = ['$rootScope', 'InventorySearch', 'MovePlanTask', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function LotLocLpnInventorySearchController ($rootScope,  InventorySearch, MovePlanTask, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
    	var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.lotAttributeSearch = null;
        vm.searchTemp = {
            tenantId : null,
            warehouseCode : null,
            lotRule : null
        };
        vm.allCount = {
            ownerCode : null,
            ownerName : null,
            caseNum : "当前页总计",
            availableQty : null,
            qty : null,
            allAllocatedQty : null,
            pickedQty : null
        }
        vm.allCount2 = {
            ownerCode : null,
            ownerName : null,
            caseNum : "所有总计",
            availableQty : null,
            qty : null,
            allocatedQty : null,
            pickedQty : null
        }
        function pageTitle() {
            return $window.document.title;
        }
        InventorySearch.beforeList({}, {}, function(resp) {
            vm.searchTemp = resp.content;
        });
      //查询
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				if(!postParams.locCode) {
					postParams.locCode = $state.params.locCode;
				}
                if(vm.ownerCode){
                    postParams.sownerCode = vm.ownerCode;
                    if(vm.lotAttributeSearch!=null){
                        if(vm.lotAttributeSearch.lotAttribute01){
                            postParams.lotAttribute01 = vm.lotAttributeSearch.lotAttribute01;
                        }
                        if(vm.lotAttributeSearch.lotAttribute02){
                            postParams.lotAttribute02 = vm.lotAttributeSearch.lotAttribute02;
                        }
                        if(vm.lotAttributeSearch.lotAttribute03){
                            postParams.lotAttribute03 = vm.lotAttributeSearch.lotAttribute03;
                        }
                        if(vm.lotAttributeSearch.lotAttribute04){
                            postParams.lotAttribute04 = vm.lotAttributeSearch.lotAttribute04;
                        }
                        if(vm.lotAttributeSearch.lotAttribute05){
                            postParams.lotAttribute05 = vm.lotAttributeSearch.lotAttribute05;
                        }
                        if(vm.lotAttributeSearch.lotAttribute06){
                            postParams.lotAttribute06 = vm.lotAttributeSearch.lotAttribute06;
                        }
                        if(vm.lotAttributeSearch.lotAttribute07){
                            postParams.lotAttribute07 = vm.lotAttributeSearch.lotAttribute07;
                        }
                        if(vm.lotAttributeSearch.lotAttribute08){
                            postParams.lotAttribute08 = vm.lotAttributeSearch.lotAttribute08;
                        }
                        if(vm.lotAttributeSearch.lotAttribute09){
                            postParams.lotAttribute09 = vm.lotAttributeSearch.lotAttribute09;
                        }
                        if(vm.lotAttributeSearch.lotAttribute10){
                            postParams.lotAttribute10 = vm.lotAttributeSearch.lotAttribute10;
                        }
                        if(vm.lotAttributeSearch.lotAttribute11){
                            postParams.lotAttribute11 = vm.lotAttributeSearch.lotAttribute11;
                        }
                        if(vm.lotAttributeSearch.lotAttribute12){
                            postParams.lotAttribute12 = vm.lotAttributeSearch.lotAttribute12;
                        }
                    }
                }
                this.httpParams = postParams;
				return InventorySearch.lotLocLPNInventory({}, postParams, function(resp) {
					params.total(resp.content.pageVo.tc);
				}).$promise.then(function(data) {
					var datas = data.content.pageVo.datas;
                    vm.allCount.allocatedQty = data.content.allAllocatedQtyAll;
                    vm.allCount.availableQty = data.content.canUseQtyAll;
                    vm.allCount.qty = data.content.qtyAll;
                    vm.allCount.pickedQty = data.content.pickedQtyAll;
                    datas[datas.length] = vm.allCount;
                    vm.allCount2.allocatedQty = data.content.allAllocatedQtyAll2;
                    vm.allCount2.availableQty = data.content.canUseQtyAll2;
                    vm.allCount2.qty = data.content.qtyAll2;
                    vm.allCount2.pickedQty = data.content.pickedQtyAll2;
                    datas[datas.length] = vm.allCount2;
					return datas;
				});
			}
		});
		console.log(vm.tableParams);
        vm.search = search;
        function search() {
            vm.tableParams.reload();
        }

        vm.btnSearch = btnSearch;
        function btnSearch() {
            if(!vm.ownerCode){
                vm.lotAttributeSearch == null;
            }
            vm.tableParams.reload();
        }

        vm.ruleChange = ruleChange;
        function ruleChange() {
            vm.lotAttributeSearch = null;
            if (vm.ownerCode && vm.searchTemp && vm.searchTemp.lotRule) {
                vm.lotAttributeSearch = {
                    tenantId: vm.searchTemp.tenantId,
                    warehouseCode: vm.searchTemp.warehouseCode,
                    lotRule: vm.searchTemp.lotRule,
                    ownerCode: vm.ownerCode,
                    skuCode: '-1'
                };
            }
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
        vm.randomMove = function(invData) {
            var data = {
                ownerCode : invData.ownerCode,
                ownerName : invData.ownerName,
                skuCode : invData.skuCode,
                skuName : invData.skuName,
                styleNum : invData.styleNum,
                lotAttribute : invData.lotAttribute,
                lot : invData.lot,
                fromLoc : invData.locCode,
                fromLpn : invData.lpn,
                fromCaseNum : invData.caseNum,
                availableQty : invData.availableQty,
                qty : invData.qty,
                toLoc : null,
                toLpn : ' ',
                toCaseNum : ' ',
                mvQty : null
            }
            vm.modalInstance = $uibModal.open({
                templateUrl : 'randomMoveBox.html',
                controller : randomMoveModle,
                controllerAs : '$ctrl',
                resolve : {
                    item :data
                }
            });
        }

        randomMoveModle.$inject = [ '$uibModalInstance' ,'item'];
        function randomMoveModle($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.ok = function(){
                $uibModalInstance.close();
            };
            $ctrl.response = {};
            $ctrl.data = {};
            $ctrl.item = item;
            $ctrl.cancel = function(){
                $uibModalInstance.dismiss('cancel');
            };

            $ctrl.doRandomMove = function() {
                if (!$ctrl.item.mvQty || $ctrl.item.mvQty <= 0) {
                    toastr.error("参数错误：请填写移动数量", "错误");
                    return;
                }
                if (!$ctrl.item.toLoc || $ctrl.item.toLoc == null || $ctrl.item.toLoc.trim() == "") {
                    toastr.error("参数错误：请填写目标库位编码", "错误");
                    return;
                }
                MovePlanTask.randomMove({},$ctrl.item,function(resp){
                }).$promise.then(function(data){

                    if(data.content.status == 1 || data.content.status == 2) {
                        toastr.error("移动失败，错误信息：" + data.content.errorMsg, "错误");
                        return;
                    }

                    toastr.success("移动成功！");
                    vm.tableParams.reload();
                    $ctrl.ok();
                });
            };
        }
    }
})();
