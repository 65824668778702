(function () {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusFileImportTask', iBusFileImportTask);

    iBusFileImportTask.$inject = ['$resource', 'iServiceConfig'];

    function iBusFileImportTask($resource, iServiceConfig) {
        var service = $resource('web/importTask', {}, {
            'search': {url: iServiceConfig.ibusHost + "/web/importTask/list", method: 'POST'},
            'preSave': {url: iServiceConfig.ibusHost + "/web/importTask/preSave", method: 'GET'},
            'save': {
                url: iServiceConfig.ibusHost + "/web/importTask/save", method: 'POST',
                transformRequest: function (data) {
                    if (data === undefined)
                        return data;

                    var fd = new FormData();
                    angular.forEach(data, function (value, key) {
                        // console.log("key:" + key + ";value:" + value);
                        if (value instanceof FileList) {
                            if (value.length == 1) {
                                fd.append(key, value[0]);
                            } else {
                                angular.forEach(value, function (file, index) {
                                    fd.append(key + '_' + index, file);
                                });
                            }
                        } else {
                            fd.append(key, value);
                        }
                    });

                    return fd;
                },
                headers: {'Content-Type': undefined}
            },
            'cancel': {url: iServiceConfig.ibusHost + "/web/importTask/cancel/:id", method: 'GET'},
            'showLogs': {url: iServiceConfig.ibusHost + "/web/importTask/showLogs/:taskNum", method: 'POST'},
            'downloadTemplate': {
                url: iServiceConfig.ibusHost + "/web/importTemplate/download/:id",
                method: 'GET',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'downloadProMsgFile': {
                url: iServiceConfig.ibusHost + "/web/importTask/downloadMsgFile/:id",
                method: 'GET',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'downloadTaskFile': {
                url: iServiceConfig.ibusHost + "/web/importTask/downloadTaskFile/:id",
                method: 'GET',
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'executeFile': {url: iServiceConfig.ibusHost + "/web/importTask/executeFile/:id", method: 'GET'}
        });

        return service;
    }
})();
