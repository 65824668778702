(function()  {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptSaleRecordQueryController', iEptSaleRecordQueryController);

    iEptSaleRecordQueryController.$inject = ['$rootScope', 'enums', 'SaleRecord', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function iEptSaleRecordQueryController ($rootScope, enums,  SaleRecord, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        
        vm.recordStatus = enums('enterprise.SupplySettlementStatus');

        function pageTitle() {
            return $window.document.title;
        }
        //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
               
				return SaleRecord.page({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();
		}

        vm.btnSearch = btnSearch;
        function btnSearch() {
            vm.tableParams.reload();
        }
        
        vm.btnGenSettle = btnGenSettle;
        function btnGenSettle() {
        	var records = vm.tableParams.getSelected();
        	var recordNums = [];
        	if (records && records.length > 0) {
        		angular.forEach(records, function(item){
        			if (item.recordNum && item.status == 10) {
        				recordNums.push(item.recordNum);
        			}
        		});
        	}
        	$state.go("iEptGenSupplySettlement",{recordNums: recordNums});
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
