(function () {
    'use strict';

    angular
        .module('iplanModule')
        .factory('iPlanWarehousePlan', iPlanWarehousePlan);

    iPlanWarehousePlan.$inject = ['$resource', 'iServiceConfig'];

    function iPlanWarehousePlan($resource, iServiceConfig) {
        var service = $resource('web/warehousePlan', {}, {
            'search': {url: iServiceConfig.iplanHost + "/web/warehousePlan/list", method: 'POST'},
            'preSave': {url: iServiceConfig.iplanHost + "/web/warehousePlan/preSave", method: 'POST'},
            'save': {url: iServiceConfig.iplanHost + "/web/warehousePlan/save", method: 'POST'},
            'getParkList': {url: iServiceConfig.iplanHost + "/web/warehousePlan/getParkList", method: 'GET'},
            'getWarehouseList': {url: iServiceConfig.iplanHost + "/web/warehousePlan/getWarehouseList", method: 'GET'},
            'getParkLotAndGatewayList': {url: iServiceConfig.iplanHost + "/web/warehousePlan/getParkLotAndGatewayList/:parkCode", method: 'GET'},
            'getPlatformList': {url: iServiceConfig.iplanHost + "/web/warehousePlan/getPlatformList/:parkCode/:warehouseCode", method: 'GET'},
            'refreshPlan': {url: iServiceConfig.iplanHost + "/web/warehousePlan/refreshPlan", method: 'GET'},
            'comfirmPlan': {url: iServiceConfig.iplanHost + "/web/warehousePlan/comfirmPlan", method: 'POST'},
            'cancelOrClosePlan': {url: iServiceConfig.iplanHost + "/web/warehousePlan/cancelOrClosePlan", method: 'POST'},
            'listAcrossDispatch': {url: iServiceConfig.iplanHost + "/web/warehousePlan/listAcrossDispatch", method: 'POST'},
            'dispatch': {url: iServiceConfig.iplanHost + "/web/warehousePlan/dispatch", method: 'POST'},
            'getWarehousePlanByMonth': {url: iServiceConfig.iplanHost + "/web/warehousePlan/getWarehousePlanByMonth", method: 'POST'},
            'getWarehousePlanByDay': {url: iServiceConfig.iplanHost + "/web/warehousePlan/getWarehousePlanByDay", method: 'POST'}
        });
        return service;
    }
})();
