(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Supplier', Supplier);

    Supplier.$inject = ['$resource', 'iServiceConfig'];

    function Supplier ($resource, iServiceConfig) {
        var service = $resource('web/supplier', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/supplier/list", method: 'POST'},
            'delete': {url: iServiceConfig.idataHost + "/web/supplier/delete", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/supplier/detail", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/supplier/save", method: 'POST'},
            'disable' :{url: iServiceConfig.idataHost + "/web/supplier/disable", method: 'POST'},
            'enable' :{url: iServiceConfig.idataHost + "/web/supplier/enable", method: 'POST'},
            
        });

        return service;
    }
})();
