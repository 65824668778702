(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Sku', Sku);

    Sku.$inject = ['$resource', 'iServiceConfig'];

    function Sku ($resource, iServiceConfig) {
        var service = $resource('web/sku', {}, {
            'skuList': { url: iServiceConfig.idataHost + "/web/sku/skulist", method: 'POST'},
            'skuDetail': {url: iServiceConfig.idataHost + "/web/sku/skudetail", method: 'POST'},
            'skuSaveNew': {url: iServiceConfig.idataHost + "/web/sku/skusavenew", method: 'POST'},
            'skuUpdata': {url: iServiceConfig.idataHost + "/web/sku/skuupdata", method: 'POST'},
            'skuDel': {url: iServiceConfig.idataHost + "/web/sku/skudel", method: 'POST'},
            'skuChangeBar': {url: iServiceConfig.idataHost + "/web/sku/skuchangebar", method: 'POST'},
            'skuChangePack': {url: iServiceConfig.idataHost + "/web/sku/skuchangepack", method: 'POST'},
            'skuCheckCode': {url: iServiceConfig.idataHost + "/web/sku/skucheckcode", method: 'POST'},
            'barDetail': {url: iServiceConfig.idataHost + "/web/sku/bardetail", method: 'POST'},
            'barSaveNew': {url: iServiceConfig.idataHost + "/web/sku/barsavenew", method: 'POST'},
            'barUpdata': {url: iServiceConfig.idataHost + "/web/sku/barupdata", method: 'POST'},
            'barDel': {url: iServiceConfig.idataHost + "/web/sku/bardel", method: 'POST'},
            'barCheckCode': {url: iServiceConfig.idataHost + "/web/sku/barcheckcode", method: 'POST'},
            'barList': {url: iServiceConfig.idataHost + "/web/sku/barlist", method: 'POST'},
            'packDetail': {url: iServiceConfig.idataHost + "/web/sku/packdetail", method: 'POST'},
            'packDel': {url: iServiceConfig.idataHost + "/web/sku/packdel", method: 'POST'},
            'packSaveNew': {url: iServiceConfig.idataHost + "/web/sku/packsavenew", method: 'POST'},
            'packUpdata': {url: iServiceConfig.idataHost + "/web/sku/packupdata", method: 'POST'},
            'packCheckCode': {url: iServiceConfig.idataHost + "/web/sku/packcheckcode", method: 'POST'},
            'packList': {url: iServiceConfig.idataHost + "/web/sku/packlist", method: 'POST'},
            'compList': {url: iServiceConfig.idataHost + "/web/sku/compList", method: 'POST'},
            'saveComp': {url: iServiceConfig.idataHost + "/web/sku/saveComp", method: 'POST'},
            'updateComp': {url: iServiceConfig.idataHost + "/web/sku/updateComp", method: 'POST'},
            'delComp': {url: iServiceConfig.idataHost + "/web/sku/delComp", method: 'POST'},
            'saveBom': {url: iServiceConfig.idataHost + "/web/sku/saveBom", method: 'POST'},
            'updateBom': {url: iServiceConfig.idataHost + "/web/sku/updateBom", method: 'POST'},
            'delBom': {url: iServiceConfig.idataHost + "/web/sku/delBom", method: 'POST'},

        });

        return service;
    }
})();
