(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('iEptOrder', {
                parent: 'main',
                url: 'enterprise/order',
                data: {
                    'pageTitle': '业务订单'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'enterprise-module/order/order.html',
                        controller: 'iEptOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
    }
})();
