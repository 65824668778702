(function()  {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptSupplySettlementDetailController', iEptSupplySettlementDetailController);

    iEptSupplySettlementDetailController.$inject = ['$rootScope', 'enums', 'SupplySettlement', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function iEptSupplySettlementDetailController ($rootScope, enums,  SupplySettlement, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.param = {};
        vm.processing = false;
        vm.recordStatus = enums('ship.SOPayStatus');
        vm.supplySettlementStatus = enums('enterprise.SupplySettlementStatus');
        function pageTitle() {
            return $window.document.title;
        }
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				return vm.supplySettlement.records;
			}
		});

        vm.getDetail = getDetail;
		function getDetail() {
			SupplySettlement.detail({},vm.param,function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	vm.supplySettlement = resp.content;
                	vm.supplySettlement.readonly = vm.supplySettlement.status != 0;
                	vm.supplySettlement.settlePanelRecord=true;
                	vm.supplySettlement.settlePanel=true;
                	vm.tableParams.reload();
                }
            });
		}
		vm.cancel = function() {
			SupplySettlement.cancel({},{settlementNum: vm.supplySettlement.settlementNum},function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	toastr.success("取消成功！");
                	vm.getDetail();
                }
                vm.processing = false;
            }, function(err) {
            	console.log("err", err);
            	toastr.error(err.data.errorMsg);
            	vm.processing = false;
            });
		}
		vm.settled = function() {
			var ssd = vm.supplySettlement;
			vm.processing = true;
			var sss = "";
			if (!ssd.ownerName || ssd.ownerName.trim() == '') {
				sss = sss + "请输入货主名称, "
			}
			if (!ssd.supplierCode || ssd.supplierCode.trim() == '') {
				sss = sss + "请输入供应商编码, "
			}
			if (!ssd.supplierName || ssd.supplierName.trim() == '') {
				sss = sss + "请输入供应商名称, "
			}
			if (!ssd.settleMethod || ssd.settleMethod.trim() == '') {
				sss = sss + "请输入结算方式, "
			}
			
			if (sss != "") {
				toastr.error("结算单："+ ssd.settlementNum+" 错误："+ sss);
				vm.processing = false;
        		return;
			}
			var subparam = {
					id: vm.supplySettlement.id,
					settlementNum: vm.supplySettlement.settlementNum,
					ownerName: vm.supplySettlement.ownerName,
					supplierCode: vm.supplySettlement.supplierCode,
					supplierName: vm.supplySettlement.supplierName,
					settleMethod: vm.supplySettlement.settleMethod,
					adjustAmount: vm.supplySettlement.adjustAmount,
					adjustRemark: vm.supplySettlement.adjustRemark,
					remark: vm.supplySettlement.remark
			}
			
		    
			SupplySettlement.settle({},subparam,function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	toastr.success("确认结算成功！");
                	vm.getDetail();
                }
                vm.processing = false;
            }, function(err) {
            	console.log("err", err);
            	toastr.error(err.data.errorMsg);
            	vm.processing = false;
            });
		}
		vm.adjust = function() {
			var ssd = vm.supplySettlement;
			vm.processing = true;
			var sss = "";
			if (!ssd.ownerName || ssd.ownerName.trim() == '') {
				sss = sss + "请输入货主名称, "
			}
			if (!ssd.supplierCode || ssd.supplierCode.trim() == '') {
				sss = sss + "请输入供应商编码, "
			}
			if (!ssd.supplierName || ssd.supplierName.trim() == '') {
				sss = sss + "请输入供应商名称, "
			}
			if (!ssd.settleMethod || ssd.settleMethod.trim() == '') {
				sss = sss + "请输入结算方式, "
			}
			
			if (sss != "") {
				toastr.error("结算单："+ ssd.settlementNum+" 错误："+ sss);
				vm.processing = false;
        		return;
			}
			var subparam = {
					id: vm.supplySettlement.id,
					settlementNum: vm.supplySettlement.settlementNum,
					ownerName: vm.supplySettlement.ownerName,
					supplierCode: vm.supplySettlement.supplierCode,
					supplierName: vm.supplySettlement.supplierName,
					settleMethod: vm.supplySettlement.settleMethod,
					adjustAmount: vm.supplySettlement.adjustAmount,
					adjustRemark: vm.supplySettlement.adjustRemark,
					remark: vm.supplySettlement.remark
			}
			SupplySettlement.adjust({},subparam,function(resp) {
                if (resp.status == 'ERROR') {
                	toastr.error(resp.errorMsg);
                } else {
                	toastr.success("调整结算单信息成功！");
                	vm.getDetail();
                }
                vm.processing = false;
            }, function(err) {
            	console.log("err", err);
            	toastr.error(err.data.errorMsg);
            	vm.processing = false;
            });
		}

        if ($state.params.settlementNum && $state.params.settlementNum != '') {
        	vm.param.settlementNum = $state.params.settlementNum;
        	vm.getDetail();
        }
    }
})();
