(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('ExpiryInventory', ExpiryInventory);

    ExpiryInventory.$inject = ['$resource', 'iServiceConfig'];

    function ExpiryInventory ($resource, iServiceConfig) {
        var service = $resource('web/expiryInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/expiryInventory/list", method: 'POST'},
        });
        return service;
    }
})();
