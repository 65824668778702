(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('ShipOrderDetail', ShipOrderDetail);

    ShipOrderDetail.$inject = ['$resource', 'iServiceConfig'];

    function ShipOrderDetail ($resource, iServiceConfig) {
        var service = $resource('web/soDetail', {}, {
            'list': {url: iServiceConfig.iwhShipHost + "/web/soDetail/list", method: 'POST'},
            'listDetail': {url: iServiceConfig.iwhShipHost + "/web/soDetail/listDetail", method: 'POST'},
            'save': {url: iServiceConfig.iwhShipHost + "/web/soDetail/save", method: 'POST'},
            'addInventoryToSo': {url: iServiceConfig.iwhShipHost + "/web/soDetail/addInventoryToSo", method: 'POST'},
            'allocateInventoryToSoLine': {url: iServiceConfig.iwhShipHost + "/web/soDetail/allocateInventoryToSoLine", method: 'POST'},
            'createFromPick': {url: iServiceConfig.iwhShipHost + "/web/soDetail/createFromPick", method: 'POST'},
            'delete': {url: iServiceConfig.iwhShipHost + "/web/soDetail/delete", method: 'POST'},
            'update': {url: iServiceConfig.iwhShipHost + "/web/soDetail/update", method: 'POST'},
            'detail': {url: iServiceConfig.iwhShipHost + "/web/soDetail/detail/:soNum/:soLineNum", params: {}, method: 'GET'}
        });

        return service;
    }
})();
