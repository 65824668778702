(function () {
    'use strict';
    var app = angular.module('iwh-receiptModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('pcScanReceive', {
            parent: 'main',
            url: 'iwh-receipt/pcScanReceive?isNoAsnReceive=&isBatchReceive=',
            params: {

            },
            data: {
                'pageTitle': '扫描收货',
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-receipt-module/pcScanReceive/pcScanReceive.html',
                    controller: 'PcScanReceiveController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

