(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Owner', Owner);

    Owner.$inject = ['$resource', 'iServiceConfig'];

    function Owner ($resource, iServiceConfig) {
        var service = $resource('web/owner', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/owner/list", method: 'POST'},
            'delete': {url: iServiceConfig.idataHost + "/web/owner/delete", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/owner/detail", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/owner/save", method: 'POST'},
        });

        return service;
    }
})();
