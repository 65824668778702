(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateAppModule', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/appmodule', {}, {
                'getAppModuleList': {
                    url: iServiceConfig.iergateHost + '/web/appmodule/getAppModuleList',
                    method: 'GET'
                },
                'updateOrSaveAppModule': {
                    url: iServiceConfig.iergateHost + '/web/appmodule/updateOrSaveAppModule',
                    method: 'POST'
                },
                'deleteAppModule': {
                    url: iServiceConfig.iergateHost + '/web/appmodule/deleteAppModule',
                    method: 'POST'
                },
                'applyConfigChange': {
                    url: iServiceConfig.iergateHost + '/web/appmodule/applyConfigChange',
                    method: 'GET'
                }
            });
        }]);
})();