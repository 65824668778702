(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusProcessRunning', iBusProcessRunning);

    iBusProcessRunning.$inject = ['$resource', 'iServiceConfig'];

    function iBusProcessRunning ($resource, iServiceConfig) {
        var service = $resource('web/run/ps', {}, {
            'list': { url: iServiceConfig.ibusHost + "/web/run/ps/list", method: 'POST'},
            'cancel': { url: iServiceConfig.ibusHost + "/web/run/ps/cancel", method: 'POST'},
            'runningCount': { url: iServiceConfig.ibusHost + "/web/run/ps/runningCount", method: 'POST'},
            'cancelAll': { url: iServiceConfig.ibusHost + "/web/run/ps/cancel-all", method: 'GET'},
            'cancelSelect': { url: iServiceConfig.ibusHost + "/web/run/ps/cancel-select", method: 'POST'}
        });

        return service;
    }
})();
