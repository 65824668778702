(function() {
    'use strict';

    angular
        .module('iwh-strategyModule')
        .factory('StrategySnapshotRule', StrategySnapshotRule);

    StrategySnapshotRule.$inject = ['$resource', 'iServiceConfig'];

    function StrategySnapshotRule ($resource, iServiceConfig) {
        var service = $resource('web/snapshot-rule', {}, {
            'detail': { url: iServiceConfig.iwhStrategyHost + "/web/snapshot-rule/detail", method: 'GET'},
            'start': { url: iServiceConfig.iwhStrategyHost + "/web/snapshot-rule/start", method: 'POST'},
            'stop': {url: iServiceConfig.iwhStrategyHost + "/web/snapshot-rule/stop", method: 'GET'}
        });
        return service;
    }
})();
