(function () {
    'use strict';

    angular.module('idataModule').controller('BomController',
        BomController);

    BomController.$inject = ['Bom', 'NgTableParams', '$uibModal',
        '$timeout', 'toastr', '$state', 'enums'];

    function BomController(Bom, NgTableParams, $uibModal, $timeout, toastr, $state, enums) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.active = 0;
        vm.search = search;
        vm.removeTab = removeTab;

        vm.bomCreate = bomCreate;

        vm.bomEdit = bomEdit;

        vm.bomDetail = bomDetail;

        vm.bomDel = bomDel;

        vm.confirmOp = confirmOp;
        vm.bomTypeEnums = enums('data.SkuBomType');
		vm.bomType= vm.bomTypeEnums.list('name', 'id');
//--------------------------------------------------

        // 通用方法
        function search() {
            vm.tableParams.reload();

        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        //列表页功能
        vm.tableParams = new NgTableParams(
            {
                count: 10
            },
            {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count(),
                        orderBy: 'bomCode'
                    }, params.filter());
                    return Bom.bomList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
            });

        vm.ok = ok;
        function ok(item) {
            console.log(item);
        }

        //查看商品详细
        function bomDetail(row) {
            Bom.detailsBom({}, {id: row.id, skuCode: row.skuCode, ownerCode: row.ownerCode},
                function (resp) {
                    var sk = resp.content
                    var entity = {
                        skuTemplate: "sku-skudetail.html",
                        barTemplate: "sku-bardetail.html",
                        packTemplate: "sku-packview.html",
                        packHtml: "sku-packhead.html",
                        packBody: "sku-packdetail.html",
                        heading: '查看' + row.name,
                        contentId: "sku-edit.html",
                        data: {
                            sku: sk,
                        },
                        active: true
                    };

                    vm.tabs.push(entity);
                    $timeout(function () {
                        vm.active = vm.tabs.length;
                        entity.active = 0;
                    }, 0);
                });
        }

        //编辑商品
        function bomEdit(row) {
            Bom.detailsBom({},
                {
                    skuCode: row.skuCode,
                    ownerCode: row.ownerCode
                },
                function (resp) {
                    var bom = resp.content;
                    console.log(bom);
                    var entity = {
                        heading: '编辑' + bom.skuName,
                        contentId: "bom-edit.html",
                        data: {
                            bom: bom
                        },
                        tableCompParams: new NgTableParams({count: 10}, {
                            getData: function (params) {
                                var postParams = angular.merge({
                                    bomCode: entity.data.bom === null ? null : entity.data.bom.bomCode,
                                    pn: params.page(),
                                    ps: params.count(),
                                }, params.filter());
                                vm.postParams = postParams;
                                return Bom.compList({}, postParams,
                                    function (resp) {
                                        params.total(resp.content.tc);
                                    }).$promise.then(function (data) {
                                    var datas = data.content.datas;
                                    console.info(datas);
                                    return datas;
                                });
                            }
                        }),
                        newBom: false,
                        active: true
                    };
                    vm.tabs.push(entity);
                    $timeout(function () {
                        vm.active = vm.tabs.length;
                        entity.active = 0;
                    }, 0);
                });

        }

        function bomDel(row) {
            vm.confirmOp("确认要删除该商品的BOM配置?",function () {
                Bom.delBom({},{id:row.id},function (resp) {
                    if (resp.content){
                        toastr.success("删除成功!","成功");
                        search();
                    }
                })
            })
        }

        //新建商品
        function bomCreate() {
            var data = {
                sku: {},
                bom: {
                    type: 10,
                    compList: []
                }
            };
            var entity = {
                heading: '新建',
                contentId: "bom-edit.html",
                data: data,
                tableCompParams: new NgTableParams({count: 10}, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            bomCode: entity.data.bom === null ? null : entity.data.bom.bomCode,
                            pn: params.page(),
                            ps: params.count(),
                        }, params.filter());
                        vm.postParams = postParams;
                        return Bom.compList({}, postParams,
                            function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            console.info(datas);
                            return datas;
                        });
                    }
                }),
                newBom: true,
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                entity.active = 0;
            }, 0);
        }

        // 确认操作封装
        function confirmOp(p, func) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'confirm-op.html',
                controller: confirmOperation,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        op: p
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                return func();
            });
        }

        confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                $uibModalInstance.close(true);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


        //==============BOM商品操作===============//

        vm.addComp = addComp;
        vm.editComp = editComp;
        vm.delComp = delComp;

        vm.saveBom = saveBom;
        vm.updateBom = updateBom;

        //添加构件
        function addComp(tab) {
            console.info(tab);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'comp-op.html',
                controller: bomModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        tab: tab,
                        comp: {ownerCode:tab.data.bom.ownerCode},
                        newComp: true
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {

            });
        }

        //编辑构件
        function editComp(tab, row, index) {
            console.log("编辑构件");
            console.info(row);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'comp-op.html',
                controller: bomModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        index: index,
                        tab: tab,
                        comp: row,
                        newComp: false
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {

            });
        }

        //删除构件
        function delComp(tab, row) {
            vm.confirmOp("确认要删除该构件?", function () {
                Bom.delComp({}, {id: row.id}, function (resp) {
                    if (resp.content > 0) {
                        toastr.success("删除成功!", "成功");
                        tab.tableCompParams.reload();
                    }
                })
            })
        }

        bomModal.$inject = ['$uibModalInstance', 'item', 'toastr'];
        function bomModal($uibModalInstance, item, toastr) {
            var $ctrl = this;
            //静态编辑,保存到数据库
            $ctrl.item = item;
            //保存到数据库
            $ctrl.save = save;
            $ctrl.update = update;

            function save(form) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                if ($ctrl.item.comp.sku.code === $ctrl.item.tab.data.bom.skuCode) {
                    toastr.error("BOM商品不能选择以自己为构件!", "错误");
                    return
                }
                var temp = {
                    skuCode: $ctrl.item.comp.sku.code,
                    skuName: $ctrl.item.comp.sku.name,
                    ownerCode: $ctrl.item.tab.data.bom.ownerCode,
                    bomCode: $ctrl.item.tab.data.bom.bomCode,
                    ownerName: $ctrl.item.tab.data.bom.ownerName,
                    compCode: $ctrl.item.comp.compCode,
                    compName: $ctrl.item.comp.compName,
                    compPriority: $ctrl.item.comp.compPriority,
                    compQty: $ctrl.item.comp.compQty,
                    processStep: $ctrl.item.comp.processStep,
                    processStepName: $ctrl.item.comp.processStepName,
                    
                    qty: $ctrl.item.comp.qty
                };
                Bom.saveComp({}, temp, function (resp) {
                    if (resp.content) {
                        toastr.success("新增成功", "成功");
                        $ctrl.item.tab.tableCompParams.reload();
                        $uibModalInstance.close($ctrl.item);
                    }
                });
            }

            function update(form) {
                console.log("更新数据库");
                console.info($ctrl.item.comp);
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
                $ctrl.item.comp.skuCode = $ctrl.item.comp.sku.code;
                $ctrl.item.comp.skuName = $ctrl.item.comp.sku.name;
                Bom.updateComp({}, $ctrl.item.comp, function (resp) {
                    if (resp.content) {
                        toastr.success("修改成功", "成功");
                        $ctrl.item.tab.tableCompParams.reload();
                    }
                });
                $ctrl.item.tab.tableCompParams.reload();
                $uibModalInstance.close($ctrl.item);
            }
        }

        //添加BOM商品
        function saveBom(t, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            console.log("开始保存BOM");
            console.info(t.data.bom);
            var temp = {
                skuCode: t.data.sku.code,
                skuName: t.data.sku.name,
                ownerCode: t.data.sku.ownerCode,
                ownerName: t.data.sku.ownerName,
                bomCode: t.data.bom.bomCode,
                baseQty: t.data.bom.baseQty,
                type: t.data.bom.type,
                description: t.data.bom.description,
                processStep: t.data.bom.processStep,
                processStepName: t.data.bom.processStepName,
                workType: t.data.bom.workType,
                workEquipment: t.data.bom.workEquipment
            };
            Bom.saveBom({}, temp, function (resp) {
                if (resp.content) {
                    toastr.success("添加BOM成功", "成功");
                    t.newBom = false;
                    Bom.detailsBom({},
                        {
                            skuCode: t.data.sku.code,
                            ownerCode: t.data.sku.ownerCode
                        },
                        function (resp) {
                            t.data.bom = resp.content;
                            console.log("完成保存BOM");
                            console.info(t.data.bom);
                        });
                }
            })
        }

        //更新BOM商品
        function updateBom(t, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            Bom.updateBom({}, t.data.bom, function (resp) {
                if (resp.content) {
                    toastr.success("更新BOM成功", "成功");
                }
            })
        }

        //删除BOM商品
    }
})();
