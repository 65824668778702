(function () {
    'use strict';
    angular.module('idashboardModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('3dLoc', {
                parent: 'main',
                params:{
                    whCode:null,
                    zoneCode:null,
                    whName:null,
                    zoneName:null
                },
                url: 'dashboard/3dLoc?whCode&zoneCode&whName&zoneName&locCode',
                data: {
                    'pageTitle': '3D仓库——库位页面'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'dashboard-module/3dInventory/3dLoc.html',
                        controller: 'ThreeDLocController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();