(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptShipOrderController', iEptShipOrderController);

        iEptShipOrderController.$inject = ['iEptShipOrder', 'NgTableParams', '$timeout', 'toastr', 'enums', '$uibModal'];

    function iEptShipOrderController(iEptShipOrder, NgTableParams, $timeout, toastr, enums, $uibModal) {
        //-----------------------------------------------------------------------//
        //----------------FIELD---------------------------FIELD------------------//
        //-----------------------------------------------------------------------//
        var vm = this;
        vm.modalInstance = null;
        vm.tabs = [];
        vm.search = search;
        vm.orderStatus = enums("enterprise.ShipOrderStatus").list("title", "id");
        vm.orderStatusEnum = enums("enterprise.ShipOrderStatus");

        vm.orderTypeList = [
            {id: 100, title: "销售出库"},
            {id: 101, title: "销售B2B出库"},
            {id: 102, title: "销售B2C出库"},
            {id: 110, title: "租用出库"},
            {id: 130, title: "退货出库"},
            {id: 140, title: "报废出库"},
            {id: 500, title: "生产领料"},
            {id: 400, title: "采购退货"},
            {id: 300, title: "调拨出库"},
            {id: 200, title: "其他出库"},
            {id: 120, title: "领用出库"}
        ];
        vm.orderTypeMap = {100:"销售出库",
                            101:"销售B2B出库",
                            102:"销售B2C出库",
                            110:"租用出库",
                            130:"退货出库",
                            140:"报废出库",
                            500:"生产领料",
                            400:"采购退货",
                            300:"调拨出库",
                            200:"其他出库",
                            120:"领用出库"}


        vm.yesNoList = [
            {title:"是", id: 1}, {title:"否", id: 0}
        ];
        vm.yesNoMap = {1:"是", 0:"否"}

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iEptShipOrder.list({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.warehouses = [];
        iEptShipOrder.getWarehouseList({},{}, function(resp) {
            vm.warehouses = resp.content.warehouses;
        });
        vm.removeTab = removeTab;
        vm.refreshTable = refreshTable;
        vm.confirmSelected = confirmSelected;
        vm.confirmOrder = confirmOrder;

        vm.create = create;
        vm.deleteDetailSelected = deleteDetailSelected;
        vm.createDetail = createDetail;
        vm.saveOrder = saveOrder;
        vm.cancelSelected = cancelSelected;
        vm.createEditPage = createEditPage;
        vm.createEditDetailPage = createEditDetailPage;

        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function refreshTable(table) {
            table.reload();
        }

        function search() {
            vm.tableParams.reload();
        }
        vm.pushToTmsSelected = function() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要推送TMS的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("CONFIRMED") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【已确认】才能被推送TMS", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptShipOrder.pushToTmsBatch({}, ids, function (resp) {
                toastr.success("推送TMS成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("推送TMS失败", "错误");
            });
        }
        vm.pushToWmsSelected = function() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要推送WMS的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("PLANED") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【已计划】才能被推送WMS", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptShipOrder.pushToWmsBatch({}, ids, function (resp) {
                toastr.success("推送WMS成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("推送WMS失败", "错误");
            });
        }
        function cancelSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要确认的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【新建】才能被取消", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptShipOrder.cancelBatch({}, ids, function (resp) {
                toastr.success("取消成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("取消失败", "错误");
            });
        }
        function confirmSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("请选择需要确认的订单", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("所选订单状态为【新建】才能被确认", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptShipOrder.confirmBatch({}, ids, function (resp) {
                toastr.success("确认成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("确认失败", "错误");
            });
        }
        function confirmOrder(entity) {
            iEptShipOrder.confirm({id: entity.data.id}, {}, function (resp) {
                toastr.success("确认成功", "成功");
                iEptShipOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("确认失败", "错误");
            });
        }
        vm.pushToTms = function(entity) {
            iEptShipOrder.pushToTms({id: entity.data.id}, {}, function (resp) {
                toastr.success("推送TMS成功", "成功");
                iEptShipOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("推送TMS失败", "错误");
            });
        }
        vm.pushToWms = function(entity) {
            iEptShipOrder.pushToWms({id: entity.data.id}, {}, function (resp) {
                toastr.success("推送WMS成功", "成功");
                iEptShipOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("推送WMS失败", "错误");
            });
        }
        vm.cancelOrder = function(entity) {
            iEptShipOrder.cancel({id: entity.data.id}, {}, function (resp) {
                toastr.success("取消订单成功", "成功");
                iEptShipOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("取消订单失败", "错误");
            });
        }
        function deleteDetailSelected(entity) {
            var paramBeans = entity.orderDetails.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                if (vm.orderStatusEnum.value("NEW") != paramBeans[i].status) {
                    toastr.warning("订单状态为新建才能删除明细", "警告");
                    return;
                }
                ids.push(paramBeans[i].id);
            }
            iEptShipOrder.deleteDetailBatch({}, ids, function (resp) {
                toastr.success("成功删除明细", "提示");
                entity.orderDetails.reload();
                iEptShipOrder.getById({id: entity.data.id}, {}, function (response) {
                    entity.data = response.content;
                });
            }, function (err) {
                toastr.error("删除失败", "错误");
            });
        }
        function saveOrder(entity, tabIndex) {
            if (validateOrder(entity)) {
                entity.data.warehouseCode = entity.data.warehouseObj.code;
                iEptShipOrder.saveHeader({}, entity.data, function (response) {
                    toastr.success((!!entity.data.id ? "更新" : "创建") + "成功");
                    vm.removeTab(tabIndex);
                    vm.createEditPage(response.content);
                }, function (error) {
                    toastr.error("保存失败", "错误");
                });
            }
        }

        function validateOrder(entity) {
            if (!!!entity.data.warehouseObj || !!!entity.data.ownerCode || !!!entity.data.type) {
                toastr.info("信息未填完", "提示");
                return false;
            }
            return true;
        }
        vm.changeOwner = function(model, t) {
            if (model) {
                t.data.ownerCode = model.code;
                t.data.ownerName = model.name;
            } else {
                t.data.ownerVo = null;
            }
        }
        vm.changeCustomer = function(model, t) {
            if (model) {
                if (!t.data.customer.id) {
                    t.data.customer.code= model.code;
                    t.data.customer.name= model.name;
                    t.data.customer.phone= model.phone;
                    t.data.customer.email= model.email;
                    t.data.customer.address= model.address;
                    t.data.customer.receiver= model.receiver;
                    t.data.customer.receiverPhone= model.receiverPhone;
                    t.data.customer.bakAddress= model.bakAddress;
                }
            } else {
                t.data.customerVo = null;
            }
        }

        vm.changeCarrier = function(model, t) {
            if (model) {
                if (!t.data.carrier.id) {
                    t.data.carrier.code= model.code;
                    t.data.carrier.name= model.name;
                    t.data.carrier.phone= model.phone;
                    t.data.carrier.address= model.address;
                    t.data.carrier.carrierContact= model.contact;
                }
            } else {
                t.data.carrierVo = null;
            }
        }
        function create() {
            var entity = {
                heading: '创建订单',
                contentId: "order-edit.html",
                data: {
                    status: 100,
                    type: 100,
                    carrier:{},
                    customer:{}
                },
                active: 0,
                newOrder: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }
        function createEditPage(row) {
            iEptShipOrder.getById({id: row.id}, {}, function (response) {
                var entity = {
                    heading: '订单详情',
                    contentId: "order-edit.html",
                    data: response.content,
                    orderDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {orderNum: response.content.orderNum}, params.filter());
                            return iEptShipOrder.listDetail({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    orderLogDetails: new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, {orderNum: response.content.orderNum}, params.filter());
                            return iEptShipOrder.listLog({}, postParams,
                                function (resp) {
                                    params.total(resp.content.tc);
                                    return resp.content.datas;
                                }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    active: 0,
                    newOrder: false
                };
                entity.data.warehouseObj = {code: entity.data.warehouseCode}
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);

            }, function (error) {
                toastr.error("加载失败", "错误");
            });
        }

        function createEditDetailPage(entity, row) {
            iEptShipOrder.getDetailById({ id: row.id }, {}, function (resp) {
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'order-detail-edit.html',
                    controller: detailController,
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        item: {
                            tab: entity,
                            orderDetail: resp.content,
                            newDetail: false,
                            readOnly: entity.data.status != vm.orderStatusEnum.value("NEW"),
                        }
                    }
                });
                vm.modalInstance.result.then(function (item) {
                    item.tab.orderDetails.reload();
                });
            });

        }

        function createDetail(entity) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'order-detail-edit.html',
                controller: detailController,
                controllerAs: '$ctrl',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    item: {
                        tab: entity,
                        orderDetail: { },
                        newDetail: true,
                        readOnly: false
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                item.tab.orderDetails.reload();
            });
        }
        //-----------------------------------------------------------------------//
        //---------newDetailController------------newDetailController------------//
        //-----------------------------------------------------------------------//
        detailController.$inject = ['$uibModalInstance', 'item', 'iEptShipOrder', 'toastr'];

        function detailController($uibModalInstance, item, iEptShipOrder, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.cancel = cancel;
            $ctrl.save = save;

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }

            function save(form) {
                if (!$ctrl.item.orderDetail.skuCode || !$ctrl.item.orderDetail.packCode || !$ctrl.item.orderDetail.orderQty) {
                    toastr.info("信息未填完", "提示");
                    return;
                }
                var detail = $ctrl.item.orderDetail;
                detail.skuName = $ctrl.item.skuObj.name;
                detail.uomBaseQty = 1;
                detail.uom = 'EA';
                detail.orderId = $ctrl.item.tab.data.id;

                console.log(detail);
                iEptShipOrder.saveDetail({}, detail, function (response) {
                    toastr.success("保存成功", "提示");
                    $uibModalInstance.close($ctrl.item);
                    iEptShipOrder.getById({id: $ctrl.item.tab.data.id}, {}, function (response) {
                        $ctrl.item.tab.data = response.content;
                        $ctrl.item.tab.data.warehouseObj = {code: $ctrl.item.tab.data.warehouseCode}
                    });
                }, function (error) {
                    toastr.error("保存失败", "错误");
                    return;
                })
            }
        }
    }
})();
