(function () {
    'use strict';
    angular.module('iergateModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('ergateSystemLog', {
                parent: 'main',
                url: 'ergate/systemlog',
                data: {
                    'pageTitle': '系统日志'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'ergate-module/systemlog/systemlog.html',
                        controller: 'ErgateSystemLogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();

