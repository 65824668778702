(function () {
    'use strict';

    angular.module('itms-dataModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('tms-carrier-maintenance', {
                parent: 'main',
                url: 'tms-data/carrier-maintenance?ownerCode',
                params: {'ownerCode': null},
                data: {
                    'pageTitle': '承运商维护',
                    'ownerCode' : null,
                },
                views: {
                    'mainContent': {
                        templateUrl: 'tms-data-module/carrier/carrier.html',
                        controller: 'TmsCarrierController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
