(function () {
    'use strict';

    angular.module('itms-dataModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('tms-fleet-maintenance', {
                parent: 'main',
                url: 'tms-data/fleet-maintenance?ownerCode',
                params: {'ownerCode': null},
                data: {
                    'pageTitle': '车队维护',
                    'ownerCode' : null,
                },
                views: {
                    'mainContent': {
                        templateUrl: 'tms-data-module/fleet/fleet.html',
                        controller: 'TmsFleetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
