(function() {
    'use strict';

    angular
        .module('ireportModule')
        .factory('RptReportConfig', RptReportConfig);

    RptReportConfig.$inject = ['$resource', 'iServiceConfig'];

    function RptReportConfig ($resource, iServiceConfig) {
        var service = $resource('web/reportConfig', {}, {
            'list': { url: iServiceConfig.ireportHost + "/web/reportConfig/list", method: 'POST'},
            'del': { url: iServiceConfig.ireportHost + "/web/reportConfig/del", method: 'POST'},
            'save': {url: iServiceConfig.ireportHost + "/web/reportConfig/save", method: 'POST'},
        });
        return service;
    }
})();
