(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('WaveController', WaveController);

    WaveController.$inject = ['$rootScope', '$timeout','ApiURI','Wave','ShipOrder','ShipOrderDetail','PickDetail','PickOrder','iServiceConfig', 'Box','BaseCode', 'NgTableParams','$uibModal','enums','toastr','Principal'];

    function WaveController ($rootScope, $timeout, ApiURI,Wave, ShipOrder,ShipOrderDetail,PickDetail,PickOrder,iServiceConfig,Box,BaseCode, NgTableParams,$uibModal,enums,toastr,Principal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;
        vm.userData = {};
        vm.containerGroupHost = iServiceConfig.iwhDataHost + ApiURI.getContainerGroupList;
        vm.stationHost = iServiceConfig.itmsDataHost + "/web/station/list";

        vm.WaveStatusObj = enums('ship.WaveStatus');
        vm.WaveStatus = vm.WaveStatusObj.list('title', 'id');

        vm.PickOrderStatusObj = enums('ship.PickOrderStatus');
        vm.PickOrderStatus = vm.PickOrderStatusObj.list('title', 'id');

        vm.WaveTypeObj = enums('ship.WaveType');
        vm.WaveTypeList = vm.WaveTypeObj.list('title', 'id');

        vm.PickMethodObj = enums('ship.PickMethod');
        vm.PickMethod = vm.PickMethodObj.list('title', 'id');

        vm.PrintStatusObj = enums('ship.PrintStatus');
        vm.PrintStatus = vm.PrintStatusObj.list('title', 'id');

        vm.BoxStatusObj = enums('ship.BoxStatus');
        vm.BoxStatus = vm.BoxStatusObj.list('title', 'id');
        
        vm.PickTypeObj = enums('ship.PickType');
        vm.PickType = vm.PickTypeObj.list('title', 'id');
        
        vm.pickDetailStatus = enums('ship.PickDetailStatus');
        vm.stockoutStatus = enums('ship.StockOutStatus');
        
        vm.pickDetailStatusList = vm.pickDetailStatus.list('title', 'id');
        
        vm.stockoutStatusList = vm.stockoutStatus.list('title', 'id');

        vm.lackShip = enums('ship.LackShip');
        vm.lackShipOptions = vm.lackShip.list('name', 'id');
        vm.soStatus = enums('ship.SOStatus');
        vm.soPayStatus = enums('ship.SOPayStatus');
        vm.soPayStatusOptions = vm.soPayStatus.list('name', 'id');
        vm.soDetailStatus = enums('ship.SODetailStatus');
        vm.pickDetailPickType = enums('ship.PickDetailPickType');
       
        vm.blockStatus = enums('ship.BlockStatus');
       
        vm.soOpStatus = enums('ship.SOOpStatus');
        vm.processStatus = enums('ship.ProcessStatus');
        vm.allocateTypes = enums('ship.SoAllocateType').list('name', 'id');;

        vm.orderSource = enums('ship.ComeFrom');
        vm.comeFroms = vm.orderSource.list('name', 'id');

        vm.baseCode = BaseCode.codes([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TRANSPORT_METHOD', keyField: 'WMS_SO_TRANSPORT_METHOD'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_PAY_METHOD', keyField: 'WMS_SO_PAY_METHOD'}
        ]);

		vm.baseCodeObjs = BaseCode.codeObjs([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TRANSPORT_METHOD', keyField: 'WMS_SO_TRANSPORT_METHOD'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_PAY_METHOD', keyField: 'WMS_SO_PAY_METHOD'}
		]);
                                    
        vm.soTypeList = [];
        vm.hasSoType = false;                        
	    vm.baseCodeObjs('WMS_SO_TYPE').then(function(data) {
	    	angular.forEach(data, function (item) {
	    		vm.soTypeList.push({ title: item.name, id: item.code });
	        });
	    	vm.hasSoType = true;
	    });
        
        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });
        vm.baseCode('WMS_SO_TRANSPORT_METHOD').then(function(data) {
            vm.transportMethods = data;
        });
        vm.baseCode('WMS_SO_PAY_METHOD').then(function(data) {
            vm.payMethods = data;
        });
        vm.payMethodList = [];
        vm.baseCodeObjs('WMS_SO_PAY_METHOD').then(function(data) {
	    	angular.forEach(data, function (item) {
	    		vm.payMethodList.push({ title: item.name, id: item.code });
	        });
	    });
        
        
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return Wave.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        vm.buildParam = function buildParam(waveNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (waveNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    waveNum : waveNum
                };
            } else {
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    param = [];
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            waveNum : list[i].waveNum
                        });
                    }
            	}
            }
            return param;
        }

        vm.search = function(){
            vm.tableParams.reload();
        }
        vm.cancel = function(wave, t) {
            Wave.cancel({waveNum: wave.waveNum}, {}, function(resp) {
                if(resp.content=="success"){
                    toastr.success("取消成功");
                    vm.tableParams.reload();
                    t.reload(wave, t);
                }
            });
        }
        vm.allocate = function(wave, t) {
            Wave.alloc({waveNum: wave.waveNum}, {}, function(resp) {
                var reqResult = resp.content;
                vm.tableParams.reload();
                t.reload(wave, t);
                $timeout(function() {
                    var successLen = reqResult.successSoNums.length;
                    var errorLen = reqResult.errorItems.length;
                    var msg = "成功请求分配订单个数： " + successLen + "，失败订单个数：" + errorLen;
                    if (errorLen > 0) {
                        msg = msg + '<br>';
                        angular.forEach(reqResult.errorItems, function (item) {
                            msg = msg + "错误单号：" + item.soNum + "，错误原因：" + item.reason + '<br>';
                        });
                        toastr.warning(msg);
                    } else {
                        toastr.success(msg);
                    }
                });
            });
        }
        vm.cancelAllocate = function(wave, t) {
            Wave.cancelAlloc({waveNum: wave.waveNum}, {}, function(resp) {
                var reqResult = resp.content;
                vm.tableParams.reload();
                t.reload(wave, t);
                $timeout(function() {
                    var successLen = reqResult.successSoNums.length;
                    var errorLen = reqResult.errorItems.length;
                    var msg = "成功请求取消分配的订单个数： " + successLen + "，失败订单个数：" + errorLen;
                    if (errorLen > 0) {
                        msg = msg + '<br>';
                        angular.forEach(reqResult.errorItems, function (item) {
                            msg = msg + "错误单号：" + item.soNum + "，错误原因：" + item.reason + '<br>';
                        });
                        toastr.warning(msg);
                    } else {
                        toastr.success(msg);
                    }
                });
            });
        }
        vm.generatePickOrder = function(wave, t) {
            Wave.generatePko({waveNum: wave.waveNum}, {}, function(resp) {
                var successItems = resp.content.successItems;
                var failItems = resp.content.failItems;
                if (successItems.length === 0 && failItems.length === 0) {
                    toastr.warning("处理了0个装箱，请检查是否存在未拣货的装箱");
                } else {
                    for (var i=0; i<successItems.length; i++) {
                        toastr.success(successItems[i].msg);
                    }
                    for (var i=0; i<failItems.length; i++) {
                        toastr.error(failItems[i].reason);
                    }
                }
            });
        }
        vm.releasePickOrder = function(wave, t) {
            Wave.release({waveNum: wave.waveNum}, {}, function(resp) {
                toastr.success("发放拣选单操作执行完成");
                vm.tableParams.reload();
                t.reload(wave, t);
            });
        }
        vm.deliveryWave = function(wave, t) {
            Wave.ship({waveNum: wave.waveNum}, {}, function(resp) {
                var reqResult = resp.content;
                vm.tableParams.reload();
                t.reload(wave, t);
                $timeout(function() {
                    var successLen = reqResult.successItems.length;
                    var errorLen = reqResult.failItems.length;
                    var msg = "成功发货的订单个数： " + successLen + "，失败订单个数：" + errorLen;
                    if (errorLen > 0) {
                        msg = msg + '<br>';
                        angular.forEach(reqResult.failItems, function (item) {
                            msg = msg + "错误单号：" + item.num + "，错误原因：" + item.reason + '<br>';
                        });
                        toastr.warning(msg);
                    } else {
                        toastr.success(msg);
                    }
                });
            });
        }
        vm.save = function(wave, t) {
            if (!wave.pickOrderRule) {
                toastr.error("请填写 拣选单规则");
                return;
            }
            
            Wave.save({}, wave, function(resp) {
                t.reload(resp.content, t);
            });
        }
        vm.plan = function(wave, t) {
            if (!wave.planHandleTime || !wave.planShipTime) {
                toastr.error("请填写 计划处理时间 和 计划发货时间");
                return;
            }
            Wave.plan({}, wave, function(resp) {
                toastr.success("计划确认成功");
                t.reload(wave, t);
            });
        }
        vm.create = function() {
            var tab = {
                heading: '新建波次单',
                contentId: "waveDetail.html",
                params: {waveNum: null},
                readonly: false,
                data: {
                    status: 0
                },

                soTableParams: undefined,
                soDetailTableParams: undefined,
                boxTableParams: undefined,
                pickDetailTableParams: undefined,
                pickOrderTableParams: undefined,
                pickDetailLackTableParams: undefined
            };
            
            tab.reload = function(waveObj, t) {
                Wave.detail({waveNum: waveObj.waveNum}, {}, function(resp) {
                    t.params = {waveNum: resp.content.waveNum};
                    t.data = resp.content;
                    t.isNew = resp.content.status == vm.WaveStatusObj.value("NEW"),
                    t.soTableParams = _newsoTableParams(t.params);
                    t.soDetailTableParams = _newsoDetailTableParams(t.params);
                    t.boxTableParams = _newboxTableParams(t.params);
                    t.pickDetailTableParams = _newpickDetailTableParams(t.params);
                    t.pickOrderTableParams = _newpickOrderTableParams(t.params);
                    t.pickDetailLackTableParams = _newpickDetailLackTableParams(t.params);
                });
            }
            vm.tabs.push(tab);
            switchTab(vm.tabs.length);
        }
        vm.waveDetail = function(wave) {
            Wave.detail({waveNum: wave.waveNum}, {}, function(resp) {
                var tab = {
                    heading: '编辑波次单',
                    contentId: "waveDetail.html",
                    isNew: resp.content.status == vm.WaveStatusObj.value("NEW"),
                    params: {waveNum: resp.content.waveNum},
                    readonly: false,
                    data: resp.content,
                    reload: undefined,
                };
                tab.reload = function(waveObj, t) {
                    Wave.detail({waveNum: waveObj.waveNum}, function(resp) {
                        t.params = {waveNum: resp.content.waveNum};
                        t.data = resp.content;
                        t.isNew = resp.content.status == vm.WaveStatusObj.value("NEW"),
                        t.soTableParams.reload();
                        t.soDetailTableParams.reload();
                        t.boxTableParams.reload();
                        t.pickDetailTableParams.reload();
                        t.pickOrderTableParams.reload();
                        t.pickDetailLackTableParams.reload();
                    });
                }
                tab.soTableParams = _newsoTableParams(tab.params);
                tab.soDetailTableParams = _newsoDetailTableParams(tab.params);
                tab.boxTableParams = _newboxTableParams(tab.params);
                tab.pickDetailTableParams = _newpickDetailTableParams(tab.params);
                tab.pickOrderTableParams = _newpickOrderTableParams(tab.params);
                tab.pickDetailLackTableParams = _newpickDetailLackTableParams(tab.params);
          
                vm.tabs.push(tab);
                switchTab(vm.tabs.length);
            });
        }
        function _newpickDetailLackTableParams(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.waveNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        postParams.stockoutStatus = 2;
                        this.httpParams = postParams;
                        return PickOrder.pickDetailListPage({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }
        function _newpickOrderTableParams(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.waveNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        this.httpParams = postParams;
                        return PickOrder.list({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }
        function _newsoTableParams(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.waveNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        this.httpParams = postParams;
                        return ShipOrder.list({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }
        function _newsoDetailTableParams(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.waveNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        this.httpParams = postParams;
                        return ShipOrderDetail.listDetail({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }
        function _newboxTableParams(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.waveNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        this.httpParams = postParams;
                        return Box.list({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }
        
        function _newpickDetailTableParams(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.waveNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        this.httpParams = postParams;
                        return PickOrder.pickDetailListPage(postParams, {},
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }
        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
        vm.chooseRemoveSo = function(solist, t) {
            if (!solist || solist.length == 0) {
                toastr.error("请选择需要移除波次的订单");
                return;
            }
            var soNums = [];
            angular.forEach(solist, function(so) {
                soNums.push(so.soNum);
            })
            Wave.removeSos({}, {waveNum: t.data.waveNum, soNums: soNums}, function(resp) {
                toastr.success("移除成功");
                t.reload(t.data, t);
            });
        }
        vm.chooseAddSo = chooseAddSo;
        function chooseAddSo(t) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'add-sos.html',
                controller: addSoModal,
                size: 'xl',
                backdrop: 'static',
                controllerAs: '$ctrl',
                resolve: {
                    item : t
                }
            });
        }
        addSoModal.$inject = ['$uibModalInstance', 'item'];
        function addSoModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.soTypes = vm.soTypes;
            $ctrl.soTypeList = vm.soTypeList;
            $ctrl.orderSource = vm.orderSource;
            $ctrl.soStatus = vm.soStatus;
            $ctrl.blockStatus = vm.blockStatus;
            $ctrl.stockoutStatus = vm.stockoutStatus;
            $ctrl.orderQueryParam = {
                carrierNum: $ctrl.item.data.carrierCode,
                roadline: $ctrl.item.data.roadline,
                plateNum: $ctrl.item.data.carrierPlateNum
            };
            $ctrl.stationHost = vm.stationHost;

            $ctrl.chooseSoTableParams = new NgTableParams({
                count : 10,counts: [10,20,50,100,200,500,1000,2000]
            }, {
                getData : function(params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count()
                    }, params.filter());
                    postParams = angular.merge(postParams, $ctrl.orderQueryParam);
                    return Wave.searchSoForWave({}, postParams, function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
            });

            $ctrl.addSos = function addSos() {
                var solist = $ctrl.chooseSoTableParams.getSelected();
                if (!solist || solist.length == 0) {
                    toastr.error("请选择需要添加到当前波次的订单");
                    return;
                }
                var soNums = [];
                angular.forEach(solist, function(so) {
                    soNums.push(so.soNum);
                })
                Wave.addSos({}, {waveNum: $ctrl.item.data.waveNum, soNums: soNums}, function(resp) {
                    toastr.success("添加成功，你还可以继续添加 或者 关闭当前窗口");
                    $ctrl.item.reload($ctrl.item.data, $ctrl.item);
                    $ctrl.chooseSoTableParams.reload();
                });
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
