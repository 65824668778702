(function () {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusBizLog', iBusBizLog);

    iBusBizLog.$inject = ['$resource', 'iServiceConfig'];

    function iBusBizLog($resource, iServiceConfig) {
        var service = $resource('web/bizLog', {}, {
            'search': {url: iServiceConfig.ibusHost + "/web/bizLog/list", method: 'POST'}
        });

        return service;
    }
})();
