(function() {
    "use strict";
    angular.module("ivendorModule").factory("InventoryInOut", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.vendorHost + '/web/inventoryInout', {}, {
        	'list': { url: iServiceConfig.vendorHost + "/web/inventoryInout/list", method: 'POST'},
            'allwhouse': { url: iServiceConfig.vendorHost + "/web/inventoryInout/allwhouse", method: 'POST'},
            'storeCodes': { url: iServiceConfig.vendorHost + "/web/inventoryInout/storeCodes", method: 'POST'}
        });
    }]);
})();
