(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('PickOrderRule', PickOrderRule);

    PickOrderRule.$inject = ['$resource', 'iServiceConfig'];

    function PickOrderRule ($resource, iServiceConfig) {
        var service = $resource('web/pickOrderRule', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/pickOrderRule/list", method: 'POST'},
            'execute': { url: iServiceConfig.iwhShipHost + "/web/pickOrderRule/execute/:ruleCode", params: {ruleCode: '@ruleCode'}, method: 'POST'}
        });

        return service;
    }
})();
