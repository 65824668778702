(function() {
    'use strict';

    angular
        .module('ireportModule')
        .factory('RptDataSource', RptDataSource);

    RptDataSource.$inject = ['$resource', 'iServiceConfig'];

    function RptDataSource ($resource, iServiceConfig) {
        var service = $resource('web/ds', {}, {
            'list': { url: iServiceConfig.ireportHost + "/web/ds/list", method: 'POST'},
            'detail': { url: iServiceConfig.ireportHost + "/web/ds/detail", method: 'POST'},
            'save': { url: iServiceConfig.ireportHost + "/web/ds/save", method: 'POST'},
            'enable': { url: iServiceConfig.ireportHost + "/web/ds/enable", method: 'POST'},
            'disable': { url: iServiceConfig.ireportHost + "/web/ds/disable", method: 'POST'},
            'nodeList': { url: iServiceConfig.ireportHost + "/web/ds/nodeList", method: 'GET'}
        });

        return service;
    }
})();
