(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleShortcutMenuController', UserModuleShortcutMenuController);

    UserModuleShortcutMenuController.$inject = ['$uibModal','$scope','NgTree','UserModuleUser', 'UserModuleFavMenu', 'toastr'];

    function UserModuleShortcutMenuController( $uibModal, $scope, NgTree, UserModuleUser, UserModuleFavMenu, toastr) {
        var vm = this;
        vm.active = true;
        vm.contentId = "add.html";
        vm.New = true;
        vm.favgroup = {
        			id : null,
        			groupName : "快捷菜单",
        			type: 2,
        			menuDetailBeans : []
        			};
        vm.trees=[];
        vm.reload = false;
        
        vm.saveConfirm=saveConfirm;
        vm.updataConfirm=updataConfirm;
        
        vm.update=update;
        
        vm.editGroup=editGroup;
        
        vm.getUserMenu=getUserMenu;
        vm.userMenus=[];
        
        vm.cancel=cancel;
        
        vm.getSelectObj=getSelectObj;
        vm.getDeleteObj=getDeleteObj;
        vm.clear=false;
// ---------------------------------------------------
        
        vm.editGroup();
        vm.getUserMenu();
        
//  初始页面数据
        function editGroup(){
        	// vm.trees=[];
        	var selsectNode=[];
        	if (!vm.New) {
        		UserModuleFavMenu.favMenuId({},{id : vm.favgroup.id},function(resp){
        			angular.forEach(resp.content, function(data){
        				var id = "3_"+data;
        				selsectNode.push(id);
        			});

        			angular.forEach(vm.trees,function(tree){
        				tree.selectedNodeIds = selsectNode;
        				tree.scReload();
					});
        		},function(resp){
        			toastr.error(resp.data.errorMsg, "错误");
        		});	
			}else{
        		UserModuleUser.menus({}, {}, function(resp) {
        			vm.trees=[];
					angular.forEach(resp.content, function(data) {
        				var tree = new NgTree({
        					data : [data],
        					selectedNodeIds : selsectNode,
        					config : {
        						meta : {
        							titleField : 'menuName',
        							parentIdField : 'parentId',
        							nodeIdField : 'menuId',
        							childField : 'children',
        							needCheck : true
        						}
        					}
        				});
        				
        				vm.trees.push(tree);
        			});
        		}, function(resp) {
        			toastr.error(resp.data.errorMsg, "错误");
        		});
        	}
        }
        
        function getUserMenu(){
        	UserModuleFavMenu.shortcutDetail({}, {}, function(resp) {
        		vm.userMenus=resp.content
        		if (vm.userMenus && vm.userMenus.length > 0) {
        			vm.getSelectObj(vm.userMenus[0]);
        		}
    		}, function(resp) {
    			toastr.error(resp.data.errorMsg, "错误");
    		});
        }
        
// 		菜单操作函数
        
        function getSelectObj(obj){
        	vm.New=false;
        	vm.favgroup.id=obj.id;
        	vm.favgroup.groupName=obj.menuName;
        	vm.editGroup();
        }
        function getDeleteObj(obj){
        	if(obj.hasOwnProperty("userId")){
        		var id=obj.id;
        		UserModuleFavMenu.delFavMenuGroup({}, {id : id}, function(resp) {
        			toastr.success("删除成功", "成功");
        			if(vm.favgroup.id == id){
            			vm.cancel();
            		}
        			vm.getUserMenu();
        		}, function(resp) {
        			toastr.error(resp.data.errorMsg, "错误");
        			vm.getUserMenu();
        			return ;
        		});
        	}else{
        		UserModuleFavMenu.delFavMenuDetail({}, obj, function(resp) {
        			toastr.success("删除成功", "成功");
        			vm.getUserMenu();
        			vm.editGroup();
        		}, function(resp) {
        			toastr.error(resp.data.errorMsg, "错误");
        			vm.getUserMenu();
        			return ;
        		});
        	}
        	
        }


        
//     保存确认   
        function saveConfirm(){
        	if(vm.favgroup.groupName==null || vm.favgroup.groupName.trim()==""){
        		toastr.warning("分组名称不能为空", "警告");
        		return ;
        	}
        	vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : {
						   creat : vm.New
						}
					}
				}
			});
        	
        	vm.modalInstance.result.then(function(item) {
				vm.update();
			});
        }
        
        function update(){
        	
        	//树
        	angular.forEach(vm.trees, function(tree) {
        		var nodes= tree.getSelectedNodes();
        		
             //  树选择的节点
        		angular.forEach(nodes, function(node) {
    				if(node.menuId.substring(0,1)=="3" && node.menuUrl != null ){
    					var id = node.menuId.substring(2);
    					var detailBean = {
    							id : null,
    							tenantId : null,
    							menuGroupId : vm.favgroup.id,
    						    authId :  id
    					}
    					vm.favgroup.menuDetailBeans.push(detailBean);
    				}
            		
    			});
        		
			});
        	vm.favgroup.type = 2;
        	UserModuleFavMenu.save({},vm.favgroup,function(){
        		toastr.success("保存成功", "成功");
        		vm.cancel();
        		
        	},function(){
        		toastr.error(resp.data.errorMsg, "错误");
        	});
        }
        
        function updataConfirm(){
        	
        }
        
        function cancel(){
        	vm.New=true;
    		vm.favgroup = {
        			id : null,
        			groupName : "快捷菜单",
        			type:2,
        			menuDetailBeans : []
        			};
    		vm.clear=true;
    		vm.getUserMenu();
    		vm.editGroup();
        }
        
        
//        弹出框
        confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;

			function ok() {
				$uibModalInstance.close($ctrl.item);
			}

			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}
    }
})();
