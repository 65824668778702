(function () {
    'use strict';
    angular.module('idashboardModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('3dTestInventory', {
                parent: 'main',
                url: 'dashboard/3dTestInventory',
                data: {
                    'pageTitle': '3D仓库——仓库页面'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'dashboard-module/3dInventory/3dWarehouse.html',
                        controller: 'ThreeDInventoryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();