(function() {
	'use strict';

	angular.module('iuserModule').controller('UserModuleTenantController',
			UserModuleTenantController);

	UserModuleTenantController.$inject = [ 'UserModuleTenant', 'NgTableParams', '$uibModal',
			'Principal' , '$timeout', 'NgTree', 'toastr'];

	function UserModuleTenantController(UserModuleTenant, NgTableParams, $uibModal, 
			Principal, $timeout, NgTree, toastr) {
		var vm = this;
		vm.modalInstance = null;
		vm.tableParams = null;
		vm.tenantDetail = null;
		vm.tabs = [];
		vm.changeTenantStatus = changeTenantStatus;
		vm.active = 0;
		vm.account = {};
		vm.status = [ /*{'id' : null,'title' : ''},*/
		              {'id' : 0,'title' : '已禁用'},
		              {'id' : 1,'title' : '新建'},
		              {'id' : 10,'title' : '已启用'} 
		            ];
		vm.search = search;
		vm.edit = edit;
		vm.detail = detail;
		vm.create = create;
		vm.update = update;
		vm.createconfirm = createconfirm;
		vm.updateconfirm = updateconfirm;
		vm.changeTenantStatusconfirm = changeTenantStatusconfirm;
		vm.changeEditionStatusconfirm = changeEditionStatusconfirm;
		vm.changeEditionStatus = changeEditionStatus;
		vm.removeTab = removeTab;
		vm.addedition = addedition;
		vm.addeditionsave = addeditionsave;
		vm.addeditionconfirm = addeditionconfirm;
		
		vm.updateSelected=updateSelected;
		vm.updateSelection=updateSelection;
		vm.isSelected=isSelected;
		
		vm.init = initData;
		
		vm.setTabTreeDate=setTabTreeDate;

		vm.editSetup = editSetup;
		vm.saveSetup = saveSetup;

        vm.setup = setup;
        function setup(row) {
			UserModuleTenant.getConfig({}, {tenantId:row.tenantId}, function(resp) {
				}).$promise.then(function(data){
                	var datas = data.content;
                	var nowTime = Date.parse(new Date());//当前时间
                	if(datas === null){
                		datas = {
                            tenantId:row.tenantId,
                            effectiveTime:null,
							serviceTerm:null,
                            maxUserCount:null,
                            maxWarehouseCount:null,
                            maxLocCount:null,
                            maxOwnerCount:null,
                            maxSkuCount:null,
                            maxAsnCount:null,
                            maxSoCount:null,
                            remainingDay:null,//剩余天数
                            remainingHour:null//剩余小时数
						};
					}else{
                		datas.remainingDay = Math.floor((datas.effectiveTime /1000 + datas.serviceTerm * 86400 - nowTime/1000)/86400);
                		if(datas.remainingDay < 0){datas.remainingDay = datas.remainingDay + 1};
                		datas.remainingHour = Math.floor(((datas.effectiveTime /1000 + datas.serviceTerm * 86400 - nowTime/1000)/3600)%24);
                		if(datas.remainingDay<0 && datas.remainingHour<0){
                            datas.remainingHour = -datas.remainingHour;
						}
                	}
                	var entity = {
                		isSet:data.content,
                	    heading : '设置租户',
                	    contentId : "tenant-setup.html",
                	    data : datas,
                	    roles : [],
                	    active : true
                	};
					vm.tabs.push(entity);
					$timeout(function(){
						vm.active = vm.tabs.length;
					},0);
			});
		}
		function editSetup(t) {
			t.isSet = ! t.isSet;
        }
        function saveSetup(t) {
            var nowTime = Date.parse(new Date());//当前时间
            t.data.remainingDay = Math.floor((t.data.effectiveTime /1000 + t.data.serviceTerm * 86400 - nowTime/1000)/86400);
            if(t.data.remainingDay < 0){t.data.remainingDay = t.data.remainingDay + 1};
            t.data.remainingHour = Math.floor(((t.data.effectiveTime /1000 + t.data.serviceTerm * 86400 - nowTime/1000)/3600)%24);
            if(t.data.remainingDay<0 && t.data.remainingHour<0){
                t.data.remainingHour = -t.data.remainingHour;
            }
            UserModuleTenant.saveConfig({}, t.data, function(resp){
                if(resp.status=="SUCCESS") {
                    toastr.success("保存成功");
                    t.isSet = ! t.isSet;
                }
			});
        }
		
		vm.api = null;
        vm.getSelectedId = getSelectedID;
        function getSelectedID(){
            vm.api.getId();
        }

		Principal.identity().then(function(account) {
			vm.account = account;
		});
		
		function initData(row) {
			UserModuleTenant.init({}, {
				tenantId: row.tenantId
            }, function (resp) {
                if(resp.status=="SUCCESS"){
                    toastr.success("初始化任务已开启，请稍后检查初始化数据是否正常");
                    vm.search();
                }
            });
        };
//树的功能
		function setTabTreeDate(treedata,tab){
			return null;
		}
		
//列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return UserModuleTenant.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
		
		function init(row) {
			UserModuleTenant.init({}, {
				tenantId : row.tenantId
			}, function(resp) {
                if(resp.status=="SUCCESS"){
                    toastr.success("初始化任务已开启，请稍后检查初始化数据是否正常");
                }
			});
		};
		
		function changeTenantStatusconfirm(row) {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'changeTenantStatusModal.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : function() {
						return row;
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.changeTenantStatus(item);
			});
		}

		function changeTenantStatus(row) {
			UserModuleTenant.changetenantstatus({}, {
				tenantId : row.tenantId,
				status : row.status
			}, function() {
				vm.search();
			});
		}
//查看租户详细		
		function detail(row) {
			UserModuleTenant.detail({}, {
				tenantId : row.tenantId
			}, function(resp) {
				var data = resp.content
				var entity = {
					heading : '查看' + row.tenantCode + '租户',
					contentId : "tenant-detail.html",
					data : data,
					roles : [],
					active : true
				};
				
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
        		});

		}
//新建租户
		function create() {
			UserModuleTenant.canselledition({}, {}, function(resp) {
				
				var editiondata = resp.content
				var entity = {
					heading : '创建租户',
					contentId : "tenant-add.html",
					data : {
						errorMsg : "",
						tenantId : "",
						tenantCode : "",
						companyName : "",
						companyAddr : "",
						contactName : "",
						contactMobile : "",
						adminLoginName:"admin",
						systemName:"智能供应链管理平台",
						templateTenantId :-100,
						password : "",
						editionList : [],
						editionTreeList : editiondata
					},
					roles : [],
					active : true
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			},function(resp) {
				return;
    		});
		}
		
		function createconfirm(tab, idx, form) {
			
			if(tab.data.editionList.length === 0){
//				tab.errorMsg="未选择需要的版本";
				toastr.warning("请选择需要的版本","警告");
				return;
			}
			
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						idx : idx,
						form : form
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.update(item.tab, item.idx, item.form);
			});
		}
		
	//新建租户选中的版本id
		function  updateSelected(action, id, tab) {
		        if (action == 'add' && tab.data.editionList.indexOf(id) == -1) {
		        	tab.data.editionList.push(id);
		        }
		        if (action == 'remove' && tab.data.editionList.indexOf(id) != -1) {
		            var idx = tab.data.editionList.indexOf(id);
		            tab.data.editionList.splice(idx, 1);
		        }
		    };
		    
		    
		  function updateSelection($event, id, tab) {
		        var checkbox = $event.target;
		        var action = (checkbox.checked ? 'add' : 'remove');
		        updateSelected(action, id, tab);
		    };
		    
		  function isSelected(id, tab) {
		        return tab.data.editionList.indexOf(id) >= 0;
		    };

		
//编辑租户		
		function edit(row) {
			UserModuleTenant.detail({}, {
				tenantId : row.tenantId
			}, function(resp) {
				var data = resp.content
				var entity = {
					heading : '编辑租户',
					contentId : "tenant-edit.html",
					data : data,
					roles : [],
					active : true
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			});
		}

		function changeEditionStatusconfirm(tab,tenantId,edtId,edtName,status) {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'changeTenantEditionStatusModal.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						tenantId : tenantId,
						edtId : edtId,
						edtName : edtName,
						status : status 
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.changeEditionStatus(item);
			});
		}
		function changeEditionStatus(item) {
			var data;
			UserModuleTenant.changeeditionstatus({}, {
				tenantId : item.tenantId,
				edtId : item.edtId,
				status : item.status
			},function(){
				UserModuleTenant.detail({}, {
					tenantId : item.tenantId
				},function(resp){
					item.tab.data = resp.content;
				});
			});
		}
		
		function updateconfirm(tab, idx, form) {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						idx : idx,
						form : form
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.update(item.tab, item.idx, item.form);
			});
		}
		

//编辑租户新添版本弹出框
		function addedition(tab) {
			
			UserModuleTenant.canselledition({}, {
				tenantId : tab.data.tenantId
			}, function(resp) {
				tab.data.stillcanSellEditionTree = resp.content.editionTreeList;
			});
			tab.data.editionList=[];
			vm.modalInstance = $uibModal.open({
				templateUrl : 'addTenantNewEdition.html',
				controller : tenantAddNewEdition,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
					}
				}
			});
           
			vm.modalInstance.result.then(function(item) {
				addeditionconfirm(item);
			});
		}
		function addeditionconfirm(item) {
			vm.modalInstanceconfirm = $uibModal.open({
				templateUrl : 'confirmaddedition.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : item
				}

			});
			vm.modalInstanceconfirm.result.then(function(item) {
				vm.addeditionsave(item);
			});
		}
		function addeditionsave(item) {
			UserModuleTenant.addnewedition({}, {
				tenantId : item.tab.data.tenantId,
				newEditionList : item.tab.data.editionList
			},function(){
				UserModuleTenant.detail({}, {
					tenantId : item.tab.data.tenantId
				},function(resp){
					item.tab.data = resp.content;
				});
			});
		}

		

		

		function update(tab, idx, form) {
			var tenant = tab.data;
            
			
			var valid = form.$valid;
			if (!valid) {
				form.showMsg = true;
				return;
			}

			UserModuleTenant.save({}, tenant, function(resp) {
				vm.removeTab(idx);
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
				return;
			});
		}

		confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;

			function ok() {
				$uibModalInstance.close($ctrl.item);
			}

			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}

		tenantAddNewEdition.$inject = [ '$uibModalInstance', 'item' ];
		function tenantAddNewEdition($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;
			
			$ctrl.updateSelected=updateSelected;
			$ctrl.updateSelection=updateSelection;
			$ctrl.isSelected=isSelected;
			
			function  updateSelected(action, id, tab) {
		        if (action == 'add' && tab.data.editionList.indexOf(id) == -1) {
		        	tab.data.editionList.push(id);
		        }
		        if (action == 'remove' && tab.data.editionList.indexOf(id) != -1) {
		            var idx = tab.data.editionList.indexOf(id);
		            tab.data.editionList.splice(idx, 1);
		        }
		    };
		    
		    function updateSelection($event, id, tab) {
		        var checkbox = $event.target;
		        var action = (checkbox.checked ? 'add' : 'remove');
		        $ctrl.updateSelected(action, id, tab);
		    };
		    
		    function isSelected(id, tab) {
		        return tab.data.editionList.indexOf(id) >= 0;
		    };
		    
			function ok() {
				$uibModalInstance.close($ctrl.item);
			}

			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}

	}
})();
