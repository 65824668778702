(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateTenantData', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/tenantdata', {}, {
                'getTenantDataList': {
                    url: iServiceConfig.iergateHost + '/web/tenantdata/getTenantDataList',
                    method: 'GET'
                },
                'updateOrSaveTenantData': {
                    url: iServiceConfig.iergateHost + '/web/tenantdata/updateOrSaveTenantData',
                    method: 'POST'
                },
                'deleteTenantData': {
                    url: iServiceConfig.iergateHost + '/web/tenantdata/deleteTenantData',
                    method: 'POST'
                }
            });
        }]);
})();