(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('iEptSaleRecordQuery', {
                parent: 'main',
                url: 'enterprise/saleRecordQuery',
                data: {
                    'pageTitle': '企业仓库销售记录查询',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'enterprise-module/sales/saleRecordQuery.html',
                        controller: 'iEptSaleRecordQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
