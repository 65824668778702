(function() {
    'use strict';

    angular.module('idataModule')
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['Customer', 'NgTableParams','$uibModal', 'Principal','$timeout','toastr','$state'];

    function CustomerController(Customer, NgTableParams, $uibModal, Principal,$timeout,toastr,$state) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.confirmDelete = confirmDelete;
        vm.showOwner = showOwner;
        vm.changeStatus=changeStatus;
        vm.deleteData = deleteData;
        vm.changeData = changeData;
        vm.tree = null;
        vm.edit = edit;
        vm.tabs = [];
        vm.active = 0;
        vm.status = [
                      {id : 0, title : "新建"},
                      {id : 10, title : "启用"},
                      {id : -10, title : "禁用"},
                      ];
        /*vm.status = [{'id':null, 'title':'全部'}, {'id':0, 'title':'已禁用'},{'id':1, 'title':'新增'},{'id':10, 'title':'已启用'}];*/
        vm.account = {};
        vm.search = search;
        vm.update = update;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.show =false;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'code'}, params.filter());
                if($state.params.ownerCode!=null) {
                    postParams.ownerCode = $state.params.ownerCode;
                }
                return Customer.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function changeData(row){
        	if(row.status > 1) {
        		Customer.disable({}, {
            		id: row.id
            	},function(resp){
                    if(resp.status=="SUCCESS"){
                        vm.search();
                        toastr.success("禁用成功");
                    }
            	});
        	} else {
        		Customer.enable({}, {
        			id: row.id
            	},function(resp){
                    if(resp.status=="SUCCESS"){
                        vm.search();
                        toastr.success("启用成功");
                    }
            	});
        	}
        }
        function changeStatus(row){
            vm.modalInstance = $uibModal.open({
            	templateUrl: 'supplier-disableAndEnable.html',
                controller: changeModal,
                controllerAs: '$ctrl',
                resolve: {
                		item: function () {
                				return row;
                        	}
                    	}
                });	
            vm.modalInstance.result.then(function(item) {
                vm.changeData(item);
            });
        }
        
        changeModal.$inject = ['$uibModalInstance', 'item'];
        function changeModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
 
        
       function deleteData(row){
        	Customer.delete({}, {id: row.id}, function() {
                vm.search();
            });
        }

        function create() {
        	
	            var entity = {
	                heading: '新建',
	                contentId: "customer-eidt.html",
	                data: {
	                    code:"",
	                    ownerCode:"",
	                    name:"",
	                    englishName:"",
	                    fullName:"",
	                    customerGroup:"",
	                    address :"",
	                    contact:"",
	                    phone:"",
	                    fax:"",
	                    level:"",
	                    type:"",
	                    subType:"",
	                    email:"",
	                    remark:"",
	                    status:0,
	                },
	                active: true
	            };
            
	            vm.tabs.push(entity);
	            $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
        }
        
        function edit(row) {
        	Customer.detail({}, {id: row.id}, function(resp) {
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,"错误");
         			return;
         		}
        		 var data = resp.content;
         			var entity = {
                             heading: '编辑'+data.code,
                             contentId: "customer-eidt.html",
                             data: data,
                             roles: [],
                             
                             active: true
                         };
                 	
             			vm.tabs.push(entity);
             			$timeout(function(){
                             vm.active = vm.tabs.length;
                         },0);
         		});
        }
        
        function showOwner(row){
        	Customer.detail({},{id: row.id},function(resp){
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,'错误');
        			return;
        		}
        		var data = resp.content;
        			var entity = {
                            heading: '查看'+data.name,
                            contentId: "customer-showOwner.html",
                            data: data,
                            roles: [],
                            active: true
                        };
                	
            			vm.tabs.push(entity);
            			$timeout(function(){
                            vm.active = vm.tabs.length;
                        },0);
        		});
        }
        	

         
        function update(tab, idx, form) {
            var customer = tab.data;
            console.log(tab.data);
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }

            customer.code=tab.data.code;
            Customer.save({}, customer, function(resp) {
            	if(resp.status === 'ERROR') {
        			toastr.error("客户编码已经存在，请重新输入",'错误');
        			return;
        		}else{
                    if(customer.id){
                        toastr.success("更新成功！");
                    }else{
                        toastr.success("保存成功！");
                    }
                }
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteData(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
