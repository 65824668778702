(function()  {
    'use strict';

    angular.module('ienterpriseModule')
        .controller('iEptSupplySettlementMgtController', iEptSupplySettlementMgtController);

    iEptSupplySettlementMgtController.$inject = ['$rootScope', 'enums', 'SupplySettlement', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function iEptSupplySettlementMgtController ($rootScope, enums,  SupplySettlement, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.openUrl = "";
        
        vm.recordStatus = enums('ship.SOPayStatus');
        vm.supplySettlementStatus = enums('enterprise.SupplySettlementStatus');

        function pageTitle() {
            return $window.document.title;
        }
        //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
               
				return SupplySettlement.page({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();
		}

        vm.btnSearch = btnSearch;
        function btnSearch() {
            vm.tableParams.reload();
        }
        
        vm.btnGenSettle = btnGenSettle;
        function btnGenSettle() {
        	$state.go("iEptGenSupplySettlement");
        }
        
        vm.edit = function(supplySettlement) {
        	console.log(supplySettlement);
        	vm.openUrl = "#/enterprise/supplySettlementDetail?settlementNum="+supplySettlement.settlementNum;
        	jQuery('#open_new_tab_a')[0].href  = vm.openUrl;
        	jQuery('#open_new_tab_a')[0].click();
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
