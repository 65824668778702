(function () {
    'use strict';

    angular.module('iwh-dataModule').controller('IwhSystemConfigController',
        SystemConfigController);

    SystemConfigController.$inject = ['IwhSystemConfig', 'NgTableParams', '$uibModal',
        '$timeout', 'toastr'];

    function SystemConfigController(SystemConfig, NgTableParams, $uibModal,
                                    $timeout, toastr) {

        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.active = 0;
        vm.search = search;   //
        vm.update = update;      //
        vm.removeTab = removeTab;    //
        vm.edit = edit;
        vm.create = create;   //
        vm.codeCheck = codeCheck;
//		
        vm.deleteData = deleteData;   //
        vm.confirmDelete = confirmDelete;    //

        vm.comfirmCreate = comfirmCreate;
        vm.updateSystemConfig = updateSystemConfig;

        vm.systemConfigDetail = systemConfigDetail;
        vm.status = [{id: 0, title: "新建"}, {id: 10, title: "启用"}, {id: 20, title: "禁用"}];

// 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

//列表页功能
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()
                }, params.filter());
//				console.log(postParams);
                return SystemConfig.list({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        //编码检查
        function codeCheck(tab) {
            if (tab.data.code == "" || tab.data.code == null) {
                toastr.warning("编码不能为空", "警告");
                return;
            }
            SystemConfig.checkCode({}, {
                code: tab.data.code
            }, function (resp) {
                if (!resp.content) {
                    toastr.success("编码可以使用可以使用", "成功");
                } else {
                    toastr.warning("编码已经被使用", "警告")
                }

            }, function (resp) {
                toastr.error(resp.data.errorMsg, "错误");
            });
        }


        //代码类型新建保存
        function comfirmCreate(tab, idx, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'confirm-save.html',
                controller: confirmOperation,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        tab: tab,
                        idx: idx,
                        form: form
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                vm.updateSystemConfig(item.tab, item.idx, item.form);
            });
            return;
        }

        //保存类型后台
        function updateSystemConfig(tab, idx, form) {
            //--------------注意
            var systemConfigBean = tab.data;

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            SystemConfig.save({}, systemConfigBean, function (resp) {
                vm.removeTab(idx);
            }, function (resp) {
                toastr.error(resp.data.errorMsg, "错误");
                return;
            });
        }


        //代码详细
        function systemConfigDetail(row) {
            SystemConfig.detail({}, {id: row.id},
                function (resp) {
                    var data = resp.content;
                    var entity = {
                        heading: '查看' + row.code,
                        contentId: "systemconfig-detail.html",
                        data: data,
                        active: true
                    };
                    vm.tabs.push(entity);
                    $timeout(function () {
                        vm.active = vm.tabs.length;
                    }, 0);
                }, function (resp) {
                    toastr.error(resp.data.errorMsg, "错误");
                    return;
                });
        }


        //删除数据
        function deleteData(row) {
            SystemConfig.delete({id: row.id}, {}, function () {
                vm.search();
            });
        }


//代码类型新建
        function create() {
            var entity = {
                heading: '新建',
                contentId: "systemconfig-edit.html",
                data: {
                    id: "",
                    tenantId: "",
                    code: "",
                    name: "",
                    value: "",
                    remark: ""
                },
                roles: [],
                NewCode: true,
                tableParams: new NgTableParams({}, {
                    counts: [], getData: function () {
                        return entity.data.systemConfigBeanList
                    }
                }),
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }


        //新建代码后台请求=
        function update(tab, systemConfigBean) {
            SystemConfig.save({}, systemConfigBean, function (resp) {
                SystemConfig.detail({}, {
                    id: tab.data.id
                }, function (resp) {
                    tab.data = resp.content;
                    tab.tableParams.reload();
                }, function (resp) {
                    toastr.error(resp.data.errorMsg, "错误");
                    return;
                });
            }, function (resp) {
                toastr.error(resp.data.errorMsg, "错误");
                return;
            });
        }


        //编辑
        function edit(row) {
            SystemConfig.detail({}, {id: row.id}, function (resp) {
                var entity = {
                    heading: '编辑',
                    contentId: "systemconfig-edit.html",
                    data: resp.content,
                    roles: [],
                    active: true
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        }


        //确认删除
        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                vm.deleteData(item);
            });
        }


        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


        //确认弹出窗
        confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.enable = enable;
        function enable(row) {
            SystemConfig.enable({}, row.id, function (resp) {
                if (resp.content == "success") {
                    toastr.success("启用成功");
                    vm.tableParams.reload();
                }
            });

        }

        vm.disable = disable;
        function disable(row) {
            SystemConfig.disable({}, row.id, function (resp) {
                if (resp.content == "success") {
                    toastr.success("禁用成功");
                    vm.tableParams.reload();
                }
            });

        }
    }
})();
