(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('AdjustmentOrderReview', AdjustmentOrderReview);

    AdjustmentOrderReview.$inject = ['$resource', 'iServiceConfig'];

    function AdjustmentOrderReview ($resource, iServiceConfig) {
        var service = $resource('web/adjustment', {}, {
            'list': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/list", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/cancel", method: 'POST'},
            'adjustNum': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/adjustNum", method: 'POST'},
            'saveAndUpdate': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/saveAndUpdate", method: 'POST'},
            'saveAndUpdateDetail': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/saveAndUpdateDetail", method: 'POST'},
            'delDetail': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/delDetail", method: 'POST'},
            'findAdjustmentDetailPage': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/findAdjustmentDetailPage", method: 'POST'},
            'submit': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/submit", method: 'POST'},
            'execute': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/execute", method: 'POST'},
            'review': {url: iServiceConfig.iwhInnerHost + "/web/adjustment/review", method: 'POST'}
        });

        return service;
    }
})();
