(function () {
    'use strict';

    angular.module('iwh-shipModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('nosoPickByScanSku', {
                parent: 'main',
                url: 'iwh-ship/nosoPickByScanSku?isNeedConfigParam=&isNeedAmount=&isNeedRemark=&isNeedBatchQty=',
                params: {

                },
                data: {
                    'pageTitle': '无单拣选'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/noso/nosoPickByScanSku.html',
                        controller: 'NosoPickByScanSkuController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
        ;
    }
})();
