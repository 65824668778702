(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Bom', Bom);

    Bom.$inject = ['$resource', 'iServiceConfig'];

    function Bom ($resource, iServiceConfig) {
        var service = $resource('web/bom', {}, {
            'compList': {url: iServiceConfig.idataHost + "/web/bom/compList", method: 'POST'},
            'saveComp': {url: iServiceConfig.idataHost + "/web/bom/saveComp", method: 'POST'},
            'updateComp': {url: iServiceConfig.idataHost + "/web/bom/updateComp", method: 'POST'},
            'delComp': {url: iServiceConfig.idataHost + "/web/bom/delComp", method: 'POST'},

            'detailsBom': {url: iServiceConfig.idataHost + "/web/bom/detailsBom", method: 'POST'},
            'bomList': {url: iServiceConfig.idataHost + "/web/bom/bomList", method: 'POST'},
            'saveBom': {url: iServiceConfig.idataHost + "/web/bom/saveBom", method: 'POST'},
            'updateBom': {url: iServiceConfig.idataHost + "/web/bom/updateBom", method: 'POST'},
            'delBom': {url: iServiceConfig.idataHost + "/web/bom/delBom", method: 'POST'}

        });

        return service;
    }
})();
