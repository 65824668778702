(function () {
    'use strict';

    angular.module('iwh-inventoryModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('bomInventoryQuery', {
                parent: 'main',
                url: 'iwh-inventory/bomInventoryQuery',
                data: {
                    'pageTitle': 'BOM商品库存'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/bom/bomInventoryQuery.html',
                        controller: 'BomInventoryQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
        ;
    }
})();
