(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('outbound-validation', {
            parent: 'main',
            url: 'iwh-strategy/outbound-validation',
            data: {'pageTitle': '出货验证规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/outboundValidation/outboundValidation.html',
                    controller: 'OutboundValidationController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('OutboundValidation', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/outbound-validation', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/outbound-validation', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/outbound-validation/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/outbound-validation/:id', params: {id: '@id'}, method: 'GET'},
            'getLotAttributes': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotAttribute.json', method: 'GET'},
        });
    }]);

    app.controller('OutboundValidationController', [
        '$scope', 'ModuleUtils', 'OutboundValidation', 'NgTableParams', '$uibModal', '$timeout', 'toastr',
        function ($scope, ModuleUtils, OutboundValidation, NgTableParams, $uibModal, $timeout, toastr) {
            $scope.ynOptions = [{k: 1, v: '是'}, {k: 0, v: '否'}];
            $scope.allowOptions = [{k: 10, v: '允许'}, {k: 20, v: '警告'}, {k: 30, v: '错误'}];

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };
            $scope.lotAttributesMap = {};
            OutboundValidation.getLotAttributes({}, function (result) {
                $scope.lotAttributes = result.content;
                angular.forEach($scope.lotAttributes, function(data,index,array){
                	$scope.lotAttributesMap[data.value] = data.label;
                });
            });

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return OutboundValidation.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.outboundValidationContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentTpl: "outboundValidationContent.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    OutboundValidation.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        tab.excludeOrderTypeList = [];
                        if (item.content.excludeOrderTypes) {
                            tab.excludeOrderTypeList = item.content.excludeOrderTypes.split(/,/g);
                        }
                        $scope.active = $scope.tabs.length;
                    });
                } else {
                    tab.data = row;
                    tab.excludeOrderTypeList = [];
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.outboundValidationShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentTpl: "outboundValidationContent.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    OutboundValidation.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        tab.excludeOrderTypeList = [];
                        if (item.content.excludeOrderTypes) {
                            tab.excludeOrderTypeList = item.content.excludeOrderTypes.split(/,/g);
                        }
                        $scope.active = $scope.tabs.length;
                    });
                } else {
                    tab.data = row;
                    tab.excludeOrderTypeList = [];
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.saveOutboundValidation = function (tab,index) {
            	console.log(tab);
                if (tab.excludeOrderTypeList) {
                    tab.data.excludeOrderTypes = tab.excludeOrderTypeList.join(',');
                } else {
                	tab.data.excludeOrderTypes = '';
                }
                if (tab.data.enableValidateExpiry==1){
                    if(tab.data.expiryType ==""||tab.data.expiryType==null){
                        toastr.error("请选择近期检查类型！");
                        return;
                    }
                }
                OutboundValidation.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.excludeOrderTypeList = resp.content.excludeOrderTypes.split(/,/g);
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };
        }
    ]);
})();