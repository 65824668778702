(function() {
    'use strict';

    angular.module('doveModule')
        .controller('iBusLogController', iBusLogController);

    iBusLogController.$inject = ['iBusRunLog', 'NgTableParams','$q', 'iBusProject'];

    function iBusLogController(iBusRunLog, NgTableParams, $q, iBusProject) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.projectMap = {};
        vm.projects = [];
        vm.search = search;
        vm.searchProjects = searchProjects;
        vm.runStatus = [{id:null, title:''},{id:'SUCCESS', title:'SUCCESS'},{id:'FAIL', title:'FAIL'}];

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusRunLog.search({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);

                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function searchProjects() {
            var deferred = $q.defer();

            var postParams = {pn: 1, ps: 999};
            iBusProject.search({}, postParams, function(resp) {
                var projects = jQuery.map(resp.content.datas, function(project) {
                    return {'id': project.code, 'title': project.name};
                });

                angular.forEach(projects, function(project) {
                    vm.projectMap[project.id] = project.title;
                });

                vm.projects = projects;

                deferred.resolve(vm.projects);
            });

            return deferred;
        }

        function search() {
            vm.tableParams.reload();
        }
    }
})();
