(function () {
    'use strict';

    angular
        .module('idataModule')
        .factory('OwnerUser', OwnerUser);

    OwnerUser.$inject = ['$resource', 'iServiceConfig'];

    function OwnerUser($resource, iServiceConfig) {
        var service = $resource('web/owneruser', {}, {
            'getUserList': {url: iServiceConfig.idataHost + "/web/owneruser/getUserList", method: 'POST'},
            'addBacth': {url: iServiceConfig.idataHost + "/web/owneruser/addBacth", method: 'POST'},
            'getOwnerList': {url: iServiceConfig.idataHost + "/web/owneruser/getOwnerList", method: 'GET'},
            'list': {url: iServiceConfig.idataHost + "/web/owneruser/list", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/owneruser/save", method: 'POST'},
            'delete': {url: iServiceConfig.idataHost + "/web/owneruser/delete", method: 'POST'},
            'deleteByUser': {url: iServiceConfig.idataHost + "/web/owneruser/deleteByUser", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/owneruser/disable", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/owneruser/enable", method: 'POST'},
            'checkCode': {url: iServiceConfig.idataHost + "/web/owneruser/checkcode", method: 'POST'}
        });
        return service;
    }
})();
