(function () {
    'use strict';

    angular.module('idataModule').controller('WarehouseController', WarehouseController);
    WarehouseController.$inject = ['Warehouse', 'NgTableParams', '$uibModal', '$timeout', 'toastr', 'WarehouseUser','iServiceConfig'];
    function WarehouseController(Warehouse, NgTableParams, $uibModal, $timeout, toastr, WarehouseUser,iServiceConfig) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.bindUserIds;
        vm.status = [
            {'id': '1', 'title': '启用'},
            {'id': '0', 'title': '禁用'}
        ];
        vm.userStatus = [
            {'id': '1', 'title': '启用'},
            {'id': '-1', 'title': '禁用'}
        ];

        vm.requireType = [{'id': '1', 'title': '是'},
            {'id': '0', 'title': '否'}];
        vm.active = 0;
        vm.currentWarehouse = null;

        vm.geoCode = function(address, lnglatObj, lngF, latF, geoContainer) {
            window.geoCode1(address, lnglatObj, lngF, latF, geoContainer);
            return false;
        }
        
        // 列表
        vm.tableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count(),
                    orderBy: 'code'
                }, params.filter());
                return Warehouse.list({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });


        // 保存仓库
        vm.saveWarehouse = function (tab, idx, form) {
            console.log(tab);
            var warehouseBean = tab.data,
                valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if (warehouseBean.receive_pca && warehouseBean.receive_pca.area) {
                warehouseBean.receiveProvinceId = warehouseBean.receive_pca.province.code;
                warehouseBean.receiveProvince = warehouseBean.receive_pca.province.name;
                warehouseBean.receiveCityId = warehouseBean.receive_pca.city.code;
                warehouseBean.receiveCity = warehouseBean.receive_pca.city.name;
                warehouseBean.receiveAreaId = warehouseBean.receive_pca.area.code;
                warehouseBean.receiveArea = warehouseBean.receive_pca.area.name;
            }
            Warehouse.save({}, warehouseBean, function (resp) {
                vm.removeTab(idx);
                toastr.success("保存成功", "提示");
            }, function (resp) {

            });
        };


        // 编码可用性检查
        vm.codeCheck = function (tab) {
            if (tab.data.code == "" || tab.data.code == null) {
                toastr.warning("编码不能为空", "警告");
                return;
            }
            Warehouse.checkCode({}, {
                code: tab.data.code
            }, function (resp) {
                if (!resp.content) {
                    toastr.success("编码可以使用", "成功");
                } else {
                    toastr.warning("编码已经被使用", "警告")
                }

            }, function (resp) {
                toastr.error(resp.data.errorMsg, "错误");
            });
        };


        // 通用方法
        vm.search = function () {
            vm.tableParams.reload();
        };


        // 删除tab
        vm.removeTab = function (idx) {
            vm.tabs.splice(idx, 1);
        };


        // 仓库详细
        vm.warehouseDetail = function (row) {
            Warehouse.detail({}, {
                id: row.id
            }, function (resp) {
                var data = resp.content
                var entity = {
                    heading: '查看仓库的用户',
                    contentId: "warehouse-detail.html",
                    data: data,
                    tableParams: new NgTableParams({
                        count: 10
                    }, {
                        counts: [],
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count(),
                                orderBy: 'code'
                            }, params.filter());
                            postParams.warehouseCode = row.code;
                            return WarehouseUser.list({}, postParams, function (resp) {
                            }).$promise.then(function (data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                        }
                    }),
                    active: true
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }, function (resp) {
                toastr.error(resp.data.errorMsg, "错误");
                return;
            });
        };


        // 创建仓库
        vm.create = function () {
            var entity = {
                heading: '新建',
                contentId: "warehouse-edit.html",
                parkUri:iServiceConfig.idataHost + '/web/park/findAll',
                data: {
                    id: "",
                    tenantId: "",
                    code: "",
                    name: "",
                    remark: "",
                    warehouseBeanList: [],
                    receive_pca: {}
                },
                NewCode: true,
                tableParams: new NgTableParams({}, {
                    counts: [], getData: function () {
                        return entity.data.warehouseBeanList
                    }
                }),
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        };

        // 编辑仓库
        vm.editWarehouse = function (row) {
            vm.currentWarehouse = row;
            vm.editTableParams = new NgTableParams({
                count: 10
            }, {
                counts: [],
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count(),
                        orderBy: 'code'
                    }, params.filter());
                    postParams.warehouseCode = row.code;
                    return WarehouseUser.list({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        vm.bindUserIds = "|";
                        var dataList = data.content.datas;
                        if (dataList instanceof Array) {
                            for (var i = 0, len = dataList.length; i < len; i++) {
                                var d = dataList[i];
                                vm.bindUserIds += d.userId + "|";
                            }
                        }
                        return dataList
                    });
                }
            });

            Warehouse.detail({}, {id: row.id}, function (resp) {
                var entity = {
                    heading: '编辑',
                    contentId: "warehouse-edit.html",
                    parkUri:iServiceConfig.idataHost + '/web/park/findAll',
                    data: resp.content,
                    tableParams: vm.editTableParams,
                    active: true
                };
                entity.data.receive_pca = {};
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        };
        vm.init = function (row) {
            Warehouse.init({}, {
                id: row.id
            }, function (resp) {
                if (resp.status == "SUCCESS") {
                    toastr.success("初始化任务已开启，请稍后检查初始化数据是否正常");
                    vm.search();
                }
            });
        };
        // 删除仓库
        vm.confirmDelete = function (row) {
            Warehouse.delete({
                id: row.id
            }, {}, function () {
                vm.search();
                toastr.success("删除仓库成功", "成功");
            });
        };

        // 改变仓库状态
        vm.changeStatus = function (row) {
            if (row.status === 1) {
                Warehouse.disable({}, {
                    id: row.id
                }, function (resp) {
                    if (resp.status == "SUCCESS") {
                        toastr.success("禁用成功");
                    }
                    vm.search();
                });
            } else {
                Warehouse.enable({}, {
                    id: row.id
                }, function (resp) {
                    if (resp.status == "SUCCESS") {
                        toastr.success("启用成功");
                    }
                    vm.search();
                });
            }
        };


        /*********************************************************************
         * 仓库用户管理
         * 2017-05-05 14:19:49
         ********************************************************************/
        vm.searchUser = function () {
            vm.editTableParams.reload();
        };

        // 创建仓库用户
        vm.createWarehouseUser = function () {
            var entity = {
                heading: '添加用户',
                contentId: "warehouseUser-edit.html",
                data: {},
                NewCode: true,
                tableParams: vm.bindUserToWarehouse,
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        };


        // 删除仓库用户
        vm.confirmDeleteUser = function (row) {
            WarehouseUser.delete({}, {id: row.id}, function () {
                toastr.success("删除仓库用户成功", "成功");
                vm.editTableParams.reload();
            });
        };


        // 改变仓库用户状态
        vm.changeWarehouseUserStatus = function changeStatus(row) {
            if (row.status === 1) {
                WarehouseUser.disable({}, {
                    id: row.id
                }, function () {
                    vm.editTableParams.reload();
                });
            } else {
                WarehouseUser.enable({}, {
                    id: row.id
                }, function () {
                    vm.editTableParams.reload();
                });
            }
        };


        // 获取绑定用户到仓库的列表对象
        vm.bindUserToWarehouse = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count(),
                    orderBy: 'code'
                }, params.filter());
                return WarehouseUser.getUserList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        // 检测给定的用户是否与当前仓库绑定
        vm.checkUserUsed = function (userId) {
            return vm.bindUserIds.indexOf("|" + userId + "|") == -1;
        };

        // 将用户绑定到仓库
        vm.saveUser = function (row) {
            WarehouseUser.save({}, {
                warehouseCode: vm.currentWarehouse.code,
                warehouseName: vm.currentWarehouse.name,
                userId: row.userId,
                userName: row.userName,
                realName: row.realName
            }, function () {
                toastr.success("绑定成功", "提示");
                vm.editTableParams.reload();
                vm.bindUserToWarehouse.reload();
            });
        };

        // 将用户从仓库中解除绑定
        vm.deleteUser = function (row) {
            WarehouseUser.deleteByUser({}, {
                warehouseCode: vm.currentWarehouse.code,
                userId: row.userId
            }, function () {
                toastr.success("解除绑定成功", "提示");
                vm.editTableParams.reload();
                vm.bindUserToWarehouse.reload();
            });
        };
    }
})();
