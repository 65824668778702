(function () {
    'use strict';

    angular.module('iportalModule')
        .controller('iPortalUserTodoController', iPortalUserTodoController);

    iPortalUserTodoController.$inject = ['NgTableParams', 'NgSelect', '$timeout', 'toastr', '$uibModal', '$scope', 'enums', 'iPortalUserTodo', 'iPortalSystemMessage'];

    function iPortalUserTodoController(NgTableParams, NgSelect, $timeout, toastr, $uibModal, $scope, enums, iPortalUserTodo, iPortalSystemMessage) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.modalInstance = null;
        vm.todoTypeEnum = enums("portal.TodoType");
        vm.todoType = enums("portal.TodoType").list("title", "id");
        vm.todoStatusEnum = enums("portal.TodoStatus");
        vm.todoStatus = enums("portal.TodoStatus").list("title", "id");
        vm.todoSourceEnum = enums("portal.TodoSource");
        vm.todoSource = enums("portal.TodoSource").list("title", "id");
        vm.todoImportanceEnum = enums("portal.TodoImportance");
        vm.todoImportance = enums("portal.TodoImportance").list("title", "id");
        vm.todoUrgencyEnum = enums("portal.TodoUrgency");
        vm.todoUrgency = enums("portal.TodoUrgency").list("title", "id");
        vm.receiveTypeEnum = enums("portal.ReceiveType");
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iPortalUserTodo.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });
        vm.userList = null;
        vm.roleList = null;
        getRoleAndUsers();

        vm.search = search;
        vm.removeTab = removeTab;
        vm.create = create;
        vm.releaseTodo = releaseTodo;
        vm.handleTodo = handleTodo;
        vm.createEidtTodoPage = createEidtTodoPage;
        vm.todoDetail = todoDetail;
        vm.saveTodo = saveTodo;
        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function getRoleAndUsers() {
            iPortalSystemMessage.getUserAndRole({}, {}, function (resp) {
                vm.userList = resp.content.users;
                vm.roleList = resp.content.roles;
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function create() {
            //todo 创建待办事项
            var entity = {
                heading: '创建待办事项',
                contentId: "todo-edit.html",
                data: {},
                multiUserSelect: new NgSelect({
                    allList: vm.userList,
                    list: [],
                    config: {
                        meta: {
                            label: 'userName',
                            id: 'userId'
                        }
                    }
                }),
                multiRoleSelect: new NgSelect({
                    allList: vm.roleList,
                    list: [],
                    config: {
                        meta: {
                            label: 'roleName',
                            id: 'roleId'
                        }
                    }
                }),
                readOnly: false,
                roles: [],
                active: 0
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function validate(entity) {
            if (!!!entity.data.title || !!!entity.data.type || !!!entity.data.todoFrom || !!!entity.data.content ||
                !!!entity.data.planStartTime || !!!entity.data.planEndTime || !!!entity.data.importance || !!!entity.data.emergency) {
                toastr.info("信息未填完", "提示");
                return false;
            }
            var users = entity.multiUserSelect.getSelected();
            var roles = entity.multiRoleSelect.getSelected();
            if (!!users && users.length > 0 && !!roles && roles.length > 0) {
                toastr.info("您只能按人员或者按角色选择,不能同时选择", "提示");
                return false;
            }
            if ((!!!users || users.length <= 0) && (!!!roles || roles.length <= 0)) {
                toastr.info("您必须选择按人员或者按角色选择权限", "提示");
                return false;
            }

            return true;
        }

        function saveTodo(entity, tabIndex, isRelease) {
            if (validate(entity)) {
                entity.data.receiverIds = [];
                var receivers = null;
                var users = entity.multiUserSelect.getSelected();
                var roles = entity.multiRoleSelect.getSelected();
                if (!!users && users.length > 0) {
                    receivers = users;
                    entity.data.receiverType = vm.receiveTypeEnum.value("USER");
                } else {
                    receivers = roles;
                    entity.data.receiverType = vm.receiveTypeEnum.value("ROLE");
                }
                angular.forEach(receivers, function (item) {
                    if (entity.data.receiverType == vm.receiveTypeEnum.value("USER")) {
                        entity.data.receiverIds.push(item.userId);
                    } else {
                        entity.data.receiverIds.push(item.roleId);
                    }
                });
                iPortalUserTodo.save({isRelease: isRelease}, entity.data, function (resp) {
                    toastr.success(isRelease ? "保存并发布成功" : "保存成功");
                    vm.removeTab(tabIndex);
                }, function (err) {
                    toastr.error("操作失败");
                });
            }
        }

        function releaseTodo(row) {
            //todo 发布待办事项
            iPortalUserTodo.release({id: row.id}, {}, function (resp) {
                toastr.success("发布成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("发布失败", "错误");
            });
        }

        function handleTodo(row) {
            //todo 处理待办事项
            iPortalUserTodo.handle({id: row.id}, {}, function (resp) {
                toastr.success("处理成功", "成功");
                vm.search();
            }, function (err) {
                toastr.error("处理失败", "错误");
            });
        }

        function createEidtTodoPage(row) {
            //todo 创建待办事项编辑页
            iPortalUserTodo.preUpdate({id: row.id}, {}, function (resp) {
                var entity = {
                    heading: '编辑待办事项',
                    contentId: "todo-edit.html",
                    data: resp.content,
                    multiUserSelect: new NgSelect({
                        allList: vm.userList,
                        list: resp.content.receiverType == vm.receiveTypeEnum.value("USER") ?
                            JSON.parse(resp.content.receivers) : [],
                        config: {
                            meta: {
                                label: 'userName',
                                id: 'userId'
                            }
                        }
                    }),
                    multiRoleSelect: new NgSelect({
                        allList: vm.roleList,
                        list: resp.content.receiverType == vm.receiveTypeEnum.value("ROLE") ?
                            JSON.parse(resp.content.receivers) : [],
                        config: {
                            meta: {
                                label: 'roleName',
                                id: 'roleId'
                            }
                        }
                    }),
                    readOnly: false,
                    roles: [],
                    active: resp.content.receiverType == vm.receiveTypeEnum.value("USER") ? 0 : 1
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }, function (err) {
                toastr.error("编辑失败", "错误");
            });
        }

        function todoDetail(row) {
            iPortalUserTodo.preUpdate({id: row.id}, {}, function (resp) {
                var entity = {
                    heading: '待办事项详情',
                    contentId: "todo-edit.html",
                    data: resp.content,
                    multiUserSelect: new NgSelect({
                        allList: vm.userList,
                        list: resp.content.receiverType == vm.receiveTypeEnum.value("USER") ?
                            JSON.parse(resp.content.receivers) : [],
                        config: {
                            meta: {
                                label: 'userName',
                                id: 'userId'
                            }
                        }
                    }),
                    multiRoleSelect: new NgSelect({
                        allList: vm.roleList,
                        list: resp.content.receiverType == vm.receiveTypeEnum.value("ROLE") ?
                            JSON.parse(resp.content.receivers) : [],
                        config: {
                            meta: {
                                label: 'roleName',
                                id: 'roleId'
                            }
                        }
                    }),
                    readOnly: true,
                    roles: [],
                    active: resp.content.receiverType == vm.receiveTypeEnum.value("USER") ? 0 : 1
                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }, function (err) {
                toastr.error("编辑失败", "错误");
            });
        }
    }
})();
