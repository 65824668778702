(function()  {
    'use strict';

    angular.module('iwh-strategyModule')
        .controller('StrategySnapshotRuleController', StrategySnapshotRuleController);

    StrategySnapshotRuleController.$inject = ['$rootScope','$stateParams', 'StrategySnapshotRule', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','iServiceConfig'];

    function StrategySnapshotRuleController ($rootScope,$stateParams, StrategySnapshotRule, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.warehouseSnap = {}

        StrategySnapshotRule.detail({}, {}).$promise.then(function(resp) {
            vm.warehouseSnap = resp.content;
        });

        vm.start = start;
        function start() {
        	StrategySnapshotRule.start({}, vm.warehouseSnap, function(resp){
        		vm.warehouseSnap = resp.content;
        		toastr.success("开始执行成功！");
            });
        }
        
        vm.stop = stop;
        function stop() {
        	StrategySnapshotRule.stop({}, {}, function(resp){
            	vm.warehouseSnap = resp.content;
        		toastr.success("停止执行成功！");
            });
        }
    }
})();
