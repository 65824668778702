(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsTransportLineController', TmsTransportLineController);

    TmsTransportLineController.$inject = ['$rootScope', 'TmsTransportLine', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsTransportLineController ($rootScope,  TransportLine, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.stationLine = {
                seq : 0,
                code : null,
                previousMileage : 0,
                stationCode : null

        }
        vm.status = [
                       	{id : null, title : "不限"},
                        {id : 0, title : "新建"},
                        {id : 10, title : "启用"},
                        {id : -10, title : "禁用"},
                    ];

        vm.yesOrNo = [
            {id : 0, title : "否"},
            {id : 1, title : "是"},
        ];

        vm.priority = [
            {id : 1, title : "高"},
            {id : 2, title : "中"},
            {id : 3, title : "低"}
        ];


        function pageTitle() {
            return $window.document.title;
        }

      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
                if($state.params.carrierCode!=null){
                    params.filter().carrierCode = $state.params.carrierCode;
                    $state.params.carrierCode=null;
                }
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return TransportLine.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.validate = validate;
        function validate(code,carrierCode){
            if(code==null ||code==""){
                return
            }else{
            	var vo = {};
            	vo.code = code;
            	vo.carrierCode = carrierCode;
                TransportLine.validateCode({}, vo, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }

        vm.defaultChoose = null;
		vm.createTransportLine = createTransportLine;
		function createTransportLine() {
                var entity = {
                    heading : '创建',
                    contentId : "createTransportLine.html",
                    active : true,
					addLine : {},
					stationList : [],
					stationLine : {
                        seq : 0,
						code : null,
                        previousMileage : 0,
                        stationCode : null

					},
                    data: {
                        status : 0,
                        totalMileage : 0,
                        stationCount : 0

                    },
                    tableParams: new NgTableParams({}, {getData:function(){return entity.stationList}})
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);

		}

		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = {
				 line : {},
				 stationList : []
			 };
            if(t.data.carrierCode==null||t.data.carrierCode==""){
                toastr.error("请选择承运商！");
                return;
            }else if(t.data.fleetCode==null||t.data.fleetCode==""){
                toastr.error("请选择车队！");
                return;
            }else if(t.data.vehicleType==null||t.data.vehicleType==""){
                toastr.error("请选择车辆类型！");
                return;
            }else if(t.data.status==null){
                console.log(t.data.status);
                toastr.error("请选择状态！");
                return;
            }
			 saveVO.line = t.data;
			 saveVO.stationList = t.stationList;
			 if(t.data.id){
				 TransportLine.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 TransportLine.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}

		vm.show = show;
		function show(row) {
            TransportLine.show({}, row.id, function(resp) {
				var TransportLine = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showTransportLine.html",
						active : true,
						data : TransportLine,
                    	stationList : TransportLine.stationList,
                    	tableParams: new NgTableParams({}, {getData:function(){return entity.stationList}}),


                };
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

		vm.edite = edite;
		function edite(row) {
            TransportLine.show({}, row.id, function(resp) {
				var TransportLine = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createTransportLine.html",
						active : true,
						data : TransportLine,
						stationList : TransportLine.stationList,
						stationLine : {
							seq : 0,
                        code : null,
                        previousMileage : 0,
                        stationCode : null

                    },
                    tableParams: new NgTableParams({}, {getData:function(){return entity.stationList}}),
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

        vm.addStation = addStation;
		function addStation(t) {

            for(var i=0;i<t.stationList.length;i++){
                if(t.stationList[i].stationCode == t.stationLine.stationCode){
                    toastr.error("此线路已有此站点", "错误");
                    return;
                }
                if(t.stationList[i].seq == t.stationLine.seq){
                    toastr.error("顺序号已存在", "错误");
                    return;
                }

            }

			var temp = {
            	seq : 0,
				code : null,
                stationCode : null,
                previousMileage : 0,
				status : 0
			};
			temp.code = t.data.code;
			temp.seq = t.stationLine.seq;
			temp.stationCode = t.stationLine.stationCode;
			temp.previousMileage = t.stationLine.previousMileage;
			t.stationList.push(temp);
			t.data.stationCount = t.data.stationCount+1;
            t.data.totalMileage = parseInt(t.data.totalMileage)+parseInt(t.stationLine.previousMileage);
			t.tableParams.reload();

        }

        vm.editeStation = editeStation;
		function editeStation(row,t){
            $('#save').css("display","block");
            $('#add').css("display","none");
			t.stationLine = vm.cloneObj(row);
            vm.stationLine = vm.cloneObj(row);

		}
        vm.saveStation = saveStation;
        function saveStation(t){
            $('#save').css("display","none");
            $('#add').css("display","block");
            for(var i=0;i<t.stationList.length;i++) {
                if(t.stationList[i].stationCode == vm.stationLine.stationCode){
                    t.stationList[i].seq = t.stationLine.seq;
                    t.stationList[i].stationCode = t.stationLine.stationCode;
                    t.stationList[i].previousMileage = t.stationLine.previousMileage;
                    t.data.totalMileage = parseInt(t.data.totalMileage)-parseInt(vm.stationLine.previousMileage);
                    t.data.totalMileage = parseInt(t.data.totalMileage)+parseInt(t.stationLine.previousMileage);
                }
            }
        }


        vm.delStation = delStation;
        function delStation(row,t){
            t.stationLine = vm.cloneObj(row);
            t.stationList.splice(parseInt(t.stationLine.seq-1),1);
            t.data.stationCount = t.data.stationCount-1;
            t.data.totalMileage = parseInt(t.data.totalMileage)+parseInt(t.stationLine.previousMileage);
            t.tableParams.reload();
        }

        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            TransportLine.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }

        vm.cloneObj = cloneObj;
        function cloneObj(obj) {
            var newObj = {};
            if (obj instanceof Array) {
                newObj = [];
            }
            for (var key in obj) {
                var val = obj[key];
                //newObj[key] = typeof val === 'object' ? arguments.callee(val) : val; //arguments.callee 在哪一个函数中运行，它就代表哪个函数, 一般用在匿名函数中。
                newObj[key] = typeof val === 'object' ? cloneObj(val): val;
            }
            return newObj;
        };

        vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                TransportLine.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }

		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }

		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
