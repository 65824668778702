(function() {
    'use strict';

    angular.module('doveModule')
        .controller('iBusJcoDatasourceController', iBusJcoDatasourceController);

    iBusJcoDatasourceController.$inject = ['iBusJcoDatasource', 'NgTableParams','$uibModal', 'iBusJcoDatasourceRunning', 'iBusProject', '$q','$timeout'];

    function iBusJcoDatasourceController(iBusJcoDatasource, NgTableParams, $uibModal, iBusJcoDatasourceRunning, iBusProject, $q, $timeout) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.projectMap = {};
        vm.projects = [];
        vm.displayed = [];
        vm.isLoading  = false;
        vm.status = [{'id':1, 'title':'启用'},{'id':0, 'title':'禁用'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.update = update;
        vm.deleteDataSource = deleteDataSource;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.searchProjects = searchProjects;
        vm.initJcoDatasource = initJcoDatasource;
        vm.stopJcoDatasource = stopJcoDatasource;
        vm.active = 0;

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusJcoDatasource.search({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }

        function deleteDataSource(row){
            iBusJcoDatasource.delete({id: row.id}, {}, function() {
                vm.search();
            });
        }

        function edit(row) {
            var entity = {
                heading: '编辑',
                contentId: "datasource-eidt.html",
                data: row,
                roles: [],
                active: true
            };

            vm.tabs.push(entity);

            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function create() {
            var entity = {
                heading: '创建',
                contentId: "datasource-eidt.html",
                data: {
                    code: "",
                    name: "",
                    projectCode: "",
                    client: "",
                    sysnr: "",
                    user: "",
                    passwd: "",
                    maxConnections: "",
                    connectTimeout: "",
                    status: 0
                },
                roles: [],
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function update(tab, idx, form) {
            var datasource = tab.data;
            form.passwordUnmatch  = "";

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            if (!datasource.id) {
                iBusJcoDatasource.add({}, datasource, function() {
                    vm.removeTab(idx);
                });
            } else {
                iBusJcoDatasource.update({}, datasource, function() {
                    vm.removeTab(idx);
                });
            }
        }

        function searchProjects() {
            var deferred = $q.defer();

            var postParams = {pn: 1, ps: 999};
            iBusProject.search({}, postParams, function(resp) {
                var projects = jQuery.map(resp.content.datas, function(project) {
                    return {'id': project.code, 'title': project.name};
                });

                angular.forEach(projects, function(project) {
                    vm.projectMap[project.id] = project.title;
                });

                vm.projects = projects;

                deferred.resolve(vm.projects);
            });

            return deferred;
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function initJcoDatasource(row) {
            iBusJcoDatasourceRunning.init({}, {code: row.code}, function() {
                vm.search();
            });
        }

        function stopJcoDatasource(row) {
            iBusJcoDatasourceRunning.close({}, {code: row.code}, function() {
                vm.search();
            });
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteDataSource(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
