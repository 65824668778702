(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('LockInventoryQueryController', LockInventoryQueryController);

    LockInventoryQueryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'LockInventory', 'toastr', '$uibModal'];

    function LockInventoryQueryController ($rootScope, $timeout,NgTableParams, enums, LockInventory, toastr,  $uibModal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.invLockStatuss = enums('inventory.InvLockStatus');
        vm.invLockTypes = enums('inventory.InvLockType');
        vm.InvFrozenTypes = enums('inventory.InvFrozenType');
        
        vm.invLockStatussSelect = vm.invLockStatuss.list('title', 'id');
        vm.invLockTypesSelect = vm.invLockTypes.list('title', 'id');
        vm.InvFrozenTypesSelect = vm.InvFrozenTypes.list('title', 'id');
        
        vm.search = search;

        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return LockInventory.queryDetail({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        }
    }
})();
