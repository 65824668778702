(function()  {
    'use strict';

    angular.module('iwh-dataModule').controller('IwhWorkAreaController', WorkAreaController);

    WorkAreaController.$inject = ["$rootScope", "$scope", "NgTableParams",
        "$uibModal", "NgSelect", "$state", "$timeout", "toastr", "IwhWorkArea"];

    function WorkAreaController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                                  $state, $timeout, toastr, WorkArea) {
        var vm = this;
        vm.tabs = [];       // 记录所有的tab
        vm.active = 0;      // 总共打开tab的总数
        vm.activeRegionCode = "";
        vm.allowLoadRegionZone = false;
        vm.regionZoneCode = null;

        /**
         * 删除指定下标的tab页
         * @param idx 下标
         */
        vm.removeTab = function(idx) {
            vm.tabs.splice(idx, 1);
        };


        /**
         * 工作区域管理
         */
        vm.region = {
            // 新增库区
            createRegion : function () {
                vm.tabs.push({
                    heading: '新增工作区域',
                    contentId: "region-edit.html",
                    data: {
                        type : "add",
                        status : 1
                    },
                    active: true
                });
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            },

            // 更新库区信息
            updateRegion : function (row) {
                row.type = "update";
                vm.tabs.push({
                    heading: '修改工作区域',
                    contentId: "region-edit.html",
                    data: row,
                    active: true
                });
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            },

            // 显示工作区域详情
            showOwner : function (row) {
                vm.tabs.push({
                    heading: '工作区域详情',
                    contentId: "region-detail.html",
                    data: row,
                    active: true
                });
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            },

            // 加载指定工作区域的库区
            loadRegionZone : function(row) {
                vm.zoneShow = row.status;
                vm.activeRegionCode = row.code;
                vm.regionZone.search();
            },

            // 获取工作区域列表
            getRegionList : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count()
                    }, params.filter());
                    return WorkArea.getRegionList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        if ( datas.length > 0 ) {
                            vm.activeRegionCode = datas[0].code;
                            vm.allowLoadRegionZone = true;
                            vm.regionZone.search();
                        }
                        return datas;
                    });
                }
            }),

            // 查询数据
            search : function () {
                vm.region.getRegionList.reload();
            },

            // 保存工作区域
            saveRegion : function (tab, idx, form) {
                var data = tab.data;
                console.debug(data);
                if ( !form.$valid ) {
                    form.showMsg = true;
                    toastr.error("请填写完整数据", '错误');
                    return;
                }
                WorkArea.saveRegion({}, data, function (resp) {
                    if ( "SUCCESS" == resp.status ) {
                        vm.removeTab(idx);
                        toastr.success("保存工作区域成功");
                        vm.region.search();
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存工作区域失败！"), "错误");
                    }
                });
            },

            // 删除工作区域
            deleteRegion : function (row) {
                WorkArea.deleteRegion({}, {
                    id : row.id
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success("删除工作区域成功！");
                        vm.region.search();
                    } else {
                        toastr.error(resp.errorMsg || "删除工作区域失败！", "错误");
                    }
                });
            },

            // 修改工作区域状态
            changeStatusRegion : function (row) {
                WorkArea.changeStatusRegion({}, {
                    id : row.id,
                    status : (row.status === 1 ? -1 : 1)
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success(row.status === 1 ? "成功禁用" : "成功启用");
                        vm.region.search();
                    } else {
                        toastr.error(resp.errorMsg || "改变库区状态失败！", "错误");
                    }
                });
            }
        };



        /**
         * 工作区域的库区管理
         */
        vm.regionZone = {
            // 新增库区
            createRegionZone : function () {
                vm.tabs.push({
                    heading: '添加库区到工作区域',
                    contentId: "add-zone.html",
                    data: {
                        relayFlag : 1
                    },
                    active: true
                });
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            },


            // 检测指定库区编码是否已经被使用
            checkZoneUsed : function (row) {
                return vm.regionZoneCode.indexOf("|" + row.code + "|") == -1;
            },


            // 获取库区列表
            getZoneList : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count()
                    }, params.filter());
                    return WorkArea.getZoneList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            }),


            // 获取工作区域的库区
            getRegionZoneList : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    if ( !vm.allowLoadRegionZone ) {
                        return;
                    }
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count(),
                        regionCode : vm.activeRegionCode
                    }, params.filter());
                    return WorkArea.getRegionZoneList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        vm.regionZoneCode = "|";
                        for (var i = 0, len = datas.length; i < len; i ++ ) {
                            vm.regionZoneCode += datas[i].zoneCode + "|";
                        }
                        return datas;
                    });
                }
            }),


            // 查询数据
            search : function () {
                vm.regionZone.getRegionZoneList.reload();
            },


            // 保存工作区域的库区
            saveRegionZone : function (row) {
                if ( (vm.activeRegionCode || "").length <= 0 ) {
                    toastr.error("工作区域代码不能为空！", "错误");
                    return;
                }
                WorkArea.saveRegionZone({}, {
                    regionCode : vm.activeRegionCode,
                    sectionCode : row.sectionCode,
                    zoneCode : row.code
                }, function (resp) {
                    if ( "SUCCESS" == resp.status ) {
                        toastr.success("添加库区到工作区域成功");
                        vm.regionZone.search();
                        vm.regionZoneCode += "|" + row.code + "|";
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "添加库区到工作区域失败！"), "错误");
                    }
                });
            },


            // 删除工作区域的库区
            deleteRegionZone : function (row) {
                WorkArea.deleteRegionZone({}, {
                    id : row.id
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success("删除工作区域的库区成功！");
                        vm.regionZone.search();
                    } else {
                        toastr.error(resp.errorMsg || "删除工作区域的库区失败！", "错误");
                    }
                });
            }
        };
    };
})();

