(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('PackTempBiz', PackTempBiz);

    PackTempBiz.$inject = ['$resource', 'iServiceConfig'];

    function PackTempBiz ($resource, iServiceConfig) {
        var service = $resource('web/pack/so', {}, {
            'getPackSoByNum': { url: iServiceConfig.iwhShipHost + "/web/pack/so/getPackSoByNum", method: 'GET'},
            'checkSn': { url: iServiceConfig.iwhShipHost + "/web/pack/so/checkSn", method: 'POST'},
            'packSku': { url: iServiceConfig.iwhShipHost + "/web/pack/so/packSku", method: 'POST'},
            'repackBox': { url: iServiceConfig.iwhShipHost + "/web/pack/so/repackBox", method: 'GET'},
            'closeBox': { url: iServiceConfig.iwhShipHost + "/web/pack/so/closeBox", method: 'POST'}
        });
        return service;
    }
})();
