(function() {
    'use strict';

    angular
        .module('ireportModule')
        .factory('RptReportTemplate', RptReportTemplate);

    RptReportTemplate.$inject = ['$resource', 'iServiceConfig'];

    function RptReportTemplate ($resource, iServiceConfig) {
        var service = $resource('web/reportTpl', {}, {
            'list': { url: iServiceConfig.ireportHost + "/web/reportTpl/list", method: 'POST'},
            'changeStatus': { url: iServiceConfig.ireportHost + "/web/reportTpl/changeStatus", method: 'POST'},
            'allNodeList': { url: iServiceConfig.ireportHost + "/web/reportTpl/allNodeList", method: 'GET'},
            'detail': { url: iServiceConfig.ireportHost + "/web/reportTpl/detail", method: 'POST'},
            'save': { url: iServiceConfig.ireportHost + "/web/reportTpl/save", method: 'POST'},
            'selectedNodeList': { url: iServiceConfig.ireportHost + "/web/reportTpl/selectedNodeList", method: 'POST'},
            'sync': { url: iServiceConfig.ireportHost + "/web/reportTpl/sync", method: 'POST'},
            'listAllActive': { url: iServiceConfig.ireportHost + "/web/reportTpl/listAllActive", method: 'GET'}
        });

        return service;
    }
})();
