(function () {
    'use strict';

    angular.module('iwh-dataModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('iwh-equipment-maintenance', {
                parent: 'main',
               url: 'iwh-data/equipment-maintenance',
                data: {
                    'pageTitle': '仓库设备维护',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-data-module/equipment/equipment.html',
                        controller: 'IwhEquipmentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
