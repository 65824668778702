(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhPlatform', Platform);

    Platform.$inject = ['$resource', 'iServiceConfig'];

    function Platform ($resource, iServiceConfig) {
        var service = $resource('web/platform', {}, {
            'listPlatform': { url: iServiceConfig.iwhDataHost + "/web/platform/listPlatform", method: 'POST'},
            'detailPlatform': {url: iServiceConfig.iwhDataHost + "/web/platform/detailPlatform", method: 'POST'},
            'savePlatform': {url: iServiceConfig.iwhDataHost + "/web/platform/savePlatform", method: 'POST'},
            'updatePlatform': {url: iServiceConfig.iwhDataHost + "/web/platform/updatePlatform", method: 'POST'},
            'delPlatform': {url: iServiceConfig.iwhDataHost + "/web/platform/delPlatform", method: 'POST'},

            'checkCode': {url: iServiceConfig.iwhDataHost + "/web/platform/checkCode", method: 'POST'},
        });
        return service;
    }
})();