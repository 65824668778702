(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('InnerTransferController',
    		InnerTransferController);

    InnerTransferController.$inject = [ 'InnerTransfer', 'NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state'];

    function InnerTransferController(InnerTransfer, NgTableParams, $uibModal,enums, $timeout, toastr,$state) {
    	console.log('22222');
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.params = {};
        vm.removeTab = removeTab;
        vm.search = search;
        

        vm.typeObj = enums('inner.TransferType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.TransferOrderStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.detailStatusObj = enums('inner.TransferOrderDStatus');
        vm.detailStatus = vm.detailStatusObj.list('title', 'id');

        vm.transferOrderLogTypeObj = enums('inner.TransferOrderLogType');
        vm.transferOrderLogType = vm.transferOrderLogTypeObj.list('title', 'id');

        vm.transferAllocateStatusObj = enums('inner.TransferAllocateStatus');
        vm.transferAllocateStatus = vm.transferAllocateStatusObj.list('title', 'id');

        vm.create = create;
        vm.save = save;
        vm.edit = edit;
        vm.searchDetail = searchDetail;
        vm.searchAllocate = searchAllocate;
        vm.searchReceipt = searchReceipt;
        vm.searchAll = searchAll;
        
      //列表页功能
        vm.tableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()
                }, params.filter());
                return InnerTransfer.list({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });


        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function searchAllocate(t) {
            t.allocateTableParams.reload();
        }

        function searchReceipt(t) {
            t.targetTableParams.reload();
        }

        function removeTab(tabs, idx, isVm) {
            tabs.splice(idx, 1);
            if (isVm) {
                vm.active = idx;
            }
        }

        //新建
        function create() {
            InnerTransfer.getTransferNum({}, {}, function (resp) {
            }).$promise.then(function (data) {
                var entity = {
                    isCreate: true,
                    heading: '新建',
                    contentId: "edit.html",
                    data: {
                        transferNum: data.content.transferNum,
                        extTransferNum: null,
                        type: 10,
                        status: 0,
                    },
                    active: true
                };
                entity.detailTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.transferNum = data.content.transferNum;
                        return InnerTransfer.detailList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                 entity.targetTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.transferNum = data.content.transferNum;
                        return InnerTransfer.receiptList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.allocateTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.transferNum = data.content.transferNum;
                        return InnerTransfer.allocateList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.logTableParams = new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.transferNum = data.content.transferNum;
                        return InnerTransfer.logList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        }

        //编辑
        function edit(row) {
            console.log(row);
            var entity = {
                isCreate: false,
                heading: '编辑【' + row.transferNum + '】',
                contentId: "edit.html",
                detailTableParams: new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.transferNum = row.transferNum;
                        return InnerTransfer.detailList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                targetTableParams : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    }, params.filter());
                    postParams.transferNum = row.transferNum;
                    return InnerTransfer.receiptList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
            }),
            allocateTableParams : new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    }, params.filter());
                    postParams.transferNum = row.transferNum;
                    return InnerTransfer.allocateList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
            }),
                logTableParams: new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.transferNum = row.transferNum;
                        return InnerTransfer.logList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                tabs: [],
                activeCount: 0,
                data: row,
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function searchDetail(t) {
            t.detailTableParams.reload();
        }

        function searchAll(t) {
            t.targetTableParams.reload();
            t.detailTableParams.reload();
            t.logTableParams.reload();
            t.allocateTableParams.reload();
            vm.tableParams.reload();
        }

        //保存
        function save(t, index, form) {
            if (!t.data.type) {
                toastr.info("请先选择转移类型", "提示");
                return;
            }
            if (!t.data.originalOwner) {
                toastr.info("请先选择原货主", "提示");
                return;
            }
            if(t.data.type==10){
                t.data.targetOwner=t.data.originalOwner;
            }

            if (!t.data.targetOwner) {
                toastr.info("请先选择目标货主", "提示");
                return;
            }
            InnerTransfer.saveAndUpdate({}, t.data, function (resp) {
            }).$promise.then(function (data) {
                t.isCreate ? toastr.success("新增成功", "成功") : toastr.success("保存成功", "成功");
                t.isCreate = false;
                t.data = data.content;
                vm.tableParams.reload();
            });
        }

        vm.cancel = function (t) {
            var list = [];
            if(t){
                list.push(t.data.id);
            }else {
                var selected = vm.tableParams.getSelected();
                if(selected.length==0){
                    toastr.error("请先选择转移单！","");
                    return
                }
                for (var i = 0; i < selected.length; i++) {
                    list.push(selected[i].id);
                }
            }
            InnerTransfer.cancel({}, list, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("取消成功!", "成功");
                if(t){
                    t.data=data.content;
                }
                vm.tableParams.reload();
            });
        }

        vm.close = function (t) {
            var list = [];
            if(t){
                list.push(t.data.id);
            }else {
                var selected = vm.tableParams.getSelected();
                if(selected.length==0){
                    toastr.error("请先选择转移单！","");
                    return
                }
                for (var i = 0; i < selected.length; i++) {
                    list.push(selected[i].id);
                }
            }
            InnerTransfer.close({}, list, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("关闭成功!", "成功");
                if(t){
                    t.data=data.content;
                }
                vm.tableParams.reload();
            });
        }

        vm.delDetail = function (t,row) {
            var vo = {
                id : t.data.id,
                ids : []
            }
            if(row){
                vo.ids.push(row.id);
            }else {
                var list = t.detailTableParams.getSelected();
                if (list.length == 0) {
                    toastr.error("请先选择明细", "");
                    return;
                }
                for (var i = 0; i < list.length; i++) {
                    vo.ids.push(list[i].id);
                }
            }
            InnerTransfer.delDetails({}, vo, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("删除成功", "成功");
                t.detailTableParams.reload();
            });
        }

        vm.allocate = function (t) {
            InnerTransfer.allocate({}, t.data.id, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("分配中请稍候", "成功");
                t.data=data.content;
                vm.searchAll(t);

            });
        }

        vm.executeTransfer = function (t) {
            InnerTransfer.executeTransfer({}, t.data.id, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("转移成功", "成功");
                t.data=data.content;
                vm.searchAll(t);
            });
        }

        vm.editOrAddDetail = function (t, row) {
            var d = {};
            var isEdit = false;
            var status = 1;
            var scValueField = ['ownerCode','skuCode','lot','locCode','lpn','caseNum'];
            if(row){
                d = angular.copy(row);
                isEdit = true;
                if(d.addMethod==0){
                    d.addMethod=false;
                }else {
                    d.addMethod=true;
                }


                var scValue = {
                    ownerCode:t.data.originalOwner,
                    skuCode:d.originalSkuCode,
                    lot:d.originalLot,
                    locCode:d.locCode,
                    lpn:d.lpn,
                    caseNum:d.caseNum
                };
            }else{
                d = {
                    transferNum:t.data.transferNum,
                    addMethod:false,
                }
            }
            InnerTransfer.getTransferLineNum({}, {}, function (resp) {
                d.lineNum = resp.content;
            });
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addDetail.html',
                controller: scConfirmModal,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item: {
                        scValue: scValue,
                        scValueField: scValueField,
                        heading: '添加转移明细',
                        isEdit: isEdit,
                        storageObj: null,
                        storageObjT: null,
                        row: t,
                        data: d
                    }
                }
            });
            /*vm.modalInstance.result.then(function (item) {
             $timeout(function () {
             vm.saveAndUpdateDetail(item);
             }, 0);
             });*/
        }

        scConfirmModal.$inject = ['$uibModalInstance', 'item'];
        function scConfirmModal($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.lotAttrObjT = {};
            $ctrl.lotAttrObjO = {};
            $ctrl.eventSkuChangeHandlerO = function () {
                if(!$ctrl.item.data.id){$ctrl.notFirst=true};
                if ($ctrl.item.row.data.type == 10) {
                    if ($ctrl.item.data.addMethod == false) {
                        InnerTransfer.getSkuRuleInfo({}, {
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code
                        }, function (resp) {
                            var tData = resp.content;
                            if ($ctrl.notFirst) {
                                $ctrl.item.data.originalPack = tData.defaultPackCode;
                                $ctrl.item.data.targetPack = tData.defaultPackCode;
                                var lotRef = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.code,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "SHIP",
                                    needDefault:false,
                                    needRequire:false
                                };
                                $ctrl.lotAttrObjO = angular.copy(lotRef);
                                lotRef.bizType = "RECEIPT";
                                lotRef.needDefault=false;
                                lotRef.needRequire=false;
                                $ctrl.lotAttrObjT = angular.copy(lotRef);
                            } else {
                                var lotRefO = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.code,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "SHIP",
                                    needDefault:false,
                                    needRequire:false
                                };
                                var lotRefT = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.code,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "RECEIPT",
                                    needDefault:false,
                                    needRequire:false
                                };

                                if ($ctrl.item.data.lotAttribute01T) {
                                    lotRefT.lotAttribute01 = $ctrl.item.data.lotAttribute01T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute02T) {
                                    lotRefT.lotAttribute02 = $ctrl.item.data.lotAttribute02T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute03T) {
                                    lotRefT.lotAttribute03 = $ctrl.item.data.lotAttribute03T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute04T) {
                                    lotRefT.lotAttribute04 = $ctrl.item.data.lotAttribute04T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute05T) {
                                    lotRefT.lotAttribute05 = $ctrl.item.data.lotAttribute05T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute06T) {
                                    lotRefT.lotAttribute06 = $ctrl.item.data.lotAttribute06T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute07T) {
                                    lotRefT.lotAttribute07 = $ctrl.item.data.lotAttribute07T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute08T) {
                                    lotRefT.lotAttribute08 = $ctrl.item.data.lotAttribute08T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute09T) {
                                    lotRefT.lotAttribute09 = $ctrl.item.data.lotAttribute09T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute10T) {
                                    lotRefT.lotAttribute10 = $ctrl.item.data.lotAttribute10T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute11T) {
                                    lotRefT.lotAttribute11 = $ctrl.item.data.lotAttribute11T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute12T) {
                                    lotRefT.lotAttribute12 = $ctrl.item.data.lotAttribute12T
                                }
                                ;

                                if ($ctrl.item.data.lotAttribute01O) {
                                    lotRefO.lotAttribute01 = $ctrl.item.data.lotAttribute01O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute02O) {
                                    lotRefO.lotAttribute02 = $ctrl.item.data.lotAttribute02O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute03O) {
                                    lotRefO.lotAttribute03 = $ctrl.item.data.lotAttribute03O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute04O) {
                                    lotRefO.lotAttribute04 = $ctrl.item.data.lotAttribute04O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute05O) {
                                    lotRefO.lotAttribute05 = $ctrl.item.data.lotAttribute05O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute06O) {
                                    lotRefO.lotAttribute06 = $ctrl.item.data.lotAttribute06O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute07O) {
                                    lotRefO.lotAttribute07 = $ctrl.item.data.lotAttribute07O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute08O) {
                                    lotRefO.lotAttribute08 = $ctrl.item.data.lotAttribute08O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute09O) {
                                    lotRefO.lotAttribute09 = $ctrl.item.data.lotAttribute09O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute10O) {
                                    lotRefO.lotAttribute10 = $ctrl.item.data.lotAttribute10O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute11O) {
                                    lotRefO.lotAttribute11 = $ctrl.item.data.lotAttribute11O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute12O) {
                                    lotRefO.lotAttribute12 = $ctrl.item.data.lotAttribute12O
                                }
                                ;
                                $ctrl.lotAttrObjO = angular.copy(lotRefO);
                                $ctrl.lotAttrObjT = angular.copy(lotRefT);
                                $ctrl.notFirst = true;
                            }
                        });
                    } else {
                        InnerTransfer.getSkuRuleInfo({}, {
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.skuCode
                        }, function (resp) {
                            var tData = resp.content;

                            if ($ctrl.notFirst) {
                                $ctrl.item.data.originalPack = tData.defaultPackCode;
                                $ctrl.item.data.targetPack = tData.defaultPackCode;
                                var lotRef = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.skuCode,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "RECEIPT",
                                    needDefault:false,
                                    needRequire:false
                                };
                                $ctrl.item.data.originalPack = tData.defaultPackCode;
                                $ctrl.item.data.targetPack = tData.defaultPackCode;
                                $ctrl.lotAttrObjT = angular.copy(lotRef);
                            } else {
                                var lotRefT = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.skuCode,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "RECEIPT",
                                    needDefault:false,
                                    needRequire:false
                                };
                                
                                if ($ctrl.item.data.lotAttribute01T) {
                                    lotRefT.lotAttribute01 = $ctrl.item.data.lotAttribute01T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute02T) {
                                    lotRefT.lotAttribute02 = $ctrl.item.data.lotAttribute02T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute03T) {
                                    lotRefT.lotAttribute03 = $ctrl.item.data.lotAttribute03T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute04T) {
                                    lotRefT.lotAttribute04 = $ctrl.item.data.lotAttribute04T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute05T) {
                                    lotRefT.lotAttribute05 = $ctrl.item.data.lotAttribute05T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute06T) {
                                    lotRefT.lotAttribute06 = $ctrl.item.data.lotAttribute06T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute07T) {
                                    lotRefT.lotAttribute07 = $ctrl.item.data.lotAttribute07T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute08T) {
                                    lotRefT.lotAttribute08 = $ctrl.item.data.lotAttribute08T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute09T) {
                                    lotRefT.lotAttribute09 = $ctrl.item.data.lotAttribute09T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute10T) {
                                    lotRefT.lotAttribute10 = $ctrl.item.data.lotAttribute10T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute11T) {
                                    lotRefT.lotAttribute11 = $ctrl.item.data.lotAttribute11T
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute12T) {
                                    lotRefT.lotAttribute12 = $ctrl.item.data.lotAttribute12T
                                }
                                ;

                                $ctrl.lotAttrObjT = angular.copy(lotRefT);
                                $ctrl.notFirst = true;
                            }
                        });
                        return
                    }
                }
                if ($ctrl.item.row.data.type == 20) {
                    if ($ctrl.item.data.addMethod == false) {

                        InnerTransfer.getSkuRuleInfo({}, {
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code
                        }, function (resp) {
                            var tData = resp.content;

                            if ($ctrl.notFirst) {
                                $ctrl.item.data.originalPack = tData.defaultPackCode;
                                var lotRefO = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.code,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "SHIP",
                                    needDefault:false,
                                    needRequire:false
                                };
                                $ctrl.lotAttrObjO = angular.copy(lotRefO);
                            } else {
                                var lotRefO = {
                                    tenantId: $ctrl.item.storageObj.tenantId,
                                    ownerCode: $ctrl.item.storageObj.ownerCode,
                                    skuCode: $ctrl.item.storageObj.code,
                                    warehouseCode: tData.warehouseCode,
                                    lotRule: tData.lotattributeRule,
                                    bizType: "SHIP",
                                    needDefault:false,
                                    needRequire:false
                                };

                                if ($ctrl.item.data.lotAttribute01O) {
                                    lotRefO.lotAttribute01 = $ctrl.item.data.lotAttribute01O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute02O) {
                                    lotRefO.lotAttribute02 = $ctrl.item.data.lotAttribute02O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute03O) {
                                    lotRefO.lotAttribute03 = $ctrl.item.data.lotAttribute03O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute04O) {
                                    lotRefO.lotAttribute04 = $ctrl.item.data.lotAttribute04O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute05O) {
                                    lotRefO.lotAttribute05 = $ctrl.item.data.lotAttribute05O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute06O) {
                                    lotRefO.lotAttribute06 = $ctrl.item.data.lotAttribute06O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute07O) {
                                    lotRefO.lotAttribute07 = $ctrl.item.data.lotAttribute07O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute08O) {
                                    lotRefO.lotAttribute08 = $ctrl.item.data.lotAttribute08O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute09O) {
                                    lotRefO.lotAttribute09 = $ctrl.item.data.lotAttribute09O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute10O) {
                                    lotRefO.lotAttribute10 = $ctrl.item.data.lotAttribute10O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute11O) {
                                    lotRefO.lotAttribute11 = $ctrl.item.data.lotAttribute11O
                                }
                                ;
                                if ($ctrl.item.data.lotAttribute12O) {
                                    lotRefO.lotAttribute12 = $ctrl.item.data.lotAttribute12O
                                }
                                ;
                                $ctrl.lotAttrObjO = angular.copy(lotRefO);
                                $ctrl.notFirst = true;
                            }
                        });
                    }

                }
                ;
            }

            $ctrl.eventSkuChangeHandlerT = function () {
                if($ctrl.item.storageObj.code){
                    InnerTransfer.getSkuRuleInfo({}, {
                        ownerCode: $ctrl.item.storageObj.ownerCode,
                        skuCode: $ctrl.item.storageObj.code
                    }, function (resp) {
                        var tData = resp.content;

                        // 设置默认值（在点击编辑按钮后第一次数据加载不执行下面动作，其他地方都将执行）
                        // 批次属性
                        var lotRef = {
                            tenantId: $ctrl.item.storageObj.tenantId,
                            ownerCode: $ctrl.item.storageObj.ownerCode,
                            skuCode: $ctrl.item.storageObj.code,
                            warehouseCode: tData.warehouseCode,
                            lotRule: tData.lotattributeRule,
                            bizType: "RECEIPT",
                            needDefault:false,
                            needRequire:false
                        };
                        $ctrl.item.data.targetPack = tData.defaultPackCode;
                        $ctrl.lotAttrObjT = lotRef;
                    });
                    return;
                }
                // 获取商品批次属性信息
                InnerTransfer.getSkuRuleInfo({}, {
                    ownerCode: $ctrl.item.storageObj.ownerCode,
                    skuCode: $ctrl.item.storageObj.skuCode
                }, function (resp) {
                    var tData = resp.content;

                    // 设置默认值（在点击编辑按钮后第一次数据加载不执行下面动作，其他地方都将执行）
                    // 批次属性
                    var lotRef = {
                        tenantId: $ctrl.item.storageObj.tenantId,
                        ownerCode: $ctrl.item.storageObj.ownerCode,
                        skuCode: $ctrl.item.storageObj.skuCode,
                        warehouseCode: tData.warehouseCode,
                        lotRule: tData.lotattributeRule,
                        bizType: "RECEIPT",
                        needDefault:false,
                        needRequire:false
                    };
                    $ctrl.item.data.targetPack = tData.defaultPackCode;
                    $ctrl.lotAttrObjT = lotRef;
                });
            };

            $ctrl.eventSkuChangeHandlerTSku = function () {
             InnerTransfer.getSkuRuleInfo({}, {
             ownerCode: $ctrl.item.storageObjT.ownerCode,
             skuCode: $ctrl.item.storageObjT.code
             }, function (resp) {
             var tData = resp.content;
                 if(!$ctrl.item.data.id){$ctrl.notFirstS=true};

             // 设置默认值（在点击编辑按钮后第一次数据加载不执行下面动作，其他地方都将执行）
             // 批次属性
                 if($ctrl.notFirstS){
                     var lotRef = {
                         tenantId: $ctrl.item.storageObjT.tenantId,
                         ownerCode: $ctrl.item.storageObjT.ownerCode,
                         skuCode: $ctrl.item.storageObjT.code,
                         warehouseCode: tData.warehouseCode,
                         lotRule: tData.lotattributeRule,
                         bizType: "RECEIPT",
                         needDefault:false,
                         needRequire:false
                     };
                     $ctrl.item.data.targetPack = tData.defaultPackCode;
                     $ctrl.lotAttrObjT = lotRef;
                 }else {
                     var lotRef = {
                         tenantId: $ctrl.item.storageObjT.tenantId,
                         ownerCode: $ctrl.item.storageObjT.ownerCode,
                         skuCode: $ctrl.item.storageObjT.code,
                         warehouseCode: tData.warehouseCode,
                         lotRule: tData.lotattributeRule,
                         bizType: "RECEIPT",
                         needDefault:false,
                         needRequire:false
                     };
                     if($ctrl.item.data.lotAttribute01T){lotRef.lotAttribute01=$ctrl.item.data.lotAttribute01T};
                     if($ctrl.item.data.lotAttribute02T){lotRef.lotAttribute02=$ctrl.item.data.lotAttribute02T};
                     if($ctrl.item.data.lotAttribute03T){lotRef.lotAttribute03=$ctrl.item.data.lotAttribute03T};
                     if($ctrl.item.data.lotAttribute04T){lotRef.lotAttribute04=$ctrl.item.data.lotAttribute04T};
                     if($ctrl.item.data.lotAttribute05T){lotRef.lotAttribute05=$ctrl.item.data.lotAttribute05T};
                     if($ctrl.item.data.lotAttribute06T){lotRef.lotAttribute06=$ctrl.item.data.lotAttribute06T};
                     if($ctrl.item.data.lotAttribute07T){lotRef.lotAttribute07=$ctrl.item.data.lotAttribute07T};
                     if($ctrl.item.data.lotAttribute08T){lotRef.lotAttribute08=$ctrl.item.data.lotAttribute08T};
                     if($ctrl.item.data.lotAttribute09T){lotRef.lotAttribute09=$ctrl.item.data.lotAttribute09T};
                     if($ctrl.item.data.lotAttribute10T){lotRef.lotAttribute10=$ctrl.item.data.lotAttribute10T};
                     if($ctrl.item.data.lotAttribute11T){lotRef.lotAttribute11=$ctrl.item.data.lotAttribute11T};
                     if($ctrl.item.data.lotAttribute12T){lotRef.lotAttribute12=$ctrl.item.data.lotAttribute12T};
                     $ctrl.lotAttrObjT = lotRef;
                     $ctrl.notFirstS=true;
                 }

             });
             };

            function ok() {
                //批次转移
                if ($ctrl.item.row.data.type === 10) {
                    var storageObj = $ctrl.item.storageObj;
                    var data = $ctrl.item.data;
                    if(storageObj==null){
                        toastr.error("请选择原商品","");
                        return;
                    }
                    if(data.targetPack==null){
                        toastr.error("请选择目标商品包装","");
                        return;
                    }
                    if(data.planTransferQty==null){
                        toastr.error("请填写计划数量！","");
                        return;
                    }
                    if(!(data.planTransferQty%1 === 0)){
                        toastr.error("计划转移数量必须为正整数", "提示");
                        return;
                    }
                    if(data.addMethod==false){
                        if(data.originalPack==null){
                            toastr.error("请选择原商品包装","");
                            return;
                        }
                        data.originalSkuCode = storageObj.code;
                        data.targetSkuCode = storageObj.code;
                        data.targetSkuName = storageObj.name;
                        data.originalSkuName = storageObj.name;
                        data.addMethod=0;
                        if($ctrl.lotAttrObjO){
                            if($ctrl.lotAttrObjO.lotAttribute01){data.lotAttribute01O=$ctrl.lotAttrObjO.lotAttribute01};
                            if($ctrl.lotAttrObjO.lotAttribute02){data.lotAttribute02O=$ctrl.lotAttrObjO.lotAttribute02};
                            if($ctrl.lotAttrObjO.lotAttribute03){data.lotAttribute03O=$ctrl.lotAttrObjO.lotAttribute03};
                            if($ctrl.lotAttrObjO.lotAttribute04){data.lotAttribute04O=$ctrl.lotAttrObjO.lotAttribute04};
                            if($ctrl.lotAttrObjO.lotAttribute05){data.lotAttribute05O=$ctrl.lotAttrObjO.lotAttribute05};
                            if($ctrl.lotAttrObjO.lotAttribute06){data.lotAttribute06O=$ctrl.lotAttrObjO.lotAttribute06};
                            if($ctrl.lotAttrObjO.lotAttribute07){data.lotAttribute07O=$ctrl.lotAttrObjO.lotAttribute07};
                            if($ctrl.lotAttrObjO.lotAttribute08){data.lotAttribute08O=$ctrl.lotAttrObjO.lotAttribute08};
                            if($ctrl.lotAttrObjO.lotAttribute09){data.lotAttribute09O=$ctrl.lotAttrObjO.lotAttribute09};
                            if($ctrl.lotAttrObjO.lotAttribute10){data.lotAttribute00O=$ctrl.lotAttrObjO.lotAttribute10};
                            if($ctrl.lotAttrObjO.lotAttribute11){data.lotAttribute11O=$ctrl.lotAttrObjO.lotAttribute11};
                            if($ctrl.lotAttrObjO.lotAttribute12){data.lotAttribute12O=$ctrl.lotAttrObjO.lotAttribute12};
                        }
                    }else {
                        if(storageObj.availableQty<data.planTransferQty){
                            toastr.error("计划转移数量不能大于等于可转移量","");
                            return;
                        }
                        if(!(data.planTransferQty%1 === 0)){
                            toastr.error("计划转移数量必须为正整数", "提示");
                            return;
                        }
                        data.originalSkuCode = storageObj.skuCode;
                        data.targetSkuCode = storageObj.skuCode;
                        data.targetSkuName = storageObj.skuName;
                        data.originalSkuName = storageObj.skuName;
                        data.originalLot = storageObj.lot;
                        data.locCode = storageObj.locCode;
                        data.lpn = storageObj.lpn;
                        data.caseNum = storageObj.caseNum;
                        data.addMethod=1;
                    }
                    if($ctrl.lotAttrObjT){
                        if($ctrl.lotAttrObjT.lotAttribute01){data.lotAttribute01T=$ctrl.lotAttrObjT.lotAttribute01};
                        if($ctrl.lotAttrObjT.lotAttribute02){data.lotAttribute02T=$ctrl.lotAttrObjT.lotAttribute02};
                        if($ctrl.lotAttrObjT.lotAttribute03){data.lotAttribute03T=$ctrl.lotAttrObjT.lotAttribute03};
                        if($ctrl.lotAttrObjT.lotAttribute04){data.lotAttribute04T=$ctrl.lotAttrObjT.lotAttribute04};
                        if($ctrl.lotAttrObjT.lotAttribute05){data.lotAttribute05T=$ctrl.lotAttrObjT.lotAttribute05};
                        if($ctrl.lotAttrObjT.lotAttribute06){data.lotAttribute06T=$ctrl.lotAttrObjT.lotAttribute06};
                        if($ctrl.lotAttrObjT.lotAttribute07){data.lotAttribute07T=$ctrl.lotAttrObjT.lotAttribute07};
                        if($ctrl.lotAttrObjT.lotAttribute08){data.lotAttribute08T=$ctrl.lotAttrObjT.lotAttribute08};
                        if($ctrl.lotAttrObjT.lotAttribute09){data.lotAttribute09T=$ctrl.lotAttrObjT.lotAttribute09};
                        if($ctrl.lotAttrObjT.lotAttribute10){data.lotAttribute10T=$ctrl.lotAttrObjT.lotAttribute10};
                        if($ctrl.lotAttrObjT.lotAttribute11){data.lotAttribute11T=$ctrl.lotAttrObjT.lotAttribute11};
                        if($ctrl.lotAttrObjT.lotAttribute12){data.lotAttribute12T=$ctrl.lotAttrObjT.lotAttribute12};
                    }

                    InnerTransfer.saveAndUpdateDetail({},data,function(resp){
                    }).$promise.then(function(data){
                        toastr.success("保存成功","");
                        $ctrl.item.row.detailTableParams.reload();
                        $uibModalInstance.close(item);
                    });
                }

                //如果是按商品移动
                if ($ctrl.item.row.data.type === 20) {
                    var storageObj = $ctrl.item.storageObj;
                    var storageObjT = $ctrl.item.storageObjT;
                    var data = $ctrl.item.data;
                    if(storageObj==null){
                        console.log(storageObj);

                        toastr.error("请选择原商品","");
                        return;
                    }
                    if(storageObjT==null){
                        toastr.error("请选择目标商品","");
                        return;
                    }
                    if(data.targetPack==null){
                        toastr.error("请选择目标商品包装","");
                        return;
                    }
                    if(data.planTransferQty==null){
                        toastr.error("请填写计划数量！","");
                        return;
                    }
                    if(!(data.planTransferQty%1 === 0)){
                        toastr.error("计划转移数量必须为正整数", "提示");
                        return;
                    }
                    if(data.addMethod==false){
                        if(data.originalPack==null){
                            toastr.error("请选择原商品包装","");
                            return;
                        }
                        data.originalSkuCode = storageObj.code;
                        data.originalSkuName = storageObj.name;
                        data.addMethod=0;
                        if($ctrl.lotAttrObjO){
                            if($ctrl.lotAttrObjO.lotAttribute01){data.lotAttribute01O=$ctrl.lotAttrObjO.lotAttribute01};
                            if($ctrl.lotAttrObjO.lotAttribute02){data.lotAttribute02O=$ctrl.lotAttrObjO.lotAttribute02};
                            if($ctrl.lotAttrObjO.lotAttribute03){data.lotAttribute03O=$ctrl.lotAttrObjO.lotAttribute03};
                            if($ctrl.lotAttrObjO.lotAttribute04){data.lotAttribute04O=$ctrl.lotAttrObjO.lotAttribute04};
                            if($ctrl.lotAttrObjO.lotAttribute05){data.lotAttribute05O=$ctrl.lotAttrObjO.lotAttribute05};
                            if($ctrl.lotAttrObjO.lotAttribute06){data.lotAttribute06O=$ctrl.lotAttrObjO.lotAttribute06};
                            if($ctrl.lotAttrObjO.lotAttribute07){data.lotAttribute07O=$ctrl.lotAttrObjO.lotAttribute07};
                            if($ctrl.lotAttrObjO.lotAttribute08){data.lotAttribute08O=$ctrl.lotAttrObjO.lotAttribute08};
                            if($ctrl.lotAttrObjO.lotAttribute09){data.lotAttribute09O=$ctrl.lotAttrObjO.lotAttribute09};
                            if($ctrl.lotAttrObjO.lotAttribute10){data.lotAttribute10O=$ctrl.lotAttrObjO.lotAttribute10};
                            if($ctrl.lotAttrObjO.lotAttribute11){data.lotAttribute11O=$ctrl.lotAttrObjO.lotAttribute11};
                            if($ctrl.lotAttrObjO.lotAttribute12){data.lotAttribute12O=$ctrl.lotAttrObjO.lotAttribute12};
                        }
                    }else {
                        if(storageObj.availableQty<data.planTransferQty){
                            toastr.error("计划转移数量不能大于等于可转移量","");
                            return;
                        }
                        if(!(data.planTransferQty%1 === 0)){
                            toastr.error("计划转移数量必须为正整数", "提示");
                            return;
                        }
                        data.originalSkuCode = storageObj.skuCode;
                        data.originalSkuName = storageObj.skuName;
                        data.originalLot = storageObj.lot;
                        data.locCode = storageObj.locCode;
                        data.lpn = storageObj.lpn;
                        data.caseNum = storageObj.caseNum;
                        data.addMethod=1;
                    }
                    data.targetSkuCode = storageObjT.code;
                    data.targetSkuName = storageObjT.name;
                    if($ctrl.lotAttrObjT){
                        if($ctrl.lotAttrObjT.lotAttribute01){data.lotAttribute01T=$ctrl.lotAttrObjT.lotAttribute01};
                        if($ctrl.lotAttrObjT.lotAttribute02){data.lotAttribute02T=$ctrl.lotAttrObjT.lotAttribute02};
                        if($ctrl.lotAttrObjT.lotAttribute03){data.lotAttribute03T=$ctrl.lotAttrObjT.lotAttribute03};
                        if($ctrl.lotAttrObjT.lotAttribute04){data.lotAttribute04T=$ctrl.lotAttrObjT.lotAttribute04};
                        if($ctrl.lotAttrObjT.lotAttribute05){data.lotAttribute05T=$ctrl.lotAttrObjT.lotAttribute05};
                        if($ctrl.lotAttrObjT.lotAttribute06){data.lotAttribute06T=$ctrl.lotAttrObjT.lotAttribute06};
                        if($ctrl.lotAttrObjT.lotAttribute07){data.lotAttribute07T=$ctrl.lotAttrObjT.lotAttribute07};
                        if($ctrl.lotAttrObjT.lotAttribute08){data.lotAttribute08T=$ctrl.lotAttrObjT.lotAttribute08};
                        if($ctrl.lotAttrObjT.lotAttribute09){data.lotAttribute09T=$ctrl.lotAttrObjT.lotAttribute09};
                        if($ctrl.lotAttrObjT.lotAttribute10){data.lotAttribute10T=$ctrl.lotAttrObjT.lotAttribute10};
                        if($ctrl.lotAttrObjT.lotAttribute11){data.lotAttribute11T=$ctrl.lotAttrObjT.lotAttribute11};
                        if($ctrl.lotAttrObjT.lotAttribute12){data.lotAttribute12T=$ctrl.lotAttrObjT.lotAttribute12};
                    }

                    InnerTransfer.saveAndUpdateDetail({},data,function(resp){
                    }).$promise.then(function(data){
                        toastr.success("保存成功","");
                        $ctrl.item.row.detailTableParams.reload();
                        $uibModalInstance.close(item);
                    });
                }

            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.plan = function(t){

        vm.modalInstance = $uibModal.open({
            templateUrl: 'plan.html',
            controller: scConfirmModal2,
            controllerAs: '$ctrl',
            backdrop: 'static',
            resolve: {
                item:{
                    heading:'计划',
                    data:t
                }
            }
        });
    };
    scConfirmModal2.$inject = ['$uibModalInstance', 'item'];
    function scConfirmModal2($uibModalInstance,item) {
        var $ctrl = this;
        $ctrl.item = item;
        $ctrl.ok = ok;
        $ctrl.planTime = null;
        $ctrl.cancel = cancel;
        function ok() {
            if($ctrl.planTime == null){
                toastr.error("请选择计划时间！","")
                return;
            }
            var list = [];
            var t = $ctrl.item ;
            if(t.data){
                list.push(t.data.data.id);
            }else {
                var selected = vm.tableParams.getSelected();
                if(selected.length==0){
                    toastr.error("请先选择转移单！","");
                    return
                }
                for (var i = 0; i < selected.length; i++) {
                    list.push(selected[i].id);
                }
            }
            var vo = {
                ids : list,
                planTransferDt : $ctrl.planTime
            }
            InnerTransfer.plan({}, vo, function (resp) {
            }).$promise.then(function (data) {
                toastr.success("计划成功!", "成功");
                if(t.data){
                    t.data.data.status=5;
                }
                vm.tableParams.reload();
            });
            $uibModalInstance.close(item);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
    
    }
})();
