(function() {
    "use strict";
    angular.module("itms-dataModule").factory("TmsFleet", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsDataHost + "/web/fleet", {}, {
        	'list': { url: iServiceConfig.itmsDataHost + "/web/fleet/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsDataHost + "/web/fleet/saveAndUpdate", method: 'POST'},
            'show': {url: iServiceConfig.itmsDataHost + "/web/fleet/show", method: 'POST'},
            'delete': {url: iServiceConfig.itmsDataHost + "/web/fleet/delete", method: 'POST'},
            'enable': {url: iServiceConfig.itmsDataHost + "/web/fleet/enable", method: 'POST'},
            'validateCode': {url: iServiceConfig.itmsDataHost + "/web/fleet/validateCode", method: 'POST'}
        });
    }]);
})();