(function()  {
    'use strict';

    angular.module('iwh-receiptModule').controller('NewGoodsController', NewGoodsController);
    NewGoodsController.$inject = ["$rootScope", "$scope", "NgTableParams",
        "$uibModal", "NgSelect", "$state", "$timeout", "toastr", "NgTree", "NewGoods"];
    function NewGoodsController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                               $state, $timeout, toastr, NgTree, NewGoods) {
        var vm = this;
        vm.messageStyles = {
            normal : "messageBlockNormal",
            newGoods : "messageBlockNewGoods",
            errorInfo : "messageBlockErrorInfo"
        };
        vm.message = {
            normal : "请输入商品条码",
            newGoods : "是新品",
            noNewGoods : "不是新品"
        };

        vm.tabs = []; // 记录所有的tab
        vm.active = 0; // 总共打开tab的总数
        vm.goodsData = {}; // 商品数据
        vm.skuCode = ""; // 商品编码 SKU201705030001
        vm.skuName; // 商品名称
        vm.ownerObject={}; // 货主对象
        vm.isNewGoods=false; // 是否是新品（true-是，false-不是）
        vm.messageBlockStyleName = vm.messageStyles.normal;
        vm.hintMessage = vm.message.normal;
        vm.packList = []; // 包装列表


        vm.eventKeyUpSkuCodeHandler = eventKeyUpSkuCodeHandler; // 处理商品代码输入按键松开事件
        vm.eventChangeSkuCodeHandler = eventChangeSkuCodeHandler;
        vm.serviceCheckGoodsIsNew = serviceCheckGoodsIsNew; // 检查当前商品是否是新品商品
        vm.serviceSubmitNewGoods = serviceSubmitNewGoods; // 提交新品商品信息到服务端进行保存
        vm.eventOwnerChangeHandler = eventOwnerChangeHandler; // 货主改变事件


        function eventOwnerChangeHandler(ownerCode, ownerObj) {
            serviceCheckGoodsIsNew(vm);
        }

        function eventKeyUpSkuCodeHandler(evt, scope) {
            // IE编码包含在window.event.keyCode中，Firefox或Safari包含在event.which中IE11+
            var keycode = window.event ? evt.keyCode : (evt.which || evt.keyCode);
            if ( keycode == 13 ) {
                serviceCheckGoodsIsNew(scope);
            }
        }

        function eventChangeSkuCodeHandler(evt, scope) {
            scope.skuName = "";
            scope.messageBlockStyleName = scope.messageStyles.normal;
            scope.hintMessage = scope.message.normal;
        }

        function serviceCheckGoodsIsNew(scope){
            if ( (scope.skuCode || "").replace(/(^\s+)|(\s+$)/g, "") == "" ) {
                toastr.error("商品条码不能为空！");
                return;
            }

            if ( undefined == scope.ownerObject ||
                (scope.ownerObject.code || "").replace(/(^\s+)|(\s+$)/g, "") == "" ) {
                toastr.error("请选择货主！");
                return;
            }

            NewGoods.checkGoodsIsNew({}, {
                skuCode : scope.skuCode,
                ownerCode : scope.ownerObject.code
            }, function(resp){
                var data = resp.content;
                scope.skuName = data.skuName;
                if ( data.isNewGoods == "yes" ) {
                    scope.isNewGoods = true;
                    scope.messageBlockStyleName = scope.messageStyles.newGoods;
                    scope.hintMessage = scope.message.newGoods;

                    // 获取包装信息
                    _getPackList(scope.ownerObject.code, scope.skuCode, scope);
                } else {
                    scope.isNewGoods = false;
                    scope.messageBlockStyleName = scope.messageStyles.errorInfo;
                    scope.hintMessage = scope.message.noNewGoods;
                }
            });
        }

        function serviceSubmitNewGoods(scope) {
            var param = scope.goodsData;
            param.ownerCode = scope.ownerObject.code;
            param.skuCode = scope.skuCode;
            param.skuName = scope.skuName;
            param.packs = [];

            for (var i = 0, len = scope.packList.length; i < len; i ++ ) {
                var t = scope.packList[i];
                param.packs.push({
                    ownerCode : param.ownerCode,
                    skuCode : param.skuCode,
                    packCode : t.packCode,
                    salverNum : t.salverNum,
                    palletLength : t.palletLength,
                    palletWidth : t.palletWidth,
                    palletHeight : t.palletHeight,
                    palletCube : t.palletCube,
                    palletGrosswgt : t.palletGrosswgt,
                    palletNetwgt : t.palletNetwgt,
                    ti : t.ti,
                    hi : t.hi
                });
            }

            NewGoods.submitNewGoods({}, param, function (resp) {
                toastr.success("提交数据成功");
                _resetStatus(scope);
            });
        }


        /**
         * 重置页面状态（页面初始状态）
         * @param scope
         */
        function _resetStatus(scope) {
            // 重置消息提示和样式
            scope.hintMessage = scope.message.normal;
            scope.messageBlockStyleName = scope.messageStyles.normal;
            scope.skuCode = "";
            scope.skuName = "";
            scope.isNewGoods = false;
            scope.ownerObject = {};
            scope.goodsData = {};
            scope.packList = [];
        }

        /**
         * 根据商品编码获取包装列表
         * @param argOwnerCode
         * @param argSkuCode
         * @param scope
         */
        function _getPackList(argOwnerCode, argSkuCode, scope){
            scope.packList= [];
            NewGoods.getSkuPackListByCode({}, {
                ownerCode : argOwnerCode,
                skuCode : argSkuCode
            }, function(resp){
                scope.packList = resp.content;
            });
        }

    }
})();

