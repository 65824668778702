(function() {
    'use strict';

    angular.module('idataModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('ownerUser-maintenance', {
                parent: 'main',
                url: 'data/ownerUser-maintenance',
                data: {
                    'pageTitle': '用户管理货主'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'data-module/ownerUser/ownerUser.html',
                        controller: 'OwnerUserController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
