(function() {
    'use strict';

    angular.module('ivendorModule')
        .controller('VendorModInventoryInOutController', VendorModInventoryInOutController);

    VendorModInventoryInOutController.$inject = ['InventoryInOut', 'NgTableParams','Report','toastr'];

    function VendorModInventoryInOutController ( InventoryInOut, NgTableParams,Report, toastr) {
        var vm = this;
        vm.active = 0;
        vm.search = search;
      //列表页功能
        vm.reports = [];
        vm.loadReport = loadReport;
        function loadReport() {
        	Report.list({functionCode:"VENDOR_INVENTORY_INOUT_LIST"}, {}, function(resp) {
 				vm.reports = resp.content;
 			});
        };
        vm.loadReport();
       
        vm.whcode=[
                  		{id : null, title : "不限"}
                  ];
        vm.storeCodes =[
                		{id : null, title : "不限"}
                        ];
       
        vm.filterdata=filterdata;
       
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return InventoryInOut.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					vm.filterdata();
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();
		}
		
		function filterdata(){
			InventoryInOut.allwhouse({},{},function(resp){
				var leng=vm.whcode.length;
				vm.whcode.splice(2, leng-2);
				if(resp.content) {
					angular.forEach(resp.content, function(data,index,array){
						console.log(data.code+'='+array[index].a);
						var check = new Object();
						 check.id = data.code;
						 check.title = data.name;
						 vm.whcode.push(check);
					});
				}
				
			},function(resp){
				toastr.error(resp.data.errorMsg,"错误");
				return ;
			});
			InventoryInOut.storeCodes({},{},function(resp){
				var leng=vm.storeCodes.length;
				vm.storeCodes.splice(2, leng-2);
				
				if(resp.content) {
					angular.forEach(resp.content, function(data,index,array){
						console.log(data.code+'='+array[index].a);
						var check = new Object();
						 check.id = data.code;
						 check.title = data.name;
						 vm.storeCodes.push(check);
					});
				}
				
			},function(resp){
				toastr.error(resp.data.errorMsg,"错误");
				return ;
			});
			
			return;
		}
    }
})();

