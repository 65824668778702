(function () {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusScheduleTaskParam', iBusScheduleTaskParam);

    iBusScheduleTaskParam.$inject = ['$resource', 'iServiceConfig'];

    function iBusScheduleTaskParam($resource, iServiceConfig) {
        var service = $resource('web/scheduleTaskParam', {}, {
            'list': {url: iServiceConfig.ibusHost + "/web/scheduleTaskParam/list", method: 'POST'},
            'add': {url: iServiceConfig.ibusHost + "/web/scheduleTaskParam/add", method: 'POST'},
            'update': {url: iServiceConfig.ibusHost + "/web/scheduleTaskParam/update", method: 'POST'},
            'delete': {url: iServiceConfig.ibusHost + "/web/scheduleTaskParam/delete/:id", method: 'POST'},
            'deleteByIds': {url: iServiceConfig.ibusHost + "/web/scheduleTaskParam/deleteByIds", method: 'POST'}
        });

        return service;
    }
})();
