(function() {
    "use strict";
    angular.module("itms-dataModule").factory("TmsTransportLine", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsDataHost + "/web/transportLine", {}, {
        	'list': { url: iServiceConfig.itmsDataHost + "/web/transportLine/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsDataHost + "/web/transportLine/saveAndUpdate", method: 'POST'},
            'show': {url: iServiceConfig.itmsDataHost + "/web/transportLine/show", method: 'POST'},
            'enable': {url: iServiceConfig.itmsDataHost + "/web/transportLine/enable", method: 'POST'},
            'delete': {url: iServiceConfig.itmsDataHost + "/web/transportLine/delete", method: 'POST'},
            'validateCode': {url: iServiceConfig.itmsDataHost + "/web/transportLine/validateCode", method: 'POST'}
        });
    }]);
})();