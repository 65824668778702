(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateDsAppRule', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/dsapprule', {}, {
                'getDsAppRuleList': {
                    url: iServiceConfig.iergateHost + '/web/dsapprule/getDsAppRuleList',
                    method: 'GET'
                },
                'updateOrSaveDsAppRule': {
                    url: iServiceConfig.iergateHost + '/web/dsapprule/updateOrSaveDsAppRule',
                    method: 'POST'
                },
                'deleteDsAppRule': {
                    url: iServiceConfig.iergateHost + '/web/dsapprule/deleteDsAppRule',
                    method: 'POST'
                }
            });
        }]);
})();