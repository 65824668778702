(function()  {
    'use strict';

    angular.module('iwh-strategyModule')
        .controller('StrategyReceiptConfigController', StrategyReceiptConfigController);

    StrategyReceiptConfigController.$inject = ['$rootScope','$stateParams', 'StrategyReceiptConfig', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','iServiceConfig'];

    function StrategyReceiptConfigController ($rootScope,$stateParams, StrategyReceiptConfig, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.active = 0;
        vm.active2 = 0;
        vm.tabs = [];
        vm.iwhDataHost = iServiceConfig.iwhDataHost+"/web/equipmentRest/getEquipmentList";
        vm.containerGroupHost = iServiceConfig.iwhDataHost+"/web/containerGroupRest/getContainerGroupList";
        vm.ownerCodeCreate = null;
        vm.ownerCodeEdite = null;
        vm.addDeviceData ={
            device : null,
			data : {},
            iwhDataHost : vm.iwhDataHost
		}


		vm.editeDeviceData = {
        	device : null,
			data : null,
            iwhDataHost : vm.iwhDataHost
		}

		vm.receiptConfig = null;

        vm.deviceSearch = deviceSearch;
        vm.ownerSearch = ownerSearch;

		StrategyReceiptConfig.getReceiptConfig({}, {}).$promise.then(function(resp) {
            vm.receiptConfig = resp.content;
        });
        
        function pageTitle() {
            return $window.document.title;
        }


		vm.deviceTableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return StrategyReceiptConfig.devicePage({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.ownerTableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return StrategyReceiptConfig.ownerPage({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        vm.ownerDeviceTableParams = new NgTableParams({
            count : 10
        }, {
            counts : [],
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                if(vm.ownerCodeEdite!=null){
                    postParams.ownerCode = vm.ownerCodeEdite;
                }
                return StrategyReceiptConfig.ownerDevicePage({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        function ownerSearch() {
            vm.ownerTableParams.reload();
        }

		function deviceSearch() {
			vm.deviceTableParams.reload();
		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}


        vm.saveReceiptConfig = saveReceiptConfig;
        function saveReceiptConfig() {
            StrategyReceiptConfig.saveReceiptConfig({}, vm.receiptConfig, function(resp){
                if(resp.content=="success"){
                    toastr.success("保存成功！");
                }
            });
        }



		vm.saveDevice = saveDevice;
		function saveDevice(editeDeviceData) {

            if(editeDeviceData.device==null||editeDeviceData.device==""){
                toastr.error("请先选择设备！");
                return;
            }
            editeDeviceData.data.deviceNum = editeDeviceData.device.code;
            editeDeviceData.data.deviceName = editeDeviceData.device.remark;
            var vo = editeDeviceData.data;
            StrategyReceiptConfig.saveDevice({}, vo, function(resp){
                if(resp.content=="success"){
                    toastr.success("保存成功！");
                    vm.deviceTableParams.reload();
                }
                editeDeviceData.data = null;
                editeDeviceData.device == null;
            });
        }

        vm.deleteDevice = deleteDevice;
		function deleteDevice(row) {
            StrategyReceiptConfig.deleteDevice({}, row.id, function(resp){
                if(resp.content=="success"){
                    toastr.success("删除成功！");
                    vm.deviceTableParams.reload();
                }
            });
        }

        vm.editeDevice = editeDevice
		function editeDevice(row) {
            vm.editeDeviceData.data = row;
            return vm.editeDeviceData;
        }

        vm.updateDevice = updateDevice
		function updateDevice(editeDeviceData) {
            if(editeDeviceData.device==null){
                toastr.error("请先选择设备！");
                return;
            }
            editeDeviceData.data.deviceNum = editeDeviceData.device.code;
            editeDeviceData.data.deviceName = editeDeviceData.device.remark;
            var vo = editeDeviceData.data;
            StrategyReceiptConfig.saveDevice({}, vo, function(resp){
                if(resp.content=="success"){
                    toastr.success("保存成功！");
                    vm.deviceTableParams.reload();
                }
            });
        }

		vm.createOwner = createOwner;
		function createOwner() {
            vm.tableShow = false;
 				var entity = {
					heading : '新建',
					contentId : "createOwner.html",
					active2 : true,
                    ownerDeviceTableParams : vm.ownerDeviceTableParams,
					data: {
					    owner : null,
                        receiptConfigOwner : {
                            needPrintLpn : 1,
                            needNewSku:1
                        },
                        addDeviceData :{
                            device : null,
                            data : null,
                            iwhDataHost : vm.iwhDataHost
                    }

	                },
					
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active2 = vm.tabs.length;
	            },0);
		}

        vm.saveOwnerReceiptConfig = saveOwnerReceiptConfig;
        function saveOwnerReceiptConfig(t) {
            if(t.data.owner==null){
                toastr.error("请先选择货主！");
                return;
            }
            t.data.receiptConfigOwner.ownerCode = t.data.owner.code;
            t.data.receiptConfigOwner.ownerName = t.data.owner.name;
            StrategyReceiptConfig.receiptConfigOwnerSave({}, t.data.receiptConfigOwner, function(resp){
                if(resp.content=="success"){
                    toastr.success("保存成功！");
                    vm.ownerCodeEdite = t.data.owner.code;
                    vm.tableShow = true;
                }
            });
        }

        vm.editeOwner = editeOwner;
        function editeOwner(row) {
                vm.ownerCodeEdite = row.ownerCode;
                var entity = {
                    heading : '编辑',
                    contentId : "createOwner.html",
                    active2 : true,
                    carrierTableParams : vm.carrierTableParams,
                    ownerDeviceTableParams : vm.ownerDeviceTableParams,
                    data: {
                        owner : {
                            code : row.ownerCode,
                            name : row.ownerName
                        },
                        receiptConfigOwner : row,
                        addDeviceData :{
                            device : null,
                            data : {},
                            iwhDataHost : vm.iwhDataHost
                        }

                    },

                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active2 = vm.tabs.length;
                },0);

        }

        vm.deleteOwner = deleteOwner;
        function deleteOwner(row) {
            StrategyReceiptConfig.deleteOwner({}, row.id, function(resp){
                if(resp.content=="success"){
                    toastr.success("删除成功！");
                    vm.ownerTableParams.reload();
                }
            });
        }


        vm.addOwnerDevice = addOwnerDevice;
        function addOwnerDevice(t) {
            if(t.data.owner==null){
                toastr.error("请先选择货主！");
                return;
            }
            t.data.addDeviceData.iDataHost = iServiceConfig.idataHost+"/web/carrierRest/getCarriersByOwnerCode"+"?ownerCode="+t.data.owner.code;
            return t.data.addDeviceData;
        }

        vm.saveOwnerDevice = saveOwnerDevice;
        function saveOwnerDevice(model,t) {
            if(model.device==null){
                toastr.error("请先选择设备！");
                return ;
            }
            if(model.data.id){

            }else {
                model.data.ownerCode = t.data.owner.code;
            }
            if(model.carrier){
                model.data.carrierName = model.carrier.name;
                model.data.carrierCode = model.carrier.code;
            }

            model.data.deviceNum = model.device.code;
            model.data.deviceName = model.device.remark;
            StrategyReceiptConfig.saveReceiptConfigOwnerDevice({}, model.data, function(resp){
                if(resp.content=="success"){
                    toastr.success("保存成功！");
                    model.data = {
                        device : null,
                        data : {},
                        iwhDataHost : vm.iwhDataHost
                    };
                    t.ownerDeviceTableParams.reload();
                }
            });
        }

        vm.deleteOwnerDevice = deleteOwnerDevice;
        function deleteOwnerDevice(row,t) {
            StrategyReceiptConfig.deleteOwnerDevice({}, row.id, function(resp){
                if(resp.content=="success"){
                    toastr.success("删除成功！");
                    t.ownerDeviceTableParams.reload();
                }
            });
        }

        vm.editeOwnerDevice = editeOwnerDevice;
        function editeOwnerDevice(row) {
            var rowT = vm.cloneObj(row);
            var addCarrierData = {
                device : null,
                data : rowT,
                iwhDataHost : vm.iwhDataHost,
            };
            console.log(addCarrierData);
            return addCarrierData;
        }

        vm.cloneObj = cloneObj;
        function cloneObj(obj) {
            var newObj = {};
            if (obj instanceof Array) {
                newObj = [];
            }
            for (var key in obj) {
                var val = obj[key];
                newObj[key] = typeof val === 'object' ? cloneObj(val): val;
            }
            return newObj;
        };


    }
})();
