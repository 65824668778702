(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('CarrierVehicle', CarrierVehicle);

    CarrierVehicle.$inject = ['$resource', 'iServiceConfig'];

    function CarrierVehicle ($resource, iServiceConfig) {
        var service = $resource('web/carrierVehicle', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/carrierVehicle/list", method: 'POST'},
            'saveCarrierVehicle': {url: iServiceConfig.idataHost + "/web/carrierVehicle/saveCarrierVehicle", method: 'POST'},
            'showCarrierVehicle': {url: iServiceConfig.idataHost + "/web/carrierVehicle/showCarrierVehicle", method: 'POST'},
            'deleteCarrierVehicle': {url: iServiceConfig.idataHost + "/web/carrierVehicle/deleteCarrierVehicle", method: 'POST'},
            'getCarrierCodes': {url: iServiceConfig.idataHost + "/web/carrierVehicle/getCarrierCodes", method: 'POST'},
            'validateCode': {url: iServiceConfig.idataHost + "/web/carrierVehicle/validateCode", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/carrierVehicle/enable", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/carrierVehicle/disable", method: 'POST'},
        });
        return service;
    }
})();
