(function() {
    "use strict";

    angular.module("iwh-dataModule").factory("IwhRoadway", ["$resource", "iServiceConfig", function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhDataHost + "/web/roadway", {}, {
            "getRoadwayList" : {
                url: iServiceConfig.iwhDataHost + "/web/roadway/getRoadwayList",
                method: "POST"
            },
            "saveRoadway" : {
                url: iServiceConfig.iwhDataHost + "/web/roadway/saveRoadway",
                method: "POST"
            },
            "getRoadwayById" : {
                url: iServiceConfig.iwhDataHost + "/web/roadway/getRoadwayById",
                method: "POST"
            },
            "delRoadwayById" : {
                url: iServiceConfig.iwhDataHost + "/web/roadway/delRoadwayById",
                method: "POST"
            },
            "changeRoadwayStatus" : {
                url : iServiceConfig.iwhDataHost + "/web/roadway/changeRoadwayStatus",
                method : "POST"
            }
        });
    }]);

})();