
(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleUserController', UserModuleUserController);

    UserModuleUserController.$inject = ['UserModuleUser', 'NgTableParams','$uibModal', 'Principal','$timeout','NgSelect', 'toastr'];

    function UserModuleUserController(UserModuleUser, NgTableParams, $uibModal, Principal,$timeout,NgSelect,toastr) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.active = 0;
        vm.account = {};
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'已禁用'},{'id':1, 'title':'新建'},{'id':10, 'title':'已启用'}];
        vm.statu = "";
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.update = update;
        vm.deleteData = deleteData;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.changeStatus=changeStatus;
        vm.changeData = changeData;
        vm.tenantCode = null;
        vm.employeeList = [];
        
        vm.roleList = [];
        vm.sr=null;
        vm.editpassword=editpassword;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return UserModuleUser.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.page.tc);
                    }).$promise.then(function(data) {
                    vm.tenantId = data.content.tenantId;
                    vm.userId = data.content.userId;
                    var datas = data.content.page.datas;
                    
                    return datas;
                });
            }
        });

        function changeData(row){
        	if(row.status === 10) {
        		UserModuleUser.disable({}, {
            		userId: row.userId
            		},function(resp){
        		    if(resp.status=="SUCCESS"){
        		        toastr.success("禁用成功！");
                        vm.search();
                    }
            	});
        	} else {
        		UserModuleUser.enable({}, {
            		userId: row.userId
            		},function(resp){
                    if(resp.status=="SUCCESS"){
                        toastr.success("启用成功！");
                        vm.search();
                    }
            	});
        	}
        }

        function changeStatus(row){
            vm.modalInstance = $uibModal.open({
            	templateUrl: 'user-disableAndEnable.html',
                controller: changeModal,
                controllerAs: '$ctrl',
                resolve: {
                		item: function () {
                				return row;
                        	}
                    	}
                });	
            vm.modalInstance.result.then(function(item) {
                vm.changeData(item);
            });
        }
        
        changeModal.$inject = ['$uibModalInstance', 'item'];
        function changeModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        
        }
        function search() {
            vm.tableParams.reload();
        }

        function deleteData(row){
            UserModuleUser.delete({}, {userId: row.userId}, function() {
                vm.search();
            });
        }
        
        function editpassword(row){
            vm.modalInstance = $uibModal.open({
            	templateUrl: 'user-eidtpassword.html',
                controller: editpasswordModle,
                controllerAs: '$ctrl',
                resolve: {
                	item: function() {
                		return row;
                	}
                }
                });	
        }
        
        editpasswordModle.$inject = ['$uibModalInstance', 'item'];
        function editpasswordModle($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.ok = ok;
            $ctrl.row = item;
            $ctrl.response = {};
            $ctrl.cancel = cancel;
            $ctrl.item = {userId: item.userId, newPassword:'',newPassword2:''};
            $ctrl.item.userId = $ctrl.row.userId;
            $ctrl.submit = submit;
            
            function submit(item){
            	if(item.newPassword == '' || item.newPassword2 == '' || item.newPassword != item.newPassword2){
            		$ctrl.response.errorMsg = "两次密码输入不对";
            		return;
            	}
            	UserModuleUser.editpassword({},item).$promise.then(function(resp){
            		$uibModalInstance.close();
            	},function(resp){
            		$ctrl.response.errorMsg = resp.errorMsg;
            	});
            }

            function ok() {
            	$uibModalInstance.close();
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function edit(row) {
        	UserModuleUser.detail({}, {userId: row.userId}, function(resp) {
                var data = resp.content;
                
                var entity = {
                        heading: '编辑'+row.userName,
                        contentId: "user-eidt.html",
                        data: data,
                        roles: [],
                        employeeList:vm.employeeList,
                        id:null,
                        getEmployeeId : function(obj){
                        	entity.id = obj.id;
                        	console.log(entity.id);
                        },
                        multiSelect :new NgSelect({
                        	allList:vm.roleList,
                        	list:data.roleIds,
    	                    config:{
    	                        meta:{
    	                            label:'roleName',
    	                            id:'roleId'
    	                        }
    	                    }
                    }),
                        active: true
                    };
                    vm.tabs.push(entity);
                    $timeout(function(){
                        vm.active = vm.tabs.length;
                    },0);
            	});   
        }

        function create() {
        	var entity = {
                    heading: '新建',
                    contentId: "user-eidt.html",
                    data: {
                        tenantCompanyName: vm.account.tenantName,
                        tenantCode:vm.account.tenantCode,
                        userName:"",
                        realName: "",
                        contactMobile: "",
                        userPassword: "",
                        userPassword2:"",
                        status: 1,
                    },
                    employeeList:vm.employeeList,
                    getEmployeeId : function(obj){
                    	entity.id = obj.id;
                    	console.log(entity.id);
                    },
                    roles: [],
                    multiSelect :new NgSelect({
                    	allList:vm.roleList,
                    	list:[],
	                    config:{
	                        meta:{
	                            label:'roleName',
	                            id:'roleId'
	                        }
	                    }
                }),
                    active: true
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
        }

        function update(tab, idx, form) {
            var user = tab.data;
            user.employeeId = tab.id;
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            
            
            if(!user.userId) {
                if (user.userPassword !== user.userPassword2) {
                	toastr.error("两次输入的密码不正确", '错误');
                	return;
                }
            }
            var selecRoles = tab.multiSelect.getSelected();
            if (vm.userId != user.userId){
                if(!selecRoles || selecRoles.length <= 0) {
                    toastr.error("请选择用户对应的角色", '错误');
                    return;
                }
            }

            user.roleIds = [];
            angular.forEach(selecRoles, function(item) {
            	user.roleIds.push(item.roleId);
            });
            
            UserModuleUser.save({}, user, function(resp) {
                if (resp.status=="SUCCESS"){
                    toastr.success("保存成功！");
                    vm.removeTab(idx);
                }
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteData(item);
            });
        }


        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        UserModuleUser.listEmployees({},{},function(re){
        	vm.employeeList = re.content;
        });
        UserModuleUser.roles({}, {}, function(res) {
        	vm.roleList = res.content;
        });
    }
})();
