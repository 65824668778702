(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhContainer', Container);

    Container.$inject = ['$resource', 'iServiceConfig'];

    function Container ($resource, iServiceConfig) {
        var service = $resource('web/containerGroup', {}, {
            'list': { url: iServiceConfig.iwhDataHost + "/web/containerGroup/list", method: 'POST'},
            'saveGroup': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/saveContainerGroup", method: 'POST'},
            'delGroup': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/deleteContainerGroup", method: 'POST'},
            'delContainer': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/deleteContainer", method: 'POST'},
            'saveContainer': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/addContainer", method: 'POST'},
            'detailGroup': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/editeContainerGroup", method: 'POST'},
            'updataContainer': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/updateContainer", method: 'POST'},
            'updataGroup': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/updateContainerGroup", method: 'POST'},
            'checkContainerCode': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/validateContainerCode", method: 'POST'},
            'checkGroupCode': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/validateContainerGroupCode", method: 'POST'},
            'enable': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/enable", method: 'POST'},
            'enableContainer': {url: iServiceConfig.iwhDataHost + "/web/containerGroup/enableContainer", method: 'POST'}
        });

        return service;
    }
})();
