(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('putaway-rule', {
            parent: 'main',
            url: 'iwh-strategy/putaway-rule',
            data: {'pageTitle': '上架规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/putawayRule/putawayRule.html',
                    controller: 'PutawayRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('PutawayRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/putaway-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/putaway-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/putaway-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/putaway-rule/:id', params: {id: '@id'}, method: 'GET'},
            'detailGet': {url: iServiceConfig.iwhStrategyHost + '/web/putaway-rule/detail/:id', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/putaway-rule/detail/save', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/putaway-rule/detail/delete/:id', params: {id: '@id'}, method: 'POST'},
            'getPutawayDirection': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.PutawayDirection.json', method: 'GET'},
            'getPutawayStackRule': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.PutawayStackRule.json', method: 'GET'},
            'getPutawayStackCompute': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.PutawayStackCompute.json', method: 'GET'},
        });
    }]);

    app.controller('PutawayRuleController', [
        '$scope', 'PutawayRule','iServiceConfig', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, PutawayRule, iServiceConfig, NgTableParams, $timeout, toastr) {
            PutawayRule.getPutawayDirection({}, function (result) {
                $scope.directionOptions = result.content;
            });
            
            PutawayRule.getPutawayStackCompute({}, function (result) {
                $scope.putawayStackComputes = result.content;
            });

            PutawayRule.getPutawayStackRule({}, function (result) {
                result.content.unshift({label:'', value: null});
                $scope.stackPrincipleOptions = result.content;
            });
            $scope.ynOptions = [
                {k: 1, v: '是'},
                {k: 0, v: '否'}
            ];

            $scope.modalInstance = null;
            $scope.tableParams = null;
            $scope.tenantDetail = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return PutawayRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.putawayRuleContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {details: []};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "putawayRuleDetail.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    PutawayRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.putawayRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {details: []};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "putawayRuleDetail.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    PutawayRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.savePutawayRule = function (tab,index) {
                PutawayRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };

            var array2str = function (arr, field) {
                if (arr && Array.isArray(arr)) {
                    if(arr.length === 0) {
                        return null;
                    }
                    if(field) {
                        var values = [];
                        for(var i = 0; i < arr.length; i++) {
                            values.push(arr[field]);
                        }
                        return values.join(',');
                    }
                    return arr.join(',');
                } else if (typeof arr === 'string') {
                    return arr;
                }
                return null;
            };

            var str2array = function (str, split) {
                if (!split || typeof split !== 'string') {
                    split = ',';
                }
                if (typeof str === 'string') {
                    return str.split(split);
                }
                return null;
            };

            var formatDetail = function (detail) {
                if (detail.limit) {
                    var limit = detail.limit;
                    limit.volumeLimits = str2array(limit.volumeLimits);
                    limit.locStorageTypeIns = str2array(limit.locStorageTypeIn);
                    limit.locStorageTypeExs = str2array(limit.locStorageTypeEx);
                    limit.locPackHandleIns = str2array(limit.locPackHandleIn);
                    limit.locPackHandleExs = str2array(limit.locPackHandleEx);
                    limit.locAbcIns = str2array(limit.locAbcIn);
                    limit.locAbcExs = str2array(limit.locAbcEx);
                    limit.skuCategoryIns = str2array(limit.skuCategoryIn);
                    limit.skuCategoryExs = str2array(limit.skuCategoryEx);
                    limit.skuFreightTypeIns = str2array(limit.skuFreightTypeIn);
                    limit.skuFreightTypeExs = str2array(limit.skuFreightTypeEx);
                    limit.skuFormTypeIns = str2array(limit.skuFormTypeIn);
                    limit.skuFormTypeExs = str2array(limit.skuFormTypeEx);
                    limit.skuAbcIns = str2array(limit.skuAbcIn);
                    limit.skuAbcExs = str2array(limit.skuAbcEx);
                    limit.skuDangerCodeIns = str2array(limit.skuDangerCodeIn);
                    limit.skuDangerCodeExs = str2array(limit.skuDangerCodeEx);
                    if (limit.locFloorRange) {
                        var lrta = limit.locFloorRange.split(',');
                        limit.locFloorRange1 = parseInt(lrta[0]) || null;
                        if (lrta[1])
                            limit.locFloorRange2 = parseInt(lrta[1]) || null;
                    }
                }

                if (!detail.skuTypes || !Array.isArray(detail.skuTypes)) {
                    detail.skuTypes = [];
                } else {
                    for (var i = 0; i < detail.skuTypes.length; i++) {
                        detail.skuTypes[i].putawayLocs = str2array(detail.skuTypes[i].putawayLocs);
                        detail.skuTypes[i].skuTypes = str2array(detail.skuTypes[i].skuTypes);
                        detail.skuTypes[i].putawayZones = str2array(detail.skuTypes[i].putawayZones);
                    }
                }
                return detail;
            };

            var parseDetail = function (detail) {
                var detail2 = angular.copy(detail);
                if (detail2.limit) {
                    var limit = detail2.limit;
                    limit.volumeLimits = array2str(limit.volumeLimits);
                    limit.locStorageTypeIn = array2str(limit.locStorageTypeIns);
                    limit.locStorageTypeEx = array2str(limit.locStorageTypeExs);
                    limit.locPackHandleIn = array2str(limit.locPackHandleIns);
                    limit.locPackHandleEx = array2str(limit.locPackHandleExs);
                    limit.locAbcIn = array2str(limit.locAbcIns);
                    limit.locAbcEx = array2str(limit.locAbcExs);
                    limit.skuCategoryIn = array2str(limit.skuCategoryIns);
                    limit.skuCategoryEx = array2str(limit.skuCategoryExs);
                    limit.skuFreightTypeIn = array2str(limit.skuFreightTypeIns);
                    limit.skuFreightTypeEx = array2str(limit.skuFreightTypeExs);
                    limit.skuFormTypeIn = array2str(limit.skuFormTypeIns);
                    limit.skuFormTypeEx = array2str(limit.skuFormTypeExs);
                    limit.skuAbcIn = array2str(limit.skuAbcIns);
                    limit.skuAbcEx = array2str(limit.skuAbcExs);
                    limit.skuDangerCodeIn = array2str(limit.skuDangerCodeIns);
                    limit.skuDangerCodeEx = array2str(limit.skuDangerCodeExs);
                    console.log(limit.locFloorRange1);
                    limit.locFloorRange1 = parseInt(limit.locFloorRange1);
                    limit.locFloorRange2 = parseInt(limit.locFloorRange2);
                    if(limit.locFloorRange1 && limit.locFloorRange2 && limit.locFloorRange1 > limit.locFloorRange2) {
                        toastr.error('【库位层高范围】初值不能大于尾值！', '输入错误');
                        return false;
                    }
                    limit.locFloorRange = (limit.locFloorRange1 || '') + ',' + (limit.locFloorRange2 || '');
                }
                var lots = [];
                if(detail2.lots) {
                	for (var i = 0; i < detail2.lots.length; i++) {
                        if (detail2.lots[i].lotAttrValue) {
                            detail2.lots[i].putawayLocs = array2str(detail2.lots[i].putawayLocs);
                            detail2.lots[i].putawayZones = array2str(detail2.lots[i].putawayZones);
                            lots.push(detail2.lots[i]);
                        }
                    }
                }
                
                detail2.lots = lots;
                var skuTypes = [];
                if(detail2.skuTypes) {
                	for (var i = 0; i < detail2.skuTypes.length; i++) {
                        if (detail2.skuTypes[i].skuTypes) {
                            detail2.skuTypes[i].putawayLocs = array2str(detail2.skuTypes[i].putawayLocs);
                            detail2.skuTypes[i].skuTypes = array2str(detail2.skuTypes[i].skuTypes);
                            detail2.skuTypes[i].putawayZones = array2str(detail2.skuTypes[i].putawayZones);
                            skuTypes.push(detail2.skuTypes[i]);
                        }
                    }
                }
                
                detail2.skuTypes = skuTypes;
                return detail2;
            };

            $scope. putawayRuleDetail= function (tab, detail) {
                var rule = tab.data;
                if (detail && detail.id) {
                    PutawayRule.detailGet({id: detail.id}, function (result) {
                        if (result.content && result.content.id) {
                            detail = formatDetail(result.content);
                            detail.isOpen = true;

                            tab.data.details.forEach(function (item, idx) {
                                if (item.id === detail.id) {
                                    tab.data.details[idx] = detail;
                                    return false;
                                }
                            });
                        }
                    });
                } else {
                    if (tab.newDetail) {
                        tab.newDetail.isOpen = true;
                    } else {
                        tab.newDetail = formatDetail({
                            tenantId: rule.tenantId,
                            warehouseCode: rule.warehouseCode,
                            ruleCode: rule.code,
                            isOpen: true
                        });
                        tab.data.details.push(tab.newDetail);
                    }
                }
            };

            $scope.savePutawayRuleDetail = function (tab, row) {
                var isNew = !row.id;
                if(!row.limit || !row.limit.volumeLimits || row.limit.volumeLimits.length === 0) {
                	toastr.error('输入错误：【容积限制】不能为空！', '输入错误');
                	return;
                }
                var detail = parseDetail(row);
                if(!detail) return;
                PutawayRule.detailSavePost({}, detail, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data.details.forEach(function (item, idx) {
                            if (item.id === resp.content.id || (isNew && !item.id)) {
                                resp.content.isOpen = false;
                                tab.data.details[idx] = formatDetail(resp.content);
                                return false;
                            }
                        });
                        if (isNew) {
                            tab.newDetail = null;
                        }
                        toastr.success('保存成功！', '成功');
                    }
                });
            };

            $scope.putawayRuleDetailDel = function (tab, detail) {
                if (!detail) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + (detail.name || '无') + '】吗？',
                    ok: function () {
                        if (!detail.id) {
                            tab.data.details.splice(tab.data.details.indexOf(detail), 1);
                            tab.newDetail = null;
                            return false;
                        }
                        PutawayRule.detailDeletePost({id: detail.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentDetail && tab.currentDetail.id === detail.id) {
                                    delete tab.currentDetail;
                                }
                                tab.data.details.splice(tab.data.details.indexOf(detail), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };

            // 添加 上架规则明细批次
            $scope.addLot = function (row) {
                if (!row.lots)
                    row.lots = [];
                row.lots.push({
                    lotRule: 'LotRule',
                    detailId: row.id,
                    ruleCode: row.ruleCode,
                    lotAttrValue: '',
                    lotAttrs: (function () {
                        var attrs = [];
                        for (var i = 0; i < 12; i++) {
                            attrs.push({label: (i + 1), value: ''});
                        }
                        return attrs;
                    })(),
                    editing: true
                });
            };
            // 编辑 上架规则明细批次
            $scope.editLot = function (row, lot) {
                lot.editing = true;
                lot.lotAttrs = [];
                var attrs = (lot.lotAttrValue || '').split(',');
                for (var i = 0; i < 12; i++) {
                    lot.lotAttrs.push({label: (i + 1), value: attrs[i] || ''});
                }
                lot.putawayLocsArray = str2array(lot.putawayLocs);
                lot.putawayZonesArray = str2array(lot.putawayZones);
            };
            // 删除 上架规则明细批次
            $scope.delLot = function (row, lot) {
                if (!row) return;
                var idx = row.lots.indexOf(lot);
                if (idx > -1) {
                    row.lots.splice(idx, 1);
                }
            };
            // 应用 上架规则明细批次
            $scope.applyLot = function (row, lot) {
                var attrs = [];
                for (var i = 0; i < lot.lotAttrs.length; i++) {
                    attrs.push(lot.lotAttrs[i].value);
                }
                lot.lotAttrValue = attrs.join(',');
                lot.putawayLocs = array2str(lot.putawayLocsArray);
                lot.putawayZones = array2str(lot.putawayZonesArray);
                lot.editing = false;
            };

            // 添加 上架规则明细商品分类
            $scope.addSkuType = function (row) {
                if (!row.skuTypes)
                    row.skuTypes = [];
                row.skuTypes.push({
                    detailId: row.id,
                    ruleCode: row.ruleCode,
                    skuTypes: '',
                    skuTypesArray: [],
                    editing: true
                });
            };
            // 编辑 上架规则明细商品分类
            $scope.editSkuType = function (row, skuType) {
                skuType.editing = true;
                skuType.skuTypesArray = str2array(skuType.skuTypes);
                skuType.putawayLocsArray = str2array(skuType.putawayLocs);
                skuType.putawayZonesArray = str2array(skuType.putawayZones);
            };
            // 删除 上架规则明细商品分类
            $scope.delSkuType = function (row, skuType) {
                if (!row) return;
                var idx = row.skuTypes.indexOf(skuType);
                if (idx > -1) {
                    row.skuTypes.splice(idx, 1);
                }
            };
            // 应用 上架规则明细商品分类
            $scope.applySkuType = function (row, skuType) {
                skuType.skuTypes = array2str(skuType.skuTypesArray);
                skuType.putawayLocs = array2str(skuType.putawayLocsArray);
                skuType.putawayZones = array2str(skuType.putawayZonesArray);
                skuType.editing = false;
            };
        }
    ]);
})();