(function() {
    'use strict';

    angular.module('iwh-receiptModule').controller('PutawayConfirmPaperController', PutawayConfirmPaperController);
    PutawayConfirmPaperController.$inject = ["$rootScope", "$scope", "NgTableParams", "$uibModal", "NgSelect",
        "$state", "$timeout", "toastr", "NgTree", "PutawayConfirmPaper", "$location", "enums", "ReceiptPaper"];
    function PutawayConfirmPaperController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                               $state, $timeout, toastr, NgTree, PutawayConfirmPaper, $location, enums, ReceiptPaper) {
        var vm = this;
        vm.errorCount = 0;
        vm.putawayNumDisabled = false;
        vm.putawayNum = "";
        vm.orderDetailData = {};
        vm.qtyCache = {};
        vm.allowFirstLoadData = false;
        vm.sliceLineNumCache = {};
        vm.success = success;
        vm.fail = fail;
        // 页面常量
        var keys = {
            putawayOrderStatus : "receipt.ReceiptPutawayOrderStatusEnum",
            putawayOrderDetailStatus : "receipt.ReceiptPutawayOrderDetailStatusEnum"
        };

        // vm.constant.orderDetailStatus.COMPLETED
        vm.constant = {
            styleName : {
                doing : "doing" // 当前正在收货
            },
            orderStatus : {
                list : enums(keys.putawayOrderStatus).list('title', 'id'),
                NEW : enums(keys.putawayOrderStatus).value('NEW'), // 创建
                COMPUTED : enums(keys.putawayOrderStatus).value('COMPUTED'), // 已计算库位
                ISSUE : enums(keys.putawayOrderStatus).value('ISSUE'), // 已发放
                DOING : enums(keys.putawayOrderStatus).value('DOING'), // 上架中
                COMPLETED : enums(keys.putawayOrderStatus).value('COMPLETED'), // 上架完成
                CANCEL : enums(keys.putawayOrderStatus).value('CANCEL') // 取消
            },
            orderDetailStatus : {
                list : enums(keys.putawayOrderDetailStatus).list('title', 'id'),
                NEW : enums(keys.putawayOrderDetailStatus).value('NEW'), // 创建
                COMPUTED : enums(keys.putawayOrderDetailStatus).value('COMPUTED'), // 已计算库位
                ISSUE : enums(keys.putawayOrderDetailStatus).value('ISSUE'), // 已发放
                COMPLETED : enums(keys.putawayOrderDetailStatus).value('COMPLETED'), // 已上架
                CANCEL : enums(keys.putawayOrderDetailStatus).value('CANCEL') // 取消
            }
        };

        vm.loadPutawayDetailListData = loadPutawayDetailListData;
        vm.eventKeyupHandler = eventKeyupHandler;
        vm.splitTableRow = splitTableRow; // 拆分表格
        vm.removeTableRow = removeTableRow; // 移除拆分行
        vm.confirmPutawayOrder = confirmPutawayOrder; // 确认上架
        vm.allConfirmPutawayOrder = allConfirmPutawayOrder; // 整单上架
        vm.changePutaway = changePutaway; // 切换上架单
        vm.eventClickHandler = eventClickHandler;


        // 处理从目标页面来源的上架单号
        var sourceParams = $location.search();
        if ( undefined != sourceParams ) {
            if ( !!sourceParams.putawayNum ) {
                vm.putawayNum = sourceParams.putawayNum;
                vm.allowFirstLoadData = true;
            }
        }


        function focusPutawayNum() {
            jQuery(".putawayNum:eq(0)").focus();
        }

        function changePutaway(scope) {
            scope.putawayNum = "";
            scope.orderDetailData = {};
            scope.putawayNumDisabled = false;
            setTimeout(function(){
                focusPutawayNum();
            }, 100);
        }

        function eventClickHandler($event, data, scope) {
            var orderDetailStatus = scope.constant.orderDetailStatus,
                target = $event.target, nodeName = target.nodeName.toLowerCase(),
                targetObj = angular.element($event.target), keyCode = $event.keyCode;

            if ( data.status == orderDetailStatus.COMPLETED ) {
                // 已经完成上架
            } else {
                if (data.styleName == scope.constant.styleName.doing) {
                    if ( nodeName == "td" ) {
                        data.styleName = "";
                    }
                } else {
                    data.styleName = scope.constant.styleName.doing;
                }
            }
        }

        function eventKeyupHandler($event, data, scope) {
        	var domObj = angular.element($event.target);
        	
            var target = angular.element($event.target),
                keyCode = $event.keyCode;

            // 上架单处理
            if ( target.hasClass("putawayNum") ) {
                if ( keyCode == 13 ) {
                    scope.loadPutawayDetailListData(scope);
                }
            }

            // 上架数量
            else if ( target.hasClass("actualQty") ) {
                handleQtyKeyUp(data, scope);
            }

            // 上架实际库位
            else if ( target.hasClass("actualLoc") ) {
            	
            	if(!data.oldactualLoc || data.oldactualLoc != data.actualLoc) {
            		vm.success();
            		ReceiptPaper.validateLocationExist({},{
                        locationCode : data.actualLoc
                    },function(resp){
                        var rdata = resp.content;
                        if ( rdata.status > 0 ) {
                        	data.actualLocError = false;
                        	data.actualLoc = rdata.locationCode;
                            domObj.removeClass("validateError");
                        } else {
                        	data.actualLocError = true;
                            domObj.addClass("validateError");
                        }
                        data.oldactualLoc = data.actualLoc;
                    }, function(resp) {
                    	vm.fail();
                    });
            	}
            }

            // 上架实际容器
            else if ( target.hasClass("actualLpn") ) {
                //
            }

            // 上架实际箱
            else if ( target.hasClass("actualCase") ) {
                //
            }
        }

        function handleQtyKeyUp(data, scope) {
            var tActualQty = data.actualQty || "";
            if(tActualQty) {
                tActualQty = tActualQty + "";
            } else {
                tActualQty = "0";
            }

            data.actualQty = tActualQty.replace(/^(0|[^0-9])|([^0-9])/g, "");
            if ( undefined == data ) {
                return;
            }

            var sum = _sumByLineNum(data._lineNum, scope);
            if ( sum > data._qty ) {
                toastr.error("上架单明细行 [" + data._lineNum + "] 商品 [" + data.skuName +
                    "] 超出实际上架数量[" + data._qty + "]");
                vm.fail();
                scope.errorCount += 1;
                _setErrorStyle(data._lineNum, scope, true);
            } else {
                if ( scope.errorCount > 0 ) {
                    scope.errorCount -= 1;
                }
                _setErrorStyle(data._lineNum, scope, false);
            }
        }

        function loadPutawayDetailListData(scope) {
            var putawayNum = scope.putawayNum || "";
            if ( putawayNum.replace(/^(\s+)|(\s+)$/g, "") == "" ) {
                toastr.error("请输入上架单单号");
                vm.fail();
                return;
            }
            var orderDetailStatus = scope.constant.orderDetailStatus;
            scope.orderDetailData = {};
            vm.success();
            PutawayConfirmPaper.getPutawayDetailList({}, {
                putawayNum: scope.putawayNum
            }, function (resp) {
                var content = resp.content, datas = content.datas;
                for (var i = 0, len = datas.length; i < len; i++ ) {
                    var temp = datas[i];
                    temp._qty = temp.qty;
                    if ( temp.lineNum.indexOf("_") != -1 ) {
                        temp._lineNum = temp.lineNum.substring(0, temp.lineNum.indexOf("_"));
                    } else {
                        scope.qtyCache[temp.lineNum] = temp.qty;
                        temp._lineNum = temp.lineNum;
                    }
                    temp.actualQty = temp.qty;
                    temp.actualLoc = temp.toLoc;
                    temp.actualLpn = temp.toLpn;
                    temp.actualCase = temp.toCase;
                    if ( temp.status == orderDetailStatus.COMPLETED ) {
                        temp.styleName = "complateTR";
                    }
                }
                scope.orderDetailData = content;
                scope.putawayNumDisabled = true;
            }, function(resp) {
                vm.fail();
            });
        }

        function confirmPutawayOrder(scope) {
        	vm.success();
            var tempDataList = scope.orderDetailData.datas,
                orderDetailStatus = scope.constant.orderDetailStatus,
                params = [], tempQtys = [];
            for (var i = 0, len = tempDataList.length; i < len; i ++ ) {
                var tempData = tempDataList[i];
                if ( tempData.status != orderDetailStatus.COMPLETED && tempData.styleName == scope.constant.styleName.doing ) {
                    var _actualQty = tempData.actualQty || 0;
                    if ( !("" + _actualQty).match(/(^0$)|(^[1-9][0-9]*$)/g) ) {
                        toastr.error("上架单明细号 [" + tempData.lineNum +
                            "] 上架数量 [" + _actualQty + "] 格式错误。<br/>格式：正整数，只允许包含0-9数字，不能是0开头");
                        vm.fail();
                        return;
                    }

                    if ( tempData.actualQty <= 0 ) {
                        toastr.error("上架单明细号 [" + tempData.lineNum + "] 上架数量必需大于0");
                        vm.fail();
                        return;
                    }

                    // 统计每条明细输入总数
                    var tempQtyObj = tempQtys[tempData._lineNum];
                    if ( undefined == tempQtyObj ) {
                        tempQtyObj = {
                            lineNum : tempData._lineNum,
                            skuName : tempData.skuName,
                            qty : (parseInt(_actualQty) || 0)
                        };
                    } else {
                        tempQtyObj.qty += (parseInt(_actualQty) || 0);
                    }

                    tempQtys[tempData._lineNum] = tempQtyObj;
                    if ( tempData.actualLocError == true ) {
                        toastr.error("行号："+tempData.lineNum+" 上架库位[" + tempData.actualLoc + "]无效，请仔细确认");
                        vm.fail();
                        return;
                    }

                    params.push({
                        id : tempData.id,
                        putawayNum : scope.putawayNum,
                        lineNum : tempData.lineNum,
                        sliceFlag : (tempData._sliceLine == "1" ? "1" : "0"),
                        actualQty : tempData.actualQty,
                        actualLoc : tempData.actualLoc,
                        actualLpn : tempData.actualLpn,
                        actualCase : tempData.actualCase,
                        parentLineNum : tempData._lineNum
                    });
                }
            }

            if ( params.length <= 0 ) {
                toastr.error("请选择待上架明细信息（点击明细行，选中行背景为绿色）");
                vm.fail();
                return;
            }

            // 输入量超收校验
            for (var attr in tempQtys) {
                var _t = tempQtys[attr];
                if (_t.qty > scope.qtyCache[_t.lineNum]) {
                    toastr.error("明细行 [" + _t.lineNum + "] 商品 [" + _t.skuName + "] 上架数量超收");
                    vm.fail();
                    return;
                }
            }
            vm.success();
            PutawayConfirmPaper.confirmPutawayOrder({}, {
                putawayNum : scope.putawayNum,
                orderDetails : params
            }, function (resp) {
                toastr.success("上架成功");
                loadPutawayDetailListData(scope);
            }, function(resp) {
            	vm.fail();
            });
        }

        function allConfirmPutawayOrder(scope) {
            var tempDataList = scope.orderDetailData.datas,
                orderDetailStatus = scope.constant.orderDetailStatus,
                params = [], tempQtys = [];
            for (var i = 0, len = tempDataList.length; i < len; i ++ ) {
                var tempData = tempDataList[i];
                if ( tempData.status != orderDetailStatus.COMPLETED ) {
                    var _actualQty = tempData.actualQty || 0;
                    if ( !("" + _actualQty).match(/(^0$)|(^[1-9][0-9]*$)/g) ) {
                        toastr.error("上架单明细号 [" + tempData.lineNum +
                            "] 上架数量 [" + _actualQty + "] 格式错误。<br/>格式：正整数，只允许包含0-9数字，不能是0开头");
                        vm.fail();
                        return;
                    }

                    if ( _actualQty <= 0 ) {
                        toastr.error("上架单明细号 [" + tempData.lineNum + "] 上架数量必需大于0");
                        vm.fail();
                        return;
                    }

                    // 统计每条明细输入总数
                    var tempQtyObj = tempQtys[tempData._lineNum];
                    if ( undefined == tempQtyObj ) {
                        tempQtyObj = {
                            lineNum : tempData._lineNum,
                            skuName : tempData.skuName,
                            qty : (parseInt(_actualQty) || 0)
                        };
                    } else {
                        tempQtyObj.qty += (parseInt(_actualQty) || 0);
                    }
                    tempQtys[tempData._lineNum] = tempQtyObj;

                    params.push({
                        id : tempData.id,
                        putawayNum : vm.putawayNum,
                        lineNum : tempData.lineNum,
                        sliceFlag : (tempData._sliceLine == "1" ? "1" : "0"),
                        actualQty : tempData.actualQty,
                        actualLoc : tempData.actualLoc,
                        actualLpn : tempData.actualLpn,
                        actualCase : tempData.actualCase,
                        parentLineNum : tempData._lineNum
                    });
                }
            }

            if ( params.length <= 0 ) {
                toastr.error("请填写要上架数量信息");
                vm.fail();
                return;
            }

            // 输入量超收校验
            for (var attr in tempQtys) {
                var _t = tempQtys[attr];
                if (_t.qty > scope.qtyCache[_t.lineNum]) {
                    toastr.error("明细行 [" + _t.lineNum + "] 商品 [" + _t.skuName + "] 上架数量超收");
                    vm.fail();
                    return;
                }
            }
            vm.success();
            PutawayConfirmPaper.allConfirmPutawayOrder({}, {
                putawayNum : scope.putawayNum,
                orderDetails : params
            }, function (resp) {
                toastr.success("上架成功");
                loadPutawayDetailListData(scope);
            },function(resp) {
            	vm.fail();
            });
        }

        function splitTableRow(index, row, scope) {
            var tempDataList = scope.orderDetailData.datas;
            if (row.qty <= 0 ) {
                toastr.error("计划上架数量小于等于0，不能拆分");
                vm.fail();
                return;
            }
            row.styleName = scope.constant.styleName.doing;

            var newRow = angular.copy(row);
            newRow._sliceLine = '1';
            newRow.qty = "";
            newRow.actualQty = 0;

            // 计算行号
            var indexObj = scope.sliceLineNumCache[newRow._lineNum];
            if ( indexObj == undefined ) {
                indexObj = _getCountByLineNum(newRow.lineNum, scope);
            }
            indexObj += 1;

            newRow.lineNum += "_" + indexObj;
            scope.sliceLineNumCache[newRow._lineNum] = indexObj;
            tempDataList.splice(index + 1, 0, newRow);
        }

        function removeTableRow(index, row, scope) {
            scope.orderDetailData.datas.splice(index, 1);
            row.actualQty = 0;
            handleQtyKeyUp(row, scope);
        }


        ////////////////////////////////////////////////////////////////
        // 获取指定行号的拆分明细数
        function _getCountByLineNum(lineNum, scope) {
            var tempDataList = scope.orderDetailData.datas, count = 0;
            if ( tempDataList instanceof Array ) {
                for (var i = 0, len = tempDataList.length; i < len; i++ ) {
                    var item = tempDataList[i];
                    if ( item.lineNum.indexOf(lineNum + "_") >= 0 ) {
                        count += 1;
                    }
                }
            }
            return count;
        }

        // 获取指定行和拆分行的总上架数量（containLineNum是否包含拆分行父行）
        function _sumByLineNum(lineNum, scope) {
            var tempDataList = scope.orderDetailData.datas, sum = 0;
            for (var i = 0, len = tempDataList.length; i < len; i++) {
                var item = tempDataList[i];
                if ( item.lineNum == lineNum || (item._sliceLine == '1' && item.lineNum.indexOf(lineNum + "_") != -1) ) {
                    sum += (parseInt(item.actualQty) || 0);
                }
            }
            return sum;
        }

        // 设置指定行的错误样式
        function _setErrorStyle(lineNum, scope, flag) {
            var tempDataList = scope.orderDetailData.datas;
            for (var i = 0, len = tempDataList.length; i < len; i++) {
                var item = tempDataList[i];
                if ( item.lineNum == lineNum || (item.lineNum.indexOf(lineNum + "_") != -1 ) ) {
                    item.errorStyle = (flag == true ? "actualQtyErrorStyle" : "");
                }
            }
        }
        
        function fail() {
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }
        success();

        // 如果是其他页面打开，则运行首次加载
        if ( vm.allowFirstLoadData ) {
            loadPutawayDetailListData(vm);
        }
        focusPutawayNum();
    }
})();

