(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('CaseInventoryQueryController', CaseInventoryQueryController);

    CaseInventoryQueryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'CaseInventory', 'toastr', '$uibModal'];

    function CaseInventoryQueryController ($rootScope, $timeout,NgTableParams, enums, CaseInventory, toastr,  $uibModal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.caseFlags = [{id:"1",title:"整箱"},{id:"0",title:"散件"}];
        vm.search = search;

        vm.tableParams = new NgTableParams({counts: [10,20,50,100,200,500,1000,2000]},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return CaseInventory.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        
        vm.open = function(caseNum) {
        	var modalInstance = $uibModal.open({
                templateUrl: "caseInvDetail.html",
                controller: ctrl,
                controllerAs: '$ctrl',
                size: 'xl',
                resolve: {
                    item:function(){
                        return {caseNum: caseNum};
                    }
                }
            });
            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.caseDetailTableParams = new NgTableParams({},{
                    getData: function(params) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count(), caseNum: caseNum}, params.filter());
                        return CaseInventory.caseDetail({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                    }
                });
                
                $ctrl.cancel = cancel;

                function cancel() {
                    modalInstance.close();
                }
            }
        };
        
        function search() {
            vm.tableParams.reload();
        };
        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        };
    }
})();
