(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('PickerOrderBiz', PickerOrderBiz);

    PickerOrderBiz.$inject = ['$resource', 'iServiceConfig'];

    function PickerOrderBiz ($resource, iServiceConfig) {
        var service = $resource('web/po', {}, {
            'list': {url: iServiceConfig.iwhShipHost + "/web/po/gen/list", method: 'POST'},
            'genBySelected': {url: iServiceConfig.iwhShipHost + "/web/po/gen/genBySelected", method: 'POST'},
            'genBySoNum': {url: iServiceConfig.iwhShipHost + "/web/po/gen/genBySoNum", method: 'GET'},
            'genByRule': {url: iServiceConfig.iwhShipHost + "/web/po/gen/genByRule", method: 'POST'},
            'getByPickNum': {url: iServiceConfig.iwhShipHost + '/web/po/getByPickNumForPick', method: 'GET'},
            'wholePick': {url: iServiceConfig.iwhShipHost + '/web/po/wholePick', method: 'POST'},
            'pickByOrder': {url:iServiceConfig.iwhShipHost + '/web/po/pickByOrder', method: 'POST'},
            'pickBySoNums': {url:iServiceConfig.iwhShipHost + '/web/po/pickBySoNums', method: 'POST'}
        });

        return service;
    }
})();
