(function() {
    'use strict';

    angular.module('ireportModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('rpt-reportTemplate', {
                parent: 'main',
                url: 'report/reportTemplate',
                data: {
                    'pageTitle': '报表模版列表'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'report-module/template/reportTemplate.html',
                        controller: 'RptReportTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
