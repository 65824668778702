(function() {
    'use strict';

    angular.module('iprintModule')
        .controller('SysMonitorController', SysMonitorController);

    SysMonitorController.$inject = ['Monitor', 'NgTableParams','$uibModal','$interval' , '$scope'];

    function SysMonitorController(Monitor, NgTableParams, $uibModal, $interval, $scope) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [
                   {
                       heading: '任务监控',
                       contentId: "taskMonitor.html",
                    }];
        vm.realStatus = [{'id':null, 'title':'全部'},{'id':0, 'title':'未连接'},{'id':1, 'title':'已连接'}];
        vm.status = [{'id':null, 'title':'全部'},{'id':0, 'title':'新建'},{'id':5, 'title':'补打中'},{'id':10, 'title':'已提交 '},{'id':20, 'title':'已打印'},{'id':-10, 'title':'打印故障'}];
        vm.statusMap = {'0':'新建','5':'补打中','10':'已提交 ','20':'已打印','-10':'打印故障'};
        
        vm.search = search;
        vm.removeTab = removeTab;
        vm.connected = null;
        vm.noConnected = null;
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return Monitor.list({}, postParams,
                    function(resp) {
                		params.total(resp.content.pvo.tc);
                        return resp.content.pvo.datas;
                    }).$promise.then(function(data) {
                    	vm.connected = data.content.connected;
                    	vm.noConnected = data.content.noConnected;
                    return data.content.pvo.datas;
                });
            }
        });
        vm.taskParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return Monitor.taskList({}, postParams,
                    function(resp) {
                		params.total(resp.content.pvo.tc);
                        return resp.content.pvo.datas;
                    }).$promise.then(function(data) {
                    	vm.havePrinted = data.content.havePrinted;
                    	vm.printAll = data.content.printAll;
                    	vm.printedErro = data.content.printedErro;
                    return data.content.pvo.datas;
                });
            }
        });
        
        vm.refreshId = $interval(function() {
        	search();
        }, 5000);
        
        function search() {
            vm.tableParams.reload();
            vm.taskParams.reload();
        }
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
        
        $scope.$on('$destroy', function() {
            if(vm.refreshId) {
                $interval.cancel(vm.refreshId);
                vm.refreshId = null;
            }
        });
    }
})();
