(function () {
    'use strict';

    angular.module('iwh-shipModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('transferBatch', {
                parent: 'main',
                url: 'iwh-ship/transferBatch',
                data: {
                    'pageTitle': '交接批次管理'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/ship/transferBatch.html',
                        controller: 'TransferBatchController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('iwhShiptTransfer', {
                parent: 'main',
                url: 'iwh-ship/transfer',
                data: {
                    'pageTitle': '发运交接'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/ship/transfer.html',
                        controller: 'TransferController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('deliveryOrderBySoNum', {
                parent: 'main',
                url: 'iwh-ship/deliveryOrderBySoNum',
                data: {
                    'pageTitle': '出库单发货'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/ship/deliveryOrderBySoNum.html',
                        controller: 'DeliveryOrderBySoNumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('deliveryOrder', {
                parent: 'main',
                url: 'iwh-ship/deliveryOrder',
                data: {
                    'pageTitle': '发运单管理'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/ship/deliveryOrder.html',
                        controller: 'DeliveryOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
    }
})();
