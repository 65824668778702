(function () {
    'use strict';
    var app = angular.module('iwh-receiptModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('receiptQueryByLot', {
            parent: 'main',
            url: 'iwh-receipt/receiptQueryByLot',
            data: {
                'pageTitle': '收货查询【库存批次】'
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-receipt-module/receiptQuery/lot.html',
                    controller: 'ReceiptQueryByLotController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

