(function () {
    'use strict';

    angular.module('iwh-shipModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('checkPack', {
                parent: 'main',
                url: 'iwh-ship/checkPack?isScanPerPiece',
                data: {
                    'pageTitle': '按箱复核装箱'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pack/checkPack.html',
                        controller: 'CheckPackController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('soCheckPack', {
                parent: 'main',
                url: 'iwh-ship/soCheckPack?isScanPerPiece',
                data: {
                    'pageTitle': '按单复核装箱'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pack/SoCheckPack.html',
                        controller: 'SoCheckPackController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('box', {
                parent: 'main',
                url: 'iwh-ship/box',
                data: {
                    'pageTitle': '装箱记录管理'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pack/box.html',
                        controller: 'BoxController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('ssPack', {
                parent: 'main',
                url: 'iwh-ship/ssPack?needPrintList=&needPrintWaybill=',
                data: {
                    'pageTitle': '单品单件复核装箱'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pack/ssPack.html',
                        controller: 'SsPackController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('packWeight', {
                parent: 'main',
                url: 'iwh-ship/packWeight',
                data: {
                    'pageTitle': '打包称重'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pack/packWeight.html',
                        controller: 'PackWeightController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('wholeCasePack', {
                parent: 'main',
                url: 'iwh-ship/wholeCasePack',
                data: {
                    'pageTitle': '按单整箱复核'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/pack/wholeCasePack.html',
                        controller: 'CheckWholeCasePackController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
    }
})();
