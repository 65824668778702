(function () {
    'use strict';

    angular.module('iergateModule')
        .controller('ErgateTenantDataController',
            ['ErgateTenantData', 'NgTableParams', '$timeout', 'toastr', 'iServiceConfig', ErgateTenantDataController]);

    function ErgateTenantDataController(ErgateTenantData, NgTableParams, $timeout, toastr, iServiceConfig) {

        var vm = this;
        
        vm.iuserHost = iServiceConfig.iuserHost;
        vm.getTenantList = vm.iuserHost + '/web/tenant/getList';

        vm.dbTypes = ['MYSQL', 'ORACLE', 'SQLSERVER'];
        
        var pageTab = {
            tabs: [],
            activeTabIndex: 0,
            removeTab: function (idx) {
                this.tabs.splice(idx, 1);
            },
            addTab: function (tab) {
                var that = this;
                var tabs = that.tabs;
                var needAdd = true;

                for (var i = 0; i < tabs.length; i++) {
                    var v = tabs[i];
                    if ((tab.data === undefined && v.data === undefined)
                        || (tab.data && v.data && (v.data.tenantId === tab.data.tenantId))) {
                        needAdd = false;
                        that.activeTabIndex = i + 1;
                        break;
                    }
                }

                if (needAdd) {
                    that.tabs.push(tab);
                    $timeout(function () {
                        if (tab.autoActive) {
                            that.activeTabIndex = that.tabs.length;
                        }
                    }, 0);
                }
            }
        };

        vm.pageTab = pageTab;

        vm.refresh = function () {
            vm.getTableList.reload().then(function () {
                toastr.success("刷新成功");
            }).catch(function () {
                toastr.error("刷新失败");
            });
        };

        vm.getTableList = new NgTableParams({count: 10}, {
            getData: function (params) {
                var data = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    },
                    params.filter()
                );

                return ErgateTenantData.getTenantDataList(data,
                    {},
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        vm.editTenantData = function (row) {
            pageTab.addTab({
                heading: '租户配置数据编辑',
                contentId: 'tenantdata-edit.html',
                data: row,
                autoActive: true
            });
        };
        
        vm.newTenantData = function() {
            pageTab.addTab({
                heading: '租户配置数据新增',
                contentId: 'tenantdata-edit.html',
                autoActive: true
            });
        };
        
        vm.deleteTenantData = function (row) {
            if (row && row.id) {
                ErgateTenantData.deleteTenantData({}, row, function (resp) {
                    if (resp.status === "SUCCESS") {
                        $timeout(function() {
                            vm.refresh();
                        }, 0);
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "删除失败！"), "错误");
                    }
                });
            }
        };

        vm.updateOrSaveTenantData = function (tab, idx, form) {
            var data = tab.data;
            if (!form.$valid) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }
            ErgateTenantData.updateOrSaveTenantData({}, data, function (resp) {
                if (resp.status === "SUCCESS") {
                    pageTab.removeTab(idx);
                    $timeout(function() {
                        vm.refresh();
                    }, 0);
                } else {
                    toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存失败！"), "错误");
                }
            });
        };
    }
})();

