(function() {
    'use strict';

    angular.module('doveModule')
        .controller('iBusProjectConfigController', iBusProjectConfigController);

    iBusProjectConfigController.$inject = ['iBusProject', 'NgTableParams', '$uibModal','$timeout'];

    function iBusProjectConfigController(iBusProject, NgTableParams, $uibModal,$timeout) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.update = update;
        vm.deleteProject = deleteProject;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.active = 0;

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusProject.search({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }

        function deleteProject(row){
            iBusProject.delete({id: row.id}, {}, function() {
                vm.search();
            });
        }

        function edit(row) {
            var entity = {
                heading: '编辑',
                contentId: "project-eidt.html",
                data: row,
                roles: [],
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function create() {
            var entity = {
                heading: '创建',
                contentId: "project-eidt.html",
                data: {

                },
                roles: [],
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function update(tab, idx, form) {
            var data = tab.data;

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            if (!!!data.id) {
                iBusProject.add({}, data, function() {
                    vm.removeTab(idx);
                });
            } else {
                iBusProject.update({}, data, function() {
                    vm.removeTab(idx);
                });
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            var modalInstance = $uibModal.open({
                templateUrl: 'deleteModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            modalInstance.result.then(function(item) {
                vm.deleteProject(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
