(function() {
    'use strict';

    angular
        .module('iprintModule')
        .factory('Monitor', Monitor);

    Monitor.$inject = ['$resource', 'iServiceConfig'];

    function Monitor ($resource, iServiceConfig) {
        var service = $resource('web/monitor', {}, {
            'list': { url: iServiceConfig.iprintHost + "/web/monitor/clientMonitor", method: 'POST'},
            'taskList': { url: iServiceConfig.iprintHost + "/web/monitor/taskMonitor", method: 'POST'},
        });
        return service;
    }
})();
