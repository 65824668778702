(function() {
    'use strict';

    angular.module('ireportModule')
        .controller('RptReportController', RptReportController);

    RptReportController.$inject = ['RptReport','RptReportTemplate','NgTableParams','$uibModal', '$q','$timeout','Upload','NgSelect'];

    function RptReportController(Report,ReportTemplate, NgTableParams, $uibModal, $q, $timeout,Upload,NgSelect) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.active = 0;
        vm.tabs = [];
        vm.projectMap = {};
        vm.projects = [];
        vm.displayed = [];
        vm.isLoading  = false;
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'新建'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.statusMap={'0':'新建','1':'启用','-1':'禁用'};
        vm.type=[{'id':null, 'title':''}, {'id':1, 'title':'JasperReport'},{'id':2, 'title':'birt'},{'id':3, 'title':'自定义'},{'id':4, 'title':'字符模板'}];
        vm.typeMap={'1':'JasperReport','3':'自定义','2':'Birt Report','4':'字符模板'};
        vm.dsTypes=[{'id':null, 'title':''}, {'id':1, 'title':'DB数据源'},{'id':2, 'title':'JSON数据源'}];
        vm.dsTypesMap={'1':'DB数据源','2':'JSON数据源'};
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.changeStatusConfirm=changeStatusConfirm;
        vm.changeStatus=changeStatus;
        vm.saveConfirm=saveConfirm;
        vm.save=save;
        
        vm.reportModules = [];
        vm.initModules = initModules;
        function initModules() {
        	Report.modules({},{},function(resp) {
        		vm.reportModules = resp.content;
            });
        }
        
        vm.reportTemplates = [];
        vm.initTemplates = initTemplates;
        function initTemplates() {
        	ReportTemplate.listAllActive({},{},function(resp) {
        		vm.reportTemplates = resp.content;
            });
        }
        
        vm.reportDatasources = [];
        vm.initDatasources = initDatasources;
        function initDatasources() {
        	Report.datasource({}, {}, function(resp) {
              	 vm.reportDatasources=resp.content
            });
        }
//第一个页面
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return Report.search({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }
        
        function changeStatusConfirm(row){
        	vm.modalInstance=$uibModal.open({
        		templateUrl : 'changeStatusModal.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : function() {
						return row;
					}
				}
        	});
        	vm.modalInstance.result.then(function(item) {
				vm.changeStatus(item);
			});
        }
        
        function changeStatus(row){
        	Report.changeStatus({}, {
				reportId : row.id,
				status : row.status
			}, function() {
				vm.search();
			});
        }
        
        
//同步
        function sync(row){
        	Report.getReportNodeList({}, {reportId:row.id}, function(resp) {
        		var entity = {
                      heading: '同步'+row.name,
                      contentId: "report-nodesync.html",
                      data:{
                    	  id:row.id,
                    	  reportId:"",
                          nodeIds:[]
                      },
                      tableParams: new NgTableParams({count: 10}, {getData:function(){return resp.content}}),
                      active: true,
                  };
                  
                  vm.tabs.push(entity);
                  $timeout(function(){
                      vm.active = vm.tabs.length;
                  },0); 
                  
              	});
        }
        
        function edit(row) {
        	Report.getReportDetail({}, {reportId:row.id}, function(resp) {
           	 var data=resp.content
                var entity = {
                   heading: '编辑'+row.name,
                   contentId: "report-eidt.html",
                   data: {
                	   id: data.id,
                       code : data.code,
                       name : data.name,
                       type : data.type,
                       dsCode: data.dsCode,
                       dsType: data.dsType,
                       jsonDsUrlCode: data.jsonDsUrlCode,
                       jsonDsBizCode: data.jsonDsBizCode,
                       status : data.status,
                       templateFile: data.templateFile,
                       moduleCode:  data.moduleCode
                   },
                   reportTemplate:{},
                   file: null,
                   active: true,
               };
               
               vm.tabs.push(entity);
               $timeout(function(){
                   vm.active = vm.tabs.length;
               },0); 
               
           	});
        }
//新建
        function create() {
        	var entity = {
                    heading: '创建',
                    contentId: "report-eidt.html",
                    data: {
                        code: "",
                        name: "",
                        type: "",
                        dsCode:"",
                        dsType: 1,
                        status:"",
                        nodeIdList:"",
                        moduleCode:  ""
                    },
                    reportTemplate:{},
                    file: null,
                    active: true,
                };
                
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0); 
        }
  //公用方法
        function saveConfirm(tab, idx, form){
        	vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmsaveOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						idx : idx,
						form : form
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.save(item.tab, item.idx, item.form);
			});
        }
        function save(tab, idx, form){
        	 var valid = form.$valid;
             if (!valid) {
                 form.showMsg = true;
                 return;
             }
            	 Report.save({}, {
            		 			 id:tab.data.id,
			                	 code:tab.data.code,
			                     name:tab.data.name,
			                     type:tab.reportTemplate.type,
			                     dsCode:tab.data.dsCode,
			                     dsType:tab.reportTemplate.dsType,
			                       jsonDsUrlCode: tab.data.jsonDsUrlCode,
			                       jsonDsBizCode: tab.data.jsonDsBizCode,
			                     status:tab.data.status,
			                     templateFile:tab.reportTemplate.code,
			                       moduleCode:  tab.data.moduleCode
			                 }, function(resp) {
            		 if(resp.status==="ERROR"){
                		 alert(resp.errorMsg);
                	 }else{
                		 vm.removeTab(idx);
                	 }
                 });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
		confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        
        vm.initTemplates();
        vm.initDatasources();
        vm.initModules();
    }
})();
