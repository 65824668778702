(function()  {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('LotLocInventorySearchController', LotLocInventorySearchController);

    LotLocInventorySearchController.$inject = ['$rootScope', 'InventorySearch', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function LotLocInventorySearchController ($rootScope,  InventorySearch, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.lotAttributeSearch = null;
        vm.searchTemp = {
            tenantId : null,
            warehouseCode : null,
            lotRule : null
        };
        vm.allCount = {
            ownerCode : null,
            ownerName : null,
            locCode : "当前页总计",
            canUseQty : null,
            qty : null,
            allAllocatedQty : null,
            pickedQty : null
        }
        vm.allCount2 = {
            ownerCode : null,
            ownerName : null,
            locCode : "所有总计",
            canUseQty : null,
            qty : null,
            allocatedQty : null,
            pickedQty : null
        }
        vm.lotAttributeSearch = null;
        vm.searchTemp = {
            tenantId : null,
            warehouseCode : null,
            lotRule : null
        };
        function pageTitle() {
            return $window.document.title;
        }

        InventorySearch.beforeList({}, {}, function(resp) {
            vm.searchTemp = resp.content;
        });

      //查询
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
                if(vm.ownerCode){
                    postParams.sownerCode = vm.ownerCode;
                    if(vm.lotAttributeSearch!=null){
                        if(vm.lotAttributeSearch.lotAttribute01){
                            postParams.lotAttribute01 = vm.lotAttributeSearch.lotAttribute01;
                        }
                        if(vm.lotAttributeSearch.lotAttribute02){
                            postParams.lotAttribute02 = vm.lotAttributeSearch.lotAttribute02;
                        }
                        if(vm.lotAttributeSearch.lotAttribute03){
                            postParams.lotAttribute03 = vm.lotAttributeSearch.lotAttribute03;
                        }
                        if(vm.lotAttributeSearch.lotAttribute04){
                            postParams.lotAttribute04 = vm.lotAttributeSearch.lotAttribute04;
                        }
                        if(vm.lotAttributeSearch.lotAttribute05){
                            postParams.lotAttribute05 = vm.lotAttributeSearch.lotAttribute05;
                        }
                        if(vm.lotAttributeSearch.lotAttribute06){
                            postParams.lotAttribute06 = vm.lotAttributeSearch.lotAttribute06;
                        }
                        if(vm.lotAttributeSearch.lotAttribute07){
                            postParams.lotAttribute07 = vm.lotAttributeSearch.lotAttribute07;
                        }
                        if(vm.lotAttributeSearch.lotAttribute08){
                            postParams.lotAttribute08 = vm.lotAttributeSearch.lotAttribute08;
                        }
                        if(vm.lotAttributeSearch.lotAttribute09){
                            postParams.lotAttribute09 = vm.lotAttributeSearch.lotAttribute09;
                        }
                        if(vm.lotAttributeSearch.lotAttribute10){
                            postParams.lotAttribute10 = vm.lotAttributeSearch.lotAttribute10;
                        }
                        if(vm.lotAttributeSearch.lotAttribute11){
                            postParams.lotAttribute11 = vm.lotAttributeSearch.lotAttribute11;
                        }
                        if(vm.lotAttributeSearch.lotAttribute12){
                            postParams.lotAttribute12 = vm.lotAttributeSearch.lotAttribute12;
                        }
                    }
                }
                this.httpParams = postParams;
				return InventorySearch.lotLocInventory({}, postParams, function(resp) {
					params.total(resp.content.pageVo.tc);
				}).$promise.then(function(data) {
					var datas = data.content.pageVo.datas;
                    vm.allCount.allocatedQty = data.content.allAllocatedQtyAll;
                    vm.allCount.availableQty = data.content.canUseQtyAll;
                    vm.allCount.qty = data.content.qtyAll;
                    vm.allCount.pickedQty = data.content.pickedQtyAll;
                    datas[datas.length] = vm.allCount;
                    vm.allCount2.allocatedQty = data.content.allAllocatedQtyAll2;
                    vm.allCount2.availableQty = data.content.canUseQtyAll2;
                    vm.allCount2.qty = data.content.qtyAll2;
                    vm.allCount2.pickedQty = data.content.pickedQtyAll2;
                    datas[datas.length] = vm.allCount2;
					return datas;
				});
			}
		});

        vm.search = search;
        function search() {
            vm.tableParams.reload();
        }

        vm.btnSearch = btnSearch;
        function btnSearch() {
            if(!vm.ownerCode){
                vm.lotAttributeSearch == null;
            }
            vm.tableParams.reload();
        }

        vm.ruleChange = ruleChange;
        function ruleChange() {
            vm.lotAttributeSearch = null;
            if (vm.ownerCode && vm.searchTemp && vm.searchTemp.lotRule) {
                vm.lotAttributeSearch = {
                    tenantId: vm.searchTemp.tenantId,
                    warehouseCode: vm.searchTemp.warehouseCode,
                    lotRule: vm.searchTemp.lotRule,
                    ownerCode: vm.ownerCode,
                    skuCode: '-1'
                };
            }
            
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
