(function () {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('iEptRequisitionLog', iEptRequisitionLog);

    iEptRequisitionLog.$inject = ['$resource', 'iServiceConfig'];

    function iEptRequisitionLog($resource, iServiceConfig) {
        var service = $resource('web/requisitionLog', {}, {
            'search': {url: iServiceConfig.ienterpriseHost + "/web/requisitionLog/list", method: 'POST'}
        });

        return service;
    }
})();
