(function() {
    "use strict";
    angular.module("itms-dataModule").factory("TmsVehicleType", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsDataHost + "/web/vehicleType", {}, {
        	'list': { url: iServiceConfig.itmsDataHost + "/web/vehicleType/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsDataHost + "/web/vehicleType/saveAndUpdate", method: 'POST'},
            'show': {url: iServiceConfig.itmsDataHost + "/web/vehicleType/show", method: 'POST'},
            'delete': {url: iServiceConfig.itmsDataHost + "/web/vehicleType/delete", method: 'POST'},
            'enable': {url: iServiceConfig.itmsDataHost + "/web/vehicleType/enable", method: 'POST'},
            'getCarrierCodes': {url: iServiceConfig.itmsDataHost + "/web/vehicleType/getCarrierCodes", method: 'POST'},
            'validateCode': {url: iServiceConfig.itmsDataHost + "/web/vehicleType/validateCode", method: 'POST'}
        });
    }]);
})();