(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('SafeInventory', SafeInventory);

    SafeInventory.$inject = ['$resource', 'iServiceConfig'];

    function SafeInventory ($resource, iServiceConfig) {
        var service = $resource('web/safeInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/safeInventory/list", method: 'POST'},
        });
        return service;
    }
})();
