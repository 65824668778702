(function () {
    'use strict';

    angular
        .module('iportalModule')
        .factory('iPortalSystemNotice', iPortalSystemNotice);

    iPortalSystemNotice.$inject = ['$resource', 'iServiceConfig'];

    function iPortalSystemNotice($resource, iServiceConfig) {
        var service = $resource('web/systemNotice', {}, {
            'search': {url: iServiceConfig.iportalHost + "/web/systemNotice/list", method: 'POST'},
            'save': {url: iServiceConfig.iportalHost + "/web/systemNotice/save", method: 'POST'},
            'release': {url: iServiceConfig.iportalHost + "/web/systemNotice/release/:id", method: 'POST'},
            'recall': {url: iServiceConfig.iportalHost + "/web/systemNotice/recall/:id", method: 'POST'},
            'read': {url: iServiceConfig.iportalHost + "/web/systemNotice/read/:id", method: 'POST'}
        });

        return service;
    }
})();
