(function () {
    'use strict';

    angular.module('iwh-dataModule')
        .controller('IwhWarehouseUserAuthController', WarehouseUserAuthController);

    WarehouseUserAuthController.$inject = ['$scope', '$rootScope', '$stateParams', 'IwhWarehouseUserAuth',
        'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout', 'NgTree'];

    function WarehouseUserAuthController($scope, $rootScope, $stateParams, WarehouseUserAuth, NgTableParams,
                                         $uibModal, NgSelect, toastr, $state, $timeout, NgTree) {
        var vm = this;
        vm.state = $rootScope;
        vm.userId = "";
        vm.userName = "";
        vm.tabs = [];
        vm.modalInstance = null;
        vm.contentId = "noSelect.html";
        vm.authTemplate = "auth.html";
        vm.equipmentTemplate = "equipment.html";
        vm.status = [
            {id: 1, title: "启用"},
            {id: -1, title: "禁用"},
        ];


        // function pageTitle() {
        //     return $window.document.title;
        // }


        // 点击设置权限按钮
        vm.selected = function (row) {
            vm.userId = row.userId;
            vm.userName = row.userName;
            // console.debug(vm.userId)
            // vm.contentId = "warehouseUserAuthTemplate.html";
            // var entity = {
            //     active : true
            // };
            // vm.tabs.push(entity);
            vm.authSearch();
            vm.equipmentSearch();
        };

        // 用户列表
        vm.userTableParams = new NgTableParams({}, {
            counts: [],
            getData: function (params) {
                var _params = angular.merge({}, params.filter());
                console.log(_params);
                return WarehouseUserAuth.getUserList(_params, {}, function (resp) {

                }).$promise.then(function (data) {
                    return data.content;
                });
            }
        });

        // 权限列表页功能
        vm.authTableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count(),
                    userId: vm.userId
                }, params.filter());

                if (vm.userId == null || vm.userId == "") {
                    return;
                }

                return WarehouseUserAuth.authList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        //设备列表页功能
        vm.equipmentTableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count(),
                    userId: vm.userId
                }, params.filter());

                if (vm.userId == null || vm.userId == "") {
                    return;
                }

                return WarehouseUserAuth.equipmentList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    vm.equipmentList = datas;
                    return datas;
                });
            }
        });

        vm.authSearch = function () {
            vm.authTableParams.reload();

        };

        vm.equipmentSearch = function () {
            vm.equipmentTableParams.reload();
        };

        vm.addAuth = function () {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addAuth.html',
                controller: addAuthModle,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        var temp = WarehouseUserAuth.addWarehouseUserAuth({}, {}).$promise.then(function (resp) {
                            return resp;
                        });
                        return temp.then(function (resp) {
                            return resp.content;
                        });

                    }
                }
            });
        };

        addAuthModle.$inject = ['$uibModalInstance', 'item'];
        function addAuthModle($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.ok = ok;
            $ctrl.response = {};
            $ctrl.cancel = cancel;
            $ctrl.item = item;
            $ctrl.jobType = null;
            $ctrl.saveVO = {};
            $ctrl.saveVO.regionCode = null;
            $ctrl.saveVO.jobTypes = [];
            $ctrl.saveVO.userId = vm.userId;
            $ctrl.saveAuth = saveAuth;
            $ctrl.list = [];
            $ctrl.regionChange = regionChange;

            function saveAuth(saveVO, form) {
                saveVO.jobTypes = [];
                saveVO.jobTypes.push($ctrl.jobType);
                if (saveVO.regionCode == '' || saveVO.regionCode == null) {
                    toastr.error("作业区域不能为空！")
                    return;
                }
                if ($ctrl.jobType == '' || $ctrl.jobType == null) {
                    toastr.error("作业类型不能为空！")
                    return;
                }
                var resp = vm.saveAuth(saveVO, form);
                resp.then(function (resp) {
                    if (resp.content == "success") {
                        ok();
                    }
                });
            }

            function regionChange(regionCode) {
                var vo = {};
                vo.regionCode = regionCode;
                vo.userId = $ctrl.saveVO.userId;
                var temp = WarehouseUserAuth.findJobType({}, vo).$promise.then(function (resp) {
                    return resp;
                });
                temp.then(
                    function (temp) {
                        $ctrl.list = temp.content;
                    }
                )
            }

            function ok() {
                $uibModalInstance.close();
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.addEquipment = addEquipment;
        function addEquipment() {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addEquipment.html',
                controller: addEquipmentModle,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        var list = [];
                        vm.equipmentList.forEach(function e(e) {
                            list.push(e.equipmentCode);
                        });
                        console.log(list);
                        return list;
                    },
                    item2: function () {
                        var temp = WarehouseUserAuth.addWarehouseUserEquipment({}, {}).$promise.then(function (resp) {
                            return resp;
                        });
                        return temp.then(function (resp) {
                            console.log(resp.content);
                            return resp.content;
                        });
                    }

                }
            });
        }

        addEquipmentModle.$inject = ['$uibModalInstance', 'item', 'item2'];
        function addEquipmentModle($uibModalInstance, item, item2) {
            var $ctrl = this;
            $ctrl.ok = ok;
            $ctrl.response = {};
            $ctrl.allList = item2;
            $ctrl.cancel = cancel;
            $ctrl.multiSelect = new NgSelect({
                allList: $ctrl.allList,
                list: item,
                config: {
                    meta: {
                        label: 'code',
                        id: 'code'
                    }
                }
            });
            $ctrl.equipmentCode = null;
            $ctrl.saveVO = {};
            $ctrl.saveVO.equipmentCodes = [];
            $ctrl.saveVO.userId = vm.userId;
            $ctrl.saveEquipment = saveEquipment;

            function saveEquipment(saveVO, form) {
                if ($ctrl.multiSelect.getSelected() == null || $ctrl.multiSelect.getSelected() == "") {
                    toastr.error("请先选择设备！");
                    return;
                }
                $ctrl.multiSelect.getSelected().forEach(function (e) {
                    saveVO.equipmentCodes.push(e.code);
                });
                var resp = vm.saveEquipment(saveVO, form);
                resp.then(function (resp) {
                    if (resp.content == "success") {
                        ok();
                    }
                });
            }

            function ok() {
                $uibModalInstance.close();
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.addEquipment2 = addEquipment2;
        function addEquipment2() {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'addEquipment2.html',
                controller: addEquipmentModle2,
                controllerAs: '$ctrl',
                resolve: {
                    item: new NgTableParams({}, {
                        counts: [], getData: function () {
                            var temp = WarehouseUserAuth.addWarehouseUserEquipment({}, {}).$promise.then(function (resp) {
                                return resp;
                            });
                            return temp.then(function (resp) {
                                return resp.content;
                            });
                        }
                    })
                }
            });
        }


        addEquipmentModle2.$inject = ['$uibModalInstance', 'item'];
        function addEquipmentModle2($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.ok = ok;
            $ctrl.response = {};
            $ctrl.cancel = cancel;
            $ctrl.item = item;

            $ctrl.equipmentCode = null;
            $ctrl.saveVO = {};
            $ctrl.saveVO.equipmentCodes = [];
            $ctrl.saveVO.userId = vm.userId;
            $ctrl.saveEquipment = saveEquipment;

            function saveEquipment(saveVO, form) {
                $ctrl.item.getSelected().forEach(function (e) {
                    saveVO.equipmentCodes.push(e.code);
                });
                var resp = vm.saveEquipment(saveVO, form);
                resp.then(function (resp) {
                    if (resp.content == "success") {
                        ok();
                    }
                });
            }

            function ok() {
                $uibModalInstance.close();
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


        vm.authEnable = authEnable;
        function authEnable(t) {
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            WarehouseUserAuth.authEnable({}, vo, function (resp) {
                if (resp.content == "success") {
                    vm.authTableParams.reload();
                }
            });
        }

        vm.equipmentEnable = equipmentEnable;
        function equipmentEnable(t) {
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            WarehouseUserAuth.equipmentEnable({}, vo, function (resp) {
                if (resp.content == "success") {
                    vm.equipmentTableParams.reload();
                }
            });
        }

        vm.saveAuth = saveAuth;
        function saveAuth(saveVO, form) {
            var valid = form.$valid;
            console.log(saveVO.jobTypes);
            return WarehouseUserAuth.saveWarehouseUserAuth({}, saveVO).$promise.then(function (resp) {
                if (resp.content == "success") {
                    toastr.success("保存成功");
                    vm.authTableParams.reload();
                    return resp;
                }
            });
        }

        vm.saveEquipment = saveEquipment;
        function saveEquipment(saveVO, form) {
            var valid = form.$valid;
            return WarehouseUserAuth.saveUserEquipment({}, saveVO).$promise.then(function (resp) {
                if (resp.content == "success") {
                    toastr.success("保存成功");
                    vm.equipmentTableParams.reload();
                    return resp;
                }
            });
        }

        vm.confirmDeleteAuth = function (row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: row
                }
            });
            vm.modalInstance.result.then(function (item) {
                WarehouseUserAuth.deleteAuth({}, item.id, function (resp) {
                    if (resp.content == "success") {
                        toastr.success("删除成功");
                        vm.authSearch();
                    }
                });
            });
        };

        vm.confirmDeleteEquipment = function (row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: row
                }
            });
            vm.modalInstance.result.then(function (item) {
                WarehouseUserAuth.deleteEquipment({}, item.id, function (resp) {
                    if (resp.content == "success") {
                        toastr.success("删除成功");
                        vm.equipmentSearch();
                    }
                });
            });
        };

        vm.confirmSaveAuth = function (t, form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        t: t,
                        form: form
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                vm.saveAuth(item.t, item.form);
            });
        };

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
