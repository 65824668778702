(function () {
    'use strict';

    angular.module('itms-dataModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('tms-driver-maintenance', {
                parent: 'main',
                url: 'tms-data/driver-maintenance?carrierCode&fleetCode',
                params: {'carrierCode': null,'fleetCode' : null},
                data: {
                    'pageTitle': '司机维护',
                    'carrierCode' : null,
                    'fleetCode' : null,
                },
                views: {
                    'mainContent': {
                        templateUrl: 'tms-data-module/driver/driver.html',
                        controller: 'TmsDriverController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
