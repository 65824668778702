(function() {
    'use strict';

    angular
        .module('iprintModule')
        .factory('PrintTask', PrintTask);

    PrintTask.$inject = ['$resource', 'iServiceConfig'];

    function PrintTask ($resource, iServiceConfig) {
        var service = $resource('web/printtask', {}, {
            'list': { url: iServiceConfig.iprintHost + "/web/printtask/list", method: 'POST'},
            'reprint': { url: iServiceConfig.iprintHost + "/web/printtask/reprint", method: 'POST'},
        });

        return service;
    }
})();
