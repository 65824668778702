(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('mix-rule', {
            parent: 'main',
            url: 'iwh-strategy/mix-rule',
            data: {'pageTitle': '混放规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/mixRule/mixRule.html',
                    controller: 'MixRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('MixRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/mix-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/mix-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/mix-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/mix-rule/:id', params: {id: '@id'}, method: 'GET'},
            'newContentGet': {url: iServiceConfig.iwhStrategyHost + '/web/mix-rule/new', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/mix-rule/detail/save', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/mix-rule/detail/delete/:id', params: {id: '@id'}, method: 'POST'},
        });
    }]);

    app.controller('MixRuleController', [
        '$scope', 'MixRule', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, MixRule, NgTableParams, $timeout, toastr) {
            $scope.ynOptions = [{k: 1, v: '混放'}, {k: 0, v: '不混放'}];

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };
            
            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return MixRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };
            $scope.newMixRuleContent = function () {
            	for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].heading === "新建") {
                        return $scope.active = i + 1;
                    }
                }
            	var tab = {
                        heading: "新建",
                        contentId: "mixRuleDetail.tpl",
                        active: true
                    };
            	$scope.tabs.push(tab);
            	MixRule.newContentGet({}, function (item) {
                    tab.data = item.content;
                    $scope.active = $scope.tabs.length;
                }, function () {
                    $scope.tabs.pop();
                });
            }
            $scope.mixRuleContent = function (row) {
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.name,
                    contentId: "mixRuleDetail.tpl",
                    active: true
                };
                
                $scope.tabs.push(tab);
                MixRule.contentGet({id: row.id}, function (item) {
                    tab.data = item.content;
                    $scope.active = $scope.tabs.length;
                }, function () {
                    $scope.tabs.pop();
                });
            };

            $scope.showMixRuleContent = function (row) {
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.name,
                    contentId: "mixRuleDetail.tpl",
                    active: true,
                    show : true
                };

                $scope.tabs.push(tab);
                MixRule.contentGet({id: row.id}, function (item) {
                    tab.data = item.content;
                    $scope.active = $scope.tabs.length;
                }, function () {
                    $scope.tabs.pop();
                });
            };

            $scope.saveMixRule = function (tab,index) {
                MixRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };
        }
    ]);
})();