(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateSystemLog', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/system-log', {}, {
                'page': {
                    url: iServiceConfig.iergateHost + '/web/system-log/page',
                    method: 'POST'
                }
            });
        }]);
})();