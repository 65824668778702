(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('iEptSupplySettlementMgt', {
                parent: 'main',
                url: 'enterprise/supplySettlementMgt',
                data: {
                    'pageTitle': '供应商结算单管理',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'enterprise-module/settle/supplySettlementMgt.html',
                        controller: 'iEptSupplySettlementMgtController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
