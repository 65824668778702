(function()  {
    'use strict';

    angular.module('ivendorModule')
        .controller('VendorModSoController', VendorModSoController);

    VendorModSoController.$inject = ['$localStorage',"iServiceConfig",'$rootScope', 'So', 'NgTableParams', '$timeout', '$window', 'Upload', 'NgSelect', 'toastr', '$state', '$uibModal','Report'];

    function VendorModSoController ($localStorage,iServiceConfig,$rootScope, So, NgTableParams, $timeout, $window, Upload,  NgSelect, toastr, $state, $uibModal,Report) {
        var vm = this;
        vm.iServiceConfig = iServiceConfig;
        vm.token = $localStorage.authenticationToken;
        vm.state = $rootScope;
        vm.user = {};
        vm.errors = {};
        vm.active = 0;
        vm.tabs = [];
        vm.carrier = {};
        vm.carrierName = null;
        vm.carrierCode = null;
        vm.totalQty = 0;
        vm.temp = 0;
        vm.oldSoDetaiQOList = {};
        vm.sku = null;
        vm.skuList = [];
        vm.skus = [];
        vm.rowData = {};
        vm.createSo = {};
        vm.defaultChoose = null;
        vm.carrierDefault = null;
        vm.createtime = null;
        vm.updatetime = null;
        vm.pageTitle = pageTitle;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.createSo = createSo;
        vm.carrierSelection = carrierSelection;
        vm.saveSo = saveSo;
        vm.saveSku = saveSku;
        vm.editeSku = editeSku;
        vm.editeSaveSku = editeSaveSku;
        vm.addSkus = addSkus;
        vm.deleteSkus = deleteSkus;
        vm.editeSo = editeSo;
        vm.editeSaveSkuByEdite = editeSaveSkuByEdite;
        vm.updateSo = updateSo;
        vm.saveOneSku = saveOneSku;
        vm.getDetail = getDetail;
        vm.deleteDetail = deleteDetail;
        vm.updateAndSumbmit = updateAndSumbmit;
        vm.saveAndSubmit = saveAndSubmit;
        vm.checkSo = checkSo;
        vm.ship = ship;
        vm.deleteSo = deleteSo;
        vm.skuSelection = skuSelection;
        vm.updateOneSku = updateOneSku;
        vm.status = [  
                       	{id : null, title : "不限"},
                        {id : 0, title : "新建"},  
                        {id : 1, title : "已提交"},  
                        {id : 2, title : "已发货"},
                        {id : 3, title : "已上传"},
                        {id : 9, title : "仓库已收货"}
                    ];
        vm.type = [  
                     {id : null, title : "不限"},
                     {id : 10, title : "采购发货"},
                     {id : 20, title : "其他"}
                 ];
        
        vm.reports = [];
        vm.loadReport = loadReport;
        function loadReport() {
        	Report.list({functionCode:"VENDOR_SO_LIST"}, {}, function(resp) {
				vm.reports = resp.content;
			});
        };
        vm.loadReport();
        
        if($state.params.sono!=null) {
        	var row = {};
        	row.sono = $state.params.sono;
        	vm.checkSo(row);
        }
        
        function pageTitle() {
            return $window.document.title;
        }
        
        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return So.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
		
		/*新增发货单*/
		function createSo() {
			So.createSo({},{}, function(resp) {
				var data = resp.content;
				var entity = {
					heading : '新增',
					contentId : "createSo.html",
					data : data,
					roles : [],
					skus : [],
					sku : {},
					rowData : {},
					totalQty : 0,
					
					active : true,
					createTime : null,
					carrierName : null,
					carrierCode : null,
					
					getTime : function getTime(t){
						entity.createTime = t;
					},
					carrierSelection : function carrierSelection(obj){
						entity.carrierName = obj.name;
						entity.carrierCode = obj.code;
						console.log(obj);
					},
					tableParams: new NgTableParams({}, {getData:function(){return vm.skus}})
					
				};
				entity.sku.qty=0;
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			});
		}
		
		/* 保存发货单*/
		function saveSo(t,idx){
			var saveSoVO = {};
			saveSoVO.whcode = t.data.whCode;
			saveSoVO.carrierCode = t.carrierCode;
			saveSoVO.carrierName = t.carrierName;
			saveSoVO.sono = t.data.sono;
			saveSoVO.status = t.data.status;
			saveSoVO.type = t.data.type;
			saveSoVO.supplierCode = t.data.supplierCode;
			saveSoVO.supplierName = t.data.supplierName;
			saveSoVO.shipFromContact = t.data.shipFromContact;
			saveSoVO.shipFromPhone = t.data.shipFromContact;
			saveSoVO.carNo = t.data.carNo;
			saveSoVO.remark = t.data.remark;
			saveSoVO.planArrivalDate = t.createTime;
			saveSoVO.totalQty = t.totalQty;
			saveSoVO.soDetaiQOList = t.skus;
			if(saveSoVO.whcode == null || saveSoVO.whcode==""){
				toastr.error("请选择仓库");
				return;
			}
			if(saveSoVO.type == null || saveSoVO.type == ""){
				toastr.error("请选择单据类型");
			}
			if(saveSoVO.carrierCode == null || saveSoVO.carrierCode==""){
				toastr.error("请选择承运商");
				return;
			}
			if(saveSoVO.planArrivalDate == null || saveSoVO.planArrivalDate == ""){
				toastr.error("请选择预计到货日期");
				return;
			}
			So.saveSo({},saveSoVO).$promise.then(function(data) {
				if(data.content == "success"){
					vm.removeTab(idx);
					toastr.success("保存成功");
					vm.tableParams = new NgTableParams({
						count : 10
					}, {
						getData : function(params) {
							var postParams = angular.merge({
								pn : params.page(),
								ps : params.count()
							}, params.filter());
							return So.list({}, postParams, function(resp) {
								params.total(resp.content.tc);
							}).$promise.then(function(data) {
								var datas = data.content.datas;
								return datas;
							});
						}
					});
				}else{
					toastr.error("保存失败");
				}
				});
		}
		
		function saveAndSubmit(t,idx){
			t.data.status = 1;
			saveSo(t,idx);
		}
		vm.submiteSo = submiteSo;
		function submiteSo(row){
			row.status = 1;
			var updateSoVO = {};
			updateSoVO.sono = row.sono;
			updateSoVO.status = row.status;
			So.updateSo({},updateSoVO).$promise.then(function(data) {
				if(data.content == "success"){
					toastr.success("提交成功");
					vm.tableParams = new NgTableParams({
						count : 10
					}, {
						getData : function(params) {
							var postParams = angular.merge({
								pn : params.page(),
								ps : params.count()
							}, params.filter());
							return So.list({}, postParams, function(resp) {
								params.total(resp.content.tc);
							}).$promise.then(function(data) {
								var datas = data.content.datas;
								return datas;
							});
						}
					});
				}else{
					toastr.error("提交失败");
				}
			});
		}
		function updateSo(t,idx){
			var updateSoVO = {};
			updateSoVO.whcode = t.data.whCode;
			updateSoVO.carrierCode = t.carrierCode;
			updateSoVO.carrierName = t.carrierName;
			updateSoVO.sono = t.data.sono;
			updateSoVO.status = t.data.status;
			updateSoVO.type = t.data.type;
			updateSoVO.supplierCode = t.data.supplierCode;
			updateSoVO.supplierName = t.data.supplierName;
			updateSoVO.shipFromContact = t.data.shipFromContact;
			updateSoVO.shipFromPhone = t.data.shipFromContact;
			updateSoVO.carNo = t.data.carNo;
			updateSoVO.remark = t.data.remark;
			updateSoVO.planArrivalDate = t.editeTime;
			updateSoVO.totalQty = t.totalQty;
			updateSoVO.soDetaiQOList = t.editeSkus;
			updateSoVO.oldSoDetaiQOList = vm.oldSoDetaiQOList;
			console.log(updateSoVO);
			So.updateSo({},updateSoVO).$promise.then(function(data) {
				if(data.content == "success"){
					vm.removeTab(idx);
					toastr.success("保存成功");
				}else{
					toastr.error("保存失败");
				}
			});
			
		}
		function updateAndSumbmit(t,idx){
			t.data.status = 1;
			vm.updateSo(t,idx);
		}
		
		function saveOneSku(t){
			var detailVO = {};
			detailVO.sono = t.data.sono;
			detailVO.sku = vm.sku.code;
			detailVO.skuName = vm.sku.name;
			detailVO.qty = t.sku.qty;
			if(t.editeSkus.length == 0){
				detailVO.soLineNo = 1;
			}else{
				detailVO.soLineNo = parseInt(t.editeSkus[t.editeSkus.length-1].soLineNo) + 1;
			}
			var flag = true;
			for(var i=0;i<t.editeSkus.length;i++){
				if(t.editeSkus[i].sku == vm.sku.code){
					toastr.error("列表已有此商品", "错误");
					flag = false;
					break;
				}
				
			}
			if(flag){
				So.saveOneSku({},detailVO).$promise.then(function(data) {
					So.getDetail({}, t.data.sono).$promise.then(function(data) {
						t.editeSkus = data.content;
						t.tableParams.reload();
						t.totalQty = 0;
						for(var i=0;i<t.editeSkus.length;i++){
							t.totalQty = t.totalQty+t.editeSkus[i].qty;
						}
					});
				});
			}
			vm.sku = null;
			t.sku.qty = 0; 
			vm.defaultChoose = null;
		}
		
		function updateOneSku(t){
			var detailVO = {};
			detailVO.sono = t.data.sono;
			detailVO.sku = vm.sku.code;
			detailVO.skuName = vm.sku.name;
			detailVO.qty = t.sku.qty;
			detailVO.id = t.rowData.id;
			So.updateOneSku({},detailVO).$promise.then(function(data) {
				So.getDetail({}, t.data.sono).$promise.then(function(data) {
					t.editeSkus = data.content;
					t.tableParams.reload();
					t.totalQty = 0;
					for(var i=0;i<t.editeSkus.length;i++){
						t.totalQty = t.totalQty+t.editeSkus[i].qty;
						console.log(t.totalQty);
					}
				});
			});
		}
		
		function getDetail(t){
			So.getDetail({}, t.data.sono).$promise.then(function(data) {
				t.skus = data.content;
				t.tableParams.reload();
			});
		}
		
		function carrierSelection(obj){
			vm.carrierName = obj.name;
			vm.carrierCode = obj.code;
			console.log(obj);
		}
		
		function skuSelection(obj){
			vm.sku= obj;
			
		}
		
		function saveSku(t){
			var temp = {};
			var line;
			if(vm.sku.code==null){
				toastr.error("请先选择商品", "错误");
				return;
			}
			if(t.skus.length == 0){
				line = 1;
			}else{
				line = t.skus[t.skus.length-1].soLineNo+1;
			}
			var sku = vm.sku.code;
			var skuName = vm.sku.name;
			var qty = t.sku.qty;
			var entryName = vm.sku.tenantName;
			var flag = true;
			temp.entryDt = new Date();
			temp.sku = sku;
			temp.skuName = skuName;
			temp.qty = qty;
			temp.soLineNo = line;
			temp.entryName = entryName;
			for(var i=0;i<t.skus.length;i++){
				if(t.skus[i].sku == temp.sku){
					toastr.error("列表已有此商品", "错误");
					flag = false;
					break;
				}
			}
			if(flag){
				t.skus.push(temp);
				t.totalQty = t.totalQty+parseInt(temp.qty);
			}
			vm.sku = null;
			t.sku.qty = 0; 
			vm.defaultChoose = null;
		}
		
		function editeSku(row,t){
			$('#addSkus').css("display","block");
			$('#addSku').css("display","block");
			$('#saveSku').css("display","none");
			$('#editeSaveSku').css("display","block");
			$('#saveOneSku').css("display","none");
			$('#editeSaveSkuByEdite').css("display","block");
			var sku = {};
			sku.code = row.sku;
			sku.name = row.skuName;
			vm.sku = sku;;
			vm.defaultChoose = row.sku;
			var qty = row.qty;
			vm.temp = qty;
			t.sku.qty = qty; 
			t.rowData = row;
			if(vm.sku.list==null){
			So.getSkus({}, {}).$promise.then(function(data) {
				vm.sku = data.content;
				
			});
			}
		}
		
		function editeSaveSku(t){
			var sku = vm.sku.code;
			var skuName = vm.sku.name;
			var qty = t.sku.qty;
			var entryName = t.sku.tenantName;
			for(var i=0;i<t.skus.length;i++){
				if(t.skus[i].soLineNo==t.rowData.soLineNo){
					t.skus[i].entryDt = new Date();
					t.skus[i].sku = sku;
					t.skus[i].skuName = skuName;
					t.skus[i].qty = qty;
					t.totalQty = t.totalQty-vm.temp+parseInt(qty);
					break;
				}
			}
		}
		
		function editeSaveSkuByEdite(t){
			var sku = vm.sku.code;
			var skuName = vm.sku.name;
			var qty = t.sku.qty;
			var entryName = t.rowData.entryName;
			for(var i=0;i<vm.skus.length;i++){
				if(vm.skus[i].soLineNo==vm.rowData.soLineNo){
					vm.skus[i].updateD = new Date();
					vm.skus[i].skuName = skuName;
					vm.skus[i].updateN = vm.updateN;
					vm.skus[i].qty = qty;
					break;
				}
			}
		}
		
		function addSkus(){
			$('#editeSaveSkuByEdite').css("display","none");
			$('#saveOneSku').css("display","block");
			$('#editeSaveSku').css("display","none");
			$('#addSkus').css("display","block");
			$('#saveSku').css("display","block");
			$('#addSku').css("display","block");
			So.getSkus({}, {}).$promise.then(function(data) {
				vm.sku = data.content;
				
			});
		}
		
		function deleteSkus(t){
			var skus= t.tableParams.getSelected();
			console.log(skus);
			for(var i=0;i<skus.length;i++){
				for(var j=0;j<t.skus.length;j++){
					if(t.skus[j].soLineNo==skus[i].soLineNo){
						console.log(j);
						t.totalQty = t.totalQty-parseInt(t.skus[j].qty);
						t.skus.splice(j,1);
						break;
					}
				}
			}
		}
		
		function deleteDetail(t){
			console.log(111);
			var skus= t.tableParams.getSelected();
			var idList = [];
			var deleteDetailVO = {};
			for(var i=0;i<skus.length;i++){
					idList[i]= skus[i].id;
				}
			deleteDetailVO.idList = idList;
			deleteDetailVO.sono = t.data.sono;
			So.deleteDetail({},deleteDetailVO).$promise.then(function(data) {
				if(data.content=="success"){
					So.getDetail({}, t.data.sono).$promise.then(function(data) {
						t.editeSkus = data.content;
						t.totalQty = 0;
						for(var i=0;i<t.editeSkus.length;i++){
							t.totalQty = t.totalQty+t.editeSkus[i].qty;
						}
						t.tableParams.reload();
					});
				}
			});
			}
		
		vm.deleteDetailConfirm = deleteDetailConfirm;
		function deleteDetailConfirm(tab){
			var sos= tab.tableParams.getSelected();
			if(sos==""||sos==null){
				toastr.warning("选择不能为空", "警告");
				return ;
			}
			
			//发货确认
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-ship.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab
					}
				}
			});
			vm.modalInstance.result.then(function(item) {
				var skus= item.tab.tableParams.getSelected();
				var idList = [];
				var deleteDetailVO = {};
				for(var i=0;i<skus.length;i++){
						idList[i]= skus[i].id;
					}
				deleteDetailVO.idList = idList;
				deleteDetailVO.sono = item.tab.data.sono;
				So.deleteDetail({},deleteDetailVO).$promise.then(function(data) {
					if(data.content=="success"){
						So.getDetail({}, item.tab.data.sono).$promise.then(function(data) {
							item.tab.editeSkus = data.content;
							item.tab.totalQty = 0;
							for(var i=0;i<item.tab.editeSkus.length;i++){
								item.tab.totalQty = item.tab.totalQty+item.tab.editeSkus[i].qty;
							}
							item.tab.tableParams.reload();
						});
					}
				});
			});
			return;
		}
		
		
		/*编辑发货单*/
		function editeSo(row) {
			So.editeSo({},row.sono, function(resp) {
				var data = resp.content;
				data.type = data.type+"";
				vm.carrierDefault = data.carrierCode;
				vm.carrier.code = data.carrierCode;
				vm.carrier.name = data.carrierName;
				vm.updateN = data.updateN;
				vm.updateD = data.updateD;
				vm.oldSoDetaiQOList = data.soDetailBeanList;
				console.log(vm.carrier);
				var entity = {
					heading : '编辑',
					contentId : "editeSo.html",
					data : data,
					roles : [],
					active : true,
					editeSkus : data.soDetailBeanList,
					sku : {},
					rowData : {},
					totalQty : data.totalQty,
					editeTime : null,
					getTime : function getTime(t){
						entity.editeTime = t;
					},
					carrierName : data.carrierCode,
					carrierCode : data.carrierName,
					carrierSelection : function carrierSelection(obj){
						entity.carrierName = obj.name;
						entity.carrierCode = obj.code;
					},
					tableParams: new NgTableParams({}, {getData:function(){return vm.skus}})
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			});
		}
		
		function checkSo(row) {
			So.checkSo({},row.sono, function(resp) {
				var data = resp.content;
				data.type = data.type+"";
 				var entity = {
					heading : '查看',
					contentId : "checkSo.html",
					data : data,
					roles : [],
					active : true,
					checkSkus : data.soDetailBeanList,
					tableParams: new NgTableParams({}, {getData:function(){return vm.skus}})
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			});
		}
		
		vm.importData = importData;
		function importData() {
			var entity = {
					heading : '导入',
					contentId : "import.html",
					active : true,
					file : null,
					errorList : []
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
		}
		
		function ship(){
			var sos= vm.tableParams.getSelected();
			So.ship({},sos).$promise.then(function(resp) {
				if(resp.content=="success"){
           		toastr.success("发货成功");
           		vm.tableParams = new NgTableParams({
					count : 10
				}, {
					getData : function(params) {
						var postParams = angular.merge({
							pn : params.page(),
							ps : params.count()
						}, params.filter());
						return So.list({}, postParams, function(resp) {
							params.total(resp.content.tc);
						}).$promise.then(function(data) {
							var datas = data.content.datas;
							return datas;
						});
					}
				});
           	 }else{
           		toastr.success("发货失败");
           	 }
			});
		}
		vm.soShipConfirm = soShipConfirm;
		function soShipConfirm(tab){
			var sos= vm.tableParams.getSelected();
			if(sos==""||sos==null){
				toastr.warning("选择不能为空", "警告");
				return ;
			}
			for(var i=0;i<sos.length;i++){
				if(sos[i].status!=1){
					toastr.warning("已提交状态才能发货", "警告");
					return;
				}
				
			}
			
			//发货确认
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-ship.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab
					}
				}
			});
			vm.modalInstance.result.then(function(item) {
				var sos= vm.tableParams.getSelected();
				So.ship({},sos).$promise.then(function(data) {
					if(data.content=="success"){
	           		toastr.success("发货成功");
	           		vm.tableParams = new NgTableParams({
						count : 10
					}, {
						getData : function(params) {
							var postParams = angular.merge({
								pn : params.page(),
								ps : params.count()
							}, params.filter());
							return So.list({}, postParams, function(resp) {
								params.total(resp.content.tc);
							}).$promise.then(function(data) {
								var datas = data.content.datas;
								return datas;
							});
						}
					});
	           	 }else{
	           		toastr.success("发货失败");
	           	 }
				});
			});
			return;
		}
		
		vm.soDeleteConfirm = soDeleteConfirm;
		function soDeleteConfirm(tab){
			var sos= vm.tableParams.getSelected();
			if(sos==""||sos==null){
				toastr.warning("选择不能为空", "警告");
				return ;
			}
			
			//删除确认
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-delete.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab
					}
				}
			});
			vm.modalInstance.result.then(function(item) {
				var sos= vm.tableParams.getSelected();
				var soList = [];
				for(var i=0;i<sos.length;i++){
					soList[i] = sos[i].sono;
				}
				So.deleteSo({},soList).$promise.then(function(data) {
					vm.tableParams = new NgTableParams({
						count : 10
					}, {
						getData : function(params) {
							var postParams = angular.merge({
								pn : params.page(),
								ps : params.count()
							}, params.filter());
							return So.list({}, postParams, function(resp) {
								params.total(resp.content.tc);
							}).$promise.then(function(data) {
								var datas = data.content.datas;
								return datas;
							});
						}
					});
				});
			});
			return;
		}
			
		function deleteSo(){
			var sos= vm.tableParams.getSelected();
			var soList = [];
			for(var i=0;i<sos.length;i++){
				soList[i] = sos[i].sono;
			}
			So.deleteSo({},soList).$promise.then(function(data) {
				vm.tableParams = new NgTableParams({
					count : 10
				}, {
					getData : function(params) {
						var postParams = angular.merge({
							pn : params.page(),
							ps : params.count()
						}, params.filter());
						return So.list({}, postParams, function(resp) {
							params.total(resp.content.tc);
						}).$promise.then(function(data) {
							var datas = data.content.datas;
							return datas;
						});
					}
				});
			});
		}
		
		
		
		vm.selectFile= selectFile;
		function selectFile(node) {    
		    var imgURL = "";      
		    try{     
		        var file = null;  
		        if(node.files && node.files[0] ){  
		            file = node.files[0];   
		        }else if(node.files && node.files.item(0)) {                                  
		            file = node.files.item(0);     
		        }     
		        //Firefox 因安全性问题已无法直接通过input[file].value 获取完整的文件路径  
		        try{  
		            //Firefox7.0   
		            imgURL =  file.getAsDataURL();    
		            //alert("//Firefox7.0"+imgRUL);                           
		        }catch(e){  
		            //Firefox8.0以上                                
		        	imgURL = window.URL.createObjectURL(file);  
		            //alert("//Firefox8.0以上"+imgRUL);  
		        }  
		     }catch(e){      //这里不知道怎么处理了，如果是遨游的话会报这个异常                   
		        //支持html5的浏览器,比如高版本的firefox、chrome、ie10  
		        if (node.files && node.files[0]) {                            
		            var reader = new FileReader();   
		            reader.onload = function (e) {                                        
		                imgURL = e.target.result;    
		            };  
		            reader.readAsDataURL(node.files[0]);   
		        }    
		     }  
		    //imgurl = imgURL;  
//		    vm.creatImg(imgURL);
		    console.log(imgURL);
		    return imgURL;  
		}  
		vm.creatImg= creatImg;
		function creatImg(imgURL){   //根据指定URL创建一个Img对象  
		    var textHtml = "<img src='"+imgURL+"'/>";  
		    $(".mark").after(textHtml);  
		} 


		
		
		vm.uploadSo = uploadSo;
		function uploadSo(t){
			if(t.file==null){
				toastr.error("请选择文件");
				return;
			}
			Upload.upload({
                url: iServiceConfig.vendorHost + "/web/so/uploadSo?token="+vm.token,
                data: {
                    file: t.file
                }
            }).then(function(resp) {
            	 console.log(resp);
           	 if(resp.data.content=="success"){
           		toastr.success("保存成功");
           	 }else{
           		t.errorList = resp.data.content;
           	 }
            }, function(resp) {
           	 if(resp.data.status==="ERROR"){
           		toastr.error(resp.errorMsg);
           	 }
            });
		}
		
		vm.getUploadSoDemo = getUploadSoDemo;
		function getUploadSoDemo(){
			So.getUploadSoDemo({},{}).$promise.then(function(data) {
			});
		}
		
		//确认弹出窗
		confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;

			function ok() {
				$uibModalInstance.close($ctrl.item);
			}

			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}
    }
})();
