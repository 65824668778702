(function()  {
    'use strict';

    angular.module('idashboardModule')
        .controller('WorkloadStatQueryController', WorkloadStatQueryController);

    WorkloadStatQueryController.$inject = ['$rootScope', 'WorkloadStat', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','iServiceConfig'];

    function WorkloadStatQueryController ($rootScope,  WorkloadStat, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        
        function pageTitle() {
            return $window.document.title;
        }

		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				this.httpParams = postParams;
				return WorkloadStat.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search;
        function search() {
            vm.tableParams.reload();
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
