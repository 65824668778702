(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('PackWeightController', PackWeightController);

    PackWeightController.$inject = ['$rootScope', '$timeout', 'BoxBiz', 'NgTableParams','ShipOrder','enums','toastr'];

    function PackWeightController ($rootScope, $timeout,BoxBiz, NgTableParams,ShipOrder,enums,toastr) {
        var vm = this;

        vm.textData = [
            {
                surplus:200,
                qty:300,
                recheckedQty:100,
                skuName:'鞋子',
                skuCode:'111722001100B02507'
            },
            {
                surplus:656,
                qty:547,
                recheckedQty:45,
                skuName:'杯子',
                skuCode:'44987238947H98390'
            },
            {
                surplus:23,
                qty:56,
                recheckedQty:13,
                skuName:'箱子',
                skuCode:'213823989128K18822'
            },
            {
                surplus:6545,
                qty:353,
                recheckedQty:6534,
                skuName:'椅子',
                skuCode:'989483248284P92839'
            },
            {
                surplus:9328,
                qty:3232,
                recheckedQty:4354,
                skuName:'桌子',
                skuCode:'6348348982294W28424'
            }
        ];

        vm.data = {};
        vm.skuDatas = [];
        vm.boxNum = null;
        vm.weight = null;//称重重量
        vm.referenceWeight = null;//参考重量
        vm.diff = null;//差量
        vm.diffPercent = null;//差量百分比
        vm.status = 1;//控制状态框  默认是1 -- normal


        $timeout(function () {
            $('#boxNum').focus();
        });

        vm.scanBoxNum = scanBoxNum; //扫描箱号
        vm.scanWeight = scanWeight; //输入重量
        vm.updateBoxWeight = updateBoxWeight;//强制提交

        //扫描箱号
        function scanBoxNum(event) {
            var keyCode = event.keyCode;
            if (keyCode === 13) {
                if(!vm.boxNum){
                    toastr.error('请扫描有效的箱号/运单号');
                    return;
                }
                //每一次重新扫描  清空数据
                vm.data = {};
                vm.skuDatas = [];
                vm.weight = null;
                vm.status = 1;
                scanBoxNumWay();
            }
        }

        //扫描箱号--方法
        function scanBoxNumWay() {
            if(vm.boxNum){
                BoxBiz.getBoxAndDetail({},{
                    num:vm.boxNum
                },function(res){
                    vm.data = res.content;
                    if(angular .isArray(vm.data.details) && vm.data.details.length>0){
                        vm.skuDatas = vm.data.details;
                        $timeout(function () {
                            $('#takeWeight').focus();
                        });
                    }
                },function(res){
                    toastr.error(res.data.errorMsg);
                });
            }
        }

        //输入重量
        function scanWeight(event) {
            var keyCode = event.keyCode;
            if (keyCode === 13) {
                if(!vm.weight){
                    toastr.error('请输入有效的重量');
                    return;
                }
                vm.status = 2;//正在采集
                // $timeout(function () {
                    scanWeightWay();
                // },2000);
            }
        }

        //输入重量--计算
        function scanWeightWay() {
            if(vm.weight){
                vm.weight = vm.weight*1;
                var diff = (vm.weight-vm.data.stdWeight).toFixed(2)*1;

                if(diff > 0){
                    var diffPercent = (diff/vm.data.stdWeight)*100;
                    if(diffPercent>vm.data.maxAllowDiff){
                        vm.diff = diff;
                        vm.diffPercent = diffPercent;
                        vm.status = 4;//重量差异超出
                        return;
                    }
                }

                updateBoxWeight();
            }
        }


        function updateBoxWeight() {
            if(vm.weight){
                BoxBiz.updateBoxWeight({},{
                    boxNum:vm.boxNum,
                    weight:vm.weight
                },function(res){
                    toastr.success('重量更新成功','成功');
                    vm.status = 3;
                    vm.weight = null;
                    vm.diff = null;
                    vm.diffPercent = null;
                    vm.data = {};
                    vm.skuDatas = [];
                    vm.boxNum = null;
                    $timeout(function () {
                        $('#boxNum').focus();
                    });
                },function(res){
                    toastr.error(res.data.errorMsg);
                });
            }
        }

    }
})();
