(function()  {
    'use strict';

    angular.module('idataModule')
        .controller('CarrierVehicleController', CarrierVehicleController);

    CarrierVehicleController.$inject = ['$rootScope','CarrierVehicle', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function CarrierVehicleController ($rootScope, CarrierVehicle, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];

        function pageTitle() {
            return $window.document.title;
        }


      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
                if($state.params.carrierCode!=null){
                    params.filter().carrierCode = $state.params.carrierCode;
                    $state.params.carrierCode=null;
                }
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count(),
                    orderBy : 'code'
				}, params.filter());

				return CarrierVehicle.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search();
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.validate = validate;
        function validate(code,carrierCode){
            if(code==null ||code==""){
                return
            }else{
                var vo = {};
                vo.code = code;
                vo.carrierCode = carrierCode;
                CarrierVehicle.validateCode({}, vo, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }

		vm.createCarrierVehicle = createCarrierVehicle;
		function createCarrierVehicle() {
            CarrierVehicle.getCarrierCodes({}, {}, function(resp) {
                var  carrierList =resp.content;
                var carrierCode = "";
                if($state.params.carrierCode!=null) {
                    carrierCode = $state.params.carrierCode;
                }
                var entity = {
                    heading : '新建',
                    contentId : "createCarrierVehicle.html",
                    active : true,
                    data: {
                        carrierCode : carrierCode,
                        code : "",
                        name : "",
                        status : 0,
                    },
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
		}

        vm.enable = enable;
        function enable(row){
            CarrierVehicle.enable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("启用成功");
                    vm.tableParams.reload();
                }
            });

        }
        vm.disable = disable;
        function disable(row){
            CarrierVehicle.disable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("禁用成功");
                    vm.tableParams.reload();
                }
            });

        }
		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = t.data;

			 if(t.data.id){
                 CarrierVehicle.saveCarrierVehicle({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 CarrierVehicle.saveCarrierVehicle({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}

		vm.show = show;
		function show(row) {
            CarrierVehicle.showCarrierVehicle({}, row.id, function(resp) {
				var Carrier = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showCarrierVehicle.html",
						active : true,
						data : Carrier,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

		vm.edite = edite;
		function edite(row) {
            CarrierVehicle.showCarrierVehicle({}, row.id, function(resp) {
				var CarrierVehicle = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createCarrierVehicle.html",
						active : true,
						data : CarrierVehicle,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}



		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                CarrierVehicle.deleteCarrierVehicle({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }

		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }

		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
