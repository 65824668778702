(function() {
    'use strict';

    angular.module('idataModule').config(config);
    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider.state('test', {
            parent: 'main',
            url: 'data/test',
            data: {
                'pageTitle': '组件测试'
            },
            views: {
                'mainContent': {
                    templateUrl: 'data-module/test/test.html',
                    controller: 'TestController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

            }
        });
    }
})();
