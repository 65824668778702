(function() {
    'use strict';

    angular.module('doveModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('iBusprocessConfig', {
                parent: 'main',
                url: 'bus/processConfig',
                data: {
                    'pageTitle': '流程配置'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/process/processConfig.html',
                        controller: 'iBusProcessConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
