(function() {
    "use strict";
    angular.module("itms-dataModule").factory("TmsDriver", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsDataHost + "/web/driver", {}, {
        	'list': { url: iServiceConfig.itmsDataHost + "/web/driver/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsDataHost + "/web/driver/saveAndUpdate", method: 'POST'},
            'show': {url: iServiceConfig.itmsDataHost + "/web/driver/show", method: 'POST'},
            'delete': {url: iServiceConfig.itmsDataHost + "/web/driver/delete", method: 'POST'},
            'enable': {url: iServiceConfig.itmsDataHost + "/web/driver/enable", method: 'POST'},
            'getCarrierCodes': {url: iServiceConfig.itmsDataHost + "/web/driver/getCarrierCodes", method: 'POST'},
            'validateCode': {url: iServiceConfig.itmsDataHost + "/web/driver/validateCode", method: 'POST'}
        });
    }]);
})();