(function() {
	'use strict';

	angular.module('idataModule').controller('TestController', TestController);

    TestController.$inject = ['NgTableParams', '$uibModal', '$timeout', 'toastr'];
	function TestController(NgTableParams, $uibModal, $timeout, toastr) {
		//
	}


})();

