(function () {
    'use strict';

    angular
        .module('iportalModule')
        .factory('iPortalUserTodo', iPortalUserTodo);

    iPortalUserTodo.$inject = ['$resource', 'iServiceConfig'];

    function iPortalUserTodo($resource, iServiceConfig) {
        var service = $resource('web/userTodo', {}, {
            'search': {url: iServiceConfig.iportalHost + "/web/userTodo/list", method: 'POST'},
            'save': {url: iServiceConfig.iportalHost + "/web/userTodo/save", method: 'POST'},
            'release': {url: iServiceConfig.iportalHost + "/web/userTodo/release/:id", method: 'POST'},
            'preUpdate': {url: iServiceConfig.iportalHost + "/web/userTodo/preUpdate/:id", method: 'POST'},
            'handle': {url: iServiceConfig.iportalHost + "/web/userTodo/handle/:id", method: 'POST'}
        });

        return service;
    }
})();
