(function () {
    'use strict';
    var app = angular.module('idashboardModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('workloadStatQuery', {
            parent: 'main',
            url: 'dashboard/workloadStatQuery',
            data: {
                'pageTitle': '人员工作量统计查询'
            },
            views: {
	            'mainContent': {
	                templateUrl: 'dashboard-module/workload/workloadStatQuery.html',
	                controller: 'WorkloadStatQueryController',
	                controllerAs: 'vm'
	            }
            },
            resolve: {}
        });
    }]);
})();

