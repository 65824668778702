(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('LockInventoryController', LockInventoryController);

    LockInventoryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'LockInventory', 'toastr', '$uibModal','$state'];

    function LockInventoryController ($rootScope, $timeout,NgTableParams, enums, LockInventory, toastr,  $uibModal,$state) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.curTab = null;
        
        vm.invLockStatuss = enums('inventory.InvLockStatus');
        vm.invLockTypes = enums('inventory.InvLockType');
        vm.InvFrozenTypes = enums('inventory.InvFrozenType');
        
        vm.invLockStatussSelect = vm.invLockStatuss.list('title', 'id');
        vm.invLockTypesSelect = vm.invLockTypes.list('title', 'id');
        vm.InvFrozenTypesSelect = vm.InvFrozenTypes.list('title', 'id');
        
        vm.search = search;
        vm.create = create;
        vm.edit = edit;
        vm.save = save;
        vm.saveLine = saveLine;
        
        vm.close = close;
        vm.cancel = cancel;
        vm.closeFromEdit = closeFromEdit;
        vm.cancelFromEdit = cancelFromEdit;
        vm.lockFromEdit = lockFromEdit;
        vm.thawFromEdit = thawFromEdit;

        vm.openDetailModal = openDetailModal;
        vm.deleteDetail = deleteDetail;
        vm.cancelDetail = cancelDetail;
        vm.removeTab = removeTab;
        
        vm.enumsContain = function(enumsObj, codes, value) {
        	var isok = false;
        	angular.forEach(codes, function(code) {
                if(enumsObj.value(code) == value) {
                	isok = true;
                }
            });
        	return isok;
        }

        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return LockInventory.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        
        function create() {
            var tab = {
                heading: '创建冻结单',
                contentId: "lock-eidt.html",
                data: {
                    status: 0,
                    lockNum: "",
                    type: 10,
                    reasonCode: "",
                    reason: "",
                    remark: ""
                },
                lockDetailParams: undefined,
            };
            LockInventory.lockNum({},{},function(data) {
                tab.data.lockNum = data.content;
                tab.lockDetailParams = new NgTableParams({},{
                    getData: function(params) {
                    	var postParams = angular.merge({pn: params.page(), ps: params.count()}, {lockNum:tab.data.lockNum});
                        return LockInventory.lineDetail({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                    var datas = data.content.datas;
                                    return datas;
                            });
                    }
                });
            });

            vm.tabs.push(tab);
            switchTab(vm.tabs.length);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
            
        }
        
        function edit(lockObj) {
            if (lockObj && lockObj.lockNum) {
            	LockInventory.detail({},{lockNum: lockObj.lockNum}, function(data) {
                    var vo = data.content;
                    var tab = {
                        heading: '编辑冻结单',
                        contentId: "lock-eidt.html",
                        params: {lockNum: vo.lockNum},
                        data: vo,
                        lockDetailParams: undefined,
                    };

                    tab.lockDetailParams = new NgTableParams({},{
                        getData: function(params) {
                        	var postParams = angular.merge({pn: params.page(), ps: params.count()}, {lockNum:vo.lockNum});
                            return LockInventory.lineDetail({}, postParams, 
                                function(resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function(data) {
                                        var datas = data.content.datas;
                                        return datas;
                                });
                        }
                    });

                    vm.tabs.push(tab);
                    switchTab(vm.tabs.length);
                });
            } else {
                toastr.error("参数不正确");
            }
        }
        
        function openDetailModal(lockObj, tab) {
        	var modalInstance = $uibModal.open({
                templateUrl: function(){
                	var templateUrl = "";
                	if(lockObj.type == vm.InvFrozenTypes.value('LOC')) {
                		templateUrl = "loc-add.html";
                	} else if(lockObj.type == vm.InvFrozenTypes.value('LPN')) {
                		templateUrl = "lpn-add.html";
                	} else if(lockObj.type == vm.InvFrozenTypes.value('CASE')) {
                		templateUrl = "case-add.html";
                	} else if(lockObj.type == vm.InvFrozenTypes.value('LOT')) {
                		templateUrl = "lot-add.html";
                	}
                	return templateUrl;
                },
                controller: ctrl,
                controllerAs: '$ctrl',
                size: 'xl',
                resolve: {
                    item:function(){
                        return {lockNum: lockObj.lockNum};
                    }
                }
            });
            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.invLockTypesSelect = vm.invLockTypesSelect;
                $ctrl.ok = ok;
                $ctrl.cancel = cancel;

                function ok() {
                    var lockDetail = $ctrl.item;
                    lockDetail.lockNum = tab.data.lockNum;
                    if (!lockDetail || !lockDetail.lockType) {
                        toastr.error("锁定类型不能为空");
                        return;
                    }
                    if (!lockDetail || !lockDetail.bizCodeValues) {
                    	if(tab.data.type == vm.InvFrozenTypes.value('LOC')) {
                    		toastr.error("库位编码不能为空");
                    	} else if(tab.data.type == vm.InvFrozenTypes.value('LPN')) {
                    		toastr.error("托盘LPN不能为空");
                    	} else if(tab.data.type == vm.InvFrozenTypes.value('CASE')) {
                    		toastr.error("箱号不能为空");
                    	} else if(tab.data.type == vm.InvFrozenTypes.value('LOT')) {
                    		toastr.error("库存批次号不能为空");
                    	}
                        return;
                    }
                    if(tab.data.type == vm.InvFrozenTypes.value('LOC') || tab.data.type == vm.InvFrozenTypes.value('LOT')) {
                    	lockDetail.bizCodes = lockDetail.bizCodeValues.join(",");
                	} else {
                		lockDetail.bizCodes = lockDetail.bizCodeValues;
                	}
                    LockInventory.addDetail({}, lockDetail, function(data) {
                    	tab.lockDetailParams.reload();
                    	toastr.success('添加成功');
                    	modalInstance.close();
                    });
                    
                }

                function cancel() {
                    modalInstance.close();
                }
            }
        }

        function save(lockObj, tab) {
            var errorMsg = "";
            if(!lockObj.lockNum || lockObj.lockNum == "") {
            	errorMsg = errorMsg + "冻结单号不能为空\n"
            }
        	if(!lockObj.type || lockObj.type <= 0) {
        		errorMsg = errorMsg + "冻结类型不能为空\n"
        	}
        	if(!lockObj.reasonCode || lockObj.reasonCode == "") {
        		errorMsg = errorMsg + "冻结原因代码不能为空\n"
        	}
        	if(!lockObj.reason || lockObj.reason == "") {
        		errorMsg = errorMsg + "冻结原因不能为空\n"
        	}
        	if(errorMsg != "") {
        		toastr.error(errorMsg);
        		return;
        	}
        	
        	LockInventory.save({}, lockObj, function(data) {
                tab.data = data.content;
                toastr.success('保存成功');
            });
        }

        function lockFromEdit(lockObj, tab) {

            LockInventory.lock({}, {lockNum: lockObj.lockNum}, function(data) {
            	$timeout(function() {
                    vm.tableParams.reload();
                    if(tab && tab.reload) {
                        tab.reload();
                    }
                    LockInventory.detail({}, {lockNum: lockObj.lockNum}, function(data) {
                    	tab.data = data.content;
                    	tab.lockDetailParams.reload();
                    });
                });
                toastr.success(lockObj.lockNum+", 冻结成功");
            });
        }
        function thawFromEdit(lockObj, tab) {

            LockInventory.thaw({}, {lockNum: lockObj.lockNum}, function(data) {
            	$timeout(function() {
                    vm.tableParams.reload();
                    if(tab && tab.reload) {
                        tab.reload();
                    }
                    LockInventory.detail({}, {lockNum: lockObj.lockNum}, function(data) {
                    	tab.data = data.content;
                    	tab.lockDetailParams.reload();
                    });
                });
                toastr.success(lockObj.lockNum+", 解冻成功");
            });
        }
        
        function close(lockObj, tab) {

            LockInventory.close({}, {lockNum: lockObj.lockNum}, function(data) {
            	$timeout(function() {
                    vm.tableParams.reload();
                });
                toastr.success(lockObj.lockNum+", 关闭成功");
            });
        }
        function closeFromEdit(lockObj, tab) {

            LockInventory.close({}, {lockNum: lockObj.lockNum}, function(data) {
            	$timeout(function() {
                    vm.tableParams.reload();
                    if(tab && tab.reload) {
                        tab.reload();
                    }
                    LockInventory.detail({}, {lockNum: lockObj.lockNum}, function(data) {
                    	tab.data = data.content;
                    	tab.lockDetailParams.reload();
                    });
                });
                toastr.success(lockObj.lockNum+", 关闭成功");
            });
        }

        function cancel(lockObj, tab) {

            LockInventory.cancel({}, {lockNum: lockObj.lockNum}, function(data) {
            	$timeout(function() {
                    vm.tableParams.reload();
                });
                toastr.success(lockObj.lockNum+", 取消成功");
            });
        }
        function cancelFromEdit(lockObj, tab) {

            LockInventory.cancel({}, {lockNum: lockObj.lockNum}, function(data) {
            	$timeout(function() {
                    vm.tableParams.reload();
                    if(tab && tab.reload) {
                        tab.reload();
                    }
                    LockInventory.detail({}, {lockNum: lockObj.lockNum}, function(data) {
                    	tab.data = data.content;
                    	tab.lockDetailParams.reload();
                    });
                });
                toastr.success(lockObj.lockNum+", 取消成功");
            });
        }

        function saveLine(lockDetail, tab, modalInstance) {
        	lockDetail.lockNum = tab.data.lockNum;
            if (!lockDetail || !lockDetail.lockType) {
                toastr.error("锁定类型不能为空");
                return;
            }
            if (!lockDetail || !lockDetail.bizCodes) {
            	if(tab.data.type == vm.invLockStatuss.value('LOC')) {
            		toastr.error("库位编码不能为空");
            	} else if(tab.data.type == vm.invLockStatuss.value('LPN')) {
            		toastr.error("托盘LPN不能为空");
            	} else if(tab.data.type == vm.invLockStatuss.value('CASE')) {
            		toastr.error("箱号不能为空");
            	} else if(tab.data.type == vm.invLockStatuss.value('LOT')) {
            		toastr.error("库存批次号不能为空");
            	}
                return;
            }
            LockInventory.addDetail({}, lockDetail, function(data) {
            	tab.lockDetailParams.reload();
            	toastr.success('添加成功');
            });
        }

        function deleteDetail(details, tab) {
            if (details && details.length > 0) {
                var lockNum = tab.data.lockNum;
                var ids = [];
                angular.forEach(details, function(detail) {
                    ids.push(detail.id);
                });

                LockInventory.deleteDetail({}, {lockNum: lockNum, ids: ids}, function() {
                	tab.lockDetailParams.reload();
                    toastr.success('删除成功');
                })
            }
        }
        
        function cancelDetail(details, tab) {
            if (details && details.length > 0) {
                var lockNum = tab.data.lockNum;
                var ids = [];
                angular.forEach(details, function(detail) {
                    ids.push(detail.id);
                });

                LockInventory.cancelDetail({}, {lockNum: lockNum, ids: ids}, function() {
                	tab.lockDetailParams.reload();
                    toastr.success('取消成功');
                })
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
                vm.curTab = vm.tabs[vm.active - 1];
            }, 0);
        }


        vm.toggle = toggle;
        function toggle(row,o){
            row[o] = !row[o];
        }
        
        if($state.params.lockNum) {
        	var lockObj = {lockNum : $state.params.lockNum};
        	vm.edit(lockObj);
        }
    }
})();
