(function()  {
    'use strict';

    angular.module('idataModule')
        .controller('SkuStorageTypeController', SkuStorageTypeController);

    SkuStorageTypeController.$inject = ['$rootScope', 'SkuStorageType', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function SkuStorageTypeController ($rootScope, SkuStorageType, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.type = [  
                     {id : null, title : "不限"},
                     {id : 10, title : "采购发货"},
                     {id : 20, title : "其他"}
                 ];

        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];
        function pageTitle() {
            return $window.document.title;
        }
        
        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count(),
                    orderBy : 'code'
				}, params.filter());
				return SkuStorageType.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
		

		vm.createSkuStorageType = createSkuStorageType;
		function createSkuStorageType() {
 				var entity = {
					heading : '新建',
					contentId : "createSkuStorageType.html",
					active : true,
					data: {
						code : "",
	                    name : "",
	                    remark :""
	                },
					
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
		}
		
		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = t.data;
			 if(t.data.id){
				 SkuStorageType.updateSkuStorageType({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
				SkuStorageType.saveSkuStorageType({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.look = look;
		function look(row) {
			SkuStorageType.lookSkuStorageType({}, row.id, function(resp) {
				var skuStorageType = resp.content;
				var entity = {
						heading : '查看',
						contentId : "lookSkuStorageType.html",
						active : true,
						data : skuStorageType,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = edite;
		function edite(row) {
			SkuStorageType.lookSkuStorageType({}, row.id, function(resp) {
				var skuStorageType = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createSkuStorageType.html",
						active : true,
						data : skuStorageType,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		
		
		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
            	SkuStorageType.deleteSkuStorageType({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }
		
		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }

        vm.enable = enable;
        function enable(row){
            SkuStorageType.enable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("启用成功");
                    vm.tableParams.reload();
                }
            });
        }
        vm.disable = disable;
        function disable(row){
            SkuStorageType.disable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("禁用成功");
                    vm.tableParams.reload();
                }
            });
        }
    }
})();
