(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('PickOrderController', PickOrderController);

    PickOrderController.$inject = ['$rootScope',"$stateParams", '$timeout','PickOrder', 'NgTableParams','enums','toastr','Principal'];

    function PickOrderController ($rootScope, $stateParams, $timeout, PickOrder, NgTableParams,enums,toastr,Principal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;
        vm.userData = {};
        vm.cancelTypes = [{id:"1",name:"取消，并生成移动单，把缺货库存移动到指定库位"},{id:"2",name:"取消，不生成移动单"}];
        vm.PickOrderStatusObj = enums('ship.PickOrderStatus');
        vm.PickOrderStatus = vm.PickOrderStatusObj.list('title', 'id');

        vm.PickMethodObj = enums('ship.PickMethod');
        vm.PickMethod = vm.PickMethodObj.list('title', 'id');

        vm.PrintStatusObj = enums('ship.PrintStatus');
        vm.PrintStatus = vm.PrintStatusObj.list('title', 'id');

        vm.BoxStatusObj = enums('ship.BoxStatus');
        vm.BoxStatus = vm.BoxStatusObj.list('title', 'id');
        
        vm.PickTypeObj = enums('ship.PickType');
        vm.PickType = vm.PickTypeObj.list('title', 'id');
        
        vm.pickDetailStatus = enums('ship.PickDetailStatus');
        vm.stockoutStatus = enums('ship.StockOutStatus');
        
        vm.pickDetailStatusList = vm.pickDetailStatus.list('title', 'id');
        
        vm.stockoutStatusList = vm.stockoutStatus.list('title', 'id');

        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        
        vm.createCancelPickdetailModal = function() {
        	var item = {
        			cancelTypes: angular.copy(vm.cancelTypes),
        			cancelType: 1,
        			toLoc:""
                };

                return item;
        }
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return PickOrder.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        vm.searchPo = searchPo;
        vm.send = send;
        vm.printDo = printDo;
        vm.cancelPick = cancelPick;
        vm.buildParam = function buildParam(pickNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (pickNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    pickNum : pickNum
                };
            } else {
            	var orders = vm.tableParams.getSelected();
            	if (orders.length > 0) {
                    param = [];
                    for(var i=0;i<orders.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            pickNum : orders[i].pickNum
                        });
                    }
            	}
            }
            return param;
        }
        function searchPo() {
            vm.tableParams.reload();
        }

        function send(po) {
        }

        function printDo(order) {
            if (order.length==0) {
                toastr.error("请先选择拣选单!");
                return;
            }
            var pickNums= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status == parseInt(vm.PickOrderStatusObj.value('CANCEL')) || order[i].status < parseInt(vm.PickOrderStatusObj.value('DISTRIBUTE'))){
                    toastr.error("此状态下不能打印拣选单" + order[i].pickNum + '！');
                    return;
                }
                pickNums.push(order[i].pickNum);
            }
            PickOrder.print({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("打印成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.cancelDo = cancelDo;
        function cancelDo(order) {
            if (order.length==0) {
                toastr.error("请先选择拣选单!");
                return;
            }
            var pickNums= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status == parseInt(vm.PickOrderStatusObj.value('CANCEL')) || order[i].status > parseInt(vm.PickOrderStatusObj.value('END_PICK'))){
                    toastr.error("此状态下不能取消拣选单" + order[i].pickNum + '！');
                    return;
                }
                pickNums.push(order[i].pickNum);
            }
            PickOrder.cancel({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("取消成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.cancelOne = cancelOne;
        function cancelOne(row) {
            var pickNums= [];
            if(row.status!=0){
                toastr.error("新建状态下才能取消");
            }
            pickNums.push(row.pickNum);

            PickOrder.cancel({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("取消成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.distributeDo = distributeDo;
        function distributeDo(order) {
            if (order.length==0) {
                toastr.error("请先选择拣选单!");
                return;
            }
            var pickNums= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status!=0){
                    toastr.error("新建状态下才能发放！");
                    return;
                }
                pickNums.push(order[i].pickNum);
            }
            PickOrder.distribute({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("发放成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.distributeOne = distributeOne;
        function distributeOne(row) {
            var pickNums= [];
            if(row.status!=0){
                toastr.error("新建状态下才能发放");
                return;
            }
            pickNums.push(row.pickNum);

            PickOrder.distribute({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("发放成功");
                    vm.tableParams.reload();
                }
            });
        }

        function cancelPick(po) {
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }

    vm.pickDetail = pickDetail;
    function pickDetail(row) {
        PickOrder.detail({pickNum: row.pickNum}, {}, function(poResp) {
            var po = poResp.content;
            if (po) {
                var boxTableParams = new NgTableParams({ count : 10 }, {
                    getData : function(params) {
                        var postParams = angular.merge({
			                                pn : params.page(),
			                                ps : params.count(),
			                                pickNum : row.pickNum
			                            }, params.filter());
                        this.httpParams = postParams;
                        return PickOrder.boxListPage({}, postParams,
                                function(resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function(data) {
                                        var datas = data.content.datas;
                                        return datas;
                                });
                    }
                });
                var pickDetailTableParams = new NgTableParams({ count : 10 }, {
                    getData : function(params) {
                        var postParams = angular.merge({
			                                pn : params.page(),
			                                ps : params.count(),
			                                pickNum : row.pickNum
			                            }, params.filter());
                        this.httpParams = postParams;
                        return PickOrder.pickDetailListPage({}, postParams,
                                function(resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function(data) {
                                        var datas = data.content.datas;
                                        return datas;
                                });
                    }
                });

                var pickDetailLackTableParams = new NgTableParams({ count : 10 }, {
                    getData : function(params) {
                        var postParams = angular.merge({
			                                pn : params.page(),
			                                ps : params.count(),
			                                pickNum : row.pickNum,
			                                stockoutStatus : 2
			                            }, params.filter());
                        this.httpParams = postParams;
                        return PickOrder.pickDetailListPage({}, postParams,
                                function(resp) {
                                    params.total(resp.content.tc);
                                }).$promise.then(function(data) {
                                        var datas = data.content.datas;
                                        return datas;
                                });
                    }
                });
                var tab = {
                    heading: '拣选单详情-'+row.pickNum,
                    contentId: 'pickDetail.html',
                    data: {
                        pickNum: row.pickNum,
                        pickOrder: po
                    },
                    boxTableParams: boxTableParams,
                    pickDetailTableParams: pickDetailTableParams,
                    pickDetailLackTableParams: pickDetailLackTableParams,
                    diffComplete: function(pickNum, t) {
                    	PickOrder.diffComplete({pickNum: pickNum}, {}, function(poResp) {
                            if (poResp.status == 'SUCCESS') {
                            	toastr.success("差异完成成功");
                            	t.data.pickOrder = poResp.content;
                            	t.boxTableParams.reload();
                            	t.pickDetailTableParams.reload();
                            	t.pickDetailLackTableParams.reload();
                            } else {
                            	toastr.success("差异完成执行失败："+ poResp.errorMsg);
                            }
                        })
                    },
                    cancelStockLack: function(t, param) {
                    	console.log(param);
                    	if (param.cancelType) {
                    		if (param.cancelType == 1 && !param.toLoc) {
                    			toastr.error("清洗选择移动目标库位");
                    			return false;
                        	}
                    	} else {
                    		toastr.error("请选择取消方式");
                    		return false;
                    	}
                    	PickOrder.cancelStockLack({pickNum: t.data.pickOrder.pickNum, cancelType: param.cancelType, toLoc: param.toLoc}, {}, function(poResp) {
                            if (poResp.status == 'SUCCESS') {
                            	
                            	if (poResp.content) {
                            		toastr.success("取消缺货分配成功");
                            		t.data.pickOrder = poResp.content;
                                	t.boxTableParams.reload();
                                	t.pickDetailTableParams.reload();
                                	t.pickDetailLackTableParams.reload();
                            	} else {
                            		toastr.error("取消缺货分配成功，拣选单已被取消");
                            		t.data.pickOrder = null;
                            		vm.removeTab(t.tabIndex);
                            	}
                            } else {
                            	toastr.success("取消缺货分配执行失败："+ poResp.errorMsg);
                            }
                        })
                    }
                    
                };
                vm.tabs.push(tab);
                tab.tabIndex = vm.tabs.length - 1;
                switchTab(vm.tabs.length);
            }
        });
    }
    
    vm.diffComplete = diffComplete;
    function diffComplete(pickNum) {
        PickOrder.diffComplete({pickNum: pickNum}, {}, function(poResp) {
            if (poResp.status == 'SUCCESS') {
            	toastr.success("差异完成成功");
            	vm.pickDetail();
            } else {
            	toastr.success("差异完成执行失败："+ poResp.errorMsg);
            }
        });
    }

    vm.toggle = toggle;
    function toggle(row,o){
        row[o] = !row[o];
    }

    }
})();
