(function () {
    'use strict';
    angular.module('idashboardModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('3dInventory', {
                parent: 'main',
                params:{

                },
                url: 'dashboard/3dInventory',
                data: {
                    'pageTitle': '仓库'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'dashboard-module/3DEarth/3DEarth.html',
                        controller: 'ThreeDEarthController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();