(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleAuthController', UserModuleAuthController);

    UserModuleAuthController.$inject = ['UserModuleAuth','$uibModal','$scope','NgTree','$timeout'];

    function UserModuleAuthController(UserModuleAuth, $uibModal,$scope,NgTree,$timeout) {
        var vm = this;
        vm.active = true;
        vm.modalInstance = null;
        vm.addNode = {};
        vm.tabs = [];
        vm.response = {};
        vm.curNode = {};
        vm.systemData = {};
        vm.moduleData = {};
        vm.authData = {};
        vm.response.content = {};
        vm.contentId = "noSelect.html";
       // vm.showSystem = showSystem;
        vm.saveSystem = saveSystem;
        vm.saveModule = saveModule;
        vm.saveAuth = saveAuth;
        vm.saveSys = saveSys;
        vm.saveMod = saveMod;
        vm.saveAu = saveAu;
        vm.editeSystem = editeSystem;
        vm.editeModule = editeModule;
        vm.editeAuth = editeAuth;
        vm.createSystem = createSystem;
        vm.createModule = createModule;
        vm.createAuthByAuth = createAuthByAuth;
        vm.createAuthByModule = createAuthByModule;
        vm.createChildAuth = createChildAuth;
        vm.selectEditeType = selectEditeType;
        vm.judgeMenuType = judgeMenuType;
        
        vm.tree = new NgTree({
            data : [],
            options:{},
            selectedNodeIds : [],
            config:{
                meta:{
                    titleField : 'title',
                    parentIdField : 'parentId',
                    nodeIdField : 'nodeId',
                    childField : 'children',
                    //needCheck: true
                }
            }
        });
        /*vm.treeOptions= {
                templateUrl : 'ng-tree/tree.html',
                nodeChildren  : 'children',
                //传入实例化对象
                tree : vm.tree
                
            };*/
        (function(){
        	$('.overflowY').height($(window).height()-125);
        })();
        
        vm.authTypes = [  
                        {id : 1, title : "菜单"},  
                        {id : 2, title : "页面"},  
                        {id : 3, title : "按钮"},
                        {id : 4, title : "URL"},
                        {id : 5, title : "图表"}
                    ];  
        UserModuleAuth.allAuthTreeBuilder({}, {},
                    function(resp) {
                        if(resp.content) {
                            vm.tree.data = resp.content;
                        }
                    });
        
        function editeSystem(node) {
        	UserModuleAuth.getSystem({}, node.id, function(resp) {
                var data = resp.content;
                vm.contentId = "editeSystem.html";
        		vm.systemData = data;
        		vm.curNode.systemData = data;
        		vm.response.content.result = null;
        		vm.response.errorMsg = null;
            });
        }
        
        function editeModule(node) {
        	UserModuleAuth.getModule({}, node.id, function(resp) {
                var data = resp.content;
                console.log(data);
                vm.contentId = "editeModule.html";
        		vm.curNode.moduleData = data;
        		vm.response.content.result = null;
        		vm.response.errorMsg = null;
            });
        }
        
        function editeAuth(node) {
        	UserModuleAuth.getAuth({}, node.id, function(resp) {
                var data = resp.content;
                console.log(data);
                vm.contentId = "editeAuth.html";
            	vm.curNode.authData = data;
            	vm.response.content.result = null;
            	vm.response.errorMsg = null;
            });
        }
        
       function selectEditeType(node){
    	   vm.curNode = node;
    	   if(node.dataType==1){
    		   vm.editeSystem(node);
    	   }else if(node.dataType==2){
    		   vm.editeModule(node);
    	   }else if(node.dataType==3){
    		   vm.editeAuth(node);
    	   }
       }
       
       vm.addChild = addChild;
       function addChild(){
           if(vm.curNode!==null){
               vm.addNode = vm.tree.addNode(vm.curNode);
           }
       }
       
        function saveSystem(curNode) {
        	return  UserModuleAuth.saveSystem({},curNode.systemData).$promise.then(function(resp){
        		return resp;
        	},function(resp){
        		return resp.data;
        	});
        	
        	
        }
        
        function saveSys(curNode){
        	var temp = vm.saveSystem(curNode);
        	temp.then(function(resp) {
        		vm.response = resp;
        		vm.tree.updateNode(vm.curNode, vm.response.content);
        	});
        }
        function saveModule(curNode) {
        	return  UserModuleAuth.saveModule({},curNode.moduleData).$promise.then(function(resp){
        		return resp;
        	},function(resp){
        		return resp.data;
        	});
        }
        function saveMod(curNode){
        	var temp = vm.saveModule(curNode);
        	temp.then(function(resp) {
        		vm.response = resp;
        		vm.tree.updateNode(vm.curNode, vm.response.content);
        	});
        }
        
        function saveAuth(curNode) {
        	return  UserModuleAuth.saveAuth({},curNode.authData).$promise.then(function(resp){
        		return resp;
        	},function(resp){
        		return resp.data;
        	});
        }
        
        function saveAu(curNode){
        	var temp = vm.saveAuth(curNode);
        	temp.then(function(resp) {
        		vm.response = resp;
        		vm.tree.updateNode(vm.curNode, vm.response.content);
        	});
        }
        
    function showSystem(){
        vm.modalInstance = $uibModal.open({
        	templateUrl: 'createSystem.html',
            controller: systemModle,
            controllerAs: '$ctrl',
            resolve: {}
            });	
    }
    
    
    function createSystem(){
        vm.modalInstance = $uibModal.open({
        	templateUrl: 'createSystem.html',
            controller: createSystemModle,
            controllerAs: '$ctrl',
            resolve: {}
            });	
    }
    
    createSystemModle.$inject = ['$uibModalInstance'];
    function createSystemModle($uibModalInstance) {
        var $ctrl = this;
        $ctrl.ok = ok;
        $ctrl.response = {};
        $ctrl.curNode = {};
        $ctrl.cancel = cancel;
        $ctrl.submitSys = submitSys;
        function submitSys(curNode){
        	var temp = vm.saveSystem(curNode);
        	temp.then(function(resp) {
        		$ctrl.response = resp;
        		if($ctrl.response.errorMsg==null){
        			vm.tree.addNode($ctrl.response.content);
        			$timeout(function(){
        				$ctrl.ok();
        			},1000);
        		}
        	});
        }

        function ok() {
        	$uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
    
    function createModule(){
        vm.modalInstance = $uibModal.open({
        	templateUrl: 'createModule.html',
            controller: createModuleModle,
            controllerAs: '$ctrl',
            resolve: {}
            });	
    }
    
    createModuleModle.$inject = ['$uibModalInstance'];
    function createModuleModle($uibModalInstance) {
        var $ctrl = this;
        $ctrl.ok = ok;
        $ctrl.response = {};
        $ctrl.curNode = {};
        $ctrl.curNode.moduleData = {};
        $ctrl.curNode.moduleData.sysName = vm.curNode.systemData.sysName;
        $ctrl.curNode.moduleData.sysId = vm.curNode.systemData.sysId;
        $ctrl.cancel = cancel;
        $ctrl.submitMod = submitMod;
        
        function submitMod(curNode){
        	var temp = vm.saveModule(curNode);
        	temp.then(function(resp) {
        		$ctrl.response = resp;
        		if($ctrl.response.errorMsg==null){
        			vm.tree.addNode($ctrl.response.content);
        			$timeout(function(){
        				$ctrl.ok();
        			},1000);
        		}
        	});
        }

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
    
    function createAuthByModule(){
        vm.modalInstance = $uibModal.open({
        	templateUrl: 'createAuth.html',
            controller: createAuthByModuleModle,
            controllerAs: '$ctrl',
            resolve: {}
            });	
    }
    
    createAuthByModuleModle.$inject = ['$uibModalInstance'];
    function createAuthByModuleModle($uibModalInstance) {
        var $ctrl = this;
        $ctrl.response = {};
        $ctrl.curNode = {};
        $ctrl.authTypes = vm.authTypes;
        $ctrl.curNode.authData = {};
        $ctrl.curNode.authData.authType = 1;
        $ctrl.curNode.authData.sysName = vm.curNode.moduleData.sysName;
        $ctrl.curNode.authData.moduName = vm.curNode.moduleData.moduName;
        $ctrl.curNode.authData.moduId = vm.curNode.moduleData.moduId;
        $ctrl.ok = ok;
        $ctrl.cancel = cancel;
        $ctrl.submitAuth = submitAuth;
        $ctrl.judgeMenuType = vm.judgeMenuType;
        
        function submitAuth(curNode){
        	var temp = vm.saveAuth(curNode);
        	temp.then(function(resp) {
        		$ctrl.response = resp;
        		if($ctrl.response.errorMsg==null){
        			vm.tree.addNode($ctrl.response.content);
        			$timeout(function(){
        				$ctrl.ok();
        			},1000);
        		}
        	});
        }

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
    
    function createAuthByAuth(){
        vm.modalInstance = $uibModal.open({
        	templateUrl: 'createAuth.html',
            controller: createAuthByAuthModle,
            controllerAs: '$ctrl',
            resolve: {}
            });	
    }
    
    createAuthByAuthModle.$inject = ['$uibModalInstance'];
    function createAuthByAuthModle($uibModalInstance) {
        var $ctrl = this;
        $ctrl.response = {};
        $ctrl.curNode = {};
        $ctrl.authTypes = vm.authTypes;
        $ctrl.curNode.authData = {};
        $ctrl.curNode.authData.authType = 1;
        $ctrl.curNode.authData.moduId = vm.curNode.authData.moduId;
        $ctrl.curNode.authData.parentAuthId = vm.curNode.authData.parentAuthId;
        $ctrl.curNode.authData.parentAuthName = vm.curNode.authData.parentAuthName;
        $ctrl.curNode.authData.sysName = vm.curNode.authData.sysName;
        $ctrl.curNode.authData.moduName = vm.curNode.authData.moduName;
        $ctrl.ok = ok;
        $ctrl.cancel = cancel;
        $ctrl.submitAuth = submitAuth;
        $ctrl.judgeMenuType = vm.judgeMenuType;
        
        function submitAuth(curNode){
        	var temp = vm.saveAuth(curNode);
        	temp.then(function(resp) {
        		$ctrl.response = resp;
        		if($ctrl.response.errorMsg==null){
        			vm.tree.addNode($ctrl.response.content);
        			$timeout(function(){
        				$ctrl.ok();
        			},1000);
        		}
        	});
        }

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
    
    vm.addChild = addChild;
    function addChild(){
        if(vm.curNode!==null){
            vm.addNode = vm.tree.addNode(vm.curNode);
        }
    }
    
    function createChildAuth(){
        vm.modalInstance = $uibModal.open({
        	templateUrl: 'createAuth.html',
            controller: createChildAuthModle,
            controllerAs: '$ctrl',
            resolve: {
            	item: function() {
            		return vm.authData;
            	}
            }
            });	
    }
    
    createChildAuthModle.$inject = ['$uibModalInstance', 'item'];
    function createChildAuthModle($uibModalInstance, item) {
        var $ctrl = this;
        $ctrl.item = item;
        $ctrl.response = {};
        $ctrl.curNode = {};
        $ctrl.curNode.authData = {};
        $ctrl.curNode.authData.authType = 1;
        $ctrl.authTypes = vm.authTypes;
        $ctrl.curNode.authData.sysName = vm.curNode.authData.sysName;
        $ctrl.curNode.authData.moduName = vm.curNode.authData.moduName;
        $ctrl.curNode.authData.parentAuthId = vm.curNode.authData.authId;
        $ctrl.curNode.authData.parentAuthName = vm.curNode.authData.authName;
        $ctrl.curNode.authData.moduId = vm.curNode.authData.moduId;
        $ctrl.ok = ok;
        $ctrl.cancel = cancel;
        $ctrl.submitAuth = submitAuth;
        $ctrl.judgeMenuType = vm.judgeMenuType;
        
        function submitAuth(curNode){
        	var temp = vm.saveAuth(curNode);
        	temp.then(function(resp) {
        		$ctrl.response = resp;
        		if($ctrl.response.errorMsg==null){
        			vm.tree.addNode($ctrl.response.content);
        			$timeout(function(){
        				$ctrl.ok();
        			},1000);
        		}
        	});
        }

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
    
    function judgeMenuType(authType){
    	console.log(11);
    	if(authType==1){
    		console.log("authType==1");
    		$("#menuName").removeAttr("readonly");
    		$("#menuName").attr("required",true);
    		$("#menuUrl").removeAttr("required");
    		$("#menuUrl").attr("readonly",true);
    	}else if(authType==4){
    		console.log("authType==4");
    		$("#menuUrl").removeAttr("readonly");
    		$("#menuUrl").attr("required",true);
    		$("#menuName").removeAttr("required");
    		$("#menuName").attr("readonly",true);
    	}else{
    		$("#menuUrl").removeAttr("required");
    		$("#menuName").removeAttr("required");
    		$("#menuUrl").attr("readonly",true);
    		$("#menuName").attr("readonly",true);
    	}
    }
    
    }
})();
