(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('LotInventory', LotInventory);

    LotInventory.$inject = ['$resource', 'iServiceConfig'];

    function LotInventory ($resource, iServiceConfig) {
        var service = $resource('web/lotInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/lotInventory/list", method: 'POST'},
            'beforeList': { url: iServiceConfig.iwhInventoryHost + "/web/lotInventory/beforeList", method: 'POST'},

        });
        return service;
    }
})();
