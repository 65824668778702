(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('PrintPickOrderController', PrintPickOrderController);

    PrintPickOrderController.$inject = ['$rootScope', '$timeout','PrintPickOrder', 'NgTableParams','enums','toastr','PickOrder','Principal'];

    function PrintPickOrderController ($rootScope, $timeout, PrintPickOrder,NgTableParams,enums,toastr,PickOrder,Principal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;
        vm.PickOrderStatusObj = enums('ship.PickOrderStatus');
        vm.PickOrderStatus = vm.PickOrderStatusObj.list('title', 'id');

        vm.PickMethodObj = enums('ship.PickMethod');
        vm.PickMethod = vm.PickMethodObj.list('title', 'id');

        vm.PrintStatusObj = enums('ship.PrintStatus');
        vm.PrintStatus = vm.PrintStatusObj.list('title', 'id');
        
        vm.PickTypeObj = enums('ship.PickType');
        vm.PickType = vm.PickTypeObj.list('title', 'id');
        
        vm.BoxStatusObj = enums('ship.BoxStatus');
        vm.BoxStatus = vm.BoxStatusObj.list('title', 'id');
        
        vm.pickDetailStatus = enums('ship.PickDetailStatus');
        vm.stockoutStatus = enums('ship.StockOutStatus');
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.search =search;
        function search() {
            vm.tableParams.reload();
            vm.tableParamsLate.reload();
        }
        
        vm.buildParam = function buildParam(pickNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (pickNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    pickNum : pickNum
                };
            } else {
            	var orders = [];
            	if (vm.active == 0) {
            		orders = vm.tableParams.getSelected();
            	} else if (vm.active == 1) {
            		orders = vm.tableParamsLate.getSelected();
            	}
            	if (orders.length > 0) {
                    param = [];
                    for(var i=0;i<orders.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            pickNum : orders[i].pickNum
                        });
                    }
            	}
            }
            return param;
        }
        
        vm.tableParamsLate = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count(),
                    minStatus: vm.PickOrderStatusObj.value('PRINT')
                }, params.filter());
                return PrintPickOrder.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count(),
                    minStatus: vm.PickOrderStatusObj.value('DISTRIBUTE'),
                    maxStatus: vm.PickOrderStatusObj.value('PRINT')
                }, params.filter());
                return PrintPickOrder.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });



        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
        vm.printDo = printDo;
        function printDo(order) {
            if (order.length==0) {
                toastr.error("请先选择拣选单!");
                return;
            }
            var pickNums= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status!=5){
                    toastr.error("发放状态下才能打印！");
                    return;
                }
                pickNums.push(order[i].pickNum);
            }
            PrintPickOrder.print({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("打印成功");
                    vm.search()
                }
            });
        }

        vm.printOne = printOne;
        function printOne(row) {
            var pickNums= [];
            if(row.status!=5){
                toastr.error("发放状态下才能打印");
                return;
            }
            pickNums.push(row.pickNum);

            PrintPickOrder.print({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("打印成功");
                    vm.search();
                }
            });
        }

        vm.latePrintDo = latePrintDo;
        function latePrintDo(order) {
            if (order.length==0) {
                toastr.error("请先选择拣选单!");
                return;
            }
            var pickNums= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status==0){
                    toastr.error("新建状态下无法补打！");
                    return;
                }
                if(order[i].status==5){
                    toastr.error("发放状态下无法补打！");
                    return;
                }
                if(order[i].status==10){
                    toastr.error("已打印状态下无法补打！");
                    return;
                }
                pickNums.push(order[i].pickNum);
            }
            PrintPickOrder.print({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("补打成功");
                    vm.search()
                }
            });
        }

        vm.latePrintOne = latePrintOne;
        function latePrintOne(row) {
            var pickNums= [];
            if(row.status==0){
                toastr.error("新建状态下无法补打！");
                return;
            }
            if(row.status==5){
                toastr.error("发放状态下无法补打！");
                return;
            }
            if(row.status==10){
                toastr.error("已打印状态下无法补打！");
                return;
            }
            pickNums.push(row.pickNum);

            PrintPickOrder.print({}, pickNums, function(resp) {
                if(resp.content=="success"){
                    toastr.success("补打成功");
                    vm.search()
                }
            });
        }
        
        vm.pickDetail = pickDetail;
        function pickDetail(row) {
            PickOrder.detail({pickNum: row.pickNum}, {}, function(poResp) {
                var po = poResp.content;
                if (po) {
                    PickOrder.boxDetailObj({}, row.pickNum, function (resp) {
                        var boxList = resp.content.boxList;
                        var pickDetails = resp.content.pickDetails;
                        var tab = {
                            heading: '拣选单详情-'+row.pickNum,
                            contentId: 'pickDetail.html',
                            data: {
                                boxList: boxList,
                                pickDetails: pickDetails,
                                pickNum: row.pickNum,
                                pickOrder: po
                            }
                        };
                        vm.tabs.push(tab);
                        switchTab(vm.tabs.length+1);
                    });
                }
            });
        }

    }
})();
