(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('PickOrder', PickOrder);

    PickOrder.$inject = ['$resource', 'iServiceConfig'];

    function PickOrder ($resource, iServiceConfig) {
        var service = $resource('web/pickOrder', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/list", method: 'POST'},
            'detail': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/detail", method: 'POST'},
            'diffComplete': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/diffComplete", method: 'POST'},
            'cancelStockLack': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/cancelStockLack", method: 'POST'},
            'distribute': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/distribute", method: 'POST'},
            'cancel': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/cancel", method: 'POST'},
            'print': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/print", method: 'POST'},
            'boxList': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/boxList", method: 'POST'},
            'boxDetailObj': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/boxDetailObj", method: 'POST'},
            'boxListPage': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/boxListPage", method: 'POST'},
            'pickDetailListPage': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/pickDetailListPage", method: 'POST'},
            'beginPick': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/beginPick", method: 'POST'},
            'endPick': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/endPick", method: 'POST'},
            'getSowingPickOrder': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/getSowingPickOrder", method: 'POST'},
            'sowSku': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/sowSku", method: 'POST'},
            'splitSowBox': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/splitSowBox", method: 'POST'},
            'resetSowSku': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/resetSowSku", method: 'POST'},
            'confirmSowPick': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/confirmSowPick", method: 'POST'},
            'confirmSowPickAndCloseBox': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/confirmSowPickAndCloseBox", method: 'POST'}
        });
        return service;
    }
})();
