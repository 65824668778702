(function() {
    'use strict';

    angular
        .module('ireportNodeModule')
        .factory('RptNodeDataSource', RptNodeDataSource);

        RptNodeDataSource.$inject = ['$resource', 'iServiceConfig'];

    function RptNodeDataSource ($resource, iServiceConfig) {
        var service = $resource('web/ds', {}, {
            'list': { url: iServiceConfig.ireportNodeHost + "/web/ds/list", method: 'POST'},
            'open': { url: iServiceConfig.ireportNodeHost + "/web/ds/open", method: 'POST'},
            'close': { url: iServiceConfig.ireportNodeHost + "/web/ds/close", method: 'POST'},
        });

        return service;
    }
})();
