(function() {
    'use strict';

    angular.module('iwh-receiptModule').controller('ReceiptUnlockController', ReceiptUnlockController);
    ReceiptUnlockController.$inject = ["$rootScope", "$scope", "NgTableParams",
        "$uibModal", "NgSelect", "$state", "$timeout", "toastr", "NgTree",
        "$window", "iServiceConfig", "$httpParamSerializer", "ReceiptUnlock"];
    function ReceiptUnlockController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                           $state, $timeout, toastr, NgTree, $window,
                               iServiceConfig, $httpParamSerializer, ReceiptUnlock) {
        var vm = this;
        vm.tabs = [];                   // 记录所有的tab
        vm.active = 0;                  // 总共打开tab的总数
        vm.fliter = {};

        vm.removeTab = removeTab;       // 删除指定下标的tab页
        vm.unlockTx = unlockTx;         // 事务解锁
        vm.eventKeyupHandler = eventKeyupHandler;


        vm.reloadTableData = reloadTableData;
        vm.receiptLotListNgTable = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count(),
                    txKey : vm.fliter.txKey,
                    asnNum : vm.fliter.asnNum
                }, params.filter());
                return ReceiptUnlock.getList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        function eventKeyupHandler($event, data) {
            reloadTableData();
        }

        function reloadTableData() {
            vm.receiptLotListNgTable.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function unlockTx(row) {
            var _txKey = row.txKey;
            ReceiptUnlock.unlockTx({}, {
                txKey : row.txKey,
                asnNum : row.asnNum
            }, function(resp){
                toastr.success("解锁[" + _txKey + "]成功");
                vm.reloadTableData();
            });
        }
    }
})();