(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhSku', Sku);

    Sku.$inject = ['$resource', 'iServiceConfig'];

    function Sku ($resource, iServiceConfig) {
        var service = $resource('web/sku', {}, {
            'skuList': { url: iServiceConfig.iwhDataHost + "/web/sku/skulist", method: 'POST'},
            'skuDetail': {url: iServiceConfig.iwhDataHost + "/web/sku/skudetail", method: 'POST'},
            'skuUpdata': {url: iServiceConfig.iwhDataHost + "/web/sku/skuupdata", method: 'POST'},
            'palletUpdata': {url: iServiceConfig.iwhDataHost + "/web/sku/skupalletupdata", method: 'POST'},
            'putDetail': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputdetail", method: 'POST'},
            'putZones': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputzones", method: 'POST'},
            'putLocs': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputlocs", method: 'POST'},
            'skuLocs': {url: iServiceConfig.iwhDataHost + "/web/sku/skulocs", method: 'POST'},
            'putZoneSave': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputzonesave", method: 'POST'},
            'putLocSave': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputlocsave", method: 'POST'},
            'skuLocSave': {url: iServiceConfig.iwhDataHost + "/web/sku/skulocsave", method: 'POST'},
            'putZoneDel': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputzonedel", method: 'POST'},
            'putLocDel': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputlocdel", method: 'POST'},
            'skuLocDel': {url: iServiceConfig.iwhDataHost + "/web/sku/skulocdel", method: 'POST'},
            'putZoneUpdata': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputzoneupdata", method: 'POST'},
            'putLocUpdata': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputlocupdata", method: 'POST'},
            'skuLocUpdata': {url: iServiceConfig.iwhDataHost + "/web/sku/skulocupdata", method: 'POST'},
            'putZoneCheck': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputzonehasexists", method: 'POST'},
            'putLocCheck': {url: iServiceConfig.iwhDataHost + "/web/sku/skuputlochasexists", method: 'POST'},
            'skuLocCheck': {url: iServiceConfig.iwhDataHost + "/web/sku/skulochasexists", method: 'POST'}
        });

        return service;
    }
})();
