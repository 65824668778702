(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleEmployeeController', UserModuleEmployeeController);

    UserModuleEmployeeController.$inject = ['UserModuleEmployee', 'NgTableParams','$uibModal', 'Principal','$timeout','NgTree','toastr','NgSelect'];

    function UserModuleEmployeeController(UserModuleEmployee, NgTableParams, $uibModal, Principal,$timeout,NgTree,toastr,NgSelect) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        
        vm.tree = null;
        
        vm.tabs = [];
        vm.active = 0;
        vm.account = {};
        vm.status = [{'id':null, 'title':'全部'}, {'id':0, 'title':'已禁用'},{'id':10, 'title':'已启用'}];
        vm.type = [{'id':10,'title':'公司'},{'id':20,'title':'部门'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.showEmployee=showEmployee;
        vm.update = update;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.changeStatus=changeStatus;
        vm.changeData = changeData;
        
        vm.show =false;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return UserModuleEmployee.list({}, postParams, function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.datas;

                    return datas;
                });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        function changeData(row){
        	if(row.status > 1) {
        		UserModuleEmployee.disable({}, {
            		id: row.id
            	},function(resp){
                    if(resp.status=="SUCCESS"){
                        toastr.success("禁用成功！");
                        vm.search();
                    }
            	});
        	} else {
        		UserModuleEmployee.enable({}, {
        			id: row.id
            	},function(resp){
                    if(resp.status=="SUCCESS"){
                        toastr.success("禁用成功！");
                        vm.search();
                    }
            	});
        	}
        }
        function changeStatus(row){
            vm.modalInstance = $uibModal.open({
            	templateUrl: 'employee-disableAndEnable.html',
                controller: changeModal,
                controllerAs: '$ctrl',
                resolve: {
                		item: function () {
                				return row;
                        	}
                    	}
                });	
            vm.modalInstance.result.then(function(item) {
                vm.changeData(item);
            });
        }
        
        changeModal.$inject = ['$uibModalInstance', 'item'];
        function changeModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = function() {
                $uibModalInstance.close($ctrl.item);
            }
            $ctrl.cancel = function() {
            	$uibModalInstance.dismiss('cancel');
            }
        }

        
        function showEmployee(row){
        	UserModuleEmployee.detail({},{id: row.id},function(resp){
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,'错误');
        			return;
        		}
        		var data = resp.content;
        		UserModuleEmployee.authTreeData({},{},function(res){
        			if(resp.status === 'ERROR') {
        				toastr.error(resp.errorMsg,'错误');
            			return;
            		}
        			var treeData = res.content;
        			var entity = {
                            heading: '查看'+data.empNum,
                            contentId: "employee-showEmployee.html",
                            data: data,
                            roles: [],
                            treeModel: new NgTree({
        	       	             data :treeData,
        	    	             config:{
        	    	                 meta:{
        	    	                     titleField : 'title',
        	    	                     parentIdField : 'parentId',
        	    	                     nodeIdField : 'nodeId',
        	    	                     childField : 'children',
        	    	                     needCheck: true
        	    	                 }
        	    	             }
        	    	         }),
                            active: true
                        };
                	
            			vm.tabs.push(entity);
            			$timeout(function(){
                            vm.active = vm.tabs.length;
                        },0);
        		});
        	});
        }
        	
        function edit(row) {
        	UserModuleEmployee.detail({}, {id: row.id}, function(resp) {
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,"错误");
         			return;
         		}
        		 var data = resp.content;
        		 UserModuleEmployee.authTreeData({},{},function(res){
         			if(res.status === 'ERROR') {
         				toastr.error(res.errorMsg,"错误");
             			return;
             		}
         			var treeData = res.content;
         			var entity = {
                             heading: '编辑'+data.empNum,
                             contentId: "employee-eidt.html",
                             data: data,
                             roles: [],
                             treeModel: new NgTree({
         	       	             data :treeData,
         	    	             config:{
         	    	                 meta:{
         	    	                     titleField : 'title',
         	    	                     parentIdField : 'parentId',
         	    	                     nodeIdField : 'nodeId',
         	    	                     childField : 'children',
         	    	                     needCheck:false
         	    	                 }
         	    	             }
         	    	         }),
         	    	        showSelected:function(node){
        	                	console.log(node);
        	                	entity.data.orgName = node.title;
        	                	entity.data.orgId = node.nodeId;
        	    	         },
                             active: true
                         };
                 	
             			vm.tabs.push(entity);
             			$timeout(function(){
                             vm.active = vm.tabs.length;
                         },0);
         		});
        	});
        }
        function create() {
        	UserModuleEmployee.authTreeData({}, {}, function(resp) {
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,'错误');
        			return;
        		}
        		var treeData = resp.content;
        		UserModuleEmployee.roles({},{}, function(res){
        			var roles = res.content;
        			
	            var entity = {
	                heading: '新建',
	                contentId: "employee-eidt.html",
	                
	                treeModel: new NgTree({
	                    data : treeData,
	                    config:{
	                        meta:{
	                            titleField : 'title',
	                            parentIdField : 'parentId',
	                            nodeIdField : 'nodeId',
	                            childField : 'children',
	                            needCheck: false
	                        }
	                    }
	                }),
	                showSelected:function(node){
	                	console.log(node);
	                	entity.data.orgName = node.title;
	                	entity.data.orgId = node.nodeId;
	    	         },
	    	         
	                data: {
	                    tenantName: vm.account.tenantName,
	                    tenantCode:vm.account.tenantCode,
	                    orgName:"",
	                    empNum:"",
	                    name:"",
	                    pinyinName:"",
	                    status:"",
	                    title:"",
	                    phone:"",
	                    email:"",
	                    userName:"",
	                    password:"",
	                    password1:""
	                },
	                multiSelect :new NgSelect({
                    	allList:roles,
                    	list:[],
	                    config:{
	                        meta:{
	                            label:'roleName',
	                            id:'roleId'
	                        }
	                    }
                }),
	                active: true
	            };
            
	            vm.tabs.push(entity);
	            $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
        		});
        	});
        }

        
        function update(tab, idx, form) {
            var employee = tab.data;
            console.log("1111111");

            var valid = form.$valid;
            if (employee.orgName==null||employee.orgName=="") {
                toastr.error("请选择组织!", '错误');
                return;
            }
            if (employee.empNum==null||employee.empNum=="") {
                toastr.error("员工编号不能为空！", '错误');
                return;
            }
            if (employee.name==null||employee.name=="") {
                toastr.error("中文名字不能为空！", '错误');
                return;
            }

            if(vm.show) {
            	if (employee.userName.trim() === '') {
            		toastr.error("请输入用户登录名", '错误');
                	return;
            	}
                if (employee.password !== employee.password1) {
            		console.log("eqweqwe")
                	toastr.error("两次输入的密码不正确", '错误');
                	return;
                }
            }
            if(vm.show) {
	            if(tab.multiSelect){
		            var selecRoles = tab.multiSelect.getSelected();
		            if(!selecRoles || selecRoles.length <= 0) {
		            	toastr.error("请选择用户对应的角色", '错误');
		            	return;
		            }
		            employee.roleIds = [];
		            angular.forEach(selecRoles, function(item) {
		            	employee.roleIds.push(item.roleId);
		            });
	            }
            }
            if(!tab.data.orgId) {
            	toastr.error("请选择所属组织", '错误');
            	return;
            }
            employee.orgId=tab.data.orgId;
            UserModuleEmployee.save({}, employee, function(resp) {
            	if(resp.status === 'ERROR') {
        			toastr.error("员工编码已经存在，请重新输入",'错误');
        			return;
        		}
        		toastr.success("保存成功！");
                vm.search();
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteData(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
