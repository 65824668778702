(function() {
    'use strict';

    angular
        .module('ireportNodeModule')
        .factory('RptNodeIndexHome', RptNodeIndexHome);

        RptNodeIndexHome.$inject = ['$resource', 'iServiceConfig'];

    function RptNodeIndexHome ($resource, iServiceConfig) {
        var service = $resource('web/', {}, {
            'nodeStat': { url: iServiceConfig.ireportNodeHost + "/web/nodeStat", method: 'GET'}
        });

        return service;
    }
})();
