(function () {
    'use strict';

    angular.module('iwh-shipModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('so-maintenance', {
                parent: 'main',
                url: 'iwh-ship/so-maintenance?soNum',
                params: {
                    'soNum':null
                },
                data: {
                    'pageTitle': '出库单维护'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/so/so.html',
                        controller: 'SOController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
            .state('processBlockSO', {
                parent: 'main',
                url: 'iwh-ship/processBlockSO',
                data: {
                    'pageTitle': '拦截处理'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-ship-module/biz/so/soBlock.html',
                        controller: 'SOBlockController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
        ;
    }
})();
