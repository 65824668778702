(function() {
    'use strict';
    angular.module('iplanModule').directive('calender',['iPlanWarehousePlan',function (iPlanWarehousePlan) {
        return{
            restrict:'EA',
            templateUrl:'plan-module/calender/calender.html',
            replace:true,
            scope:{
                data:'=',
                show:'=',
                showday:'=',
                datedata:'='
            },
            link:function (scope) {
                var vm=this;
            //月份操作区域
                var date=new Date();
                var year=date.getFullYear();
                var month=date.getMonth();
                var day=date.getDate();
                var calenderWatch=scope.$watch('data',function (newVal,oldVal) {
                    if(newVal.length===0 || !newVal){

                    }else{
                        createDate(newVal);
                    }
                },true);


                scope.$watch('datedata.warehouse',function (n) {
                    createDate(scope.data);
                });


                function createDate(params) {
                    //判断闰年
                    function runNian(_year) {
                        if(_year%400 === 0 || (_year%4 === 0 && _year%100 !== 0) ) {
                            return true;
                        }
                        else { return false; }
                    }
                    //判断某年某月的1号是星期几
                    function getFirstDay(_year,_month) {
                        var allDay = 0, y = _year-1, i = 1;
                        allDay = y + Math.floor(y/4) - Math.floor(y/100) + Math.floor(y/400) + 1;
                        for ( ; i<_month; i++) {
                            switch (i) {
                                case 1: allDay += 31; break;
                                case 2:
                                    if(runNian(_year)) { allDay += 29; }
                                    else { allDay += 28; }
                                    break;
                                case 3: allDay += 31; break;
                                case 4: allDay += 30; break;
                                case 5: allDay += 31; break;
                                case 6: allDay += 30; break;
                                case 7: allDay += 31; break;
                                case 8: allDay += 31; break;
                                case 9: allDay += 30; break;
                                case 10: allDay += 31; break;
                                case 11: allDay += 30; break;
                                case 12: allDay += 31; break;
                            }
                        }
                        return allDay%7;
                    }

                    //获取一个月有多少天
                    function getDayNum(month,_year) {
                        var total;
                        switch (month) {
                            case 1: total=31; break;
                            case 2:
                                if(runNian(_year)) { total=29; }
                                else {  total=28; }
                                break;
                            case 3: total=31; break;
                            case 4: total=30; break;
                            case 5: total=31; break;
                            case 6: total=30; break;
                            case 7: total=31; break;
                            case 8: total=31; break;
                            case 9: total=30; break;
                            case 10: total=31; break;
                            case 11: total=30; break;
                            case 12: total=31; break;
                        }
                        return total;
                    }

                    scope.calendarData=[];

                    var monthDay=getDayNum(params.month,params.year);
                    // console.log(params,'params');
                    var firstDay=new Date(params.year+'-'+params.month+'-01').getDay();
                    if(firstDay==0){
                        //代表第一天是周日
                        for(var j=0;j<6;j++){
                            scope.calendarData.push({
                                date:null,
                                unLoad:null,
                                load:null,
                                notAppoint:true,
                                year:params.year,
                                month:params.month
                            });
                        }
                    }else{
                        for(var k=0;k<firstDay-1;k++){
                            scope.calendarData.push({
                                date:null,
                                unLoad:null,
                                load:null,
                                notAppoint:true,
                                year:params.year,
                                month:params.month
                            });
                        }
                    }

                    for(var i=1;i<=monthDay;i++){
                        scope.calendarData.push({
                            date: i,
                            unLoad:0,
                            load:0,
                            notAppoint:true,
                            year:params.year,
                            month:params.month
                        });
                    }
                    if(scope.data.someday && scope.data.someday.length>0){
                        scope.data.someday.forEach(function (v) {
                            var startMonth=new Date(v.planStartTime).getMonth();
                            var startDate=new Date(v.planStartTime).getDate();
                            var endMonth=new Date(v.planEndTime).getMonth();
                            var endDate=new Date(v.planEndTime).getDate();
                            if(startMonth<endMonth){
                                //就表明这个任务已经跨月了
                                if(startMonth+1<scope.data.month){
                                    //表示任务其实时间是在之前的月
                                    if(endMonth+1===scope.data.month){
                                        //表示结束时间是在这个月
                                        scope.calendarData.forEach(function (x) {
                                            if(x.date<=endDate){
                                                if(v.planType===20){
                                                    x.load++;
                                                }else if(v.planType===10){
                                                    x.unLoad++;
                                                }
                                                //如果该时间小于当前时间，表示是过去
                                                if(new Date(v.planStartTime)<new Date()){
                                                    //只要存在有不是6，7状态的，就把当天标记为过期未处理
                                                    if(v.status===6 || v.status===7){
                                                        x.hasHandled=true;
                                                    }else{
                                                        x.untreated=true;
                                                    }
                                                }else{
                                                    if(!x.untreated){
                                                        x.hasAppoint=true;
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    else{
                                        //表示结束时间在以后的月
                                        scope.calendarData.forEach(function (x) {
                                            if(v.planType===20){
                                                x.load++;
                                            }else if(v.planType===10){
                                                x.unLoad++;
                                            }
                                            //如果该时间小于当前时间，表示是过去
                                            if(new Date(v.planStartTime)<new Date()){
                                                //只要存在有不是6，7状态的，就把当天标记为过期未处理
                                                if(v.status===6 || v.status===7){
                                                    x.hasHandled=true;
                                                }else{
                                                    x.untreated=true;
                                                }
                                            }else{
                                                if(!x.untreated){
                                                    x.hasAppoint=true;
                                                }
                                            }
                                        });
                                    }
                                }else{
                                    //表示任务就是从当前选择的月开始的
                                    if(endMonth+1===scope.data.month){
                                        //表示结束时间是在这个月
                                        scope.calendarData.forEach(function (x) {
                                            if(x.date<=endDate && x.date>=startDate){
                                                if(v.planType===20){
                                                    x.load++;
                                                }else if(v.planType===10){
                                                    x.unLoad++;
                                                }
                                                //如果该时间小于当前时间，表示是过去
                                                if(new Date(v.planStartTime)<new Date()){
                                                    //只要存在有不是6，7状态的，就把当天标记为过期未处理
                                                    if(v.status===6 || v.status===7){
                                                        x.hasHandled=true;
                                                    }else{
                                                        x.untreated=true;
                                                    }
                                                }else{
                                                    if(!x.untreated){
                                                        x.hasAppoint=true;
                                                    }
                                                }
                                            }
                                        });
                                    }else{
                                        //表示结束时间在以后的月份去了
                                        scope.calendarData.forEach(function (x) {
                                            if(x.date>=startDate){
                                                if(v.planType===20){
                                                    x.load++;
                                                }else if(v.planType===10){
                                                    x.unLoad++;
                                                }
                                                //如果该时间小于当前时间，表示是过去
                                                if(new Date(v.planStartTime)<new Date()){
                                                    //只要存在有不是6，7状态的，就把当天标记为过期未处理
                                                    if(v.status===6 || v.status===7){
                                                        x.hasHandled=true;
                                                    }else{
                                                        x.untreated=true;
                                                    }
                                                }else{
                                                    if(!x.untreated){
                                                        x.hasAppoint=true;
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }

                            }
                            else if(startMonth===endMonth){
                                //表示任务没跨月，需要判定结束的date
                                scope.calendarData.forEach(function (x) {
                                    if(x.date>=startDate && x.date<=endDate){
                                        if(v.planType===20){
                                            x.load++;
                                        }else if(v.planType===10){
                                            x.unLoad++;
                                        }
                                        //如果该时间小于当前时间，表示是过去
                                        if(new Date(v.planStartTime)<new Date()){
                                            //只要存在有不是6，7状态的，就把当天标记为过期未处理
                                            if(v.status===6 || v.status===7){
                                                x.hasHandled=true;
                                            }else{
                                                x.untreated=true;
                                            }
                                        }else{
                                            if(!x.untreated){
                                                x.hasAppoint=true;
                                            }
                                        }
                                    }
                                });
                            }

                        });

                    }

                    scope.dayGroup=[];          //用于最终展示的天预约页面数据

                    var obj,startHour,endHour;

                    //如果存在天页面的数据时
                    if(scope.data.plans && scope.data.plans.length>0){
                        if(scope.datedata.warehouse.code==='NULL' && !scope.datedata.justShowPlatform){
                            //代表此时没有选择仓库，下面页面就要显示仓库列表
                            scope.data.plans.forEach(function (v) {
                                var exist=false;
                                scope.dayGroup.forEach(function (t) {
                                    if(v.warehouseCode===t.name){
                                        exist=true;
                                        if(new Date(v.planStartTime).getDate()===new Date(v.planEndTime).getDate()){
                                            startHour=new Date(v.planStartTime).getHours();
                                            endHour=new Date(v.planEndTime).getHours();

                                            t.details.forEach(function (m) {
                                                if(m.hour>=startHour && m.hour<=endHour) {
                                                    if(v.planType===20){
                                                        m.load++;
                                                    }else if(v.planType===10){
                                                        m.unload++;
                                                    }

                                                    if(new Date(v.planStartTime)>new Date()){
                                                        //如果预约开始时间大约当前时间
                                                        m.hasAppoint=true;
                                                    }else if(new Date(v.planEndTime)<new Date()){
                                                        //如果预约结束时间小于当前时间
                                                        if(v.status===6 || v.status===7){
                                                            m.hasHandled=true;
                                                        }else{
                                                            m.untreated=true;
                                                        }
                                                    }else{
                                                        if(m.hour>new Date().getHours()){
                                                            m.hasAppoint=true;

                                                        }else{
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        else {
                                            startHour=new Date(v.planStartTime).getHours();
                                            endHour=new Date(v.planEndTime).getHours();
                                            //手动创建一个今天的起始时间来作对比
                                            var todayYear=new Date(scope.datedata.date).getFullYear();
                                            var todayMonth=new Date(scope.datedata.date).getMonth()+1;
                                            var todayDate=new Date(scope.datedata.date).getDate();
                                            var timeStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 00:00:00';
                                            var endStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 23:59:59';
                                            var todayStartTime=new Date(timeStamp);
                                            var todayEndTime=new Date(endStamp);

                                            if(new Date(v.planStartTime)<todayStartTime){
                                                if(new Date(v.planEndTime)>todayEndTime){
                                                    //表示这条数据已经贯穿了整个今天24小时
                                                    obj.details.forEach(function (m) {
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                                else{
                                                    //表示这条任务是在今天之内结束的

                                                    obj.details.forEach(function (m) {

                                                        if(m.hour<=endHour){
                                                            if(v.planType===20){
                                                                m.load++;
                                                            }else if(v.planType===10){
                                                                m.unload++;
                                                            }
                                                            if(new Date(v.planStartTime)>new Date()){
                                                                //如果预约开始时间大约当前时间
                                                                m.hasAppoint=true;
                                                            }else if(new Date(v.planEndTime)<new Date()){
                                                                //如果预约结束时间小于当前时间
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }else{
                                                                if(m.hour>new Date().getHours()){
                                                                    m.hasAppoint=true;
                                                                }else{
                                                                    if(v.status===6 || v.status===7){
                                                                        m.hasHandled=true;
                                                                    }else{
                                                                        m.untreated=true;
                                                                    }
                                                                }
                                                            }
                                                        }


                                                    });


                                                }

                                            }
                                            else{
                                                //起始时间就在今天
                                                if(new Date(v.planEndTime)>todayEndTime){
                                                    obj.details.forEach(function (m) {
                                                        if(m.hour>=startHour){
                                                            if(v.planType===20){
                                                                m.load++;
                                                            }else if(v.planType===10){
                                                                m.unload++;
                                                            }

                                                            if(new Date(v.planStartTime)>new Date()){
                                                                //如果预约开始时间大约当前时间
                                                                m.hasAppoint=true;
                                                            }else if(new Date(v.planEndTime)<new Date()){
                                                                //如果预约结束时间小于当前时间
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }else{
                                                                if(m.hour>new Date().getHours()){
                                                                    m.hasAppoint=true;
                                                                }else{
                                                                    if(v.status===6 || v.status===7){
                                                                        m.hasHandled=true;
                                                                    }else{
                                                                        m.untreated=true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }else{
                                                    obj.details.forEach(function (m) {
                                                        if(m.hour>=startHour && m.hour<=endHour){
                                                            if(v.planType===20){
                                                                m.load++;
                                                            }else if(v.planType===10){
                                                                m.unload++;
                                                            }

                                                            if(new Date(v.planStartTime)>new Date()){
                                                                //如果预约开始时间大约当前时间
                                                                m.hasAppoint=true;
                                                            }else if(new Date(v.planEndTime)<new Date()){
                                                                //如果预约结束时间小于当前时间
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }else{
                                                                if(m.hour>new Date().getHours()){
                                                                    m.hasAppoint=true;
                                                                }else{
                                                                    if(v.status===6 || v.status===7){
                                                                        m.hasHandled=true;
                                                                    }else{
                                                                        m.untreated=true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                if(!exist){
                                    obj={
                                        name:v.warehouseCode,
                                        details:[
                                            {
                                                hour:'00',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'01',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'02',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'03',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'04',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'05',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'06',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'07',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'08',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'09',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'10',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'11',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'12',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'13',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'14',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'15',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'16',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'17',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'18',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'19',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'20',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'21',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'22',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'23',
                                                load:0,
                                                unload:0
                                            },
                                        ]
                                    };

                                    if(new Date(v.planStartTime).getDate()===new Date(v.planEndTime).getDate()){
                                        startHour=new Date(v.planStartTime).getHours();
                                        endHour=new Date(v.planEndTime).getHours();
                                        obj.details.forEach(function (m) {
                                            if(m.hour>=startHour && m.hour<=endHour) {

                                                if(v.planType===20){
                                                    m.load++;
                                                }else if(v.planType===10){
                                                    m.unload++;
                                                }

                                                if(new Date(v.planStartTime)>new Date()){
                                                    //如果预约开始时间大约当前时间
                                                    m.hasAppoint=true;
                                                }else if(new Date(v.planEndTime)<new Date()){
                                                    //如果预约结束时间小于当前时间
                                                    if(v.status===6 || v.status===7){
                                                        m.hasHandled=true;
                                                    }else{
                                                        m.untreated=true;
                                                    }
                                                }else{
                                                    if(m.hour>new Date().getHours()){
                                                        m.hasAppoint=true;
                                                    }else{
                                                        if(v.status===6 || v.status===7){
                                                            m.hasHandled=true;
                                                        }else{
                                                            m.untreated=true;
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    else{
                                        startHour=new Date(v.planStartTime).getHours();
                                        endHour=new Date(v.planEndTime).getHours();
                                        //手动创建一个今天的起始时间来作对比
                                        var todayYear=new Date(scope.datedata.date).getFullYear();
                                        var todayMonth=new Date(scope.datedata.date).getMonth()+1;
                                        var todayDate=new Date(scope.datedata.date).getDate();
                                        var timeStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 00:00:00';
                                        var endStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 23:59:59';
                                        var todayStartTime=new Date(timeStamp);
                                        var todayEndTime=new Date(endStamp);

                                        if(new Date(v.planStartTime)<todayStartTime){
                                            if(new Date(v.planEndTime)>todayEndTime){
                                                //表示这条数据已经贯穿了整个今天24小时
                                                obj.details.forEach(function (m) {
                                                    if(v.planType===20){
                                                        m.load++;
                                                    }else if(v.planType===10){
                                                        m.unload++;
                                                    }

                                                    if(new Date(v.planStartTime)>new Date()){
                                                        //如果预约开始时间大约当前时间
                                                        m.hasAppoint=true;
                                                    }else if(new Date(v.planEndTime)<new Date()){
                                                        //如果预约结束时间小于当前时间
                                                        if(v.status===6 || v.status===7){
                                                            m.hasHandled=true;
                                                        }else{
                                                            m.untreated=true;
                                                        }
                                                    }else{
                                                        if(m.hour>new Date().getHours()){
                                                            m.hasAppoint=true;
                                                        }else{
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                            else{
                                                //表示这条任务是在今天之内结束的

                                                obj.details.forEach(function (m) {

                                                    if(m.hour<=endHour){
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }
                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    }


                                                });


                                            }
                                        }
                                        else{
                                            //起始时间就在今天

                                            if(new Date(v.planEndTime)>todayEndTime){
                                                obj.details.forEach(function (m) {
                                                    if(m.hour>=startHour){
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }else{
                                                obj.details.forEach(function (m) {
                                                    if(m.hour>=startHour && m.hour<=endHour){
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }

                                    }
                                    scope.dayGroup.push(obj);

                                }

                            });
                        }
                        else{
                            //代表此时选择了仓库，下面就要显示月台数据
                            scope.data.plans.forEach(function (v) {
                                var exist=false;
                                scope.dayGroup.forEach(function (t) {
                                    if(v.platformName===t.name){
                                        exist=true;
                                        if(new Date(v.planStartTime).getDate()===new Date(v.planEndTime).getDate()){
                                            startHour=new Date(v.planStartTime).getHours();
                                            endHour=new Date(v.planEndTime).getHours();
                                            t.details.forEach(function (m) {
                                                if(m.hour>=startHour && m.hour<=endHour) {
                                                    if(v.planType===20){
                                                        m.load++;
                                                    }else if(v.planType===10){
                                                        m.unload++;
                                                    }

                                                    if(new Date(v.planStartTime)>new Date()){
                                                        //如果预约开始时间大约当前时间
                                                        m.hasAppoint=true;
                                                    }else if(new Date(v.planEndTime)<new Date()){
                                                        //如果预约结束时间小于当前时间
                                                        if(v.status===6 || v.status===7){
                                                            m.hasHandled=true;
                                                        }else{
                                                            m.untreated=true;
                                                        }
                                                    }else{
                                                        if(m.hour>new Date().getHours()){
                                                            m.hasAppoint=true;
                                                        }else{
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }
                                        else{

                                            startHour=new Date(v.planStartTime).getHours();
                                            endHour=new Date(v.planEndTime).getHours();

                                            //手动创建一个今天的起始时间来作对比
                                            var todayYear=new Date(scope.datedata.date).getFullYear();
                                            var todayMonth=new Date(scope.datedata.date).getMonth()+1;
                                            var todayDate=new Date(scope.datedata.date).getDate();
                                            var timeStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 00:00:00';
                                            var endStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 23:59:59';
                                            var todayStartTime=new Date(timeStamp);
                                            var todayEndTime=new Date(endStamp);

                                            if(new Date(v.planStartTime)<todayStartTime){
                                                if(new Date(v.planEndTime)>todayEndTime){
                                                    //起始时间比今天早，结束时间比今天晚，代表今天24小时都被占满了
                                                    t.details.forEach(function (m) {
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                                else{
                                                    //表示这条任务是在今天之内结束的
                                                    t.details.forEach(function (m) {
                                                        if(m.hour<=endHour) {
                                                            console.log(m)
                                                            if(v.planType===20){
                                                                m.load++;
                                                            }else if(v.planType===10){
                                                                m.unload++;
                                                            }

                                                            if(new Date(v.planStartTime)>new Date()){
                                                                //如果预约开始时间大约当前时间
                                                                m.hasAppoint=true;
                                                            }else if(new Date(v.planEndTime)<new Date()){
                                                                //如果预约结束时间小于当前时间
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }else{
                                                                if(m.hour>new Date().getHours()){
                                                                    m.hasAppoint=true;
                                                                }else{
                                                                    if(v.status===6 || v.status===7){
                                                                        m.hasHandled=true;
                                                                    }else{
                                                                        m.untreated=true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }else{
                                                //表示起始时间就在今天
                                                if(new Date(v.planEndTime)>todayEndTime){
                                                    t.details.forEach(function (m) {
                                                        if(m.hour>=startHour) {
                                                            if(v.planType===20){
                                                                m.load++;
                                                            }else if(v.planType===10){
                                                                m.unload++;
                                                            }

                                                            if(new Date(v.planStartTime)>new Date()){
                                                                //如果预约开始时间大约当前时间
                                                                m.hasAppoint=true;
                                                            }else if(new Date(v.planEndTime)<new Date()){
                                                                //如果预约结束时间小于当前时间
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }else{
                                                                if(m.hour>new Date().getHours()){
                                                                    m.hasAppoint=true;
                                                                }else{
                                                                    if(v.status===6 || v.status===7){
                                                                        m.hasHandled=true;
                                                                    }else{
                                                                        m.untreated=true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                                else{
                                                    t.details.forEach(function (m) {
                                                        if(m.hour<=endHour && m.hour>=startHour) {
                                                            if(v.planType===20){
                                                                m.load++;
                                                            }else if(v.planType===10){
                                                                m.unload++;
                                                            }

                                                            if(new Date(v.planStartTime)>new Date()){
                                                                //如果预约开始时间大约当前时间
                                                                m.hasAppoint=true;
                                                            }else if(new Date(v.planEndTime)<new Date()){
                                                                //如果预约结束时间小于当前时间
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }else{
                                                                if(m.hour>new Date().getHours()){
                                                                    m.hasAppoint=true;
                                                                }else{
                                                                    if(v.status===6 || v.status===7){
                                                                        m.hasHandled=true;
                                                                    }else{
                                                                        m.untreated=true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    }
                                });
                                if(!exist){
                                    obj={
                                        name:v.platformName,
                                        details:[
                                            {
                                                hour:'00',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'01',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'02',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'03',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'04',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'05',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'06',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'07',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'08',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'09',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'10',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'11',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'12',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'13',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'14',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'15',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'16',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'17',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'18',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'19',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'20',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'21',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'22',
                                                load:0,
                                                unload:0
                                            },
                                            {
                                                hour:'23',
                                                load:0,
                                                unload:0
                                            },
                                        ]
                                    };
                                    if(new Date(v.planStartTime).getDate()===new Date(v.planEndTime).getDate()){
                                        startHour=new Date(v.planStartTime).getHours();
                                        endHour=new Date(v.planEndTime).getHours();
                                        obj.details.forEach(function (m) {
                                            if(m.hour>=startHour && m.hour<=endHour) {
                                                if(v.planType===20){
                                                    m.load++;
                                                }else if(v.planType===10){
                                                    m.unload++;
                                                }

                                                //有两种判断

                                                if(new Date(v.planStartTime)>new Date()){
                                                    //如果预约开始时间大约当前时间
                                                    m.hasAppoint=true;
                                                }else if(new Date(v.planEndTime)<new Date()){
                                                    //如果预约结束时间小于当前时间
                                                    if(v.status===6 || v.status===7){
                                                        m.hasHandled=true;
                                                    }else{
                                                        m.untreated=true;
                                                    }
                                                }else{
                                                    if(m.hour>new Date().getHours()){
                                                        m.hasAppoint=true;
                                                    }else{
                                                        if(v.status===6 || v.status===7){
                                                            m.hasHandled=true;
                                                        }else{
                                                            m.untreated=true;
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }
                                    else{
                                        startHour=new Date(v.planStartTime).getHours();
                                        endHour=new Date(v.planEndTime).getHours();

                                        //手动创建一个今天的起始时间来作对比
                                        var todayYear=new Date(scope.datedata.date).getFullYear();
                                        var todayMonth=new Date(scope.datedata.date).getMonth()+1;
                                        var todayDate=new Date(scope.datedata.date).getDate();
                                        var timeStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 00:00:00';
                                        var endStamp=todayYear+'-'+todayMonth+'-'+todayDate+' 23:59:59';
                                        var todayStartTime=new Date(timeStamp);
                                        var todayEndTime=new Date(endStamp);

                                        if(new Date(v.planStartTime)<todayStartTime){
                                            if(new Date(v.planEndTime)>todayEndTime){
                                                //起始时间比今天早，结束时间比今天晚，代表今天24小时都被占满了
                                                obj.details.forEach(function (m) {
                                                    if(v.planType===20){
                                                        m.load++;
                                                    }else if(v.planType===10){
                                                        m.unload++;
                                                    }

                                                    if(new Date(v.planStartTime)>new Date()){
                                                        //如果预约开始时间大约当前时间
                                                        m.hasAppoint=true;
                                                    }else if(new Date(v.planEndTime)<new Date()){
                                                        //如果预约结束时间小于当前时间
                                                        if(v.status===6 || v.status===7){
                                                            m.hasHandled=true;
                                                        }else{
                                                            m.untreated=true;
                                                        }
                                                    }else{
                                                        if(m.hour>new Date().getHours()){
                                                            m.hasAppoint=true;
                                                        }else{
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                            else{
                                                //表示这条任务是在今天之内结束的
                                                obj.details.forEach(function (m) {
                                                    if(m.hour<=endHour) {
                                                        console.log(m)
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }else{
                                            //表示起始时间就在今天
                                            if(new Date(v.planEndTime)>todayEndTime){
                                                obj.details.forEach(function (m) {
                                                    if(m.hour>=startHour) {
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                            else{
                                                obj.details.forEach(function (m) {
                                                    if(m.hour<=endHour && m.hour>=startHour) {
                                                        if(v.planType===20){
                                                            m.load++;
                                                        }else if(v.planType===10){
                                                            m.unload++;
                                                        }

                                                        if(new Date(v.planStartTime)>new Date()){
                                                            //如果预约开始时间大约当前时间
                                                            m.hasAppoint=true;
                                                        }else if(new Date(v.planEndTime)<new Date()){
                                                            //如果预约结束时间小于当前时间
                                                            if(v.status===6 || v.status===7){
                                                                m.hasHandled=true;
                                                            }else{
                                                                m.untreated=true;
                                                            }
                                                        }else{
                                                            if(m.hour>new Date().getHours()){
                                                                m.hasAppoint=true;
                                                            }else{
                                                                if(v.status===6 || v.status===7){
                                                                    m.hasHandled=true;
                                                                }else{
                                                                    m.untreated=true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                    }

                                    scope.dayGroup.push(obj);
                                }
                            });
                        }
                    }

                    if(year===params.year && month===params.month-1){
                        scope.calendarData.forEach(function (v) {
                            if(v.year===year && v.month===month+1 && v.date===day){
                                v.isToday=true;
                            }
                        })
                    }
                }

                scope.checkDay=function (e) {
                    if(!e.date){
                        return;
                    }
                    scope.data.monthChangeTime=true;          //改成是按月双击改变时间的

                    scope.show=false;
                    scope.showWarehouse=true;

                    scope.data.day=e.date;
                    //双击选中时会造成date的改变，而date改变又会引起重新请求，这里不想让它再请求所以禁止
                    // scope.data.allowDateChangeQuery=false;

                    var speMonth,speDay;
                    if(e.month<10){
                        speMonth='0'+e.month;
                    }else{
                        speMonth=e.month;
                    }
                    if(e.date<10){
                        speDay='0'+e.date;
                    }else{
                        speDay=e.date;
                    }

                    scope.datedata.date=e.year+'-'+speMonth+'-'+speDay;
                    scope.datedata.park=scope.data.park;
                    // scope.datedata.dayWarehouse=scope.data.warehouse;

                };

            //天数操作区域
                //计算小时数
                scope.headHours=[];
                for(var i=0;i<24;i++){
                    if(i<10){
                        scope.headHours.push('0'+i);
                    }else{
                        scope.headHours.push(i);
                    }
                }

                scope.hours=[];
                scope.hours.push({
                    hour:'小时:'
                });
                for(var i=0;i<=23;i++){
                    if(i<10){
                        i='0'+i;
                    }
                    scope.hours.push({
                        hour:i
                    });
                }
                scope.timeZone=[1,2,3,4,5,6,7];
                //orderType(预约类型):1是卸货，2是装货;
                //status(预约状态):
                scope.warehouseData=[
                    {
                        warehouseName:'仓库1',
                        parkName:'园区1',
                        orderType:'1',
                        status:'已预约'
                    },
                    {
                        warehouseName:'仓库2',
                        parkName:'园区2',
                        orderType:'1',
                        status:'已检入'
                    },
                    {
                        warehouseName:'仓库3',
                        parkName:'园区3',
                        orderType:'2',
                        status:'已停泊'
                    }
                ];
//监听仓库是否变化，仓库的值不为空或者全部了，就显示月台的数据而不是仓库的数据
                var warehouseWatch=scope.$watch('data.warehouse',function (n,o) {
                    if(n==='' || n==='全部'){
                        //就显示仓库数据
                        scope.showWarehouse=true;
                    }else{
                        //就显示月台数据
                        scope.showWarehouse=false;
                    }
                    if(scope.showday){
                        scope.showWarehouse=true;
                    }
                },true);

                //全局操作区域
                scope.$on('$destroy',function () {
                    calenderWatch();
                    warehouseWatch();
                })
            }
        }
    }]);

})();
