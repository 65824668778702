(function() {
    "use strict";
    angular.module("itms-dataModule").factory("TmsProductType", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsDataHost + "/web/productType", {}, {
        	'list': { url: iServiceConfig.itmsDataHost + "/web/productType/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsDataHost + "/web/productType/saveAndUpdate", method: 'POST'},
            'show': {url: iServiceConfig.itmsDataHost + "/web/productType/show", method: 'POST'},
            'delete': {url: iServiceConfig.itmsDataHost + "/web/productType/delete", method: 'POST'},
            'getCarrierCodes': {url: iServiceConfig.itmsDataHost + "/web/productType/getCarrierCodes", method: 'POST'},
            'validateCode': {url: iServiceConfig.itmsDataHost + "/web/productType/validateCode", method: 'POST'},
            'codes': {url: iServiceConfig.itmsDataHost + "/web/productType/codes", method: 'GET'},
            'enable': {url: iServiceConfig.itmsDataHost + "/web/productType/enable", method: 'POST'}
        });
    }]);
})();