(function () {
    'use strict';

    angular.module('iwh-innerModule').factory('CountDifference',CountDifference);

    CountDifference.$inject = ['$resource', 'iServiceConfig'];

    function CountDifference($resource, iServiceConfig) {
        var service = $resource('web/countDifference', {}, {
            'list': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/list", method: 'POST'},
            'handle': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/handle", method: 'POST'},
            'findCountDifferenceDetailPage': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/findCountDifferenceDetailPage", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/cancel", method: "POST"},
            'handleBySku': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/handleBySku", method: "POST"},
            'handleByLot': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/handleByLot", method: "POST"},
            'handleByGenAdjust': {url: iServiceConfig.iwhInnerHost + "/web/countDifference/handleByGenAdjust", method: "POST"}
            }
        );
        return service;
    }
})();