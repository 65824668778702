(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusProject', iBusProject);

    iBusProject.$inject = ['$resource', 'iServiceConfig'];

    function iBusProject ($resource, iServiceConfig) {
        var service = $resource('web/proj', {}, {
            'search': { url: iServiceConfig.ibusHost + "/web/proj/list", method: 'POST'},
            'get': { url: iServiceConfig.ibusHost + "/web/proj/:id", method: 'GET'},
            'add': { url: iServiceConfig.ibusHost + "/web/proj/save", method: 'POST'},
            'update': { url: iServiceConfig.ibusHost + "/web/proj/save", method: 'POST'},
            'delete': { url: iServiceConfig.ibusHost + "/web/proj/delete/:id", method: 'POST'}
        });

        return service;
    }
})();
