(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsProductTypeController', TmsProductTypeController);

    TmsProductTypeController.$inject = ['$rootScope','TmsProductType', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsProductTypeController ($rootScope, ProductType, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];
        vm.type = [
            {id : "A", title : "A"},
            {id : "B", title : "B"},
            {id : "C", title : "C"}
        ];

        function pageTitle() {
            return $window.document.title;
        }
        vm.initCodes = initCodes;
        vm.codeMap = {};
        vm.compressTypes = [];
        vm.compressTypesMap = {};
        vm.storageTypes = [];
        vm.storageTypesMap = {};
        vm.dangerGrades = [];
        vm.dangerGradesMap = {};
        vm.lightTypes = [];
        vm.lightTypesMap = {};
        vm.materielTypes = [];
        vm.materielTypesMap = {};
        vm.waterproofTypes = [];
        vm.waterproofTypesMap = {};
        
        function initCodes() {
        	vm.codeMap = new Promise(function(resolve, reject) {
	        		ProductType.codes({}, {}, function(resp) {
	                    resolve(resp.content);
	                });
        		});
        	
        	vm.compressTypes = new Promise(function(resolve, reject) {
        		vm.codeMap.then(function(data) {
        			angular.forEach(data.compressTypes, function(item){
        				item.id = item.code;
        				item.title = item.name;
        				vm.compressTypesMap[''+item.code] = item.name;
        			});
        			resolve(data.compressTypes);
        		});
    		});
        	
        	vm.storageTypes = new Promise(function(resolve, reject) {
        		vm.codeMap.then(function(data) {
        			angular.forEach(data.storageTypes, function(item){
        				item.id = item.code;
        				item.title = item.name;
        				vm.storageTypesMap[''+item.code] = item.name;
        			});
        			resolve(data.storageTypes);
        		});
    		});
        	vm.dangerGrades = new Promise(function(resolve, reject) {
        		vm.codeMap.then(function(data) {
        			angular.forEach(data.dangerGrades, function(item){
        				item.id = item.code;
        				item.title = item.name;
        				vm.dangerGradesMap[''+item.code] = item.name;
        			});
        			resolve(data.dangerGrades);
        		});
    		});
        	vm.lightTypes = new Promise(function(resolve, reject) {
        		vm.codeMap.then(function(data) {
        			angular.forEach(data.lightTypes, function(item){
        				item.id = item.code;
        				item.title = item.name;
        				vm.lightTypesMap[''+item.code] = item.name;
        			});
        			resolve(data.lightTypes);
        		});
    		});
        	vm.materielTypes = new Promise(function(resolve, reject) {
        		vm.codeMap.then(function(data) {
        			angular.forEach(data.materielTypes, function(item){
        				item.id = item.code;
        				item.title = item.name;
        				vm.materielTypesMap[''+item.code] = item.name;
        			});
        			resolve(data.materielTypes);
        		});
    		});
        	vm.waterproofTypes = new Promise(function(resolve, reject) {
        		vm.codeMap.then(function(data) {
        			angular.forEach(data.waterproofTypes, function(item){
        				item.id = item.code;
        				item.title = item.name;
        				vm.waterproofTypesMap[''+item.code] = item.name;
        			});
        			resolve(data.waterproofTypes);
        		});
    		});
        }
        vm.initCodes();
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
                if($state.params.carrierCode!=null){
                    params.filter().carrierCode = $state.params.carrierCode;
                    $state.params.carrierCode=null;
                }
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());

				return ProductType.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.validate = validate;
        function validate(code,carrierCode){
            if(code==null ||code==""){
                return
            }else{
                var vo = {};
                vo.code = code;
                vo.carrierCode = carrierCode;
                ProductType.validateCode({}, vo, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }

		vm.createProductType = createProductType;
		function createProductType() {

                var entity = {
                    heading : '创建',
                    contentId : "createProductType.html",
                    active : true,
                    data: {
                        code : "",
                        name : "",
                        status : 0,
                    },
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
		}

		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
            if(t.data.waterproofType==null||t.data.waterproofType==""){
                toastr.error("请选择防水类型！");
                return;
            }else if(t.data.lightType==null||t.data.lightType==""){
                toastr.error("请选择光照类型！");
                return;
            }else if(t.data.dangerGrade==null||t.data.dangerGrade==""){
                toastr.error("请选择危险等级！");
                return;
            }else if(t.data.compressType==null||t.data.compressType==""){
                toastr.error("请选择抗压类型！");
                return;
            }else if(t.data.materielType==null||t.data.materielType==""){
                toastr.error("请选择材料类型！");
                return;
            }else if(t.data.storageType==null||t.data.storageType==""){
                toastr.error("请选择存储类型！");
                return;
            }else if(t.data.status==null){
                console.log(t.data.status);
                toastr.error("请选择状态！");
                return;
            }
			 var saveVO = t.data;
			 if(t.data.id){
                 ProductType.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 ProductType.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}

		vm.show = show;
		function show(row) {
            ProductType.show({}, row.id, function(resp) {
				var Carrier = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showProductType.html",
						active : true,
						data : Carrier,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

		vm.edite = edite;
		function edite(row) {
            ProductType.show({}, row.id, function(resp) {
				var ProductType = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createProductType.html",
						active : true,
						data : ProductType,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}


        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            ProductType.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }

		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                ProductType.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }

		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }

		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
