(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('BoxBiz', BoxBiz);

    BoxBiz.$inject = ['$resource', 'iServiceConfig'];

    function BoxBiz ($resource, iServiceConfig) {
        var service = $resource('web/box', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/box/list", method: 'POST'},
            'getBoxDetail': { url: iServiceConfig.iwhShipHost + "/web/box/getBoxDetail", method: 'POST'},
            'closeBox': { url: iServiceConfig.iwhShipHost + "/web/box/closeBox", method: 'POST'},
            'openBox': { url: iServiceConfig.iwhShipHost + "/web/box/openBox", method: 'POST'},
            'splitBox': { url: iServiceConfig.iwhShipHost + "/web/box/splitBox", method: 'POST'},
            'packSku': { url: iServiceConfig.iwhShipHost + "/web/box/packSku", method: 'POST'},
            'getPackBoxByNum': { url: iServiceConfig.iwhShipHost + "/web/box/getPackBoxByNum", method: 'GET'},
            'getBoxAndDetail': { url: iServiceConfig.iwhShipHost + "/web/box/getBoxAndDetail", method: 'POST'},
            'updateBoxWeight': { url: iServiceConfig.iwhShipHost + "/web/box/updateBoxWeight", method: 'POST'},
            'getBoxsBySoNum': {url: iServiceConfig.iwhShipHost + "/web/box/getBoxsBySoNum/:soNum", params: {name:'@soNum'}, method: 'POST'},
            'packAndCloseBox': {url: iServiceConfig.iwhShipHost + "/web/box/packAndCloseBox", method: 'POST'}
        });
        return service;
    }
})();
