(function () {
    'use strict';
    angular.module('idashboardModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('3dZone', {
                parent: 'main',
                url: 'dashboard/3dZone?whCode&whName',
                params:{
                    whCode:null,
                    whName:null
                },
                data: {
                    'pageTitle': '3D仓库——库区页面'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'dashboard-module/3dInventory/3dZone.html',
                        controller: 'ThreeDZoneController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();