(function () {
    'use strict';

    angular.module('idashboardModule')
        .factory('WorkloadStat', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/workload', {}, {
                'list': {
                    url: iServiceConfig.idashboardHost + '/web/workload/list',
                    method: 'POST'
                }
            });
        }]);
})();