(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('PickConfirmController', PickConfirmController);

    PickConfirmController.$inject = ['$rootScope', '$stateParams', '$timeout', 'NgTableParams', 'PickerOrderBiz', 'toastr', '$filter'];

    function PickConfirmController ($rootScope, $stateParams, $timeout, NgTableParams, PickerOrderBiz, toastr, $filter) {
        var vm = this;
        vm.confirmStatus = {status: 'SUCCESS', message: '成功'}; // SUCCESS|ERROR
        vm.params = {};
        vm.pickOrder = {};

        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'},
            off: {'background-color': '#868e96', 'color': '#fff'}
        };
        vm.pickDetail = {};
        vm.pickByOrder = pickByOrder;  //使用填入的数量执行拣选
        vm.pickAllByOrder = pickAllByOrder;  // 整单拣货
        
        vm.getPickOrder = getPickOrder;  // 获取拣货单
        vm.inputEmployeeCode = inputEmployeeCode; //输入员工号
        vm.styleFn = styleFn; //明细样式
        vm.selectRow = selectRow; //选择一行
        vm.unSelectRow = unSelectRow; //不选择一行
        vm.success = success;
        vm.fail = fail;

        vm.initLoadFromParam = function() {
            if($stateParams.soNum) {
                vm.params.pickNum = $stateParams.soNum;
                vm.getPickOrder({key:'Enter'}, vm.params.pickNum)
            } else if($stateParams.pickNum) {
                vm.params.pickNum = $stateParams.pickNum;
                vm.getPickOrder({key:'Enter'}, vm.params.pickNum)
            }
        }
        function inputEmployeeCode($event, pickerCode) {
            if (pickerCode && $event.key === 'Enter') {
                jQuery('#pickNum').select();
            }
        }

        function getPickOrder($event, pickNum) {
            vm.pickOrder = {};
            if(pickNum && $event.key === 'Enter') {
            	vm.success();
                PickerOrderBiz.getByPickNum({pickNum: pickNum}, {}, function(data) {
                    if (data.content) {
                        // data.content.pickDetails = $filter('orderBy')(data.content.pickDetails, function(data) {
                        //     return {
                        //         'boxNum': data.boxNum,
                        //         'skuCode': data.skuCode,
                        //         'qty': data.pickQty
                        //     };
                        // }, false, function(obj1, obj2) {
                        //     var boxNum1 = obj1.value.boxNum;
                        //     var skuCode1 = obj1.value.skuCode;
                        //     var qty1 = obj1.value.qty;
                        //     var boxNum2 = obj2.value.boxNum;
                        //     var skuCode2 = obj2.value.skuCode;
                        //     var qty2 = obj2.value.qty;

                        //     if (qty1 === 0 || qty2 === 0) {
                        //         if (qty1 === 0) {
                        //             return -1;
                        //         } else {
                        //             return 1;
                        //         }
                        //     }

                        //     if (boxNum1 !== boxNum2) {
                        //         return boxNum1 > boxNum2 ? 1: -1;
                        //     }

                        //     if (skuCode1 !== skuCode2) {
                        //         return skuCode1 > skuCode2 ? 1: -1;
                        //     }

                        //     if (obj1.index < obj2.index) {
                        //         return -1;
                        //     } else {
                        //         return 1;
                        //     }
                        // });

                        angular.forEach(data.content.pickDetails, function(item) {
                            if (item.pickQty > 0) {
                                item.picked = true;
                            }
                        });

                        vm.pickOrder = data.content;
                        success();
                    } else {
                        fail('不存在拣选单'+pickNum);
                    }
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        fail(resp.data.errorMsg);
                    }
                });
            }
        }

        function styleFn(item) {
            if (item.boxNum === vm.pickDetail.boxNum
                && item.skuCode === vm.pickDetail.skuCode
                && item.loc === vm.pickDetail.loc
                && item.picked === vm.pickDetail.picked) {
                return vm.alertColor.current;
            }

            if (item.picked) {
                return vm.alertColor.done;
            }

            return {};
        }

        function selectRow(row) {
            vm.pickDetail = row;
        }

        function unSelectRow(row) {
            if (row.boxNum === vm.pickDetail.boxNum
                && row.skuCode === vm.pickDetail.skuCode
                && row.loc === vm.pickDetail.loc
                && row.picked === vm.pickDetail.picked) {
                vm.pickDetail = {};
            }
        }
        function pickAllByOrder(pickOrder, pickerCode) {
            if (pickOrder) {
                var param = {
                    pickNum: pickOrder.pickNum,
                    pickerCode: pickerCode,
                    pickDetails: []
                };
                angular.forEach(pickOrder.pickDetails, function(detail) {
                    param.pickDetails.push({
                        boxNum: detail.boxNum,
                        soNum: detail.soNum,
                        ownerCode: detail.ownerCode,
                        skuCode: detail.skuCode,
                        qty: detail.qty,
                        pickQty: detail.qty,
                        loc: detail.loc,
                        lpn: detail.lpn,
                        pickNum: detail.pickNum
                    });
                });
                vm.success();
                PickerOrderBiz.pickByOrder({}, param, function(data) {
                    success();
                    toastr.success('拣选确认成功');

                    vm.params = {};
                    vm.pickOrder = {};
                    jQuery('#pickerCode').select();
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        fail(resp.data.errorMsg);
                    }
                });
            } else {
                toastr.error('请扫描拣选单号','警告');
                fail('请扫描拣选单号');
            }
        }
        function pickByOrder(pickOrder, pickerCode) {
            if (pickOrder) {
                var param = {
                    pickNum: pickOrder.pickNum,
                    pickerCode: pickerCode,
                    pickDetails: []
                };
                angular.forEach(pickOrder.pickDetails, function(detail) {
                    if (detail.pickingQty) {
                        param.pickDetails.push({
                            boxNum: detail.boxNum,
                            soNum: detail.soNum,
                            ownerCode: detail.ownerCode,
                            skuCode: detail.skuCode,
                            qty: detail.qty,
                            pickQty: detail.pickingQty,
                            loc: detail.loc,
                            lpn: detail.lpn,
                            pickNum: detail.pickNum
                        });
                    }
                });
                vm.success();
                PickerOrderBiz.pickByOrder({}, param, function(data) {
                    success();
                    toastr.success('拣选确认成功');

                    vm.params = {};
                    vm.pickOrder = {};
                    jQuery('#pickerCode').select();
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        fail(resp.data.errorMsg);
                    }
                });
            } else {
                toastr.error('请扫描拣选单号','警告');
                fail('请扫描拣选单号');
            }
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: msg};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '成功'};
        }
        
        vm.success();
        vm.initLoadFromParam();
    }
})();
