(function () {
    'use strict';
    var app = angular.module('iwh-dataModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('iwh-owner-maintenance', {
            parent: 'main',
           url: 'iwh-data/owner-maintenance',
            params: {
                'ownerCode': null,
                'postParams': null
            },
            data: {
                'pageTitle': '货主维护',
                'ownerCode' : null,
                'postParams': null,
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-data-module/owner/owner.html',
                    controller: 'IwhOwnerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

