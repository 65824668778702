(function () {
    'use strict';

    angular.module('doveModule')
        .controller('iBusBizLogController', iBusBizLogController);

    iBusBizLogController.$inject = ['iBusBizLog', 'NgTableParams', '$timeout', 'toastr'];

    function iBusBizLogController(iBusBizLog, NgTableParams, $timeout, toastr) {
        //-----------------------------------------------------------------------//
        //----------------START--------------------------------------------------//
        //-----------------------------------------------------------------------//
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.logLevel = [
            {'id': "DEBUG", 'title': "DEBUG"},
            {'id': "INFO", 'title': "INFO"},
            {'id': "WARN", 'title': "WARN"},
            {'id': "ERROR", 'title': "ERROR"}];
        vm.search = search;
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusBizLog.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }

        //-----------------------------------------------------------------------//
        //----------------END----------------------------------------------------//
        //-----------------------------------------------------------------------//
    }
})();
