(function()  {
    'use strict';

    angular.module('itms-orderModule')
        .controller('TmsCustomerOrderController', TmsCustomerOrderController);

    TmsCustomerOrderController.$inject = ['$rootScope','$stateParams', 'TmsCustomerOrder', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout', 'iServiceConfig'];

    function TmsCustomerOrderController ($rootScope,$stateParams, CustomerOrder, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout, iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.iServiceConfig = iServiceConfig;

        vm.modalInstance = null;
        vm.orderData = "orderData.html";
        vm.orderProducts = "orderProducts.html";
        vm.productType = {
            code : null,
            name : null
        };

        vm.orderSource = [
                     {id : 1, title : "手工创建"},
                     {id : 2, title : "上游ERP"},
                     {id : 3, title : "上游WMS"},
                     {id : 4, title : "上游OMS"},
                 ];

        vm.transportMode = [
            {id : 10, title : "陆运"},
            {id : 20, title : "空运"},
            {id : 30, title : "水运"},
        ];

        vm.transportType = [
            {id : 10, title : "快递"},
            {id : 20, title : "物流"},
        ];
        vm.priority = [
            {id : 1, title : "1"},
            {id : 2, title : "2"},
            {id : 3, title : "3"},
            {id : 4, title : "4"},
            {id : 5, title : "5"},
            {id : 6, title : "6"},
            {id : 7, title : "7"},
            {id : 8, title : "8"},
            {id : 9, title : "9"},
            {id : 10, title : "10"},
        ];
        vm.type = [
            {id : 10, title : "中转"},
            {id : 20, title : "直运"},
        ];
        vm.payMethod = [
            {id : 10, title : "预付"},
            {id : 20, title : "到付"},
        ];

        vm.payStatus = [
            {id : 0, title : "未付款"},
            {id : 10, title : "部分支付"},
            {id : 20, title : "全部支付"},
        ];
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "已提交"},
            {id : 15, title : "审核拒绝"},
            {id : 20, title : "已审核"},
            {id : 30, title : "已转运单"},
            {id : 35, title : "已装货"},
            {id : 40, title : "运输中"},
            {id : 45, title : "已卸货"},
            {id : 50, title : "已签收"},
            {id : 60, title : "已回单"},
            {id : -10, title : "已取消"},
        ];
        vm.statusMap = {'0':'新建','10':'已提交','15':'审核拒绝 ','20':'已审核','30':'已转运单','35':'已装货','40':'运输中','45':'已卸货','50' :'已签收','60':'已回单', '-10' :'取消'};

        function pageTitle() {
            return $window.document.title;
        }


        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return CustomerOrder.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.orderTypes = [];
        vm.orderTypesMap = {};

        vm.initCodes = initCodes;
        function initCodes() {
            vm.codeMap = new Promise(function (resolve, reject) {
                CustomerOrder.codes({}, {}, function (resp) {
                    resolve(resp.content);
                });
            });


            vm.orderTypes = new Promise(function (resolve, reject) {
                vm.codeMap.then(function (data) {
                    angular.forEach(data.orderTypes, function (item) {
                        item.id = item.code;
                        item.title = item.name;
                        vm.orderTypesMap['' + item.code] = item.name;
                    });
                    resolve(data.orderTypes);
                });
            });

        }
        vm.initCodes();

        vm.search = search;
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.getTime = getTime;
        function getTime(t){
            return t;
        }

		vm.validate = validate;
        function validate(code){
        	if(code==null ||code==""){
        		return
			}else{
                CustomerOrder.validateCode({}, code, function(resp) {
                    if(resp.content=="success"){

                    }
                });
			}
		}

		vm.createCustomerOrder = createCustomerOrder;
		function createCustomerOrder() {
            CustomerOrder.create({}, {}, function(resp) {
            	var orderNum = resp.content;
                var entity = {
                    heading: '创建',
                    contentId: "orderMaintenance.html",
                    active: true,
                    shipper : {
                        name : null,
                        contact : null,
                        telephone : null,
                        cellPhone : null
                    },
                    customerOrder : {
                        orderNum : orderNum,
                        extOrderNum : orderNum,
                        totalWeight : 0,
                        totalVolume :0,
                        status: 0,
                        orderSource: 1
                    },
                    orderDetailList: [],
                    orderDetail :{
                        productType : null,
                        productTypeName : null,
                        productName : null
                    },
                    getExpectTime : function getTime(t){
                        entity.customerOrder.expectTime = t;
                    },
                    getLoadStartTime : function getTime(t){
                        entity.customerOrder.loadStartTime = t;
                    },
                    getUnloadStartTime : function getTime(t){
                        entity.customerOrder.unloadStartTime = t;
                    },
                    getLoadEndTime : function getTime(t){
                        entity.customerOrder.loadEndTime = t;
                    },
                    getUnloadEndTime : function getTime(t){
                        entity.customerOrder.unloadEndTime = t;
                    },
                    tableParams: new NgTableParams({}, {counts:[],getData:function(){return entity.orderDetailList}})

                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
		}
		
		vm.update = update;
		function update(t, index,form){
			var valid = form.$valid;
			 var saveVO = {
                 customerOrder :{},
                 orderDetailList :[],
             };
            var mydate = new Date();
            //t.customerOrder.orderTime = mydate.toLocaleDateString();
            saveVO.customerOrder = t.customerOrder;
            saveVO.orderDetailList = t.orderDetailList;
			 if(t.customerOrder.id){
				 CustomerOrder.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 saveVO.customerOrder.shipperName = t.shipper.name;
                 saveVO.customerOrder.shipperContact = t.shipper.contact;
                 saveVO.customerOrder.shipperTelephone = t.shipper.telephone;
                 saveVO.customerOrder.shipperCellphone = t.shipper.cellPhone;
                 CustomerOrder.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.show = show;
		function show(row) {
            CustomerOrder.show({}, row.id, function(resp) {
				var CustomerOrder = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showCustomerOrder.html",
						active : true,
						data : CustomerOrder,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = edite;
		function edite(row) {
            CustomerOrder.edite({}, row.id, function(resp) {
				var CustomerOrder = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "orderMaintenance.html",
						active : true,
                        customerOrder : CustomerOrder.customerOrder,
                        orderDetailList: CustomerOrder.orderDetailList,
                        orderDetail :{
                            productType : null,
                            productTypeName : null,
                            productName : null
                    },
                    getExpectTime : function getTime(t){
                        entity.customerOrder.expectTime = t;
                    },
                    getLoadStartTime : function getTime(t){
                        entity.customerOrder.loadStartTime = t;
                    },
                    getUnloadStartTime : function getTime(t){
                        entity.customerOrder.unloadStartTime = t;
                    },
                    getLoadEndTime : function getTime(t){
                        entity.customerOrder.loadEndTime = t;
                    },
                    getUnloadEndTime : function getTime(t){
                        entity.customerOrder.unloadEndTime = t;
                    },
                    tableParams: new NgTableParams({}, {counts:[],getData:function(){return entity.orderDetailList}})
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            CustomerOrder.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }

        vm.saveProduct = saveProduct;
        function saveProduct(t){
            var tempDetail = vm.cloneObj(t.orderDetail);
            tempDetail.productTypeName = vm.productType.name;
            tempDetail.productType = vm.productType.code;
            tempDetail.status = 0;
            tempDetail.orderNum = t.customerOrder.orderNum;
            if(t.orderDetailList.length==0){
                tempDetail.lineNum = 1;
                tempDetail.extLineNum = 1;
                t.orderDetailList.push(tempDetail);
                t.tableParams.reload();
            }else{
                tempDetail.lineNum =parseInt(t.orderDetailList[t.orderDetailList.length-1].lineNum)+1;
                tempDetail.extLineNum = parseInt(t.orderDetailList[t.orderDetailList.length-1].extLineNum)+1;
                t.orderDetailList.push(tempDetail);
                t.tableParams.reload();
            }
            t.customerOrder.totalWeight = 0;
            t.customerOrder.totalVolume = 0;
            t.customerOrder.totalFreight = 0;
            for(var i=0;i<t.orderDetailList.length;i++) {
                t.customerOrder.totalWeight = parseInt(t.customerOrder.totalWeight)+parseInt(t.orderDetailList[i].totalWeight);
                t.customerOrder.totalVolume = parseInt(t.customerOrder.totalVolume)+parseInt(t.orderDetailList[i].totalVolume);
                t.customerOrder.totalFreight = parseInt(t.customerOrder.totalFreight)+parseInt(t.orderDetailList[i].freight);
            }
        }

        vm.delDetail = delDetail;
        function delDetail(row,t){

            for(var i=0;i<t.orderDetailList.length;i++) {
                if(t.orderDetailList[i].lineNum == row.lineNum){
                    t.customerOrder.totalWeight = parseInt(t.customerOrder.totalWeight)-parseInt(t.orderDetailList[i].totalWeight);
                    t.customerOrder.totalVolume = parseInt(t.customerOrder.totalVolume)-parseInt(t.orderDetailList[i].totalVolume);
                    t.customerOrder.totalFreight = parseInt(t.customerOrder.totalFreight)-parseInt(t.orderDetailList[i].freight);
                    t.orderDetailList.splice(i-1,1);
                    t.tableParams.reload();
                    return;
                }
            }
        }

        vm.convert = convert;
        function convert(t) {
            var examineList = [];

            var dto = {
                id: null,
                status: null,
            }
            dto.id = t.customerOrder.id;
            dto.status = t.customerOrder.status;
            examineList.push(dto);
            CustomerOrder.convert({}, examineList, function(resp) {
                if(resp.content=="success"){
                    toastr.success("转运单成功");
                    t.customerOrder.status = 30;
                }
            });

        }

        vm.withdraw = withdraw;
        function withdraw(t) {
            if(t.customerOrder.status != 10){
                toastr.error("已提交状态才能撤回");
                return;
            }
            CustomerOrder.withdraw({}, t.customerOrder.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("撤回成功！");
                    t.customerOrder.status = -10;
                }
            });
        }

        vm.batchConvert = batchConvert;
        function batchConvert() {
            var examineList = [];

            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                examineList.push(dto);
            }
            CustomerOrder.convert({}, examineList, function(resp) {
                if(resp.content=="success"){
                    toastr.success("转运单成功");

                    vm.tableParams.reload();
                }
            });

        }

        vm.batchPass = batchPass
        function  batchPass() {
            var vo = {
                examineList : [],
                accept : 1
            }
            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                vo.examineList.push(dto);
            }
            CustomerOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核通过");
                    vm.tableParams.reload();
                }
            });
        }

        vm.batchCancel = batchCancel
        function  batchCancel(t) {
            var vo = {
                examineList : [],
                accept : 2
            }
            var examineList = [];
            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                console.log(dto.status);
                examineList.push(dto);

            }
            vo.examineList = examineList;
            CustomerOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核拒绝成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.pass = pass;
        function pass(t) {
            var vo = {
                examineList : [],
                accept : 1
            }
            var examineList = [];

            var dto = {
                id: null,
                status: null,
            }
            dto.id = t.customerOrder.id;
            dto.status = t.customerOrder.status;
            examineList.push(dto);
            vo.examineList = examineList;
            CustomerOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核通过");
                    t.customerOrder.status = 20;
                }
            });

        }
        vm.cancel= cancel;
        function cancel(t) {
            var vo = {
                examineList : [],
                accept : 2
            }
            var examineList = [];

            var dto = {
                id: null,
                status: null,
            }
            dto.id = t.customerOrder.id;
            dto.status = t.customerOrder.status;
            examineList.push(dto);
            vo.examineList = examineList;
            CustomerOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核拒绝成功");
                    t.customerOrder.status = 15;
                }
            });

        }

        vm.cloneObj = cloneObj;
        function cloneObj(obj) {
            var newObj = {};
            if (obj instanceof Array) {
                newObj = [];
            }
            for (var key in obj) {
                var val = obj[key];
                newObj[key] = typeof val === 'object' ? cloneObj(val): val;
            }
            return newObj;
        };
		
		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                CustomerOrder.delete({}, item, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }
		
		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }
        vm.confirmSubmit = confirmSubmit;
        function confirmSubmit(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'submitConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : t,
                        index : $index,
                        form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
                if(item.t.customerOrder.status !=0){
                    toastr.error("新建状态才能提交！");
                    return;
                }
                item.t.customerOrder.status = 10;
                vm.update(item.t,item.index,item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
