(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('inbound-validation', {
            parent: 'main',
            url: 'iwh-strategy/inbound-validation',
            data: {'pageTitle': '收货验证规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/inboundValidation/inboundValidation.html',
                    controller: 'InboundValidationController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('InboundValidation', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/inbound-validation', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/inbound-validation', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/inbound-validation/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/inbound-validation/:id', params: {id: '@id'}, method: 'GET'},
            'getLotAttributes': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotAttribute.json', method: 'GET'},
        });
    }]);

    app.controller('InboundValidationController', [
        '$scope', 'InboundValidation', 'NgTableParams', '$uibModal', '$timeout', 'toastr',
        function ($scope, InboundValidation, NgTableParams, $uibModal, $timeout, toastr) {
            $scope.ynOptions = [{k: 1, v: '是'}, {k: 0, v: '否'}];
            $scope.allowOptions = [{k: 10, v: '允许'}, {k: 20, v: '警告'}, {k: 30, v: '错误'}];

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };
            
            InboundValidation.getLotAttributes({}, function (result) {
                $scope.lotAttributes = result.content;
            });

            $scope.array2str = function (arr, field) {
                if (arr && Array.isArray(arr)) {
                    if(arr.length === 0) {
                        return null;
                    }
                    if(field) {
                        var values = [];
                        for(var i = 0; i < arr.length; i++) {
                            values.push(arr[field]);
                        }
                        return values.join(',');
                    }
                    return arr.join(',');
                } else if (typeof arr === 'string') {
                    return arr;
                }
                return null;
            };

            $scope.str2array = function (str, split) {
                if (!split || typeof split !== 'string') {
                    split = ',';
                }
                if (typeof str === 'string') {
                    return str.split(split);
                }
                return null;
            };
            
            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return InboundValidation.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.inboundValidationContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentTpl: "inboundValidationContent.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    InboundValidation.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        tab.data.asnTypes = $scope.str2array(tab.data.asnType);
                        $scope.active = $scope.tabs.length;
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.show = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '编辑',
                    contentTpl: "inboundValidationContent.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    InboundValidation.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        tab.data.asnTypes = $scope.str2array(tab.data.asnType);
                        $scope.active = $scope.tabs.length;
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.saveInboundValidation = function (tab,index) {
                if(tab.data.enableValidateExpiry && !tab.data.expiryType) {
                    toastr.error('有效期检查类型必须选择', '输入错误');
                    return;
                }
                if(tab.data.enableValidateVersion && !tab.data.versionType) {
                    toastr.error('版本号检查类型必须选择', '输入错误');
                    return;
                }
                tab.data.asnType = $scope.array2str(tab.data.asnTypes);
                InboundValidation.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };
        }
    ]);
})();