(function() {
    'use strict';

    angular.module('iwh-innerModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('countPaper', {
                parent: 'main',
                url: 'iwh-inner/countPaper?countNum=',
                params: {
                    'countNum': null
                },
                data: {
                    'pageTitle': '按库存明细盘点 - 纸单盘点执行',
                    'countNum': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inner-module/countOrder/countPaper.html',
                        controller: 'CountPaperController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
