(function () {
    'use strict';

    angular.module('doveModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('iBusimportTask', {
                parent: 'main',
                url: 'bus/importTask',
                data: {
                    'pageTitle': '导入任务'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/importTask/importTask.html',
                        controller: 'iBusImportTaskController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
    }
})();
