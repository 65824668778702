(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('CancelPickController', CancelPickController);

    CancelPickController.$inject = ['$rootScope', '$timeout', 'NgTableParams', 'toastr', 'enums', 'PickDetail'];

    function CancelPickController ($rootScope, $timeout, NgTableParams, toastr, enums, PickDetail) {
        var vm = this;

        vm.pickDetailStatus = enums('ship.PickDetailStatus');
        vm.tableParams = new NgTableParams(
            {
                count: 10
            },
            {
                getData: function(params) {
                    var postParams = angular.merge({pn: params.page(), ps: params.count()},  params.filter());
                    return PickDetail.listCancelPickDetails({}, postParams,
                        function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
                }
        });

        vm.search = search;
        vm.cancelPick = cancelPick;

        function search() {
            vm.tableParams.reload();
        }

        function cancelPick(pickDetails) {
            if (pickDetails && pickDetails.length > 0) {
                var pdIds = [];
                angular.forEach(pickDetails, function(detail) {
                    pdIds.push(detail.id);
                });

                PickDetail.cancelPick({}, pdIds, function(data) {
                    var msg = '取消成功';
                    console.log(data);
                    if (data.content.successItem && data.content.successItem.num) {
                        msg += ',创建了移动单:' + data.content.successItem.num + ',请到仓库库内/移动计划中执行移动操作';
                    }
                    toastr.success(msg);
                    $timeout(function() {
                        vm.search();
                    });
                });
            } else {
                toastr.error("未选择取消的拣选明细!");
            }
        }
    }
})();
