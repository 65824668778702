(function() {
    'use strict';

    angular.module('ivendorModule')
        .controller('VendorModSoDetailController', VendorModSoDetailController);

    VendorModSoDetailController.$inject = ['SoDetail', 'NgTableParams','$state','Report', 'toastr'];

    function VendorModSoDetailController ( SoDetail, NgTableParams,$state,Report, toastr) {
        var vm = this;
        vm.active = 0;
        vm.search = search;
        vm.status = [  
                       	{id : null, title : "不限"},
                        {id : 0, title : "新建"},  
                        {id : 1, title : "已提交"},  
                        {id : 2, title : "已发货"},
                        {id : 3, title : "已上传"},
                        {id : 9, title : "仓库已收货"}
                    ];
      //列表页功能
        vm.type = [  
                   {id : null, title : "不限"},
                   {id : 10, title : "采购发货"},
                   {id : 20, title : "其他"}
                   ];
        
       vm.listdata=null;
       vm.filterdata=filterdata;
       
       vm.reports = [];
       vm.loadReport = loadReport;
       function loadReport() {
       	Report.list({functionCode:"VENDOR_SO_DETAIL"}, {}, function(resp) {
				vm.reports = resp.content;
			});
       };
       vm.loadReport();
       
       vm.allQty = null;
       
       vm.detail=detail;
       
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return SoDetail.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					vm.filterdata(datas);
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();
			
		}
		
		function detail(row){
			$state.go('vendor-mod-so-maintenance', {sono : row.sono});
		}
		
		function filterdata(listdata){
			if(listdata != null && listdata.length>0){
				var bean= listdata[0];
				vm.allQty=bean.allQty;
				}else{
					vm.allQty = null;
				}
			return;
		}
    }
})();

