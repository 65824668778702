(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('BoxController', BoxController);

    BoxController.$inject = ['$rootScope', '$timeout', 'Box', 'NgTableParams','ShipOrder','enums','toastr','Principal'];

    function BoxController ($rootScope, $timeout,Box, NgTableParams,ShipOrder,enums,toastr,Principal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;

        vm.BoxStatusObj = enums('ship.BoxStatus');
        vm.pickDetailPickType = enums('ship.PickDetailPickType');
        vm.BoxStatus = vm.BoxStatusObj.list('title', 'id');
        
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.closeVO = {
            waybillNum : null,
            PackMaterialCode : null
        };

        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return Box.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });
        vm.searchBox = searchBox;
        vm.openDetail = openDetail;
        vm.openBox = openBox;
        vm.openBoxReturnToOrder = openBoxReturnToOrder;
        vm.openBoxOne = openBoxOne;
        vm.closeBoxOne = closeBoxOne;
        vm.buildParam = function buildParam(boxNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (boxNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    boxNum : boxNum
                };
            } else {
            	var boxs = vm.tableParams.getSelected();
            	if (boxs.length > 0) {
                    param = [];
                    for(var i=0;i<boxs.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            boxNum : boxs[i].boxNum
                        });
                    }
            	}
            }
            return param;
        }
        function searchBox() {
            vm.tableParams.reload();
        }

        function openDetail(row) {
            Box.getBoxDetail({},row.boxNum,function(resp) {
                var tab = {
                    heading: '装箱明细',
                    contentId: 'box-detail.html',
                    data: {
                        box: resp.content.boxBean,
                        detailList: resp.content.detailList,
                        sns: resp.content.sns
                    },
                    reload: reload
                };

                vm.tabs.push(tab);
                switchTab(vm.tabs.length);
            });

            function reload() {
                var that = this;
                Box.getBoxDetail({}, row.boxNum, function(resp) {
                    that.data = {
                        box: resp.content.boxBean,
                        detailList: resp.content.detailList,
                        sns: resp.content.sns
                    };
                });
            }
        }
        function openBoxReturnToOrder(boxs) {
            if(boxs.length===0){
                toastr.error("请先选择箱号");
                return;
            }
            var boxNums = [];
            var valid = true;
            for (var i=0;i<boxs.length;i++){
                if (!allowOpenBox(boxs[i])) {
                    valid = false;
                    break;
                }
                boxNums.push(boxs[i].boxNum);
            }

            if (!valid) {
                return;
            }

            Box.openBoxBackToOrder({}, boxNums, function(resp) {
                toastr.success("开箱成功！");
                vm.tableParams.reload();
            });
        }
        function openBox(boxs) {
            if(boxs.length===0){
                toastr.error("请先选择箱号");
                return;
            }
            var boxNums = [];
            var valid = true;
            for (var i=0;i<boxs.length;i++){
                if (!allowOpenBox(boxs[i])) {
                    valid = false;
                    break;
                }
                boxNums.push(boxs[i].boxNum);
            }

            if (!valid) {
                return;
            }

            Box.openBox({}, boxNums, function(resp) {
                toastr.success("开箱成功！");
                vm.tableParams.reload();
            });
        }
        vm.cancelBoxOne = function(box) {
        	if (box.status !== vm.BoxStatusObj.value('BOXING')) {
                toastr.error('箱号：' + box.boxNum + ' 不能取消复核，状态为装箱中的箱才能取消复核记录');
                return;
            }

            var boxNums = [];
            boxNums.push(box.boxNum);
            Box.openBox({},boxNums,function(resp) {
                toastr.success("取消复核记录成功！");
                vm.tableParams.reload();
                if (tab) {
                    tab.reload();
                }
            });
        }
        function openBoxOne(box, tab) {
            if (!allowOpenBox(box)) {
                return;
            }

            var boxNums = [];
            boxNums.push(box.boxNum);
            Box.openBox({},boxNums,function(resp) {
                toastr.success("开箱成功！");
                vm.tableParams.reload();
                if (tab) {
                    tab.reload();
                }
            });
        }

        function allowOpenBox(box) {
            if (box.status !== vm.BoxStatusObj.value('BOX_DONE')) {
                toastr.error('箱号：' + box.boxNum + '，状态为装箱完成的箱才能开箱');
                return false;
            } else {
                return true;
            }
        }

        function closeBoxOne(row) {
            var vo = {
                boxNum : row.boxNum,
                waybillNum : row.waybillNum,
                packMaterialCode : row.packMaterialCode
            };
            Box.closeBox({},vo,function(resp) {
                toastr.success("关箱成功!");
                vm.tableParams.reload();
            });
        }

        vm.confirmAdd = confirmAdd;
        function confirmAdd(t) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'complete-box2.html',
                controller: addModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : t

                }
            });
        }
        addModal.$inject = ['$uibModalInstance', 'item'];
        function addModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.addBoxes = addBoxes;

            function addBoxes() {
                var boxes = $ctrl.tableParam.getSelected();
                vm.addBoxes(boxes,$ctrl.item.data.deliveryOrder,$ctrl.item);

            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
    }
})();
