(function () {
    'use strict';

    angular.module('iuserModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('userModuleh5AppConfig',{
                parent:'main',
                url:'user/h5AppConfig',
                data:{
                    'pageTitle':'h5 APP'
                },
                views:{
                    'mainContent':{
                        templateUrl:'user-module/h5/h5.html',
                        controller:'UserModuleH5Controller',
                        controllerAs:'vm'
                    }
                },
                resolve:{

                }
            })
    }

})();
