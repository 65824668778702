(function() {
    'use strict';

    angular.module('iplanModule')
        .controller('iPlanStyleDemoController', iPlanStyleDemoController);

    iPlanStyleDemoController.$inject = ['$scope'];

    function iPlanStyleDemoController ($scope) {
        var vm=this;
        var time=new Date();
        var nowYear=time.getFullYear();
        var nowMonth=time.getMonth();
        vm.years=[];
        vm.months=[];
        for(var i=2016;i<=nowYear;i++){
            vm.years.push(i);
        }
        vm.year=vm.years[vm.years.length-1];
        for(var i=1;i<=12;i++){
            vm.months.push(i);
        }
        vm.month=vm.months[nowMonth];

        vm.warehouse='';

        //假设请求月份数据的参数
        vm.params={
            year:vm.year,
            month:vm.month,
            zone:'',
            warehouse:vm.warehouse,
            date:vm.date,
            platform:''
        };

        vm.app='';

        vm.showMonth=true;
        vm.setMonth=function () {
            vm.showMonth=true;
        }
        vm.setDay=function () {
            vm.showMonth=false;
        }

        var dateWatch=$scope.$watch('vm.date',function (n, o) {
            vm.params.date=n;
        });

        var paramsWatch=$scope.$watch('vm.params.date',function (n) {
            vm.date=n;
        })


        vm.date=new Date();



        vm.changeYear=function (e) {
            vm.params.year=e;
        }
        vm.changeMonth=function (e) {
            vm.params.month=e;
        }
        vm.changeWarehouse=function (e) {
            vm.params.warehouse=e;
        }

        //回到今天，就是把年份和月份调整为此时此刻的年份和月份
        vm.backToday=function () {
            vm.year=nowYear;
            vm.params.year=nowYear;
            vm.month=nowMonth+1;
            vm.params.month=nowMonth+1;
        };

        $scope.$on('$destroy',function () {
            dateWatch();            //销毁watch
            paramsWatch();
        });
    }
})();
