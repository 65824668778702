(function()  {
    'use strict';

    angular.module('iwh-receiptModule').controller('AsnController', AsnController);
    AsnController.$inject = ["$rootScope","$window", "$scope", "$state", "$timeout", "NgTableParams",
        "toastr", "enums", "Asn","ReceiptPrint","ReceiptPaper", "$uibModal", "Principal"];
    function AsnController($rootScope, $window, $scope, $state, $timeout, NgTableParams,
                           toastr, enums, Asn, ReceiptPrint,ReceiptPaper, $uibModal, Principal) {
        var vm = this;
        vm.tabs = [];                                       // 记录所有的tab
        vm.active = 0;                                      // 总共打开tab的总数
        vm.userData = {};
        vm.constant = {
            asnStatus : {
                list  : enums("receipt.AsnStatusEnum").list("title", "id"),
                INCOMPLETE : enums("receipt.AsnStatusEnum").value("INCOMPLETE"), // 不完整
                NEW : enums("receipt.AsnStatusEnum").value("NEW"), // 新建
                NO_START : enums("receipt.AsnStatusEnum").value("NO_START"), // 未开始
                RECEIPTING : enums("receipt.AsnStatusEnum").value("RECEIPTING"), // 收货中
                RECEIPT_COMPLETE : enums("receipt.AsnStatusEnum").value("RECEIPT_COMPLETE"), // 收货完成
                VALIDATE_CLOSE : enums("receipt.AsnStatusEnum").value("VALIDATE_CLOSE"), // 验证关闭
                CANCELED : enums("receipt.AsnStatusEnum").value("CANCELED") // 已取消
            },
            asnDetailStatus : {
                list : enums("receipt.AsnDetailStatusEnum").list("title", "id"),
                NEW : enums("receipt.AsnDetailStatusEnum").value("NEW"), // 新建
                NO_START : enums("receipt.AsnDetailStatusEnum").value("NO_START"), // 未开始
                RECEIPTING : enums("receipt.AsnDetailStatusEnum").value("RECEIPTING"), // 收货中
                RECEIPT_COMPLETE : enums("receipt.AsnDetailStatusEnum").value("RECEIPT_COMPLETE"), // 收货完成
            },
            comeFrom : {
                list : enums("receipt.AsnComeFromEnum").list("title", "id"),
                WMS : enums("receipt.AsnComeFromEnum").value("WMS"),
                OMS : enums("receipt.AsnComeFromEnum").value("OMS"),
                ERP : enums("receipt.AsnComeFromEnum").value("ERP")
            },
            orderType : {
                list : enums("receipt.AsnOrderTypeEnum").list("title", "id"),
                Purchase : enums("receipt.AsnOrderTypeEnum").value("Purchase")
            },
            reportType : {
                REPORT_RECEIPT_BILL : enums("receipt.ReportTypeEnum").value("EVENT_REPORT_RECEIPT_BILL"), // 收货单
                REPORT_RECEIPT_DIFF : enums("receipt.ReportTypeEnum").value("EVENT_REPORT_RECEIPT_DIFF"), // 收货单差异
                REPORT_RECEIPT_QUERY : enums("receipt.ReportTypeEnum").value("EVENT_REPORT_RECEIPT_QUERY"), // 收货查询
                REPORT_PUTAWAY_QUERY : enums("receipt.ReportTypeEnum").value("EVENT_REPORT_PUTAWAY_QUERY") // 收货上架查询
            }
        };

        vm.ansStatus = {
            NEW : enums('receipt.AsnStatusEnum').value('NEW')
        };

        vm.removeTab = removeTab;                           // 删除指定下标的tab页
        vm.createSubTabs = createSubTabs;                   // 创建通知单编辑页面的二级tab页面
        vm.createAsn = createAsn;                           // 创建Asn单
        vm.editAsn = editAsn;                               // 编辑Asn单
        vm.closeAsn = closeAsn;                             // 关闭Asn单
        vm.cancleAsn = cancleAsn;                           // 取消ASN单
        vm.deleteAsn = deleteAsn;                           // 删除Asn单
        vm.saveAsn = saveAsn;                               // 保存Asn单
        vm.refreshAsnTable = refreshAsnTable;               // 刷新Asn单
        vm.createGoods = createGoods;                       // 创建商品
        vm.updateGoods = updateGoods;                       // 更新商品
        vm.saveGoods = saveGoods;                           // 保存Asn单明细商品
        vm.deleteGoods = deleteGoods;                       // 删除Asn单明细商品
        vm.refreshGoods = refreshGoods;                     // 刷新Asn单明细列表
        vm.cancleGoodsUpdate = cancleGoodsUpdate;           // 取消Asn单明细商品更新
        vm.eventKeyUpHandler = eventKeyUpHandler;
        vm.printPreOrder = printPreOrder;                   // 打印预收货单
        vm.printLpn = printLpn;                             // 打印LPN物料单
        vm.printSkuBarCode = printSkuBarCode;               // 打印商品条码
        vm.printSkuBarCodeBatch = printSkuBarCodeBatch;     // 批量打印商品条码
        vm.enableSaveGoodsBtn = enableSaveGoodsBtn;         // 检测是否启用【保存商品】按钮
        vm.reportCallback = reportCallback;                 // 报表回调函数

        vm.receiveAll = receiveAll;   // 确认整单收货

        vm.getAsnTableList = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()}, params.filter());
                this.httpParams = postParams;
                return Asn.getAsnList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        // 获取用户数据
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
            // if ( console && console.debug ) {
            //     console.debug(vm.userData);
            // }
        });
        function receiveAll(sub, data, $index) {
            var param = {asnNum: data.asnNum};
            ReceiptPaper.receiptAllByAsn({}, param, function (resp) {
                toastr.success("收货成功！");
                vm.success();
                vm.removeTab($index);
                vm.editAsn({asnNum: data.asnNum});
            });
            
        }
        function enableSaveGoodsBtn(constant, asnStatus, asnDetailStatus) {
            // 如果ASN单已经 “取消” 或者 “关闭”，则不能保存商品
            if ( constant.asnStatus.CANCELED == asnStatus || constant.asnStatus.VALIDATE_CLOSE == asnStatus ) {
                return false;
            }
            // 如果明细已经收货完成，则不能保存商品
            if ( constant.asnDetailStatus.RECEIPT_COMPLETE == asnDetailStatus ) {
                return false;
            }
            return true;
        }

        function eventKeyUpHandler($event, dataScope){
            var target = $event.target, domObj = angular.element(target);
            if ( domObj.hasClass("qty") ) {
                var _qty = dataScope.goodsObj.qty || 0;
                dataScope.goodsObj.qty = (_qty + "").replace(/(^0)|([^0-9])/g, "");
                dataScope.goodsObj.changeQty = (dataScope.goodsObj.qty != dataScope.goodsObj._qty);
            }
        }

        function createSubTabs(subTabScope, asnScopeData, goodsScopeData) {
            subTabScope.subTabs = [{
                heading: '通知单信息',
                contentId: "asn-edit-base.html",
                data: asnScopeData,
                roles: []
            }];
            if ( !!goodsScopeData ) {
                subTabScope.subTabs.push(_getSubTabGoodsInfo(goodsScopeData, false));
            }
            subTabScope.subActive = subTabScope.subTabs.length;
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
        vm.changeCustomer = changeCustomer;
        function changeCustomer(model, sub) {
            if (model) {
                sub.data.customerCode = model.code;
                sub.data.customerName = model.name;
                sub.data.customerPhone = model.phone;
                sub.data.customerAddress = model.address;
            }
        }
        function createAsn() {
            // 生成ASN编码（通过调用IDATA模块生成的）
            Asn.createAsnCode({}, {}, function(resp){
                var subTabData = {
                    heading: '新建ASN',
                    contentId: "asn-edit.html",
                    data: {
                        asnNum : resp.content,
                        ownerCode : "-"
                    },
                    roles: [],
                    active: true
                };

                // ASN基本信息
                var asnTempData = {
                    asnNum : resp.content,
                    status : vm.ansStatus.NEW,
                    comeFrom : vm.constant.comeFrom.WMS,
                    type : vm.constant.orderType.Purchase,
                    asnTime : new Date(),
                    statusText : "新建",
                    currentAsnOperStatus : "add",
                    actualArrivalTime : null,
                    startReceiveTime : null,
                    endReceiveTime : null,
                    arrangePlatformTime : null,
                    estArrivalTime : null
                };
                asnTempData.getAsnOperLogList = _getAsnOperLogNgTable(asnTempData.asnNum);

                vm.createSubTabs(subTabData, asnTempData);
                vm.tabs.push(subTabData);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        function refreshAsnTable() {
            vm.getAsnTableList.reload();
        }
        
        function editAsn(row) {
            if ( _alreadyEdisAsn(row.asnNum) ) {
                return;
            }

            Asn.getAsnByCode({}, {
                asnNum : row.asnNum
            }, function (resp) {
                // asn数据
                var asnTempData = resp.content;
                if ( asnTempData != undefined ) {
                    asnTempData.currentAsnOperStatus = "update";
                    asnTempData.getAsnOperLogList = _getAsnOperLogNgTable(asnTempData.asnNum);

                    // 供应商
                    asnTempData.supplierObject = {
                        code : asnTempData.supplierCode,
                        name : asnTempData.supplierName,
                        address : asnTempData.supplierAddress,
                        contact : asnTempData.supplierContact,
                        phone : asnTempData.supplierPhone,
                        email : asnTempData.supplierEmail,
                        provinceId : asnTempData.supplierProvince,
                        cityId : asnTempData.supplierCity,
                        areaId : asnTempData.supplierArea
                    };

                    // 承运商
                    asnTempData.carrierObject = {
                        code : asnTempData.carrierCode,
                        name : asnTempData.carrierName,
                        address : asnTempData.carrierAddress,
                        contact : asnTempData.carrierContact,
                        phone : asnTempData.carrierPhone,
                        email : asnTempData.carrierEmail,
                        provinceId : asnTempData.carrierProvince,
                        cityId : asnTempData.carrierCity,
                        areaId : asnTempData.carrierArea
                    };
                }

                // 商品信息
                var goodsTempData = {
                    getAsnDetailList : _getAsnDetailListNgTable(asnTempData.asnNum)
                };

                var subTabData = {
                    status : asnTempData.status,
                    asnNum : asnTempData.asnNum,
                    heading: '编辑[' + asnTempData.asnNum+ ']',
                    contentId: "asn-edit.html",
                    data: {
                        asnNum : asnTempData.asnNum,
                        ownerCode : asnTempData.ownerCode
                    },
                    roles: []
                };
                vm.createSubTabs(subTabData, asnTempData, goodsTempData);
                vm.tabs.push(subTabData);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        function closeAsn(asnScope, flag, $index) {
            Asn.closeAsn({}, {
                asnNum : asnScope.asnNum
            }, function (resp) {
                toastr.success("成功关闭ASN");
                if ( flag == true ) {
                    removeTab($index);
                }
                refreshAsnTable();
            });
        }

        function cancleAsn(asnScope, flag, $index) {
            Asn.cancleAsn({}, {
                asnNum : asnScope.asnNum
            }, function (resp) {
                toastr.success("成功取消ASN");
                if ( flag == true ) {
                    removeTab($index);
                }
                refreshAsnTable();
            });
        }

        function deleteAsn(asnScope, flag, $index) {
            Asn.deleteAsnByCode({}, {
                asnNum : asnScope.asnNum
            }, function (resp) {
                toastr.success("删除ASN成功");
                if ( flag == true ) {
                    removeTab($index);
                }
                refreshAsnTable();
            });
        }
        
        function printPreOrder(asn) {
            ReceiptPrint.printAsn({}, {asnNum:asn.asnNum}, function (resp) {
                if(resp.content.printTaskId > 0) {
                    toastr.success("已成功发送打印任务到打印服务器，请等待打印完成!");
                } else if(resp.content.previewUrl) {
                    toastr.success("打印配置数据没有配置正确，跳转到预览页面进行手动打印！");
                    $window.open(resp.content.previewUrl);
                }
            });
        }

        function printLpn(asn) {
            ReceiptPrint.printLpn({}, {asnNum:asn.asnNum}, function (resp) {
                if(resp.content.printTaskId > 0) {
                    toastr.success("已成功发送打印任务到打印服务器，请等待打印完成!");
                } else if(resp.content.previewUrl) {
                    toastr.success("打印配置数据没有配置正确，跳转到预览页面进行手动打印！");
                    $window.open(resp.content.previewUrl);
                }
            });
        }

        function printSkuBarCodeBatch(subData) {
            $uibModal.open({
                templateUrl : 'skuBarPrintBatch.html',
                controller : ['$uibModalInstance', 'item', function($uibModalInstance, item) {
                    var $ctrl = this;
                    $ctrl.item = item;
                    $ctrl.ok = function() {
                        ReceiptPrint.printSkuBarCodeBatch({}, {
                            asnNum : subData.asnNum
                        }, function (resp) {
                            toastr.success(resp.content);
                        });
                        $uibModalInstance.close($ctrl.item);
                    };

                    $ctrl.cancel = function(){
                        $uibModalInstance.dismiss('cancel');
                    };
                }],
                controllerAs : '$ctrl',
                resolve : {
                    item : {}
                }
            });
        }

        function printSkuBarCode(rowData) {
            $uibModal.open({
                templateUrl : 'skuBarPrint.html',
                controller : ['$uibModalInstance', 'item', function($uibModalInstance, item) {
                    var $ctrl = this;
                    $ctrl.item = item;
                    $ctrl.printCount = rowData.qty || 0;
                    $ctrl.ok = function() {
                        ReceiptPrint.printSkuBarCode({}, {
                            asnNum : rowData.asnNum,
                            lineNum : rowData.lineNum,
                            printCount : $ctrl.printCount
                        }, function (resp) {
                            if(resp.content.printTaskId > 0) {
                                toastr.success("已成功发送打印任务到打印服务器，请等待打印完成!");
                            } else if(resp.content.previewUrl) {
                                toastr.success("打印配置数据没有配置正确，跳转到预览页面进行手动打印！");
                                $window.open(resp.content.previewUrl);
                            }
                        });
                        $uibModalInstance.close($ctrl.item);
                    };

                    $ctrl.cancel = function(){
                        $uibModalInstance.dismiss('cancel');
                    };
                }],
                controllerAs : '$ctrl',
                resolve : {
                    item : {}
                }
            });
        }

        function saveAsn(asnScope, $index, subTabScope) {
            var param = {
                "id": (undefined == asnScope.id ? -1 : asnScope.id),
                "actualArrivalTime": asnScope.actualArrivalTime,
                "arrangePlatformTime": asnScope.arrangePlatformTime,
                "asnNum": asnScope.asnNum,
                "asnTime": asnScope.asnTime,
                "breedCount": asnScope.breedCount,
                "cdOrderNum": asnScope.cdOrderNum,
                "comeFrom": asnScope.comeFrom,
                "estArrivalTime": asnScope.estArrivalTime,
                "extField1": asnScope.extField1,
                "extField10": asnScope.extField10,
                "extField11": asnScope.extField11,
                "extField12": asnScope.extField12,
                "extField13": asnScope.extField13,
                "extField14": asnScope.extField14,
                "extField15": asnScope.extField15,
                "extField2": asnScope.extField2,
                "extField3": asnScope.extField3,
                "extField4": asnScope.extField4,
                "extField5": asnScope.extField5,
                "extField6": asnScope.extField6,
                "extField7": asnScope.extField7,
                "extField8": asnScope.extField8,
                "extField9": asnScope.extField9,
                "extNum": asnScope.extNum,
                "ownerCode": asnScope.ownerCode,
                "ownerName": asnScope.ownerName,
                "pieceCount": asnScope.pieceCount,
                "platformCode": asnScope.platformCode,
                "poNum": asnScope.poNum,
                "priority": asnScope.priority,
                "remark": asnScope.remark,
                "status": asnScope.status,
                "tenantId": asnScope.tenantId,
                "transferOrderNum": asnScope.transferOrderNum,
                "type": asnScope.type,
                "warehouseCode": asnScope.warehouseCode,
                "operStatus" : asnScope.currentAsnOperStatus,
                "carrierPlateNum" : asnScope.carrierPlateNum,
                "carrierTrailerNum" : asnScope.carrierTrailerNum,
                "carrierDriver" : asnScope.carrierDriver,
                "carrierDriverPhone" : asnScope.carrierDriverPhone,
                "customerCode" : asnScope.customerCode,
                "customerName" : asnScope.customerName,
                "customerPhone" : asnScope.customerPhone,
                "customerAddress" : asnScope.customerAddress,
                "customerOrderNo" : asnScope.customerOrderNo,
                "shipOrderNo" : asnScope.shipOrderNo,
                "returnWaybillNum" : asnScope.returnWaybillNum
            };

            // 验证日期
            var nowDate = new Date();
            if ( undefined != param.estArrivalTime && (nowDate.getTime() - param.estArrivalTime.getTime() > 0) ) {
                toastr.error("预约到货时间需比现在晚");
                return;
            }
            if ( undefined != param.arrangePlatformTime && (nowDate.getTime() - param.arrangePlatformTime.getTime() > 0) ) {
                toastr.error("月台安排时间需比现在晚");
                return;
            }

            var section = asnScope;
            // 供应商
            if ( undefined != section.supplierObject ) {
                param.supplierCode = section.supplierObject.code;
                param.supplierName = section.supplierObject.name;
                param.supplierAddress = section.supplierObject.address;
                param.supplierContact = section.supplierObject.contact;
                param.supplierPhone = section.supplierObject.phone;
                param.supplierEmail = section.supplierObject.email;
                param.supplierProvince = section.supplierObject.provinceId;
                param.supplierCity = section.supplierObject.cityId;
                param.supplierArea = section.supplierObject.areaId;
            }

            // 承运商
            if ( undefined != section.carrierObject ) {
                param.carrierCode = section.carrierObject.code;
                param.carrierName = section.carrierObject.name;
                param.carrierAddress = section.carrierObject.address;
                param.carrierContact = section.carrierObject.contact;
                param.carrierPhone = section.carrierObject.phone;
                param.carrierEmail = section.carrierObject.email;
                param.carrierProvince = section.carrierObject.provinceId;
                param.carrierCity = section.carrierObject.cityId;
                param.carrierArea = section.carrierObject.areaId;
            }

            // 货主信息
            if ( section.ownerObject != undefined ) {
                param.ownerName = section.ownerObject.name;
                param.ownerCode = section.ownerObject.code;
            }

            // 供应商信息
            if ( section.supplierObject != undefined ) {
                param.supplierName = section.supplierObject.name;
                param.supplierCode = section.supplierObject.code;
            }

            // 承运商信息
            if ( section.carrierObject != undefined ) {
                param.carrierName = section.carrierObject.name;
                param.carrierCode = section.carrierObject.code;
            }

            Asn.saveAsn({}, param, function (resp) {
                toastr.success("保存成功");
                if ( asnScope.currentAsnOperStatus == "add" ) {
                    // 商品信息
                    var goodsTempData = {
                        getAsnDetailList : _getAsnDetailListNgTable(subTabScope.data.asnNum)
                    };
                    subTabScope.data.ownerCode = section.ownerCode;
                    subTabScope.subTabs.push(_getSubTabGoodsInfo(goodsTempData));
                }
                refreshAsnTable();
                asnScope.getAsnOperLogList.reload();
                asnScope.currentAsnOperStatus = "update";
            });
        }

        function createGoods(goodsScope, $index, subTabScope) {
            goodsScope.goodsEditStatus = false;
            goodsScope.asnNum = subTabScope.asnNum;
            goodsScope.goodsObj = {
                operStatus : "add"
            };
        }

        function updateGoods(row, goodsScope) {
            Asn.getAsnDetailById({}, {
                id : row.id
            }, function(resp){
                var tData = resp.content;
                tData._qty = tData.qty;

                // 商品信息
                tData.skuObject = {
                    code : tData.skuCode,
                    name : tData.skuName,
                    ownerCode : tData.ownerCode
                };

                // 包装单位信息
                tData.uomObject = {
                    code : tData.uom,
                    count : tData.uomQty
                };

                // 批次属性
                Asn.getSkuRuleInfo({}, {
                    ownerCode : tData.ownerCode,
                    skuCode : tData.skuCode
                }, function (resp) {
                    var temp = {
                        tenantId : resp.content.tenantId,
                        skuCode : resp.content.skuCode,
                        ownerCode : resp.content.ownerCode,
                        warehouseCode : resp.content.warehouseCode,
                        lotRule : resp.content.lotattributeRule,
                        bizType : "RECEIPT",
                        needDefault: false,
                    	needRequire: false
                    };
                    for (var attr in tData) {
                        if ( attr.indexOf("lotAttribute") == 0 ) {
                            temp[attr] = tData[attr];
                        }
                    }
                    tData.lotAttrObj = temp;
                });

                goodsScope.goodsEditStatus = false;
                goodsScope.goodsObj = tData;
                goodsScope.goodsObj.operStatus = "update";
                goodsScope.firstUpdate = true;
            });
        }

        function cancleGoodsUpdate(goodsScope, subTabScope) {
            goodsScope.goodsEditStatus = true;
        }

        function refreshGoods(goodsScope) {
            goodsScope.getAsnDetailList.reload();
        }

        function saveGoods(goodsScope, subTabScope) {
            var tData = goodsScope.goodsObj;
            tData.asnNum = subTabScope.asnNum;

            // 当修改时，需要验证用户是否修改过qty，如果修改过，则不允许小于已收货量
            if ( tData._qty != undefined && tData.changeQty != undefined && tData.changeQty == true ) {
                var qty = (tData.qty||'0') * (tData.uomObject.count||'0');
                if ( qty < (tData.receiveQty || 0) ) {
                    toastr.error("不允许预期收货量小于收货数量");
                    return;
                }
            }

            // 商品信息
            if ( tData.skuObject != undefined ) {
                tData.skuCode = tData.skuObject.code;
                tData.skuName = tData.skuObject.name;
                tData.ownerCode = tData.skuObject.ownerCode;
            }

            // 批次属性
            for ( var attr in tData.lotAttrObj ) {
                if ( attr.indexOf("lotAttribute") == 0 ) {
                    tData[attr] = tData.lotAttrObj[attr];
                }
            }

            // 包装单位信息
            if ( tData.uomObject != undefined ) {
                tData.uom = tData.uomObject.code;
                tData.uomQty = tData.uomObject.count;
            }

            Asn.saveAsnDetail({}, tData, function(resp){
                toastr.success("成功保存ASN明细");
                goodsScope.goodsEditStatus = true;
                refreshGoods(goodsScope);
            });
        }

        function deleteGoods(row, goodsScope) {
            Asn.deleteAsnDetailById({}, {
                id : row.id
            }, function(resp){
                toastr.success("成功删除ASN明细");
                refreshGoods(goodsScope);
            });
        }



        ////////////////////////////////////////////////////////////////////
        // 获取编辑页面商品页面的tab信息
        function _getSubTabGoodsInfo(goodsData, isActive){
            if ( undefined == goodsData ) {
                goodsData = {};
            }

            goodsData.goodsObj = {};
            goodsData.goodsEditStatus = true;
            goodsData.eventSkuChangeHandler = function(code, skuObj) {
                var _goodsData = goodsData, _goodsObj = _goodsData.goodsObj;
                _goodsObj.skuObject = skuObj;
                if (!_goodsObj.purchasePrice) {
                	_goodsObj.purchasePrice = skuObj.purchasePrice;
                }
                if ( undefined == code || undefined == skuObj ) {
                    _goodsObj.lotAttrObj = null;
                    _goodsObj.receiveLoc = "";
                    _goodsObj.packCode = "";
                    _goodsObj.uom = "";
                    _goodsObj.purchasePrice = "";
                    return;
                }

                if ( undefined == skuObj.ownerCode || undefined == skuObj.code ) {
                    return;
                }

                // 获取商品批次属性信息
                Asn.getSkuRuleInfo({}, {
                    ownerCode: skuObj.ownerCode,
                    skuCode: skuObj.code
                }, function (resp) {
                    var tData = resp.content;

                    // 设置默认值（在点击编辑按钮后第一次数据加载不执行下面动作，其他地方都将执行）
                    if ( !_goodsData.firstUpdate ) {
                        // 批次属性
                        _goodsObj.lotAttrObj = {
                            tenantId: skuObj.tenantId,
                            ownerCode: skuObj.ownerCode,
                            skuCode: skuObj.code,
                            warehouseCode: tData.warehouseCode,
                            lotRule: tData.lotattributeRule,
                            bizType : "RECEIPT",
                            needDefault: false,
                        	needRequire: false
                        };

                        _goodsObj.receiveLoc = tData.receiptQcLoc; // 初始化默认收货库位
                        _goodsObj.packCode = tData.defaultPackCode; // 初始化默认包装编码
                        _goodsObj.uom = tData.defaultUom; // 初始化默认计量单位
                    }
                    _goodsData.firstUpdate = false;
                });
            };

            return {
                heading: '通知单商品',
                contentId: "goods-list.html",
                data: goodsData,
                roles: [],
                active: isActive
            };
        }

        // 获取ASN操作日志NgTable对象
        function _getAsnOperLogNgTable(asnNum) {
            return new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count(),
                        asnNum : asnNum
                    }, params.filter());
                    if ( undefined == postParams.asnNum || postParams.asnNum == "" ) {
                        return;
                    }
                    return Asn.getAsnOperLogList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            });
        }

        // 获取ASN详情NgTable对象
        function _getAsnDetailListNgTable(asnNum) {
            return new NgTableParams({
                count: 10,httpParams:{asnNum: asnNum}
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count(),
                        asnNum : asnNum
                    }, params.filter());
                    console.log(this);
                    return Asn.getAsnDetailList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            });
        }

        // 验证给定的asnNum是否已经在编辑了
        function _alreadyEdisAsn(asnNum) {
            var flag =  false, index;
            for (var i = 0, len = vm.tabs.length; i < len; i++ ) {
                var t = vm.tabs[i];
                if ( t.asnNum == asnNum ) {
                    flag = true;
                    index = i + 1;
                    break;
                }
            }

            if ( flag == true ) {
                $timeout(function(){
                    vm.active = index;
                },0);
            }

            return flag;
        }

        // 报表回调
        function reportCallback($reportCode, asnNum){
            var param = null;
            if ( undefined == vm.userData ) {
                toastr.error("获取用户登录信息失败");
            }
            if (asnNum) {
            	param = {
                        printUser : vm.userData.realName,
                        tenantId : vm.userData.tenantId,
                        warehouseCode : vm.userData.warehouseCode,
                        asnNum : asnNum
                    };
            	if ( $reportCode == vm.constant.reportType.REPORT_RECEIPT_QUERY ) {
                    param = {
                        printUser : vm.userData.realName,
                        tenantId : vm.userData.tenantId,
                        warehouseCode : vm.userData.warehouseCode,
                        timeStart : "",
                        timeEnd : "",
                        asnNum : asnNum,
                        extNum : "",
                        skuBarCode : "",
                        ownerCode : ""
                    };
                }
            } else {
            	var list = vm.getAsnTableList.getSelected();
            	if (list.length > 0) {
                    param = [];
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            asnNum : list[i].asnNum
                        });
                    }
            	}
            }
            return param;
        }
        
        if ($state.params.asnNum) {
        	vm.editAsn({asnNum:$state.params.asnNum});
        }
    }
    
})();

