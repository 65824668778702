(function() {
    'use strict';

    angular.module('idataModule')
        .controller('OwnerController', OwnerController);

    OwnerController.$inject = ['Owner', 'NgTableParams','$uibModal', '$state','Principal','$timeout','toastr'];

    function OwnerController(Owner, NgTableParams, $uibModal,$state, Principal,$timeout,toastr) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.confirmDelete = confirmDelete;
        vm.showOwner = showOwner;
        vm.deleteData = deleteData;
        vm.tree = null;
        vm.edit = edit;
        vm.tabs = [];
        vm.active = 0;
        vm.account = {};
        vm.search = search;
        vm.update = update;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.show =false;
        vm.postParams = {};
        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {

                var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'code'}, params.filter());
                if($state.params.postParams!=null) {
                    var  temp = new Object();
                    for(var p in $state.params.postParams)
                    {
                        var name=p;//属性名称
                        var value=$state.params.postParams[p];//属性对应的值
                        temp[name]=$state.params.postParams[p];
                    }
                    $state.params.postParams = null;


                }
                vm.postParams = postParams;
                return Owner.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }
        
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
        
        function deleteData(row){
        	console.log(row.id);
            Owner.delete({}, {id: row.id}, function() {
                vm.search();
            });
        }

        function create() {
	            var entity = {
	                heading: '新建',
	                contentId: "owner-eidt.html",
	                data: {
	                    code:"",
	                    name:"",
	                    englishName:"",
	                    fullName:"",
	                    address :"",
	                    contact:"",
	                    phone:"",
	                    fax:"",
	                    email:"",
	                    remark:""
	                },
	                active: true
	            };
            
	            vm.tabs.push(entity);
	            $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
        }
        
        function edit(row) {
        	Owner.detail({}, {id: row.id}, function(resp) {
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,"错误");
         			return;
         		}
        		 var data = resp.content;
         			var entity = {
                             heading: '编辑'+data.code,
                             contentId: "owner-eidt.html",
                             data: data,
                             roles: [],
                             
                             active: true
                         };
                 	
             			vm.tabs.push(entity);
             			$timeout(function(){
                             vm.active = vm.tabs.length;
                         },0);
         		});
        }
        
        function showOwner(row){
        	Owner.detail({},{id: row.id},function(resp){
        		if(resp.status === 'ERROR') {
        			toastr.error(resp.errorMsg,'错误');
        			return;
        		}
        		var data = resp.content;
        			var entity = {
                            heading: '查看'+data.name,
                            contentId: "owner-showOwner.html",
                            data: data,
                            roles: [],
                            active: true
                        };
                	
            			vm.tabs.push(entity);
            			$timeout(function(){
                            vm.active = vm.tabs.length;
                        },0);
        		});
        }
        	

         
        function update(tab, idx, form) {
            var owner = tab.data;
            console.log(tab.data);
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }

            owner.code=tab.data.code;
            Owner.save({}, owner, function(resp) {
            	if(resp.status === 'ERROR') {
        			toastr.error("货主编码已经存在，请重新输入",'错误');
        			return;
        		}else{
            	    if(owner.id){
                        toastr.success("更新成功！");
                    }else{
                        toastr.success("保存成功！");
                    }
                }
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteData(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        
        vm.carrier = carrier;
        function carrier(row){
        	console.log(row.code);
            $state.go('carrier-maintenance', {ownerCode : row.code,postParams : vm.postParams});
        }
        vm.supplier = supplier;
        function supplier(row){
        	console.log(row.code);
            $state.go('supplier-maintenance', {ownerCode : row.code});
        }
        vm.customer = customer;
        function customer(row){
            $state.go('customer-maintenance', {ownerCode : row.code});
        }
        vm.sku = sku;
        function sku(row){
            $state.go('sku-maintenance', {ownerCode : row.code});
        }

    }
})();
