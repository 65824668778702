(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('InventoryTran', InventoryTran);

    InventoryTran.$inject = ['$resource', 'iServiceConfig'];

    function InventoryTran ($resource, iServiceConfig) {
        var service = $resource('web/inventoryTran', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/inventoryTran/list", method: 'POST'},
            'beforeList': { url: iServiceConfig.iwhInventoryHost + "/web/inventoryTran/beforeList", method: 'POST'},

        });
        return service;
    }
})();
