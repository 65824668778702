(function () {
    'use strict';

    angular.module('idataModule').controller('WarehouseUserController', WarehouseUserController);
    WarehouseUserController.$inject = ['WarehouseUser', 'NgTableParams', '$uibModal', '$timeout', 'toastr'];
    function WarehouseUserController(WarehouseUser, NgTableParams, $uibModal, $timeout, toastr) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = [
            {'id': '1', 'title': '启用'},
            {'id': '-1', 'title': '禁用'}
        ];
        vm.active = 0;
        vm.search = search;
        vm.removeTab = removeTab;
        vm.create = create;
        vm.codeCheck = codeCheck;

        vm.addWarehouseUser = addWarehouseUser;

        vm.deleteData = deleteData;
        vm.confirmDelete = confirmDelete;

        vm.changeStatus = changeStatus;
        vm.changeData = changeData;
        
        vm.userList = [];
        vm.warehouseList = [];
        
        vm.userListMap = {};
        vm.warehouseListMap = {};
        
        vm.getUserList = function() {
        	WarehouseUser.getUserList({}, {pn:1, ps:100000}, function (resp) {
        		vm.userList = resp.content.datas;
        		angular.forEach(vm.userList, function(item){
        			vm.userListMap[item.userId] = item;
            	});
            });
        }
        
        vm.getWarehouseList = function() {
        	WarehouseUser.getWarehouseList({}, {pn:1, ps:100000}, function (resp) {
        		vm.warehouseList = resp.content;
        		angular.forEach(vm.warehouseList, function(item){
        			vm.warehouseListMap[item.code] = item;
            	});
            });
        }
        
        vm.getUserList();
        vm.getWarehouseList();

        function changeData(row) {
            if (row.status === 1) {
                WarehouseUser.disable({}, {
                    id: row.id
                }, function () {
                    vm.search();
                });
            } else {
                WarehouseUser.enable({}, {
                    id: row.id
                }, function () {
                    vm.search();
                });
            }
        }

        function changeStatus(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'warehouseUser-disableAndEnable.html',
                controller: changeModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                vm.changeData(item);
            });
        }

        changeModal.$inject = ['$uibModalInstance', 'item'];
        function changeModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = function () {
                $uibModalInstance.close($ctrl.item);
            }
            $ctrl.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }
        }

        // 编码检查
        function codeCheck(tab) {
            if (tab.data.code == "" || tab.data.code == null) {
                toastr.warning("编码不能为空", "警告");
                return;
            }
            WarehouseUser.checkCode({}, {
                code: tab.data.code
            }, function (resp) {
                if (!resp.content) {
                    toastr.success("编码可以使用可以使用", "成功");
                } else {
                    toastr.warning("编码已经被使用", "警告")
                }

            }, function (resp) {
                toastr.error(resp.data.errorMsg, "错误");
            });
        }


        function addWarehouseUser() {
        	if (!vm.data.selectedUsers || vm.data.selectedUsers.length == 0) {
        		toastr.error("请选择用户", "参数错误");
        		return;
        	}
        	if (!vm.data.selectedWarehouses || vm.data.selectedWarehouses.length == 0) {
        		toastr.error("请选择仓库", "参数错误");
        		return;
        	}
        	var params = [];
        	angular.forEach(vm.data.selectedUsers, function(userId){
        		var user = vm.userListMap[userId];
        		angular.forEach(vm.data.selectedWarehouses, function(warehouseCode){
        			var warehouse = vm.warehouseListMap[warehouseCode];
        			params.push({
        				warehouseCode: warehouseCode,
        				userId: userId,
        				warehouseName: warehouse.name,
        				userName: user.userName,
        				realName: user.realName
        			});
            	});
        	});
        	console.log(params);
        	WarehouseUser.addBacth({}, params, function (resp) {
        		toastr.success("添加用户&仓库关系成功！", "成功");
        		removeTab(vm.tabs.length - 1);
            })
        }

        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        // 列表页功能
        vm.tableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()
                }, params.filter());
                return WarehouseUser.list({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });


        // 删除数据
        function deleteData(row) {
            WarehouseUser.delete({}, {id: row.id}, function () {
                vm.search();
            });
        }

        function create() {
        	vm.data = {};
            var entity = {
                heading: '添加',
                contentId: "warehouseUser-edit.html",
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }
        // 确认删除
        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                vm.deleteData(item);
            });
        }


        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


        // 确认弹出窗
        confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
