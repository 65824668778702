(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhEquipment', Equipment);

    Equipment.$inject = ['$resource', 'iServiceConfig'];

    function Equipment ($resource, iServiceConfig) {
        var service = $resource('web/equipment', {}, {
            'list': { url: iServiceConfig.iwhDataHost + "/web/equipment/list", method: 'POST'},
            'saveEquipment': {url: iServiceConfig.iwhDataHost + "/web/equipment/saveEquipment", method: 'POST'},
            'showEquipment': {url: iServiceConfig.iwhDataHost + "/web/equipment/showEquipment", method: 'POST'},
            'deleteEquipment': {url: iServiceConfig.iwhDataHost + "/web/equipment/deleteEquipment", method: 'POST'},
            'validateCode': {url: iServiceConfig.iwhDataHost + "/web/equipment/validateCode", method: 'POST'},
            'validateSpecCode': {url: iServiceConfig.iwhDataHost + "/web/equipment/validateSpecCode", method: 'POST'},
            'createEquipment': {url: iServiceConfig.iwhDataHost + "/web/equipment/createEquipment", method: 'POST'},
            'getEquipmentSpec': {url: iServiceConfig.iwhDataHost + "/web/equipment/getEquipmentSpec", method: 'POST'},
            'editeEquipment': {url: iServiceConfig.iwhDataHost + "/web/equipment/editeEquipment", method: 'POST'},
            'enable': {url: iServiceConfig.iwhDataHost + "/web/equipment/enable", method: 'POST'},
        });
        return service;
    }
})();
