(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('GeneratePickOrderController', GeneratePickOrderController);

    GeneratePickOrderController.$inject = ['$rootScope', '$timeout', 'NgTableParams', 'toastr', 'enums', 'PickerOrderBiz', 'BaseCode', 'iServiceConfig', 'ApiURI'];

    function GeneratePickOrderController ($rootScope, $timeout, NgTableParams, toastr, enums, PickerOrderBiz, BaseCode, iServiceConfig, ApiURI) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;

        vm.pickMethod = enums('ship.PickMethod');
        vm.pickMethodList = vm.pickMethod.list();
        vm.genPickOrderGroupType = enums('ship.GenPickOrderGroupType');
        vm.genPickOrderGroupTypeList = vm.genPickOrderGroupType.list();
        vm.pickType = enums('ship.PickType');
        vm.pickTypeList = vm.pickType.list();
        vm.soTypes = {};

        vm.qtyReport = {};
        vm.searchParams = {};
        vm.genRule = {};
        vm.tableParams = new NgTableParams(
            {
                count: 100
            },
            {
                counts: [100, 500, 1000],

                getData: function(params) {
                    vm.searchParams.soNums = [];
                    vm.searchParams.waveNums = [];
                    if (vm.searchParams && vm.searchParams.soNumCommas && vm.searchParams.soNumCommas.length > 0) {
                        vm.searchParams.soNums = vm.searchParams.soNumCommas.replace(/，/g, ',').split(',');
                    }
                    if (vm.searchParams && vm.searchParams.waveNumCommas && vm.searchParams.waveNumCommas.length > 0) {
                        vm.searchParams.waveNums = vm.searchParams.waveNumCommas.replace(/，/g, ',').split(',');
                    }

                    var postParams = angular.merge({pn: params.page(), ps: params.count()}, vm.searchParams);
                    return PickerOrderBiz.list({}, postParams,
                        function(resp) {
                            if (resp.content) {
                                vm.qtyReport.orderCount = resp.content.orderCount;
                                vm.qtyReport.boxQty = resp.content.boxQty;

                                if (resp.content.boxPage) {
                                    params.total(resp.content.boxPage.tc);
                                } else {
                                    params.total(0);
                                }
                            }
                        }).$promise.then(function(data) {
                            if (data.content && data.content.boxPage) {
                                var datas = data.content.boxPage.datas;
                                return datas;
                            } else {
                                return [];
                            }
                    });
                }
        });

        vm.search = search;
        vm.genByRule = genByRule;
        vm.genByManual = genByManual;
        vm.createGenPickOrderModal = createGenPickOrderModal;
        vm.calcBoxNums = calcBoxNums;

        vm.baseCode = BaseCode.codes([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'}
        ]);

        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });

        function calcBoxNums() {
            var rn = 0;
            var cn = 0;
            if (vm.genRule.rowNum) {
                rn = vm.genRule.rowNum;
            }
            if (vm.genRule.colNum) {
                cn = vm.genRule.colNum;
            }

            vm.genRule.boxQtyTo = rn * cn;
        }

        function createGenPickOrderModal() {
            var item = {
                pickMethodList: angular.copy(vm.pickMethodList),
                pickMethodObj: angular.copy(vm.pickMethod),
                pickTypeList: angular.copy(vm.pickTypeList)
            };

            return item;
        }

        function search() {
            console.debug(vm.searchParams);
            vm.tableParams.reload();
        }

        function genByRule(param, rule, sow) {
            console.debug(vm.genRule);
            if (param && rule) {
                if (rule.pickMethod === undefined || rule.pickMethod === null) {
                    toastr.error('<生成规则>参数拣货方式不能为空');
                    return;
                }

                param.soNums = [];
                param.waveNums = [];
                if (param.soNumCommas && param.soNumCommas.length > 0) {
                    param.soNums = param.soNumCommas.replace(/，/g, ',').split(',');
                }
                if (param.waveNumCommas && param.waveNumCommas.length > 0) {
                    param.waveNums = param.waveNumCommas.replace(/，/g, ',').split(',');
                }
                var paramC = angular.copy(param);
                var ruleC = angular.copy(rule);
                ruleC.searchParams = paramC;
                ruleC.groupTypes = [];
                if (angular.isNumber(vm.genRule.rule1)) {
                    ruleC.groupTypes.push(vm.genRule.rule1);
                }
                if (angular.isNumber(vm.genRule.rule2)) {
                    ruleC.groupTypes.push(vm.genRule.rule2);
                }
                if (angular.isNumber(vm.genRule.rule3)) {
                    ruleC.groupTypes.push(vm.genRule.rule3);
                }
                if (angular.isNumber(vm.genRule.rule4)) {
                    ruleC.groupTypes.push(vm.genRule.rule4);
                }
                if (angular.isNumber(vm.genRule.rule5)) {
                    ruleC.groupTypes.push(vm.genRule.rule5);
                }
                if (sow) {
                    ruleC.rowNum = sow.rowNum;
                    ruleC.colNum = sow.colNum;
                }

                PickerOrderBiz.genByRule({}, ruleC, function (data) {
                    if (data && data.content && data.content.message) {
                        vm.search();

                        toastr.success(data.content.message);
                    }
                });
            }
        }

        function genByManual(selectedRows, model) {
            console.debug(selectedRows);
            var param = {
                pickMethod: model.pickMethod,
                pickType: model.pickType,
                rowNum: model.rowNum,
                colNum: model.colNum,
                boxNums: []
            };
            angular.forEach(selectedRows, function (item) {
                param.boxNums.push(item.boxNum);
            });
            PickerOrderBiz.genBySelected({}, param, function(data) {
                console.debug(data);
                if(data && data.content) {
                    $timeout(function() {
                        vm.search();

                        if (angular.isArray(data.content)) {
                            toastr.success('成功生成' + data.content.length + '个拣选单');
                        }
                    });
                }
            });
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
    }
})();
