(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleEmployee', UserModuleEmployee);

    UserModuleEmployee.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleEmployee ($resource, iServiceConfig) {
        var service = $resource('web/employee', {}, {
            'list': { url: iServiceConfig.iuserHost + "/web/employee/list", method: 'POST'},
            'save': {url: iServiceConfig.iuserHost + "/web/employee/save", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/employee/detail", method: 'POST'},
            'disable':{url: iServiceConfig.iuserHost + "/web/employee/disable", method:'POST'},
            'enable':{url: iServiceConfig.iuserHost + "/web/employee/enable", method:'POST'},
            'roles':{url: iServiceConfig.iuserHost + "/web/employee/roles", method:'GET'},
            'authTreeData': {url: iServiceConfig.iuserHost + "/web/employee/auth-tree", method: 'GET'}
        });

        return service;
    }
})();
