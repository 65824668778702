(function () {
    'use strict';

    angular.module('iwh-dataModule').controller('IwhSkuController',
        SkuController);

    SkuController.$inject = ['IwhSku', 'NgTableParams', '$uibModal',
        '$timeout', 'toastr', '$state'];

    function SkuController(IwhSku, NgTableParams, $uibModal,
                           $timeout, toastr, $state) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tenantDetail = null;
        vm.tabs = [];
        vm.active = 0;
        vm.search = search;
        vm.removeTab = removeTab;
//		--------------------------------------------------
        vm.skuEdit = skuEdit;
        vm.saveSku = saveSku;
        vm.confirmOp = confirmOp;
        vm.showPalletEdit = showPalletEdit;
        vm.updataPallet = updataPallet;
        vm.cancelPalletEdit = cancelPalletEdit;
        vm.locEdit = locEdit;
        vm.showAddPutawayZone = showAddPutawayZone;
        vm.pZoneSave = pZoneSave;
        vm.pZoneUpdata = pZoneUpdata;
        vm.pZoneEdit = pZoneEdit;
        vm.pZoneDel = pZoneDel;

        vm.showAddPutawayLoc = showAddPutawayLoc;
        vm.pLocSave = pLocSave;
        vm.pLocUpdata = pLocUpdata;
        vm.pLocEdit = pLocEdit;
        vm.pLocDel = pLocDel;

        vm.showAddSkuLoc = showAddSkuLoc;
        vm.skuLocSave = skuLocSave;
        vm.skuLocUpdata = skuLocUpdata;
        vm.skuLocEdit = skuLocEdit;
        vm.skuLocDel = skuLocDel;

        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        //列表页功能
        vm.tableParams = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count(),
                    orderBy : 'code'
                }, params.filter());
                return IwhSku.skuList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        function skuEdit(row) {
            IwhSku.skuDetail({}, {ownerCode: row.ownerCode,skuCode: row.code}, function (resp) {
                var sku = resp.content;
                var entity = {
                    skuBaseTemplate: "sku-skubase.html",
                    skuTemplate: "sku-skuedite.html",
                    palletTemplate: "sku-palletedite.html",
                    palletHead: "sku-packhead.html",
                    palletEdite: "sku-palletEdit.html",
                    heading: '编辑【'+row.code+'】',
                    contentId: "sku-edit.html",
                    requireType:[{'id':'1','title':'是'},
                        {'id':'0','title':'否'}],
                    data: {
                        sku: sku,
                        pallets: sku.pallets,
                        temppallet: null,
                        editPallet: false,
                        pallet: {},
                        tempPalletStatus: null
                    },
                    active: 0
                };

//                if ( row.id == -1 ) {
//                    entity.data.sku.id = row.id;
//                    entity.data.sku.ownerCode = row.ownerCode;
//                    entity.data.sku.ownerName = row.ownerName;
//                    entity.data.sku.warehouseCode = row.warehouseCode;
//                    entity.data.sku.whName = row.whName;
//                    entity.data.sku.skuCode = row.skuCode;
//                }

                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                    entity.active = 0;
                }, 0);
            });
        }

        // 保存商品修改
        function saveSku(t, $index, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            vm.confirmOp('updata', function () {
                IwhSku.skuUpdata({}, t.data.sku, function(res) {
                    if(res.status == 'SUCCESS') {
                    	t.data.sku.id = res.content.id
                    	toastr.success("成功", "保存成功");
                    	vm.search();
                    } else {
                    	toastr.error("失败", res.errorMsg);
                    }
                });
            });
        }

        // 展示托盘编辑
        function showPalletEdit(row, t) {
            if (t.data.editPallet) {
                t.data.tempPalletStatus.edit = false;
            }
            row.edit = true;
            var temp = new Object();
            for (var p in row) {
                var name = p;//属性名称
                var value = row[p];//属性对应的值
                temp[name] = row[p];
            }
            t.data.pallet = temp;
            t.data.temppallet = row;
            t.data.tempPalletStatus = row;
            t.data.editPallet = true;
        }

        function updataPallet(t, form, row) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            vm.confirmOp('updata', function () {
                IwhSku.palletUpdata({}, t.data.pallet, function (resp) {
                	if(resp.status == 'SUCCESS') {
                		var pallet = resp.content;
                    	toastr.success("成功", "保存托盘包装成功");
                    	for (var p in pallet) {
                            var name = p;//属性名称
                            var value = pallet[p];//属性对应的值
                            t.data.temppallet[name] = pallet[p];
                        }
                    	vm.cancelPalletEdit(t);
                    } else {
                    	toastr.error("失败", resp.errorMsg);
                    }
                });
            });

        }

        // 取消编辑
        function cancelPalletEdit(t) {
            t.data.editPallet = false;
            t.data.tempPalletStatus.edit = false;
            t.data.tempPalletStatus = {};
            t.data.pallet = {};
            t.data.temppallet = {};
        }

        // 商品库位
        function locEdit(row) {
            IwhSku.putDetail({}, {ownerCode: row.ownerCode,skuCode: row.code}, function (resp) {
                var temp = resp.content;
                var entity = {
                    zoneTemplate: "sku-putawayZone.html",
                    pLocTemplate: "sku-putawayLoc.html",
                    locTemplate: "sku-skuLoc.html",
                    heading: '存放【'+row.code+'】',
                    contentId: "sku-loczone.html",
                    data: {
                        sku: row,
                        putZones: temp.putZones,
                        putLocs: temp.putLocs,
                        skuLocs: temp.skuLocs,
                        putZone: {},
                        putLoc: {},
                        skuLoc: {},
                        showputZone: false,
                        editputZone: false,
                        showputLoc: false,
                        editputLoc: false,
                        showskuLoc: false,
                        editskuLoc: false,
                        selectedSkuLoc : function(skuLocData) {
                        	console.log(skuLocData);
                            entity.data.skuLoc.locType = skuLocData.storageType;
                            vm.locType = skuLocData.storageType;
                        }
                    },
                    active: true
                };
                
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                    entity.active = 0;
                }, 0);


            });
        }

        function showAddPutawayZone(t) {
            t.data.putZone = {zoneCode:""};
            t.data.showputZone = !t.data.showputZone;
            t.data.editputZone = false;
        }

        function pZoneSave(t, form) {
            if (t.data.putZone.zoneCode == null || t.data.putZone.zoneCode == '') {
                toastr.warning("请先选择库区", "警告");
                return;
            }

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            var zone = t.data.putZone;
            zone.ownerCode = t.data.sku.ownerCode;
            zone.skuCode = t.data.sku.code;
            IwhSku.putZoneCheck({}, zone, function (resp) {
                if (resp.content) {
                    toastr.warning("库区已存在", "警告");
                    return;
                }

                vm.confirmOp('save', function () {
                        IwhSku.putZoneSave({}, zone, function (res) {
                            toastr.success("保存成功", "成功");
                            vm.showAddPutawayZone(t);
                            IwhSku.putZones({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (re) {
                                t.data.putZones = re.content;
                            });

                        });

                    }
                );
            });
        }

        function pZoneUpdata(t, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            var zone = t.data.putZone;
            vm.confirmOp('updata', function () {
                IwhSku.putZoneUpdata({}, zone, function (resp) {
                    toastr.success("更新成功", "成功");
                    vm.showAddPutawayZone(t);
                    IwhSku.putZones({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                        t.data.putZones = res.content;
                    });

                });

            });
        }

        function pZoneEdit(row, t) {
            var temp = new Object();
            for (var p in row) {
                var name = p;//属性名称
                var value = row[p];//属性对应的值
                temp[name] = row[p];
            }
            t.data.putZone = temp;
            t.data.showputZone = true;
            t.data.editputZone = true;
        }

        function pZoneDel(row, t) {
        	vm.confirmOp('del', function () {
                IwhSku.putZoneDel({}, {id: row.id}, function (resp) {
                    toastr.success("删除成功", "成功");
                    IwhSku.putZones({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                        t.data.putZones = res.content;
                    });

                });

            });
        }
        vm.refreshPutZones = function(t) {
        	IwhSku.putZones({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                t.data.putZones = res.content;
            });
        }
        vm.refreshSkuLocs = function(t) {
        	IwhSku.skuLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                t.data.skuLocs = res.content;
            });
        }
        
        vm.refreshPutLocs = function(t) {
        	IwhSku.putLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                t.data.putLocs = res.content;
            });
        }
        
        function showAddPutawayLoc(t) {
            t.data.putLoc = {locCode:""};
            t.data.showputLoc = !t.data.showputLoc;
            t.data.editputLoc = false;
        }

        function pLocSave(t, form) {
            if (t.data.putLoc.locCode == null || t.data.putLoc.locCode == '') {
                toastr.warning("请先选择库位", "警告");
                return;
            }
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            var loc = t.data.putLoc;
            loc.ownerCode = t.data.sku.ownerCode;
            loc.skuCode = t.data.sku.code;
            IwhSku.putLocCheck({}, loc, function (resp) {
                if (resp.content) {
                    toastr.warning("库位已存在", "警告");
                    return;
                }
                vm.confirmOp('save', function () {
                        IwhSku.putLocSave({}, loc, function (res) {
                            toastr.success("保存成功", "成功");
                            vm.showAddPutawayLoc(t);
                            IwhSku.putLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (re) {
                                t.data.putLocs = re.content;
                            });
                        });
                    }
                );
            });
        }

        function pLocUpdata(t, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            var loc = t.data.putLoc;
            vm.confirmOp('updata', function () {
                IwhSku.putLocUpdata({}, loc, function (resp) {
                    toastr.success("更新成功", "成功");
                    vm.showAddPutawayLoc(t);
                    IwhSku.putLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                        t.data.putLocs = res.content;
                    });

                });

            });
        }

        function pLocEdit(row, t) {
            var temp = new Object();
            for (var p in row) {
                var name = p;//属性名称
                var value = row[p];//属性对应的值
                temp[name] = row[p];
            }
            t.data.putLoc = temp;
            t.data.showputLoc = true;
            t.data.editputLoc = true;
        }

        function pLocDel(row, t) {
        	vm.confirmOp('del', function () {
                IwhSku.putLocDel({}, {id: row.id}, function (resp) {
                    toastr.success("删除成功", "成功");
                    IwhSku.putLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                        t.data.putLocs = res.content;
                    });

                });

            });
        }

        function showAddSkuLoc(t) {
            t.data.skuLoc = {locCode:"",locType:"",replenishFromCase:1,replenishFromStorage:1,replenishPriority:1};
            t.data.showskuLoc = !t.data.showskuLoc;
            t.data.editskuLoc = false;
        }

        function skuLocSave(t, form) {
            if (t.data.skuLoc.locCode == null || t.data.skuLoc.locCode == '') {
                toastr.warning("请先选择库位", "警告");
                return;
            }

            if (t.data.skuLoc.locType == null || t.data.skuLoc.locType == '') {
                    toastr.warning("请先选择库位类型", "警告");
                    return;
            }
            if (t.data.skuLoc.replenishPack == null || t.data.skuLoc.replenishPack == '') {
                toastr.warning("请先选择补货包装", "警告");
                return;
            }
            if (t.data.skuLoc.replenishUom == null || t.data.skuLoc.replenishUom == '') {
                toastr.warning("请先选择补货单位", "警告");
                return;
            }
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            var loc = t.data.skuLoc;
            loc.ownerCode = t.data.sku.ownerCode;
            loc.skuCode = t.data.sku.code;
            IwhSku.skuLocCheck({}, loc, function (resp) {
                if (resp.content) {
                    toastr.warning("库位已存在", "警告");
                    return;
                }
                vm.confirmOp('save', function () {
                        IwhSku.skuLocSave({}, loc, function (res) {
                            toastr.success("保存成功", "成功");
                            vm.showAddSkuLoc(t);
                            IwhSku.skuLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (re) {
                                t.data.skuLocs = re.content;
                            });
                        });
                    }
                );
            });
        }

        function skuLocUpdata(t, form) {
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            var loc = t.data.skuLoc;
            vm.confirmOp('updata', function () {
                IwhSku.skuLocUpdata({}, loc, function (resp) {
                    toastr.success("更新成功", "成功");
                    vm.showAddSkuLoc(t);
                    IwhSku.skuLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                        t.data.skuLocs = res.content;
                    });
                });
            });
        }

        function skuLocEdit(row, t) {
            var temp = new Object();
            for (var p in row) {
                var name = p;//属性名称
                var value = row[p];//属性对应的值
                temp[name] = row[p];
            }
            t.data.skuLoc = temp;
            t.data.showskuLoc = true;
            t.data.editskuLoc = true;
        }

        function skuLocDel(row, t) {
        	vm.confirmOp('del', function () {
                IwhSku.skuLocDel({}, {id: row.id}, function (resp) {
                    toastr.success("删除成功", "成功");
                    IwhSku.skuLocs({}, {ownerCode: t.data.sku.ownerCode,skuCode: t.data.sku.code}, function (res) {
                        t.data.skuLocs = res.content;
                    });
                });
            });
        }
        
        // 确认操作封装
        function confirmOp(p, func) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'confirm-op.html',
                controller: confirmOperation,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        op: p
                    }
                }
            });
            vm.modalInstance.result.then(function (item) {
                return func();
            });
        }

        confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                $uibModalInstance.close(true);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
