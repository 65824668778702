(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsDriverController', TmsDriverController);

    TmsDriverController.$inject = ['$rootScope', 'TmsDriver', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsDriverController ($rootScope,  Driver, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
                       	{id : null, title : "不限"},
                        {id : 0, title : "新建"},
                        {id : 10, title : "启用"},
                        {id : -10, title : "禁用"},
                    ];

        vm.drivingType = [
            {id : "A", title : "A"},
            {id : "B", title : "B"},
            {id : "C", title : "C"}
        ];


        function pageTitle() {
            return $window.document.title;
        }

      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
                if($state.params.carrierCode!=null){
                    params.filter().carrierCode = $state.params.carrierCode;
                    $state.params.carrierCode=null;
                }
                if($state.params.fleetCode!=null){
                    params.filter().fleetCode = $state.params.fleetCode;
                    $state.params.fleetCode=null;
                }
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Driver.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.validate = validate;
        function validate(code,carrierCode){
            if(code==null ||code==""){
                return
            }else{
            	var vo = {};
            	vo.code = code;
            	vo.carrierCode = carrierCode;
                Driver.validateCode({}, vo, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }

        vm.defaultChoose = null;
		vm.createDriver = createDriver;
		function createDriver() {
                var carrierCode = "";
                if($state.params.carrierCode!=null) {
                   carrierCode = $state.params.carrierCode;
                }
                var entity = {
                    heading : '创建',
                    contentId : "createDriver.html",
                    active : true,
                    data: {
                        carrierCode : carrierCode,
                        status : 0,
                    },
                    getDriverLicenseDate:  function(t){
                        entity.data.driverLicenseDate = t;
                    }

                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);

		}

		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
            if(t.data.carrierCode==null||t.data.carrierCode==""){
                toastr.error("请选择承运商！");
                return;
            }else if(t.data.fleetCode==null||t.data.fleetCode==""){
                toastr.error("请选择车队！");
                return;
            }else if(t.data.drivingType==null||t.data.drivingType==""){
                toastr.error("请选择准驾车型！");
                return;
            }else if(t.data.status==null){
                toastr.error("请选择状态！");
                return;
            }
			 var saveVO = t.data;
			 if(t.data.id){
				 Driver.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 Driver.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}

		vm.show = show;
		function show(row) {
            Driver.show({}, row.id, function(resp) {
				var driver = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showDriver.html",
						active : true,
						data : driver,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

		vm.edite = edite;
		function edite(row) {
            Driver.show({}, row.id, function(resp) {
				var Driver = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createDriver.html",
						active : true,
						data : Driver,
                    	carrierCode : Driver.carrierCode,
                        getDriverLicenseDate:  function(t){
                            entity.data.driverLicenseDate = t;
                        },
                    	carrierSelection : function carrierSelection(obj){
                        entity.carrierCode = obj.code;
                    },
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}


        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            Driver.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }

		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                Driver.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }

		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }

		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
