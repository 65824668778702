(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleTenant', UserModuleTenant);

    UserModuleTenant.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleTenant ($resource, iServiceConfig) {
        var service = $resource('web/tenant', {}, {
            'list': { url: iServiceConfig.iuserHost + "/web/tenant/list", method: 'POST'},
            'save': {url: iServiceConfig.iuserHost + "/web/tenant/save", method: 'POST'},
            'changetenantstatus': {url: iServiceConfig.iuserHost + "/web/tenant/changetenantstatus", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/tenant/tenantdetail", method: 'POST'},
            'canselledition': {url: iServiceConfig.iuserHost + "/web/tenant/getallcanselledition", method: 'POST'},
            'addnewedition': {url: iServiceConfig.iuserHost + "/web/tenant/addnewedition", method: 'POST'},
            'changeeditionstatus': {url: iServiceConfig.iuserHost + "/web/tenant/changeeditionstatus", method: 'POST'},
            'init': {url: iServiceConfig.iuserHost + "/web/tenant/init", method: 'POST'},

            'getConfig': {url: iServiceConfig.iuserHost + "/web/tenant/getConfig", method: 'POST'},
            'saveConfig': {url: iServiceConfig.iuserHost + "/web/tenant/saveConfig", method: 'POST'},
            
        });

        return service;
    }
})();
