(function () {
    'use strict';

    angular.module('iwh-innerModule').controller('AdjustmentController',AdjustmentController);

    AdjustmentController.$inject = ['Adjustment', 'NgTableParams', '$timeout','toastr','enums','Principal'];

    function AdjustmentController(Adjustment, NgTableParams, $timeout,toastr,enums,Principal){
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.params = {};
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.search = search;
        vm.removeTab = removeTab;
        vm.formatDateTime = formatDateTime;
        vm.create = create;
        vm.edit = edit;
        vm.delect = delect;
        vm.save = save;
        vm.getDetail = getDetail;
        vm.getDetailE = getDetailE;
        vm.saveDetail = saveDetail;
        vm.delDetail = delDetail;
        vm.submit = submit;
        vm.execute = execute;
        vm.cancel = cancel;
        vm.batchSubmit = batchSubmit;
        vm.batchExecute = batchExecute;
        vm.batchCancel = batchCancel;
        vm.show = show;
        vm.saveDetailBefore = saveDetailBefore;

        vm.batchSubmitNew = batchSubmitNew;

        vm.typeObj = enums('inner.AdjustmentType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.AdjustmentStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.detailStatusObj = enums('inner.AdjustmentDetailStatus');
        vm.detailStatus = vm.detailStatusObj.list('title', 'id');

        vm.reasonCodeObj = enums('inner.AdjustmentReason');
        vm.reasonCode = vm.reasonCodeObj.list('title', 'id');
        vm.buildParam = function buildParam(data){
        	if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (data) {
            	var param = {};
                param = {
                    tenantId: data.tenantId,
                    warehouseCode: data.warehouseCode,
                    adjustNum: data.adjustNum,
                    operatorName : vm.userData.realName
                }
                return param;
            } else {
            	var param = [];
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            adjustNum : list[i].adjustNum
                        });
                    }
            	}
                return param;
            }
        }


        //列表页功能
        vm.tableParams = new NgTableParams({count : 10}, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return Adjustment.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        /*//m明细列表页功能
        vm.detailTableParams = new NgTableParams({count : 10}, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return Adjustment.findAdjustmentDetailPage({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });*/
        // 通用方法
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(tabs,idx) {
            tabs.splice(idx, 1);
        }
        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        // 查看
        function show(row){
            var entity = {
                heading : '查看【'+row.adjustNum+'】',
                contentId : "detail.html",
                type:vm.type,
                detailTableParams:new NgTableParams({count : 10}, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.adjustNum = row.adjustNum;
                        return Adjustment.findAdjustmentDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                show:true,
                reasonCode : vm.reasonCode,
                status : vm.status,
                data: row,
                active : true,
                activeDetail :0,
                tabs:[]
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        // 添加
        function create(){
            Adjustment.adjustNum({},{},function(resp){
            }).$promise.then(function(data){
                var entity = {
                    type:vm.type,
                    isCreate:true,
                    heading : '新建',
                    contentId : "detail.html",
                    storageObj:{},
                    // reasonCode : vm.reasonCode,
                    // status : vm.status,
                    detailTableParams : new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.adjustNum = data.content;
                        return Adjustment.findAdjustmentDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                    data : {
                        adjustNum:data.content,
                        status: 0,
                        ownerCode:null,
                        // 调整库存
                        // skuCode:null,
                        // 商品名称
                        // lot:null,
                        // loc:null,
                        // lpn:null,
                        // caseNum:null,
                        // 可调整数量
                    },
                    detail:{
                        adjustNum:data.content,
                        status: 0,
                        ownerCode :data.ownerCode,
                    },
                    active : true
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        //保存更新
        function save(t) {
            if(t.data.type==null){
                toastr.error("","请先选择调整类型！");
                return;
            }
            if(t.data.reasonCode==null){
                toastr.error("","请先选择原因代码！");
                return;
            }
            if(t.data.ownerCode==null){
                toastr.error("","请先选择货主！");
                return;
            }
            Adjustment.saveAndUpdate({},t.data,function(resp){
            }).$promise.then(function(data){
                if(t.data.id){
                    toastr.success("更新成功！");
                }else {
                    toastr.success("保存成功！");
                }
                t.data = data.content;
            });

        }

        //提交
        function submit(row){
            var ids = [];
            ids.push(row.id);
            Adjustment.submit({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("提交成功！");
                vm.tableParams.reload();
            });
        }

        function batchSubmit(){
            var getSelected = vm.tableParams.getSelected();
            if(getSelected.length==0){
                toastr.error("","请先选择单！");
                return;
            }
            var ids=[];
           for(var i=0;i<getSelected.length;i++){
               ids.push(getSelected[i].id);
           }
            Adjustment.submit({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("提交成功！");
                vm.tableParams.reload();
            });
        }
        function batchSubmitNew(t){
            var ids=[];
            ids.push(t.data.id);
            Adjustment.submit({},ids,function(resp){
            }).$promise.then(function(data){
                t.data.status=10;
                toastr.success("提交成功！");
                t.detailTableParams.reload();
                vm.tableParams.reload();
            });
        }

        //执行
        function execute(row){
            var ids = [];
            ids.push(row.id);
            Adjustment.execute({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("执行成功！");
                vm.tableParams.reload();
            });
        }

        function batchExecute(){
            var getSelected = vm.tableParams.getSelected();
            if(getSelected.length==0){
                toastr.error("","请先选择单！");
                return;
            }
            var ids=[];
            for(var i=0;i<getSelected.length;i++){
                ids.push(getSelected[i].id);
            }
            Adjustment.execute({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("执行成功！");
                vm.tableParams.reload();
            });
        }

        //取消
        function cancel(row){
            var ids = [];
            ids.push(row.id);
            Adjustment.cancel({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("取消成功！");
                vm.tableParams.reload();
            });
        }

        function batchCancel(row){
            var getSelected = vm.tableParams.getSelected();
            if(getSelected.length==0){
                toastr.error("","请先选择单！");
                return;
            }
            var ids=[];
            for(var i=0;i<getSelected.length;i++){
                ids.push(getSelected[i].id);
            }
            Adjustment.cancel({},ids,function(resp){
            }).$promise.then(function(data){
                toastr.success("取消成功！");
                vm.tableParams.reload();
            });
        }

        function getDetail(t){
            var scValueField = ['ownerCode', 'skuCode', 'lot', 'locCode', 'lpn', 'caseNum'];
            var detail = {
                scValueField:scValueField,
                detail : {
                    adjustNum : t.data.adjustNum,
                    status : 0,
                    ownerCode : t.data.ownerCode,
                    qty : null,
                    reasonCode : null,
                    reason : null
                },
                ngTable :t.detailTableParams,
                status : vm.status,
                reasonCode : vm.reasonCode,
                storageObj : {}
            }
            return detail;
        }

        function getDetailE(t,row){
            var d = angular.copy(row);
            var scValue = {
                ownerCode:d.ownerCode,
                skuCode:d.skuCode,
                lot:d.lot,
                loc:d.loc,
                lpn:d.lpn,
                caseNum:d.caseNum
            };
            var scValueField = ['ownerCode', 'skuCode', 'lot', 'locCode', 'lpn', 'caseNum'];

            var detail = {
                scValue:scValue,
                scValueField:scValueField,
                detail :row,
                ngTable :t.detailTableParams,
                status : vm.status,
                reasonCode : vm.reasonCode,
                storageObj : {}
            }
            return detail;
        }

        function saveDetailBefore($modal,isNewAdd) {
            if(isNewAdd){
                if($modal.storageObj.skuCode==null){
                    toastr.error("","请先选择库存信息！");
                    return;
                }
                if($modal.detail.qty==null){
                    toastr.error("","请先填写调整数量！");
                    return;
                }else {
                    var t = new RegExp("^-?\\d+$");
                    if(!t.test($modal.detail.qty)){
                        toastr.error("","调整数量只能是整数！");
                        return;
                    }
                    if($modal.detail.qty==0){
                        toastr.error("","调整数量不能为0！");
                        return;
                    }
                    if($modal.storageObj.availableQty+$modal.detail.qty<0){
                        toastr.error("","调整数量过大，库存数量不足！");
                        return;
                    }
                }
                if($modal.detail.reasonCode==null){
                    toastr.error("","请先选择原因代码！");
                    return;
                }
            }/*else{
                if($modal.skuCode==null){
                    toastr.error("","请先选择库存信息！");
                    return;
                }
                if($modal.qty==null){
                    toastr.error("","请先填写调整数量！");
                    return;
                }else {
                    var t = new RegExp("^-?\\d+$");
                    if(!t.test($modal.qty)){
                        toastr.error("","调整数量只能是整数！");
                        return;
                    }
                    if($modal.storageObj.availableQty+$modal.detail.qty<0){
                        toastr.error("","调整数量过大，库存数量不足！");
                        return;
                    }
                }
                if($modal.reasonCode==null){
                    toastr.error("","请先选择原因代码！");
                    return;
                }
            }*/
            return true;
        }
        //保存更新
        function saveDetail($modal) {
             $modal.detail.skuCode = $modal.storageObj.skuCode;
             $modal.detail.lot = $modal.storageObj.lot;
             $modal.detail.loc = $modal.storageObj.locCode;
             $modal.detail.lpn = $modal.storageObj.lpn;
             $modal.detail.caseNum = $modal.storageObj.caseNum;
            Adjustment.saveAndUpdateDetail({}, $modal.detail,function(resp){
            }).$promise.then(function(data){
                if($modal.detail.id){
                    toastr.success("更新成功！");
                }else {
                    toastr.success("保存成功！");
                }
                $modal.ngTable.reload();
            });
        }

        function delDetail(row,t) {
            Adjustment.delDetail({}, row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("删除成功！");
                t.detailTableParams.reload();
            });
        }
        // 编辑
        function edit(row){
            var entity = {
                isCreate:true,
                heading : '编辑【'+row.adjustNum+'】',
                contentId : "detail.html",
                storageObj:{},
                reasonCode : vm.reasonCode,
                status : vm.status,
                detailTableParams : new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.adjustNum = row.adjustNum;
                        return Adjustment.findAdjustmentDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                data : row,
                detail:{
                    adjustNum:row.adjustNum,
                    status: row.status,
                    ownerCode :row.ownerCode,
                },
                active : true
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        // 删除
        function delect(row) {
            Adjustment.delDetail({})
        }
    }


})();