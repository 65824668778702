(function () {
    'use strict';

    angular.module('itaskModule')
        .controller('TaskControlController', TaskControlController);

    TaskControlController.$inject = ['TaskControl', 'NgTableParams', '$uibModal', '$state', 'Principal', '$timeout', 'toastr','iServiceConfig'];

    function TaskControlController(TaskControl, NgTableParams, $uibModal, $state, Principal, $timeout, toastr,iServiceConfig) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;

        vm.create = create;
        vm.taskCreate = taskCreate;

        vm.distribute = distribute;//分配
        vm.release = release;//释放
        vm.cancel = cancel;//释放
        vm.showLog = showLog;//显示日志
        vm.removeTab = removeTab;//移除tab
        vm.search = search;
        vm.confirmOp = confirmOp;//去人操作


        //任务状态
        vm.taskStatus = [
            {id: "10", title: "已生成"},
            {id: "20", title: "已分配"},
            {id: "30", title: "处理中"},
            {id: "40", title: "已处理"},
            {id: "50", title: "已取消"}
        ];
        //操作类型
        vm.logType = [
            {id: "10", title: "生成"},
            {id: "20", title: "分配"},
            {id: "30", title: "开始处理"},
            {id: "40", title: "处理完成"},
            {id: "50", title: "处理完成"}
        ];
        //任务类型
        vm.taskType = [
            {id: "10", title: "收货"},
            {id: "20", title: "循环盘点"},
            {id: "30", title: "移动"},
            {id: "40", title: "上架"},
            {id: "50", title: "拣货"},
            {id: "60", title: "质量控制/检验"},
            {id: "70", title: "补货"}
        ];
        //业务单据类型
        vm.bizType = [
            {id: "10", title: "仓库业务"},
            {id: "20", title: "运输业务"},
            {id: "0", title: "其它业务"}
        ];
        vm.tableParams = new NgTableParams(
            {count: 10},
            {
                getData: function (params) {
                    var postParams = angular.merge(
                        {
                            pn: params.page(),
                            ps: params.count(),
                            orderBy: 'code'
                        },
                        params.filter());
                    if ($state.params.postParams !== null) {
                        var temp = {};
                        for (var p in $state.params.postParams) {
                            var name = p;//属性名称
                            var value = $state.params.postParams[p];//属性对应的值
                            temp[name] = $state.params.postParams[p];
                        }
                        $state.params.postParams = null;
                    }
                    vm.postParams = postParams;
                    return TaskControl.taskList({}, postParams,
                        function (resp) {
                            params.total(resp.content.tc)
                        })
                        .$promise.then(function (data) {
                            return data.content.datas;
                        });
                }
            }
        );

        function create() {
            var entity = {
                heading: '新建任务',
                contentId: "task-edit.html",
                data: {
                    task:{
                        taskType:vm.taskType[0].id,
                        subTaskCount:0,
                        bizType:vm.bizType[0].id,
                        bizData:"",
                        bizNum:"",
                        bizLineNum:"",
                        executeUrl:"",
                        description:""
                    }
                },
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function taskCreate(form,t) {
            console.log("请求参数");
            console.info(t.data.task);
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            if (t.data.task.taskType === undefined){
                toastr.warning("请选择任务类型","警告!");
                return
            }

            if (t.data.task.bizType === undefined){
                toastr.warning("请选择任务类型","警告!");
                return
            }

            if (t.data.task.requireExecuteDt === null || !!!t.data.task.requireExecuteDt){
                toastr.warning("请选择要求处理时间","警告!");
                return
            }

            if (t.data.task.requireExecuteDt <= new Date()){
                toastr.warning("要求处理时间不能在当前时间之前","警告!");
                return
            }

            TaskControl.taskCreate({},t.data.task,function (resp) {
                if (resp.content){
                    console.info(resp.content);
                    toastr.success("成功","任务创建成功!");
                }
            });
        }

        //region分配
        function distribute() {
            //获取表格选中的对象数组
            var temps = vm.tableParams.getSelected();
            if(temps.length ===0){
                toastr.warning("请选择一个任务!","警告");
                return
            }
            $uibModal.open({
                templateUrl: 'distribute.html',
                controller: distributeModal,
                controllerAs: '$ctrl',
                resolve: {
                	findEmployeeUri: iServiceConfig.itaskHost + '/web/task/findEmployeeList',
                    item: {
                        owner: {}
                    }
                }
            }).result.then(function (item) {
                if(item.owner===undefined){
                    toastr.warning("请选择推送人!","警告");
                    return
                }
                angular.forEach(temps, function (data) {
                    data.ownerId = item.owner.id;
                    data.ownerName = item.owner.name;
                });
                TaskControl.taskAllocation({}, temps, function (resp) {
                    var result = resp.content;
                    toastr.success("分配完成!", "成功 :" + result.success + "失败 :" + result.error)
                    vm.tableParams.reload();
                });
            });
        }

        // 分配弹出框的控制器
        distributeModal.$inject = ['$uibModalInstance', 'item'];
        function distributeModal($uibModalInstance, item) {
            var ctrl = this;
            ctrl.item = item;
            ctrl.ok = ok;
            ctrl.cancel = cancel;

            TaskControl.findEmployeeList({}, {}, function (resp) {
                if (resp.content) {
                    item.owners = resp.content;
                }
            });

            function ok() {
                $uibModalInstance.close(ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        //endregion

        //region 释放
        function release() {
            var temps = vm.tableParams.getSelected();
            if(temps.length ===0){
                toastr.warning("请选择一个任务!","警告");
                return
            }
            vm.confirmOp('rel', function () {
                TaskControl.taskRelease({}, temps, function (resp) {
                    var result = resp.content;
                    toastr.success("释放完成!", "成功 :" + result.success + "失败 :" + result.error);
                    vm.tableParams.reload();
                })
            })
        }
        //endregion 释放

        //region 取消
        function cancel() {
            var temps = vm.tableParams.getSelected();
            if(temps.length ===0){
                toastr.warning("请选择一个任务!","警告");
                return
            }
            vm.confirmOp('cancel', function () {
                TaskControl.taskCancel({},temps, function (resp) {
                    var result = resp.content;
                    toastr.success("取消完成!", "成功 :" + result.success + "失败 :" + result.error);
                    vm.tableParams.reload();
                })
            })
        }

        // endregion
        //显示日志
        function showLog(row) {
            var entity = {
                isCreate: false,
                heading: '任务操作日志',
                contentId: "editTaskHandler.html",
                data: {
                    logTableParams: new NgTableParams(
                        {count: 10},
                        {
                            getData: function (params) {
                                var postParams = angular.merge(
                                    {
                                        taskNum: row.taskNum,
                                        pn: params.page(),
                                        ps: params.count(),
                                        orderBy: 'operateTime'
                                    },
                                    params.filter());
                                if ($state.params.postParams !== null) {
                                    var temp = {};
                                    for (var p in $state.params.postParams) {
                                        var name = p;//属性名称
                                        var value = $state.params.postParams[p];//属性对应的值
                                        temp[name] = $state.params.postParams[p];
                                    }
                                    $state.params.postParams = null;
                                }
                                vm.postParams = postParams;
                                return TaskControl.taskLogList({}, postParams,
                                    function (resp) {
                                        params.total(resp.content.tc)
                                    })
                                    .$promise.then(function (data) {
                                        return data.content.datas;
                                    });
                            }
                        }
                    )
                },
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }


        //region 封装的确认框
        function confirmOp(p, func) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'confirm-op.html',
                controller: confirmOperation,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        op: p
                    }
                }
            }).result.then(function (item) {
                return func();
            })
        }

        confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                $uibModalInstance.close(true);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        //endregion

        function removeTab(idx) {
            vm.active = idx;
            vm.tabs.splice(idx, 1);
        }

        function search() {
            vm.tableParams.reload();
        }

    }
})();
