(function()  {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('InventoryTranController', InventoryTranController);

    InventoryTranController.$inject = ['$rootScope', 'InventoryTran', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','enums'];

    function InventoryTranController ($rootScope,  InventoryTran, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,enums) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.lotAttributeSearch = null;
        vm.searchTemp = {
            tenantId : null,
            warehouseCode : null,
            lotRule : null
        };
        
        vm.InvTranTypeObj = enums('inventory.InvTranType');
        vm.InvTranType = vm.InvTranTypeObj.list('title', 'id');
        
        vm.invTranSourceTypeObj = enums('inventory.InvTranSourceType');
        vm.invTranSourceTypes = vm.invTranSourceTypeObj.list('title', 'id');

        console.log(vm.InvTranType);
        function pageTitle() {
            return $window.document.title;
        }

        InventoryTran.beforeList({}, {}, function(resp) {
            vm.searchTemp = resp.content;
        });
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
                if(vm.ownerCode){
                    postParams.sownerCode = vm.ownerCode;
                    if(vm.lotAttributeSearch!=null){
                        if(vm.lotAttributeSearch.lotAttribute01){
                            postParams.lotAttribute01 = vm.lotAttributeSearch.lotAttribute01;
                        }
                        if(vm.lotAttributeSearch.lotAttribute02){
                            postParams.lotAttribute02 = vm.lotAttributeSearch.lotAttribute02;
                        }
                        if(vm.lotAttributeSearch.lotAttribute03){
                            postParams.lotAttribute03 = vm.lotAttributeSearch.lotAttribute03;
                        }
                        if(vm.lotAttributeSearch.lotAttribute04){
                            postParams.lotAttribute04 = vm.lotAttributeSearch.lotAttribute04;
                        }
                        if(vm.lotAttributeSearch.lotAttribute05){
                            postParams.lotAttribute05 = vm.lotAttributeSearch.lotAttribute05;
                        }
                        if(vm.lotAttributeSearch.lotAttribute06){
                            postParams.lotAttribute06 = vm.lotAttributeSearch.lotAttribute06;
                        }
                        if(vm.lotAttributeSearch.lotAttribute07){
                            postParams.lotAttribute07 = vm.lotAttributeSearch.lotAttribute07;
                        }
                        if(vm.lotAttributeSearch.lotAttribute08){
                            postParams.lotAttribute08 = vm.lotAttributeSearch.lotAttribute08;
                        }
                        if(vm.lotAttributeSearch.lotAttribute09){
                            postParams.lotAttribute09 = vm.lotAttributeSearch.lotAttribute09;
                        }
                        if(vm.lotAttributeSearch.lotAttribute10){
                            postParams.lotAttribute10 = vm.lotAttributeSearch.lotAttribute10;
                        }
                        if(vm.lotAttributeSearch.lotAttribute11){
                            postParams.lotAttribute11 = vm.lotAttributeSearch.lotAttribute11;
                        }
                        if(vm.lotAttributeSearch.lotAttribute12){
                            postParams.lotAttribute12 = vm.lotAttributeSearch.lotAttribute12;
                        }
                    }
                }
                this.httpParams = postParams;
                return InventoryTran.list({}, postParams, function(resp) {
					params.total(resp.content.pageVo.tc);
				}).$promise.then(function(data) {
                    var datas = data.content.pageVo.datas;
                    vm.allCount ={
                        uomQty : "总计",
                        qty : data.content.allQty
                    }
                    datas[datas.length] = vm.allCount;
                    return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();
		}

        vm.btnSearch = btnSearch;
        function btnSearch() {
            if(!vm.ownerCode){
                vm.lotAttributeSearch == null;
            }
            vm.tableParams.reload();
        }

        vm.ruleChange = ruleChange;
        function ruleChange() {
            vm.lotAttributeSearch = null;
            if (vm.ownerCode && vm.searchTemp && vm.searchTemp.lotRule) {
                vm.lotAttributeSearch = {
                    tenantId: vm.searchTemp.tenantId,
                    warehouseCode: vm.searchTemp.warehouseCode,
                    lotRule: vm.searchTemp.lotRule,
                    ownerCode: vm.ownerCode,
                    skuCode: '-1'
                };
            }
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
