(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('TransferBatchController', TransferBatchController);

    TransferBatchController.$inject = ['$rootScope', '$timeout', 'NgTableParams', 'TransferBatch', 'DeliveryOrder', 'toastr', 'enums','Principal'];

    function TransferBatchController ($rootScope, $timeout, NgTableParams, TransferBatch, DeliveryOrder, toastr, enums,Principal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.params = {};
        vm.userData = {};
        vm.TransferBatchStatusObj = enums('ship.TransferBatchStatus');
        vm.TransferBatchStatus = vm.TransferBatchStatusObj.list();

        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.search = search;
        vm.cancelTransferBatch = cancelTransferBatch;
        vm.completeTransfe = completeTransfe;
        vm.getTransferBatch = getTransferBatch;
        vm.removeTab = removeTab;
        vm.buildParam = function buildParam(transferNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (transferNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    transferNum : transferNum
                };
            } else {
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    param = [];
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            transferNum : list[i].transferNum
                        });
                    }
            	}
            }
            return param;
        }
        function cancelTransferBatch(transferBatchs) {
            if (transferBatchs) {
                if (!angular.isArray(transferBatchs)) {
                    transferBatchs = [transferBatchs];
                }
                if (transferBatchs.length === 0) {
                    toastr.error('请选择交接批次');
                    return;
                }

                var transferNums = [];
                angular.forEach(transferBatchs, function(item) {
                    var transferNum = item.transferNum;
                    transferNums.push(transferNum);
                });

                TransferBatch.cancelTransferBatch({}, transferNums, function(data) {
                    var msg = '成功取消交接批次' + data.content.successItems.length + '个,失败'
                        + data.content.failItems.length + '个';
                    if (data.content.failItems.length > 0) {
                        toastr.warning(msg);
                    } else {
                        toastr.success(msg);
                    }

                    vm.search();
                });
            }
        }

        function completeTransfe(transferBatchs) {
            if (transferBatchs) {
                if (!angular.isArray(transferBatchs)) {
                    transferBatchs = [transferBatchs];
                }
                if (transferBatchs.length === 0) {
                    toastr.error('请选择交接批次');
                    return;
                }

                var transferNums = [];
                angular.forEach(transferBatchs, function(item) {
                    var transferNum = item.transferNum;
                    transferNums.push(transferNum);
                });

                TransferBatch.completeTransferBatch({}, transferNums, function(resp) {
                    toastr.success('成功完成交接批次' + data.content.successItems.length + '个,失败'
                        + data.content.failItems.length + '个');

                    vm.search();
                });
            }
        }
        
        function getTransferBatch(transferNum) {
            if (transferNum) {
                TransferBatch.getTransferBatch({}, {
                    transferNum: transferNum
                }, function(data) {
                    var tab = {
                        heading: transferNum,
                        contentId: "detail.html",
                        data: {
                            tb: data.content
                        }
                    };

                    vm.tabs.push(tab);
                    switchTab(vm.tabs.length);
                    $timeout(function(){
                        vm.active = vm.tabs.length;
                    },0);
                });
            }
        }

        function search() {
            vm.tableParams.reload();
        }

        vm.tableParams = new NgTableParams({}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, vm.params);
                return TransferBatch.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
    }
})();
