(function() {
    'use strict';

    angular.module('iwh-dataModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('iwh-sku-maintenance', {
                parent: 'main',
               url: 'iwh-data/sku-maintenance',
                data: {
                    'pageTitle': '商品维护'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-data-module/sku/sku.html',
                        controller: 'IwhSkuController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
