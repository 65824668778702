(function () {
    'use strict';

    angular.module('iwh-strategyModule', [
        'ngStorage',
        'ngResource',
        'ngCookies',
        'ngAria',
        'ngCacheBuster',
        'ngFileUpload',
        'ui.bootstrap',
        'angularMoment',
        'ui.router',
        'infinite-scroll',
        'ngTouch',
        // jhipster-needle-angularjs-add-module JHipster will add new module here
        'angular-loading-bar',
        'ngTable',
        'exTable',
        'filesModel',
        'ui.tree',
        'treeControl',
        'ngTree',
        'ngSelect',
        'ngMenu',
        'iwhStrategy',
        'toastr',
        'soModal',
        'scTabs',
        'scInput',
        'scTable',
        'iservice.base',
        'frapontillo.bootstrap-switch'
    ]).run(['stateHandler', '$rootScope', function (stateHandler) {
        stateHandler.initialize();
    }]);
})();
