(function() {
    'use strict';

    angular
        .module('itaskModule')
        .factory('TaskControl', TaskControl);

    TaskControl.$inject = ['$resource', 'iServiceConfig'];

    function TaskControl ($resource, iServiceConfig) {
        var service = $resource('web/task', {}, {
            'taskList': { url: iServiceConfig.itaskHost + "/web/task/taskList", method: 'POST'},
            'taskAllocation': {url: iServiceConfig.itaskHost + "/web/task/taskAllocation", method: 'POST'},
            'taskRelease': {url: iServiceConfig.itaskHost + "/web/task/taskRelease", method: 'POST'},
            'taskCancel': {url: iServiceConfig.itaskHost + "/web/task/taskCancel", method: 'POST'},
            'taskLogList': {url: iServiceConfig.itaskHost + "/web/task/taskLogList", method: 'POST'},
            'taskCreate': {url: iServiceConfig.itaskHost + "/web/task/taskCreate", method: 'POST'},
            'findEmployeeList': {url: iServiceConfig.itaskHost + "/web/task/findEmployeeList", method: 'GET'}
        });
        return service;
    }
})();