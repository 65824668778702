(function () {
    'use strict';
    angular.module('imessageModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('iMessagemessage', {
                parent: 'main',
                url: 'message/message',
                data: {
                    'pageTitle': '消息'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'message-module/message/message.html',
                        controller: 'iMessageMessageController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();

