(function() {
    'use strict';

    angular.module('idataModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('owner-maintenance', {
                parent: 'main',
                url: 'data/owner-maintenance',
                params: {'postParams' : null},
                data: {
                    'pageTitle': '货主维护',
                    	'postParams': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'data-module/owner/owner.html',
                        controller: 'OwnerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
