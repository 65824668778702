(function() {
    'use strict';

    angular.module('ireportModule')
        .controller('RptReportTemplateController', RptReportTemplateController);

    RptReportTemplateController.$inject = ['RptReportTemplate', 'NgTableParams','$uibModal', '$q','$timeout','Upload','NgSelect','iServiceConfig'];

    function RptReportTemplateController(ReportTemplate, NgTableParams, $uibModal, $q, $timeout,Upload,NgSelect,iServiceConfig) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.active = 0;
        vm.tabs = [];
        vm.projectMap = {};
        vm.projects = [];
        vm.displayed = [];
        vm.isLoading  = false;
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'新建'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.statusMap = {'0':'新建','1':'启用','-1':'禁用'};
        vm.type=[{'id':null, 'title':''}, {'id':1, 'title':'JasperReport'},{'id':2, 'title':'birt'},{'id':3, 'title':'自定义'},{'id':4, 'title':'字符模板'}];
        vm.typeMap={'1':'Jasper Report','2':'Birt Report','3':'自定义','4':'字符模板'};
        vm.dsTypes=[{'id':null, 'title':''}, {'id':1, 'title':'DB数据源'},{'id':2, 'title':'JSON数据源'}];
        vm.dsTypesMap={'1':'DB数据源','2':'JSON数据源'};
        
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.changeStatusConfirm=changeStatusConfirm;
        vm.changeStatus=changeStatus;
        vm.sync=sync;
        vm.saveConfirm=saveConfirm;
        vm.save=save;
        
        vm.syncNode=syncNode;
        vm.nodeTableReload=nodeTableReload;

        
//第一个页面
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return ReportTemplate.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function search() {
            vm.tableParams.reload();
        }
        
        function changeStatusConfirm(row){
        	vm.modalInstance=$uibModal.open({
        		templateUrl : 'changeStatusModal.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : function() {
						return row;
					}
				}
        	});
        	vm.modalInstance.result.then(function(item) {
				vm.changeStatus(item);
			});
        }
        
        function changeStatus(row){
        	ReportTemplate.changeStatus({}, {
        		id : row.id,
				status : row.status
			}, function() {
				vm.search();
			});
        }
        
        
//同步
        function sync(row){
        	ReportTemplate.selectedNodeList({}, {id:row.id}, function(resp) {
        		var entity = {
                      heading: '同步'+row.name,
                      contentId: "template-nodesync.html",
                      data:{
                    	  id:row.id,
                    	  templateId:"",
                          nodeIds:[]
                      },
                      tableParams: new NgTableParams({count: 10}, {getData:function(){return resp.content}}),
                      active: true,
                  };
                  
                  vm.tabs.push(entity);
                  $timeout(function(){
                      vm.active = vm.tabs.length;
                  },0); 
                  
              	});
        }
        function syncNode(tab, idx){
        	console.log(tab);
        	var selectedObjs = tab.tableParams.getSelected();
        	if(selectedObjs==null){
        		alert("请选择需要同步的节点");
        		return;
        	}
        	var nodeIds = [];
        	var templateId = null;
            angular.forEach(selectedObjs, function(data) {
            	nodeIds.push(data.nodeId);
            	templateId = data.templateId;
            });
            if(nodeIds.length <= 0) {
            	alert("请选择需要同步的节点");
        		return;
            }
            ReportTemplate.sync({}, {id:templateId, nodeIds: nodeIds}, function(resp) {
    			if(resp.status != 'ERROR') {
    				vm.removeTab(idx);
    			} else {
    				alert(resp.errorMsg);
    			}
    		});
        }
        function nodeTableReload(tab){
        	console.log(tab);
        	ReportTemplate.selectedNodeList({}, {id:tab.data.id}, function(resp) {
    			tab.tableParams.reload();
    		});
        }
        
        function edit(row) {
        	ReportTemplate.detail({}, {id:row.id}, function(resp) {
           	 var data=resp.content
                var entity = {
                   heading: '编辑'+row.name,
                   contentId: "template-eidt.html",
                   data: {
                	   id: data.id,
                       code : data.code,
                       name : data.name,
                       type : data.type,
                       dsType: data.dsType,
                       templateParam: data.templateParam,
                       status : data.status
                   },
                   file: null,
                   active: true,
                   multiSelect: new NgSelect({
                       allList:data.nodes,
                       list:data.hasSelectNodeIds,
                       config:{
                           meta:{
                               label:'name',
                               id:'id'
                           }
                       }
                   })
               };
               
               vm.tabs.push(entity);
               $timeout(function(){
                   vm.active = vm.tabs.length;
               },0); 
               
           	});
        }
//新建
        function create() {
        	ReportTemplate.allNodeList({}, {}, function(resp) {
        	 var dataNodes=resp.content
             var entity = {
                heading: '创建',
                contentId: "template-eidt.html",
                data: {
                    code: "",
                    name: "",
                    type: "",
                    dsType: 1,
                    status:"",
                    templateParam:"",
                    nodeIdList:""
                },
                file: null,
                active: true,
                multiSelect: new NgSelect({
                    allList:dataNodes,
                    list:[],
                    config:{
                        meta:{
                            label:'name',
                            id:'id'
                        }
                    }
                })
            };
            
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0); 
            
        	});
        }
  //公用方法
        function saveConfirm(tab, idx, form){
        	vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmsaveOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						idx : idx,
						form : form
					}
				}
			});

			vm.modalInstance.result.then(function(item) {
				vm.save(item.tab, item.idx, item.form);
			});
        }
        function save(tab, idx, form){
        	 var valid = form.$valid;
             if (!valid) {
                 form.showMsg = true;
                 return;
             }
             var selectedObjs=tab.multiSelect.getSelected();
             var nodeIdList = [];
             angular.forEach(selectedObjs, function(data) {
            	 nodeIdList.push(data.id);
             });
             if(tab.file == null) {
            	 if(!tab.data.id) {
            		 alert("请上传模版文件！");
            		 return;
            	 }
            	 console.log(tab.data);
            	 ReportTemplate.save({}, {
            		 			 id:tab.data.id,
			                	 code:tab.data.code,
			                     name:tab.data.name,
			                     type:tab.data.type,
			                     dsType:tab.data.dsType,
			                     status:tab.data.status,
			                     templateParam:tab.data.templateParam,
			                     nodeIdList:nodeIdList
			                 }, function(resp) {
            		 if(resp.status==="ERROR"){
                		 alert(resp.errorMsg);
                	 }else{
                		 vm.removeTab(idx);
                	 }
                 });
             } else {
            	 Upload.upload({
                     url: iServiceConfig.ireportHost + "/web/reportTpl/saveUpload",
                     data: {
                    	 id:tab.data.id,
                    	 code:tab.data.code,
                         name:tab.data.name,
                         type:tab.data.type,
                         dsType:tab.data.dsType,
                         status:tab.data.staus,
                         templateParam:tab.data.templateParam,
                         nodeIdList:nodeIdList,
                         file: tab.file
                     }
                 }).then(function(resp) {
                	 if(resp.status==="ERROR"){
                		 alert(resp.errorMsg);
                	 }else{
                		 vm.removeTab(idx);
                	 }
                 }, function(resp) {
                	 if(resp.status==="ERROR"){
                		 alert(resp.errorMsg);
                	 }else{
                		 vm.removeTab(idx);
                	 }
                 });
             }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
		confirmOperation.$inject = ['$uibModalInstance', 'item'];
        function confirmOperation($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
