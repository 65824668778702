(function() {
    "use strict";
    angular.module("itms-orderModule").factory("TmsTransportDispatch", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsOrderHost + "/web/roadOrder", {}, {
        	'list': { url: iServiceConfig.itmsOrderHost + "/web/roadOrder/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/saveAndUpdate", method: 'POST'},
            'create': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/create", method: 'POST'},
            'listForLine': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/listForLine", method: 'POST'},
            'show': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/show", method: 'POST'},
            'showDetail': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/showDetail", method: 'POST'},
            'showRoadOrder': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/showRoadOrder", method: 'POST'},
            'cancel': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/cancel", method: 'POST'},
            'cancelTransport': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/cancelTransportOrder", method: 'POST'},
            'submit': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/submit", method: 'POST'},
            'load': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/load", method: 'POST'},
            'unload': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/unload", method: 'POST'},
            'sign': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/sign", method: 'POST'},
            'reportLocation': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/reportLocation", method: 'POST'},
            'carBack': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/carBack", method: 'POST'},
            'getVehicle': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/getVehicle", method: 'POST'},
            'driveOut': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/driveOut", method: 'POST'},
            'distributVehicle': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/distributVehicle", method: 'POST'},
            'addTransportToRoad': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/addTransportToRoad", method: 'POST'},
            'listNewRoads': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/listNewRoads", method: 'GET'},
            'codes': {url: iServiceConfig.itmsOrderHost + "/web/roadOrder/codes", method: 'POST'}
        });
    }]);
})();