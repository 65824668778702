(function() {
    'use strict';

    angular
        .module('iprintModule')
        .factory('Printer', Printer);

    Printer.$inject = ['$resource', 'iServiceConfig'];

    function Printer ($resource, iServiceConfig) {
        var service = $resource('web/printer', {}, {
            'list': { url: iServiceConfig.iprintHost + "/web/printer/list", method: 'POST'},
        });

        return service;
    }
})();
