(function() {
    'use strict';

    angular.module('iwh-innerModule')
        .controller('CountPaperController', CountPaperController);

    CountPaperController.$inject = ['$rootScope', '$stateParams', '$timeout', 'NgTableParams', 'CountOrder', 'toastr', '$filter'];

    function CountPaperController ($rootScope, $stateParams, $timeout, NgTableParams, CountOrder, toastr, $filter) {
        var vm = this;
        vm.params = {};
        vm.countOrder = {};

        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'},
            off: {'background-color': '#868e96', 'color': '#fff'}
        };
        vm.countDetail = {};
        vm.countByOrder = countByOrder;  //使用填入的数量执行盘点
        
        vm.getCountOrder = getCountOrder;  // 获取盘点单
        vm.inputEmployeeCode = inputEmployeeCode; //输入员工号
        vm.styleFn = styleFn; //明细样式
        vm.selectRow = selectRow; //选择一行
        vm.unSelectRow = unSelectRow; //不选择一行
        vm.success = success;
        vm.fail = fail;

        vm.initLoadFromParam = function() {
            if($stateParams.countNum) {
                vm.params.countNum = $stateParams.countNum;
                vm.getCountOrder({key:'Enter'}, vm.params.countNum)
            } else if($stateParams.countNum) {
                vm.params.countNum = $stateParams.countNum;
                vm.getCountOrder({key:'Enter'}, vm.params.countNum)
            }
        }
        function inputEmployeeCode($event, counterCode) {
            if (counterCode && $event.key === 'Enter') {
                jQuery('#countNum').select();
            }
        }

        function getCountOrder($event, countNum) {
            vm.countOrder = {};
            if(countNum && $event.key === 'Enter') {
            	vm.success();
                CountOrder.getForCountByCountNum({}, {countNum: countNum}, function(data) {
                    if (data.content) {
                        vm.countOrder = data.content;
                        if (!vm.countOrder.details || vm.countOrder.details.length == 0) {
                            toastr.success('当前盘点单没有可盘点的明细行数据，未发放盘点单 或者 已经盘点完');
                            vm.checkStatus = {status: 'SUCCESS', message: '当前盘点单没有可盘点的明细行数据，未发放盘点单 或者 已经盘点完'};
                        } else {
                            angular.forEach(vm.countOrder.details, function(detail) {
                                detail.countQty = detail.systemQty;
                            });
                        }
                        
                        success();
                    } else {
                        toastr.error('不存在盘点单'+countNum, "错误");
                        fail('不存在盘点单'+countNum);
                    }
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        toastr.error(resp.data.errorMsg, "错误");
                        fail(resp.data.errorMsg);
                    }
                });
            }
        }

        function styleFn(item) {
            if (item.countLineNum === vm.countDetail.countLineNum) {
                return vm.alertColor.current;
            }

            if (item.counted) {
                return vm.alertColor.done;
            }

            return {};
        }

        function selectRow(row) {
            vm.countDetail = row;
        }

        function unSelectRow(row) {
            if (row.countLineNum === vm.countDetail.countLineNum) {
                vm.countDetail = {};
            }
        }
        
        function countByOrder(countOrder, counterCode) {
            if (countOrder) {
                var param = {
                    countNum: countOrder.countNum,
                    details: []
                };
                angular.forEach(countOrder.details, function(detail) {
                    if (!detail.counted) {
                        param.details.push({
                            countLineNum: detail.countLineNum,
                            countQty: detail.countQty
                        });
                    }
                });
                vm.success();
                CountOrder.countPaper({}, param, function(data) {
                    success();
                    toastr.success('盘点确认成功');

                    vm.params = {};
                    vm.countOrder = {};
                    jQuery('#countNum').select();
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        toastr.error(resp.data.errorMsg,'错误');
                        fail(resp.data.errorMsg);
                    }
                });
            } else {
                toastr.error('请扫描拣选单号','警告');
                fail('请扫描拣选单号');
            }
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: msg};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '成功'};
        }
        
        vm.success();
        vm.initLoadFromParam();
    }
})();
