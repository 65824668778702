(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Park', Park);

    Park.$inject = ['$resource', 'iServiceConfig'];

    function Park ($resource, iServiceConfig) {
        var service = $resource('web/park', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/park/list", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/park/detail", method: 'POST'},

            'savePark': {url: iServiceConfig.idataHost + "/web/park/savePark", method: 'POST'},
            'saveParkGateway': {url: iServiceConfig.idataHost + "/web/park/saveParkGateway", method: 'POST'},
            'saveParkParkingLot': {url: iServiceConfig.idataHost + "/web/park/saveParkParkingLot", method: 'POST'},

            'updatePark': {url: iServiceConfig.idataHost + "/web/park/updatePark", method: 'POST'},
            'updateParkGateway': {url: iServiceConfig.idataHost + "/web/park/updateParkGateway", method: 'POST'},
            'updateParkParkingLot': {url: iServiceConfig.idataHost + "/web/park/updateParkParkingLot", method: 'POST'},

            'delPark': {url: iServiceConfig.idataHost + "/web/park/delPark", method: 'POST'},
            'delParkGateway': {url: iServiceConfig.idataHost + "/web/park/delParkGateway", method: 'POST'},
            'delParkParkingLot': {url: iServiceConfig.idataHost + "/web/park/delParkParkingLot", method: 'POST'},

            'gatewayList': {url: iServiceConfig.idataHost + "/web/park/gatewayList", method: 'POST'},
            'parkingLotList': {url: iServiceConfig.idataHost + "/web/park/parkingLotList", method: 'POST'},

            'checkCode': {url: iServiceConfig.idataHost + "/web/park/checkCode", method: 'POST'},
        });

        return service;
    }
})();