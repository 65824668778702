(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('SnapInventory', SnapInventory);

    SnapInventory.$inject = ['$resource', 'iServiceConfig'];

    function SnapInventory ($resource, iServiceConfig) {
        var service = $resource('web/snapInventory', {}, {
            'lotInventory': { url: iServiceConfig.iwhInventoryHost + "/web/snapInventory/lotInventory", method: 'POST'},
            'skuInventory': { url: iServiceConfig.iwhInventoryHost + "/web/snapInventory/skuInventory", method: 'POST'},
            'lotLocLpnInventory': { url: iServiceConfig.iwhInventoryHost + "/web/snapInventory/lotLocLpnInventory", method: 'POST'},
            'skuInitEndStatInventory': { url: iServiceConfig.iwhInventoryHost + "/web/snapInventory/skuInitEndStatInventory", method: 'POST'}
        });
        return service;
    }
})();
