(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('ExpiryInventoryQueryController', ExpiryInventoryQueryController);

    ExpiryInventoryQueryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'ExpiryInventory', 'toastr', '$uibModal'];

    function ExpiryInventoryQueryController ($rootScope, $timeout,NgTableParams, enums, ExpiryInventory, toastr,  $uibModal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.expiryInventoryStatus = enums('inventory.ExpiryInventoryStatus');
        vm.expiryInventoryStatusList = vm.expiryInventoryStatus.list('title', 'id');
        
        vm.search = search;

        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return ExpiryInventory.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        vm.getCurrentRowStyle = function(status) {
        	if(status == 0) {
        		return {};
        	} else if(status == 10) {
        		return {'background-color': '#b1a511', 'color': '#fff'};
        	} else if(status == 20) {
        		return {'background-color': '#dc3545', 'color': '#fff'};
        	}
        	
        }
        
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        }
    }
})();
