(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleFavMenu', UserModuleFavMenu);

    UserModuleFavMenu.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleFavMenu ($resource, iServiceConfig) {
        var service = $resource('web/favoritemenu', {}, {
            'save': {url: iServiceConfig.iuserHost + "/web/favoritemenu/save", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/favoritemenu/detail", method: 'POST'},
            'shortcutDetail': {url: iServiceConfig.iuserHost + "/web/favoritemenu/shortcut-detail", method: 'POST'},
            'delFavMenuGroup': {url: iServiceConfig.iuserHost + "/web/favoritemenu/delfavmenugroup", method: 'POST'},
            'delFavMenuDetail': {url: iServiceConfig.iuserHost + "/web/favoritemenu/delfavmenudetail", method: 'POST'},
            'favMenuId': {url: iServiceConfig.iuserHost + "/web/favoritemenu/favmenuid", method: 'POST'}
        });

        return service;
    }
})();
