(function()  {
	'use strict';

	angular.module('iwh-dataModule').controller('IwhRoadwayController', RoadwayController);

    RoadwayController.$inject = ["$rootScope", "$scope", "NgTableParams",
		"$uibModal", "NgSelect", "$state", "$timeout", "IwhRoadway", "toastr"];

	function RoadwayController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                               $state, $timeout, Roadway, toastr) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;


        /**
         * 表格过滤器数据
         */
        vm.filterData = {
            // 状态
            status : [
                {id: null, title: '全部'},
                {id: 1, title: "启用"},
                {id: -1, title: "禁用"},
            ]
        };


        /**
         * 重新加载/刷新表格数据
         */
        vm.search = function () {
            vm.getTableList.reload();
        };


        /**
         * 列表页功能
         */
        vm.getTableList = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count(),orderBy : 'code'}, params.filter());
                return Roadway.getRoadwayList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });


        /**
         * 创建巷道
         */
        vm.createRoadway = function () {
            vm.tabs.push({
                heading: '新增巷道',
                contentId: "roadway-edit.html",
                data: {
                    code : "",
                    name : "",
                    length : "",
                    width : "",
                    inCoordinateX : "",
                    inCoordinateY : "",
                    outCoordinateX : "",
                    outCoordinateY : "",
                    remark : ""
                },
                active: true
            });
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        };


        /**
         * 保存巷道
         */
        vm.saveRoadway = function (tab, idx, form) {
            var roadway = tab.data;
            if ( !form.$valid ) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }
            Roadway.saveRoadway({}, roadway, function (resp) {
                if ( "SUCCESS" == resp.status ) {
                    vm.removeTab(idx);
                    toastr.success("保存巷道成功");
                    vm.getTableList.reload();
                } else {
                    toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存巷道失败！"), "错误");
                }
            });
        };

        /**
         * 更新巷道
         * @param row
         */
        vm.updateRoadway = function (row) {
            vm.tabs.push({
                heading: '更新巷道',
                contentId: "roadway-edit.html",
                data: row,
                roles: [],
                active: true
            });
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        };

        /**
         * 显示巷道数据
         * @param row
         */
        vm.showOwner = function(row){
            vm.tabs.push({
                heading: '巷道详情',
                contentId: "roadway-detail.html",
                data: row,
                roles: [],
                active: true
            });
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        };

        /**
         * 删除巷道
         * @param row 当前行数据
         */
        vm.deleteRoadway = function (row) {
			Roadway.delRoadwayById({}, {
                id : row.id
            }, function (resp) {
                if ("SUCCESS" == resp.status) {
                    vm.search();
                    toastr.success("删除巷道成功！");
                } else {
                    toastr.error(resp.errorMsg || "删除巷道失败！", "错误");
                }
            })
        };


        /**
         * 改变巷道状态
         * @param row 当前行的数据
         */
        vm.changeRoadwayStatus = function (row) {
            Roadway.changeRoadwayStatus({}, {
                id: row.id,
                status: (row.status === 1 ? -1 : 1)
            }, function (resp) {
                if ("SUCCESS" == resp.status) {
                    vm.search();
                    toastr.success(row.status === 1 ? "禁用成功" : "启用成功");
                } else {
                    toastr.error(resp.errorMsg || "改变巷道状态失败！", "错误");
                }
            });
        };


        /**
         * 删除指定下标的tab页
         * @param idx 下标
         */
        vm.removeTab = function(idx) {
            vm.tabs.splice(idx, 1);
        };


    }; // controller end
})();

