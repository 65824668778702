(function()  {
    'use strict';

    angular.module('idataModule')
        .controller('WmsCarrierController', WmsCarrierController);

    WmsCarrierController.$inject = ['$rootScope','$stateParams', 'WmsCarrier', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function WmsCarrierController ($rootScope,$stateParams, WmsCarrier, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
		
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];
        vm.type = [  
                     {id : null, title : "不限"},
                     {id : 10, title : "采购发货"},
                     {id : 20, title : "其他"}
                 ];
        
        
        function pageTitle() {
            return $window.document.title;
        }


        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count(),
					orderBy : 'code'
				}, params.filter());
                if($stateParams.ownerCode!=null){
                	console.log($stateParams.ownerCode);
                    postParams.ownerCode = $stateParams.ownerCode;
                }
				return WmsCarrier.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        if($state.params.carrierCode!=null) {
            vm.tableParams;

        }
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.validate = validate;
        function validate(carrierCode){
        	if(carrierCode==null ||carrierCode==""){
        		return
			}else{
                WmsCarrier.validateCode({}, carrierCode, function(resp) {
                    if(resp.content=="success"){

                    }
                });
			}
		}

		vm.createCarrier = createCarrier;
		function createCarrier() {
 				var entity = {
					heading : '新建',
					contentId : "createCarrier.html",
					active : true,
					data: {
						code : "",
	                    name : "",
	                    status :0
	                },
					
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
		}
		
		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = t.data;
			 if(t.data.ownerCode==null||t.data.ownerCode==""){
			 	toastr.error("请先选择货主！");
			 	return;
			 }
			 if(t.data.id){
				 WmsCarrier.saveCarrier({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 WmsCarrier.saveCarrier({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.show = show;
		function show(row) {
            WmsCarrier.showCarrier({}, row.id, function(resp) {
				var Carrier = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showCarrier.html",
						active : true,
						data : Carrier,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = edite;
		function edite(row) {
            WmsCarrier.showCarrier({}, row.id, function(resp) {
				var Carrier = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createCarrier.html",
						active : true,
						data : Carrier,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
        vm.enable = enable;
        function enable(row){
			WmsCarrier.enable({}, row.id, function(resp) {
                    if(resp.content=="success"){
						toastr.success("启用成功");
						vm.tableParams.reload();
                    }
                });

        }
        vm.disable = disable;
        function disable(row){
            WmsCarrier.disable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("禁用成功");
                    vm.tableParams.reload();
                }
            });

        }
		
		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                WmsCarrier.deleteCarrier({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }
		
		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }

		vm.driver = driver;
        function driver(row){
            $state.go('carrierDriver-maintenance', {carrierCode : row.code});
        }
		vm.vehicle = vehicle;
		function vehicle(row){
            $state.go('carrierVehicle-maintenance', {carrierCode : row.code});
        }

        vm.owner = owner;
        function owner(){
			console.log("11111");
            if($state.params.postParams!=null) {
                console.log($state.params.postParams);
                $state.go('owner-maintenance', {postParams : $state.params.postParams});
            }
		}
    }
})();
