(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('Pack', Pack);

    Pack.$inject = ['$resource', 'iServiceConfig'];

    function Pack ($resource, iServiceConfig) {
        var service = $resource('web/pack', {}, {
            'getPackPickOrder': { url: iServiceConfig.iwhShipHost + "/web/pack/getPackPickOrder", method: 'POST'},
            'packSingleSku': { url: iServiceConfig.iwhShipHost + "/web/pack/packSingleSku", method: 'POST'},
            'printList': { url: iServiceConfig.iwhShipHost + "/web/pack/printList", method: 'POST'},
            'printWaybill': { url: iServiceConfig.iwhShipHost + "/web/pack/printWaybill", method: 'POST'}
        });
        return service;
    }
})();
