(function()  {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('ShipQueryByLotController', ShipQueryByLotController);

    ShipQueryByLotController.$inject = ['$rootScope', 'ShipQuery', 'InventorySearch', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','enums', 'BaseCode','iServiceConfig', 'ApiURI'];

    function ShipQueryByLotController ($rootScope,  ShipQuery, InventorySearch, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout, enums, BaseCode,iServiceConfig,ApiURI) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.ownerCode = null
        vm.lotAttributeSearch = null;
        vm.searchTemp = {
            tenantId : null,
            warehouseCode : null,
            lotRule : null
        };
        vm.soStatusEnum = enums('ship.SOStatus');
        vm.soDetailStatusEnum = enums('ship.SODetailStatus');
        vm.pickDetailStatusEnum = enums('ship.PickDetailStatus');
        vm.boxStatusEnum = enums('ship.BoxStatus');
        
        vm.baseCode = BaseCode.codes([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'}
        ]);

		vm.baseCodeObjs = BaseCode.codeObjs([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'}
		]);
                                    
        vm.soTypeList = [];
        vm.hasSoType = false;                        
	    vm.baseCodeObjs('WMS_SO_TYPE').then(function(data) {
	    	angular.forEach(data, function (item) {
	    		vm.soTypeList.push({ title: item.name, id: item.code });
	        });
	    	vm.hasSoType = true;
	    });
        
        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });
        
        function pageTitle() {
            return $window.document.title;
        }
        InventorySearch.beforeList({}, {}, function(resp) {
            vm.searchTemp = resp.content;
        });
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
                if(vm.ownerCode){
                    postParams.ownerCode = vm.ownerCode;
                    if(vm.lotAttributeSearch!=null){
                        if(vm.lotAttributeSearch.lotAttribute01){
                            postParams.lotAttribute01 = vm.lotAttributeSearch.lotAttribute01;
                        }
                        if(vm.lotAttributeSearch.lotAttribute02){
                            postParams.lotAttribute02 = vm.lotAttributeSearch.lotAttribute02;
                        }
                        if(vm.lotAttributeSearch.lotAttribute03){
                            postParams.lotAttribute03 = vm.lotAttributeSearch.lotAttribute03;
                        }
                        if(vm.lotAttributeSearch.lotAttribute04){
                            postParams.lotAttribute04 = vm.lotAttributeSearch.lotAttribute04;
                        }
                        if(vm.lotAttributeSearch.lotAttribute05){
                            postParams.lotAttribute05 = vm.lotAttributeSearch.lotAttribute05;
                        }
                        if(vm.lotAttributeSearch.lotAttribute06){
                            postParams.lotAttribute06 = vm.lotAttributeSearch.lotAttribute06;
                        }
                        if(vm.lotAttributeSearch.lotAttribute07){
                            postParams.lotAttribute07 = vm.lotAttributeSearch.lotAttribute07;
                        }
                        if(vm.lotAttributeSearch.lotAttribute08){
                            postParams.lotAttribute08 = vm.lotAttributeSearch.lotAttribute08;
                        }
                        if(vm.lotAttributeSearch.lotAttribute09){
                            postParams.lotAttribute09 = vm.lotAttributeSearch.lotAttribute09;
                        }
                        if(vm.lotAttributeSearch.lotAttribute10){
                            postParams.lotAttribute10 = vm.lotAttributeSearch.lotAttribute10;
                        }
                        if(vm.lotAttributeSearch.lotAttribute11){
                            postParams.lotAttribute11 = vm.lotAttributeSearch.lotAttribute11;
                        }
                        if(vm.lotAttributeSearch.lotAttribute12){
                            postParams.lotAttribute12 = vm.lotAttributeSearch.lotAttribute12;
                        }
                    }
                }
				this.httpParams = postParams;
				return ShipQuery.lot({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});
        vm.ruleChange = ruleChange;
        function ruleChange() {
            vm.lotAttributeSearch = null;
            if (vm.ownerCode && vm.searchTemp && vm.searchTemp.lotRule) {
                vm.lotAttributeSearch = {
                    tenantId: vm.searchTemp.tenantId,
                    warehouseCode: vm.searchTemp.warehouseCode,
                    lotRule: vm.searchTemp.lotRule,
                    ownerCode: vm.ownerCode,
                    skuCode: '-1'
                };
            }
        }
        vm.search = search;
        function search() {
            if(!vm.ownerCode){
                vm.lotAttributeSearch == null;
            }
            vm.tableParams.reload();
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
