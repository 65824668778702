(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhSystemConfig', SystemConfig);

    SystemConfig.$inject = ['$resource', 'iServiceConfig'];

    function SystemConfig ($resource, iServiceConfig) {
        var service = $resource('web/systemConfig', {}, {
        	
            'list': { url: iServiceConfig.iwhDataHost+ "/web/systemConfig/list", method: 'POST'},
            'detail': {url: iServiceConfig.iwhDataHost+ "/web/systemConfig/detail", method: 'POST'},
            'save': {url: iServiceConfig.iwhDataHost+ "/web/systemConfig/save", method: 'POST'},
            'delete':{url:iServiceConfig.iwhDataHost+ "/web/systemConfig/delete",method:'POST'},
            'checkCode': {url: iServiceConfig.iwhDataHost+ "/web/systemConfig/checkcode", method: 'POST'},
            'disable': {url: iServiceConfig.iwhDataHost+ "/web/systemConfig/disable", method: 'POST'},
            'enable': {url: iServiceConfig.iwhDataHost+ "/web/systemConfig/enable", method: 'POST'},
        });
        return service;
    }
})();
