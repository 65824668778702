(function() {
    "use strict";

    angular.module("iwh-dataModule").factory("IwhWorkArea", ["$resource", "iServiceConfig", function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhDataHost + "/web/workArea", {}, {
            // 工作区域
            "getRegionList"         : {url: iServiceConfig.iwhDataHost + "/web/workArea/getRegionList", method: "POST"},
            "saveRegion"            : {url: iServiceConfig.iwhDataHost + "/web/workArea/saveRegion", method: "POST"},
            "deleteRegion"          : {url: iServiceConfig.iwhDataHost + "/web/workArea/deleteRegion", method: "POST"},
            "changeStatusRegion"    : {url: iServiceConfig.iwhDataHost + "/web/workArea/changeStatusRegion", method: "POST"},

            // 工作区域的库区
            "getRegionZoneList"     : {url: iServiceConfig.iwhDataHost + "/web/workArea/getRegionZoneList", method: "POST"},
            "saveRegionZone"        : {url: iServiceConfig.iwhDataHost + "/web/workArea/saveRegionZone", method: "POST"},
            "deleteRegionZone"      : {url: iServiceConfig.iwhDataHost + "/web/workArea/deleteRegionZone", method: "POST"},
            "getZoneList"           : {url: iServiceConfig.iwhDataHost + "/web/workArea/getZoneList", method: "POST"}
        });
    }]);

})();