(function () {
    'use strict';
    var app = angular.module('iwh-shipModule');
    app.config(['$stateProvider', function($stateProvider) {
    	$stateProvider.state('shipQueryByLot', {
            parent: 'main',
            url: 'iwh-ship/shipQueryByLot',
            data: {
                'pageTitle': '发货查询【库存批次】'
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-ship-module/biz/query/lot.html',
                    controller: 'ShipQueryByLotController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

