(function () {
    "use strict";

    angular.module("iwh-dataModule").factory("IwhWhLocation", ["$resource", "iServiceConfig", function ($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhDataHost + "/web/whLocation", {}, {
            "treeData": {url: iServiceConfig.iwhDataHost + "/web/whLocation/treeData", method: "POST"},
            "treeDataByCondition": {url: iServiceConfig.iwhDataHost + "/web/whLocation/treeDataByCondition", method: "POST"},

            // 区段服务
            "getSectionList": {url: iServiceConfig.iwhDataHost + "/web/whLocation/getSectionList", method: "POST"},
            "saveSection": {url: iServiceConfig.iwhDataHost + "/web/whLocation/saveSection", method: "POST"},
            "delSectionById": {url: iServiceConfig.iwhDataHost + "/web/whLocation/delSectionById", method: "POST"},
            "changeSectionStatus": {url: iServiceConfig.iwhDataHost + "/web/whLocation/changeSectionStatus", method: "POST"},

            // 库区服务
            "getZoneList": {url: iServiceConfig.iwhDataHost + "/web/whLocation/getZoneList", method: "POST"},
            "saveZone": {url: iServiceConfig.iwhDataHost + "/web/whLocation/saveZone", method: "POST"},
            "delZoneById": {url: iServiceConfig.iwhDataHost + "/web/whLocation/delZoneById", method: "POST"},
            "changeZoneStatus": {url: iServiceConfig.iwhDataHost + "/web/whLocation/changeZoneStatus", method: "POST"},

            // 库位服务
            "getLocationList": {url: iServiceConfig.iwhDataHost + "/web/whLocation/getLocationList", method: "POST"},
            "saveLocation": {url: iServiceConfig.iwhDataHost + "/web/whLocation/saveLocation", method: "POST"},
            "delLocationById": {url: iServiceConfig.iwhDataHost + "/web/whLocation/delLocationById", method: "POST"},
            "changeLocationStatus": {url: iServiceConfig.iwhDataHost + "/web/whLocation/changeLocationStatus", method: "POST"}
        });
    }]);

})();