(function()  {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('iEptInventoryTranWhController', iEptInventoryTranWhController);

    iEptInventoryTranWhController.$inject = ['$rootScope', 'InventoryQuery', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','enums'];

    function iEptInventoryTranWhController ($rootScope,  InventoryQuery, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,enums) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        
        vm.InvTranTypeObj = enums('inventory.InvTranType');
        vm.InvTranType = vm.InvTranTypeObj.list('title', 'id');
        
        vm.invTranSourceTypeObj = enums('inventory.InvTranSourceType');
        vm.invTranSourceTypes = vm.invTranSourceTypeObj.list('title', 'id');

        function pageTitle() {
            return $window.document.title;
        }
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());

                return InventoryQuery.tranWh({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();
		}

        vm.btnSearch = btnSearch;
        function btnSearch() {
            vm.tableParams.reload();
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
