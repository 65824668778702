(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('prealloc-rule', {
            parent: 'main',
            url: 'iwh-strategy/prealloc-rule',
            data: {'pageTitle': '预分配规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/preallocRule/preallocRule.html',
                    controller: 'PreallocRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('PreallocRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/prealloc-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/prealloc-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/prealloc-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/prealloc-rule/:id', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/prealloc-rule/detail/save', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/prealloc-rule/detail/delete/:id', params: {id: '@id'}, method: 'POST'},
            'getPreallocateLotMethod': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.PreallocateLotMethod.json', method: 'GET'},
            'getPreallocateMultiPackMethod': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.PreallocateMultiPackMethod.json', method: 'GET'},
            'getStdPackUom': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.StdPackUom.json', method: 'GET'},
        });
    }]);

    app.controller('PreallocRuleController', [
        '$scope', 'PreallocRule', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, PreallocRule, NgTableParams, $timeout, toastr) {
            $scope.ynOptions = [{k: 1, v: '是'}, {k: 0, v: '否'}];
            PreallocRule.getPreallocateLotMethod({}, function (result) {
                $scope.preallocateLotMethods = result.content;
            });

            PreallocRule.getPreallocateMultiPackMethod({}, function (result) {
                $scope.preallocateMultiPackMethods = result.content;
            });

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return PreallocRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.preallocRuleContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {details: []};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '(新建)',
                    contentId: "preallocRuleDetail.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    PreallocRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.preallocRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {details: []};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '(添加)',
                    contentId: "preallocRuleDetail.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    PreallocRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.savePreallocRule = function (tab,index) {
                PreallocRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };

            $scope.preallocRuleDetail = function (tab, detail) {
                var rule = tab.data;
                var cdetail = angular.copy(detail);
                if(cdetail) {
                	if(cdetail.zoneIn) {
                		cdetail.zoneIns = cdetail.zoneIn.split(/,/g);
                	} else {
                		cdetail.zoneIns = [];
                	}
                	if(cdetail.zoneEx) {
                		cdetail.zoneExs = cdetail.zoneEx.split(/,/g);
                	} else {
                		cdetail.zoneExs = [];
                	}
                	
                	if (cdetail.orderTypeIn) {
                        cdetail.orderTypeIns = cdetail.orderTypeIn.split(/,/g);
                    } else {
                        cdetail.orderTypeIns = [];
                    }
                    if (cdetail.orderTypeEx) {
                        cdetail.orderTypeExs = cdetail.orderTypeEx.split(/,/g);
                    } else {
                        cdetail.orderTypeExs = [];
                    }
                	
                	if(cdetail.locCategoryIn) {
                		cdetail.locCategoryIns = cdetail.locCategoryIn.split(/,/g);
                	} else {
                		cdetail.locCategoryIns = [];
                	}
                	if(cdetail.locCategoryEx) {
                		cdetail.locCategoryExs = cdetail.locCategoryEx.split(/,/g);
                	} else {
                		cdetail.locCategoryExs = [];
                	}
                	if(cdetail.locStorageTypeIn) {
                		cdetail.locStorageTypeIns = cdetail.locStorageTypeIn.split(/,/g);
                	} else {
                		cdetail.locStorageTypeIns = [];
                	}
                	if(cdetail.locStorageTypeEx) {
                		cdetail.locStorageTypeExs = cdetail.locStorageTypeEx.split(/,/g);
                	} else {
                		cdetail.locStorageTypeExs = [];
                	}
                	cdetail.uoms = cdetail.uom ? cdetail.uom.split(/,/g) : [];
                }
                tab.currentDetail = cdetail || {
                        tenantId: rule.tenantId,
                        warehouseCode: rule.warehouseCode,
                        ruleCode: rule.code
                    };
            };

            $scope.savePreallocRuleDetail = function (tab) {
                if (!tab.currentDetail) return;
                var isNew = !tab.currentDetail.id;
                if(tab.currentDetail.zoneIns) {
            		tab.currentDetail.zoneIn = tab.currentDetail.zoneIns.join(',');
            	} else {
            		tab.currentDetail.zoneIn = '';
            	}
            	if(tab.currentDetail.zoneExs) {
            		tab.currentDetail.zoneEx = tab.currentDetail.zoneExs.join(',');
            	} else {
            		tab.currentDetail.zoneEx = '';
            	}
            	if (tab.currentDetail.orderTypeIns) {
                    tab.currentDetail.orderTypeIn = tab.currentDetail.orderTypeIns.join(',');
                } else {
                    tab.currentDetail.orderTypeIn = '';
                }
                if (tab.currentDetail.orderTypeExs) {
                    tab.currentDetail.orderTypeEx = tab.currentDetail.orderTypeExs.join(',');
                } else {
                    tab.currentDetail.orderTypeEx = '';
                }
            	if(tab.currentDetail.locCategoryIns) {
            		tab.currentDetail.locCategoryIn = tab.currentDetail.locCategoryIns.join(',');
            	} else {
            		tab.currentDetail.locCategoryIn = '';
            	}
            	if(tab.currentDetail.locCategoryExs) {
            		tab.currentDetail.locCategoryEx = tab.currentDetail.locCategoryExs.join(',');
            	} else {
            		tab.currentDetail.locCategoryEx = '';
            	}
            	if(tab.currentDetail.locStorageTypeIns) {
            		tab.currentDetail.locStorageTypeIn = tab.currentDetail.locStorageTypeIns.join(',');
            	} else {
            		tab.currentDetail.locStorageTypeIn = '';
            	}
            	if(tab.currentDetail.locStorageTypeExs) {
            		tab.currentDetail.locStorageTypeEx = tab.currentDetail.locStorageTypeExs.join(',');
            	} else {
            		tab.currentDetail.locStorageTypeEx = '';
            	}
            	tab.currentDetail.uom = tab.currentDetail.uoms ? tab.currentDetail.uoms.join(',') : '';
            	
                PreallocRule.detailSavePost({}, tab.currentDetail, function (resp) {
                	tab.currentDetail = null;
                	PreallocRule.contentGet({id: tab.data.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    });
                	toastr.success('保存成功！', '成功');
                });
            };

            $scope.preallocRuleDetailDel = function (tab, detail) {
                if (!detail || !detail.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + detail.name + '】吗？',
                    ok: function () {
                        PreallocRule.detailDeletePost({id: detail.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentDetail && tab.currentDetail.id === detail.id) {
                                    delete tab.currentDetail;
                                }
                                tab.data.details.splice(tab.data.details.indexOf(detail), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };
        }
    ]);
})();