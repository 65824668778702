(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('AutoController', AutoController);

    AutoController.$inject = ['$rootScope', '$timeout', 'Auto', 'toastr','$interval'];

    function AutoController ($rootScope, $timeout,Auto, toastr,$interval) {
        var vm = this;

        vm.taskBar = {
            config:{
                title: '自动任务监控',
                debug: true,
                stack: false,
            },
            data:[
                {
                name: '任务类型',
                datapoints: [
                    { x: '', y: 0 },
                ]
                }
            ]
        };
        vm.tasks = [];
        vm.getTask = getTask;
        vm.start = start;
        vm.stop = stop;

        //获取任务
        function getTask() {
            getTaskWay().then(function (res) {
                if(res.content && angular.isArray(res.content.data) && res.content.data.length>0){
                    if(res.content.data.length<10){
                        var len = 10-res.content.data.length;
                        for(var j=0;j<len;j++){
                            res.content.data.push({dataName:'', count:0});
                        }
                    }
                    getData(res.content);
                }
            }).catch(function (res) {

            });
        }

        //获取任务---方法
        function getTaskWay() {
            return new Promise(function (resolve,reject) {
                Auto.getMonitingData({},{},function (res) {
                    resolve(res);
                },function (res) {
                    reject(res);
                });
            });
        }

        vm.getTask();

        //生成数据
        function getData(content) {
            //生成图表数据
            var datapoints = [];
            for(var i=0;i<content.data.length;i++){
                var t= {
                    x:content.data[i].dataName,
                    y:content.data[i].count,
                };
                datapoints.push(t);
            }
            $timeout(function () {
                vm.taskBar.data[0].datapoints = [];
                vm.taskBar.data[0].datapoints = datapoints;
            });

            //生成按钮数据
            vm.tasks = content.task;

        }

        //开始
        function start(t) {
            Auto.start({},{
                taskAppCode:t.taskAppCode,
                taskCode:t.taskCode,
                taskStatus:t.taskStatus
            },function (res) {
                toastr.success('开始成功','成功');
                t.running = true;
                for(var k=0;k<vm.tasks.length;k++){
                    if(vm.tasks[k].taskCode == t.taskCode){
                        vm.tasks[k].taskStatus = res.content.taskStatus;
                        break;
                    }
                }
            });
        }

        //暂停
        function stop(t) {
            Auto.stop({},{
                taskAppCode:t.taskAppCode,
                taskCode:t.taskCode,
                taskStatus:t.taskStatus
            },function (res) {
                toastr.success('暂停成功','成功');
                t.running = false;
                for(var k=0;k<vm.tasks.length;k++){
                    if(vm.tasks[k].taskCode == t.taskCode){
                        vm.tasks[k].taskStatus = res.content.taskStatus;
                        break;
                    }
                }
            });
        }

        //改变任务状态
        vm.changeStatus = changeStatus;
        function changeStatus(t) {
            if(!t.running){
                vm.start(t);
            }else{
                vm.stop(t);
            }
        }

        var timer = $interval(function () {
            getTask();
        },600000);

    }
})();
