(function() {
    'use strict';

    angular
        .module('iprintModule')
        .factory('IprintClient', IprintClient);

    IprintClient.$inject = ['$resource', 'iServiceConfig'];

    function IprintClient ($resource, iServiceConfig) {
        var service = $resource('web/clientshow', {}, {
            'find': { url: iServiceConfig.iprintHost + "/web/clientshow/find", method: 'POST'},
            'findclient':{url:iServiceConfig.iprintHost + "/web/clientshow/findclient", method: 'POST'}
           
        });

        return service;
    }
})();
