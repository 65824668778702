(function() {
    'use strict';

    angular.module('iuserModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('edition-maintenance', {
                parent: 'main',
                url: 'user/edition-maintenance',
                data: {
                    'pageTitle': '版本维护'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'user-module/edition/edition.html',
                        controller: 'UserModuleEditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
