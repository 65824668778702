(function() {
    'use strict';

    angular.module('idataModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('warehouseUser-maintenance', {
                parent: 'main',
                url: 'data/warehouseUser-maintenance',
                data: {
                    'pageTitle': '仓库用户'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'data-module/warehouseUser/warehouseUser.html',
                        controller: 'WarehouseUserController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
