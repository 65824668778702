(function() {
    'use strict';

    angular.module('itaskModule')
        .controller('TaskConfigController', TaskConfigController);

    TaskConfigController.$inject = ['$timeout'];

    function TaskConfigController ($timeout) {
        var vm = this;

    }
})();
