(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('lot-rule', {
            parent: 'main',
            url: 'iwh-strategy/lot-rule',
            data: {'pageTitle': '批次属性'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/lotRule/lotRule.html',
                    controller: 'LotRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('LotRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/lot-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/lot-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/lot-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/lot-rule/:id', params: {id: '@id'}, method: 'GET'},
            'contentDetailGet': {url: iServiceConfig.iwhStrategyHost + '/web/lot-rule/detail/:id', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/lot-rule/detail/save', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/lot-rule/detail/delete/:id', params: {id: '@id'}, method: 'POST'},

            'getLotAttributes': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotAttribute.json', method: 'GET'},
            'getDataTypes': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotDataType.json', method: 'GET'},
            'getInDefaultMethods': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotInDefaultMethod.json', method: 'GET'},
            'getOutDefaultMethods': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotOutDefaultMethod.json', method: 'GET'},
            'getComponentTypes': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.LotComponentType.json', method: 'GET'}
        });
    }]);

    app.controller('LotRuleController', [
        '$scope', 'LotRule', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, LotRule, NgTableParams, $timeout, toastr) {
            LotRule.getLotAttributes({}, function (result) {
                $scope.lotAttributes = result.content;
            });
            LotRule.getDataTypes({}, function (result) {
                $scope.dataTypes = result.content;
                $scope.dataTypesMap = {};
                angular.forEach($scope.dataTypes, function(data,index,array){
                	//data等价于array[index]
                	$scope.dataTypesMap[data.value+''] = data.label;
            	});
            });
            LotRule.getInDefaultMethods({}, function (result) {
                $scope.inDefaultMethods = result.content;
            });
            LotRule.getOutDefaultMethods({}, function (result) {
                $scope.outDefaultMethods = result.content;
            });
            LotRule.getComponentTypes({}, function (result) {
                $scope.componentTypes = result.content;
                $scope.componentTypesMap = {};
                angular.forEach($scope.componentTypes, function(data,index,array){
                	//data等价于array[index]
                	$scope.componentTypesMap[data.value+''] = data.label;
            	});
            });

            $scope.ynOptions = [{k: 1, v: '是'}, {k: 0, v: '否'}];
            $scope.dataTypeOptions = [{k: 10, v: '日期'}, {k: 11, v: '规格'}, {k: 12, v: '渠道'}];
            $scope.ioDefaultMethods = {'13': '测试数据', '14': '旧数据', '17': '新数据'};
            $scope.componentTypeOptions = {'NB01': '类型1', 'NB02': '类型2', 'NB03': '类型3', 'NB04': '类型4'};
            $scope.dateFormats = ['yyyyMMdd', 'yyyy-MM-dd', 'yyyy.MM.dd', 'yyyy/MM/dd'];
            $scope.datetimeFormats = ['yyyyMMddHHmmss', 'yyyy-MM-dd HH:mm:ss', 'yyyy.MM.dd HH:mm:ss', 'yyyy/MM/dd HH:mm:ss'];

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };
            
            $scope.lotAttributeChanged = function(currentDetail) {
            	if (currentDetail.fieldCode == 'lotAttribute05') {
            		currentDetail.inDefaultMethod = 'ASN_FIELD';
            		currentDetail.inDefaultValue = 'supplierCode';
            		currentDetail.dataType = 30;
            		currentDetail.maxLength = 60;
            		currentDetail.displayName = '供应商';
            	} else if (currentDetail.fieldCode == 'lotAttribute01') {
            		currentDetail.dataType = 30;
            		currentDetail.maxLength = 60;
            		currentDetail.displayName = '包装代码';
            	} else if (currentDetail.fieldCode == 'lotAttribute02') {
            		currentDetail.dataType = 41;
            		currentDetail.maxLength = 60;
            		currentDetail.dataFormat = 'yyyyMMddHHmmss';
            		currentDetail.displayName = '入库时间';
            	} else if (currentDetail.fieldCode == 'lotAttribute03') {
            		currentDetail.dataType = 40;
            		currentDetail.maxLength = 60;
            		currentDetail.dataFormat = 'yyyyMMdd';
            		currentDetail.displayName = '生产日期';
            	} else if (currentDetail.fieldCode == 'lotAttribute04') {
            		currentDetail.dataType = 40;
            		currentDetail.maxLength = 60;
            		currentDetail.dataFormat = 'yyyyMMdd';
            		currentDetail.displayName = '失效日期';
            	}
            }

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return LotRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.lotRuleContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {lotRuleDetails: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "lotRuleDetail.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    LotRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.lotRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {lotRuleDetails: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '查看',
                    contentId: "lotRuleDetail.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    LotRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.saveLotRule = function (tab,index) {
                LotRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };

            $scope.lotRuleDetail = function (tab, detail) {
                var rule = tab.data;
                if(detail && detail.id) {
                	LotRule.contentDetailGet({id: detail.id}, function (item) {
                        var ddetail = item.content;
                        
                        tab.currentDetail = ddetail || {
                            tenantId: rule.tenantId,
                            warehouseCode: rule.warehouseCode,
                            ruleCode: rule.code,
                            required: 1,
                            display: 1,
                            maxLength: 2,
                            inDefaultMethod:'INPUT',
                            outDefaultMethod:'INPUT',
                            componentType:'INPUT'
                        };
                        
                    });
                } else {
                	tab.currentDetail = detail || {
                        tenantId: rule.tenantId,
                        warehouseCode: rule.warehouseCode,
                        ruleCode: rule.code,
                        required: 1,
                        display: 1,
                        maxLength: 2,
                        inDefaultMethod:'INPUT',
                        outDefaultMethod:'INPUT',
                        componentType:'INPUT'
                    };
                }
            };

            $scope.saveLotRuleDetail = function (tab) {
                if (!tab.currentDetail){
                    return
                }
                var isNew = !tab.currentDetail.id;
                LotRule.detailSavePost({}, tab.currentDetail, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.currentDetail = resp.content;
                        toastr.success('保存成功！', '成功');
                        tab.currentDetail = false;//隐藏新建表单
                        LotRule.contentGet({id: tab.data.id}, function (item) {
                            tab.data = item.content;
                        });
                    }
                });
            };

            $scope.lotRuleDetailDel = function (tab, detail) {
                if (!detail || !detail.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + detail.displayName + '】吗？',
                    ok: function () {
                        LotRule.detailDeletePost({id: detail.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentDetail && tab.currentDetail.id === detail.id) {
                                    delete tab.currentDetail;
                                }
                                LotRule.contentGet({id: tab.data.id}, function (item) {
                                    tab.data = item.content;
                                });
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };
        }
    ]);
})();