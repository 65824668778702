(function()  {
    'use strict';

    angular.module('iwh-receiptModule')
        .controller('ReceiptQueryBySkuController', ReceiptQueryBySkuController);

    ReceiptQueryBySkuController.$inject = ['$rootScope', 'ReceiptQuery', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','enums', 'BaseCode','iServiceConfig', 'ApiURI'];

    function ReceiptQueryBySkuController ($rootScope,  ReceiptQuery, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout, enums, BaseCode,iServiceConfig,ApiURI) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        
        vm.asnStatusEnum = enums('receipt.AsnStatusEnum');
        vm.asnDetailStatusEnum = enums('receipt.AsnDetailStatusEnum');
        vm.receiptDetailStatusEnum = enums('receipt.ReceiptDetailStatusEnum');
        
        vm.baseCodeObjs = BaseCode.codeObjs([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_ORDER_TYPE', keyField: 'WMS_ORDER_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_ORDER_SOURCE', keyField: 'WMS_ORDER_SOURCE'}
		]);
        vm.baseCode = BaseCode.codes([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_ORDER_TYPE', keyField: 'WMS_ORDER_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_ORDER_SOURCE', keyField: 'WMS_ORDER_SOURCE'}
        ]);
        vm.asnTypes = {};
        vm.comeFroms = {};
        vm.baseCode('WMS_ORDER_TYPE').then(function(data) {
            vm.asnTypes = data;
        });      
        vm.baseCode('WMS_ORDER_SOURCE').then(function(data) {
            vm.comeFroms = data;
        });   
        vm.asnTypeList = [];
        vm.comeFromList = [];
        vm.hasAsnType = false;   
	    vm.baseCodeObjs('WMS_ORDER_TYPE').then(function(data) {
	    	angular.forEach(data, function (item) {
	    		vm.asnTypeList.push({ title: item.name, id: item.code });
	        });
	    	vm.hasAsnType = true;
	    });
        function pageTitle() {
            return $window.document.title;
        }

		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				this.httpParams = postParams;
				return ReceiptQuery.sku({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search;
        function search() {
            vm.tableParams.reload();
        }

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
    }
})();
