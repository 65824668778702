(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('ReplenishPendRepRController',
        ReplenishPendRepRController);

    ReplenishPendRepRController.$inject = [ 'PendRepR', 'NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state'];

    function ReplenishPendRepRController(PendRepR, NgTableParams, $uibModal,enums,
                                 $timeout, toastr,$state) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;

        vm.search = search;

        vm.removeTab = removeTab;

        vm.cancel = cancel;
        vm.generate = generate;

        
       

        vm.typeObj = enums('inner.ReplenishPendRepRType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.ReplenishPendRepRStatus');
        vm.status = vm.statusObj.list('title', 'id');
        
        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(tabs,idx,isVm) {
            tabs.splice(idx, 1);
            if(isVm){
                vm.active = idx;
            }
        }
        
        //列表页功能
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return PendRepR.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });



        //取消
        function cancel(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                PendRepR.cancel({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("成功取消", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.error("请先选择待补货记录", "提示");
            }
        }

        //生成补货
        function generate(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                PendRepR.generateReplenishOrder({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("生成成功, 补货单号："+data.content, "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.error("请先选择待补货记录", "提示");
            }
        }


        vm.generate = function(){

            vm.modalInstance = $uibModal.open({
                templateUrl: 'generate.html',
                controller: scConfirmModal2,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:{
                        heading:'生成补货',
                    }
                }
            });
        };
        scConfirmModal2.$inject = ['$uibModalInstance', 'item'];
        function scConfirmModal2($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.ok = ok;
            $ctrl.transferLoc = null;
            $ctrl.allocateRule = null;
            $ctrl.cancel = cancel;
            function ok() {
                if($ctrl.transferLoc == null){
                    toastr.error("请选择补货中间库位！","")
                    return;
                }
                if($ctrl.allocateRule == null){
                    toastr.error("请选择分配规则！","")
                    return;
                }
                var temp=[];

                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
                var vo = {
                    ids : temp,
                    transferLoc : $ctrl.transferLoc,
                    allocateRule : $ctrl.allocateRule,
                }
                if(angular.isArray(temp) && temp.length>0){
                    PendRepR.generateReplenishOrder({},vo,function(resp){
                    }).$promise.then(function(data){
                        toastr.success("生成成功", "成功");
                        $uibModalInstance.close(item);
                        vm.tableParams.reload();
                    });
                }else{
                    toastr.error("请先选择待补货记录", "提示");
                }
            }
            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


    }
})();
