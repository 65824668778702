(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('CountPlan', CountPlan);

    CountPlan.$inject = ['$resource', 'iServiceConfig'];

    function CountPlan ($resource, iServiceConfig) {
        var service = $resource('web/countPlan', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/countPlan/list", method: 'POST'},
            'edit': { url: iServiceConfig.iwhInnerHost + "/web/countPlan/edit", method: 'POST'},
            'getPlanNum': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/getPlanNum", method: 'POST'},
            'saveAndUpdate': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/saveAndUpdate", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/cancel", method: 'POST'},
            'closed': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/closed", method: 'POST'},
            'execute': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/execute", method: 'POST'},
            'getCountPlanLog': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/getCountPlanLog", method: 'POST'},
            'begin': {url: iServiceConfig.iwhInnerHost + "/web/countPlan/begin", method: 'POST'},
        });

        return service;
    }
})();
