(function() {
    'use strict';

    angular.module('ireportNodeModule')
        .controller('RptNodeMainContentController', RptNodeMainContentController);

        RptNodeMainContentController.$inject = ['RptNodeIndexHome', '$interval', '$scope'];

    function RptNodeMainContentController (IndexHome, $interval, $scope) {
        var vm = this;
        vm.sessionStat = null;
        vm.refreshId = null;
        vm.getStat = getStat;
        vm.refresh = refresh;
        
        function getStat() {
        	IndexHome.nodeStat(function(data) {
                vm.sessionStat = data.content;
            });
        }
        
        vm.refreshId = $interval(function() {
        	vm.refresh();
        }, 10000);
        
        function refresh() {
        	vm.getStat();
        }
        
        $scope.$on('$destroy', function() {
            if(vm.refreshId) {
                $interval.cancel(vm.refreshId);
                vm.refreshId = null;
            }
        });
        vm.refresh();
    }
})();
