(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusDataSource', iBusDataSource);

    iBusDataSource.$inject = ['$resource', 'iServiceConfig'];

    function iBusDataSource ($resource, iServiceConfig) {
        var service = $resource('web/ds', {}, {
            'search': { url: iServiceConfig.ibusHost + "/web/ds/list", method: 'POST'},
            'get': { url: iServiceConfig.ibusHost + "/web/ds/:id", method: 'GET'},
            'add': { url: iServiceConfig.ibusHost + "/web/ds/add", method: 'POST'},
            'update': { url: iServiceConfig.ibusHost + "/web/ds/update", method: 'POST'},
            'delete': { url: iServiceConfig.ibusHost + "/web/ds/delete/:id", method: 'POST'}
        });

        return service;
    }
})();
