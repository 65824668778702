(function () {
    'use strict';

    angular.module('idashboardModule')
        .factory('Depotboard', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/depotboard', {}, {
                'getAllWH': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getAllWH',
                    method: 'GET'
                },
                'getReceiptRate': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getReceiptRate',
                    method: 'GET'
                },
                'getLocState': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getLocState',
                    method: 'GET'
                },
                'getShipRate': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getShipRate',
                    method: 'GET'
                },
                'getReceiptAndShipRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getReceiptAndShipRecent',
                    method: 'GET'
                },
                'getReceiptRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getReceiptRecent',
                    method: 'GET'
                },
                'getWarehouseRateInUse': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getWarehouseRateInUse',
                    method: 'GET'
                },
                'getShipRecent': {
                    url: iServiceConfig.idashboardHost + '/web/dashboard/getShipRecent',
                    method: 'GET'
                },
                'getWHReceiptRecent': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getWHReceiptRecent',
                    method: 'GET'
                },
                'getWHShipRecent': {
                    url: iServiceConfig.idashboardHost + '/web/depotboard/getWHShipRecent',
                    method: 'GET'
                }
            });
        }]);
})();