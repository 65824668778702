(function () {
    'use strict';

    angular.module('iplanModule')
        .controller('iPlanDispatchController', iPlanDispatchController);

    iPlanDispatchController.$inject = ['$scope', 'enums', 'iPlanWarehousePlan', 'toastr'];

    function iPlanDispatchController($scope, enums, iPlanWarehousePlan, toastr) {
        var vm = this;
        vm.active = 0;
        vm.planDispatchTypeEnum = enums("plan.PlanDispatchType");
        vm.planTypeEnum = enums("plan.PlanType");
        vm.planStatusEnum = enums("plan.PlanStatus");
        vm.dispatchType = vm.planDispatchTypeEnum.value('IN_CHECK');
        vm.btnDisabled = true;
        vm.data = {
            bean: {},
            data: {},
            condition: null,
            listBean: {},
            list: {}
        };


        vm.listAcrossDispatch = listAcrossDispatch;
        vm.dispatch = dispatch;
        vm.selectPlan = selectPlan;
        vm.conditionChangeEvent = conditionChangeEvent;
        vm.switchTab = switchTab;
        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function getParkLotAndGatewayList(parkCode, callback) {
            if (!!!parkCode) {
                return;
            }
            iPlanWarehousePlan.getParkLotAndGatewayList({parkCode: parkCode}, {}, function (resp) {
                callback(resp.content);
            });
        }

        function getPlatformList(parkCode, warehouseCode, callback) {
            if (!!!parkCode || !!!warehouseCode) {
                return;
            }
            iPlanWarehousePlan.getPlatformList({parkCode: parkCode, warehouseCode: warehouseCode}, {}, function (resp) {
                callback(resp.content);
            });
        }

        function listAcrossDispatch(condition, type) {
            if (!!!condition) {
                return {};
            } else {
                var postParams = {
                    condition: condition,
                    total: 10,
                    type: type
                };
                return iPlanWarehousePlan.listAcrossDispatch({}, postParams).$promise.then(function (resp) {
                    return resp.content;
                });
            }
        }

        function validate() {
            if (!!!vm.data.bean.id) {
                toastr.warning("必须选择预约单后才能操作", "警告");
                return false;
            }
            if (vm.dispatchType == vm.planDispatchTypeEnum.value('IN_CHECK') && !!!vm.data.data.cardNum) {
                toastr.warning("卡号不能为空", "警告");
                return false;
            }
            return true;
        }

        function checkBtn() {
            if (!!!vm.data.bean.id) {
                vm.btnDisabled = true;
                return;
            }
            if (vm.dispatchType == vm.planDispatchTypeEnum.value('IN_CHECK')) {
                if (vm.data.bean.status == vm.planStatusEnum.value('PLANNED')) {
                    vm.btnDisabled = false;
                } else {
                    vm.btnDisabled = true;
                }
            } else if (vm.dispatchType == vm.planDispatchTypeEnum.value('HANDLE')) {
                if (vm.data.bean.status == vm.planStatusEnum.value('IN_CHECKED') ||
                    vm.data.bean.status == vm.planStatusEnum.value('PARKED')) {
                    vm.btnDisabled = false;
                } else {
                    vm.btnDisabled = true;
                }
            } else if (vm.dispatchType == vm.planDispatchTypeEnum.value('PARK')) {
                if (vm.data.bean.status == vm.planStatusEnum.value('IN_CHECKED') ||
                    vm.data.bean.status == vm.planStatusEnum.value('WORKING')) {
                    vm.btnDisabled = false;
                } else {
                    vm.btnDisabled = true;
                }
            } else if (vm.dispatchType == vm.planDispatchTypeEnum.value('OUT_CHECK')) {
                if (vm.data.bean.status == vm.planStatusEnum.value('IN_CHECKED') ||
                    vm.data.bean.status == vm.planStatusEnum.value('WORKING') ||
                    vm.data.bean.status == vm.planStatusEnum.value('PARKED')) {
                    vm.btnDisabled = false;
                } else {
                    vm.btnDisabled = true;
                }
            }
        }

        function dispatch() {
            if (validate()) {
                var data = {
                    id: vm.data.bean.id,
                    type: vm.dispatchType,
                    name: !!vm.data.listBean ? vm.data.listBean.name : null,
                    code: !!vm.data.listBean ? vm.data.listBean.code : null,
                    cardNum: vm.data.data.cardNum
                };
                iPlanWarehousePlan.dispatch({}, data, function (resp) {
                    toastr.info("操作成功", "成功");
                    iPlanWarehousePlan.refreshPlan({id: vm.data.bean.id}, {}, function (resp) {
                        vm.data.bean = resp.content;
                        checkBtn();
                    });
                }, function (err) {
                    toastr.error("操作失败", "错误");
                });
            }
        }

        function selectPlan($item, $model, $label, $event) {
            vm.data.bean = $item;
            checkBtn();
            if (vm.dispatchType == vm.planDispatchTypeEnum.value('IN_CHECK')) {
                if ($item.planNum.startsWith($model.trim())) {
                    vm.data.condition = $item.planNum;
                } else if (!!$item.carrierPlateNum && $item.carrierPlateNum.startsWith($model.trim())) {
                    vm.data.condition = $item.carrierPlateNum;
                } else if (!!$item.carrierPhone && $item.carrierPhone.startsWith($model.trim())) {
                    vm.data.condition = $item.carrierPhone;
                }
            } else {
                vm.data.condition = $item.cardNum;
            }
            if (vm.dispatchType != vm.planDispatchTypeEnum.value('HANDLE')) {
                getParkLotAndGatewayList($item.packCode, function (data) {
                    if (vm.dispatchType == vm.planDispatchTypeEnum.value('IN_CHECK')) {
                        vm.data.list = data.parkGatewayInList;
                        vm.data.data.cardNum = $item.cardNum;
                        vm.data.listBean.name = $item.parkEntranceName;
                        vm.data.listBean.code = $item.packEntranceCode;
                    } else if (vm.dispatchType == vm.planDispatchTypeEnum.value('PARK')) {
                        vm.data.list = data.parkLotList;
                        vm.data.listBean.name = $item.parkingLotName;
                        vm.data.listBean.code = $item.parkingLotCode;
                    } else if (vm.dispatchType == vm.planDispatchTypeEnum.value('OUT_CHECK')) {
                        vm.data.list = data.parkGatewayOutList;
                        vm.data.listBean.name = $item.parkExitName;
                        vm.data.listBean.code = $item.packExitCode;
                    }
                })
            } else {
                getPlatformList($item.packCode, $item.warehouseCode, function (data) {
                    vm.data.list = data;
                    vm.data.listBean.code = $item.platformCode;
                    vm.data.listBean.name = $item.platformName;
                })
            }
        }

        function conditionChangeEvent() {
            vm.data = {
                bean: {},
                data: {},
                condition: vm.data.condition,
                listBean: {},
                list: {}
            };
            checkBtn();
        }

        function switchTab(type) {
            vm.dispatchType = type;
            vm.data = {
                bean: {},
                data: {},
                condition: null,
                listBean: {},
                list: {}
            };
            checkBtn();
        }

        //-----------------------------------------------------------------------//
        //-----------------------------------------------------------------------//
        //-----------------------------------------------------------------------//

        // vm.dispatchList = [
        //     {
        //         active: '1',
        //         heading: '检入',
        //         inputGroup: [
        //             {
        //                 title: '卡号',
        //                 value: ''
        //             },
        //             {
        //                 title: '预约单号/车牌号/手机号',
        //                 value: ''
        //             },
        //         ],
        //         selectGroup: [
        //             {
        //                 title: '检入门',
        //                 value: '',
        //                 lists: [
        //                     {
        //                         id: 1,
        //                         title: '园区1'
        //                     },
        //                     {
        //                         id: 2,
        //                         title: '园区2'
        //                     },
        //                     {
        //                         id: 3,
        //                         title: '园区3'
        //                     },
        //                 ],
        //             }
        //         ],
        //         orderNum: '',
        //         type: '',
        //         park: '',        //园区
        //         enter: '',       //入口
        //         out: '',         //出口
        //         contacts: '',    //联系人
        //         contactNum: '',  //联系人电话
        //         logistic: '',    //物流公司
        //         plateNum: '',    //车牌号
        //         trailerNum: '',  //拖车号
        //         appointStartTime: '',        //预约开始时间
        //         appointEndTime: '',          //预约结束时间
        //         actualStartTime: '',         //实际开始时间
        //         actualEndTime: '',           //实际结束时间
        //     },
        //     {
        //         active: '2',
        //         heading: '装卸',
        //         inputGroup: [
        //             {
        //                 title: '卡号',
        //                 value: ''
        //             },
        //         ],
        //         selectGroup: [
        //             {
        //                 title: '月台',
        //                 value: '',
        //                 lists: [
        //                     {
        //                         id: 1,
        //                         title: '月台1'
        //                     },
        //                     {
        //                         id: 2,
        //                         title: '月台2'
        //                     },
        //                     {
        //                         id: 3,
        //                         title: '月台3'
        //                     },
        //                 ],
        //             }
        //         ],
        //         orderNum: '',
        //         type: '',
        //         park: '',        //园区
        //         enter: '',       //入口
        //         out: '',         //出口
        //         contacts: '',    //联系人
        //         contactNum: '',  //联系人电话
        //         logistic: '',    //物流公司
        //         plateNum: '',    //车牌号
        //         trailerNum: '',  //拖车号
        //         appointStartTime: '',        //预约开始时间
        //         appointEndTime: '',          //预约结束时间
        //         actualStartTime: '',         //实际开始时间
        //         actualEndTime: '',           //实际结束时间
        //     },
        //     {
        //         active: '3',
        //         heading: '停泊',
        //         inputGroup: [
        //             {
        //                 title: '卡号',
        //                 value: ''
        //             },
        //         ],
        //         selectGroup: [
        //             {
        //                 title: '停车场',
        //                 value: '',
        //                 lists: [
        //                     {
        //                         id: 1,
        //                         title: '停车场1'
        //                     },
        //                     {
        //                         id: 2,
        //                         title: '停车场2'
        //                     },
        //                     {
        //                         id: 3,
        //                         title: '停车场3'
        //                     },
        //                 ],
        //             }
        //         ],
        //         orderNum: '',
        //         type: '',
        //         park: '',        //园区
        //         enter: '',       //入口
        //         out: '',         //出口
        //         contacts: '',    //联系人
        //         contactNum: '',  //联系人电话
        //         logistic: '',    //物流公司
        //         plateNum: '',    //车牌号
        //         trailerNum: '',  //拖车号
        //         appointStartTime: '',        //预约开始时间
        //         appointEndTime: '',          //预约结束时间
        //         actualStartTime: '',         //实际开始时间
        //         actualEndTime: '',           //实际结束时间
        //     },
        //     {
        //         active: '4',
        //         heading: '检出',
        //         inputGroup: [
        //             {
        //                 title: '卡号',
        //                 value: ''
        //             },
        //         ],
        //         selectGroup: [
        //             {
        //                 title: '检出门',
        //                 value: '',
        //                 lists: [
        //                     {
        //                         id: 1,
        //                         title: '一号门'
        //                     },
        //                     {
        //                         id: 2,
        //                         title: '二号门'
        //                     },
        //                     {
        //                         id: 3,
        //                         title: '三号门'
        //                     },
        //                 ],
        //             }
        //         ],
        //         orderNum: '',
        //         type: '',
        //         park: '',        //园区
        //         enter: '',       //入口
        //         out: '',         //出口
        //         contacts: '',    //联系人
        //         contactNum: '',  //联系人电话
        //         logistic: '',    //物流公司
        //         plateNum: '',    //车牌号
        //         trailerNum: '',  //拖车号
        //         appointStartTime: '',        //预约开始时间
        //         appointEndTime: '',          //预约结束时间
        //         actualStartTime: '',         //实际开始时间
        //         actualEndTime: '',           //实际结束时间
        //     },
        // ];
        //
        //
        // vm.search = search;
        // vm.confirm = confirm;     //确定
        //
        //
        // function confirm(e) {
        //     console.log(e)
        // }
        //
        // function search() {
        //     console.log(1)
        // }
    }
})();
