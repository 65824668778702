(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('CountOrderController',
        CountOrderController);

    CountOrderController.$inject = [ 'CountOrder','CountDifference', 'NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state','Principal'];

    function CountOrderController(CountOrder,CountDifference, NgTableParams, $uibModal,enums,
                                 $timeout, toastr,$state,Principal) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;

        vm.search = search;

        vm.removeTab = removeTab;

        vm.detail = detail;//查看详细信息

        vm.countDetail = countDetail;//盘点明细

        vm.upDateDetail = upDateDetail;//盘点明细刷新

        vm.handOut = handOut;//发放

        vm.handOutOne = handOutOne;//发放单个

        vm.cancel = cancel;//取消

        vm.count = count;//盘点

        vm.finish = finish;//完成

        vm.publish = publish;//发布

        vm.reCount=reCount;//复盘

        vm.diffCount=diffCount;//差异复盘

        vm.diff = diff;//差异
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.formatDateTime =formatDateTime;
        vm.reportCallback = reportCallback;

        vm.typeObj = enums('inner.CountPlanType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.CountOrderStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.yesOrNoObj = enums('inner.YesOrNo');
        vm.yesOrNo = vm.yesOrNoObj.list('title', 'id');

        vm.detailStatusObj = enums('inner.CountOrderDetailStatus');
        vm.detailStatus = vm.detailStatusObj.list('title', 'id');

        vm.recordStatusObj = enums('inner.CountOrderRecordStatus');
        vm.recordStatus = vm.recordStatusObj.list('title', 'id');

        vm.diffStatusObj = enums('inner.DiffStatus');
        vm.diffStatus = vm.diffStatusObj.list('title', 'id');
        
        vm.buildParam = function buildParam() {
            return {

            };
        }
        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(tabs,idx,isVm) {
            tabs.splice(idx, 1);
            if(isVm){
                vm.active = idx;
            }
        }

        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        //列表页功能
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return CountOrder.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        //查看详细信息
        function detail(row){
            
            console.log(row);
            var entity = {
                heading : '查看详情'+'【'+row.countNum+'】',
                contentId : "detail.html",
                tableParams:new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.countNum = row.countNum;
                        return CountOrder.getCountOrderDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                data:row,
                active : true,
                activeDetail :0,
                tabs:[]
            };
            vm.tabs.push(entity);
            console.log(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
                
                vm.tabs[vm.tabs.length-1].tableParams.reload();
                console.log(vm.tabs[vm.tabs.length-1]);
            },0);
        }

        //盘点明细
        function countDetail(t,row){
            var vo={
                countNum : row.countNum,
                countLineNum : row.countLineNum
            };
           CountOrder.getCountOrderRecord({}, vo, function(resp) {
            }).$promise.then(function(data) {
                var entity ={
                    heading:'【'+row.countLineNum+'】行的盘点记录',
                    contentId:"countDetail.html",
                    data:data.content,
                    obj:vo,
                    active:true
                };
                t.tabs.push(entity);
                $timeout(function(){
                    t.activeDetail = t.tabs.length;
                },0);
            });
        }

        //刷新盘点明细
        function upDateDetail(a){
            CountOrder.getCountOrderRecord({}, a.obj, function(resp) {
            }).$promise.then(function(data) {
                a.data = data.content;
            });
        }

        //发放
        function handOut(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }
            if(angular.isArray(temp) && temp.length>0){
                CountOrder.handOut({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("发放成功", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择盘点单", "提示");
            }
        }

        function handOutOne(t) {
            CountOrder.handOutOne({},t.data.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("发放成功", "成功");
                t.data=data.content;
                t.tableParams.reload();
                vm.tableParams.reload();
            });
        }

        //取消
        function cancel(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                CountOrder.cancel({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("成功取消", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择盘点单", "提示");
            }
        }

        //盘点
        function count(row) {
            CountOrder.count({},row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("可以开始盘点", "成功");
                vm.tableParams.reload();
            });
        }

        //完成
        function finish(row) {
            CountOrder.finish({},row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("完成", "成功");
                vm.tableParams.reload();
            });
        }

        //发布
        function publish(row) {
            CountOrder.publish({},row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("发布成功", "成功");
                vm.tableParams.reload();
            });
        }

        //复盘
        function reCount(row) {
            CountOrder.reCount({},row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("可以开始复盘", "成功");
                vm.tableParams.reload();
                row.status = 40;
            });
        }

        //差异复盘
        function diffCount(row) {
            CountOrder.diffCount({},row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("可以开始差异复盘", "成功");
                vm.tableParams.reload();
            });
        }

        // 查看差异
        function diff(countNum){
            CountOrder.diff({},countNum,function(resp){
            }).$promise.then(function(data){
                if(data.content=='noDiff'){
                    toastr.info("此盘点单无差异生成","提示：");
                    return;
                }
                var entity = {
                    heading : '差异明细'+'【'+countNum+'】',
                    contentId : "diff.html",
                    tableParams:new NgTableParams({
                        count : 10
                    }, {
                        getData : function(params) {
                            var postParams = angular.merge({
                                pn : params.page(),
                                ps : params.count()
                            }, params.filter());
                            postParams.diffNum = data.content.diffNum;
                            return CountDifference.findCountDifferenceDetailPage({}, postParams, function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                        }
                    }),
                    data: data.content,
                    active : true,
                    activeDetail :0,
                    tabs:[]
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        vm.getData = function(a){
            console.log(a);
        }
// 报表回调
        function reportCallback($reportCode, data){
        	if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (data) {
            	var param = {};
                param = {
                    tenantId: data.tenantId,
                    warehouseCode: data.warehouseCode,
                    countNum: data.countNum
                }
                return param;
            } else {
            	var param = [];
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            countNum : list[i].countNum
                        });
                    }
            	}
                return param;
            }
        }

    }
})();
