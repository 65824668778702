(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('CountOrder', CountOrder);

    CountOrder.$inject = ['$resource', 'iServiceConfig'];

    function CountOrder ($resource, iServiceConfig) {
        var service = $resource('web/countOrder', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/countOrder/list", method: 'POST'},
            'handOut': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/handOut", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/cancel", method: 'POST'},
            'count': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/count", method: 'POST'},
            'reCount': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/reCount", method: 'POST'},
            'diffCount': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/diffCount", method: 'POST'},
            'finish': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/finish", method: 'POST'},
            'publish': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/publish", method: 'POST'},
            'diff': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/diff", method: 'POST'},
            'getCountOrderDetailPage': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/getCountOrderDetailPage", method: 'POST'},
            'getCountOrderRecord': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/getCountOrderRecord", method: 'POST'},
            'scanCountNum': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/scanCountNum", method: 'POST'},
            'scanZoneCode': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/scanZoneCode", method: 'POST'},
            'finishCount': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/finishCount", method: 'POST'},
            'handOutOne': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/handOutOne", method: 'POST'},
            'scanZoneCodeForLoc': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/scanZoneCodeForLoc", method: 'POST'},
            'countPaper': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/countPaper", method: 'POST'},
            'getForCountByCountNum': {url: iServiceConfig.iwhInnerHost + "/web/countOrder/getForCountByCountNum", method: 'POST'}
        });

        return service;
    }
})();
