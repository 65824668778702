(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('PendRepR', PendRepR);

    PendRepR.$inject = ['$resource', 'iServiceConfig'];

    function PendRepR ($resource, iServiceConfig) {
        var service = $resource('web/replenishPend', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/replenishPend/list", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/replenishPend/cancel", method: 'POST'},
            'generateReplenishOrder': {url: iServiceConfig.iwhInnerHost + "/web/replenishPend/generateReplenishOrder", method: 'POST'},

        });

        return service;
    }
})();
