(function () {
    'use strict';
    var app = angular.module('iwh-receiptModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('Receiptunlock', {
            parent: 'main',
            url: 'iwh-receipt/unlock',
            params: {
                'ownerCode': null,
                'postParams': null
            },
            data: {
                'pageTitle': '事务解锁',
                'ownerCode' : null,
                'postParams': null,
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-receipt-module/unlock/unlock.html',
                    controller: 'ReceiptUnlockController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

