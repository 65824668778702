(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("ReceiptPaper", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhReceiptHost + "/web/receiptPaper", {}, {
            'getAsnInfo': { url: iServiceConfig.iwhReceiptHost + "/web/receiptPaper/getAsnInfo", method: 'POST'},
            'validateLocationExist' : { url: iServiceConfig.iwhReceiptHost + "/web/receiptPaper/validateLocationExist", method: 'POST'},
            'receiptPC' : { url: iServiceConfig.iwhReceiptHost + "/web/receiptPaper/receiptPC", method: 'POST'},
            'receiptAllByAsn' : { url: iServiceConfig.iwhReceiptHost + "/web/receiptPaper/receiptAllByAsn", method: 'POST'},
            'checkSerialNumAvailable' : { url: iServiceConfig.iwhReceiptHost + "/web/receiptPaper/checkSerialNumAvailable", method: 'POST'}
        });
    }]);
})();