(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('MovePlanController',
        MovePlanController);

    MovePlanController.$inject = [ 'MovePlan','MovePlanTask', 'NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state','Principal'];

    function MovePlanController(MovePlan,MovePlanTask, NgTableParams, $uibModal,enums,
                                  $timeout, toastr,$state,Principal) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.params = {};
        vm.reloadTable = reloadTable;
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.typeObj = enums('inner.MovePlanType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.MovePlanStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.detailStatusObj = enums('inner.MovePlanDetailStatus');
        vm.detailStatus = vm.detailStatusObj.list('title', 'id');

        vm.taskStatusObj = enums('inner.MovePlanTaskStatus');
        vm.taskStatus = vm.taskStatusObj.list('title', 'id');

        vm.moveAll = moveAll;

        vm.search = search;

        vm.removeTab = removeTab;

        vm.formatDateTime =formatDateTime;//日期转换

        vm.create = create;//新增

        vm.edit = edit;//编辑

        vm.save = save;//保存

        // vm.addDetail = addDetail;//添加明细

        vm.saveAndUpdateDetail = saveAndUpdateDetail;//保存明细

        vm.generateMoveTask = generateMoveTask;//生成移动

        vm.handOut = handOut;//发放

        vm.cancel = cancel;//取消

        vm.delDetail =delDetail;//删除明细单

        vm.cancelTask = cancelTask;//取消移动任务
        vm.processStatusObj = enums('inner.MovePlanProcessStatus');
        vm.processStatus = vm.processStatusObj.list('title', 'id');
        vm.buildParam = function buildParam(data){
        	if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (data) {
            	var param = {};
                param = {
                    tenantId: data.tenantId,
                    warehouseCode: data.warehouseCode,
                    moveNum: data.moveNum,
                    operatorName : vm.userData.realName
                }
                return param;
            } else {
            	var param = [];
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            moveNum : list[i].moveNum
                        });
                    }
            	}
                return param;
            }
        }
        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(tabs,idx,isVm) {
            tabs.splice(idx, 1);
            if(isVm){
                vm.active= idx;
            }
        }

        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        //列表页功能
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return MovePlan.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        //新建
        function create(){
            MovePlan.getMoveNum({},{},function(resp){
            }).$promise.then(function(data){
                var entity = {
                    isCreate:true,
                    moveType:vm.moveType,
                    heading : '新建',
                    contentId : "edit.html",
                    data : {
                        moveNum:data.content,
                        moveLineNum:null,
                        type: 10,
                        status:0,
                        planStartTime:new Date(),
                        planEndTime:new Date(),
                        actStartTime:null,
                        actEndTime:null,
                        generatorName:null,
                        generateTime:null,
                        releaserName:null,
                        releaseTime:null,
                        entryName:null,
                        entryDt:null,
                        updateName:null,
                        updateDt:null,
                        remark:null
                    },
                    active : true
                };
                entity.planTableParams = new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.moveNum = entity.data.moveNum?entity.data.moveNum:null;
                        postParams.moveLineNum = entity.data.moveLineNum?entity.data.moveLineNum:null;
                        return MovePlan.findMovePlanDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                entity.taskTableParams = new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.moveNum = entity.data.moveNum?entity.data.moveNum:null;
                        return MovePlan.findMovePlanTaskPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                });
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

       function reloadTable(tableParams){
           tableParams.reload();
        }

        //编辑
        function edit(row){
            var entity = {
                isCreate:false,
                heading : '编辑【'+row.moveNum+'】',
                contentId : "edit.html",
                moveType:vm.moveType,
                planTableParams:new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.moveNum = entity.data.moveNum?entity.data.moveNum:null;
                        postParams.moveLineNum = entity.data.moveLineNum?entity.data.moveLineNum:null;
                        return MovePlan.findMovePlanDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                taskTableParams:new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.moveNum = entity.data.moveNum?entity.data.moveNum:null;
                        return MovePlan.findMovePlanTaskPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                tabs:[],
                activeCount:0,
                data:row,
                active : true
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        //保存
        function save(t,index,form){
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(!t.data.type){
                toastr.info("请先选择移动类型", "提示");
                return;
            }
            if(!t.data.transferLoc){
                toastr.info("请先选择中间库位", "提示");
                return;
            }
            if(!t.data.planStartTime){
                toastr.info("请先选择计划开始时间", "提示");
                return;
            }
            if(!t.data.planEndTime){
                toastr.info("请先选择计划完成时间", "提示");
                return;
            }



            MovePlan.saveAndUpdate({},t.data,function(resp){
            }).$promise.then(function(data){
                t.isCreate?toastr.success("新增成功", "成功"):toastr.success("保存成功", "成功");
                t.isCreate = false;
                t.data = data.content;
            });
        }

        // // 添加明细
        // function addDetail(t) {
        //     var entity = {
        //         heading:'添加计划明细',
        //         contentId : "addDetail.html",
        //         type:t.data.type,//应该是t.data.type
        //         status:1,
        //         tabs:t.tabs,
        //         storageObj:{},
        //         data:{
        //             moveNum:t.data.moveNum,
        //             moveLineNum:t.data.moveLineNum,
        //             fromZone:null,
        //             fromLoc:null,
        //             toZone:null,
        //             toLoc:null,
        //             ownerCode:null,
        //             skuCode:null,
        //             planQty:null,
        //             toLpn:null,
        //             toCase:null
        //         },
        //         active:true
        //     };
        //     vm.params.toZone=entity.data.toZone;
        //     t.tabs.push(entity);
        //     $timeout(function(){
        //         t.activeCount = t.tabs.length+1;
        //     },0);
        // }

        //保存添加的明细
        function saveAndUpdateDetail(a){
            var data= a.data;

            var d = angular.copy(data);

            //循环将对象中的数组隐式转换成字符串
            for(var key in d){
                if(angular.isArray(d[key])){
                    d[key] = d[key]+'';
                }
            }

            MovePlan.saveAndUpdateDetail({},d,function(resp){
            }).$promise.then(function(data){
                a.isEdit?toastr.success('保存成功','成功'): toastr.success("添加成功", "成功");
                a.row.planTableParams.reload();
            });
        }

         //生成移动
        function generateMoveTask(row,t) {
            var temp=[];
            if(row && row.id){
                if(row.processStatus==10){
                    toastr.error("该单正在执行请稍后再试！");
                    return;
                }
                temp.push(row.moveNum);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].processStatus==10){
                            toastr.error("有单据正在执行,单号为："+list[i].moveNum);
                            return;
                        }
                        if(list[i].id){
                            temp.push(list[i].moveNum);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                MovePlan.generateMoveTask({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("", "已开始异步执行，请稍后刷新页面查看执行结果");
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择", "提示");
            }
        }

        //发放
        function handOut(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else {
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].id) {
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                MovePlan.handOut({},temp,function(resp){
                }).$promise.then(function(data){
                    if(data.content!=null){
                        var s = "以下为发放失败的单,单号为："+'<br> '+data.content;
                        toastr.error(s,"");
                        return;
                    }
                    toastr.success("发放成功", "成功");
                    vm.tableParams.reload();
                    if(row){
                        row.status = 20;
                    }
                });
            }else{
                toastr.info("请先选择", "提示");
            }
        }

        //取消
        function cancel(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else {
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].id) {
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                MovePlan.cancel({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("取消成功", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择", "提示");
            }
        }

        //删除明细单
        function  delDetail(row,t) {
            MovePlan.delDetail({},row.id,function(resp){
            }).$promise.then(function(data){
                toastr.success("删除成功", "成功");
                vm.tableParams.reload();
                t.planTableParams.reload();

            });
        }

        //整单移动
        // function cancelTask(row) {
        //     MovePlan.cancelTask({},row.id,function(resp){
        //     }).$promise.then(function(data){
        //         toastr.success("取消成功", "成功");
        //         t.taskTableParams.reload();
        //     });
        // }
        function moveAll(moveNum,t) {
                MovePlanTask.moveAll({},moveNum,function (resp) {
                }).$promise.then(function (data) {
                    if(data.content!=null){
                        toastr.error(data.content,"提示");
                        t.taskTableParams.reload();
                    }else {
                        toastr.success("移动成功", "成功");
                        vm.tableParams.reload();
                        t.planTableParams.reload();
                        t.taskTableParams.reload();
                        vm.tableParams.reload();
                    }

                });

        }

        //取消移动任务
        function cancelTask(t,row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else {
                if(angular.isFunction(t.taskTableParams.getSelected)){
                    var list = t.taskTableParams.getSelected();
                    for (var i = 0; i < list.length; i++) {
                        if (list[i].id) {
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                MovePlan.cancelTask({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("取消成功", "提示");
                    t.taskTableParams.reload();
                });
            }else{
                toastr.info("请先选择移动任务", "成功");
            }
        }

        //添加明细弹出框
        vm.editOrAddDetail = function(t,row){
            var d = {};
            var isEdit = false;
            var status = 1;
            var scValueField = ['ownerCode','skuCode','lot','locCode','lpn','caseNum'];
            if(row){
                d = angular.copy(row);
                isEdit = true;
                status  = d.fromLoc?2:1;
                var scValue = {
                    ownerCode:d.ownerCode,
                    skuCode:d.skuCode,
                    lot:d.lot,
                    locCode:d.fromLoc,
                    lpn:d.fromLpn,
                    caseNum:d.fromCase
                };
            }else{
                d = {
                    moveNum:t.data.moveNum,
                    moveLineNum:t.data.moveLineNum,
                    fromZone:null,
                    fromLoc:null,
                    toZone:null,
                    toLoc:null,
                    ownerCode:null,
                    skuCode:null,
                    planQty:null,
                    toLpn:null,
                    toCase:null
                }
            }



            vm.modalInstance = $uibModal.open({
                templateUrl: 'addDetail.html',
                controller: scConfirmModal,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:{
                        scValue:scValue,
                        scValueField:scValueField,
                        heading:'添加计划明细',
                        isEdit:isEdit,
                        // type:t.data.type,//应该是t.data.type
                        status:status,
                        storageObj:{},
                        row:t,
                        data:d
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
                $timeout(function () {
                    vm.saveAndUpdateDetail(item);
                }, 0);
            });
        };
        scConfirmModal.$inject = ['$uibModalInstance', 'item'];
        function scConfirmModal($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            function ok() {
                //如果是按库位移动
                if($ctrl.item.row.data.type===20){
                    if($ctrl.item.status === 1 && !$ctrl.item.data.fromZone){
                        toastr.info("请先选择库区", "提示");
                        return;
                    }
                    if($ctrl.item.status === 2  && !$ctrl.item.data.fromLoc){
                        toastr.info("请先选择库位", "提示");
                        return;
                    }
                    if(!$ctrl.item.data.toZone){
                        toastr.info("请先选择库区", "提示");
                        return;
                    }
                }

                //如果是按商品移动
                if($ctrl.item.row.data.type===30){
                    if(!$ctrl.item.data.ownerCode){
                        toastr.error("请先选择货主", "提示");
                        return;
                    }
                    if(!$ctrl.item.data.skuCode){
                        toastr.error("请先选择商品", "提示");
                        return;
                    }
                    if(!$ctrl.item.data.toZone){
                        toastr.error("请先选择库区", "提示");
                        return;
                    }
                }

                //如果是按库存移动
                if($ctrl.item.row.data.type===10){
                    if(!$ctrl.item.storageObj.ownerCode){
                        toastr.error("请先选择移动库存", "提示");
                        return;
                    }
                    if($ctrl.item.data.planQty){
                        if($ctrl.item.data.planQty<=0){
                            toastr.error("计划移动数量必须大于0", "提示");
                            return;
                        }else if($ctrl.item.data.planQty-$ctrl.item.storageObj.availableQty>0){
                            toastr.error("计划数量不能大于可移动数量","提示");
                            return;
                        }else{
                            if(!($ctrl.item.data.planQty%1 === 0)){
                                toastr.error("计划移动数量必须为正整数", "提示");
                                return;
                            }
                        }
                    }else {
                        toastr.error("计划移动数量不能为空！", "提示");
                        return;
                    }

                    if(!$ctrl.item.data.toZone){
                        toastr.error("请先选择库区", "提示");
                        return;
                    }
                    $ctrl.item.data.skuCode = $ctrl.item.storageObj.skuCode?$ctrl.item.storageObj.skuCode:null;
                    $ctrl.item.data.ownerCode = $ctrl.item.storageObj.ownerCode?$ctrl.item.storageObj.ownerCode:null;
                    $ctrl.item.data.lot = $ctrl.item.storageObj.lot?$ctrl.item.storageObj.lot:null;
                    $ctrl.item.data.fromLoc = $ctrl.item.storageObj.locCode?$ctrl.item.storageObj.locCode:null;
                    $ctrl.item.data.fromLpn = $ctrl.item.storageObj.lpn?$ctrl.item.storageObj.lpn:null;
                    $ctrl.item.data.fromCase = $ctrl.item.storageObj.caseNum?$ctrl.item.storageObj.caseNum:null;
                }

                $uibModalInstance.close(item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
