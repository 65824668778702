(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('InvPendPaOccupy', InvPendPaOccupy);

    InvPendPaOccupy.$inject = ['$resource', 'iServiceConfig'];

    function InvPendPaOccupy ($resource, iServiceConfig) {
        var service = $resource('web/invPendPaOccupy', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/invPendPaOccupy/list", method: 'POST'},

        });
        return service;
    }
})();
