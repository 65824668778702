(function() {
    'use strict';

    angular.module('ireportNodeModule')
        .controller('RptNodeReportController', RptNodeReportController);

        RptNodeReportController.$inject = ['RptNodeReportService', 'NgTableParams','$uibModal', '$q','$timeout', 'iServiceConfig'];

    function RptNodeReportController(ReportService, NgTableParams, $uibModal, $q, $timeout, iServiceConfig) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.search = search;
        vm.removeTab = removeTab;
        vm.ireportNodeHost = iServiceConfig.ireportNodeHost;
        vm.active = 0;
        vm.typeList = [{'id':1, 'title':'JasperReport'},{'id':2, 'title':'Birt'},{'id':3, 'title':'自定义'}];
        vm.dsTypes=[{'id':null, 'title':''}, {'id':1, 'title':'DB数据源'},{'id':2, 'title':'JSON数据源'}];
        
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return ReportService.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });
        function showError(resp) {
        	if(resp.status === 'ERROR') {
        		alert(resp.errorMsg);
        		return true;
        	} 
        	return false;
        }

        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
    }
})();
