(function() {
    'use strict';

    angular.module('doveModule')
        .controller('iBusDataSourceController', iBusDataSourceController);

    iBusDataSourceController.$inject = ['iBusDataSource', 'NgTableParams','$uibModal', 'iBusDataSourceRunning', 'iBusProject', '$q','$timeout'];

    function iBusDataSourceController(iBusDataSource, NgTableParams, $uibModal, iBusDataSourceRunning, iBusProject, $q, $timeout) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.projectMap = {};
        vm.projects = [];
        vm.displayed = [];
        vm.isLoading  = false;
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'新建'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.update = update;
        vm.deleteDataSource = deleteDataSource;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.initDatabase = initDatabase;
        vm.stopDatabase = stopDatabase;
        vm.stopAll = stopAll;
        vm.initAll = initAll;
        vm.searchProjects = searchProjects;
        vm.initSelect = initSelect;
        vm.stopSelect = stopSelect;
        vm.active = 0;

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusDataSource.search({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function initSelect() {
            var selected = vm.tableParams.getSelected();
            var codes = jQuery.map(selected, function(item) {
                return item.code;
            });

            iBusDataSourceRunning.initSelect({}, codes, function(resp) {
                vm.search();
                alert("共 "+resp.content.total+" 个数据源，成功启动 "+resp.content.start+" 个数据源");
            });
        }

        function stopSelect() {
            var selected = vm.tableParams.getSelected();
            var codes = jQuery.map(selected, function(item) {
                return item.code;
            });

            iBusDataSourceRunning.closeSelect({}, codes, function(resp) {
                vm.search();
                alert("共 "+resp.content.total+" 个数据源，成功停止 "+resp.content.stop+" 个数据源");
            });
        }

        function searchProjects() {
            var deferred = $q.defer();

            var postParams = {pn: 1, ps: 999};
            iBusProject.search({}, postParams, function(resp) {
                var projects = jQuery.map(resp.content.datas, function(project) {
                    return {'id': project.code, 'title': project.name};
                });

                angular.forEach(projects, function(project) {
                    vm.projectMap[project.id] = project.title;
                });

                vm.projects = projects;

                deferred.resolve(vm.projects);
            });

            return deferred;
        }

        function initDatabase(datasource) {
            iBusDataSourceRunning.init({}, {code: datasource.code}, function() {
                vm.search();
            });
        }

        function initAll(data) {
        	iBusDataSourceRunning.initAll({}, {}, function(resp) {
                vm.search();
                alert("共 "+resp.content.total+" 个数据源，成功启动 "+resp.content.start+" 个数据源");
            });
        }

        function stopDatabase(datasource) {
            iBusDataSourceRunning.close({}, {code: datasource.code}, function(result) {
                vm.search();
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function deleteDataSource(row){
            iBusDataSource.delete({id: row.id}, {}, function() {
                vm.search();
            });
        }

        function edit(row) {
            var entity = {
                heading: '编辑',
                contentId: "datasource-eidt.html",
                data: row,
                roles: [],
                active: true
            };

            vm.tabs.push(entity);
            
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function create() {
            var entity = {
                heading: '创建',
                contentId: "datasource-eidt.html",
                data: {
                    code: "",
                    name: "",
                    driverClassName: "",
                    jdbcUrl: "",
                    userName: "",
                    password: "",
                    maxActive: "",
                    minIdle: "",
                    validationSql: "",
                    status: ""
                },
                roles: [],
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function update(tab, idx, form) {
            var datasource = tab.data;
            form.passwordUnmatch  = "";

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            if (!!!datasource.id) {
                iBusDataSource.add({}, datasource, function() {
                    vm.removeTab(idx);
                });
            } else {
                iBusDataSource.update({}, datasource, function() {
                    vm.removeTab(idx);
                });
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteDataSource(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function stopAll() {
        	iBusDataSourceRunning.closeAll({}, {}, function(resp) {
                vm.search();
                alert("共 "+resp.content.total+" 个数据源，成功停止 "+resp.content.stop+" 个数据源");
            });
        }
    }
})();
