(function() {
    'use strict';

    angular.module('iwh-receiptModule').controller('ReceiptPutawayController', ReceiptPutawayController);
    ReceiptPutawayController.$inject = ["$rootScope", "$scope", "NgTableParams",
        "$uibModal", "NgSelect", "$state", "$timeout", "toastr", "NgTree", "ReceiptPutaway",
        "$window", "iServiceConfig", "$httpParamSerializer", "enums", "ReceiptPrint",'Principal'];
    function ReceiptPutawayController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                           $state, $timeout, toastr, NgTree, ReceiptPutaway, $window,
                               iServiceConfig, $httpParamSerializer, enums, ReceiptPrint,Principal) {
        var vm = this;
        vm.tabs = [];                   // 记录所有的tab
        vm.active = 0;                  // 总共打开tab的总数

        var key = {
            PUTAWAY : "receipt.ReceiptPutawayOrderStatusEnum",
            DETAIL : "receipt.ReceiptPutawayOrderDetailStatusEnum"
        };

        // vm.constant.detail
        vm.constant = {
            putaway : {
                list : enums(key.PUTAWAY).list('title', 'id'),
                NEW : enums(key.PUTAWAY).value("NEW"),              // 创建
                COMPUTED : enums(key.PUTAWAY).value("COMPUTED"),    // 已计算库位
                ISSUE : enums(key.PUTAWAY).value("ISSUE"),          // 已发放
                DOING : enums(key.PUTAWAY).value("DOING"),          // 上架中
                COMPLETED : enums(key.PUTAWAY).value("COMPLETED"),  // 上架完成
                CANCEL : enums(key.PUTAWAY).value("CANCEL")         // 取消
            },
            detail : {
                list : enums(key.DETAIL).list('title', 'id'),
                NEW : enums(key.DETAIL).value("NEW"),               // 创建
                COMPUTED : enums(key.DETAIL).value("COMPUTED"),     // 已计算库位
                ISSUE : enums(key.DETAIL).value("ISSUE"),           // 已发放
                COMPLETED : enums(key.DETAIL).value("COMPLETED"),   // 已上架
                CANCEL : enums(key.DETAIL).value("CANCEL")          // 取消
            }
        };

        vm.refreshPutawayOrderList = refreshPutawayOrderList; // 刷新按钮点击处理
        vm.editPutawayOrder = editPutawayOrder; // 编辑上架单
        vm.putaway = putaway; // 上架操作
        vm.batchPutaway = batchPutaway; // 批量上架操作
        vm.print = print; // 打印上架单
        vm.batchPrint = batchPrint; // 批量打印
        vm.removeTab = removeTab; // 删除指定下标的tab页
        vm.reGeneratePutaway = reGeneratePutaway; // 重新计算上架
        vm.delPutawayDetail = delPutawayDetail; // 删除给定的上架单明细（只能删除处于“创建”状态的明细）
        vm.canclePutaway = canclePutaway;
        vm.canclePutawayBatch = canclePutawayBatch;
        vm.canclePutawayDetail = canclePutawayDetail;
        vm.grantPutway = grantPutway;
        vm.toggle = toggle;


        function toggle(row,o){
            row[o] = !row[o];
        }

        vm.userData = {};
        // 获取用户数据
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        
        vm.buildParam = function buildParam(putawayNum) {
        	var param = null;
            if ( undefined == vm.userData ) {
                toastr.error("获取用户登录信息失败");
            }
            if (putawayNum) {
            	param = {
            			operatorName: vm.userData.realName,
                        printUser : vm.userData.realName,
                        tenantId : vm.userData.tenantId,
                        warehouseCode : vm.userData.warehouseCode,
                        putawayNum : putawayNum
                    };
            } else {
            	var list = vm.putawayOrderNgTable.getSelected();
            	if (list.length > 0) {
                    param = [];
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                        	printUser : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            putawayNum : list[i].putawayNum
                        });
                    }
            	}
            }
            return param;
        }
        vm.putawayOrderNgTable = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return ReceiptPutaway.getPutawayOrderList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });


        // 移除指定下标的tab
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        // 发放确认
        function grantPutway(row, scope) {
            if ( undefined == row ) {
                toastr.error("请选择上架单");
                return;
            }
            ReceiptPutaway.grantPutway({}, {
                putawayNum : row.putawayNum
            }, function(resp){
                toastr.success("发放上架单成功");
                scope.refreshPutawayOrderList();
            });
        }

        // 取消指定上架单明细
        function canclePutawayDetail(row, scope) {
            ReceiptPutaway.canclePutawayDetail({}, {
                putawayNum : row.putawayNum,
                lineNum : row.lineNum
            }, function(resp){
                toastr.success("取消上架单成功");
                scope.refreshTable(scope);
            });
        }

        // 取消单个上架
        function canclePutaway(row, scope) {
            if ( undefined == row ) {
                toastr.error("请选择上架单");
                return;
            }
            ReceiptPutaway.canclePutaway({}, {
                putawayNum : row.putawayNum
            }, function(resp){
                toastr.success("取消上架单成功");
                scope.refreshPutawayOrderList();
            });
        }

        // 取消批量上架
        function canclePutawayBatch(scope) {
            var selectedObj = scope.putawayOrderNgTable.getSelected();
            if ( !(selectedObj instanceof Array) || selectedObj.length <= 0 ) {
                toastr.error("请勾选上架单");
                return;
            }
            var params = [], errorMsg=[];
            for (var i = 0, len = selectedObj.length; i < len; i++ ) {
                var obj = selectedObj[i];
                // “取消” 、“上架完成”、“上架中”、“发放” 不允许取消
                if ( obj.status == scope.constant.putaway.CANCEL ) {
                    errorMsg.push("上架单[" + obj.putawayNum + "]已取消，不能执行取消<br>");
                    continue;
                } else if ( obj.status == scope.constant.putaway.COMPLETED ) {
                    errorMsg.push("上架单[" + obj.putawayNum + "]已上架完成，不能执行取消<br>");
                    continue;
                } else if ( obj.status == scope.constant.putaway.DOING ) {
                    errorMsg.push("上架单[" + obj.putawayNum + "]为上架中，不能执行取消<br>");
                    continue;
                } else if ( obj.status == scope.constant.putaway.ISSUE ) {
                    errorMsg.push("上架单[" + obj.putawayNum + "]已发放，不能执行取消<br>");
                    continue;
                }

                params.push({
                    putawayNum : obj.putawayNum
                });
            }
            if ( params.length <= 0 ) {
                toastr.error(errorMsg.join("") + "提示：没有可用于取消的上架单");
                return;
            } else {
                if ( errorMsg.length > 0 ) {
                    toastr.error(errorMsg.join(""));
                }
            }
            ReceiptPutaway.canclePutawayBatch({}, params, function(resp) {
                toastr.success("批量取消上架单成功");
                scope.refreshPutawayOrderList();
            });
        }

        // 判断tab是否已经存在，key自定义的唯一业务key
        function tabAlreadyExisted(key) {
            var existFlag = false, tIndex = -1;
            angular.forEach(vm.tabs, function(data, index, array){
                if (data.key == key) {
                    existFlag = true;
                    tIndex = index;

                }
            });

            if ( existFlag ) {
                vm.active = tIndex + 1;
            }

            return existFlag;
        }

        function reGeneratePutaway(row, subTabScope) {
            ReceiptPutaway.reGeneratePutaway({}, {
                putawayNum : row.putawayNum
            }, function (resp) {
                toastr.success("重新计算成功，上架单号："+resp.content.putawayNum);
                refreshPutawayOrderList();
            });
        }

        function refreshPutawayOrderList() {
            vm.putawayOrderNgTable.reload();
        }

        function editPutawayOrder(row, subTabScope) {
            if ( tabAlreadyExisted(row.putawayNum) ) {
                return;
            }

            var tabData = {
                key : row.putawayNum,
                heading: '上架单详情[' + row.putawayNum + ']',
                contentId: "putaway-edit.html",
                data : row,
                roles : [],
                getTableList : new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count(),
                            putawayNum : tabData.data.putawayNum
                        }, params.filter());
                        this.httpParams = postParams;
                        return ReceiptPutaway.getPutawayDetailList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            return data.content.datas;
                        });
                    }
                }),
                // 刷新表格
                refreshTable : function(){
                    tabData.getTableList.reload();
                }
            };
            vm.tabs.push(tabData);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        function putaway(row, subTabScope) {
            if ( undefined == row ) {
                return;
            }
            var params= $httpParamSerializer({
                putawayNum : row.putawayNum
            });
            $window.open("/#/iwh-receipt/pcPutaway?" + params);
        }

        function batchPutaway() {
            var selectedObj = vm.putawayOrderNgTable.getSelected();
            if ( !(selectedObj instanceof Array) || selectedObj.length <= 0 ) {
                toastr.error("请勾选上架单");
                return;
            }
            console.debug(["batchPutaway", selectedObj]);
        }

        function print(row, subTabScope) {
            ReceiptPrint.printPutaway({}, {
                putawayNum : row.putawayNum
            }, function (resp) {
                if(resp.content.printTaskId > 0) {
                    toastr.success("已成功发送打印任务到打印服务器，请等待打印完成!");
                } else if(resp.content.previewUrl) {
                    toastr.success("打印配置数据没有配置正确，跳转到预览页面进行手动打印！");
                    $window.open(resp.content.previewUrl);
                }
            });
        }

        function batchPrint() {
            var selectedObj = vm.putawayOrderNgTable.getSelected(), param = [];
            if ( !(selectedObj instanceof Array) || selectedObj.length <= 0 ) {
                toastr.error("请勾选需要打印的上架单");
                return;
            }
            for (var i = 0, len = selectedObj.length; i < len; i ++ ) {
                var _t = selectedObj[i];
                param.push({
                    putawayNum : _t.putawayNum
                });
            }
            ReceiptPrint.printPutawayBatch({}, param, function (resp) {
                toastr.success(resp.content);
            });
        }

        function delPutawayDetail(row, subTabScope) {
            if ( undefined == row ) {
                return;
            }
            ReceiptPutaway.deletePutawayDetail({},{
                id : row.id
            },function (resp) {
                subTabScope.refreshTable();
            })
        }

    }
})();