(function () {
    'use strict';
    angular.module('imessageModule')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('iMessagemessagelog', {
                parent: 'main',
                url: 'message/messagelog',
                data: {
                    'pageTitle': '调度任务'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'message-module/messagelog/messagelog.html',
                        controller: 'iMessageMessageLogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            });
        }]);
})();

