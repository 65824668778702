(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('PickOrderRuleController', PickOrderRuleController);

    PickOrderRuleController.$inject = ['iServiceConfig', '$timeout','PickOrderRule', 'NgTableParams','enums','toastr', '$httpParamSerializer'];

    function PickOrderRuleController (ServiceConfig, $timeout, PickOrderRule, NgTableParams,enums,toastr, $httpParamSerializer) {
        var vm = this;
        vm.active = 0;
        vm.executeStrategy = executeStrategy;
        vm.straUrl = '/#/iwh-strategy/pko-rule?';

        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return PickOrderRule.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    angular.forEach(datas, function(data) {
                        data.ruleUrl = vm.straUrl + $httpParamSerializer({ruleCode: data.code});
                    });

                    return datas;
                });
            }
        });

        function executeStrategy(item) {
            PickOrderRule.execute({ruleCode: item.code}, {}, function(data) {
                var successItems = data.content.successItems;
                var failItems = data.content.failItems;
                if (successItems.length === 0 && failItems.length === 0) {
                    toastr.warning("处理了0个装箱，请检查是否存在未拣货的装箱");
                } else {
                    for (var i=0; i<successItems.length; i++) {
                        toastr.success(successItems[i].msg);
                    }
                    for (var i=0; i<failItems.length; i++) {
                        toastr.error(failItems[i].reason);
                    }
                }
            });
        }
    }
})();
