(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('SkuStorageType', SkuStorageType);

    SkuStorageType.$inject = ['$resource', 'iServiceConfig'];

    function SkuStorageType ($resource, iServiceConfig) {
        var service = $resource('web/skuStorageType', {}, {
            'list': { url: iServiceConfig.idataHost + "/web/skuStorageType/list", method: 'POST'},
            'saveSkuStorageType': {url: iServiceConfig.idataHost + "/web/skuStorageType/saveSkuStorageType", method: 'POST'},
            'lookSkuStorageType': {url: iServiceConfig.idataHost + "/web/skuStorageType/lookSkuStorageType", method: 'POST'},
            'updateSkuStorageType': {url: iServiceConfig.idataHost + "/web/skuStorageType/updateSkuStorageType", method: 'POST'},
            'deleteSkuStorageType': {url: iServiceConfig.idataHost + "/web/skuStorageType/deleteSkuStorageType", method: 'POST'},
            'disable': {url: iServiceConfig.idataHost + "/web/skuStorageType/disable", method: 'POST'},
            'enable': {url: iServiceConfig.idataHost + "/web/skuStorageType/enable", method: 'POST'},
        });
        return service;
    }
})();
