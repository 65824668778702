(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('Wave', Wave);

    Wave.$inject = ['$resource', 'iServiceConfig'];

    function Wave ($resource, iServiceConfig) {
        var service = $resource('web/wave', {}, {
        	'list': {url: iServiceConfig.iwhShipHost + "/web/wave/list", method: 'POST'},
            'save': {url: iServiceConfig.iwhShipHost + "/web/wave/save", method: 'POST'},
            'plan': {url: iServiceConfig.iwhShipHost + "/web/wave/plan", method: 'POST'},
            'update': {url: iServiceConfig.iwhShipHost + "/web/wave/update", method: 'POST'},
            'detail': {url: iServiceConfig.iwhShipHost + "/web/wave/detail/:waveNum", params: {name:'@waveNum'}, method: 'GET'},
            'cancel': {url: iServiceConfig.iwhShipHost + "/web/wave/cancel/:waveNum", params: {name:'@waveNum'}, method: 'POST'},
            'cancelAlloc': {url: iServiceConfig.iwhShipHost + "/web/wave/cancelAlloc/:waveNum", params: {name:'@waveNum'}, method: 'POST'},
            'alloc': {url: iServiceConfig.iwhShipHost + "/web/wave/alloc/:waveNum", params: {name:'@waveNum'}, method: 'POST'},
            'generatePko': {url: iServiceConfig.iwhShipHost + "/web/wave/generatePko/:waveNum", params: {name:'@waveNum'}, method: 'POST'},
            'release': {url: iServiceConfig.iwhShipHost + "/web/wave/release/:waveNum", params: {name:'@waveNum'}, method: 'POST'},
            'ship': {url: iServiceConfig.iwhShipHost + "/web/wave/ship/:waveNum", params: {name:'@waveNum'}, method: 'POST'},
            'searchSoForWave': {url: iServiceConfig.iwhShipHost + "/web/wave/searchSoForWave", method: 'POST'},
            'addSos': {url: iServiceConfig.iwhShipHost + "/web/wave/addSos", method: 'POST'},
            'removeSos': {url: iServiceConfig.iwhShipHost + "/web/wave/removeSos", method: 'POST'},
        });

        return service;
    }
})();
