(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsFleetController', TmsFleetController);

    TmsFleetController.$inject = ['$rootScope','$stateParams', 'TmsFleet', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsFleetController ($rootScope,$stateParams, Fleet, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];
        vm.contractType = [
                     {id : 1, title : "自有"},
                     {id : 2, title : "外包"}
                 ];
        
        
        function pageTitle() {
            return $window.document.title;
        }


        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Fleet.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        if($state.params.code!=null) {
            vm.tableParams;

        }
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.validate = validate;
        function validate(code){
        	if(code==null ||code==""){
        		return
			}else{
                Fleet.validateCode({}, code, function(resp) {
                    if(resp.content=="success"){

                    }
                });
			}
		}

		vm.createFleet = createFleet;
		function createFleet() {
 				var entity = {
					heading : '创建',
					contentId : "createFleet.html",
					active : true,
					data: {
						code : "",
	                    name : "",
	                    status :0
	                },
					
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
		}
		
		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = t.data;
            if(t.data.carrierCode==null||t.data.carrierCode==""){
                toastr.error("请选择承运商！");
                return;
            }else if(t.data.contractType==null||t.data.contractType==""){
                toastr.error("请选择承包类型！");
                return;
            }else if(t.data.status==null){
                console.log(t.data.status);
                toastr.error("请选择状态！");
                return;
            }
			 if(t.data.id){
				 Fleet.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 Fleet.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.show = show;
		function show(row) {
            Fleet.show({}, row.id, function(resp) {
				var Fleet = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showFleet.html",
						active : true,
						data : Fleet,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = edite;
		function edite(row) {
            Fleet.show({}, row.id, function(resp) {
				var Fleet = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createFleet.html",
						active : true,
						data : Fleet,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            Fleet.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }
		
		
		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                Fleet.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }
		
		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
