(function() {
    'use strict';

    angular.module('ireportModule')
        .controller('RptNodeController', RptNodeController);

    RptNodeController.$inject = ['RptProcessNode', 'NgTableParams','$uibModal', '$q','NgSelect','$timeout'];

    function RptNodeController(ProcessNode, NgTableParams, $uibModal, $q, NgSelect, $timeout) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = [{'id':null, 'title':''},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.connectStatus = [{'id':null, 'title':''},{'id':0, 'title':'未连接'},{'id':1, 'title':'已连接'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.enable = enable;
        vm.disable = disable;
        vm.active = 0;
        vm.update = update;
        vm.isAdmin = false;
        
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return ProcessNode.list({}, postParams,
                    function(resp) {
                		vm.isAdmin = resp.content.isAdmin;
                        params.total(resp.content.page.tc);
                        return resp.content.page.datas;
                    }).$promise.then(function(data) {
                    return data.content.page.datas;
                });
            }
        });
        function showError(resp) {
        	if(resp.status === 'ERROR') {
        		alert(resp.errorMsg);
        		return true;
        	} 
        	return false;
        }
        function enable(node) {
        	ProcessNode.enable({}, {id: node.id}, function(resp) {
        		if(showError(resp)) {
        			return;
        		}
                vm.search();
            });
        }

        function disable(node) {
        	ProcessNode.disable({}, {id: node.id}, function(result) {
        		if(showError(result)) {
        			return;
        		}
                vm.search();
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function edit(row) {
        	ProcessNode.detail({}, {id: row.id}, function(result) {
        		if(showError(result)) {
        			return;
        		}
        		var entity = {
                      heading: '编辑'+row.code,
                        contentId: "node-edit.html",
                        data: result.content,
                        roles: []
                    };
                vm.tabs.push(entity);
                $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
            });
        }

        function create() {
			var entity = {
	                heading: '创建',
	                contentId: "node-edit.html",
	                data: {
	                    id: "",
		            	tenantId: "",
		            	code: "",
		            	name: "",
		            	address: "",
		            	contact: "",
		            	contactPhone: "",
		            	ip: "",
		            	connectStatus: 0,
		            	apiUrl: "",
		            	serverCode:"",
		            	status: 1
	                },
	                active: true
	            };
	
	            vm.tabs.push(entity);
	            $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
        }

        function update(tab, idx, form) {
            var node = tab.data;
            form.passwordUnmatch  = "";

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            ProcessNode.save({}, node, function(result) {
            	if(showError(result)) {
        			return;
        		}
            	vm.search();
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
    }
})();
