(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('ShipOrder', ShipOrder);

    ShipOrder.$inject = ['$resource', 'iServiceConfig'];

    function ShipOrder ($resource, iServiceConfig) {
        var service = $resource('web/so', {}, {
            'list': {url: iServiceConfig.iwhShipHost + "/web/so/list", method: 'POST'},
            'save': {url: iServiceConfig.iwhShipHost + "/web/so/save", method: 'POST'},
            'createFromPick' : {url: iServiceConfig.iwhShipHost + "/web/so/createFromPick", method: 'POST'},
            'update': {url: iServiceConfig.iwhShipHost + "/web/so/update", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhShipHost + "/web/so/cancel", method: 'POST'},
            'close': {url: iServiceConfig.iwhShipHost + "/web/so/close", method: 'POST'},
            'allocBatch': {url: iServiceConfig.iwhShipHost + "/web/so/allocBatch", method: 'POST'},
            'block': {url: iServiceConfig.iwhShipHost + "/web/so/block/:soNum", params: {name:'@soNum'}, method: 'POST'},
            'alloc': {url: iServiceConfig.iwhShipHost + "/web/so/alloc/:soNum", params: {name:'@soNum'}, method: 'POST'},
            'allocResult': {url: iServiceConfig.iwhShipHost + "/web/so/allocResult/:soNum", params: {name:'@soNum'}, method: 'POST'},
            'shipInOneStep': {url: iServiceConfig.iwhShipHost + "/web/so/shipInOneStep", method: 'POST'},
            'allocCase': {url: iServiceConfig.iwhShipHost + "/web/so/allocCase/:soNum", params: {name:'@soNum'}, method: 'POST'},
            'cancelAlloc': {url: iServiceConfig.iwhShipHost + "/web/so/cancelAlloc", method: 'POST'},
            'genSoNum': {url: iServiceConfig.iwhShipHost + "/web/so/genSoNum", method: 'POST'},
            'handleBlocks': {url: iServiceConfig.iwhShipHost + "/web/so/handleBlocks", method: 'POST'},
            'detail': {url: iServiceConfig.iwhShipHost + "/web/so/detail/:soNum", params: {name:'@soNum'}, method: 'GET'},
            'soWithPickDetail': {url: iServiceConfig.iwhShipHost + "/web/so/getSoForDelivery/:soNum", params: {name:'@soNum'}, method: 'GET'},
            'getShipOrder': {url: iServiceConfig.iwhShipHost + "/web/so/getShipOrder/:soNum", params: {name:'@soNum'}, method: 'GET'},
            'getSkuByBarcode': {url: iServiceConfig.iwhShipHost + "/web/so/getSkuByBarcode", method: 'POST'},
            'genPickOrderBatch': {url: iServiceConfig.iwhShipHost + "/web/so/genPickOrderBatch", method: 'POST'},
            
            'log': {url: iServiceConfig.iwhShipHost + "/web/soLog/list", method: 'POST'},
            'shipConfig': {url: iServiceConfig.iwhShipHost + "/web/so/shipConfig", method: 'POST'},
            'sendToTms': {url: iServiceConfig.iwhShipHost + "/web/so/sendToTms/:soNum", params: {name:'@soNum'}, method: 'GET'},
        });

        return service;
    }
})();
