(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('PrintPickOrder', PrintPickOrder);

    PrintPickOrder.$inject = ['$resource', 'iServiceConfig'];

    function PrintPickOrder ($resource, iServiceConfig) {
        var service = $resource('web/pickOrder', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/list", method: 'POST'},
            'print': { url: iServiceConfig.iwhShipHost + "/web/pickOrder/print", method: 'POST'}
        });
        return service;
    }
})();
