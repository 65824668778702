(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsPartnerController', TmsPartnerController);

    TmsPartnerController.$inject = ['$rootScope','$stateParams', 'TmsPartner', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsPartnerController ($rootScope,$stateParams, Partner, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.search = search;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];
        vm.geoCode = function(address, lnglatObj, lngF, latF, geoContainer) {
            window.geoCode1(address, lnglatObj, lngF, latF, geoContainer);
            return false;
        }
        vm.type = [
                     {id : "1", title : "货主方"},
                     {id : "2", title : "发货方"},
                     {id : "3", title : "收货方"},
                     {id : "4", title : "付款方"}
                 ];
        
        vm.statusMap = {'0': '新建','10' : '启用','-10' : '禁用'};
	     vm.typeMap = {'1': '货主方','2' : '发货方','3' : '收货方','4' : '付款方'};
        function pageTitle() {
            return $window.document.title;
        }


        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return Partner.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        if($state.params.code!=null) {
            vm.tableParams;

        }
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.validate = validate;
        function validate(code){
        	if(code==null ||code==""){
        		return
			}else{
                Partner.validateCode({}, code, function(resp) {
                    if(resp.content=="success"){

                    }
                });
			}
		}

		vm.createPartner = createPartner;
		function createPartner() {
            Partner.create({}, {}, function(resp) {
                var vo = resp.content;
                var entity = {
                    heading: '创建',
                    contentId: "createPartner.html",
                    active: true,
                    multiSelect: new NgSelect({
                        allList: vo,
                        list: [],
                        config: {
                            meta: {
                                label: 'name',
                                id: 'code'
                            }
                        }
                    }),
                    data: {
                        code: "",
                        name: "",
                        status: 0,
                        provinceId:0,
                        cityId:0,
                        areaId:0
                    },

                };
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
		}
		
		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
            if(t.data.type==null||t.data.type==""){
                toastr.error("请选择类型！");
                return;
            }else if(t.data.station==null||t.data.station==""){
                toastr.error("请选择默认站点！");
                return;
            }else if(t.data.status==null){
                toastr.error("请选择状态！");
                return;
            }
			 var include = [];
            t.multiSelect.getSelected().forEach(function(e){
                include.push(e.code);
            });
            t.data.includeStation=include.toString();
            console.log(t.data.includeStation);
			 var saveVO = t.data;
			 if(t.data.id){
				 Partner.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 Partner.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}
		
		vm.show = show;
		function show(row) {
            Partner.show({}, row.id, function(resp) {
				var Partner = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showPartner.html",
						active : true,
						data : Partner,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = edite;
		function edite(row) {
            Partner.show({}, row.id, function(resp) {
				var Partner = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createPartner.html",
						active : true,
						data : Partner,
                    	multiSelect: new NgSelect({
                        allList: Partner.stationList,
                        list: Partner.selectedStations,
                        config: {
                            meta: {
                                label: 'name',
                                id: 'code'
                            }
                        }
                    })
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            Partner.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }
		
		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                Partner.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }
		
		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
