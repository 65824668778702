(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleRole', UserModuleRole);

    UserModuleRole.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleRole ($resource, iServiceConfig) {
        var service = $resource('web/role', {}, {
            'list': { url: iServiceConfig.iuserHost + "/web/role/list", method: 'POST'},
            'save': {url: iServiceConfig.iuserHost + "/web/role/save", method: 'POST'},
            'delete': {url: iServiceConfig.iuserHost + "/web/role/delete", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/role/detail", method: 'POST'},
            'disable':{url: iServiceConfig.iuserHost + "/web/role/disable", method:'POST'},
            'enable':{url: iServiceConfig.iuserHost + "/web/role/enable", method:'POST'},
            'authTreeData': {url: iServiceConfig.iuserHost + "/web/role/auth-tree", method: 'POST'}
        });

        return service;
    }
})();
