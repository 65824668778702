(function () {
    'use strict';

    angular.module('iwh-inventoryModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('snapInventoryQuery', {
                parent: 'main',
                url: 'iwh-inventory/snapInventoryQuery',
                data: {
                    'pageTitle': '明细库存快照'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/snap/snapInventoryQuery.html',
                        controller: 'SnapInventoryQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
            .state('snapLotInventoryQuery', {
                parent: 'main',
                url: 'iwh-inventory/snapLotInventoryQuery',
                data: {
                    'pageTitle': '批次库存快照'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/snap/snapLotInventoryQuery.html',
                        controller: 'SnapLotInventoryQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
            .state('snapSkuInventoryQuery', {
                parent: 'main',
                url: 'iwh-inventory/snapSkuInventoryQuery',
                data: {
                    'pageTitle': '商品库存快照'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/snap/snapSkuInventoryQuery.html',
                        controller: 'SnapSkuInventoryQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
            .state('snapSkuInitEndInventoryQuery', {
                parent: 'main',
                url: 'iwh-inventory/snapSkuInitEndInventoryQuery',
                data: {
                    'pageTitle': '商品期初期末库存统计'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/snap/snapSkuInitEndInventoryQuery.html',
                        controller: 'SnapSkuInitEndInventoryQueryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
        ;
    }
})();
