(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusTaskRunning', iBusTaskRunning);

    iBusTaskRunning.$inject = ['$resource', 'iServiceConfig'];

    function iBusTaskRunning ($resource, iServiceConfig) {
        var service = $resource('web/run/tk', {}, {
            'list': { url: iServiceConfig.ibusHost + "/web/run/tk/list", method: 'POST'},
            'schedule': { url: iServiceConfig.ibusHost + "/web/run/tk/schedule", method: 'POST'},
            'cancel': { url: iServiceConfig.ibusHost + "/web/run/tk/cancel", method: 'POST'},
            'runningCount': { url: iServiceConfig.ibusHost + "/web/run/tk/runningCount", method: 'POST'},
            'scheduleAll': { url: iServiceConfig.ibusHost + "/web/run/tk/schedule-all", method: 'GET'},
            'cancelAll': { url: iServiceConfig.ibusHost + "/web/run/tk/cancel-all", method: 'GET'},
            'exState': { url: iServiceConfig.ibusHost + "/web/run/tk/ex-state", method: 'GET'},
            'scheduleSelect': { url: iServiceConfig.ibusHost + "/web/run/tk/schedule-select", method: 'POST'},
            'stopSelect': { url: iServiceConfig.ibusHost + "/web/run/tk/cancel-select", method: 'POST'}

        });

        return service;
    }
})();
