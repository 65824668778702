(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusJcoDatasourceRunning', iBusJcoDatasourceRunning);

    iBusJcoDatasourceRunning.$inject = ['$resource', 'iServiceConfig'];

    function iBusJcoDatasourceRunning ($resource, iServiceConfig) {
        var service = $resource('web/run/jcods', {}, {
            'init': { url: iServiceConfig.ibusHost + "/web/run/jcods/init", method: 'POST'},
            'close': { url: iServiceConfig.ibusHost + "/web/run/jcods/close", method: 'POST'}
        });

        return service;
    }
})();
