(function () {
    'use strict';

    angular.module('idashboardModule')
        .controller('ThreeDLocController', ThreeDLocController);
    ThreeDLocController.$inject = ['Warehouse3D','$state','$scope','$stateParams','$timeout','cfpLoadingBar'];
    function ThreeDLocController(Warehouse3D,$state,$scope,$stateParams,$timeout,cfpLoadingBar) {
        var vm = this;
        var camera, scene, raycaster, renderer;
        var mouse = new THREE.Vector2(), INTERSECTED;
        var requestTimer;
        var isIn=false;
        var infoObject={};
        var controls;

        //字体部分变量
        var mesh, curveSegments = 5, bevelThickness = 1, bevelSize = 0.1;
        //加载字体元素
        var loader = new THREE.FontLoader();
        //字体部分变量
        var materials,titleMaterials,group,textGeo,bevelEnabled = true,textMesh1,textMesh2,font = undefined;
        var size=10,height=1;
        var deep=1;     //图像的默认深度
        var sphericalDelta = new THREE.Spherical();         //用于手动旋转
        vm.fontSize=40;         //初始字体大小

        vm.whCode=$stateParams.whCode;
        vm.zoneCode=$stateParams.zoneCode;
        vm.whName=$stateParams.whName;
        vm.zoneName=$stateParams.zoneName;
        vm.locCode=$stateParams.locCode;
        vm.loadDone=false;      //是否加载完了数据，默认为false
        vm.page=1;              //当前数据的页数，初始为1

        vm.showFont=false;      //是否显示文字，默认为显示
        vm.showFontStatus=[
            {
                name:'显示文字',
                status:true
            },
            {
                name:'隐藏文字',
                status:false
            }
        ];
        vm.pageSize=2000;       //分页大小，默认为2000
        vm.pageSizeOption=[
            {
                name:500,
                value:500
            },
            {
                name:2000,
                value:2000
            },
            {
                name:4000,
                value:4000
            },
            {
                name:6000,
                value:6000
            },
            {
                name:8000,
                value:8000
            },
            {
                name:10000,
                value:10000
            },
            {
                name:20000,
                value:20000
            }
        ];

        //改变字体隐藏显示状态
        vm.changeFontStatus=function () {
            draw();
        };
        //改变分页大小
        vm.changePageSize=function () {

            if(vm.len/vm.pageSize-Math.floor(vm.len/vm.pageSize)>0.5){
                vm.totalLen=Math.ceil(vm.len/vm.pageSize);
            }else{
                if(Math.floor(vm.len/vm.pageSize)===0){
                    vm.totalLen=1;
                }else{
                    vm.totalLen=Math.floor(vm.len/vm.pageSize);
                }
            }

            vm.btnGroups=[];
            for(var i=1;i<=vm.totalLen;i++){
                if(i===1){
                    vm.btnGroups.push({
                        val:i,
                        isActive:true
                    });
                }else{
                    vm.btnGroups.push({
                        val:i,
                        isActive:false
                    });
                }
            }
            if(vm.page!==vm.totalLen){
                vm.boxData=vm.totalData.slice(vm.pageSize*(vm.page-1),vm.pageSize*vm.page);
            }else{
                vm.boxData=vm.totalData.slice(vm.pageSize*(vm.page-1));
            }

            var totalDepth=0;
            var totalWidth=0;
            vm.boxData.forEach(function (v) {
                if(v.x+v.depth>totalDepth){
                    totalDepth=v.x+v.depth;
                }
                if(Math.abs(v.z)+v.width>totalWidth){
                    totalWidth=Math.abs(v.z)+v.width;
                }
            });
            //根据长度来计算底盘的大小,也要用于camera相关大小显示
            vm.helpWidth=totalDepth>totalWidth?totalDepth:totalWidth;
            vm.boxData.forEach(function (v) {
                v.fontX=v.x-vm.helpWidth/2;
                v.fontY=v.y;
                v.fontZ=v.z-v.width/2;
            });
            draw(true);
            // $timeout(function () {
            //     $scope.$apply(function () {
            //         vm.page=pageNum;
            //     });
            //     vm.move(vm.searchResult);
            // },0);
        };

        //巷道部分展示
        Warehouse3D.getRoadwayList({
            whCode:vm.whCode,
        },{},function (res) {
            vm.roadList=res.content;

            //加载库位数据
            Warehouse3D.getLocationList({
                whCode:vm.whCode,
                zoneCode:vm.zoneCode==='null'?'':vm.zoneCode
            },{},function (res) {

                vm.loadDone=true;
                vm.boxLength=Math.ceil(Math.sqrt(res.content.length));
                var tooSmall=false,small=false;
                res.content.forEach(function (v,k) {
                    var m=v.z;
                    v.z=-v.y;
                    v.y=m;
                    v.hideInner=false;
                    if(v.depth<1000 || v.height<1000 || v.width<1000){
                        small=true;
                    }
                    if(v.depth<100 || v.height<100 || v.width<100){
                        tooSmall=true;
                    }
                });
                if(tooSmall){
                    vm.roadList.forEach(function (v) {
                        v.inCoordinateX*=1;
                        v.inCoordinateY*=1;
                        v.length*=1;
                        v.width*=1;
                    });
                    res.content.forEach(function (v) {
                        v.depth*=1;
                        v.height*=1;
                        v.width*=1;
                        v.x*=1;
                        v.y*=1;
                        v.z*=1;
                        vm.fontSize=40;

                    })
                }
                else if(small){
                    vm.roadList.forEach(function (v) {
                        v.inCoordinateX*=1;
                        v.inCoordinateY*=1;
                        v.length*=1;
                        v.width*=1;
                    });
                    res.content.forEach(function (v) {
                        v.depth*=1;
                        v.height*=1;
                        v.width*=1;
                        v.x*=1;
                        v.y*=1;
                        v.z*=1;
                        vm.fontSize=40;
                    })
                }
                vm.totalData=res.content;       //总数据
                vm.len=res.content.length;      //总数量

                /********
                 2000是准备一页放2000个数据
                 如果超过2000倍数的数量没有1000个，就直接凑到当页后面
                 如果超过了1000个，就再新增一页来存放
                 *****/
                if(vm.len/2000-Math.floor(vm.len/2000)>0.5){
                    vm.totalLen=Math.ceil(vm.len/2000);
                }else{
                    if(Math.floor(vm.len/2000)===0){
                        vm.totalLen=1;
                    }else{
                        vm.totalLen=Math.floor(vm.len/2000);
                    }
                }
                /********
                 目前的总页数为一页，因为只有三个库位
                 vm.btnGroups是用来存放页面上1-2000、2000-4000显示这种按钮数组的
                 *****/
                vm.btnGroups=[];
                for(var i=1;i<=vm.totalLen;i++){
                    if(i===1){
                        vm.btnGroups.push({
                            val:i,
                            isActive:true
                        });
                    }else{
                        vm.btnGroups.push({
                            val:i,
                            isActive:false
                        });
                    }
                }
                /********
                 把当前分页要显示的数据从总数组中拆出来用于显示，
                 即是vm.boxData才是当前页用来显示的数组
                 *****/
                if(vm.page!==vm.totalLen){
                    vm.boxData=res.content.slice(2000*(vm.page-1),2000*vm.page);
                }else{
                    vm.boxData=res.content.slice(2000*(vm.page-1));
                }
                /********
                 开始计算底盘大小
                 totalDepth:总的长度
                 totalWidth:总的宽度
                 最终取其中最大的一个赋值给vm.helpWidth
                 *****/
                var totalDepth=0;
                var totalWidth=0;
                vm.boxData.forEach(function (v) {
                	console.log('v.x',v.x)
                	console.log('v.depth',v.depth)
                	console.log('v.x+v.depth',v.x+v.depth)
                    if(v.x+v.depth>totalDepth){
                        totalDepth=v.x+v.depth;
                    }
                    if(Math.abs(v.z)+v.width>totalWidth){
                        totalWidth=Math.abs(v.z)+v.width;
                    }
                });
                console.log('totalDepth',totalDepth)
                console.log('totalWidth',totalWidth)
                //根据长度来计算底盘的大小,也要用于camera相关大小显示
                vm.helpWidth=totalDepth>totalWidth?totalDepth:totalWidth;
                console.log('helpWidth',vm.helpWidth)
                vm.boxData.forEach(function (v) {
                    v.fontX=v.x-vm.helpWidth/2;
                    v.fontY=v.y;
                    v.fontZ=v.z-v.width/2;
                });

                vm.setPage=function (v) {
                    if(v+1===vm.page){
                        return;
                    }
                    cfpLoadingBar.start();
                    $timeout(function () {
                        vm.btnGroups.forEach(function (m,k) {
                            if(k===v){
                                m.isActive=true;
                            }else{
                                m.isActive=false;
                            }
                        });
                        vm.page=v+1;
                        vm.info='';
                        vm.infoCaseNum = ''
                        vm.infoSkuCode = '';
                        	
                        if(vm.page!==vm.totalLen){
                            vm.boxData=res.content.slice(vm.pageSize*(vm.page-1),vm.pageSize*vm.page);
                        }else{
                            vm.boxData=res.content.slice(vm.pageSize*(vm.page-1));
                        }
                        vm.boxData.forEach(function (v) {
                            v.color='';
                            v.hideInner=false;
                        });
                        vm.hasLoadFZont=true;
                        draw();
                        controls.reset();
                    },0);
                }

                init();

            },function (res) {
                console.log(res,'错误')
            });

        },function (res) {

        });




        function init() {
            vm.search=search;
            vm.reset=reset;     //重置搜索结果
            vm.back=back;
            vm.move=move;        //移动，按照搜索结果中的第一个元素移动
            vm.inputClick=function (e) {
                if(e.keyCode===13){
                    search();
                }
            };
            vm.inputCaseClick=function (e) {
                if(e.keyCode===13){
                    searchCase();
                }
            };
            vm.inputSkuCodeClick = function (e) {
            	if(e.keyCode===13){
                    searchSkuCode();
                }
            }
            vm.inputOwnerCodeClick = function (e) {
            	if(e.keyCode===13){
            		if (vm.infoOwnerCode && vm.infoOwnerCode != '') {
                		Warehouse3D.getLocCodeByOwnerCode({
                    		ownerCode:vm.infoOwnerCode, warehouseCode:vm.whCode
                        },{},function (res) {
                        	vm.info = '';
                        	if (res.status == 'SUCCESS') {
                        		search(res.content);
                        	} else {
                        		toastr.error(res.errorMsg);
                        	}
                        },function (res) {

                        });
                	}
                }
            }
            vm.inputLpnClick = function (e) {
            	if(e.keyCode===13){
            		if (vm.infoLpn && vm.infoLpn != '') {
                		Warehouse3D.getLocCodeByLpn({
                    		lpn:vm.infoLpn, warehouseCode:vm.whCode
                        },{},function (res) {
                        	vm.info = '';
                        	if (res.status == 'SUCCESS') {
                        		search(res.content);
                        	} else {
                        		toastr.error(res.errorMsg);
                        	}
                        },function (res) {

                        });
                	}
                }
            }

            function move(v) {
//                 controls.reset();
//                 camera.position.x = 0;
//                 camera.position.y = (vm.helpWidth*deep);
//                 camera.position.z = 0;
//                 controls.update();
//                 
            	console.log('v[0]', v[0]);
            	console.log('vm.Min', vm.Min);
            	
                 camera.position.x= v[0].x-(vm.helpWidth/2);
                 camera.position.y = (vm.helpWidth*deep)/3;
                 camera.position.z=v[0].z-(-vm.helpWidth/2);
                 
                 console.log('camera.position', camera.position);
                controls.target=new THREE.Vector3(camera.position.x,0,camera.position.z);
                sphericalDelta.phi = 0.9;
                controls.update('','',sphericalDelta.theta,sphericalDelta.phi);
            }
            function reset() {
                cfpLoadingBar.start();
                $timeout(function () {
                    vm.boxData.forEach(function (v) {
                        v.color='';
                        v.hideInner=false;
                    });
                    vm.info='';
                    vm.infoCaseNum = '';
                    vm.infoSkuCode = '';

                    vm.infoOwnerCode = '';
                    vm.infoLpn = '';
                    draw();
                    controls.reset();
                    draw();
                    controls.reset();
                },0);

            }
            function back() {

                $state.go('3dZone',{
                    whCode:vm.whCode,
                    whName:vm.whName
                });
            }
            function searchCase() {
            	if (vm.infoCaseNum && vm.infoCaseNum != '') {
            		Warehouse3D.getLocCodeByCaseNum({
                		caseNum:vm.infoCaseNum, warehouseCode:vm.whCode
                    },{},function (res) {
                    	if (res.status == 'SUCCESS') {
                    		vm.info = '';
                    		search(res.content);
                    	} else {
                    		toastr.error(res.errorMsg);
                    	}
                    },function (res) {

                    });
            	}
            }
            function searchSkuCode() {
            	if (vm.infoSkuCode && vm.infoSkuCode != '') {
            		Warehouse3D.getLocCodeBySkuCode({
            			skuCode:vm.infoSkuCode, ownerCode:vm.infoOwnerCode, warehouseCode:vm.whCode
                    },{},function (res) {
                    	vm.info = '';
                    	if (res.status == 'SUCCESS') {
                    		search(res.content);
                    	} else {
                    		toastr.error(res.errorMsg);
                    	}
                    },function (res) {

                    });
            	}
            }
            
            function search(locCodeList) {
            	console.log(locCodeList);
                if(!vm.info && !locCodeList && locCodeList.length == 0){
                    //表示没搜索到
                	toastr.error('没有对应库位，请重新输入!');
                    vm.info='';
                    return;
                }
                var locCodeArr = locCodeList ? locCodeList : [];
                vm.searchResult=[];
                var position='';
                for(var j=0;j<vm.totalData.length;j++){
                    if(vm.info && vm.info != '' && vm.totalData[j].title.indexOf(vm.info)!==-1){
                        position=j;
                        break;
                    } else {
                    	angular.forEach(locCodeArr, function(item){  
                    	    if (item == vm.totalData[j].title) {
                    	    	position=j;
                    	    }
                    	}); 
                    	if (position === j) {
                    		break;
                    	}
                    }
                }


                if(position===''){
                    //表示没搜索到
                	toastr.error('没有对应库位，请重新输入!');
                    vm.info='';
                    return;
                }else{
                    position++;
                }

                var pageNum=Math.ceil(position/2000);
                vm.page=pageNum;

                vm.btnGroups.forEach(function (m,k) {
                    if(k===vm.page-1){
                        m.isActive=true;
                    }else{
                        m.isActive=false;
                    }
                });

                if(vm.page!==vm.totalLen){
                    vm.boxData=vm.totalData.slice(2000*(vm.page-1),2000*vm.page);
                }else{
                    vm.boxData=vm.totalData.slice(2000*(vm.page-1));
                }

//                console.log(vm.boxData,'data')

                //如果没退出，表示搜到对应的数据了
                vm.boxData.forEach(function (v) {
                    if(vm.info && vm.info != '' && v.title.indexOf(vm.info)!==-1){
                        v.color='#0000FF';
                        v.hideInner=false;
                        vm.searchResult.push(v);
                    } else{
                    	var isIn = false;
                    	angular.forEach(locCodeArr, function(item){  
                    	    if (item == v.title) {
                                isIn = true;
                    	    }
                    	});
                    	if (isIn) {
                    		v.color='#0000FF';
                            v.hideInner=false;
                            vm.searchResult.push(v);
                    	} else {
                    		v.color='';
                            v.hideInner=true;
                    	}
                    }
                });
                draw();
                controls.reset();
                draw();
                controls.reset();
                $timeout(function () {
                    $scope.$apply(function () {
                        vm.page=pageNum;
                    });
                    vm.move(vm.searchResult);
                },0);
            }

            camera = new THREE.CinematicCamera( 60, jQuery('#3DZone').width() / jQuery('#3DZone').height(), 1, 100000000 );
            camera.position.x = vm.helpWidth/2;
            camera.position.y = vm.helpWidth;
            camera.position.z = vm.helpWidth*2/3;
            camera.lookAt({
                x : 0,
                y : 0,
                z : 0
            });
            camera.updateMatrixWorld();
            controls = new THREE.OrbitControls( camera );
            /*
            *求由绿到红的渐变色值,百分比 bili 取值 1...100
            */
            //绘制

            //字体加载
            //字体group加载
            // var ma=new THREE.MeshPhongMaterial( { color: '#F5F5F5' } );
            // materials = [ma];
            // titleMaterials=[
            //     new THREE.MeshPhongMaterial( { color: '#CD853F', flatShading: true } ),
            //     new THREE.MeshPhongMaterial( { color: '#CD853F',flatShading: true } )
            // ];

            draw();

            raycaster = new THREE.Raycaster();
            renderer = new THREE.WebGLRenderer( { antialias: true } );
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( jQuery('#3DZone').width(), jQuery('#3DZone').height() );
            document.getElementById('3DZone').appendChild(renderer.domElement);
            document.addEventListener( 'mousemove', onDocumentMouseMove, false );
            document.addEventListener('mousedown',onDocumentMouseDown,false);
            document.addEventListener('dblclick',onDblClick,false)
            window.addEventListener( 'resize', onWindowResize, false );
            var effectController  = {
                focalLength: 15,
                // jsDepthCalculation: true,
                // shaderFocus: false,
                //
                fstop: 2.8,
                // maxblur: 1.0,
                //
                showFocus: false,
                focalDepth: 3,
                // manualdof: false,
                // vignetting: false,
                // depthblur: false,
                //
                // threshold: 0.5,
//                 gain: 2.0,
//                 bias: 0.5,
//                 fringe: 0.7,
                //
                // focalLength: 35,
                // noise: true,
                // pentagon: false,
                //
                // dithering: 0.0001
            };
            var matChanger = function( ) {
                for (var e in effectController) {
                    if (e in camera.postprocessing.bokeh_uniforms)
                        camera.postprocessing.bokeh_uniforms[ e ].value = effectController[ e ];
                }
                camera.postprocessing.bokeh_uniforms[ 'znear' ].value = camera.near;
                camera.postprocessing.bokeh_uniforms[ 'zfar' ].value = camera.far;
                camera.setLens(effectController.focalLength, camera.frameHeight ,effectController.fstop, camera.coc);
                effectController['focalDepth'] = camera.postprocessing.bokeh_uniforms["focalDepth"].value;
            };
            matChanger();
            window.addEventListener( 'resize', onWindowResize, false );


            animate();
            if (vm.locCode && vm.locCode != '') {
            	vm.info = vm.locCode;
            	vm.search();
            	vm.locCode = '';
            }
        }

        //水位线颜色
        function getColorByBaiFenBi(bili){
            //var 百分之一 = (单色值范围) / 50;  单颜色的变化范围只在50%之内
            var one = (255+255) / 100;
            var r=0;
            var g=0;
            var b=0;

            if ( bili < 50 ) {
                // 比例小于50的时候红色是越来越多的,直到红色为255时(红+绿)变为黄色.
                r = one * bili;
                g=255;
            }
            if ( bili >= 50 ) {
                // 比例大于50的时候绿色是越来越少的,直到0 变为纯红
                g =  255 - ( (bili - 50 ) * one) ;
                r = 255;
            }
            r = parseInt(r);// 取整
            g = parseInt(g);// 取整
            b = parseInt(b);// 取整

            //返回16进制颜色
            var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
            return hex;
        }

        function draw(e) {
            vm.maxWidth=0;              //当前画布的最大X
            vm.maxHeight=0;             //当前画布的最大Z
            vm.minWidth=0;              //当前画布的最小X
            vm.minHeight=0;             //当前画布的最小Z

            vm.roadDetail=[];       //用于存放此页面需要呈现的巷道
            vm.boxDetail=[];       //用于存放内部方块的数组

            /****
                新增的reset()方法
             */
            console.log(vm)
            controls.reset();
            scene = new THREE.Scene();
            scene.background = new THREE.Color( 0xffffff );
            scene.add(new THREE.AmbientLight( 0xffffff, 0.3 ));//设置方块的透明度

            var light = new THREE.DirectionalLight( 0xffffff, 1);
            light.position.set( 1, 1, 1 ).normalize();
            scene.add( light );

            var Min=0;
//             //计算底盘的大小
            vm.boxData.forEach(function (v) {
                if(!Min){
                    Min=v.x;
                }else if(v.x<Min){
                    Min=v.x;
                }
            });
            vm.Min=Min-170;
            console.log("vm.Min = ", vm.Min)
            console.log(vm)
            vm.boxData.forEach(function (v) {
                v.x=v.x-Min;
                v.fontX=v.x+v.depth/2-vm.helpWidth/2;
                v.fontY=v.y;
                v.fontZ=v.z-v.width/2;
            });
            console.log(vm)
            group = new THREE.Group();
            group.position.y = 0;
            scene.add( group );

            //先统一初始化一下
            vm.maxWidth=vm.boxData[0].x;
            vm.maxHeight=vm.boxData[0].z;
            if(vm.page!==1){
                vm.minWidth=vm.boxData[0].x;
                vm.minHeight=vm.boxData[0].z;
            }
            console.log(vm)
            var geo=new THREE.BoxGeometry( 80, 50, 60 );
            vm.boxData.forEach(function (v,k) {
                if(v.x>vm.maxWidth){
                    vm.maxWidth=v.x;
                }
                if(v.z<vm.maxHeight){
                    vm.maxHeight=v.z;
                }
                if(v.x<vm.minWidth){
                    vm.minWidth=v.x;
                }
                if(v.z>vm.minHeight){
                    vm.minHeight=v.z;
                }
                mesh = new THREE.Mesh( new THREE.BoxGeometry( v.depth, v.height, v.width ) ,new THREE.MeshLambertMaterial({
                    color: v.color?v.color:'#808080',
                    transparent:true,
                    opacity:0.4,
                    side: THREE.DoubleSide
                }) );
                mesh.position.x=v.x+v.depth/2-vm.helpWidth/2;
                mesh.position.y=v.y+v.height/2;
                mesh.position.z=v.z-v.width/2+vm.helpWidth/2;
                mesh.name=v.title;
                mesh.percent=v.percent;
                mesh.breedCount=v.breedCount;
                mesh.qtyTotal=v.qtyTotal;
                mesh.queryInvUrl=v.queryInvUrl;
                scene.add(mesh);
                //生成内部方块数组
                //其中，|y|*2+per=100;如果per>80,y*2+per=100;



                if(!v.hideInner && v.percent!==0){
                    vm.boxDetail.push(
                        {
                            x:v.x,
                            y:v.y-v.height/2,
                            z:v.z,
                            per:v.percent,
                            depth:v.depth,
                            height:v.height,
                            width:v.width
                        }
                    );
                }

                //边框
               // var edges = new THREE.EdgesHelper( mesh, 0x808080 );//设置边框，可以旋转
               // edges.position.x=v.x+70;
               // edges.position.y=v.y+20;
               // edges.position.z=v.z;
               // scene.add( edges );
            });

            //选取巷道  vm.roadDetail
//            vm.roadList.forEach(function (v) {
//                if(v.inCoordinateX<=vm.maxWidth && v.inCoordinateX>=vm.minWidth && v.inCoordinateY<=(-vm.maxHeight) && v.inCoordinateY>=(-vm.minHeight)){
//                    vm.roadDetail.push(v);
//                }
//            });

            //开始画巷道
            var startX=0,startZ=0;
            console.log(vm.roadDetail,'巷道')
            console.log(vm.helpWidth,'width')
            vm.roadDetail.forEach(function (v) {
                //先根据已知数据确定起点
                if(v.inCoordinateX<=v.outCoordinateX){
                    startX=v.inCoordinateX;
                }else{
                    startX=v.inCoordinateX-v.width;
                }
                if(v.inCoordinateY<=v.outCoordinateY){
                    startZ=v.inCoordinateY;
                }else{
                    startZ=v.inCoordinateY-v.length;
                }
                startZ=-startZ;

                v.fontX=startX-vm.helpWidth/2;
                v.fontZ=startZ-v.width/2;

                mesh = new THREE.Mesh( new THREE.BoxGeometry( v.length, 10, v.width) ,new THREE.MeshLambertMaterial({
                    color: '#03a9f4',
                    transparent:true,
                    opacity:0.4,
                    side: THREE.DoubleSide
                }));

                var distance=0;
                if(vm.helpWidth>100000){
                    distance=400;
                }else if(vm.helpWidth>50000){
                    distance=150;
                } else if(vm.helpWidth>10000){
                    distance=50;
                }else if(vm.helpWidth>1000){
                    distance=15;
                }else{
                    distance=6;
                }

                mesh.position.x=startX-vm.helpWidth/2+v.length/2;
                mesh.position.y=distance;
                mesh.position.z=startZ+vm.helpWidth/2-v.width/2;
                mesh.name=v.name;
                mesh.code=v.code;
                mesh.title=v.title;
                mesh.meshType=1;
                scene.add(mesh);
            });

            var fontSize;
            if(!font){
                if(!vm.hasLoadFZont){
                    loader.load( 'http://static.56start.com/fonts/Microsoft.json', function ( response ) {
                        font = response;
                        group.remove( textMesh1 );
                        if(vm.showFont){
                            vm.boxData.forEach(function (v) {
                                fontSize=v.height>v.depth?v.depth/v.title.length:v.height/v.title.length;
                                createText(v,vm.helpWidth,fontSize);
                            });
                            vm.roadDetail.forEach(function (v) {
                                createRoadText(v,vm.helpWidth,60,'');
                            });
                        }
                        if(!vm.zoneName){
                            createRoadText({
                                name:'仓库: '+ vm.whName,
                                fontX:0,
                                fontY:5,
                                fontZ:vm.helpWidth/22,
                                // width:100,
                                // depth:0,
                                // height:0,
                            },vm.helpWidth,vm.helpWidth/60,titleMaterials,true);
                        }else{
                            createRoadText({
                                name:'库区: '+ vm.zoneName,
                                fontX:0,
                                fontY:5,
                                fontZ:vm.helpWidth/22,
                                // width:100,
                                // depth:0,
                                // height:0,
                            },vm.helpWidth,vm.helpWidth/60,titleMaterials,true);
                        }

                        vm.hasLoadFZont=false;
                    });
                }
            }
            else{
                group.remove( textMesh1 );
                if(vm.showFont){
                    if(e){
                        vm.boxData.forEach(function (v) {
                            if(!v.hideInner){
                                fontSize=v.height>v.depth?v.depth/v.title.length:v.height/v.title.length;
                                createText(v,vm.helpWidth,fontSize);
                            }
                        });
                    }else{
                        vm.boxData.forEach(function (v) {
                            fontSize=v.height>v.depth?v.depth/v.title.length:v.height/v.title.length;
                            createText(v,vm.helpWidth,fontSize);
                        });
                    }
                }
                if(!vm.zoneName){
                    createRoadText(
                        {
                            name:'仓库: '+ vm.whName,
                            fontX:0,
                            fontY:5,
                            fontZ:vm.helpWidth/22,
                        }
                        ,vm.helpWidth,vm.helpWidth/60,titleMaterials,true);
                }else{
                    createRoadText(
                        {
                            name:'库区: '+ vm.zoneName,
                            fontX:0,
                            fontY:5,
                            fontZ:vm.helpWidth/22,
                        }
                        ,vm.helpWidth,vm.helpWidth/60,titleMaterials,true);
                }



            }
            cfpLoadingBar.complete();



            //生成内部方块
            var innerMaterial=new THREE.MeshLambertMaterial({
                color:getColorByBaiFenBi(20),
//                transparent:true,
//                opacity:0.6,
                side: THREE.DoubleSide
            });

            var innerGeo=new THREE.BoxGeometry( 80, 80, 80 );
            vm.boxDetail.forEach(function (v) {

                var per=0;
                if(v.per<5){
                    per=5;
                }else{
                    per=v.per;
                }
                if(v.per>100){
                    per=100;
                }

                mesh = new THREE.Mesh( new THREE.BoxGeometry( v.depth-3, per/100*v.height*0.9, v.width-3 )  , new THREE.MeshLambertMaterial({
                    color:getColorByBaiFenBi(per),
//                transparent:true,
//                opacity:0.6,
                    side: THREE.DoubleSide
                }));
                mesh.position.x=v.x+v.depth/2-vm.helpWidth/2;     //x,y,z调整位置,z-60是因为z的正向为视角的负方向
                mesh.position.y=v.y+v.height/2+per/100*v.height*0.45;
                mesh.position.z=v.z-v.width/2+vm.helpWidth/2;
                scene.add(mesh);

                //边框
                // var edges = new THREE.EdgesHelper( mesh, 0xe0e0e0 );//设置边框，可以旋转
                // edges.position.x=v.x+v.depth/2-vm.helpWidth/2-Min;
                // edges.position.y=v.y+v.height/2+per/100*v.height*0.45;
                // edges.position.z=v.z-v.width/2+vm.helpWidth/2;
                // scene.add( edges );
            });
            var distance=0;
            if(vm.helpWidth>100000){
                distance=300;
            }else if(vm.helpWidth>50000){
                distance=100;
            } else if(vm.helpWidth>10000){
                distance=30;
            }else if(vm.helpWidth>1000){
                distance=10;
            }else{
                distance=3;
            }
            console.log("distance="+distance)
            var helper = new THREE.GridHelper(vm.helpWidth,30,0xf0f0f0,0xffffff);
            helper.position.x = 0;
            helper.position.y = distance;
            helper.position.z = 0;
            scene.add( helper );

            var ground = new THREE.Mesh(
                new THREE.PlaneBufferGeometry( vm.helpWidth*1.1, vm.helpWidth*1.1, 1, 1 ),
                new THREE.MeshPhongMaterial( {
                    color: '#CAE1FF',
                    // shininess: 150,
                    // transparent:true,
                    // opacity:0.9,
                } )
            );
            ground.rotation.x = - Math.PI / 2; // rotates X/Y to X/Z
            ground.position.y = -5; // rotates X/Y to X/Z
            ground.receiveShadow = true;
            scene.add( ground );
        }

        //生成字体
        function createText(v,helpwidth,speSize,speMaterial,isHide) {
            cfpLoadingBar.start();
            $timeout(function () {
                var name='';
                var copyName='';
                var sum=0;

                if(!isHide){
                    for(var i=0;i<v.title.length;i++){
                        if(/^[\u4e00-\u9fa5]*$/.test(v.title[i])){
                            sum++;
                        }else{
                            sum+=0.5;
                        }
                        if(sum<=7){
                            copyName+=v.title[i];
                        }
                    }

                    if(sum<=7){
                        name=v.title;
                    }else{
                        name=copyName;
                    }
                }else{
                    name=v.title;
                }


                textGeo = new THREE.TextBufferGeometry( name, {
                    font: font,
                    size: speSize?speSize:size,
                    height: height,
                    curveSegments: curveSegments,
                    // bevelThickness: bevelThickness,
                    // bevelSize: bevelSize,
                    // bevelEnabled: bevelEnabled,
                    // material: 0,
                    // extrudeMaterial: 1
                });
                textGeo.computeBoundingBox();
                textGeo.computeVertexNormals();
                // "fix" side normals by removing z-component of normals for side faces
                // (this doesn't work well for beveled geometry as then we lose nice curvature around z-axis)
                if ( ! bevelEnabled ) {
                    var triangleAreaHeuristics = 0.1 * ( height * size );
                    for ( var i = 0; i < textGeo.faces.length; i ++ ) {
                        var face = textGeo.faces[i];
                        if ( face.materialIndex == 1 ) {
                            for ( var j = 0; j < face.vertexNormals.length; j ++ ) {
                                face.vertexNormals[j].z = 0;
                                face.vertexNormals[j].normalize();
                            }
                            var va = textGeo.vertices[ face.a ];
                            var vb = textGeo.vertices[ face.b ];
                            var vc = textGeo.vertices[ face.c ];
                            var s = THREE.GeometryUtils.triangleArea( va, vb, vc );
                            if ( s > triangleAreaHeuristics ) {
                                for ( var j = 0; j < face.vertexNormals.length; j ++ ) {
                                    face.vertexNormals[j].copy( face.normal );
                                }
                            }
                        }
                    }
                }
                textMesh1 = new THREE.Mesh( textGeo, speMaterial?speMaterial:materials );
                textMesh1.position.x = v.fontX-v.depth/2;
                textMesh1.position.y = v.fontY+v.height/2;
                textMesh1.position.z = v.fontZ+helpwidth/2+v.width/2;
                //旋转角度
                textMesh1.rotation.x = Math.PI;
                textMesh1.rotation.y = Math.PI;
                textMesh1.rotation.z = Math.PI;
                group.add( textMesh1 );
                //画两面文字
                textMesh2 = new THREE.Mesh( textGeo, speMaterial?speMaterial:materials );
                textMesh2.position.x = v.fontX+v.depth/2;
                textMesh2.position.y = v.fontY+v.height/2;
                textMesh2.position.z = v.fontZ+helpwidth/2-v.width/2;
                //旋转角度
                textMesh2.rotation.x = Math.PI;
                textMesh2.rotation.y = 0;
                textMesh2.rotation.z = Math.PI ;
                group.add( textMesh2 );

                cfpLoadingBar.complete();
            },0);
        }

        function createRoadText(v,helpwidth,speSize,speMaterial) {
            textGeo = new THREE.TextBufferGeometry( v.name, {
                font: font,
                size: speSize?speSize:size,
                height: height,
                curveSegments: curveSegments,
                // bevelThickness: bevelThickness,
                // bevelSize: bevelSize,
                // bevelEnabled: bevelEnabled,
                // material: 0,
                // extrudeMaterial: 1
            });
            textGeo.computeBoundingBox();
            textGeo.computeVertexNormals();
            // "fix" side normals by removing z-component of normals for side faces
            // (this doesn't work well for beveled geometry as then we lose nice curvature around z-axis)
            if ( ! bevelEnabled ) {
                var triangleAreaHeuristics = 0.1 * ( height * size );
                for ( var i = 0; i < textGeo.faces.length; i ++ ) {
                    var face = textGeo.faces[ i ];
                    if ( face.materialIndex == 1 ) {
                        for ( var j = 0; j < face.vertexNormals.length; j ++ ) {
                            face.vertexNormals[j].z = 0;
                            face.vertexNormals[j].normalize();
                        }
                        var va = textGeo.vertices[ face.a ];
                        var vb = textGeo.vertices[ face.b ];
                        var vc = textGeo.vertices[ face.c ];
                        var s = THREE.GeometryUtils.triangleArea( va, vb, vc );
                        if ( s > triangleAreaHeuristics ) {
                            for ( var j = 0; j < face.vertexNormals.length; j ++ ) {
                                face.vertexNormals[j].copy( face.normal );
                            }
                        }
                    }
                }
            }
            textMesh1 = new THREE.Mesh( textGeo, speMaterial?speMaterial:materials );
            textMesh1.position.x = v.fontX;
            textMesh1.position.y = 0;
            textMesh1.position.z = v.fontZ+helpwidth/2;
            //旋转角度
            textMesh1.rotation.x = Math.PI/2;
            textMesh1.rotation.y = Math.PI;
            textMesh1.rotation.z = Math.PI;
            group.add( textMesh1 );
        }

        function onWindowResize() {
            camera.aspect = jQuery('#3DZone').width() / jQuery('#3DZone').height();
            camera.updateProjectionMatrix();
            renderer.setSize( jQuery('#3DZone').width(), jQuery('#3DZone').height() );
        }
        //单机事件
        function onDocumentMouseDown() {
            if(isIn){
                //鼠标点击事件
                // console.log(infoObject,'info')
            }
        }
        //双击事件
        function onDblClick() {
            if(isIn){
                //鼠标点击事件
                console.log(infoObject,'info')
                window.open(infoObject.queryInvUrl);
            }
        }

        function onDocumentMouseMove( event ) {
            event.preventDefault();
//            mouse.x = ( (event.clientX-150) / jQuery('#3DZone').width() ) * 2 - 1;
//            mouse.y = - ( (event.clientY-80) / jQuery('#3DZone').height() ) * 2 + 1;
            
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            
            console.log('isIn', isIn);
            console.log('event clientX clientY', event.clientX, event.clientY);
            if(isIn){
                $('#testDiv').css({
                    left:event.clientX,
                    top:event.clientY,
                    display:'block'
                });
            }else{
                $('#testDiv').css({
                    display:'none'
                });
            }
        }

        //控制帧数，防止无谓的重新渲染，浪费性能
        var fps = 30;
        var now;
        var then = Date.now();
        var interval = 1000/fps;
        var delta;

        function animate() {
            requestTimer=requestAnimationFrame( animate, renderer.domElement );
            now=Date.now();
            delta=now-then;
            if(delta>interval){
                then = now - (delta % interval);
                render();
            }
        }

        function render() {
            // find intersections
        	var vector = new THREE.Vector3(mouse.x, mouse.y,0.5).unproject(camera);
        	var raycaster = new THREE.Raycaster(camera.position, vector.sub(camera.position).normalize());

//            raycaster.setFromCamera( mouse, camera );
            var intersects = raycaster.intersectObjects( scene.children );
            if ( intersects.length > 0) {
                var inBox=false;
                intersects.forEach(function (v) {
                    if(v.object.name){
                        inBox=true;
                    }
                });
                if(intersects[0].object.name && inBox){
                    isIn=true;
                    if(intersects[0].object.meshType===1){
                        jQuery('#testDiv').html(
                            '<div>巷道名:'+intersects[0].object.name+'</div>'+
                            '<div>巷道编码:'+intersects[0].object.code+'</div>'+
                            '<div>巷道全称:'+intersects[0].object.title+'</div>'
                            // '<div>仓库别名:'+intersects[0].object.remark+'</div>'
                            // `<div>姓名:${intersects[0].object.name}</div>`
                        );
                    }else{
                        jQuery('#testDiv').html(
                            '<div>库位名:'+intersects[0].object.name+'</div>'+
                            '<div>库位商品品种数:'+intersects[0].object.breedCount+'</div>'+
                            '<div>库位商品总量:'+intersects[0].object.qtyTotal+'</div>'+
                            '<div>库位容量百分比:'+intersects[0].object.percent+'</div>'
                        );
                    }

                    infoObject.name=intersects[0].object.name;
                    infoObject.percent=intersects[0].object.percent;
                    infoObject.queryInvUrl=intersects[0].object.queryInvUrl;
                    var targetDistance = intersects[ 0 ].distance;
                    //Using Cinematic camera focusAt method
                    camera.focusAt(targetDistance);
                    if ( INTERSECTED != intersects[ 0 ].object ) {
                        if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
                        INTERSECTED = intersects[ 0 ].object;
                        INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
                        INTERSECTED.material.emissive.setHex( 0xff0000 );
                    }
                }else{
                    isIn=false;
                }
            } else {
                isIn=false;
                infoObject={};
                if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
                INTERSECTED = null;
            }

            camera.renderCinematic(scene, renderer,camera);
        }

        $scope.$on('$destroy',function () {
            cancelAnimationFrame(requestTimer);
        });
    }
})();


