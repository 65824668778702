(function() {
    'use strict';

    angular.module('iprintModule')
        .controller('IprintClientController', IprintClientController);

    IprintClientController.$inject = ['IprintClient','NgTableParams','$uibModal','$timeout'];

    function IprintClientController(IprintClient,NgTableParams, $uibModal,$timeout) {
        //debugger;
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        vm.tabs = [];
        vm.realStatus = [{'id':1, 'title':'已连接'},{'id':-1, 'title':'未连接'}];
        vm.status = [{'id':1, 'title':'已启用'},{'id':-1, 'title':'已禁用'}];
        vm.opType = [{'id':1,'title':'注册连接'},{'id':2,'title':'关闭连接'}]
        vm.search = search;
        vm.create = create;
        vm.removeTab = removeTab;
        vm.currClientCode = null;
        vm.active = 0;
        
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return IprintClient.find({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });
        
        vm.tableClientTrack= new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({clientCode: vm.currClientCode,pn: params.page(), ps: params.count()}, params.filter());
                return IprintClient.findclient({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });
        
        
        
        function search() {
            vm.tableParams.reload();
        }

        function create(data) {
        	console.log(data);
        	vm.currClientCode = data.code;
            var entity = {
                heading: '跟踪'+vm.currClientCode,
                contentId: "user-track.html",
                
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
                vm.tableClientTrack.reload();
            },0);
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
    }
    
})();
