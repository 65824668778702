(function()  {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('SkuInventorySearchController', SkuInventorySearchController);

    SkuInventorySearchController.$inject = ['$rootScope', 'InventorySearch', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function SkuInventorySearchController ($rootScope,  InventorySearch, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.allCount = {
            ownerCode : null,
            ownerName : null,
            skuName : "当前页总计",
            canUseQty : null,
            qty : null,
            allAllocatedQty : null,
            pickedQty : null
        }
        vm.allCount2 = {
            ownerCode : null,
            ownerName : null,
            skuName : "所有总计",
            canUseQty : null,
            qty : null,
            allAllocatedQty : null,
            pickedQty : null
        }
        function pageTitle() {
            return $window.document.title;
        }
        vm.search = search;
		function search() {
			vm.tableParams.reload();
		}

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        //sku查询
        vm.tableParams = new NgTableParams({
            count : 10,counts: [10,20,50,100,200,500,1000,2000]
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return InventorySearch.skuInventory({}, postParams, function(resp) {
                    params.total(resp.content.pageVo.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.pageVo.datas;
                    vm.allCount.allocatedQty = data.content.allAllocatedQtyAll;
                    vm.allCount.availableQty = data.content.canUseQtyAll;
                    vm.allCount.qty = data.content.qtyAll;
                    vm.allCount.pickedQty = data.content.pickedQtyAll;
                    datas[datas.length] = vm.allCount;
                    vm.allCount2.allocatedQty = data.content.allAllocatedQtyAll2;
                    vm.allCount2.availableQty = data.content.canUseQtyAll2;
                    vm.allCount2.qty = data.content.qtyAll2;
                    vm.allCount2.pickedQty = data.content.pickedQtyAll2;
                    datas[datas.length] = vm.allCount2;
                    return datas;
                });
            }
        });
    }
})();
