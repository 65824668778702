(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('cutbox-rule', {
            parent: 'main',
            url: 'iwh-strategy/cutbox-rule',
            data: {'pageTitle': '切箱规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/cutboxRule/cutboxRule.html',
                    controller: 'CutboxRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('CutboxRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/cutbox-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/:id', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/sort', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/sort/del/:id', params: {id: '@id'}, method: 'POST'},
            "computePost": {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/compute', method: 'POST'},
            "computeDelete": {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/compute/del/:id', params: {id: '@id'}, method: 'POST'},
            "groupPost": {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/group', method: 'POST'},
            "groupDelete": {url: iServiceConfig.iwhStrategyHost + '/web/cutbox-rule/group/del/:id', params: {id: '@id'}, method: 'POST'},
            'getCutBoxFields': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.CutBoxFields.json', method: 'GET'},
            'getCutBoxShareMethod': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.CutBoxShareMethod.json', method: 'GET'},
            'getCutBoxComputeMethod': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.CutBoxComputeMethod.json', method: 'GET'},
        });
    }]);

    app.controller('CutboxRuleController', [
        '$scope', 'CutboxRule', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, CutboxRule, NgTableParams, $timeout, toastr) {
        	$scope.sortOptions = [{k: '10', v: '升序'}, {k: '20', v: '降序'}];
        	$scope.sortOptionsMap = {'10':'升序','20': '降序'};
            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };
            $scope.cutBoxFieldsMap = {};
            CutboxRule.getCutBoxFields({}, function (result) {
                $scope.cutBoxFields = result.content;
                angular.forEach($scope.cutBoxFields, function(data,index,array){
                	$scope.cutBoxFieldsMap[data.value] = data.label;
                });
            });
            $scope.cutBoxShareMethodsMap = {};
            CutboxRule.getCutBoxShareMethod({}, function (result) {
                $scope.cutBoxShareMethods = result.content;
                angular.forEach($scope.cutBoxShareMethods, function(data,index,array){
                	$scope.cutBoxShareMethodsMap[data.value+''] = data.label;
                });
            });
            $scope.cutBoxComputeMethodsMap = {};
            CutboxRule.getCutBoxComputeMethod({}, function (result) {
                $scope.cutBoxComputeMethods = result.content;
                angular.forEach($scope.cutBoxComputeMethods, function(data,index,array){
                	$scope.cutBoxComputeMethodsMap[data.value] = data.label;
                });
            });
            
            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return CutboxRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.cutboxRuleContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {sorts: [], computes:[], groups:[]};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "cutboxRuleSort.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    CutboxRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.cutboxRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {sorts: [], computes:[], groups:[]};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '新建',
                    contentId: "cutboxRuleSort.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    CutboxRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.saveCutboxRule = function (tab,index) {
                CutboxRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };

            $scope.cutboxRuleSort = function (tab, sort) {
                var rule = tab.data;
                var csort = angular.copy(sort);
                tab.currentSort = csort || {
                        tenantId: rule.tenantId,
                        warehouseCode: rule.warehouseCode,
                        cutboxRule: rule.code
                    };
            };

            $scope.saveCutboxRuleSort = function (tab) {
                if (!tab.currentSort) return;
                var isNew = !tab.currentSort.id;
                CutboxRule.detailSavePost({}, tab.currentSort, function (resp) {
                	tab.currentSort = null;
                	CutboxRule.contentGet({id: tab.data.id}, function (item) {
                        tab.data = item.content;
                    });
                    toastr.success('保存成功！', '成功');
                });
            };

            $scope.cutboxRuleSortDel = function (tab, sort) {
                if (!sort || !sort.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + $scope.cutBoxFieldsMap[sort.sortBy] + '】吗？',
                    ok: function () {
                        CutboxRule.detailDeletePost({id: sort.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentSort && tab.currentSort.id === sort.id) {
                                    delete tab.currentSort;
                                }
                                tab.data.sorts.splice(tab.data.sorts.indexOf(sort), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };

            $scope.cutboxRuleCompute = function (tab, compute) {
                var rule = tab.data;
                var ccompute = angular.copy(compute);
                tab.currentCompute = ccompute || {
                        tenantId: rule.tenantId,
                        warehouseCode: rule.warehouseCode,
                        cutboxRule: rule.code
                    };
            };

            $scope.saveCutboxRuleCompute = function (tab) {
                if (!tab.currentCompute) return;
                var isNew = !tab.currentCompute.id;
                CutboxRule.computePost({}, tab.currentCompute, function (resp) {
                	tab.currentCompute = null;
                	CutboxRule.contentGet({id: tab.data.id}, function (item) {
                        tab.data = item.content;
                    });
                    toastr.success('保存成功！', '成功');
                });
            };

            $scope.cutboxRuleComputeDel = function (tab, compute) {
                if (!compute || !compute.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + $scope.cutBoxComputeMethodsMap[compute.computeMethod] + '】吗？',
                    ok: function () {
                        CutboxRule.computeDelete({id: compute.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentCompute && tab.currentCompute.id === compute.id) {
                                    delete tab.currentCompute;
                                }
                                tab.data.computes.splice(tab.data.computes.indexOf(compute), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };

            $scope.cutboxRuleGroup = function (tab, group) {
                var rule = tab.data;
                var cgroup = angular.copy(group);
                tab.currentGroup = cgroup || {
                        tenantId: rule.tenantId,
                        warehouseCode: rule.warehouseCode,
                        cutboxRule: rule.code
                    };
            };

            $scope.saveCutboxRuleGroup = function (tab) {
                if (!tab.currentGroup) return;
                var isNew = !tab.currentGroup.id;
                CutboxRule.groupPost({}, tab.currentGroup, function (resp) {
                	tab.currentGroup = null;
                	CutboxRule.contentGet({id: tab.data.id}, function (item) {
                        tab.data = item.content;
                    });
                    toastr.success('保存成功！', '成功');
                });
            };

            $scope.cutboxRuleGroupDel = function (tab, group) {
                if (!group || !group.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + $scope.cutBoxFieldsMap[group.groupBy] + '】吗？',
                    ok: function () {
                        CutboxRule.groupDelete({id: group.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentGroup && tab.currentGroup.id === group.id) {
                                    delete tab.currentGroup;
                                }
                                tab.data.groups.splice(tab.data.groups.indexOf(group), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };
        }
    ]);
})();