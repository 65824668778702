(function () {
    'use strict';

    angular.module('iplanModule')
        .controller('iPlanWarehouseOrderController', iPlanWarehouseOrderController);

    iPlanWarehouseOrderController.$inject = ['NgTableParams', '$timeout', 'toastr', '$uibModal', '$scope', 'enums', 'iPlanWarehousePlan', 'iPlanWarehousePlanDetail', 'iPlanWarehousePlanLog'];

    function iPlanWarehouseOrderController(NgTableParams, $timeout, toastr, $uibModal, $scope, enums, iPlanWarehousePlan, iPlanWarehousePlanDetail, iPlanWarehousePlanLog) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.modalInstance = null;
        vm.planDetailServiceType = enums("plan.PlanDetailServiceType").list("title", "id");
        vm.planDetailServiceTypeEnum = enums("plan.PlanDetailServiceType");
        vm.planLogType = enums("plan.PlanLogType").list("title", "id");
        vm.planLogTypeEnum = enums("plan.PlanLogType");
        vm.planStatus = enums("plan.PlanStatus").list("title", "id");
        vm.planStatusEnum = enums("plan.PlanStatus");
        vm.planType = enums("plan.PlanType").list("title", "id");
        vm.planTypeEnum = enums("plan.PlanType");
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iPlanWarehousePlan.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });



        vm.search = search;
        vm.removeTab = removeTab;
        vm.create = create;
        vm.createEditPage = createEditPage;
        vm.savePlan = savePlan;
        vm.cancelSelected = cancelSelected;
        vm.closeSelected = closeSelected;
        vm.plan = plan;
        vm.closePlan = closePlan;
        vm.cancelPlan = cancelPlan;
        vm.parkChangeEvent = parkChangeEvent;
        vm.createDetail = createDetail;
        vm.createDetailEditPage = createDetailEditPage;
        vm.deleteDetail = deleteDetail;
        vm.refreshTable = refreshTable;

        //-----------------------------------------------------------------------//
        //----------------METHOD-----------------------METHOD--------------------//
        //-----------------------------------------------------------------------//
        function getIsParkEdited(entity) {
            if (!!!entity.data.planNum) {
                return;
            }
            iPlanWarehousePlanDetail.getCountByPlanNum({planNum: entity.data.planNum}, {}, function (resp) {
                entity.isParkEdited = !(!!resp.content && resp.content > 0);
            });
        }

        function getParkList(callback) {
            iPlanWarehousePlan.getParkList({}, {}, function (resp) {
                callback(resp.content);
            }, function (err) {
                toastr.error("获取园区列表失败", "错误");
            });
        }

        function getWarehouseList(parkCode, callback) {
            if (!!!parkCode) {
                return;
            }
            iPlanWarehousePlan.getWarehouseList({parkCode: parkCode}, {}, function (resp) {
                callback(resp.content);
            }, function (err) {
                toastr.error("获取仓库列表失败", "错误");
            });
        }

        function getParkLotAndGatewayList(parkCode, callback) {
            if (!!!parkCode) {
                return;
            }
            iPlanWarehousePlan.getParkLotAndGatewayList({parkCode: parkCode}, {}, function (resp) {
                callback(resp.content);
            }, function (err) {
                toastr.error("获取停车场和检入检出门列表失败", "错误");
            });
        }

        function getPlatformList(parkCode, warehouseCode, callback) {
            if (!!!parkCode || !!!warehouseCode) {
                return;
            }
            iPlanWarehousePlan.getPlatformList({parkCode: parkCode, warehouseCode: warehouseCode}, {}, function (resp) {
                callback(resp.content);
            }, function (err) {
                toastr.error("获取月台列表失败", "错误");
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function refreshTable(table) {
            table.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function parkChangeEvent(entity) {
            if (!!!entity.park.code) {
                return;
            }
            getParkLotAndGatewayList(entity.park.code, function (data) {
                entity.parkLotList = data.parkLotList;
                entity.parkGatewayInList = data.parkGatewayInList;
                entity.parkGatewayOutList = data.parkGatewayOutList;
                entity.parkGatewayIn = null;
                entity.parkGatewayOut = null;
                entity.parkLot = null;
            });
        }

        function validatePlan(entity) {
            if (!!!entity.data.planNum || !!!entity.data.status || !!!entity.data.planType || !!!entity.park) {
                toastr.info("信息未填完整", "提示");
                return false;
            }
            return true;
        }

        function create() {
            iPlanWarehousePlan.preSave({}, {}, function (response) {
                var preData = {};
                preData.planNum = response.content.planNum;
                preData.status = response.content.status;
                var entity = {
                    heading: '创建预约单',
                    contentId: "warehousePlan-edit.html",
                    data: {
                        planNum: preData.planNum,
                        status: preData.status
                    },
                    carrier: null,
                    carrierCode: null,
                    park: null,
                    parkGatewayIn: null,
                    parkGatewayOut: null,
                    parkLot: null,
                    platform: null,
                    parkList:null,
                    parkGatewayInList:null,
                    parkGatewayOutList:null,
                    parkLotList:null,
                    roles: [],
                    active: 0,
                    newPlan: true,
                    isParkEdited:false
                };
                getParkList(function (data) {
                    entity.parkList = data;
                });
                vm.tabs.push(entity);
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }, function (error) {
                toastr.error("创建失败", "错误");
            });
        }

        function createEditPage(row) {
            var entity = {
                heading: '预约单详情',
                contentId: "warehousePlan-edit.html",
                data: row,
                carrier: {
                    code: row.carrierCode,
                    name: row.carrierName
                },
                carrierCode: row.carrierCode,
                park: {
                    code: row.packCode,
                    name: row.packName
                },
                parkGatewayIn: {
                    code: row.packEntranceCode,
                    name: row.parkEntranceName
                },
                parkGatewayOut: {
                    code: row.packExitCode,
                    name: row.parkExitName
                },
                parkLot: {
                    code: row.parkingLotCode,
                    name: row.parkingLotName
                },
                platform: {
                    code: row.platformCode,
                    name: row.platformName
                },
                parkList:null,
                parkGatewayInList:null,
                parkGatewayOutList:null,
                parkLotList:null,
                planDetailList: new NgTableParams({count: 10}, {
                    getData: function (params) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}
                            , {planNum: row.planNum}, params.filter());
                        return iPlanWarehousePlanDetail.search({}, postParams,
                            function (resp) {
                                params.total(resp.content.tc);
                                return resp.content.datas;
                            }).$promise.then(function (data) {
                            return data.content.datas;
                        });
                    }
                }),
                planLogList: new NgTableParams({count: 10}, {
                    getData: function (params) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}
                            , {planNum: row.planNum}, params.filter());
                        return iPlanWarehousePlanLog.search({}, postParams,
                            function (resp) {
                                params.total(resp.content.tc);
                                return resp.content.datas;
                            }).$promise.then(function (data) {
                            return data.content.datas;
                        });
                    }
                }),
                roles: [],
                active: 0,
                newPlan: false,
                isParkEdited:false
            };
            getParkList(function (data) {
                entity.parkList = data;
            });
            getParkLotAndGatewayList(row.packCode, function (data) {
                entity.parkLotList = data.parkLotList;
                entity.parkGatewayInList = data.parkGatewayInList;
                entity.parkGatewayOutList = data.parkGatewayOutList;
            });
            getIsParkEdited(entity);
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function cancelSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                var bean = paramBeans[i];
                if (bean.status > vm.planStatusEnum.value("PLANNED")) {
                    toastr.warning("只有新建和已计划状态下的预约单才能执行取消操作", "警告");
                    return;
                }
                ids.push(bean.id);
            }
            iPlanWarehousePlan.cancelOrClosePlan({ids: ids, isClose: false}, {}, function (resp) {
                toastr.success("成功取消" + resp.content + "条预约单");
                vm.search();
            }, function (err) {
                toastr.error("取消失败", "错误");
            });
        }

        function closeSelected() {
            var paramBeans = vm.tableParams.getSelected();
            var ids = [];
            if (!!!paramBeans || paramBeans.length == 0) {
                toastr.warning("选择不能为空", "警告");
                return;
            }
            for (var i in paramBeans) {
                var bean = paramBeans[i];
                if (bean.status != vm.planStatusEnum.value("OUT_CHECKED")) {
                    toastr.warning("只有已检出状态下的预约单才能执行关闭操作", "警告");
                    return;
                }
                ids.push(bean.id);
            }
            iPlanWarehousePlan.cancelOrClosePlan({ids: ids, isClose: true}, {}, function (resp) {
                toastr.success("成功关闭" + resp.content + "条预约单");
                vm.search();
            }, function (err) {
                toastr.error("关闭失败", "错误");
            });
        }


        function savePlan(entity, tabIndex) {
            if (validatePlan(entity)) {
                entity.data.packCode = entity.park.code;
                entity.data.packName = entity.park.name;
                if (!!entity.carrier) {
                    entity.data.carrierCode = entity.carrier.code;
                    entity.data.carrierName = entity.carrier.name;
                } else {
                    entity.data.carrierCode = null;
                    entity.data.carrierName = null;
                }
                if (!!entity.parkGatewayIn) {
                    entity.data.packEntranceCode = entity.parkGatewayIn.code;
                    entity.data.parkEntranceName = entity.parkGatewayIn.name;
                } else {
                    entity.data.packEntranceCode = null;
                    entity.data.parkEntranceName = null;
                }
                if (!!entity.parkGatewayOut) {
                    entity.data.packExitCode = entity.parkGatewayOut.code;
                    entity.data.parkExitName = entity.parkGatewayOut.name;
                } else {
                    entity.data.packExitCode = null;
                    entity.data.parkExitName = null;
                }
                if (!!entity.parkLot) {
                    entity.data.parkingLotCode = entity.parkLot.code;
                    entity.data.parkingLotName = entity.parkLot.name;
                } else {
                    entity.data.parkingLotCode = null;
                    entity.data.parkingLotName = null;
                }
                if (!!entity.platform) {
                    entity.data.platformCode = entity.platform.code;
                    entity.data.platformName = entity.platform.name;
                } else {
                    entity.data.platformCode = null;
                    entity.data.platformName = null;
                }

                iPlanWarehousePlan.save({}, entity.data, function (response) {
                    toastr.success((!entity.newPlan ? "更新" : "创建") + "成功");
                    entity.data = response.content;
                    entity.carrier = {
                        code: response.content.carrierCode,
                        name: response.content.carrierName
                    };
                    entity.carrierCode = response.content.carrierCode;
                    entity.park = {
                        code: response.content.packCode,
                        name: response.content.packName
                    };
                    entity.parkGatewayIn = {
                        code: response.content.packEntranceCode,
                        name: response.content.parkEntranceName
                    };
                    entity.parkGatewayOut = {
                        code: response.content.packExitCode,
                        name: response.content.parkExitName
                    };
                    entity.parkLot = {
                        code: response.content.parkingLotCode,
                        name: response.content.parkingLotName
                    };
                    entity.platform = {
                        code: response.content.platformCode,
                        name: response.content.platformName
                    };
                    if (entity.newPlan) {
                        entity.heading = "预约单详情";
                        entity.planDetailList = new NgTableParams({count: 10}, {
                            getData: function (params) {
                                var postParams = angular.merge({pn: params.page(), ps: params.count()}
                                    , {planNum: response.content.planNum}, params.filter());
                                return iPlanWarehousePlanDetail.search({}, postParams,
                                    function (resp) {
                                        params.total(resp.content.tc);
                                        return resp.content.datas;
                                    }).$promise.then(function (data) {
                                    return data.content.datas;
                                });
                            }
                        });
                        entity.planLogList = new NgTableParams({count: 10}, {
                            getData: function (params) {
                                var postParams = angular.merge({pn: params.page(), ps: params.count()}
                                    , {planNum: response.content.planNum}, params.filter());
                                return iPlanWarehousePlanLog.search({}, postParams,
                                    function (resp) {
                                        params.total(resp.content.tc);
                                        return resp.content.datas;
                                    }).$promise.then(function (data) {
                                    return data.content.datas;
                                });
                            }
                        });
                        entity.newPlan = false;
                        entity.isParkEdited = true;
                    }
                }, function (error) {
                    toastr.error("保存失败", "错误");
                });
            }
        }

        function plan(entity, tabIndex) {

            iPlanWarehousePlan.getParkList({}, {}, function (resp) {
                if (resp.content) {
                    var calenderParks = resp.content;
                    //将第一个元素添加成 全部
                    calenderParks.splice(0, 0, {code: 'NULL', name: '全部'});

                    getWarehouseList(entity.data.packCode, function (data) {
                        var warehouseList = data;
                        vm.modalInstance = $uibModal.open({
                            templateUrl: 'makeOrder.html',
                            controller: makeOrderController,
                            controllerAs: '$ctrl',
                            size: "xl",
                            backdrop: 'static',
                            resolve: {
                                item: {
                                    tab: entity,
                                    markOrder: {
                                        park: {
                                            code: entity.data.packCode,
                                            name: entity.data.packName
                                        },
                                        warehouse: null,
                                        parkGatewayIn: {
                                            code: entity.data.packEntranceCode,
                                            name: entity.data.parkEntranceName
                                        },
                                        parkGatewayOut: {
                                            code: entity.data.packExitCode,
                                            name: entity.data.parkExitName
                                        },
                                        platform: {},
                                        planStartTime: null,
                                        planEndTime: null
                                    },
                                    parkList: entity.parkList,
                                    warehouseList: warehouseList,
                                    parkGatewayInList: entity.parkGatewayInList,
                                    parkGatewayOutList: entity.parkGatewayOutList,
                                    platformList: null,
                                    calender: {
                                        showMonth: true
                                    },
                                    calenderParks:calenderParks
                                }
                            }
                        });
                        vm.modalInstance.result.then(function (item) {
                            iPlanWarehousePlan.refreshPlan({id: item.tab.data.id}, {}, function (resp) {
                                item.tab.data = resp.content;
                                item.tab.parkGatewayIn = {
                                    code: resp.content.packEntranceCode,
                                    name: row.parkEntranceName
                                };
                                item.tab.parkGatewayOut = {
                                    code: resp.content.packExitCode,
                                    name: resp.content.parkExitName
                                };
                            });
                        });
                    });







                }
            });




        }

        function closePlan(entity, tabIndex) {
            if (entity.data.status != vm.planStatusEnum.value("OUT_CHECKED")) {
                toastr.warning("只有已检出状态下的预约单才能执行关闭操作", "警告");
                return;
            }
            iPlanWarehousePlan.cancelOrClosePlan({ids: [entity.data.id], isClose: true}, {}, function (resp) {
                toastr.success("成功关闭" + resp.content + "条预约单");
                iPlanWarehousePlan.refreshPlan({id: entity.data.id}, {}, function (resp) {
                    entity.data = resp.content;
                });
            }, function (err) {
                toastr.error("关闭失败", "错误");
            });
        }

        function cancelPlan(entity) {
            if (entity.data.status > vm.planStatusEnum.value("PLANNED")) {
                toastr.warning("只有新建和已计划状态下的预约单才能执行取消操作", "警告");
                return;
            }
            iPlanWarehousePlan.cancelOrClosePlan({ids: [entity.data.id], isClose: false}, {}, function (resp) {
                toastr.success("成功取消" + resp.content + "条预约单");
                iPlanWarehousePlan.refreshPlan({id: entity.data.id}, {}, function (resp) {
                    entity.data = resp.content;
                });
            }, function (err) {
                toastr.error("取消失败", "错误");
            });
        }

        function createDetailEditPage(entity, row) {
            getWarehouseList(entity.data.packCode, function (data) {
                var warehouseList = data;
                getPlatformList(entity.data.packCode, row.warehouseCode, function (data) {
                    var platformList = data;
                    var param = {
                        templateUrl: 'plan-detail-edit.html',
                        controller: newDetail,
                        controllerAs: '$ctrl',
                        size: "md",
                        backdrop: 'static',
                        resolve: {
                            item: {
                                tab: entity,
                                planDetail: row,
                                owner: {
                                    code: row.ownerCode,
                                    name: row.ownerName
                                },
                                ownerCode: row.ownerCode,
                                platform: {
                                    code: row.platformCode,
                                    name: row.platformName
                                },
                                bizOrder: {},
                                bizOrderCode: row.bizOrderNum,
                                newDetail: false,
                                planTypeEnum: vm.planDetailServiceTypeEnum,
                                planDetailServiceType: vm.planDetailServiceType,
                                warehouseList: warehouseList,
                                platformList: platformList,
                                changeFlag: 1
                            }
                        }

                    };
                    if (row.bizType == vm.planDetailServiceTypeEnum.value('SHIP')) {
                        param.resolve.item.bizOrder = {
                            soNum: row.bizOrderNum,
                            totalQty: row.totalQty,
                            totalStdWeight: row.totalWeight
                        };
                    } else {
                        param.resolve.item.bizOrder = {
                            asnNum: row.bizOrderNum,
                            qty: row.totalQty,
                            totalStdWeight: row.totalWeight
                        };
                    }
                    vm.modalInstance = $uibModal.open(param);
                    vm.modalInstance.result.then(function (item) {
                        item.tab.planDetailList.reload();
                    });
                });
            });
        }

        function deleteDetail(entity, row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                size: "sm",
                resolve: {
                    item: {
                        tab: entity,
                        planDetail: row
                    }
                }
            });

            vm.modalInstance.result.then(function (item) {
                item.tab.planDetailList.reload();
            });
        }

        function createDetail(entity) {
            getWarehouseList(entity.data.packCode, function (data) {
                var warehouseList = data;
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'plan-detail-edit.html',
                    controller: newDetail,
                    controllerAs: '$ctrl',
                    size: "md",
                    backdrop: 'static',
                    resolve: {
                        item: {
                            tab: entity,
                            planDetail: {
                                planNum: entity.data.planNum
                            },
                            owner: null,
                            ownerCode: null,
                            platform: null,
                            bizOrder: null,
                            bizOrderCode: null,
                            newDetail: true,
                            planTypeEnum: vm.planDetailServiceTypeEnum,
                            planDetailServiceType: vm.planDetailServiceType,
                            warehouseList: warehouseList,
                            platformList: null,
                            changeFlag: 0
                        }
                    }
                });
                vm.modalInstance.result.then(function (item) {
                    item.tab.planDetailList.reload();
                });
            });
        }

        //-----------------------------------------------------------------------//
        //---------deleteModalController------------deleteModalController------------//
        //-----------------------------------------------------------------------//
        deleteModal.$inject = ['$uibModalInstance', 'item', 'iPlanWarehousePlanDetail', 'toastr'];

        function deleteModal($uibModalInstance, item, iPlanWarehousePlanDetail, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                iPlanWarehousePlanDetail.delete({id: $ctrl.item.planDetail.id}, {}, function (resp) {
                    getIsParkEdited($ctrl.item.tab);
                    toastr.success("删除成功", "成功");
                    $uibModalInstance.close($ctrl.item);
                }, function (err) {
                    toastr.error("删除失败", "错误");
                });
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        //-----------------------------------------------------------------------//
        //---------makeOrderController------------makeOrderController------------//
        //-----------------------------------------------------------------------//
        makeOrderController.$inject = ['$uibModalInstance', 'item', 'iPlanWarehousePlan','$scope'];

        function makeOrderController($uibModalInstance, item, iPlanWarehousePlan,$scope) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.showMonth=true;
            $ctrl.copyMonthData=[];         //备份日历月数据


            $ctrl.cancel = cancel;
            $ctrl.comfirmPlan = comfirmPlan;
            $ctrl.warehouseChangeEvent = warehouseChangeEvent;
            $ctrl.changeYear=changeYear;            //修改年份时的操作
            $ctrl.changeMonth=changeMonth;          //修改月份时的操作
            $ctrl.backToday=backToday;              //返回今天
            $ctrl.backToCalender=backToCalender;    //从月台显示页面回到日历页面
            $ctrl.calenderParks=item.calenderParks; //日历页面园区选项列表
            $ctrl.calenderPlatform=[{code: 'NULL', name: '全部'}];

            $ctrl.data={
                park:$ctrl.calenderParks[0],
                warehouse:{code: 'NULL', name: '全部'},
                platform:{code: 'NULL', name: '全部'}
            };                       //日历切换数据时的选项数据

            $ctrl.changeCalenderPark=changeCalenderPark;    //切换园区时的数据展示
            $ctrl.changeCalenderWarehouse=changeCalenderWarehouse;    //切换仓库时的数据展示
            $ctrl.changeCalenderPlatform=changeCalenderPlatform;      //切换月台

            getWarehouseList();         //页面打开时先请求一下库位

            //切换选项时的数据展示
            function changeCalenderPark() {
                getWarehouseList();         //改变园区之后先请求对应仓库数据
                getCalenderMonthData();
                getCalenderDayData();
            }
            function changeCalenderWarehouse() {
                getCalenderMonthData();
                getCalenderDayData();

                if($ctrl.data.warehouse.code==='NULL'){
                    //为null的时候要情况月台数据
                    $ctrl.calenderPlatform=[];
                    $ctrl.calenderPlatform.splice(0, 0, {code: 'NULL', name: '全部'});
                    $ctrl.data.platform=$ctrl.calenderPlatform[0];

                }else{
                    //改变仓库后也要切换对应的月台数据
                    iPlanWarehousePlan.getPlatformList({parkCode: $ctrl.data.park.code, warehouseCode: $ctrl.data.warehouse.code}, {}, function (resp) {
                        $ctrl.calenderPlatform=resp.content;
                        $ctrl.calenderPlatform.splice(0, 0, {code: 'NULL', name: '全部'});
                        $ctrl.data.platform=$ctrl.calenderPlatform[0];
                    }, function (err) {
                        toastr.error("获取月台列表失败", "错误");
                    });
                }
            }

            function changeCalenderPlatform() {
                console.log($ctrl.calenderData.plans,'data')
                if($ctrl.data.platform.code==='NULL'){
                    $ctrl.calenderData.someday=$ctrl.copyMonthData;
                    $ctrl.calenderData.plans=$ctrl.copyDayData;


                }else{
                    var arr=[],dayArr=[];
                    $ctrl.copyMonthData.forEach(function (v) {
                        if(v.platformCode===$ctrl.data.platform.code){
                            arr.push(v);
                        }
                    });
                    $ctrl.calenderData.someday=arr;
                    //处理切换月台时的天的选择
                    $ctrl.copyDayData.forEach(function (v) {
                        if(v.platformCode===$ctrl.data.platform.code){
                            dayArr.push(v);
                        }
                    });
                    $ctrl.calenderData.plans=dayArr;

                }
            }


            var date=new Date();
            $ctrl.years = [];
            for (var j = 2015; j <= year; j++) {
                $ctrl.years.push({
                    id: j,
                    title: j
                });
            }
            $ctrl.months = [];
            for (var i = 1; i <= 12; i++) {
                $ctrl.months.push({
                    id: i,
                    title: i
                });
            }


            $ctrl.calenderData={
                year:date.getFullYear(),
                month:date.getMonth()+1,
                someday:[],
                park:$ctrl.item.markOrder.park,
                warehouse:$ctrl.item.markOrder.warehouse?$ctrl.item.markOrder.warehouse:{code: 'NULL', name: '全部'},
            };

            $ctrl.calenderDatas={
                date:new Date('2018-01-01'),
                warehouse:$ctrl.item.markOrder.warehouse?$ctrl.item.markOrder.warehouse:{code: 'NULL', name: '全部'},
                justShowPlatform:false       //只显示月台
            };

            getCalenderMonthData();         //弹出框打开时要先请求一次数据

            function cancel() {
                $uibModalInstance.dismiss('cancel');
                iPlanWarehousePlan.refreshPlan({id: $ctrl.item.tab.data.id}, {}, function (resp) {
                    $ctrl.item.tab.data = resp.content;
                    $ctrl.item.tab.parkGatewayIn = {
                        code: resp.content.packEntranceCode,
                        name: resp.content.parkEntranceName
                    };
                    $ctrl.item.tab.parkGatewayOut = {
                        code: resp.content.packExitCode,
                        name: resp.content.parkExitName
                    };
                });
            }

            function validate(makeOrder) {
                if (!!!makeOrder.park || !!!makeOrder.warehouse || !!!makeOrder.warehouse.code ||
                    !!!makeOrder.planStartTime || !!!makeOrder.planEndTime) {
                    toastr.info("信息未填完", "提示");
                    return false;
                }
                if (makeOrder.planStartTime > makeOrder.planEndTime) {
                    toastr.info("预计开始时间必须小于预计结束时间", "提示");
                    return false;
                }

                return true;
            }

            function comfirmPlan() {
                if (validate($ctrl.item.markOrder)) {
                    var data = {
                        id: $ctrl.item.tab.data.id,
                        planNum: $ctrl.item.tab.data.planNum,
                        packCode: $ctrl.item.markOrder.park.code,
                        packName: $ctrl.item.markOrder.park.name,
                        warehouseCode: $ctrl.item.markOrder.warehouse.code,
                        packEntranceCode: $ctrl.item.markOrder.parkGatewayIn.code,
                        parkEntranceName: $ctrl.item.markOrder.parkGatewayIn.name,
                        packExitCode: $ctrl.item.markOrder.parkGatewayOut.code,
                        parkExitName: $ctrl.item.markOrder.parkGatewayOut.name,
                        platformCode: $ctrl.item.markOrder.platform.code,
                        platformName: $ctrl.item.markOrder.platform.name,
                        planStartTime: $ctrl.item.markOrder.planStartTime,
                        planEndTime: $ctrl.item.markOrder.planEndTime
                    };
                    iPlanWarehousePlan.comfirmPlan({}, data, function (resp) {
                        toastr.success("预约成功", "成功");
                        $uibModalInstance.close($ctrl.item);
                    }, function (err) {
                        toastr.error("预约失败", "错误");
                    });
                }
            }

            function warehouseChangeEvent() {
                if (!!!$ctrl.item.markOrder.warehouse.code || !!!$ctrl.item.markOrder.park.code) {
                    return;
                }
                getPlatformList($ctrl.item.markOrder.park.code, $ctrl.item.markOrder.warehouse.code, function (data) {
                    $ctrl.item.platformList = data;
                    $ctrl.item.markOrder.platform = {};
                });
            }

            //修改年份时进行的数据请求操作
            function changeYear() {
                getCalenderMonthData();
            }
            //修改月份时进行的数据请求操作
            function changeMonth() {
                getCalenderMonthData();
            }
            //返回今天
            function backToday() {
                var date=new Date();
                if($ctrl.calenderData.year!==date.getFullYear() || $ctrl.calenderData.month!==date.getMonth()+1){
                    $ctrl.calenderData.year=date.getFullYear();
                    $ctrl.calenderData.month=date.getMonth()+1;
                    getCalenderMonthData();
                }
            }
            //返回日历显示页面
            function backToCalender() {
                $ctrl.showMonth=true;
            }

            //获取日历月数据
            function getCalenderMonthData() {
                var param = {
                    month: new Date($ctrl.calenderData.year, $ctrl.calenderData.month - 1),
                    parkCode: $ctrl.data.park.code,
                    warehouseCode: $ctrl.data.warehouse.code?$ctrl.data.warehouse.code:'NULL'
                };
                iPlanWarehousePlan.getWarehousePlanByMonth({}, param, function (resp) {
                    if (resp.content) {
                        $ctrl.calenderData.someday=resp.content;
                        $ctrl.copyMonthData=$ctrl.calenderData.someday;     //把月数据备份起来
                    }
                });
            }


            //获取日历天数据
            function getCalenderDayData() {
                var date=new Date($ctrl.calenderDatas.date);
                var param = {
                    day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                    parkCode: $ctrl.data.park.code,
                    warehouseCode: $ctrl.data.warehouse.code?$ctrl.data.warehouse.code:'NULL'
                };
                iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                    if (resp.content) {
                        //天页面展示所需的数据
                        $ctrl.calenderData.plans=resp.content;
                        $ctrl.copyDayData=$ctrl.calenderData.plans;
                    }
                });
            }


            //获取园区下对应仓库列表的请求
            function getWarehouseList() {
                iPlanWarehousePlan.getWarehouseList({parkCode: $ctrl.data.park.code}, {}, function (resp) {
                    if (resp.content) {
                        $ctrl.calenderWarehouses=resp.content;
                        $ctrl.calenderWarehouses.splice(0, 0, {code: 'NULL', name: '全部'});
                        $ctrl.data.warehouse=$ctrl.calenderWarehouses[0];
                    }
                });

            }

            //获取日历天数据
            $scope.$watch('$ctrl.calenderDatas.date',function (n) {
                var date=new Date(n);
                var param = {
                    day: new Date(date.getFullYear(), date.getMonth(),date.getDate()),
                    parkCode: $ctrl.data.park.code,
                    warehouseCode: $ctrl.data.warehouse.code?$ctrl.data.warehouse.code:'NULL'
                };
                iPlanWarehousePlan.getWarehousePlanByDay({}, param, function (resp) {
                    if (resp.content) {
                        //天页面展示所需的数据
                        $ctrl.calenderData.plans=resp.content;
                    }
                });
            });



            $ctrl.showData=function () {
                console.log($ctrl.item.markOrder.warehouse,'仓库')
            }

        }


        //-----------------------------------------------------------------------//
        //---------newDetailController------------newDetailController------------//
        //-----------------------------------------------------------------------//
        newDetail.$inject = ['$uibModalInstance', 'item', 'iPlanWarehousePlanDetail', 'toastr'];

        function newDetail($uibModalInstance, item, iPlanWarehousePlanDetail, toastr) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.cancel = cancel;
            $ctrl.savePlanDetail = savePlanDetail;
            $ctrl.warehouseDetailChangeEvent = warehouseDetailChangeEvent;
            $ctrl.planDetailServiceTypeChangeEvent = planDetailServiceTypeChangeEvent;
            $ctrl.validateBizOrder = validateBizOrder;
            function cancel() {
                $uibModalInstance.dismiss('cancel');
                $ctrl.item.tab.planDetailList.reload();
            }

            function planDetailServiceTypeChangeEvent() {
                if (!$ctrl.item.newDetail && $ctrl.item.changeFlag > 0) {
                    $ctrl.item.changeFlag--;
                    return;
                }

                console.log(111)
                $ctrl.item.bizOrder = null;
                $ctrl.item.bizOrderCode = null;
            }

            function warehouseDetailChangeEvent() {
                planDetailServiceTypeChangeEvent();
                if (!!!$ctrl.item.planDetail.warehouseCode || !!!$ctrl.item.tab.data.packCode) {
                    return;
                }
                getPlatformList($ctrl.item.tab.data.packCode, $ctrl.item.planDetail.warehouseCode, function (data) {
                    $ctrl.item.platformList = data;
                    $ctrl.item.platform = {};
                });
            }

            function validatePlanDetail(item) {
                if (!!!item.planDetail.bizType || !!!item.planDetail.warehouseCode
                    || !!!item.owner || !!!item.bizOrder) {
                    toastr.info("信息未填完", "提示");
                    return false;
                }
                return true;
            }

            function validateBizOrder(type, code) {
                if (!!!type || !!!code) {
                    return;
                }
                iPlanWarehousePlanDetail.isBizOrderUsed({
                    id: $ctrl.item.planDetail.id,
                    type: type,
                    bizCode: code
                }, {}, function (resp) {
                    if (resp.content.isUsed) {
                        toastr.error("此单据已被'" + resp.content.planNum + "'预约单使用,请选择其他单据", "错误");
                    }
                });
            }
            function savePlanDetail() {
                if (validatePlanDetail($ctrl.item)) {
                    $ctrl.item.planDetail.ownerCode = $ctrl.item.owner.code;
                    $ctrl.item.planDetail.ownerName = $ctrl.item.owner.name;
                    $ctrl.item.planDetail.platformCode = !!$ctrl.item.platform ?
                        $ctrl.item.platform.code : null;
                    $ctrl.item.planDetail.platformName = !!$ctrl.item.platform ?
                        $ctrl.item.platform.name : null;
                    if ($ctrl.item.planDetail.bizType == $ctrl.item.planTypeEnum.value('SHIP')) {
                        $ctrl.item.planDetail.bizOrderNum = $ctrl.item.bizOrder.soNum;
                        $ctrl.item.planDetail.totalQty = $ctrl.item.bizOrder.totalQty;
                        $ctrl.item.planDetail.totalWeight = $ctrl.item.bizOrder.totalStdWeight;
                    } else {
                        $ctrl.item.planDetail.bizOrderNum = $ctrl.item.bizOrder.asnNum;
                        $ctrl.item.planDetail.totalQty = $ctrl.item.bizOrder.qty;
                        $ctrl.item.planDetail.totalWeight = $ctrl.item.bizOrder.totalStdWeight;
                    }
                    iPlanWarehousePlanDetail.save({}, $ctrl.item.planDetail, function (resp) {
                        getIsParkEdited($ctrl.item.tab);
                        toastr.success("保存成功", "成功");
                        $uibModalInstance.close($ctrl.item);
                    }, function (err) {
                        toastr.error("保存失败", "错误");
                    });
                }
            }
        }

        //-----------------------------------------------------------------------//
        //-----------------------------------------------------------------------//
        //-----------------------------------------------------------------------//
        vm.edit = edit;//编辑

        //子页面多级菜单区域
        vm.handleActive = 0;
        vm.research = research;       //重新查询
        vm.addBill = addBill;         //添加单据
        vm.editBill = editBill;       //编辑单据
        vm.makeOrder = makeOrder;     //预约一个单子
        vm.showBtn = showBtn;         //控制什么tab页该显示何种按钮


        //预约页面所用变量
        vm.parks = [
            {
                id: 0,
                title: '全部'
            },
            {
                id: 1,
                title: '园区1'
            },
            {
                id: 2,
                title: '园区2'
            },
            {
                id: 3,
                title: '园区3'
            },
        ];      //园区
        vm.warehouses = [
            {
                id: 0,
                title: '全部'
            },
            {
                id: 1,
                title: '仓库1'
            },
            {
                id: 2,
                title: '仓库2'
            },
            {
                id: 3,
                title: '仓库3'
            },
        ];  //仓库
        vm.platforms = [
            {
                id: 0,
                title: '全部'
            },
            {
                id: 1,
                title: '月台1'
            },
            {
                id: 2,
                title: '月台2'
            },
            {
                id: 3,
                title: '月台3'
            },
        ];  //仓库
        var time = new Date();
        var year = time.getFullYear();            //年
        var month = time.getMonth() + 1;            //月
        var date = time.getDate();                 //日
        vm.showMonth = true;


        //日历组件需要的元素
        // vm.params={
        //     year:vm.year,       //年
        //     month:vm.month,     //月
        //     date:vm.date,       //日
        //     park:vm.park.title,            //园区
        //     warehouse:vm.warehouse.title,   //仓库
        //     platform:vm.platform.title,        //月台
        // };

        vm.backToToday = backToToday;     //返回今天


        function edit(row) {
            var entity = {
                isCreate: false,
                heading: '预约单详情',
                contentId: "editOrCreate.html",
                data: {
                    orderNum: row.orderNum,
                    status: row.status,
                    type: row.type,
                    park: row.park,
                    checkInDoor: row.checkInDoor,
                    checkOutDoor: row.checkOutDoor,
                    plateNum: row.plateNum,
                    trailerNum: row.trailerNum,
                    contact: row.contact,
                    contactNum: row.contactNum,
                    orderer: row.orderer,
                    estimatedStartingTime: row.estimatedStartingTime,
                    estimatedEndTime: row.estimatedEndTime,
                    actualStartTime: row.actualStartTime,
                    actualEndTime: row.actualEndTime,
                    founder: row.founder,
                    orderTime: row.orderTime,
                    updater: row.updater,
                    updaterTime: row.updaterTime,
                    canceler: row.canceler,
                    cancelTime: row.cancelTime,
                },
                tableParams: [
                    {
                        businessType: '仓库收货',
                        warehouse: '仓库1',
                        platform: '月台1',
                        ownerCode: '货主1',
                        ownerName: '货主1',
                        docNumber: '112553262',          //单据号
                        totalQty: '22',                  //总数量
                        totalWeight: '457',              //总重量
                    },
                    {
                        businessType: '仓库发货',
                        warehouse: '仓库3',
                        platform: '月台6',
                        ownerCode: '货主4',
                        ownerName: '货主4',
                        docNumber: '746745458',          //单据号
                        totalQty: '47',                  //总数量
                        totalWeight: '13',              //总重量
                    },
                ],
                newTableParams: [
                    {
                        operateTime: '2017-01-02 12:00:00',
                        operatorName: '长者',
                        operateType: '取消',
                        operateContent: '今天早上，天气晴朗，阳光明媚'
                    },
                    {
                        operateTime: '2017-06-01 13:22:16',
                        operatorName: '地底世界',
                        operateType: '停泊',
                        operateContent: '哇哈哈啊，阿佛按时发货'
                    },
                ],
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function research() {

        }

        function addBill() {
            $uibModal.open({
                templateUrl: 'addBill.html',
                controller: billModle,
                controllerAs: '$ctrl',
                resolve: {
                    item: undefined
                }
            });
        }

        function editBill(row) {
            $uibModal.open({
                templateUrl: 'addBill.html',
                controller: billModle,
                controllerAs: '$ctrl',
                resolve: {
                    item: row
                }
            });
        }

        //预约，生成一个新的tab页
        function makeOrder() {
            var entity = {
                isCreate: false,
                heading: '进行预约',
                contentId: "makeOrder.html",
                data: {
                    showMonth: true,
                    park: 0,
                    warehouse: 0,
                    platform: 0,
                    year: year,
                    month: month,
                    date: date
                },
                params: {
                    // year:vm.year,       //年
                    // month:vm.month,     //月
                    // date:vm.date,       //日
                    // park:vm.park.title,            //园区
                    // warehouse:vm.warehouse.title,   //仓库
                    // platform:vm.platform.title,        //月台
                },
                // tableParams:[
                //     {
                //         businessType:'仓库收货',
                //         warehouse:'仓库1',
                //         platform:'月台1',
                //         ownerCode:'货主1',
                //         ownerName:'货主1',
                //         docNumber:'112553262',          //单据号
                //         totalQty:'22',                  //总数量
                //         totalWeight:'457',              //总重量
                //     },
                //     {
                //         businessType:'仓库发货',
                //         warehouse:'仓库3',
                //         platform:'月台6',
                //         ownerCode:'货主4',
                //         ownerName:'货主4',
                //         docNumber:'746745458',          //单据号
                //         totalQty:'47',                  //总数量
                //         totalWeight:'13',              //总重量
                //     },
                // ],
                // newTableParams:[
                //     {
                //         operateTime:'2017-01-02 12:00:00',
                //         operatorName:'长者',
                //         operateType:'取消',
                //         operateContent:'今天早上，天气晴朗，阳光明媚'
                //     },
                //     {
                //         operateTime:'2017-06-01 13:22:16',
                //         operatorName:'地底世界',
                //         operateType:'停泊',
                //         operateContent:'哇哈哈啊，阿佛按时发货'
                //     },
                // ],
                active: true,
                showOrderBtn: true
            };
            console.log(entity)
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                vm.isOrder = true;
                vm.showMonth = true;
            }, 0);
        }

        function showBtn(e) {
            if (e.showOrderBtn) {
                vm.isOrder = true;
            } else {
                vm.isOrder = false;
            }
        }

        //返回今天
        function backToToday(e) {
            e.data.year = year;
            e.data.month = month;
            e.data.date = date;
        }

        //业务单据controller
        billModle.$inject = ['item'];

        function billModle(item) {
            var $ctrl = this;

        }

        //预约：新增预约单controller
        makeAppoint.$inject = ['item'];

        function makeAppoint(item) {
            console.log(1)
        }

        $scope.$on('$destroy', function () {
            console.log('销毁')
        });


        /**
         * 获取仓库预约
         */
        function getWarehousePlan() {
            var param = {
                month: new Date(vm.data.year, vm.data.month - 1),
                parkCode: vm.data.park.code,
                warehouseCode: vm.data.warehouse.code
            };
            iPlanWarehousePlan.getWarehousePlan({}, param, function (resp) {
                if (resp.content) {
                    vm.datas.date = resp.content;
                    console.info(vm.datas);
                }
            });
        }

    }
})();
