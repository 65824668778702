(function () {
    'use strict';

    angular.module('idashboardModule')
        .factory('Warehouse3D', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/warehouse3D', {}, {
                'getWarehouseList': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getWarehouseList',
                    method: 'GET'
                },
                'getZoneList': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getZoneList',
                    method: 'GET'
                },
                'getLocationList': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getLocationList',
                    method: 'GET'
                },
                'getRoadwayList': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getRoadwayList',
                    method: 'GET'
                },
                'getLocCodeByCaseNum': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getLocCodeByCaseNum',
                    method: 'GET'
                },
                'getLocCodeBySkuCode': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getLocCodeBySkuCode',
                    method: 'GET'
                },
                'getLocCodeByOwnerCode': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getLocCodeByOwnerCode',
                    method: 'GET'
                },
                'getLocCodeByLpn': {
                    url: iServiceConfig.idashboardHost + '/web/warehouse3D/getLocCodeByLpn',
                    method: 'GET'
                }
            });
        }]);
})();