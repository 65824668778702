(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('InventorySearch', InventorySearch);

    InventorySearch.$inject = ['$resource', 'iServiceConfig'];

    function InventorySearch ($resource, iServiceConfig) {
        var service = $resource('web/inventorySearch', {}, {
            'ownerInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/ownerInventory", method: 'POST'},
            'skuInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/skuInventory", method: 'POST'},
            'skuLocInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/skuLocInventory", method: 'POST'},
            'skuLocLPNInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/skuLocLPNInventory", method: 'POST'},
            'lotLocInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/lotLocInventory", method: 'POST'},
            'lotLocLPNInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/lotLocLPNInventory", method: 'POST'},
            'zoneLocInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/zoneLocInventory", method: 'POST'},
            'lpnCaseNumInventory': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/lpnCaseNumInventory", method: 'POST'},
            'beforeList': { url: iServiceConfig.iwhInventoryHost + "/web/inventorySearch/beforeList", method: 'POST'},

        });
        return service;
    }
})();
