(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusSchedule', iBusSchedule);

    iBusSchedule.$inject = ['$resource', 'iServiceConfig'];

    function iBusSchedule ($resource, iServiceConfig) {
        var service = $resource('web/schedule', {}, {
            'search': { url: iServiceConfig.ibusHost + "/web/schedule/list", method: 'POST'},
            'get': { url: iServiceConfig.ibusHost + "/web/schedule/:id", method: 'GET'},
            'add': { url: iServiceConfig.ibusHost + "/web/schedule/add", method: 'POST'},
            'update': { url: iServiceConfig.ibusHost + "/web/schedule/update", method: 'POST'},
            'delete': { url: iServiceConfig.ibusHost + "/web/schedule/delete/:id", method: 'POST'}
        });

        return service;
    }
})();
