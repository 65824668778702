(function () {
    'use strict';
    var app = angular.module('iwh-dataModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('iwh-storageTypeZone-maintenance', {
            parent: 'main',
           url: 'iwh-data/storageTypeZone-maintenance',
            data: {
                'pageTitle': '存储分类库区维护',
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-data-module/storageTypeZone/storageTypeZone.html',
                    controller: 'IwhStorageTypeZoneController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

