(function() {
    'use strict';

    angular
        .module('ireportNodeModule')
        .factory('RptNodeReportService', RptNodeReportService);

        RptNodeReportService.$inject = ['$resource', 'iServiceConfig'];

    function RptNodeReportService ($resource, iServiceConfig) {
        var service = $resource('web/report', {}, {
            'list': { url: iServiceConfig.ireportNodeHost + "/web/report/list", method: 'POST'}
        });

        return service;
    }
})();
