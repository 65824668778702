(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('SoCheckPackController', SoCheckPackController);

    SoCheckPackController.$inject = ['$state', '$timeout', 'NgTableParams', 'scModal', '$filter', 'toastr', 'PackTempBiz', 'ShipOrder', 'enums', 'iServiceConfig', 'ApiURI', '$http', '$sce', '$httpParamSerializer', '$localStorage', '$uibModal'];

    function SoCheckPackController ($state, $timeout, NgTableParams, scModal, $filter, toastr, PackTempBiz, ShipOrder, enums, iServiceConfig, ApiURI, $http, $sce, $httpParamSerializer, $localStorage, $uibModal) {
        var vm = this;
        vm.isShowPackedPickDetail = false;
        vm.params = {
            isScanPerPiece: false,
            isReduce: false,
            isPrintList: true,
            isPrintSheet: true,
            isScanPackMaterial: true,
            isScanWayBillNum: true,
            useScanGun: false,
            isCanChooseOtherTempBox: false,
            listPrinter:"",
            waybillNumPrinter:"",
        };
        vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'}; // SUCCESS|ERROR
        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'}
        };
        vm.box = {};
        vm.pickDetail = {};
        vm.nextSku = false;
        vm.getSkuCodeHost = iServiceConfig.idataHost + ApiURI.getSkuCodeBySkuBarcode + '?' + ApiURI.jsonpParam + $localStorage.authenticationToken;
        vm.needPrintDetail = enums('ship.NeedPrintDetail');
        vm.changeOrderCmd = '$CHANGEPKO$';
        vm.errorCtrlCmd = '$ERRORCTRL$';
        vm.endScanCmd = '$ENDSCAN$';

        vm.calcScanQty = calcScanQtyEnter;
        vm.getBox = getBox;
        vm.getCurrentBox = getCurrentBox;
        vm.getDetail = getDetail;
        vm.goSkuCode = goSkuCode;
        vm.splitBox  = splitBox ;
        vm.redoPack = redoPack;
        vm.execCmd = execCmd;
        vm.detectScanPerPiece = detectScanPerPiece;
        vm.fail = fail;
        vm.success = success;
        vm.resortBoxPickDetail = resortBoxPickDetail;

        if ($state.params.isScanPerPiece > 0) {
            vm.params.isScanPerPiece = true;
            vm.detectScanPerPiece(vm.params.isScanPerPiece);
        }

        function getCurrentBox() {
            vm.box.isScanPackMaterial = vm.params.isScanPackMaterial;
            vm.box.isScanWayBillNum = vm.params.isScanWayBillNum;
            return vm.box;
        }
        vm.openChooseTempBoxWindow = function openChooseTempBoxWindow() {
            $uibModal.open({
                templateUrl: 'chooseTempBox.html',
                controller: openChooseTempBoxCtrl,
                controllerAs: '$ctrl',
            });
        }
        
        openChooseTempBoxCtrl.$inject=['$uibModalInstance','$scope'];
        function openChooseTempBoxCtrl($uibModalInstance,$scope) {
            var me = this;
            me.tempBoxs = vm.box.tempBoxs;
            me.useSelectBox = function useSelectBox() {
            	if (!me.selectedBoxNum) {
            		toastr.error("请选择需要继续复核的临时箱号！");
            	} else {
            		vm.getBox({key: 'Enter'}, vm.params.code, me.selectedBoxNum);
                	$uibModalInstance.dismiss('close');
            	}
            }
            me.gotoNewBox = function gotoNewBox() {
            	$uibModalInstance.dismiss('close');
            	vm.goSkuCode();
            }
        }
        
        vm.openInitParamWindow = function openInitParamWindow() {
            $uibModal.open({
                templateUrl: 'initParam.html',
                controller: openInitParamCtrl,
                controllerAs: '$ctrl',
            })
        }
        
        openInitParamCtrl.$inject=['$uibModalInstance','$scope'];
        function openInitParamCtrl($uibModalInstance,$scope) {
            var me=this;
            me.listPrinter=vm.params.listPrinter;
            me.waybillNumPrinter=vm.params.waybillNumPrinter;
            me.isPrintList=vm.params.isPrintList;
            me.isPrintSheet=vm.params.isPrintSheet;
            
            me.isCanChooseOtherTempBox=vm.params.isCanChooseOtherTempBox;
            
            me.entryInMainPage = function entryInMainPage() {
            	vm.params.listPrinter = me.listPrinter;
            	vm.params.waybillNumPrinter = me.waybillNumPrinter;
            	vm.params.isPrintList = me.isPrintList;
            	vm.params.isPrintSheet = me.isPrintSheet;
            	vm.params.isCanChooseOtherTempBox = me.isCanChooseOtherTempBox;
            	$uibModalInstance.dismiss('close');
            }
            me.close = function close() {
            	$uibModalInstance.dismiss('close');
            }
        }
        
        vm.success();
        // 获取装箱信息
        function getBox($event, soNum, boxNum) {
            var key = $event.key;
            if (key === 'Enter') {
            	vm.success();
            	PackTempBiz.getPackSoByNum({num: soNum, boxNum: boxNum}, {}, function(data) {
                    success();

                    var param = {ownerCode: data.content.ownerCode};
                    ShipOrder.shipConfig({}, param, function(resp) {
                        if (resp && resp.content) {
                            $timeout(function() {
                                vm.params.isScanPackMaterial = resp.content.needPackMaterial && resp.content.needPackMaterial > 0;
                                vm.params.isScanWayBillNum = resp.content.needWaybillNum && resp.content.needWaybillNum > 0;
                            });
                        }
                    });

                    $timeout(function () {
                        vm.box = data.content;
                        vm.box.skuBarcodeMap = {};
                        angular.forEach(vm.box.pickDetails, function(item) {
                            var skuName = item.skuName;
                            var skuCode = item.skuCode;
                            var skuBarcodes = item.skuBarcodes;
                            if (!skuBarcodes || skuBarcodes.length === 0) {
                                fail('商品' + skuName + '没有配置条码');
                            }

                            angular.forEach(skuBarcodes, function(skuBarcode) {
                                vm.box.skuBarcodeMap[skuBarcode] = skuCode;
                            });

                        });
                        _sortDetails(vm.box.pickDetails, null);
                        if (vm.params.isCanChooseOtherTempBox && !vm.box.boxNum) {
                        	if (vm.box.tempBoxs && vm.box.tempBoxs.length > 0) {
                        		vm.openChooseTempBoxWindow();
                        		return;
                            }
                        }
                        
                        goSkuCode();
                    });
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        openModal('error-confirm.html', {message: resp.data.errorMsg}, 'code');
                        fail(resp.data.errorMsg);
                    }
                });
            }
        }

        function goSkuCode() {
            if (vm.params.useScanGun) {
                jQuery('#skuCode').select();
            } else {
                jQuery('#skuCodeManual').select();
            }
        }

        // 获取商品明细
        function getDetail(skuBarcode, ownerCode, $event) {
            if (!$event || $event.which === 13) {
                if (!vm.box || !vm.box.pickDetails) {
                    openModal('error-confirm.html', {message: '请扫描有效的箱号'}, 'code');
                    fail();
                    return;
                }
                if (!skuBarcode) {
                    return;
                }

                if (vm.changeOrderCmd === skuBarcode || vm.endScanCmd === skuBarcode) {
                    execCmd(skuBarcode);
                } else {
                    if (vm.box.skuBarcodeMap) {
                        vm.params.skuCode = vm.box.skuBarcodeMap[skuBarcode];
                    }

                    if (!vm.params.skuCode) {
                        vm.params.skuCode = skuBarcode;
                    }

                    if (vm.params.skuCode) {
                        var details = _sortDetails(vm.box.pickDetails, vm.params.skuCode);

                        vm.pickDetail = {};
                        var found = details.length > 0 && details[0].priority === 0;
                        if (!found) {
                            var msg = "拣选明细中没有发现该商品";
                            openModal('error-confirm.html', {message: msg}, 'skuCode');
                            fail(msg);
                        } else {
                            success();
                            vm.pickDetail = details[0];
                            if (vm.box.skuBarcodeMap && vm.box.skuBarcodeMap[skuBarcode]) {
                                vm.pickDetail.skuBarcode = skuBarcode;
                            } else {
                                vm.pickDetail.skuBarcode = '';
                            }
                            
                            if (!vm.params.isScanPerPiece) {
                            	_PlaySuccessAudio();
                                $timeout(function () {
                                    jQuery('#toPackQty').select();
                                });
                            } else {
                                vm.params.toPackQty = 1;

                                var qty = vm.params.toPackQty;
                                var isReduce = vm.params.isReduce;
                                if (!/^[0-9]+$/.test(qty) || parseInt(qty, 10) <= 0) {
                                    openModal('error-confirm.html', {message: '复核数量需为大于零的整数'}, 'toPackQty');
                                    fail();
                                    return;
                                }
                                qty = parseInt(qty, 10);
                                var msg;

                                if (isReduce) {
                                    if (qty > vm.pickDetail.packQty) {
                                        msg = '扣除数量大于已经扫描数量';
                                        if (vm.params.isScanPerPiece) {
                                            if (vm.params.useScanGun) {
                                                openModal('error-confirm.html', {message: msg}, 'skuCode');
                                            } else {
                                                openModal('error-confirm.html', {message: msg}, 'skuCodeManual');
                                            }
                                        } else {
                                            openModal('error-confirm.html', {message: msg}, 'toPackQty');
                                        }
                                        fail(msg);

                                        return;
                                    }
                                }else{
                                    if(qty>(vm.pickDetail.qty-vm.pickDetail.packQty)){
                                        msg = '复核数量大于待复核数量';
                                        if (vm.params.isScanPerPiece) {
                                            if (vm.params.useScanGun) {
                                                openModal('error-confirm.html', {message: msg}, 'skuCode');
                                            } else {
                                                openModal('error-confirm.html', {message: msg}, 'skuCodeManual');
                                            }
                                        } else {
                                            openModal('error-confirm.html', {message: msg}, 'toPackQty');
                                        }
                                        fail(msg);

                                        return;
                                    }
                                }

                                if(vm.pickDetail.needSerialNum){
                                    openModalSerial('takeSerialNum.html',vm.params.isScanPerPiece,qty);
                                }else{
                                    calcScanQty(vm.params.toPackQty, vm.box.boxNum, vm.params.skuCode, vm.pickDetail.packCode, vm.params.isReduce);
                                }
                                goSkuCode();
                            }
                        }
                    } else {
                        var msg = "商品条码" + skuBarcode + '不存在';
                        openModal('error-confirm.html', {message: msg}, 'skuCode');
                        fail(msg);
                    }
                }
            }
        }
        // 重新扫描
        function redoPack(box, params) {
            $('#operateBox').find('button').blur();
            var boxNum = box.boxNum;
            var soNum = box.soNum;
            vm.success();
            PackTempBiz.repackBox({boxNum:boxNum}, {}, function(data) {
                $timeout(function() {
                	jQuery('#code').select();
                	toastr.success('当前装箱记录复核重置成功');
                    resetBox();
                    vm.params.code = soNum;
                    vm.getBox({key: 'Enter'}, vm.params.code);
                });
            }, function(data) {
            	vm.fail("错误");
            });
        }
        // 封箱
        function splitBox(box, params) {
            $('#operateBox').find('button').blur();

            if(box.needWeigh){
            	var stdWeight = 0;
            	angular.forEach(vm.box.pickDetails, function(item) {
            		if (item.stdWeight && item.packQty > 0) {
            			stdWeight = stdWeight + (item.packQty * item.stdWeight);
            		}
            	})
            	box.stdWeight = stdWeight;
                openModalWeight('takeWeight.html',box,params,splitBoxWay);
                return;
            }

            splitBoxWay(box, params);
        }

        // 封箱---方法
        function splitBoxWay(box, params,weight) {
            var param = {
                boxNum: box.boxNum,
                waybillNum: box.waybillNum,
                packMaterialCode: box.packMaterialCode,
                weight:weight?weight:null
            };

            if (params) {
                param.printList = params.isPrintList;
                param.printWaybillNum = params.isPrintSheet;
                param.listPrinter = params.listPrinter;
                param.waybillNumPrinter = params.waybillNumPrinter;
            }
            vm.success();
            PackTempBiz.closeBox({}, param, function(data) {
                $timeout(function() {
                    // jQuery('#code').focus();
                    jQuery('#code').select();
                    if(data && data.content && data.content.hasSkuToPack == true) {
                        vm.params.code = box.soNum;
                        vm.getBox({key: 'Enter'}, vm.params.code);
                    } else {
                        resetBox();
                    }
                    toastr.success('封箱成功，箱号：'+data.content.box.boxNum+", 装箱数量："+data.content.box.skuQty);
                    openModal('success-confirm.html', {message: '封箱成功，箱号：'+data.content.box.boxNum+", 装箱数量："+data.content.box.skuQty}, 'code','OK','successCode');
                });
            }, function(data) {
            	vm.fail("错误");
            });
        }
        
        // 复核(Enter键盘响应)
        function calcScanQtyEnter($event, qty, boxNum, skuCode, packCode, isReduce) {
            var key = $event.key;
            if (key === 'Enter') {

                if (!/^[0-9]+$/.test(qty) || parseInt(qty, 10) <= 0) {
                    openModal('error-confirm.html', {message: '复核数量需为大于零的整数'}, 'toPackQty');
                    fail();
                    return;
                }
                qty = parseInt(qty, 10);

                if (isReduce) {
                    if (qty > vm.pickDetail.packQty) {
                        var msg = '扣除数量大于已经扫描数量';
                        if (vm.params.isScanPerPiece) {
                            if (vm.params.useScanGun) {
                                openModal('error-confirm.html', {message: msg}, 'skuCode');
                            } else {
                                openModal('error-confirm.html', {message: msg}, 'skuCodeManual');
                            }
                        } else {
                            openModal('error-confirm.html', {message: msg}, 'toPackQty');
                        }
                        fail(msg);

                        return;
                    }
                }else{
                    if(qty>(vm.pickDetail.qty-vm.pickDetail.packQty)){
                        var msg = '复核数量大于待复核数量';
                        if (vm.params.isScanPerPiece) {
                            if (vm.params.useScanGun) {
                                openModal('error-confirm.html', {message: msg}, 'skuCode');
                            } else {
                                openModal('error-confirm.html', {message: msg}, 'skuCodeManual');
                            }
                        } else {
                            openModal('error-confirm.html', {message: msg}, 'toPackQty');
                        }
                        fail(msg);

                        return;
                    }
                }

                if(vm.pickDetail.needSerialNum){
                    openModalSerial('takeSerialNum.html',vm.params.isScanPerPiece,qty);
                }else{
                    calcScanQty(qty, boxNum, skuCode, packCode, isReduce);
                }
            }
        }

        // 复核
        function calcScanQty(qty, boxNum, skuCode, packCode, isReduce,serialNums) {
            if (qty && skuCode) {
                if (!/^[0-9]+$/.test(qty) || parseInt(qty, 10) <= 0) {
                    openModal('error-confirm.html', {message: '复核数量需为大于零的整数'}, 'toPackQty');
                    fail();
                    return;
                }
                qty = parseInt(qty, 10);

                if (isReduce) {
                    if (qty > vm.pickDetail.packQty) {
                        var msg = '扣除数量大于已经扫描数量';
                        if (vm.params.isScanPerPiece) {
                            if (vm.params.useScanGun) {
                                openModal('error-confirm.html', {message: msg}, 'skuCode');
                            } else {
                                openModal('error-confirm.html', {message: msg}, 'skuCodeManual');
                            }
                        } else {
                            openModal('error-confirm.html', {message: msg}, 'toPackQty');
                        }
                        fail(msg);

                        return;
                    }
                }else{
                    if(qty>(vm.pickDetail.qty-vm.pickDetail.packQty)){
                        var msg = '复核数量大于待复核数量';
                        if (vm.params.isScanPerPiece) {
                            if (vm.params.useScanGun) {
                                openModal('error-confirm.html', {message: msg}, 'skuCode');
                            } else {
                                openModal('error-confirm.html', {message: msg}, 'skuCodeManual');
                            }
                        } else {
                            openModal('error-confirm.html', {message: msg}, 'toPackQty');
                        }
                        fail(msg);

                        return;
                    }
                }

                var param = {
                    boxNum: boxNum,
                    soNum: vm.box.soNum,
                    skuCode: skuCode,
                    packCode: packCode,
                    skuQty: qty,
                    subtract: isReduce,
                    serialNums:serialNums?serialNums:[]
                };
                vm.success();
                PackTempBiz.packSku({}, param, function(resp) {
                	_PlaySuccessAudio();
                	vm.box.boxNum = resp.content.boxNum;
                    if(!vm.pickDetail.packQty) {
                        vm.pickDetail.packQty = 0;
                    }
                    if (isReduce) {
                        vm.pickDetail.packQty = vm.pickDetail.packQty - qty;
                        vm.box.packQty = vm.box.packQty - qty;
                    } else {
                        vm.pickDetail.packQty = vm.pickDetail.packQty + qty;
                        vm.box.packQty = vm.box.packQty + qty;
                    }

                    if (_is_box_completed(vm.box)) {
                        resetSku();
                        goSkuCode();
                        _sortDetails(vm.box.pickDetails);
                        if (vm.params.isScanPackMaterial || vm.params.isScanWayBillNum) {
                            jQuery('button.close-scan').click();
                        } else {
                            jQuery('button.close-noscan').click();
                        }

                    } else {
                        resetSku();
                        goSkuCode();
                        _sortDetails(vm.box.pickDetails);
                    }
                }, function(resp) {
                    if (resp.data && resp.data.errorMsg) {
                        if (vm.params.useScanGun) {
                            openModal('error-confirm.html', {message: resp.data.errorMsg}, 'skuCode');
                        } else {
                            openModal('error-confirm.html', {message: resp.data.errorMsg}, 'skuCodeManual');
                        }
                        fail(resp.data.errorMsg);
                    }
                });
            }
        }

        // 打开控制弹出框
        function openModal(templateUrl, model, elemId, ctrlCmd, inputCode) {
            var paramItem = angular.extend({errorCtrlCmd: ctrlCmd ? ctrlCmd : vm.errorCtrlCmd}, model);
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return paramItem;
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.execCmd = execCmd;
                $ctrl.close = close;

                function execCmd($event, cmd) {
                    var key = $event.key;
                    if (key === 'Enter') {
                        if (cmd === vm.errorCtrlCmd || vm.errorCtrlCmd === 'OK') {
                            modalInstance.close();

                            vm.success();
                            if (elemId) {
                                jQuery('#' + elemId).select();
                            }
                        } else {
                            jQuery(inputCode ? inputCode : '#errorCode').select();
                        }
                    }
                }
                function close() {
                    modalInstance.close();
                }
            }
        }

        // 是否逐件扫描
        function detectScanPerPiece(isScanPerPiece) {
            if (isScanPerPiece) {
                vm.params.toPackQty = 1;
            }
        }

        function execCmd(cmd) {
            if (cmd) {
                if (cmd === vm.changeOrderCmd) {
                    resetBox();
                    jQuery('#code').select();

                    return true;
                } else if (cmd === vm.endScanCmd) {
                    if (vm.params.isScanPackMaterial || vm.params.isScanWayBillNum) {
                        jQuery('button.close-scan').click();
                        return true;
                    } else {
                        jQuery('button.close-noscan').click();
                        return true;
                    }
                } else {
                    toastr.error('不支持的命令');
                }
            }

            return false;
        }
        
        function _is_box_completed(box) {
            if (box && box.pickDetails && box.pickDetails.length > 0) {
                var done = true;
                angular.forEach(box.pickDetails, function (detail) {
                    if (detail.packQty !== detail.qty) {
                        done = false;
                    }
                });

                return done;
            }

            return false;
        }
        function resortBoxPickDetail() {
        	_sortDetails(vm.box.pickDetails, null);
        }
        function _sortDetails(details, skuCode) {
            angular.forEach(details, function(detail, idx) {
                if (detail.skuCode === skuCode) {
                    detail.priority = 0;
                    detail.style = vm.alertColor.current;
                } else if (detail.qty <= detail.packQty) {
                    detail.priority = idx + vm.isShowPackedPickDetail ? 10000 : 100000;
                    detail.style = vm.alertColor.done;
                } else if (detail.packQty > 0) {
                    detail.priority = idx + 1000;
                    detail.style = vm.alertColor.doing;
                } else {
                    detail.priority = idx + vm.isShowPackedPickDetail ? 100000 : 10000;
                    detail.style = vm.alertColor.on;
                }
            });

            vm.box.pickDetails = $filter('orderBy')(vm.box.pickDetails, 'priority');
            return vm.box.pickDetails;
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }

        function resetSku() {
            vm.pickDetail = {};
            if (vm.params.isScanPerPiece) {
                vm.params.toPackQty = 1;
            } else {
                vm.params.toPackQty = '';
            }

            vm.params.skuCode = '';
            jQuery('#skuCode').val('');
            vm.params.skuBarcode = '';
            vm.params.packCode = '';
        }

        function resetBox() {
            vm.box = {};
            vm.pickDetail = {};
            if (vm.params.isScanPerPiece) {
                vm.params.toPackQty = 1;
            } else {
                vm.params.toPackQty = '';
            }
            vm.params.code = '';
            vm.params.skuCode = '';
            jQuery('#skuCode').val('');
            vm.params.skuBarcode = '';
            vm.params.packCode = '';
        }

        //检查序列号--方法
        function checkSerialWay(num) {
            return new Promise(function(resolve,reject){
            	PackTempBiz.checkSn({},{
                    boxNum:vm.box.boxNum,
                    soNum:vm.box.soNum,
                    ownerCode:vm.box.ownerCode,
                    skuCode:vm.pickDetail.skuCode,
                    serialNum:num,
                    subtract:vm.params.isReduce
                },function(res){
                    resolve(res);
                },function(res){
                    reject(res);
                });
            });
        }

        // 打开采集序列号弹出框
        function openModalSerial(templateUrl,isScanPerPiece,qty) {
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return {
                            isScanPerPiece:isScanPerPiece,
                            qty:qty
                        }
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.item.errorSerial = false;
                $ctrl.item.isClose = false;
                $ctrl.item.serialNums = [];
                $ctrl.checkSerial = checkSerial;
                $ctrl.ok = ok;
                $ctrl.cancel = cancel;

                function checkSerial($event, serialNum) {
                    var key = $event.key;
                    if (key === 'Enter') {
                        if($ctrl.item.serialNums.length>=qty){
                            $ctrl.item.errorSerial = true;
                            $ctrl.item.errorMsg ='当前所扫描的序列号数量已达规定数量';
                            _PlayErrorAudio();
                            return;
                        }
                        var idx = $ctrl.item.serialNums.indexOf(serialNum);
                        if(idx>-1){
                            $ctrl.item.errorSerial = true;
                            $ctrl.item.errorMsg ='当前序列号已被扫描';
                            _PlayErrorAudio();
                            return;
                        }
                        //检查序列号
                        checkSerialWay(serialNum).then(function(res){
                            $ctrl.item.serialNums.push(serialNum);
                            if(isScanPerPiece){
                                calcScanQty(vm.params.toPackQty, vm.box.boxNum, vm.params.skuCode, vm.pickDetail.packCode, vm.params.isReduce,$ctrl.item.serialNums);
                                modalInstance.close();
                            }
                            $ctrl.item.serialNum = null;
                            $ctrl.item.errorSerial = false;
                            $ctrl.item.errorMsg ='';
                        }).catch(function(res){
                            $('#takeSerial').select();
                            if (res.data && res.data.errorMsg) {
                                $ctrl.item.errorSerial = true;
                                $ctrl.item.errorMsg =res.data.errorMsg;
                                _PlayErrorAudio();
                            }
                        });
                    }
                }

                function ok(item) {
                    //如果是逐渐扫描
                    if(isScanPerPiece){
                        checkSerial({key:'Enter'},item.serialNum);
                    }else{
                        if($ctrl.item.serialNums.length<qty){
                            $ctrl.item.errorSerial = true;
                            $ctrl.item.errorMsg ='当前所扫描的序列号数量不足，请继续扫描';
                        }else if($ctrl.item.serialNums.length==qty){
                            calcScanQty(qty, vm.box.boxNum, vm.params.skuCode, vm.pickDetail.packCode, vm.params.isReduce,$ctrl.item.serialNums);
                            modalInstance.close();
                        }
                    }
                }
                function cancel(item) {
                    $ctrl.item.isClose = true;
                    modalInstance.close();
                }
            }
        }

        // 打开称重弹出框
        function openModalWeight(templateUrl,box,params,funcWay) {
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return {
                            box:box
                        }
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.item.diffPercent = null;
                $ctrl.item.diff = null;
                $ctrl.item.isOverstep = false;
                $ctrl.item.takeWeight = null;
                $ctrl.takeWeight = takeWeight;
                $ctrl.submit =submit;//提交

                function takeWeight($event, weight) {
                    var key = $event.key;
                    if (key === 'Enter') {

                        var stdWeight = $ctrl.item.box.stdWeight*1;
                        var diff = Math.abs((weight*1-stdWeight).toFixed(2)*1);

                        if(diff > 0){
                            var diffPercent = ((diff/stdWeight)*100).toFixed(2)*1;

                            var maxAllowDiff = $ctrl.item.box.maxAllowDiff*1;
                            if(diffPercent>maxAllowDiff){
                                $ctrl.item.diff = diff;
                                $ctrl.item.diffPercent = diffPercent;
                                $ctrl.item.isOverstep = true;
                                return;
                            }
                        }
                        $ctrl.item.isOverstep = false;
                        funcWay(box, params,weight);
                        modalInstance.close();
                    }
                }

                function submit(item) {
                    funcWay(box, params,$ctrl.item.takeWeight);
                    modalInstance.close();
                }
            }
        }
        vm.openInitParamWindow();
    }
})();
