(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('DeliveryOrder', DeliveryOrder);

    DeliveryOrder.$inject = ['$resource', 'iServiceConfig'];

    function DeliveryOrder ($resource, iServiceConfig) {
        var service = $resource('web/deliveryOrder', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/list", method: 'POST'},
            'getDeliverNum': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/getDeliverNum", method: 'POST'},
            'saveAndUpdateDelivery': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/saveAndUpdateDelivery", method: 'POST'},
            'saveBox': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/saveBox", method: 'POST'},
            'cancelOneBox': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/cancelOneBox", method: 'POST'},
            'edite': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/edite", method: 'POST'},
            'transfer': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/transfer", method: 'POST'},
            'delivery': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/delivery", method: 'POST'},
            'cancel': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/cancel", method: 'POST'},
            'getDelivery': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/getDelivery", method: 'POST'},
            'pageForDelivery': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/pageForDelivery", method: 'POST'},
            'deliveryBySoNum': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/deliveryBySoNum", method: 'POST'},
            'deliveryBySoNums': { url: iServiceConfig.iwhShipHost + "/web/deliveryOrder/deliveryBySoNums", method: 'POST'}
        });
        return service;
    }
})();
