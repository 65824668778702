(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("ReceiptPrint", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource("/web/print", {}, {
            // 打印预收货单
            'printAsn': { url:  iServiceConfig.iwhReceiptHost + "/web/print/asn", method: 'POST'},

            // 打印LPN物料单
            'printLpn': { url: iServiceConfig.iwhReceiptHost + "/web/print/lpn", method: 'POST'},

            // 打印商品条码
            'printSkuBarCode': { url: iServiceConfig.iwhReceiptHost + "/web/print/skuBarCode/printSkuBarCode", method: 'POST'},
            'printSkuBarCodeBatch': { url: iServiceConfig.iwhReceiptHost + "/web/print/skuBarCode/printSkuBarCodeBatch", method: 'POST'},

            // 打印上架单
            'printPutaway': { url: iServiceConfig.iwhReceiptHost + "/web/print/putaway/printPutaway", method: 'POST'},
            'printPutawayBatch': { url: iServiceConfig.iwhReceiptHost + "/web/print/putaway/printPutawayBatch", method: 'POST'}
        });
    }]);
})();