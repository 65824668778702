(function () {
    'use strict';

    angular.module('imessageModule')
        .factory('iMessageMessage', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/message', {}, {
                'getMessageList': {
                    url: iServiceConfig.imessageHost + '/web/message/getMessageList',
                    method: 'GET'
                },
                'getHistoryMessageList': {
                    url: iServiceConfig.imessageHost + '/web/message/getHistoryMessageList',
                    method: 'GET'
                },
                'suspendMessage': {
                    url: iServiceConfig.imessageHost + '/web/message/suspendMessage',
                    method: 'POST'
                },
                'resumeMessage': {
                    url: iServiceConfig.imessageHost + '/web/message/resumeMessage',
                    method: 'POST'
                },
                'cancelMessage': {
                    url: iServiceConfig.imessageHost + '/web/message/cancelMessage',
                    method: 'POST'
                },
                'saveMessage': {
                    url: iServiceConfig.imessageHost + '/web/message/saveMessage',
                    method: 'POST'
                }
            });
        }]);
})();