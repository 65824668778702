(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleAuth', UserModuleAuth);

    UserModuleAuth.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleAuth ($resource, iServiceConfig, $sessionStorage, $state, Principal, $rootScope) {
        var service = $resource('web/auth', {}, {
            'allAuthTreeBuilder': { url: iServiceConfig.iuserHost + "/web/auth/allAuthTreeBuilder"},
            'getSystem': {url: iServiceConfig.iuserHost + "/web/auth/getSystem", method: 'POST'},
            'saveSystem': {url: iServiceConfig.iuserHost + "/web/auth/saveSystem", method: 'POST'},
            'getModule': {url: iServiceConfig.iuserHost + "/web/auth/getModule", method: 'POST'},
            'saveModule':{url: iServiceConfig.iuserHost + "/web/auth/saveModule", method:'POST'},
            'getAuth':{url: iServiceConfig.iuserHost + "/web/auth/getAuth", method:'POST'},
            'saveAuth':{url: iServiceConfig.iuserHost + "/web/auth/saveAuth", method:'POST'}
        });

        return service;
    }
})();
