(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusDataSourceRunning', iBusDataSourceRunning);

    iBusDataSourceRunning.$inject = ['$resource', 'iServiceConfig'];

    function iBusDataSourceRunning ($resource, iServiceConfig) {
        var service = $resource('web/run/ds', {}, {
            'list': { url: iServiceConfig.ibusHost + "/web/run/ds/list", method: 'POST'},
            'init': { url: iServiceConfig.ibusHost + "/web/run/ds/init", method: 'POST'},
            'initAll': { url: iServiceConfig.ibusHost + "/web/run/ds/init-all", method: 'GET'},
            'close': { url: iServiceConfig.ibusHost + "/web/run/ds/close", method: 'POST'},
            'initSelect': { url: iServiceConfig.ibusHost + "/web/run/ds/init-select", method: 'POST'},
            'closeSelect': { url: iServiceConfig.ibusHost + "/web/run/ds/close-select", method: 'POST'},
            'runningCount': { url: iServiceConfig.ibusHost + "/web/run/ds/runningCount", method: 'POST'},
            'closeAll': { url: iServiceConfig.ibusHost + "/web/run/ds/close-all", method: 'GET'}
        });

        return service;
    }
})();
