(function() {
    'use strict';

    angular.module('ireportNodeModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('rptNode-report', {
                parent: 'main',
                url: 'report-node/report',
                data: {
                    'pageTitle': '报表配置'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'report-node-module/report/report.html',
                        controller: 'RptNodeReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
