(function(ng) {
    'use strict';

    ng.module('scBarcode', []);

    ng.module('scBarcode').directive('scBarcode', scBarcode);
    scBarcode.$inject = [];
    function scBarcode() {
        return {
            restrict: 'EA',
            template: '<img class="barcode">',
            scope: {
                scModel: '=scModel',
                displayValue: '=?displayValue'
            },
            link: {
                pre: linkFn
            }
        };

        function linkFn(scope,element, attrs, ctrl) {
            var displayValue = false;
            if (scope.displayValue) {
                displayValue = scope.displayValue;
            }

            var unwatch = scope.$watch('scModel', function(newVal, oldVal) {
                if (newVal && angular.isString(newVal)) {
                    jQuery(element).find('img.barcode').JsBarcode(newVal, {displayValue: displayValue});
                }
            });

            scope.$on('$destroy', function() {
                unwatch();
            });
        }
    }

    ng.module('scBarcode').directive('autofocus', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element[0].focus();
            }
        };
    });

    ng.module('scBarcode').directive('scKeydown', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            scope: {
                scModel: '=?scModel',
                scCallback: '&scKeydown'
            },
            link: {
                pre :function (scope, element) {
                    var keyevent = 'ENTER';

                    var buffer = [];
                    var $elem = jQuery(element[0]);
                    $elem.on('keydown', function(event) {
                        event.stopPropagation();
                        var key = event.key;
                        if (key.toUpperCase() !== keyevent) {
                            if (key.length === 1) {
                                buffer.push(key);
                            }
                        } else if (buffer.length > 0) {
                            var val = buffer.join('');
                            $elem.val(val);
                            $elem.select();
                            buffer = [];
                            scope.scModel = val;
                            scope.$model = val;
                            $timeout(function () {
                                scope.$apply(scope.scCallback);
                            });
                        }
                    });

                    var unWatch = scope.$watch('scModel', function (newVal, oldVal) {
                        if (newVal !== scope.$model) {
                            $elem.val(newVal);
                            scope.$model = newVal;
                        }
                    });

                    scope.$on('$destroy', function() {
                        unWatch();
                    });
                }
            }
        };
    }]);
}(angular));