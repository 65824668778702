(function() {
    'use strict';

    angular.module('iwh-receiptModule').controller('ReceiptLotController', ReceiptLotController);
    ReceiptLotController.$inject = ["$rootScope", "$scope", "NgTableParams",
        "$uibModal", "NgSelect", "$state", "$timeout", "toastr", "enums", "ReceiptLot", "ReceiptPrint",'Principal'];
    function ReceiptLotController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                           $state, $timeout, toastr, enums, ReceiptLot, ReceiptPrint,Principal) {
        var vm = this;
        vm.tabs = [];                   // 记录所有的tab
        vm.active = 0;                  // 总共打开tab的总数

        // 常量数据
        var constantStatukey = "receipt.ReceiptBatchStatusEnum";
        var constantDetailStatusKey = "receipt.ReceiptDetailStatusEnum";
        vm.constant = {
            keys : {
                status : "",
                detailStatus : ""
            },
            status : {
                list : enums(constantStatukey).list('title', 'id'),
                NEW : enums(constantStatukey).value('NEW'), // 新建
                DOING : enums(constantStatukey).value('RECEIVING'), // 收货中
                COMPLETED : enums(constantStatukey).value('COMPLETED'), // 已完成
                PUTAWAY : enums(constantStatukey).value('PUTAWAY'), // 已上架处理
                REVERT : enums(constantStatukey).value('RECYCLE') // 已回转收货
            },
            detailStatus : {
                list : enums(constantDetailStatusKey).list('title', 'id'),
                NEW : enums(constantDetailStatusKey).value('NEW'), // 新建
                RECEIVING : enums(constantDetailStatusKey).value('RECEIVING'), // 收货中
                RECEIVED : enums(constantDetailStatusKey).value('RECEIVED'), // 已收货
                PUTAWAY : enums(constantDetailStatusKey).value('PUTAWAY'), // 已上架
                RECYCLE : enums(constantDetailStatusKey).value('RECYCLE'), // 已回转收货
                CANCEL : enums(constantDetailStatusKey).value('CANCEL') // 已取消
            }
        };


        vm.removeTab = removeTab;                               // 删除指定下标的tab页
        vm.generatePutaway = generatePutaway;                   // 生成上架单
        vm.generatePutways = generatePutways;                   // 一次将多个批次生成上架单
        vm.reloadReceiptLotList = reloadReceiptLotList;         // 刷新加载table数据
        vm.editLot = editLot;                                   // 编辑
        vm.deleteLot = deleteLot;                               // 一次删除一个收货批次
        vm.deleteLots = deleteLots;                             // 一次删除多个Lot
        vm.rotationLot = rotationLot;                           // 一次回转一个收货批次
        vm.completeLots = completeLots;                         // 完成多个收货批次
        vm.rotationLotForDetail = rotationLotForDetail;         // 一次回转一个收货批次，根据收货日志编号进行回转
        vm.rotationLotForDetailPart = rotationLotForDetailPart;
        vm.lookLot = lookLot;                                   // 查看
        vm.detailTableRowInit = detailTableRowInit;
        vm.printLpn = printLpn;                                 // 打印LPN物料单
        vm.userData = {};
        // 获取用户数据
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.buildParam = function buildParam(batchNum) {
        	var param = null;
            if ( undefined == vm.userData ) {
                toastr.error("获取用户登录信息失败");
            }
            if (batchNum) {
            	param = {
            			operatorName: vm.userData.realName,
                        printUser : vm.userData.realName,
                        tenantId : vm.userData.tenantId,
                        warehouseCode : vm.userData.warehouseCode,
                        batchNum : batchNum
                    };
            } else {
            	var list = vm.receiptLotListNgTable.getSelected();
            	if (list.length > 0) {
                    param = [];
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                        	printUser : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            batchNum : list[i].batchNum
                        });
                    }
            	}
            }
            return param;
        }

        // 获取收货批次数据列表
        vm.receiptLotListNgTable = new NgTableParams({
            count: 10
        }, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count()}, params.filter());
                return ReceiptLot.getReceiptLotList({}, postParams, function (resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });


        // 移除一个tab
        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function reloadReceiptLotList() {
            vm.receiptLotListNgTable.reload();
        }

        // 判断tab是否已经存在，key自定义的唯一业务key
        function tabAlreadyExisted(key) {
            var existFlag = false, tIndex = -1;
            angular.forEach(vm.tabs, function(data, index, array){
                if (data.key == key) {
                    existFlag = true;
                    tIndex = index;

                }
            });

            if ( existFlag ) {
                vm.active = tIndex + 1;
            }

            return existFlag;
        }

        // 一个批次一个上架单
        function generatePutaway(row) {
            _generatePutaway([{
                batchNum:row.batchNum
            }]);
        }

        // 多个批次生成一个上架单
        function generatePutways() {
            var selectedObj = vm.receiptLotListNgTable.getSelected();
            if ( !(selectedObj instanceof Array) || selectedObj.length <= 0 ) {
                toastr.error("请勾选收货批次");
                return;
            }

            var params = [];
            for (var i = 0, len = selectedObj.length; i < len; i++ ) {
                var temp = selectedObj[i];
                if ( temp.status == vm.constant.status.PUTAWAY ){
                    toastr.error("批次[" + temp.batchNum + "]已经生成上架单，不允许再次生成");
                    return;
                }
                params.push({
                    batchNum : temp.batchNum
                });
            }
            _generatePutaway(params);
        }

        // 查看批次
        function lookLot(row) {
            if ( row == undefined ) {
                toastr.error("批次编码为空，不能加载批次详细信息！");
                return;
            }

            if ( tabAlreadyExisted(row.batchNum) ) {
                return;
            }

            ReceiptLot.getReceiptLotDetail({}, {
                batchNum : row.batchNum
            }, function(resp){
                var tempData = resp.content;

                var tabData = {
                    key : row.batchNum,
                    heading: '查看[' + row.batchNum + ']',
                    contentId: "receiptLot-detail.html",
                    data: tempData,
                    roles: [],
                    // 获取收货批次详细数据列表
                    receiptDetailListNgTable : new NgTableParams({
                        count: 10
                    }, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn : params.page(),
                                ps : params.count(),
                                batchNum : tabData.data.batchNum
                            }, params.filter());
                            this.httpParams = postParams;
                            return ReceiptLot.getReceiptDetailList({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    // 重新加载表格数据
                    reloadReceiptDetailList : function (subTabScope) {
                        subTabScope.receiptDetailListNgTable.reload();
                    }
                };
                vm.tabs.push(tabData);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        // 编辑批次
        function editLot(row) {
            if ( row == undefined ) {
                toastr.error("批次编码为空，不能加载批次详细信息！");
                return;
            }

            if ( tabAlreadyExisted(row.batchNum) ) {
                return;
            }

            ReceiptLot.getReceiptLotDetail({}, {
                batchNum : row.batchNum
            }, function(resp){
                var tabData = {
                    key : row.batchNum,
                    heading: '编辑[' + row.batchNum + ']',
                    contentId: "receiptLot-edit.html",
                    data: resp.content,
                    roles: [],
                    // 获取收货批次详细数据列表
                    receiptDetailListNgTable : new NgTableParams({
                        count: 10
                    }, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn : params.page(),
                                ps : params.count(),
                                batchNum : tabData.data.batchNum
                            }, params.filter());
                            this.httpParams = postParams;
                            return ReceiptLot.getReceiptDetailList({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    }),
                    // 重新加载表格数据
                    reloadReceiptDetailList : function (subTabScope) {
                        subTabScope.data.revolutionAll = false;
                        subTabScope.receiptDetailListNgTable.reload();
                    }
                };
                vm.tabs.push(tabData);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        function detailTableRowInit(row, subScope) {
            console.debug([row, subScope.data]);
            var data = subScope.data;
            if ( data.status == vm.constant.status.PUTAWAY ||
                    data.status == vm.constant.status.REVERT ) {
                data.revolutionAll = false;
            } else {
                // 主单不是已上架和已回转，且状态是收货中或收货完成，允许回转
                if ( row.status == vm.constant.detailStatus.RECEIVING ||
                        row.status == vm.constant.detailStatus.RECEIVED ) {
                    data.revolutionAll = true;
                }
            }
        }

        // 一次删除一个批次
        function deleteLot(row) {
            ReceiptLot.batchRotationLot({}, [{
                batchNum : (row && row.batchNum)
            }], function(resp){
                reloadReceiptLotList();
            });
        }

        // 一次删除多个批次
        function deleteLots() {
            var selectedObj = vm.receiptLotListNgTable.getSelected();
            if ( !(selectedObj instanceof Array) || selectedObj.length <= 0 ) {
                toastr.error("请勾选收货批次");
                return;
            }
            var params = [];
            for (var i = 0, len = selectedObj.length; i < len; i++ ) {
                params.push({
                    batchNum : (selectedObj[i] && selectedObj[i].batchNum)
                });
            }
            ReceiptLot.batchDeleteLot({}, params, function(resp){
                reloadReceiptLotList();
            });
        }

        // 一次完成多个批次
        function completeLots() {
            var selectedObj = vm.receiptLotListNgTable.getSelected();
            if ( !(selectedObj instanceof Array) || selectedObj.length <= 0 ) {
                toastr.error("请勾选收货批次");
                return;
            }

            var params = [];
            for (var i = 0, len = selectedObj.length; i < len; i++ ) {
                var temp = selectedObj[i];
                if ( temp.status == vm.constant.status.COMPLETED ) {
                    toastr.error("[" + temp.batchNum + "]收货批次已完成，不能再次执行完成操作");
                    return;
                }
                if ( temp.status == vm.constant.status.DOING ) {
                    params.push({
                        batchNum : temp.batchNum
                    });
                }
            }
            if ( params.length <= 0 ) {
                toastr.error("请选择收获批次（状态：收货中）");
                return;
            }
            ReceiptLot.batchCompleteLot({}, params, function(resp){
                toastr.success("成功完成批次");
                reloadReceiptLotList();
            });
        }

        // 批次回转
        function rotationLot(data, subTabScope) {
            ReceiptLot.rotationReceiptLotByBatch({}, {
                batchNum : data.batchNum
            }, function(resp){
                toastr.success("整单回转成功");
                getBatchInfo(data.batchNum, subTabScope);
                subTabScope.reloadReceiptDetailList(subTabScope);
            });
        }
        // 部分回转
        function rotationLotForDetailPart(row, subTabScope) {
            var param = {};
            if ( row != undefined ) {
            	if (!row.cancelQty || row.cancelQty <= 0) {
            		toastr.error("请输入要回转的数量");
                    return;
                }
            	if (row.cancelQty > row.qty) {
            		toastr.error("录入回转的数量不能大于 "+ row.qty);
                    return;
            	}
            	param = {
                    batchNum : row.batchNum,
                    lineNum : row.lineNum,
                    cqty : row.cancelQty
            	};
            }
            
            if ( !param.batchNum ) {
                toastr.error("请选择要回转的收货批次详情");
                return;
            }
            	
            ReceiptLot.rotationReceiptLotByQty({}, param, function(resp){
                toastr.success("回转成功");
                getBatchInfo(row.batchNum, subTabScope);
                subTabScope.reloadReceiptDetailList(subTabScope);
            });
        }
        // 单个回转
        function rotationLotForDetail(row, subTabScope) {
            var params = [];
            if ( row != undefined ) {
                params.push({
                    batchNum : row.batchNum,
                    lineNum : row.lineNum
                });
            }

            if ( params.length <= 0 ) {
                toastr.error("请选择要回转的收货批次详情");
                return;
            }
            ReceiptLot.rotationReceiptLot({}, params, function(resp){
                toastr.success("回转成功");
                getBatchInfo(row.batchNum, subTabScope);
                subTabScope.reloadReceiptDetailList(subTabScope);
            });
        }

        function getBatchInfo(batchNum, subTabScope) {
            ReceiptLot.getReceiptLotDetail({}, {
                batchNum : batchNum
            }, function(resp){
                subTabScope.data = resp.content;
            });
        }

        function printLpn(data, subTabScope) {
            ReceiptPrint.printLpn({}, {
                batchNum : data.batchNum
            }, function (resp) {
                toastr.success(resp.content);
            });
        }


        ////////////////////////////////////////////////////////////////
        // 生成上架单服务
        function _generatePutaway(args) {
            ReceiptLot.generatePutaway({}, args, function (resp) {
                toastr.success("生成成功，上架单号："+resp.content.putawayNum);
                reloadReceiptLotList();
            });
        }

    }
})();