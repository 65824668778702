(function() {
    "use strict";
    angular.module("iwh-receiptModule").factory("NewGoods", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.iwhReceiptHost + "/web/newGoods", {}, {
            'checkGoodsIsNew': { url: iServiceConfig.iwhReceiptHost + "/web/newGoods/checkGoodsIsNew", method: 'POST'},
            'submitNewGoods': { url: iServiceConfig.iwhReceiptHost + "/web/newGoods/submitNewGoods", method: 'POST'},
            'getSkuPackListByCode' : { url: iServiceConfig.iwhReceiptHost + "/web/newGoods/getSkuPackListByCode", method: 'POST'}
        });
    }]);
})();
