(function() {
    'use strict';

    angular
        .module('iuserModule')
        .factory('UserModuleEdition', UserModuleEdition);

    UserModuleEdition.$inject = ['$resource', 'iServiceConfig'];

    function UserModuleEdition ($resource, iServiceConfig) {
        var service = $resource('web/edition', {}, {
            'list': { url: iServiceConfig.iuserHost + "/web/edition/list", method: 'POST'},
            'openSell': {url: iServiceConfig.iuserHost + "/web/edition/open", method: 'POST'},
            'closeSell': {url: iServiceConfig.iuserHost + "/web/edition/close", method: 'POST'},
            'view': {url: iServiceConfig.iuserHost + "/web/edition/view", method: 'POST'},
            'detail': {url: iServiceConfig.iuserHost + "/web/edition/detail", method: 'POST'},
            'save': {url: iServiceConfig.iuserHost + "/web/edition/save", method: 'POST'},
            'delete': {url: iServiceConfig.iuserHost + "/web/edition/delete", method: 'POST'},
            'authTreeData': {url: iServiceConfig.iuserHost + "/web/edition/auth-tree", method: 'GET'}
        });

        return service;
    }
})();
