(function () {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('iEptRequisition', iEptRequisition);

    iEptRequisition.$inject = ['$resource', 'iServiceConfig'];

    function iEptRequisition($resource, iServiceConfig) {
        var service = $resource('web/requisition', {}, {
            'search': {url: iServiceConfig.ienterpriseHost + "/web/requisition/list", method: 'POST'},
            'preSave': {url: iServiceConfig.ienterpriseHost + "/web/requisition/preSave", method: 'GET'},
            'save': {url: iServiceConfig.ienterpriseHost + "/web/requisition/save", method: 'POST'},
            'getById': {url: iServiceConfig.ienterpriseHost + "/web/requisition/getById/:id", method: 'POST'},
            'plan': {url: iServiceConfig.ienterpriseHost + "/web/requisition/plan", method: 'POST'},
            'close': {url: iServiceConfig.ienterpriseHost + "/web/requisition/close", method: 'POST'},
            'outWh': {url: iServiceConfig.ienterpriseHost + "/web/requisition/outWh", method: 'POST'},
            'inWh': {url: iServiceConfig.ienterpriseHost + "/web/requisition/inWh", method: 'POST'},
            'pullOutWh': {url: iServiceConfig.ienterpriseHost + "/web/requisition/pullOutWh", method: 'POST'},
            'pullInWh': {url: iServiceConfig.ienterpriseHost + "/web/requisition/pullInWh", method: 'POST'},
            'cancelWh': {url: iServiceConfig.ienterpriseHost + "/web/requisition/cancelWh", method: 'POST'}
        });

        return service;
    }
})();
