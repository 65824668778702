(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('Box', Box);

    Box.$inject = ['$resource', 'iServiceConfig'];

    function Box ($resource, iServiceConfig) {
        var service = $resource('web/box', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/box/list", method: 'POST'},
            'getBoxDetail': { url: iServiceConfig.iwhShipHost + "/web/box/getBoxDetail", method: 'POST'},
            'closeBox': { url: iServiceConfig.iwhShipHost + "/web/box/closeBox", method: 'POST'},
            'openBox': { url: iServiceConfig.iwhShipHost + "/web/box/openBox", method: 'POST'},
            'openBoxBackToOrder': { url: iServiceConfig.iwhShipHost + "/web/box/openBoxBackToOrder", method: 'POST'},
            'getBoxByBoxNum': { url: iServiceConfig.iwhShipHost + "/web/box/getBoxByBoxNum/:boxNum", method: 'POST'}
        });
        return service;
    }
})();
