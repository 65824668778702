(function () {
    'use strict';

    angular.module('soModal', []).directive('soConfirmModal', ['$uibModal', function ($uibModal) {
        return {
            restrict: 'A',
            replace: false,
            scope: {
                soConfirmModal: '&'
            },
            link: function (s, el, attrs) {
                var opts = s.soConfirmModal();
                if (!opts || typeof opts !== 'object') return;
                el.bind('click', function () {
                    if (typeof opts.preShow === 'function' && !opts.preShow()) return;

                    $uibModal.open({
                        templateUrl: 'iwh-strategy-module/directive/soModal/soModal.html',
                        controller: ['$uibModalInstance', function ($uibModalInstance) {
                            var $modal = this;
                            $modal.item = opts;
                            $modal.ok = function () {
                                $uibModalInstance.close($modal.item);
                                typeof opts.ok === 'function' && opts.ok();
                            };
                            $modal.cancel = function () {
                                $uibModalInstance.dismiss('cancel');
                                typeof opts.cancel === 'function' && opts.cancel();
                            };
                        }],
                        controllerAs: '$modal'
                    });
                });
            }
        };
    }]);
})();