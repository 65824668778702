// adjustmentOrderReview
(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('AdjustmentOrderReviewController',
        AdjustmentOrderReviewController);

    AdjustmentOrderReviewController.$inject = [ '$uibModal','AdjustmentOrderReview', 'NgTableParams', '$timeout','toastr','enums'];

    function AdjustmentOrderReviewController($uibModal,AdjustmentOrderReview, NgTableParams, $timeout,toastr,enums) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;

        vm.search = search;
        vm.removeTab = removeTab;
        vm.formatDateTime =formatDateTime;
        vm.batchReview = batchReview;
        vm.review = review;
        vm.edit = edit;

        vm.typeObj = enums('inner.AdjustmentType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.AdjustmentStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.detailStatusObj = enums('inner.AdjustmentDetailStatus');
        vm.detailStatus = vm.detailStatusObj.list('title', 'id');

        vm.reasonCodeObj = enums('inner.AdjustmentReason');
        vm.reasonCode = vm.reasonCodeObj.list('title', 'id');

        // 通用方法
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(tabs,idx) {
            tabs.splice(idx, 1);
        }
        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        //列表页功能
        vm.tableParams = new NgTableParams({count : 10}, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                postParams.status=10;
                return AdjustmentOrderReview.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

// 编辑
        function edit(row){
            var entity = {
                isCreate:true,
                heading : '查看',
                contentId : "detail.html",
                storageObj:{},
                reasonCode : vm.reasonCode,
                status : vm.status,
                detailTableParams : new NgTableParams({
                    count : 10
                }, {
                    getData : function(params) {
                        var postParams = angular.merge({
                            pn : params.page(),
                            ps : params.count()
                        }, params.filter());
                        postParams.adjustNum = row.adjustNum;
                        return AdjustmentOrderReview.findAdjustmentDetailPage({}, postParams, function(resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                data : row,
                detail:{
                    adjustNum:row.adjustNum,
                    status: row.status,
                    ownerCode :row.ownerCode,
                },
                active : true
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        vm.getData =getData;
        function getData(id){
            var ids = [id];
            var data = {
                ids : ids,
                reviewRemark : null,
                type : null
            }
            return data;
        }

        vm.getDatas = getDatas;
        function getDatas(tables){
            var getSelected = tables.getSelected();
            var ids=[];
            for(var i=0;i<getSelected.length;i++){
                ids.push(getSelected[i].id);
            }
            var data = {
                ids : ids,
                reviewRemark : null,
                type : null
            }
            var datas = {
                data : data,
                reviewPass : vm.reviewPass
            }

            return datas;
        }


//审

        function batchReview(tables){
            var getSelected = tables.getSelected();
            var ids=[];
            for(var i=0;i<getSelected.length;i++){
                ids.push(getSelected[i].id);
            }
            if(ids.length==0){
                toastr.error("","请先选择！");
                return;
            }
            var data = {
                ids : ids,
                reviewRemark : null,
                type : null
            }
            vm.modalInstance = $uibModal.open({
                templateUrl : 'reviewBox.html',
                controller : reviewModle,
                controllerAs : '$ctrl',
                resolve : {
                    item :data
                }
            });
        }

        function review(row){
            var ids = [row.id];
            var data = {
                ids : ids,
                reviewRemark : null,
                type : null
            }
            vm.modalInstance = $uibModal.open({
                templateUrl : 'reviewBox.html',
                controller : reviewModle,
                controllerAs : '$ctrl',
                resolve : {
                    item :data
                }
            });
        }
        reviewModle.$inject = [ '$uibModalInstance' ,'item'];
        function reviewModle($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.ok = function(){
                $uibModalInstance.close();
            };
            $ctrl.response = {};
            $ctrl.data = {};
            $ctrl.item = item;
            $ctrl.cancel = function(){
                $uibModalInstance.dismiss('cancel');
            };

            $ctrl.reviewPass = function() {
                $ctrl.item.type=0;
                AdjustmentOrderReview.review({},$ctrl.item,function(resp){
                }).$promise.then(function(data){
                    toastr.success("审核通过！");
                    vm.tableParams.reload();
                    $ctrl.ok();
                });
            };
            $ctrl.reviewReruse = function() {
                $ctrl.item.type=1;
                if($ctrl.item.reviewRemark==null){
                    toastr.error("","请填写拒绝原因！");
                    return;
                }
                AdjustmentOrderReview.review({},$ctrl.item,function(resp){
                }).$promise.then(function(data){
                    toastr.success("拒绝成功！");
                    vm.tableParams.reload();
                    $ctrl.ok();
                });
            };
        }


    }
})();
