(function() {
    'use strict';

    angular
        .module('iwh-strategyModule')
        .factory('StrategyShipConfig', StrategyShipConfig);

    StrategyShipConfig.$inject = ['$resource', 'iServiceConfig'];

    function StrategyShipConfig ($resource, iServiceConfig) {
        var service = $resource('web/shipConfig', {}, {
            'getShipConfig': { url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/getShipConfig", method: 'POST'},
            'saveShipConfig': { url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/saveShipConfig", method: 'POST'},
            'saveDevice': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/saveDevice", method: 'POST'},
            'editeDevice': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/editeDevice", method: 'POST'},
            'deleteDevice': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/deleteDevice", method: 'POST'},
            'devicePage': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/devicePage", method: 'POST'},
            'ownerPage': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/ownerPage", method: 'POST'},
            'editeShipConfigOwner': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/editeShipConfigOwner", method: 'POST'},
            'ownerCarrierPage': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/ownerCarrierPage", method: 'POST'},
            'ownerDevicePage': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/ownerDevicePage", method: 'POST'},
            'shipConfigOwnerSave': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/shipConfigOwnerSave", method: 'POST'},
            'shipConfigCarrierSave': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/shipConfigCarrierSave", method: 'POST'},
            'editeShipConfigCarrier': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/editeShipConfigCarrier", method: 'POST'},
            'deleteShipConfigCarrier': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/deleteShipConfigCarrier", method: 'POST'},
            'saveShipConfigOwnerDevice': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/saveShipConfigOwnerDevice", method: 'POST'},
            'editeOwnerDevice': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/editeOwnerDevice", method: 'POST'},
            'deleteOwnerDevice': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/deleteOwnerDevice", method: 'POST'},
            'deleteOwner': {url: iServiceConfig.iwhStrategyHost + "/web/shipConfig/deleteOwner", method: 'POST'},
        });
        return service;
    }
})();
