(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('SkuCategory', SkuCategory);

    SkuCategory.$inject = ['$resource', 'iServiceConfig'];

    function SkuCategory ($resource, iServiceConfig) {
        var service = $resource('web/skucategory', {}, {
            'saveNew': {url: iServiceConfig.idataHost + "/web/skucategory/savenew", method: 'POST'},
            'updata': {url: iServiceConfig.idataHost + "/web/skucategory/updata", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/skucategory/detail", method: 'POST'},
            'del': {url: iServiceConfig.idataHost + "/web/skucategory/delete", method: 'POST'},
            'treeData': {url: iServiceConfig.idataHost + "/web/skucategory/treedata", method: 'POST'}
        });

        return service;
    }
})();
