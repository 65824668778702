(function () {
    'use strict';

    angular.module('idashboardModule')
        .controller('DashboardController', DashboardController);
    DashboardController.$inject = ['toastr','$timeout','iServiceConfig'];
    function DashboardController(toastr,$timeout,iServiceConfig) {
        var vm = this;
        vm.add = add;
        vm.closeDiv = closeDiv;

        //自定义设置
        function add(showDiv,bgDiv) {
            document.getElementById(showDiv).style.display='flex';
            document.getElementById(bgDiv).style.display='block' ;
        }
        //关闭弹出层
        function closeDiv(showDiv,bgDiv) {
            document.getElementById(showDiv).style.display='none';
            document.getElementById(bgDiv).style.display='none';
        };

        //获取所有配置数据
        vm.contentData = [];
        var getUrl = iServiceConfig.idashboardHost + '/web/mychart/all?token=' + jQuery.parseJSON(localStorage.getItem('iservice-authenticationToken'));
        jQuery.ajax({
            url: getUrl,
            type: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify({"chartType":"IDASHBOARD_MAIN"}),
            success: function(data) {
                vm.contentData = data.content;

                for(var i=0;i<vm.contentData.length;i++){
                    vm.contentData[i]['sortNum'] = i+1;
                }
            }
        });

        //列
        vm.type = [{'widthSpace':1,'title':'一列'},{'widthSpace':2,'title':'两列'},{'widthSpace':3,'title':'三列'}];
        //保存配置
        vm.saveConfig = function(){
            var arr = [];
            var list = vm.contentData;
            for(var i=0;i<list.length;i++){
                var temp;
                if(list[i]['status'] === 1){
                    temp = {
                        "chartCode" : list[i]["chartCode"],
                        "sortNum" : list[i]["sortNum"],
                        "widthSpace" : list[i]["widthSpace"],
                        "status" : list[i]["status"]
                    };
                    arr.push(temp);
                }
            }
            var url = iServiceConfig.idashboardHost + '/web/mychart/save';
            var params = {
                "chartType" : "IDASHBOARD_MAIN",
                "charts" : arr
            };
            jQuery.getD(url,params,function(data) {
                if(data.status == "SUCCESS"){
                    toastr.success("保存成功", "成功");
                    vm.closeDiv('MyDiv2','fade2');
                    window.location.reload();
                }
            },"post");
        };

        //改变图表排序
        vm.changeSortNum = changeSortNum;
        function changeSortNum(chart,sortWay) {
            if(chart['sortNum']){
                if(sortWay == 'asc'){
                    if(chart['sortNum'] == 1){
                        return ;
                    }
                    //改变上下两个对象在数组中的位置
                    var c = vm.contentData.splice(chart['sortNum']-1,1);
                    vm.contentData.splice(chart['sortNum']-2,0,c[0]);
                    //改变数组顺序后，chart['sortNum']-2则为被移动的对象，chart['sortNum']-1为被交换对象
                    vm.contentData[chart['sortNum']-1]['sortNum']+=1;
                    chart['sortNum']-=1;//当前对象应在前一个对象改变后 再进行修改，因为改变当前对象sortNum后，上一对象的index不再是chart['sortNum']-2
                }else{
                    if(chart['sortNum'] == vm.contentData.length){
                        return ;
                    }

                    //改变上下两个对象在数组中的位置
                    var c = vm.contentData.splice(chart['sortNum']-1,1);
                    vm.contentData.splice(chart['sortNum'],0,c[0]);
                    //改变数组顺序后，chart['sortNum']则为被移动的对象，chart['sortNum']-1为被交换对象
                    vm.contentData[chart['sortNum']-1]['sortNum']-=1;
                    chart['sortNum']+=1;
                }
            }
        }

        var fixHelperModified = function(e, tr) {
            var $originals = tr.children();
            var $helper = tr.clone();
            $helper.children().each(function(index) {
                $(this).width($originals.eq(index).width())
            });
            return $helper;
        };
        var  updateIndex = function(e, ui) {
            $('td.index', ui.item.parent()).each(function (i) {
                $(this).html(i + 1);
            });
            $timeout(function(){
                var tr2= $(ui.item[0]);//当前tr
                var tr1 = tr2.prev();//前一个tr
                var tr3 = tr2.next();//后一个tr
                var sortNum1 = null;
                if(tr1[0]){
                    sortNum1 = tr1.children('td')[0].textContent;
                }
                var sortNum3 = null;
                if(tr3[0]){
                    sortNum3 = tr3.children('td')[0].textContent;
                }
                var sortNum2 = tr2.children('td')[0].textContent;
                if((sortNum1<sortNum2 && sortNum3>sortNum2) || (sortNum3===null && sortNum2>sortNum1) || (sortNum1===null && sortNum2<sortNum3)){
                    return;
                }

                //如果sortNum1为null  说明当前已经到第一个
                if(sortNum1 === null){
                    var obj1 = vm.contentData.splice(sortNum2-1,1);
                    vm.contentData.splice(0,0,obj1[0]);
                    for(var i=0;i<vm.contentData.length;i++){
                        vm.contentData[i]['sortNum'] = i+1;
                    }
                    return;
                }
                //如果sortNum3为null  说明当前已经到最后一个
                if(sortNum3 === null){
                    var obj3 = vm.contentData.splice(sortNum2-1,1);
                    vm.contentData.splice(vm.contentData.length,0,obj3[0]);
                    for(var j=0;j<vm.contentData.length;j++){
                        vm.contentData[j]['sortNum'] = j+1;
                    }
                    return;
                }
                //如果sortNum1和sortNum3都存在
                if(sortNum1 && sortNum3){
                    var idx = sortNum1;//默认对象插入的位置是向前
                    //如果向后移动
                    if(sortNum1>sortNum2){
                        idx = sortNum1-1;
                    }

                    var obj2 = vm.contentData.splice(sortNum2-1,1);
                    vm.contentData.splice(idx,0,obj2[0]);
                    for(var k=0;k<vm.contentData.length;k++){
                        vm.contentData[k]['sortNum'] = k+1;
                    }
                }
            });
        };

        $("#chartConfig>tbody").sortable({
            helper: fixHelperModified,
            stop: updateIndex
        }).disableSelection();
    }
})();


