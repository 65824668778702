(function() {
    "use strict";
    angular.module("ivendorModule").factory("So", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.vendorHost + '/web/so', {}, {
        	'list': { url: iServiceConfig.vendorHost + "/web/so/list", method: 'POST'},
            'createSo': {url: iServiceConfig.vendorHost + "/web/so/createSo", method: 'POST'},
            'saveSo': {url: iServiceConfig.vendorHost + "/web/so/saveSo", method: 'POST'},
            'getSkus': {url: iServiceConfig.vendorHost + "/web/so/getSkus", method: 'POST'},
            'editeSo':{url: iServiceConfig.vendorHost + "/web/so/editeSo", method:'POST'},
            'updateSo':{url: iServiceConfig.vendorHost + "/web/so/updateSo", method:'POST'},
            'saveOneSku':{url: iServiceConfig.vendorHost + "/web/so/saveOneSku", method:'POST'},
            'getDetail':{url: iServiceConfig.vendorHost + "/web/so/getDetail", method:'POST'},
            'deleteDetail':{url: iServiceConfig.vendorHost + "/web/so/deleteDetail", method:'POST'},
            'checkSo':{url: iServiceConfig.vendorHost + "/web/so/checkSo", method:'POST'},
            'ship':{url: iServiceConfig.vendorHost + "/web/so/ship", method:'POST'},
            'deleteSo':{url: iServiceConfig.vendorHost + "/web/so/deleteSo", method:'POST'},
            'updateOneSku':{url: iServiceConfig.vendorHost + "/web/so/updateOneSku", method:'POST'},
            'getUploadSoDemo':{url: iServiceConfig.vendorHost + "/web/so/getUploadSoDemo", method:'POST'}
        });
    }]);
})();
