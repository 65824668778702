(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('ReplenishOrderController',
        ReplenishOrderController);

    ReplenishOrderController.$inject = [ 'ReplenishOrder', 'NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state'];

    function ReplenishOrderController(ReplenishOrder, NgTableParams, $uibModal,enums,
                                 $timeout, toastr,$state) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;

        vm.search = search;
        vm.searchAll = searchAll;
        vm.searchTask = searchTask;
        vm.searchDetail = searchDetail;

        vm.removeTab = removeTab;

        vm.cancel = cancel;
        vm.cancelTask = cancelTask;
        vm.close = close;
        vm.generateTask = generateTask;
        vm.show = show;
        vm.handOut = handOut;

        
       

        vm.typeObj = enums('inner.ReplenishOrderType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.ReplenishOrderStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.taskStatusObj = enums('inner.ReplenishOrderTaskStatus');
        vm.taskStatus = vm.taskStatusObj.list('title', 'id');

        vm.detailStatusObj = enums('inner.ReplenishOrderDStatus');
        vm.detailStatus = vm.detailStatusObj.list('title', 'id');

        
        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function searchAll(t) {
            vm.tableParams.reload();
            t.detailTableParams.reload();
            t.taskTableParams.reload();
        }

        function searchTask(t) {
            t.taskTableParams.reload();
        }

        function searchDetail(t) {
            t.detailTableParams.reload();
        }



        function removeTab(tabs,idx,isVm) {
            tabs.splice(idx, 1);
            if(isVm){
                vm.active = idx;
            }
        }
        
        //列表页功能
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return ReplenishOrder.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

//编辑
        function show(row) {
            var entity = {
                isCreate: false,
                heading: '编辑【' + row.replenishNum + '】',
                contentId: "edit.html",
                detailTableParams: new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.replenishNum = row.replenishNum;
                        return ReplenishOrder.detailList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                taskTableParams : new NgTableParams({
                    count: 10
                }, {
                    getData: function (params) {
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count()
                        }, params.filter());
                        postParams.replenishNum = row.replenishNum;
                        return ReplenishOrder.taskList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    }
                }),
                tabs: [],
                activeCount: 0,
                data: row,
                active: true
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        //发放
        function handOut(row) {
            var temp=[];
            if(row && row.data.id){
                temp.push(row.data.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                ReplenishOrder.handOut({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("发放成功", "成功");
                    if(row && row.data.id){
                        row.data.status=10;
                        row.taskTableParams.reload();
                    }
                    vm.tableParams.reload();
                });
            }else{
                toastr.error("请先选择补货单", "提示");
            }
        }

        //发放
        function close(row) {
            var temp=[];
            if(row && row.data.id){
                temp.push(row.data.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                ReplenishOrder.close({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("关闭成功", "成功");
                    if(row && row.data.id){
                        row.data.status=40;
                    }
                    vm.tableParams.reload();
                });
            }else{
                toastr.error("请先选择补货单", "提示");
            }
        }

        //取消
        function cancel(row) {
            var temp=[];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                ReplenishOrder.cancel({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("成功取消", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.error("请先选择补货单", "提示");
            }
        }

        //取消任务
        function cancelTask(row) {
            var temp=[];
            var replenishNum = null;
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(row.taskTableParams.getSelected)){
                    var list = row.taskTableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                            replenishNum = list[i].replenishNum;
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                ReplenishOrder.cancelTask({},{replenishNum: replenishNum,taskIds: temp},function(resp){
                }).$promise.then(function(data){
                    toastr.success("成功取消", "成功");
                    row.taskTableParams.reload();
                });
            }else{
                toastr.error("请先选择任务", "提示");
            }
        }

        //生成补货
        function generateTask(row) {
            var temp=[];
            if(row && row.data.id){
                temp.push(row.data.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                ReplenishOrder.generateTask({},temp,function(resp){
                }).$promise.then(function(data){
                	var msg = "";
                	if(data.content) {
                		angular.forEach(data.content, function(errormsg,index,array){
                			msg = msg + errormsg;
                		});
                	}
                	
                    if(row && row.data.id){
                        if(data.content){
                            toastr.error(msg, "生成失败");
                        } else {
                            toastr.success("任务生成中请稍候", "生成成功");
                            row.data.status=2;
                            row.detailTableParams.reload();
                        }
                    }else {
                        if(data.content){
                            toastr.warning(msg, "警告");
                        }else {
                            toastr.success("任务生成中请稍候", "生成成功");
                        }
                    }
                    vm.tableParams.reload();
                });
            }else{
                toastr.error("请先选择补货单", "提示");
            }
        }


        vm.generate = function(){

            vm.modalInstance = $uibModal.open({
                templateUrl: 'generate.html',
                controller: scConfirmModal2,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:{
                        heading:'生成补货',
                    }
                }
            });
        };
        scConfirmModal2.$inject = ['$uibModalInstance', 'item'];
        function scConfirmModal2($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.ok = ok;
            $ctrl.transferLoc = null;
            $ctrl.cancel = cancel;
            function ok() {
                if($ctrl.transferLoc == null){
                    toastr.error("请选择补货中间库位！","")
                    return;
                }
                var temp=[];

                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
                var vo = {
                    ids : temp,
                    transferLoc : $ctrl.transferLoc
                }
                if(angular.isArray(temp) && temp.length>0){
                    PendRepR.generateTask({},vo,function(resp){
                    }).$promise.then(function(data){
                        toastr.success("生成成功", "成功");
                        $uibModalInstance.close(item);
                        vm.tableParams.reload();
                    });
                }else{
                    toastr.error("请先选择补货单", "提示");
                }
            }
            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }


    }
})();
