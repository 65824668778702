(function() {
    'use strict';

    angular
        .module('iwh-innerModule')
        .factory('ReplenishOrder', ReplenishOrder);

    ReplenishOrder.$inject = ['$resource', 'iServiceConfig'];

    function ReplenishOrder ($resource, iServiceConfig) {
        var service = $resource('web/replenishOrder', {}, {
            'list': { url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/list", method: 'POST'},
            'detailList': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/detailList", method: 'POST'},
            'taskList': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/taskList", method: 'POST'},
            'handOut': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/handOut", method: 'POST'},
            'close': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/close", method: 'POST'},
            'cancel': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/cancel", method: 'POST'},
            'generateTask': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/generateTask", method: 'POST'},
            'cancelTask': {url: iServiceConfig.iwhInnerHost + "/web/replenishOrder/cancelTask", method: 'POST'},
        });

        return service;
    }
})();
