(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('group-attrs', {
            parent: 'main',
            url: 'iwh-strategy/group-attrs',
            data: {'pageTitle': '分组字段'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/groupRule/groupAttribute.html',
                    controller: 'GroupAttributeController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('GroupAttributeApi', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/group-rule/attrs', {}, {
            'listGet': {url: iServiceConfig.iwhStrategyHost + '/web/group-rule/attrs', method: 'GET'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/group-rule/attrs', method: 'POST'},
            'deletePost': {url: iServiceConfig.iwhStrategyHost + '/web/group-rule/attrs/delete', method: 'POST'},
            'getGroupTypes': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.GroupRuleType.json', method: 'GET'}
        });
    }]);

    app.controller('GroupAttributeController', [
        '$scope', 'GroupAttributeApi', 'toastr',
        function ($scope, GroupAttributeApi, toastr) {
            $scope.bizType = null;
            $scope.bizTypes = [];
            $scope.groupTypes = [{value: null, label: '全部'}];

            GroupAttributeApi.getGroupTypes({}, function (result) {
                result.content.forEach(function (item) {
                    $scope.groupTypes.push(item);
                    $scope.bizTypes[item.value] = item.label;
                });
            });

            $scope.getList = function () {
                GroupAttributeApi.listGet({bizType: $scope.bizType}, function (result) {
                    $scope.datas = result.content;
                });
            };

            $scope.save = function (data) {
                if (!data) return;
                if (!data.bizType) {
                    toastr.error('类型必须选择', '提示');
                    return;
                }
                if (!data.attribute || data.attribute.trim().length === 0) {
                    toastr.error('字段不能为空', '提示');
                    return;
                }
                if (!data.name || data.name.trim().length === 0) {
                    toastr.error('字段名称不能为空', '提示');
                    return;
                }
                GroupAttributeApi.savePost({}, data, function (resp) {
                    if (resp.content && resp.content.id) {
                        if ($scope.bizType && $scope.bizType === resp.content.bizType) {
                            $scope.datas.splice(0, 0, resp.content);
                        }
                        $scope.data = null;
                        toastr.success('保存成功！', '成功');
                    } else {
                        toastr.error('保存失败：' + resp['errorMsg'], '失败');
                    }
                });
            };

            $scope.delete = function (data) {
                if (!data && !data.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + (data.name || '待添加') + '】字段吗？',
                    ok: function () {
                        if (!data.id) {
                            $scope.data = null;
                        } else {
                            GroupAttributeApi.deletePost({id: data.id}, null, function (result) {
                                if (result.status === 'SUCCESS') {
                                    $scope.datas.splice($scope.datas.indexOf(data), 1);
                                    toastr.success('删除成功！', '成功');
                                } else {
                                    toastr.error('删除失败，请检查服务器数据是否正确！', '失败');
                                }
                            });
                        }
                    }
                };
            };

            $scope.getList();
        }
    ]);
})();