(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('SOController', SOController);

    SOController.$inject = ['$rootScope','$state', '$timeout','NgTableParams', 'enums', 'InventorySearch','DeliveryOrder','PickerOrderBiz', 'ShipOrder', 'ShipOrderDetail', 'PickDetail', 'toastr', 'iServiceConfig', 'ApiURI', 'BaseCode', '$uibModal','Principal'];

    function SOController ($rootScope,$state, $timeout,NgTableParams, enums,  InventorySearch, DeliveryOrder, PickerOrderBiz, ShipOrder, ShipOrderDetail, PickDetail, toastr, iServiceConfig, ApiURI, BaseCode, $uibModal,Principal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.curTab = null;
        vm.orderSource = enums('ship.ComeFrom');
        vm.comeFroms = vm.orderSource.list('name', 'id');
        
        vm.lackShip = enums('ship.LackShip');
        vm.lackShipOptions = vm.lackShip.list('name', 'id');
        vm.soStatus = enums('ship.SOStatus');
        vm.soPayStatus = enums('ship.SOPayStatus');
        vm.soPayStatusOptions = vm.soPayStatus.list('name', 'id');
        vm.soDetailStatus = enums('ship.SODetailStatus');
        vm.pickDetailPickType = enums('ship.PickDetailPickType');
        vm.pickDetailStatus = enums('ship.PickDetailStatus');
        vm.blockStatus = enums('ship.BlockStatus');
        vm.stockoutStatus = enums('ship.StockOutStatus');
        vm.soOpStatus = enums('ship.SOOpStatus');
        vm.processStatus = enums('ship.ProcessStatus');
        vm.allocateTypes = enums('ship.SoAllocateType').list('name', 'id');;
        vm.soTypes = {};
        vm.transportMethods = {};
        vm.payMethods = {};
        vm.containerGroupHost = iServiceConfig.iwhDataHost + ApiURI.getContainerGroupList;
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return ShipOrder.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        
        vm.searchSo = searchSo;
        vm.createSo = createSo;
        vm.editSo = editSo;
        vm.saveSo = saveSo;
        vm.allocForSo = allocForSo;
        vm.genPickOrder = genPickOrder;
        vm.cancelAllocForSo = cancelAllocForSo;
        vm.closeSo = closeSo;
        vm.allocBatch = allocBatch;
        vm.pickBatch = pickBatch;
        vm.shipBatch = shipBatch;
        vm.genPickOrderBatch = genPickOrderBatch;
        vm.cancelSo = cancelSo;
        vm.blockSo = blockSo;
        vm.viewSo = viewSo;
        vm.sendTms=sendTms;

        vm.searchSku = searchSku;
        vm.getSku = getSku;
        vm.saveSku = saveSku;
        vm.deleteSku = deleteSku;
        
        vm.deliverySOPick = deliverySOPick;

        vm.removeTab = removeTab;
        vm.openSkuModal = openSkuModal;
        vm.openSkuAllocateModal = openSkuAllocateModal;

        vm.buildParam = buildParam;

        vm.baseCode = BaseCode.codes([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TRANSPORT_METHOD', keyField: 'WMS_SO_TRANSPORT_METHOD'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_PAY_METHOD', keyField: 'WMS_SO_PAY_METHOD'}
        ]);

		vm.baseCodeObjs = BaseCode.codeObjs([
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TRANSPORT_METHOD', keyField: 'WMS_SO_TRANSPORT_METHOD'},
            {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_PAY_METHOD', keyField: 'WMS_SO_PAY_METHOD'}
		]);
                                    
        vm.soTypeList = [];
        vm.hasSoType = false;                        
	    vm.baseCodeObjs('WMS_SO_TYPE').then(function(data) {
	    	angular.forEach(data, function (item) {
	    		vm.soTypeList.push({ title: item.name, id: item.code });
	        });
	    	vm.hasSoType = true;
	    });
        
        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });
        vm.baseCode('WMS_SO_TRANSPORT_METHOD').then(function(data) {
            vm.transportMethods = data;
        });
        vm.baseCode('WMS_SO_PAY_METHOD').then(function(data) {
            vm.payMethods = data;
        });
        vm.payMethodList = [];
        vm.baseCodeObjs('WMS_SO_PAY_METHOD').then(function(data) {
	    	angular.forEach(data, function (item) {
	    		vm.payMethodList.push({ title: item.name, id: item.code });
	        });
	    });

        function buildParam(soNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (soNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    soNum : soNum
                };
            } else {
            	var orders = vm.tableParams.getSelected();
            	if (orders.length > 0) {
                    param = [];
                    for(var i=0;i<orders.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            soNum : orders[i].soNum
                        });
                    }
            	}
            }
            return param;
        }

        function searchSo() {
            vm.tableParams.reload();
        }

        function createSo() {
            var tab = {
                heading: '创建出库单',
                contentId: "iwh-ship-module/biz/so/soEdit.html",
                data: {
                    orderTime: new Date(),
                    comeFrom: 'WMS',
                    customerAddr: {}
                },

                operatorLogParams: undefined,

                soSkuParams: undefined,

                pickDetailParams: undefined,

                getDefaultConfig: getDefaultConfig,

                changeCustomer: changeCustomer,

                changeCarrier: changeCarrier
            };

            ShipOrder.genSoNum(function(data) {
                tab.data.soNum = data.content;
            });

            vm.tabs.push(tab);
            switchTab(vm.tabs.length);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);

            function getDefaultConfig(data) {
                if (!data.id && data.ownerVo && data.ownerVo.code) {
                    var param = {ownerCode: data.ownerVo.code};
                    if (data.carrierVo && data.carrierVo.code) {
                        param.carrierCode = data.carrierVo.code;
                    }

                    ShipOrder.shipConfig({}, param, function(resp) {
                        if (resp && resp.content) {
                            applyDefaultConfig(resp.content, data);
                        }
                    });
                }
            }

            function applyDefaultConfig(config, data) {
                if (config.allowLackShip) {
                    data.lackShip = vm.lackShip.value('YES');
                    data.maxLackRate = config.maxLackRate;
                } else {
                    data.lackShip = vm.lackShip.value('NO');
                    data.maxLackRate = '';
                }
                if (config.needCutbox) {
                    data.cutboxRule = config.cutboxRule;
                    data.cutboxContainer = config.cutboxContainer;
                }
            }
        }

        function viewSo(soObj) {
            if (soObj && soObj.soNum) {
                ShipOrder.detail({soNum: soObj.soNum}, function(data) {
                    var vo = _prepareSO_view(data.content);
                    var tab = {
                        heading: '查看出库单',
                        contentId: "iwh-ship-module/biz/so/soEdit.html",
                        data: vo,

                        operatorLogParams: _newLogTableParam({soNum: soObj.soNum}),

                        soSkuParams: _newSkuTableParam({soNum: soObj.soNum}),

                        pickDetailParams: _newPickDetailTableParam({soNum: soObj.soNum}),

                        readonly: true
                    };

                    // tab.operatorLogParams = _newLogTableParam({soNum: soObj.soNum});
                    // tab.soSkuParams = _newSkuTableParam({soNum: soObj.soNum});
                    // tab.pickDetailParams = _newPickDetailTableParam({soNum: soObj.soNum});

                    vm.tabs.push(tab);
                    switchTab(vm.tabs.length);
                });
            } else {
                toastr.error("订单参数不正确");
            }
        }

        function editSo(soObj) {
            if (soObj && soObj.soNum) {
                ShipOrder.detail({soNum: soObj.soNum}, function(data) {
                    var vo = _prepareSO_view(data.content);
                    vo.customerAddr = {}
                    var tab = {
                        heading: '编辑出库单',
                        contentId: "iwh-ship-module/biz/so/soEdit.html",
                        params: {soNum: soObj.soNum},
                        data: vo,

                        operatorLogParams: undefined,

                        soSkuParams: undefined,

                        pickDetailParams: undefined,

                        reload: undefined,

                        changeCustomer: changeCustomer,

                        changeCarrier: changeCarrier
                    };

                    tab.operatorLogParams = _newLogTableParam({soNum: soObj.soNum});
                    tab.soSkuParams = _newSkuTableParam({soNum: soObj.soNum});
                    tab.pickDetailParams = _newPickDetailTableParam({soNum: soObj.soNum});
                    tab.reload = _newReloadFunc({soNum: soObj.soNum}, tab);

                    vm.tabs.push(tab);
                    switchTab(vm.tabs.length);
                });
            } else {
                toastr.error("订单参数不正确");
            }
        }

        function saveSo(vo, tab) {
            var checkResult = _checkSO(vo);
            if (!checkResult) {
                return;
            }

            var so = _prepareSO_submit(vo);

            var saveResult = null;
            if (so.id) {
                saveResult = ShipOrder.update(so);
            } else {
                saveResult = ShipOrder.save(so);
            }

            saveResult.$promise.then(function(data) {
                if (data && data.content) {
                    tab.data = _prepareSO_view(data.content);

                    console.debug(tab.operatorLogParams);
                    if (!tab.operatorLogParams) {
                        tab.operatorLogParams = _newLogTableParam({soNum: tab.data.soNum});
                    }
                    if (!tab.soSkuParams) {
                        tab.soSkuParams = _newSkuTableParam({soNum: tab.data.soNum});
                    }
                    if (!tab.pickDetailParams) {
                        tab.pickDetailParams = _newPickDetailTableParam({soNum: tab.data.soNum});
                    }
                    if (!tab.reload) {
                        tab.reload = _newReloadFunc({soNum: tab.data.soNum}, tab);
                    }

                    tab.reload();
                    toastr.success('保存成功');
                }
            });
        }
        function deliverySOPick(so, tab) {
            var params = {
                soNum: so.soNum,
                packMaterial: '',
                waybillNum: '',
                carrierName:'',
                onlyShipPickSku: true
            };
            DeliveryOrder.deliveryBySoNum({}, params, function() {
                $timeout(function() {
                    toastr.success('发货成功');
                    tab.reload();
                });
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    toastr.error(resp.data.errorMsg);
                }
            });
        }
        function genPickOrder(so, tab) {
            if (so && so.soNum) {
            	
                var soNums = [];
                soNums.push(so.soNum);
    
                ShipOrder.genPickOrderBatch({}, {soNums: soNums}, function(data) {
                    var reqResult = data.content;
                    $timeout(function() {
                        var successLen = reqResult.successItems.length;
                        var msg = "";
                        if (successLen > 0) {
                            msg = "成功生成拣选单： " + reqResult.successItems[0].num;
                            toastr.success(msg);
                        } else {
                            msg = "生成拣选单失败";
                            toastr.warning(msg);
                        }
                        
                        vm.tableParams.reload();
                        if(tab && tab.reload) {
                            tab.reload();
                        }
                        // ShipOrder.detail({soNum: soObj.soNum}, function(data) {
                        //     var vo = _prepareSO_view(data.content);
                        //     tab.data = vo;
                        //     tab.reload();
                        // });
                    });
                });
            }
        }
        function allocForSo(so, tab) {
            console.debug(so);
            if (so && so.soNum) {
            	if (so.allocateType && so.allocateType == 1) {
            		ShipOrder.allocCase({soNum: so.soNum}, {}, function (data) {
                        var reqResult = data.content;
                        $timeout(function() {
                            var successLen = reqResult.successSoNums.length;
                            var errorLen = reqResult.errorItems.length;
                            var msg = "成功请求分配个数： " + successLen + "，失败个数：" + errorLen;
                            if (errorLen > 0) {
                                msg = msg + '<br>';
                                angular.forEach(reqResult.errorItems, function (item) {
                                    msg = msg + "错误单号：" + item.soNum + "，错误原因：" + item.reason + '<br>';
                                });

                                toastr.warning(msg);
                            } else {
                                toastr.success(msg);
                            }

                            vm.tableParams.reload();
                            if(tab && tab.reload) {
                                tab.reload();
                            }
                        });
                    });
            	} else {
            		ShipOrder.alloc({soNum: so.soNum}, {}, function (data) {
                        var reqResult = data.content;
                        $timeout(function() {
                            var successLen = reqResult.successSoNums.length;
                            var errorLen = reqResult.errorItems.length;
                            var msg = "成功请求分配个数： " + successLen + "，失败个数：" + errorLen;
                            if (errorLen > 0) {
                                msg = msg + '<br>';
                                angular.forEach(reqResult.errorItems, function (item) {
                                    msg = msg + "错误单号：" + item.soNum + "，错误原因：" + item.reason + '<br>';
                                });

                                toastr.warning(msg);
                            } else {
                                toastr.success(msg);
                            }

                            vm.tableParams.reload();
                            if(tab && tab.reload) {
                                tab.reload();
                            }
                        });
                    });
            	}
            }
        }

        function cancelAllocForSo(sos, tab) {
            console.debug(sos);
            if (angular.isObject(sos)) {
                sos = [sos];
            }

            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });

            ShipOrder.cancelAlloc({}, {soNums: soNums}, function(data) {
                $timeout(function() {
                    toastr.success("取消分配成功");
                    $timeout(function() {
                        vm.tableParams.reload();

                        if(tab && tab.reload) {
                            tab.reload();
                        }
                    });
                });
            });
        }
        function genPickOrderBatch(sos) {
            if (angular.isObject(sos) && !angular.isArray(sos)) {
                sos = [sos];
            }
            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });

            ShipOrder.genPickOrderBatch({}, {soNums: soNums}, function(data) {
            	var reqResult = data.content;
                $timeout(function() {
                    var successLen = reqResult.successItems.length;
                    var msg = "成功生成拣选单个数： " + successLen;
                    toastr.success(msg);
                    vm.tableParams.reload();
                });
            });
        }
        function allocBatch(sos) {
        	if (angular.isObject(sos) && !angular.isArray(sos)) {
                sos = [sos];
            }
            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });

            ShipOrder.allocBatch({}, {soNums: soNums}, function(data) {
            	var reqResult = data.content;
                $timeout(function() {
                    var successLen = reqResult.successSoNums.length;
                    var errorLen = reqResult.errorItems.length;
                    var msg = "成功请求分配个数： " + successLen + "，失败个数：" + errorLen;
                    if (errorLen > 0) {
                        msg = msg + '<br>';
                        angular.forEach(reqResult.errorItems, function (item) {
                            msg = msg + "错误单号：" + item.soNum + "，错误原因：" + item.reason + '<br>';
                        });

                        toastr.warning(msg);
                    } else {
                        toastr.success(msg);
                    }

                    vm.tableParams.reload();
                });
            });
        }

        function pickBatch(sos) {
        	if (angular.isObject(sos) && !angular.isArray(sos)) {
                sos = [sos];
            }
            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });
            if (soNums.length <= 0) {
                toastr.error('参数错误：请选择需要拣选的出库单', "错误");
                return;
            }
            var params = {
                soNums: soNums
            };
            PickerOrderBiz.pickBySoNums({}, params, function(resp) {
                $timeout(function() {
                    if (resp.content.successSoNums && resp.content.successSoNums.length > 0) {
                        toastr.success('拣选成功订单：'+resp.content.successMsg);
                    }
                    if (resp.content.errorSoNums && resp.content.errorSoNums.length > 0) {
                        toastr.error('拣选失败订单：'+resp.content.errorMsg, "错误");
                    }
                    vm.tableParams.reload();
                });
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    toastr.error(resp.data.errorMsg, "错误");
                }
                vm.tableParams.reload();
            });
        }
        function shipBatch(sos) {
        	if (angular.isObject(sos) && !angular.isArray(sos)) {
                sos = [sos];
            }
            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });
            if (soNums.length <= 0) {
                toastr.error('参数错误：请选择需要发运的出库单', "错误");
                return;
            }
            var params = {
                soNums: soNums,
                packMaterial: '',
                waybillNum: '',
                carrierName:'',
                onlyShipPickSku: true
            };
            DeliveryOrder.deliveryBySoNums({}, params, function(resp) {
                $timeout(function() {
                    if (resp.content.successSoNums && resp.content.successSoNums.length > 0) {
                        toastr.success('发运成功订单：'+resp.content.successMsg);
                    }
                    if (resp.content.errorSoNums && resp.content.errorSoNums.length > 0) {
                        toastr.error('发运失败订单：'+resp.content.errorMsg, "错误");
                    }
                    vm.tableParams.reload();
                });
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    toastr.error(resp.data.errorMsg, "错误");
                }
                vm.tableParams.reload();
            });
        }

        function closeSo(sos, tab) {
            if (angular.isObject(sos) && !angular.isArray(sos)) {
                sos = [sos];
            }
            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });

            ShipOrder.close({}, {soNums: soNums}, function(data) {
                $timeout(function() {
                    if (tab) {
                        vm.removeTab(vm.active - 1);
                    } else {
                        vm.tableParams.reload();
                    }
                    if (data.content && data.content.content && data.content.content.moveNum) {
                        toastr.success('关闭成功, 创建了移动单:' + data.content.content.moveNum + ',请到仓库库内/移动计划中执行移动操作');
                    } else {
                        toastr.success("关闭成功");
                    }

                });
            });
        }

        function blockSo(so, tab) {
            if (so && so.soNum) {
                ShipOrder.block({soNum: so.soNum}, {}, function(data) {
                    $timeout(function() {
                        if (tab) {
                            vm.removeTab(vm.active - 1);
                        }
                        $timeout(function() {
                            vm.tableParams.reload();

                            if(tab && tab.reload) {
                                tab.reload();
                            }
                        });

                        toastr.success("拦截成功");
                    });
                });
            }
        }
        function sendTms(so, tab) {
        	ShipOrder.sendToTms({soNum: so.soNum}, function(data) {
        		toastr.success("推送成功");
            });
        }
        function cancelSo(sos, tab) {
            if (angular.isObject(sos)) {
                sos = [sos];
            }

            var soNums = [];
            angular.forEach(sos, function(item) {
                soNums.push(item.soNum);
            });

            ShipOrder.cancel({}, {soNums: soNums}, function(data) {
                $timeout(function() {
                    if (tab) {
                        vm.removeTab(vm.active - 1);
                    }
                    $timeout(function() {
                        vm.tableParams.reload();
                        if(tab && tab.reload) {
                            tab.reload();
                        }
                    });

                    toastr.success("取消成功");
                });
            });
        }

        function searchSku() {
            vm.soSkuParams.reload();
        }

        vm.chooseInventoryAddToSo = chooseInventoryAddToSo;
        function chooseInventoryAddToSo(t) {
            console.log(t);
            vm.modalInstance = $uibModal.open({
                templateUrl: 'iwh-ship-module/biz/so/soAddSkuByInventory.html',
                controller: chooseInventoryAddToSoModal,
                size: 'xl',
                backdrop: 'static',
                controllerAs: '$ctrl',
                resolve: {
                    item : t
                }
            });
        }
        chooseInventoryAddToSoModal.$inject = ['$uibModalInstance', 'item', 'InventorySearch', 'ShipOrderDetail'];
        function chooseInventoryAddToSoModal($uibModalInstance, item, InventorySearch, ShipOrderDetail) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.soTypes = vm.soTypes;
            $ctrl.soTypeList = vm.soTypeList;
            $ctrl.orderSource = vm.orderSource;
            $ctrl.soStatus = vm.soStatus;
            $ctrl.blockStatus = vm.blockStatus;
            $ctrl.stockoutStatus = vm.stockoutStatus;
            $ctrl.orderQueryParam = {
                carrierNum: $ctrl.item.data.carrierCode,
                roadline: $ctrl.item.data.roadline,
                plateNum: $ctrl.item.data.carrierPlateNum
            };
            $ctrl.stationHost = vm.stationHost;
            $ctrl.ownerCode = $ctrl.item.data.ownerCode;
            $ctrl.searchTemp = {
                tenantId : null,
                warehouseCode : null,
                lotRule : null
            };

            InventorySearch.beforeList({}, {}, function(resp) {
                $ctrl.searchTemp = resp.content;
            });

            $ctrl.chooseInventoryTableParams = new NgTableParams({
                count : 10,counts: [10,20,50,100,200,500,1000,2000]
            }, {
                getData : function(params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count()
                    }, params.filter());
                    if($ctrl.ownerCode){
                        postParams.sownerCode = $ctrl.item.data.ownerCode;
                        postParams.ownerCode = $ctrl.item.data.ownerCode;
                        if($ctrl.lotAttributeSearch!=null){
                            if($ctrl.lotAttributeSearch.lotAttribute01){
                                postParams.lotAttribute01 = $ctrl.lotAttributeSearch.lotAttribute01;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute02){
                                postParams.lotAttribute02 = $ctrl.lotAttributeSearch.lotAttribute02;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute03){
                                postParams.lotAttribute03 = $ctrl.lotAttributeSearch.lotAttribute03;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute04){
                                postParams.lotAttribute04 = $ctrl.lotAttributeSearch.lotAttribute04;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute05){
                                postParams.lotAttribute05 = $ctrl.lotAttributeSearch.lotAttribute05;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute06){
                                postParams.lotAttribute06 = $ctrl.lotAttributeSearch.lotAttribute06;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute07){
                                postParams.lotAttribute07 = $ctrl.lotAttributeSearch.lotAttribute07;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute08){
                                postParams.lotAttribute08 = $ctrl.lotAttributeSearch.lotAttribute08;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute09){
                                postParams.lotAttribute09 = $ctrl.lotAttributeSearch.lotAttribute09;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute10){
                                postParams.lotAttribute10 = $ctrl.lotAttributeSearch.lotAttribute10;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute11){
                                postParams.lotAttribute11 = $ctrl.lotAttributeSearch.lotAttribute11;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute12){
                                postParams.lotAttribute12 = $ctrl.lotAttributeSearch.lotAttribute12;
                            }
                        }
                    }
                    return InventorySearch.lotLocLPNInventory({}, postParams, function(resp) {
                        params.total(resp.content.pageVo.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.pageVo.datas;
                        var index = 0;
                        var lotLocLpnCaseQtyMap = $ctrl.item.data.lotLocLpnCaseQtyMap;
                        if (!lotLocLpnCaseQtyMap) {
                            lotLocLpnCaseQtyMap = {};
                        }
                        angular.forEach(datas, function(data) {
                            index++;
                            data.index = index;
                            var key = data.skuCode+"$$"+data.lot+"$$"+data.locCode+"$$"+data.lpn+"$$"+data.caseNum;
                            var currentUseQty = lotLocLpnCaseQtyMap[key];
                            data.currentUseQty = currentUseQty;
                            if (data.currentUseQty && data.currentUseQty > 0) {
                                data.currentCanUseQty = data.availableQty - data.currentUseQty;
                            } else {
                                data.currentCanUseQty = data.availableQty;
                            }

                            if (data.currentCanUseQty <= 0) {
                                data.currentCanUseQty = 0;
                            }
                            data.currentOutboundQty = data.currentCanUseQty;
                            data.canOutbound = data.currentCanUseQty > 0;
                        });
                        return datas;
                    });
                }
            });
            $ctrl.ruleChange = function ruleChange() {
                $ctrl.lotAttributeSearch = null;
                if ($ctrl.ownerCode && $ctrl.searchTemp && $ctrl.searchTemp.lotRule) {
                    $ctrl.lotAttributeSearch = {
                        tenantId: $ctrl.searchTemp.tenantId,
                        warehouseCode: $ctrl.searchTemp.warehouseCode,
                        lotRule: $ctrl.searchTemp.lotRule,
                        ownerCode: $ctrl.item.data.ownerCode,
                        skuCode: '-1'
                    };
                }
            }
            $ctrl.search = function search() {
                if(!$ctrl.ownerCode){
                    $ctrl.lotAttributeSearch == null;
                }
                $ctrl.chooseInventoryTableParams.reload();
            }

            $ctrl.addInventoryToSo = function addInventoryToSo() {
                var invlist = $ctrl.chooseInventoryTableParams.getSelected();
                if (!invlist || invlist.length == 0) {
                    toastr.error("请选择需要添加到当前出库单的库存");
                    return;
                }
                var message = null;
                angular.forEach(invlist, function(inv) {
                    if (inv.availableQty <= 0 || inv.availableQty < inv.currentOutboundQty || inv.currentOutboundQty <= 0) {
                        if (message) {
                            message = message + ", "+ inv.index;
                        } else {
                            message = "出库数量必须 >0 并且 <= 可用库存不足，行号:"+ inv.index ;
                        }
                    }
                });
                if (message) {
                    toastr.error(message);
                    return;
                }
                ShipOrderDetail.addInventoryToSo({}, {soNum: $ctrl.item.data.soNum, invlist: invlist}, function(resp) {
                    // toastr.success("添加成功，你还可以继续添加 或者 关闭当前窗口");
                    toastr.success("成功添加库存到出库单中！");
                    $ctrl.item.reload($ctrl.item.data, $ctrl.item);
                    // $ctrl.chooseInventoryTableParams.reload();
                    $ctrl.ok();
                });
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        function openSkuAllocateModal(row, tab) {
            var modalInstance = $uibModal.open({
                templateUrl: 'iwh-ship-module/biz/so/soSkuAllocateInv.html',
                controller: skuAllocateInventoryModal,
                controllerAs: '$ctrl',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    item: tab,
                    soLine: row
                }
            });
        }
        skuAllocateInventoryModal.$inject = ['$uibModalInstance', 'item', 'soLine', 'InventorySearch', 'ShipOrderDetail'];
        function skuAllocateInventoryModal($uibModalInstance, item, soLine, InventorySearch, ShipOrderDetail) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.soLine = soLine;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.ownerCode = $ctrl.item.data.ownerCode;
            $ctrl.searchTemp = {
                tenantId : null,
                warehouseCode : null,
                lotRule : null
            };

            InventorySearch.beforeList({}, {}, function(resp) {
                $ctrl.searchTemp = resp.content;
            });

            $ctrl.chooseInventoryTableParams = new NgTableParams({
                count : 10,counts: [10,20,50,100,200,500,1000,2000]
            }, {
                getData : function(params) {
                    var postParams = angular.merge({
                        pn : params.page(),
                        ps : params.count()
                    }, params.filter());
                    if($ctrl.ownerCode){
                        postParams.sownerCode = $ctrl.item.data.ownerCode;
                        postParams.ownerCode = $ctrl.item.data.ownerCode;
                        postParams.skuCode = $ctrl.soLine.skuCode;
                        postParams.lot = $ctrl.soLine.lot;
                        postParams.locCode = $ctrl.soLine.locationCode;
                        postParams.lpn = $ctrl.soLine.lpn && $ctrl.soLine.lpn !== "" && $ctrl.soLine.lpn !== " " ? $ctrl.soLine.lpn : postParams.lpn;
                        postParams.caseNum = $ctrl.soLine.oriBoxNum && $ctrl.soLine.oriBoxNum !== " " ? $ctrl.soLine.oriBoxNum : postParams.caseNum;
                        if($ctrl.lotAttributeSearch!=null){
                            if($ctrl.lotAttributeSearch.lotAttribute01){
                                postParams.lotAttribute01 = $ctrl.lotAttributeSearch.lotAttribute01;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute02){
                                postParams.lotAttribute02 = $ctrl.lotAttributeSearch.lotAttribute02;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute03){
                                postParams.lotAttribute03 = $ctrl.lotAttributeSearch.lotAttribute03;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute04){
                                postParams.lotAttribute04 = $ctrl.lotAttributeSearch.lotAttribute04;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute05){
                                postParams.lotAttribute05 = $ctrl.lotAttributeSearch.lotAttribute05;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute06){
                                postParams.lotAttribute06 = $ctrl.lotAttributeSearch.lotAttribute06;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute07){
                                postParams.lotAttribute07 = $ctrl.lotAttributeSearch.lotAttribute07;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute08){
                                postParams.lotAttribute08 = $ctrl.lotAttributeSearch.lotAttribute08;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute09){
                                postParams.lotAttribute09 = $ctrl.lotAttributeSearch.lotAttribute09;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute10){
                                postParams.lotAttribute10 = $ctrl.lotAttributeSearch.lotAttribute10;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute11){
                                postParams.lotAttribute11 = $ctrl.lotAttributeSearch.lotAttribute11;
                            }
                            if($ctrl.lotAttributeSearch.lotAttribute12){
                                postParams.lotAttribute12 = $ctrl.lotAttributeSearch.lotAttribute12;
                            }
                        }
                        postParams.lotAttribute01 = $ctrl.soLine.lotAttribute01 && $ctrl.soLine.lotAttribute01 !=="" && $ctrl.soLine.lotAttribute01 !== " " ? $ctrl.soLine.lotAttribute01 : postParams.lotAttribute01;
                        postParams.lotAttribute02 = $ctrl.soLine.lotAttribute02 && $ctrl.soLine.lotAttribute02 !=="" && $ctrl.soLine.lotAttribute02 !== " " ? $ctrl.soLine.lotAttribute02 : postParams.lotAttribute02;
                        postParams.lotAttribute03 = $ctrl.soLine.lotAttribute03 && $ctrl.soLine.lotAttribute03 !=="" && $ctrl.soLine.lotAttribute03 !== " " ? $ctrl.soLine.lotAttribute03 : postParams.lotAttribute03;
                        postParams.lotAttribute04 = $ctrl.soLine.lotAttribute04 && $ctrl.soLine.lotAttribute04 !=="" && $ctrl.soLine.lotAttribute04 !== " " ? $ctrl.soLine.lotAttribute04 : postParams.lotAttribute04;
                        postParams.lotAttribute05 = $ctrl.soLine.lotAttribute05 && $ctrl.soLine.lotAttribute05 !=="" && $ctrl.soLine.lotAttribute05 !== " " ? $ctrl.soLine.lotAttribute05 : postParams.lotAttribute05;
                        postParams.lotAttribute06 = $ctrl.soLine.lotAttribute06 && $ctrl.soLine.lotAttribute06 !=="" && $ctrl.soLine.lotAttribute06 !== " " ? $ctrl.soLine.lotAttribute06 : postParams.lotAttribute06;
                        postParams.lotAttribute07 = $ctrl.soLine.lotAttribute07 && $ctrl.soLine.lotAttribute07 !=="" && $ctrl.soLine.lotAttribute07 !== " " ? $ctrl.soLine.lotAttribute07 : postParams.lotAttribute07;
                        postParams.lotAttribute08 = $ctrl.soLine.lotAttribute08 && $ctrl.soLine.lotAttribute08 !=="" && $ctrl.soLine.lotAttribute08 !== " " ? $ctrl.soLine.lotAttribute08 : postParams.lotAttribute08;
                        postParams.lotAttribute09 = $ctrl.soLine.lotAttribute09 && $ctrl.soLine.lotAttribute09 !=="" && $ctrl.soLine.lotAttribute09 !== " " ? $ctrl.soLine.lotAttribute09 : postParams.lotAttribute09;
                        postParams.lotAttribute10 = $ctrl.soLine.lotAttribute10 && $ctrl.soLine.lotAttribute10 !=="" && $ctrl.soLine.lotAttribute10 !== " " ? $ctrl.soLine.lotAttribute10 : postParams.lotAttribute10;
                        postParams.lotAttribute11 = $ctrl.soLine.lotAttribute11 && $ctrl.soLine.lotAttribute11 !=="" && $ctrl.soLine.lotAttribute11 !== " " ? $ctrl.soLine.lotAttribute11 : postParams.lotAttribute11;
                        postParams.lotAttribute12 = $ctrl.soLine.lotAttribute12 && $ctrl.soLine.lotAttribute12 !=="" && $ctrl.soLine.lotAttribute12 !== " " ? $ctrl.soLine.lotAttribute12 : postParams.lotAttribute12;
                    }
                    return InventorySearch.lotLocLPNInventory({}, postParams, function(resp) {
                        params.total(resp.content.pageVo.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.pageVo.datas;
                        var index = 0;
                        var lotLocLpnCaseQtyMap = $ctrl.item.data.lotLocLpnCaseQtyMap;
                        if (!lotLocLpnCaseQtyMap) {
                            lotLocLpnCaseQtyMap = {};
                        }
                        angular.forEach(datas, function(data) {
                            index++;
                            data.index = index;
                            var needAllocateQty = $ctrl.soLine.orderQty - $ctrl.soLine.preallocateQty - $ctrl.soLine.allocateQty;

                            data.currentCanUseQty = data.availableQty >= needAllocateQty ? needAllocateQty : data.availableQty;

                            data.currentOutboundQty = data.currentCanUseQty;
                            data.canOutbound = data.currentCanUseQty > 0;
                        });
                        return datas;
                    });
                }
            });
            $ctrl.ruleChange = function ruleChange() {
                $ctrl.lotAttributeSearch = null;
                if ($ctrl.ownerCode && $ctrl.searchTemp && $ctrl.searchTemp.lotRule) {
                    $ctrl.lotAttributeSearch = {
                        tenantId: $ctrl.searchTemp.tenantId,
                        warehouseCode: $ctrl.searchTemp.warehouseCode,
                        lotRule: $ctrl.searchTemp.lotRule,
                        ownerCode: $ctrl.item.data.ownerCode,
                        skuCode: '-1'
                    };
                    $ctrl.lotAttributeSearch.lotAttribute01 = $ctrl.soLine.lotAttribute01;
                    $ctrl.lotAttributeSearch.lotAttribute02 = $ctrl.soLine.lotAttribute02;
                    $ctrl.lotAttributeSearch.lotAttribute03 = $ctrl.soLine.lotAttribute03;
                    $ctrl.lotAttributeSearch.lotAttribute04 = $ctrl.soLine.lotAttribute04;
                    $ctrl.lotAttributeSearch.lotAttribute05 = $ctrl.soLine.lotAttribute05;
                    $ctrl.lotAttributeSearch.lotAttribute06 = $ctrl.soLine.lotAttribute06;
                    $ctrl.lotAttributeSearch.lotAttribute07 = $ctrl.soLine.lotAttribute07;
                    $ctrl.lotAttributeSearch.lotAttribute08 = $ctrl.soLine.lotAttribute08;
                    $ctrl.lotAttributeSearch.lotAttribute09 = $ctrl.soLine.lotAttribute09;
                    $ctrl.lotAttributeSearch.lotAttribute10 = $ctrl.soLine.lotAttribute10;
                    $ctrl.lotAttributeSearch.lotAttribute11 = $ctrl.soLine.lotAttribute11;
                    $ctrl.lotAttributeSearch.lotAttribute12 = $ctrl.soLine.lotAttribute12;
                }
            }
            $ctrl.search = function search() {
                if(!$ctrl.ownerCode){
                    $ctrl.lotAttributeSearch == null;
                }
                $ctrl.chooseInventoryTableParams.reload();
            }

            $ctrl.allocateInventoryToSoLine = function allocateInventoryToSoLine() {
                var invlist = $ctrl.chooseInventoryTableParams.getSelected();
                console.log(invlist);

                if (!invlist || invlist.length == 0) {
                    toastr.error("请选择需要分配到当前出库商品的库存");
                    return;
                }
                var needAllocateQty = $ctrl.soLine.orderQty - $ctrl.soLine.preallocateQty - $ctrl.soLine.allocateQty;
                var message = null;
                var inputAllocateQty = 0;
                angular.forEach(invlist, function(inv) {
                    if (inv.availableQty <= 0 || inv.availableQty < inv.currentOutboundQty || inv.currentOutboundQty <= 0) {
                        if (message) {
                            message = message + ", "+ inv.index;
                        } else {
                            message = "出库数量必须 >0 并且 <= 可用库存不足，行号:"+ inv.index ;
                        }
                    } else {
                        inputAllocateQty = inputAllocateQty + inv.currentOutboundQty;
                    }
                });

                if (message) {
                    toastr.error(message);
                    return;
                }
                if (inputAllocateQty > needAllocateQty) {
                    toastr.error("输入库存量 "+inputAllocateQty+" > "+needAllocateQty+", 不能提交！");
                    return;
                }
                ShipOrderDetail.allocateInventoryToSoLine({}, {soNum: $ctrl.item.data.soNum, lineNum: $ctrl.soLine.lineNum, invlist: invlist}, function(resp) {
                    // toastr.success("添加成功，你还可以继续添加 或者 关闭当前窗口");
                    toastr.success("成功分配库存给出库商品！");
                    $ctrl.item.reload($ctrl.item.data, $ctrl.item);
                    $ctrl.ok();
                });
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
        function openSkuModal(row, tab) {
            var modalInstance = $uibModal.open({
                templateUrl: 'iwh-ship-module/biz/so/soSkuEdit.html',
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    item:function(){
                        return vm.getSku(row, tab.data.tenantId, tab.data.warehouseCode, tab.data.ownerVo.code, tab.data.soNum);
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.allocateType = tab.data.allocateType;

                $ctrl.ok = ok;
                $ctrl.cancel = cancel;

                function ok() {
                    vm.saveSku($ctrl.item, tab, modalInstance);
                }

                function cancel() {
                    modalInstance.close();
                }
            }
        }

        function getSku(row, tenantId, warehouseCode, ownerCode, soNum) {
            if(row) {
                var soLineNum = row.lineNum;
                return new Promise(function(resolve) {
                    ShipOrderDetail.detail({soNum: soNum, soLineNum: soLineNum}, function(data) {
                        var vo = _prepareSOSku_view(data.content, tenantId, warehouseCode, ownerCode, soNum);
                        resolve(vo);
                    }, function() {
                        var vo = _prepareSOSku_view(null, tenantId, warehouseCode, ownerCode, soNum);
                        resolve(vo);
                    });
                });
            }

            return _prepareSOSku_view(null, tenantId, warehouseCode, ownerCode, soNum);
        }

        function saveSku(row, tab, modalInstance) {
            if (!row || !row.skuVo) {
                toastr.error("商品信息不能为空");
                return;
            }
            if (!row.skuVo.name) {
                toastr.error("商品名称不能为空");
                return;
            }
            if (!row.packCode) {
                toastr.error("包装编码不能为空");
                return;
            }
            if (!row.uom) {
                toastr.error("单位不能为空");
                return;
            }
            if (!row.packOrderQty) {
                toastr.error("期望数量不能为空");
                return;
            }
            
            if (tab.data.allocateType && tab.data.allocateType == 1) {
            	if (!row.oriBoxNum || row.oriBoxNum.trim() == '') {
                    toastr.error("当前出库订单分配类型为“整箱分配”，箱号不能为空");
                    return;
                }
            }

            if (row && row.skuVo) {
                var so = _prepareSOSku_submit(row);
                var promise = null;
                if (!so.id) {
                    promise = ShipOrderDetail.save(so).$promise;
                } else {
                    promise = ShipOrderDetail.update(so).$promise;
                }

                promise.then(function(data) {
                    modalInstance.close();

                    tab.data.version += 1;

                    tab.reload();
                    toastr.success('保存成功');
                });
            } else if(row && angular.isFunction(row.then)) {
                row.then(function(row) {
                    var so = _prepareSOSku_submit(row);
                    var promise = null;
                    if (!so.id) {
                        promise = ShipOrderDetail.save(so).$promise;
                    } else {
                        promise = ShipOrderDetail.update(so).$promise;
                    }

                    promise.then(function(data) {
                        modalInstance.close();

                        tab.data.version += 1;

                        tab.reload();
                        toastr.success('保存成功');
                    });
                });
            }
        }

        function deleteSku(skus, tab) {
            console.debug(skus);
            if (skus && skus.length > 0) {
                var soNum = tab.data.soNum;
                var ids = [];
                angular.forEach(skus, function(sku) {
                    ids.push(sku.id);
                });

                ShipOrderDetail.delete({}, {soNum: soNum, ids: ids}, function() {
                    tab.data.version += 1;

                    tab.reload();
                    toastr.success('删除成功');
                })
            }
        }

        function _checkSO(vo) {
            var so = vo;
            if (so.carrierVo) {
                var containVal = false;
                for (var f in so.carrierVo) {
                    if (so.carrierVo[f] !== '') {
                        containVal = true;
                        break;
                    }
                }

                if (containVal) {
                    if (!so.carrierVo.code) {
                		so.carrierVo.code = '0';
                    }
                }
            }

            if (so.customerVo) {
                var containVal = false;
                for (var f in so.customerVo) {
                    if (so.customerVo[f] !== '') {
                        containVal = true;
                        break;
                    }
                }

                if (containVal) {
                	if (!so.customerVo.code) {
                		so.customerVo.code = '0';
                    }
                }
            }

            return true;
        }

        function _prepareSO_submit(vo) {
            if (vo.customerAddr && vo.customerAddr.area) {
                vo.customerVo.provinceId = vo.customerAddr.province.code;
                vo.customerVo.province = vo.customerAddr.province.name;
                vo.customerVo.cityId = vo.customerAddr.city.code;
                vo.customerVo.city = vo.customerAddr.city.name;
                vo.customerVo.areaId = vo.customerAddr.area.code;
                vo.customerVo.area = vo.customerAddr.area.name;
            }
            var so = angular.copy(vo);
            if (so.ownerVo) {
                so.ownerCode = so.ownerVo.code;
                so.ownerName = so.ownerVo.name;
                delete so.ownerVo;
            }

            if (so.carrierVo && so.carrierVo.code) {
                so.carrierVo.id = null;
                so.carrierVo.countryId = 'CN';
                if (so.carrier && so.carrier.id) {
                    so.carrierVo.id = so.carrier.id;
                }
                if (so.carrierVo.code) {
                    so.carrier = so.carrierVo;
                    so.carrier.carrierPhone = so.carrierVo.phone;
                    so.carrier.carrierContact = so.carrierVo.contact;
                }

                delete so.carrierVo;
            } else {
                delete so.carrier;
                delete so.carrierVo;
            }

            if (so.customerVo && so.customerVo.code) {
                so.customerVo.id = null;
                so.customerVo.countryId = 'CN';
                if (so.customer && so.customer.id) {
                    so.customerVo.id = so.customer.id;
                }
                so.customer = so.customerVo;

                delete so.customerVo;
            } else {
                delete so.customer;
                delete so.customerVo;
            }
            return so;
        }

        function _prepareSO_view(so) {
            var vo = angular.copy(so);
            vo.ownerVo = {};
            vo.ownerVo.code = so.ownerCode;
            vo.ownerVo.name = so.ownerName;
            if (so.carrier) {
                vo.carrierVo = so.carrier;
                vo.carrierVo.phone = so.carrier.carrierPhone;
                vo.carrierVo.contact = so.carrier.carrierContact;
            }
            if (so.customer) {
                vo.customerVo = so.customer;
            }

            return vo;
        }

        function _prepareSOSku_submit(vo) {
            console.debug(vo);
            if (vo) {
                var so = {
                    id: vo.id,
                    soNum: vo.soNum,
                    lineNum: vo.lineNum,
                    packCode: vo.packCode,
                    uom: vo.uom,
                    oriBoxNum: vo.oriBoxNum,
                    orderQty: vo.orderQty,
                    lot: vo.lot,
                    locationCode: vo.locationCode,
                    lpn: vo.lpn,
                    sellPrice: vo.sellPrice,
                    remark: vo.remark,
                    extendField1: vo.extendField1,
                    extendField2: vo.extendField2,
                    extendField3: vo.extendField3,
                    extendField4: vo.extendField4,
                    extendField5: vo.extendField5,
                    extendField6: vo.extendField6,
                    extendField7: vo.extendField7,
                    extendField8: vo.extendField8,
                    extendField9: vo.extendField9,
                    extendField10: vo.extendField10
                };

                if(vo.skuVo) {
                    so.skuCode = vo.skuVo.code;
                    so.skuName = vo.skuVo.name;
                }

                if(vo.lotVo) {
                    so.lotAttribute01 = vo.lotVo.lotAttribute01;
                    so.lotAttribute02 = vo.lotVo.lotAttribute02;
                    so.lotAttribute03 = vo.lotVo.lotAttribute03;
                    so.lotAttribute04 = vo.lotVo.lotAttribute04;
                    so.lotAttribute05 = vo.lotVo.lotAttribute05;
                    so.lotAttribute06 = vo.lotVo.lotAttribute06;
                    so.lotAttribute07 = vo.lotVo.lotAttribute07;
                    so.lotAttribute08 = vo.lotVo.lotAttribute08;
                    so.lotAttribute09 = vo.lotVo.lotAttribute09;
                    so.lotAttribute10 = vo.lotVo.lotAttribute10;
                    so.lotAttribute11 = vo.lotVo.lotAttribute11;
                    so.lotAttribute12 = vo.lotVo.lotAttribute12;
                }

                so.uomBaseQty = vo.uomVo.count;

                return so;
            }

            return null;
        }

        function _prepareSOSku_view(so, tenantId, warehouseCode, ownerCode, soNum) {
            console.debug(so);
            if (so) {
                var vo = {
                    id: so.id,
                    soNum: soNum,
                    ownerCode: ownerCode,
                    lineNum: so.lineNum,
                    skuVo: {
                        code: so.skuCode,
                        name: so.skuName
                    },
                    packCode: so.packCode,
                    uom: so.uom,
                    oriBoxNum: so.oriBoxNum,
                    sellPrice: so.sellPrice,
                    uomVo: {
                        count: so.uomBaseQty
                    },
                    orderQty: so.orderQty,
                    lot: so.lot,
                    locationCode: so.locationCode,
                    lpn: so.lpn,
                    lotVo: {
                        tenantId: tenantId,
                        warehouseCode: warehouseCode,
                        ownerCode: ownerCode,
                        skuCode: so.skuCode,
                        bizType: 'SHIP',
                        lotAttribute01: so.lotAttribute01,
                        lotAttribute02: so.lotAttribute02,
                        lotAttribute03: so.lotAttribute03,
                        lotAttribute04: so.lotAttribute04,
                        lotAttribute05: so.lotAttribute05,
                        lotAttribute06: so.lotAttribute06,
                        lotAttribute07: so.lotAttribute07,
                        lotAttribute08: so.lotAttribute08,
                        lotAttribute09: so.lotAttribute09,
                        lotAttribute10: so.lotAttribute10,
                        lotAttribute11: so.lotAttribute11,
                        lotAttribute12: so.lotAttribute12,
                        needDefault: false,
                        needRequire: false
                    },
                    remark: so.remark,
                    extendField1: so.extendField1,
                    extendField2: so.extendField2,
                    extendField3: so.extendField3,
                    extendField4: so.extendField4,
                    extendField5: so.extendField5,
                    extendField6: so.extendField6,
                    extendField7: so.extendField7,
                    extendField8: so.extendField8,
                    extendField9: so.extendField9,
                    extendField10: so.extendField10,
                    needInitQty: true,
                    needInitPack: true,
                    needInitUom: true,

                    updateLotVo: updateLotVo,
                    updatePack: updatePack,
                    updateQty: updateQty
                };

                return vo;
            }

            return {
                soNum: soNum,
                ownerCode: ownerCode,
                lotVo: {
                    tenantId: tenantId,
                    warehouseCode: warehouseCode,
                    ownerCode: ownerCode,
                    bizType: 'SHIP',
                    needDefault: false,
                    needRequire: false,
                    skuCode: ''
                },
                sellPrice: '',
                updateLotVo: updateLotVo,
                updatePack: updatePack,
                updateQty: updateQty
            };

            function updateLotVo(vo) {
            	console.log(vo)
                if (vo.skuVo && vo.lotVo) {
                    if (vo.skuVo.code !== vo.lotVo.skuCode) {
                        var lotVo = angular.copy(vo.lotVo);
                        lotVo.skuCode = vo.skuVo.code;
//                        if (!lotVo.needDefault) {
//                            lotVo.needDefault = !lotVo.needDefault;
//                        }
                        vo.lotVo = lotVo;
                    }
                }
                if (!vo.sellPrice && vo.skuVo) {
                	vo.sellPrice = vo.skuVo.sellPrice;
                }
                if (!vo.needInitPack) {
                    vo.packCode = '';
                } else {
                    vo.needInitPack = false;
                }
            }

            function updatePack(vo) {
                if (!vo.needInitUom) {
                    vo.uom = '';
                } else {
                    vo.needInitUom = false;
                }
            }

            function updateQty(oldUom, uom, vo) {
                if(uom) {
                    var count = uom.count;

                    if (count) {
                        vo.uomVo = uom;

                        if (vo.needInitQty) {
                            if (vo.orderQty || vo.orderQty === 0) {
                                vo.packOrderQty = parseInt(vo.orderQty / count);
                                vo.needInitQty = false;
                            }
                        } else {
                            if (vo.packOrderQty) {
                                vo.orderQty = parseInt(vo.packOrderQty * count);
                            }
                        }
                    }
                }
            }
        }

        function _newLogTableParam(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.soNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count()}, addParams);
                        return ShipOrder.log(postParams, {},
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                            var datas = data.content.datas;
                            return datas;
                        });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }

        function _newSkuTableParam(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.soNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count(), soNum: addParams.soNum}, params.filter());
                        this.httpParams = postParams;
                        return ShipOrderDetail.list({}, postParams,
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }

        function _newPickDetailTableParam(addParams) {
            return new NgTableParams({},{
                getData: function(params) {
                    if (addParams.soNum) {
                        var postParams = angular.merge({pn: params.page(), ps: params.count(), soNum: addParams.soNum}, params.filter());
                        this.httpParams = postParams;
                        return PickDetail.list({}, postParams, 
                            function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                    } else {
                        params.total(0);
                        return new Promise(function(resolve) {
                            resolve([]);
                        });
                    }
                }
            });
        }

        function _newReloadFunc(params, tab) {
            return function() {
                ShipOrder.detail(params, function(data) {
                    var refreshVo = _prepareSO_view(data.content);
                    tab.data =  refreshVo;
                });

                if (tab.operatorLogParams) {
                    tab.operatorLogParams.reload();
                }

                if (tab.soSkuParams) {
                    tab.soSkuParams.reload();
                }

                if (tab.pickDetailParams) {
                    tab.pickDetailParams.reload();
                }
            };
        }

        function changeCustomer(model, t) {
            if (model) {
                if (!t.data.customer || !t.data.customer.id) {
                    if (t.data.customer && model.code === t.data.customer.code) {
                        t.data.customerVo = {
                            code: t.data.customer.code,
                            name: t.data.customer.name,
                            phone: t.data.customer.phone,
                            email: t.data.customer.email,
                            countryId: t.data.customer.countryId,
                            provinceId: t.data.customer.provinceId,
                            cityId: t.data.customer.cityId,
                            areaId: t.data.customer.areaId,
                            address: t.data.customer.address,
                            receiver: t.data.customer.contact,
                            receiverPhone: t.data.customer.phone,
                            bakAddress: t.data.customer.bakAddress
                        };
                    } else {
                        t.data.customerVo = {
                            code: model.code,
                            name: model.name,
                            phone: model.phone,
                            email: model.email,
                            countryId: model.countryId,
                            provinceId: model.provinceId,
                            cityId: model.cityId,
                            areaId: model.areaId,
                            address: model.address,
                            receiver: model.receiver,
                            receiverPhone: model.receiverPhone,
                            bakAddress: model.bakAddress
                        };
                    }
                }
            } else {
                t.data.customerVo = null;
            }
        }

        function changeCarrier(model, t) {
            if (model) {
                if (!t.data.carrier || !t.data.carrier.id) {
                    if (t.data.carrier && model.code === t.data.carrier.code) {
                        t.data.carrierVo = {
                            code: t.data.carrier.code,
                            name: t.data.carrier.name,
                            phone: t.data.carrier.carrierPhone,
                            countryId: t.data.carrier.countryId,
                            provinceId: t.data.carrier.provinceId,
                            cityId: t.data.carrier.cityId,
                            areaId: t.data.carrier.areaId,
                            address: t.data.carrier.address,
                            contact: t.data.carrier.carrierContact
                        };
                    } else {
                        t.data.carrierVo = {
                            code: model.code,
                            name: model.name,
                            phone: model.phone,
                            countryId: model.countryId,
                            provinceId: model.provinceId,
                            cityId: model.cityId,
                            areaId: model.areaId,
                            address: model.address,
                            contact: model.contact
                        };
                    }
                }
            } else {
                t.data.carrierVo = null;
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
            // if (vm.tabs.length - idx > 0) {
            //     vm.tabs.splice(idx,1);
            //     vm.active = idx;
            //     switchTab(idx);
            // }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
                vm.curTab = vm.tabs[vm.active - 1];
            }, 0);
        }


        vm.toggle = toggle;
        function toggle(row,o){
            row[o] = !row[o];
        }
        
        if ($state.params.soNum) {
        	vm.viewSo({soNum:$state.params.soNum});
        }
    }
})();
