(function () {
    'use strict';

    angular
        .module('iplanModule')
        .factory('iPlanWarehousePlanLog', iPlanWarehousePlanLog);

    iPlanWarehousePlanLog.$inject = ['$resource', 'iServiceConfig'];

    function iPlanWarehousePlanLog($resource, iServiceConfig) {
        var service = $resource('web/warehousePlanLog', {}, {
            'search': {url: iServiceConfig.iplanHost + "/web/warehousePlanLog/list", method: 'POST'}
        });

        return service;
    }
})();
