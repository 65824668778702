(function() {
    'use strict';

    angular.module('iwh-inventoryModule')
        .controller('AgeInventoryQueryController', AgeInventoryQueryController);

    AgeInventoryQueryController.$inject = ['$rootScope', '$timeout','NgTableParams', 'enums', 'AgeInventory', 'toastr', '$uibModal'];

    function AgeInventoryQueryController ($rootScope, $timeout,NgTableParams, enums, AgeInventory, toastr,  $uibModal) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        
        vm.search = search;

        vm.tableParams = new NgTableParams({},{
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return AgeInventory.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function(data) {
                        var datas = data.content.datas;
                        return datas;
                    });
            }
        });
        vm.getCurrentRowStyle = function(age) {
        	if(age <= 30) {
        		return {};
        	} else if(age > 30 && age <= 60) {
        		return {'background-color': '#b1a511', 'color': '#fff'};
        	} else if(age > 60 && age <= 100) {
        		return {'background-color': '#EE7600', 'color': '#fff'};
        	} else if(age > 100 && age <= 200) {
        		return {'background-color': '#CD3278', 'color': '#fff'};
        	} else if(age > 200) {
        		return {'background-color': '#A52A2A', 'color': '#fff'};
        	}
        }
        
        function search() {
            vm.tableParams.reload();
        }
        function removeTab(idx) {
            vm.tabs.splice(idx,1);
            vm.active = idx;
        }
    }
})();
