(function () {
    'use strict';

    angular.module('iwh-innerModule').config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('adjustmentOrder',{
                parent:'main',
                url: 'iwh-inner/adjustmentOrder',
                data:{
                    'pageTitle': '库存调整单'
                },
                views:{
                    'mainContent':{
                        templateUrl: 'iwh-inner-module/adjustment/adjustment.html',
                        controller: 'AdjustmentController',
                        controllerAs: 'vm'
                    }
                },
                resolve:{}
            })
    }


})();