(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('AgeInventory', AgeInventory);

    AgeInventory.$inject = ['$resource', 'iServiceConfig'];

    function AgeInventory ($resource, iServiceConfig) {
        var service = $resource('web/ageInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/ageInventory/list", method: 'POST'},
        });
        return service;
    }
})();
