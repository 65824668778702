(function()  {
    'use strict';

    angular.module('itms-orderModule')
        .controller('TmsRoadTrackController', TmsRoadTrackController);

    TmsRoadTrackController.$inject = ['$rootScope','$stateParams', 'TmsRoadTrack', 'TmsRoadOrderExecute', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout', 'iServiceConfig'];

    function TmsRoadTrackController ($rootScope,$stateParams, RoadTrack, RoadOrderExecute, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.roadNum = null;
        vm.modalInstance = null;
        vm.iServiceConfig = iServiceConfig;
        vm.productType = {
            code : null,
            name : null
        };
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "已提交"},
            {id : 20, title : "已计划"},
            {id : 30, title : "运输中"},
            {id : 40, title : "已完成"},
            {id : -10, title : "已取消"},
        ];
        vm.statusMap = {'0':'新建','10':'已提交','20':'已计划','30':'运输中','40':'已完成', '-10' :'取消'};

        function pageTitle() {
            return $window.document.title;
        }
        
      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count(),
					status: 30
				}, params.filter());
				return RoadTrack.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});
		vm.search = search;
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.showMap = function(row) {
            vm.currentRoadOrder = row;
            RoadOrderExecute.trailStations({}, row.roadNum, function(resp) {
                
                if (resp && resp.content && resp.content.length > 0) {
                    var labelsData = [];
                    var i = 0;
                    angular.forEach(resp.content,function(trailStation,index,objs){
                        i++;
                        var labelName = trailStation.station.shortName;
                        labelName = labelName + (trailStation.loadOrders && trailStation.loadOrders.length > 0 ? ', 装载：'+trailStation.loadOrders.length : '');
                        labelName = labelName + (trailStation.unloadOrders && trailStation.unloadOrders.length > 0 ? ', 卸载：'+trailStation.unloadOrders.length : '');
                        labelsData.push({
                            name: labelName,
                            position: [trailStation.station.longitude, trailStation.station.latitude],
                            zooms: [10, 20],
                            opacity: 1,
                            zIndex: 5,
                            icon: {
                                type: 'image',
                                image: 'http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                                clipOrigin: [459, 92],
                                clipSize: [50, 68],
                                size: [25, 34],
                                anchor: 'bottom-center',
                                angel: 0,
                                retina: true
                            },
                            text: {
                                content: labelName,
                                direction: 'top',
                                offset: [0, 0],
                                style: {
                                    fontSize: 15,
                                    fontWeight: '800',
                                    fillColor: '#EE0000',
                                    strokeColor: '#fff',
                                    strokeWidth: 2,
                                }
                            }
                        });
                    });
                    window.openMapLine('mapContainer', labelsData);
                    vm.active = 1;
                } else {
                    window.openMapLine('mapContainer', []);
                    toastr.error("无数据");
                }
                
            });
        }
		
		vm.show = show;
		function show(row) {
            RoadTrack.showRoadOrder({}, row.roadNum, function(resp) {
				var RoadTrack = resp.content;
				var entity = {
						heading : '查看',
						contentId : "show.html",
						active : true,
                        roadOrder : RoadTrack.roadOrder,
                        transportOrderList : RoadTrack.transportOrderList,
                        roadOrderGpsList : RoadTrack.roadOrderGpsList,
                        transportTableParam : new NgTableParams({}, {getData:function(){return RoadTrack.transportOrderList}}),
                        gpsTableParam : new NgTableParams({}, {getData:function(){return RoadTrack.roadOrderGpsList}}),
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}


        vm.reportLocation = reportLocation;
        function reportLocation(row) {
            window.getMapLocation('mapLocationContainer', function(result){
                console.log(result);
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'reportLocation.html',
                    controller: reportLocationModal,
                    controllerAs: '$ctrl',
                    resolve: {
                        item : {
                            roadOrder: row,
                            locatiionObj: {
                                longitude: result.position.lng,
                                latitude: result.position.lat,
                                address: result.formattedAddress
                            }
                        }                        
                    }
                });
            });
        }
        reportLocationModal.$inject = ['$uibModalInstance','item'];
        function reportLocationModal($uibModalInstance,item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.vo = {
                longitude : item.locatiionObj.longitude,
                latitude : item.locatiionObj.latitude,
                address : item.locatiionObj.address,
                remark : null ,
                roadNum :item.roadOrder.roadNum,
            }
            $ctrl.reportLocation = reportLocation;
            function reportLocation() {
                    RoadTrack.reportLocation({}, $ctrl.vo, function (resp) {
                        if (resp.content == "success") {
                            toastr.success("上传成功");
                            $uibModalInstance.close($ctrl.item);
                            vm.tableParams.reload();
                        }
                    });

            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
})();
