(function() {
    'use strict';

    angular.module('idataModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('customer-maintenance', {
                parent: 'main',
                url: 'data/customer-maintenance?ownerCode',
                params: {'ownerCode' : null},
                data: {
                    'pageTitle': '客户维护',
                    'ownerCode': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'data-module/customer/customer.html',
                        controller: 'CustomerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
