(function() {
    'use strict';

    angular.module('doveModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config($stateProvider) {
        $stateProvider
            .state('iBustaskRunning', {
                parent: 'main',
                url: 'bus/taskRunning',
                data: {
                    'pageTitle': '调度任务运行监控'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/run/task/taskRunning.html',
                        controller: 'iBusTaskRunningController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            }).state('iBusprocessRunning', {
                parent: 'main',
                url: 'bus/processRunning',
                data: {
                    'pageTitle': '流程运行监控'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/run/process/processRunning.html',
                        controller: 'iBusProcessRunningController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            }).state('iBusdsRunning', {
                parent: 'main',
                url: 'bus/dsRunning',
                data: {
                    'pageTitle': '数据源运行监控'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/run/datasource/dsRunning.html',
                        controller: 'iBusDsRunningController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
    }
})();
