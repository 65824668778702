(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('ShipQuery', ShipQuery);

    ShipQuery.$inject = ['$resource', 'iServiceConfig'];

    function ShipQuery ($resource, iServiceConfig) {
        var service = $resource('web/shipQuery', {}, {
        	'sku'     : { url: iServiceConfig.iwhShipHost + "/web/shipQuery/sku", method: 'POST'},
            'lot'  : { url: iServiceConfig.iwhShipHost + "/web/shipQuery/lot", method: 'POST'},
            'boxDetail'  : { url: iServiceConfig.iwhShipHost + "/web/shipQuery/boxDetail", method: 'POST'}
        });
        return service;
    }
})();
