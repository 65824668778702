(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('SowingPickController', SowingPickController);

    SowingPickController.$inject = ['$rootScope', '$timeout', 'PickOrder', '$filter', '$uibModal', 'toastr'];

    function SowingPickController ($rootScope, $timeout, PickOrder, $filter, $uibModal, toastr) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];

        vm.alertColor = {
            current: {'background-color': '#dc3545', 'color': '#fff'},
            on: {'background-color': '#f8f9fa', 'color': '#000'},
            doing: {'background-color': '#ffc107', 'color': '#fff'},
            done: {'background-color': '#28a745', 'color': '#fff'},
            off: {'background-color': '#868e96', 'color': '#fff'}
        };
        vm.charArray = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        vm.boxMaxWidth = 120;

        vm.checkStatus = {
            status: 'SUCCESS',
            message: '扫描成功'
        };
        vm.params = {
            useScanGun: false,
            pickerCode: '',
            sowRow: '',
            sowCol: '',
            pickNum: '',
            skuBarcode: ''
        };
        vm.rows = [];
        vm.positionMap = {};
        vm.pickOrder = {};
        vm.pickDetails = [];
        vm.boxMap = {};
        vm.sku2Boxes = {};
        vm.barcode2SkuCode = {};
        vm.box = null;
        vm.skuCode = null;
        vm.changeOrderCmd = '$CHANGEPKO$';
        vm.errorCtrlCmd = '$ERRORCTRL$';
        vm.endSowCmd = '$ENDSCAN$';

        vm.inputEmployeeCode = inputEmployeeCode;
        vm.inputSowWallStart = inputSowWallStart;
        vm.inputSowWallEnd = inputSowWallEnd;
        vm.inputPickNum = inputPickNum;
        vm.inputSkuBarcode = inputSkuBarcode;
        vm.confirmSowPick = confirmSowPick;
        vm.confirmSowPickAndCloseBox = confirmSowPickAndCloseBox;
        vm.resetSowSku = resetSowSku;
        vm.getCurrentPickOrder = getCurrentPickOrder;
        vm.success = success;
        vm.fail = fail;
        vm.sortPickDetails = sortPickDetails;
        vm.removeTab = removeTab;
        
        vm.selectedSowBox = null;
        vm.spiltSowBox = spiltSowBox;
        vm.getCurrentSowBox = getCurrentSowBox;
        function spiltSowBox(pickNum, selectedSowBox) {
        	vm.success();
            PickOrder.splitSowBox({}, {pickNum: pickNum, boxNum: selectedSowBox.box.boxNum}, function (resp) {
                toastr.success("拆分箱成功");
                vm.selectedSowBox = null;
                vm.success();
                getSowingPickOrder(pickNum);
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'pickNum');

                    fail(resp.data.errorMsg);
                }
            });
        	
        }
        function getCurrentSowBox() {
            return vm.selectedSowBox;
        }
        
        vm.selectSowBox = function(boxObj) {
        	if (vm.selectedSowBox && vm.selectedSowBox.box.boxNum == boxObj.box.boxNum) {
        		vm.selectedSowBox = null;
        	} else {
        		vm.selectedSowBox = boxObj;
        	}
        }

        function inputEmployeeCode($event, pickerCode) {
            if ($event.which === 13) {
                if (pickerCode) {
                    jQuery('#pickNum').select();
                }
            }
        }

        function inputSowWallStart($event, sowRow) {
            if ($event.which === 13) {
                if (sowRow) {
                    jQuery('#sowCol').select();
                }
            }
        }

        function inputSowWallEnd($event, sowRow, sowCol) {
            if ($event.which === 13) {
                if (sowRow && sowCol) {
                    calcBoxes(vm.params.sowRow, vm.params.sowCol);
                    jQuery('#pickNum').select();
                }
            }
        }
        function getSowingPickOrder(pickNum) {
        	resetPickOrder();
            PickOrder.getSowingPickOrder({pickNum: pickNum}, {}, function (resp) {
                vm.success();
                vm.pickOrder = resp.content;

                if (vm.pickOrder.pickNum) {
                    angular.forEach(vm.pickOrder.boxes, function(box) {
                        vm.positionMap[box.sowPosition] = box;
                        vm.boxMap[box.boxNum] = box;

                        angular.forEach(box.pickDetails, function (pd) {
                            vm.pickDetails.push(pd);

                            var skuCode = pd.skuCode;

                            if (!vm.sku2Boxes[skuCode]) {
                                vm.sku2Boxes[skuCode] = [];
                            }
                            if (vm.sku2Boxes[skuCode].indexOf(box.boxNum) === -1) {
                                vm.sku2Boxes[skuCode].push(box.boxNum);

                                var skuBarcodes = pd.skuBarcodes;
                                angular.forEach(skuBarcodes, function(barcode) {
                                    vm.barcode2SkuCode[barcode] = skuCode;
                                });
                            }
                            box[skuCode] = pd;

                            pd.styleFn = function () {
                                var style = {};
                                if (vm.box && this.skuCode === vm.skuCode && this.boxNum === vm.box.boxNum) {
                                    style = vm.alertColor.current;
                                } else if (this.sowQty > 0 && this.sowQty === this.qty) {
                                    style = vm.alertColor.done;
                                } else if (this.sowQty > 0) {
                                    style = vm.alertColor.doing;
                                } else {
                                    style = vm.alertColor.on;
                                }

                                return style;
                            };
                        });
                    });

                    for (var skuCode in vm.sku2Boxes) {
                        var boxes = vm.sku2Boxes[skuCode];
                        vm.sku2Boxes[skuCode] = $filter('orderBy')(boxes, '+priority');
                    }

                    var success = calcBoxes(vm.params.sowRow, vm.params.sowCol);
                    if (success) {
                        vm.sortPickDetails(vm.pickDetails);
                        goSkuBarcode();
                    }
                } else {
                    calcBoxes(vm.params.sowRow, vm.params.sowCol);
                }
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'pickNum');

                    fail(resp.data.errorMsg);
                }
            });
        }
        function inputPickNum($event, pickNum) {
            if ($event.which === 13) {
                if (pickNum) {
                	vm.success();
                	getSowingPickOrder(pickNum);
                }
            }
        }

        function sortPickDetails(pickDetails) {
            if (pickDetails && pickDetails.length > 0) {
                vm.pickDetails = $filter('orderBy')(pickDetails, function(data) {
                    return {
                        'boxNum': data.boxNum,
                        'skuCode': data.skuCode,
                        'qty': data.qty,
                        'sowQty': data.sowQty
                    };
                }, false, function(obj1, obj2) {
                    var boxNum1 = obj1.value.boxNum;
                    var skuCode1 = obj1.value.skuCode;
                    var qty1 = obj1.value.qty;
                    var sowQty1 = obj1.value.sowQty;
                    var boxNum2 = obj2.value.boxNum;
                    var skuCode2 = obj2.value.skuCode;
                    var qty2 = obj2.value.qty;
                    var sowQty2 = obj2.value.sowQty;

                    if (vm.box && vm.skuCode) {
                        if (boxNum1 === vm.box.boxNum && skuCode1 === vm.skuCode) {
                            return -1;
                        }
                        if (boxNum2 === vm.box.boxNum && skuCode2 === vm.skuCode) {
                            return 1;
                        }
                    }

                    if (qty1 !== sowQty1 || qty2 !== sowQty2 ) {
                        if (qty1 === sowQty1) {
                            return 1;
                        }
                        if (qty2 === sowQty2) {
                            return -1;
                        }
                    }

                    if (sowQty1 === 0 || sowQty2 === 0) {
                        if (sowQty1 > 0) {
                            return -1;
                        }
                        if (sowQty2 > 0) {
                            return 1;
                        }
                    }

                    if (obj1.index < obj2.index) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }
        }

        function inputSkuBarcode($event, skuBarcode) {
            if ($event.which === 13) {
                if (skuBarcode) {
                    if (vm.changeOrderCmd === skuBarcode || vm.endSowCmd === skuBarcode) {
                        execCmd(skuBarcode);
                    } else {
                        resetSku();

                        var skuCode = vm.barcode2SkuCode[skuBarcode];
                        if (!skuCode) {
                            skuCode = skuBarcode;
                        }

                        var boxNums = vm.sku2Boxes[skuCode];
                        if (boxNums) {
                            var sowSku = {};
                            for (var i=0; i<boxNums.length; i++) {
                                var boxNum = boxNums[i];
                                var box = vm.boxMap[boxNum];
                                var pickDetail = box[skuCode];
                                if (pickDetail && pickDetail.qty > pickDetail.sowQty) {
                                    vm.box = box;
                                    vm.box.css = 'black';
                                    $timeout(function() {
                                        vm.box.css = '';
                                    }, 300);

                                    sowSku.boxNum = box.boxNum;
                                    sowSku.skuCode = skuCode;
                                    sowSku.qty = 1;
                                    break;
                                }
                            }

                            if (sowSku.boxNum) {
                                vm.skuCode = skuCode;
                                vm.sortPickDetails(vm.pickDetails);
                                vm.success();
                                PickOrder.sowSku({}, sowSku, function(resp) {
                                    vm.success();
                                    var box = vm.boxMap[sowSku.boxNum];
                                    box.sowQty = box.sowQty + sowSku.qty;
                                    box[sowSku.skuCode].sowQty = box[sowSku.skuCode].sowQty + sowSku.qty;
                                    vm.pickOrder.remainSkuQty = vm.pickOrder.remainSkuQty - sowSku.qty;

                                    var allDone = true;
                                    for (var i=0; i<vm.pickDetails.length; i++) {
                                        var pd = vm.pickDetails[i];
                                        if (pd.qty !== pd.sowQty) {
                                            allDone = false;
                                            break;
                                        }
                                    }
                                    if (allDone) {
                                        jQuery('#doneBtn').click();
                                    } else {
                                        goSkuBarcode();
                                    }
                                }, function(resp) {
                                    if (resp.data && resp.data.errorMsg) {
                                        if (vm.params.useScanGun) {
                                            openModal('error-confirm.html', {message: resp.data.errorMsg}, 'skuBarcode');
                                        } else {
                                            openModal('error-confirm.html', {message: resp.data.errorMsg}, 'skuBarcodeManual');
                                        }
                                        fail();
                                    }
                                });
                            } else {
                                if (vm.params.useScanGun) {
                                    openModal('error-confirm.html', {message: '商品条码' + skuBarcode + '播种数量已足够'}, 'skuBarcode');
                                } else {
                                    openModal('error-confirm.html', {message: '商品条码' + skuBarcode + '播种数量已足够'}, 'skuBarcodeManual');
                                }
                                fail();
                            }
                        } else {
                            if (vm.params.useScanGun) {
                                openModal('error-confirm.html', {message: '商品条码' + skuBarcode + '不存在拣选单中'}, 'skuBarcode');
                            } else {
                                openModal('error-confirm.html', {message: '商品条码' + skuBarcode + '不存在拣选单中'}, 'skuBarcodeManual');
                            }
                            fail();
                        }
                    }
                }
            }
        }

        function goSkuBarcode() {
            if (vm.params.useScanGun) {
                jQuery('#skuBarcode').select();
            } else {
                jQuery('#skuBarcodeManual').select();
            }
        }
        
        function getCurrentPickOrder() {
            return vm.pickOrder;
        }

        function confirmSowPick(pickerCode, pickNum) {
            if (!pickNum) {
                openModal('error-confirm.html', {message: '拣选单号需要录入'}, 'pickNum');
                return;
            }
            vm.success();
            PickOrder.confirmSowPick({pickerCode: pickerCode, pickNum: pickNum}, {}, function (resp) {
                toastr.success("播种确认成功");
                vm.success();
                reset();
                jQuery('#pickerCode').select();
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'pickNum');

                    fail(resp.data.errorMsg);
                }
            });
        }
        function confirmSowPickAndCloseBox(pickerCode, pickNum) {
            if (!pickNum) {
                openModal('error-confirm.html', {message: '拣选单号需要录入'}, 'pickNum');
                return;
            }
            vm.success();
            PickOrder.confirmSowPickAndCloseBox({pickerCode: pickerCode, pickNum: pickNum}, {}, function (resp) {
                toastr.success("播种确认 & 装箱完成");
                vm.success();
                reset();
                jQuery('#pickerCode').select();
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'pickNum');

                    fail(resp.data.errorMsg);
                }
            });
        }
        function resetSowSku(pickNum) {
            if (!pickNum) {
                jQuery('#pickNum').select();
                return;
            }
            vm.success();
            PickOrder.resetSowSku({pickNum: pickNum}, {}, function (resp) {
                toastr.success("重置成功");
                vm.success();
                inputPickNum({which: 13}, pickNum);
            }, function(resp) {
                if (resp.data && resp.data.errorMsg) {
                    openModal('error-confirm.html', {message: resp.data.errorMsg}, 'pickNum');

                    fail(resp.data.errorMsg);
                }
            });
        }

        function execCmd(cmd) {
            if (cmd) {
                if (cmd === vm.changeOrderCmd) {
                    reset();
                    jQuery('#pickerCode').select();

                    return true;
                } else if (cmd === vm.endSowCmd) {
                    jQuery('#doneBtn').click();

                    return true;
                } else {
                    toastr.error('不支持的命令');
                }
            }

            return false;
        }

        function calcBoxes(rowCount, colCount) {
            vm.rows = [];
            var rows = [];

            if (vm.pickOrder.pickNum) {
                if (rowCount !== undefined) {
                    rowCount = Math.max(rowCount, vm.pickOrder.rows);
                } else {
                    rowCount = vm.pickOrder.rows;
                }

                if (colCount !== undefined) {
                    colCount = Math.max(colCount, vm.pickOrder.columns);
                } else {
                    colCount = vm.pickOrder.columns;
                }

                vm.params.sowRow = rowCount;
                vm.params.sowCol = colCount;
            }

            if (!rowCount) {
                openModal('error-confirm.html', {message: '行数需要录入'}, 'sowRow');
                fail();
                return false;
            }
            if (!colCount) {
                openModal('error-confirm.html', {message: '列数需要录入'}, 'sowCol');
                fail();
                return false;
            }

            for (var i=0; i< rowCount; i++) {
                rows.push({columns: []});
                for (var j=0; j<colCount; j++) {
                    var sowPosition = vm.charArray[i] + '' + (j+1);
                    rows[i]['columns'][j] = {
                        sowPosition: sowPosition,
                        qty: 0,
                        sowQty: 0,
                        style: {},
                        css: '',
                        styleFn: styleFn
                    };

                    if (vm.positionMap[sowPosition]) {
                        rows[i]['columns'][j].box = vm.positionMap[sowPosition];
                    }
                }
            }

            vm.rows = rows;

            $timeout(function() {
                var $sowReion = jQuery('#sowRegion');
                if ($sowReion.length === 1) {
                    var tW = $sowReion.width();
                    tW = tW - colCount * 5 * 2 - 1;
                    var avgW = tW / colCount;
                    if (avgW > vm.boxMaxWidth) {
                        avgW = vm.boxMaxWidth;
                    }
                    angular.forEach(vm.rows, function(row) {
                        angular.forEach(row.columns, function(col) {
                            col.style['width'] = avgW + 'px';
                            col.style['height'] = avgW + 'px';
                            if (avgW === vm.boxMaxWidth) {
                                col.css = 'maxSowBox';
                            } else if (avgW >= 80) {
                                col.css = 'mediumerSowBox';
                            } else if (avgW >= 60) {
                                col.css = 'mediumSowBox';
                            } else {
                                col.css = 'minSowBox';
                            }
                        });
                    });
                }
            });

            return true;

            function styleFn() {
                if (this.box) {
                    if (vm.box && this.box.boxNum === vm.box.boxNum) {
                        this.style = angular.merge(this.style, vm.alertColor.current);
                        if (vm.selectedSowBox && this.box.boxNum == vm.selectedSowBox.box.boxNum) {
                        	this.style = angular.merge(this.style, {'border' : '7px solid #51ee20'});
                        } else {
                        	this.style.border = null;
                        }
                    } else if (this.box.sowQty > 0 && this.box.sowQty === this.box.qty) {
                        this.style = angular.merge(this.style, vm.alertColor.done);
                        if (vm.selectedSowBox && this.box.boxNum == vm.selectedSowBox.box.boxNum) {
                        	this.style = angular.merge(this.style, {'border' : '7px solid #51ee20'});
                        } else {
                        	this.style.border = null;
                        }
                    } else if (this.box.sowQty > 0) {
                        this.style = angular.merge(this.style, vm.alertColor.doing);
                        if (vm.selectedSowBox && this.box.boxNum == vm.selectedSowBox.box.boxNum) {
                        	this.style = angular.merge(this.style, {'border' : '7px solid #51ee20'});
                        } else {
                        	this.style.border = null;
                        }
                    } else {
                        this.style = angular.merge(this.style, vm.alertColor.on);
                        if (vm.selectedSowBox && this.box.boxNum == vm.selectedSowBox.box.boxNum) {
                        	this.style = angular.merge(this.style, {'border' : '7px solid #51ee20'});
                        } else {
                        	this.style.border = null;
                        }
                    }
                } else {
                    this.style = angular.merge(this.style, vm.alertColor.off);
                }

                return this.style;
            }
        }

        function reset() {
            vm.params.pickerCode = '';
            vm.params.pickNum = '';

            resetPickOrder();
            calcBoxes(vm.params.sowRow, vm.params.sowCol);
        }

        function resetPickOrder() {
            vm.pickOrder = {};
            vm.pickDetails = [];
            vm.positionMap = {};
            vm.boxMap = {};
            vm.sku2Boxes = {};
            vm.barcode2SkuCode = {};
            vm.params.skuBarcode = '';

            resetSku();
        }

        function resetSku() {
            vm.box = null;
            vm.skuCode = null;
        }

        // 打开控制弹出框
        function openModal(templateUrl, model, elemId) {
            var paramItem = angular.extend({errorCtrlCmd: vm.errorCtrlCmd}, model);
            var modalInstance = $uibModal.open({
                templateUrl: templateUrl,
                controller: ctrl,
                controllerAs: '$ctrl',
                backdrop: 'static',
                resolve: {
                    item:function(){
                        return paramItem;
                    }
                }
            });

            ctrl.$inject = ['item'];
            function ctrl(item) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.execCmd = execCmd;

                function execCmd($event, cmd) {
                    if ($event.which === 13) {
                        if (cmd === vm.errorCtrlCmd) {
                            modalInstance.close();

                            vm.success();
                            if (elemId) {
                                jQuery('#' + elemId).select();
                            }
                        } else {
                            jQuery('#errorCode').select();
                        }
                    }
                }
            }
        }

        function fail(msg) {
        	_PlayErrorAudio();
            vm.checkStatus = {status: 'ERROR', message: '错误'};
        }

        function success() {
            vm.checkStatus = {status: 'SUCCESS', message: '扫描成功'};
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }
        
        vm.success();
    }
})();
