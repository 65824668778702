(function() {
    'use strict';

    angular.module('doveModule')
        .controller('iBusProcessConfigController', iBusProcessConfigController);

    iBusProcessConfigController.$inject = ['iBusProcess', 'NgTableParams', '$uibModal', 'iBusProcessRunning', 'iBusProject','$q','$timeout'];

    function iBusProcessConfigController(iBusProcess, NgTableParams, $uibModal, iBusProcessRunning, iBusProject, $q,$timeout) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.projectMap = {};
        vm.projects = [];
        vm.runStatus = [{'id':null, 'title':''}, {'id':1, 'title':'运行'}, {'id':0, 'title':'未运行'}];
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'新建'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.repeatFlags = [{'id':1, 'title':'可重复执行'},{'id':0, 'title':'不可重复执行'}];
        vm.search = search;
        vm.edit = edit;
        vm.update = update;
        vm.deleteProcess = deleteProcess;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.stopAll = stopAll;
        vm.stop = stop;
        vm.searchProjects = searchProjects;
        vm.stopSelect = stopSelect;
        vm.active = 0;
        vm.logLevel = [
            {'id': "DEBUG", 'title': "DEBUG"},
            {'id': "INFO", 'title': "INFO"},
            {'id': "WARN", 'title': "WARN"},
            {'id': "ERROR", 'title': "ERROR"}];
        vm.tableParams = new NgTableParams({count: 25}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusProcess.search({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });

        function stopSelect() {
            var selected = vm.tableParams.getSelected();
            var codes = jQuery.map(selected, function(item) {
                return item.code;
            });

            iBusProcessRunning.cancelSelect({}, codes, function(resp) {
                vm.search();
                alert("共 "+resp.content.total+" 个流程，成功停止 "+resp.content.stop+" 个流程！\n正在运行中流程是不能被停止；如有未停止完成的流程，请重试");
            });
        }

        function searchProjects() {
            var deferred = $q.defer();

            var postParams = {pn: 1, ps: 999};
            iBusProject.search({}, postParams, function(resp) {
                var projects = jQuery.map(resp.content.datas, function(project) {
                    return {'id': project.code, 'title': project.name};
                });

                angular.forEach(projects, function(project) {
                    vm.projectMap[project.id] = project.title;
                });

                vm.projects = projects;

                deferred.resolve(vm.projects);
            });

            return deferred;
        }

        function search() {
            vm.tableParams.reload();
        }

        function deleteProcess(row){
            iBusProcess.delete({id: row.id}, {}, function() {
                vm.search();
            });
        }

        function edit(row) {
            var entity = {
                heading: row ? '编辑' : '创建',
                contentId: "process-eidt.html",
                data: row || {configData: '<?xml version="1.0" encoding="UTF-8"?>\n<process code="" name="">\n</process>'},
                roles: [],
                active: true
            };

            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
            }, 0);
        }

        function update(tab, idx, form) {
            var data = tab.data;
            form.passwordUnmatch  = "";

            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }

            if (!data.id) {
                iBusProcess.add({}, data, function() {
                    vm.removeTab(idx);
                });
            } else {
                iBusProcess.update({}, data, function() {
                    vm.removeTab(idx);
                });
            }
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            var modalInstance = $uibModal.open({
                templateUrl: 'deleteModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            modalInstance.result.then(function(item) {
                vm.deleteProcess(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        function stopAll() {
        	iBusProcessRunning.cancelAll({}, {}, function(resp) {
                vm.search();
                alert("共 "+resp.content.total+" 个流程，成功停止 "+resp.content.stop+" 个流程！\n正在运行中流程是不能被停止；如有未停止完成的流程，请重试");
            });
        }

        function stop(code) {
        	iBusProcessRunning.cancel({}, {"code":code}, function(resp) {
                vm.search();
            });
        }
    }
})();
