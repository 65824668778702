(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('PickDetail', PickDetail);

    PickDetail.$inject = ['$resource', 'iServiceConfig'];

    function PickDetail ($resource, iServiceConfig) {
        var service = $resource('web/pickDetail', {}, {
            'list': {url: iServiceConfig.iwhShipHost + "/web/pickDetail/list", method: 'POST'},
            'listCancelPickDetails': {url: iServiceConfig.iwhShipHost + "/web/pickDetail/listCancelPickDetails", method: 'POST'},
            'cancelPick': {url: iServiceConfig.iwhShipHost + "/web/pickDetail/cancelPick", method: 'POST'}
        });

        return service;
    }
})();