(function()  {
    'use strict';

    angular.module('itms-dataModule')
        .controller('TmsTransportLineStationController', TmsTransportLineStationController);

    TmsTransportLineStationController.$inject = ['$rootScope', 'TmsTransportLineStation', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout'];

    function TmsTransportLineStationController ($rootScope,  TransportLineStation, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.status = [
                       	{id : null, title : "不限"},
                        {id : 0, title : "新建"},
                        {id : 10, title : "启用"},
                        {id : -10, title : "禁用"},
                    ];

        vm.yesOrNo = [
            {id : 0, title : "否"},
            {id : 1, title : "是"},
        ];

        vm.priority = [
            {id : 1, title : "高"},
            {id : 2, title : "中"},
            {id : 3, title : "低"}
        ];


        function pageTitle() {
            return $window.document.title;
        }

      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
                if($state.params.carrierCode!=null){
                    params.filter().carrierCode = $state.params.carrierCode;
                    $state.params.carrierCode=null;
                }
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return TransportLineStation.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

        vm.search = search;
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.validate = validate;
        function validate(code,carrierCode){
            if(code==null ||code==""){
                return
            }else{
            	var vo = {};
            	vo.code = code;
            	vo.carrierCode = carrierCode;
                TransportLineStation.validateCode({}, vo, function(resp) {
                    if(resp.content=="success"){

                    }
                });
            }
        }

        vm.defaultChoose = null;
		vm.createTransportLineStation = createTransportLineStation;
		function createTransportLineStation() {
            TransportLineStation.getCarrierCodes({}, {}, function(resp) {
              var  carrierList =resp.content;
                var carrierCode = "";
                if($state.params.carrierCode!=null) {
                   carrierCode = $state.params.carrierCode;
                }
                var entity = {
                    heading : '创建',
                    contentId : "createTransportLineStation.html",
                    active : true,
                    data: {
                        carrierBeanList : carrierList,
                        carrierCode : carrierCode,
                        status : 0,
                    },
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });

		}

		vm.update = update;
		function update(t, index,form){
			 var valid = form.$valid;
			 var saveVO = t.data;
			 if(t.data.id){
				 TransportLineStation.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("更新成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}else{
                 TransportLineStation.save({}, saveVO, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("保存成功");
						 vm.removeTab(index);
						 search();
					 }
				 });
			}
		}

		vm.show = show;
		function show(row) {
            TransportLineStation.show({}, row.id, function(resp) {
				var TransportLineStation = resp.content;
				var entity = {
						heading : '查看',
						contentId : "showTransportLineStation.html",
						active : true,
						data : TransportLineStation,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

		vm.edite = edite;
		function edite(row) {
            TransportLineStation.show({}, row.id, function(resp) {
				var TransportLineStation = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "createTransportLineStation.html",
						active : true,
						data : TransportLineStation,
                    	carrierCode : TransportLineStation.carrierCode,
                    	carrierSelection : function carrierSelection(obj){
                        entity.carrierCode = obj.code;
                    },
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}



		vm.confirmDelete = confirmDelete;
		function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : row
                }
            });
            vm.modalInstance.result.then(function(item) {
                TransportLineStation.delete({}, item.id, function(resp) {
					 if(resp.content=="success"){
						 toastr.success("删除成功");
						 search();
					 }
				 });
            });
        }

		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }

		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
