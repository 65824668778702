(function () {
    'use strict';
    var app = angular.module('iwh-receiptModule');
    app.config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('newGoods', {
            parent: 'main',
            url: 'iwh-receipt/newGoods',
            params: {
                'ownerCode': null,
                'postParams': null
            },
            data: {
                'pageTitle': '新品维护',
                'ownerCode' : null,
                'postParams': null,
            },
            views: {
                'mainContent': {
                    templateUrl: 'iwh-receipt-module/newGoods/newGoods.html',
                    controller: 'NewGoodsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {}
        });
    }]);
})();

