(function(ng) {
    'use strict';

    ng.module('scInput', []);

    ng.module('scInput').directive('scInput', scInput);
    scInput.$inject = [];
    function scInput() {
        return {
            restrict: 'A',
            terminal: true,
            priority: 1001,
            compile: function(ele) {
                var $ele = jQuery(ele);

                var $form = $ele.parents('form');
                var formName = undefined;
                if ($form.length > 0) {
                    // if ($form.attr('name') === undefined) {
                    //     formName = 'form-' + (new Date()).getTime() + Math.random().toString(16).slice(10);
                    //     console.log(formName);
                    //     $form.attr('name', formName);
                    // } else {
                        formName = $form.attr('name');
                    // }
                }

                var validator = $ele.attr('validator');
                if (validator) {
                    var nameAttr = $ele.attr('name');
                    // if (nameAttr === undefined) {
                    //     nameAttr = 'input-' + (new Date()).getTime() + Math.random().toString(16).slice(10);
                    //     $ele.attr('name', nameAttr);
                    // }

                    var split = validator.split(',');
                    if (split.length > 0) {
                        var $div = jQuery('<div class="valid-div" ng-show="' + formName + '.$submitted || ' + formName + '.' + nameAttr + '.$touched"></div>').insertAfter($ele);
                        for (var i = 0; i < split.length; i++) {
                            var rule = split[i].trim();
                            $ele.attr(rule, "");

                            // if (rule === 'required') {
                            //     var displayRule = formName + "." + nameAttr + ".$error.required";
                            //     jQuery('<span class="valid-span" ng-show="' + displayRule + '"><p>必填项</p></span>').appendTo($div);
                            // } else if (rule === 'pos-integer') {
                            //     var displayRule = formName + "." + nameAttr + ".$error.posInteger";
                            //     jQuery('<span class="valid-span" ng-show="' + displayRule + '"><p>需为正整数</p></span>').appendTo($div);
                            // }
                        }
                    }

                    // $ele.attr('ng-model-options', "{updateOn: 'blur'}");
                }

                return function(scope, element, attrs, controller) {
                    controller.compileDirectiveTemplates();
                };
            },
            controller: ['$scope', '$compile', '$element', '$parse', function($scope, $compile, $element, $parse) {
                this.compileDirectiveTemplates = function() {
                    if($element.attr('sc-input') !== undefined){
                        $element.removeAttr('sc-input');

                        $compile(jQuery($element).siblings('div.valid-div'))($scope);

                        $compile($element)($scope);
                    }
                };
            }]
        };
    }

    var INTEGER_REGEXP = /^\d+$/;//非负整数验证
    ng.module('scInput').directive('posInteger', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.posInteger = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        elm[0].setCustomValidity("");
                        return true;
                    }

                    if(INTEGER_REGEXP.test(viewValue)){
                        elm[0].setCustomValidity("");
                        return true;
                    }else{
                        elm[0].setCustomValidity("请输入非负整数");
                        return false;
                    }
                };
            }
        };
    });

    var PLUS_REGEXP = /^\d+(\.\d)?/;//正数验证
    ng.module('scInput').directive('posPlus', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.posPlus = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        elm[0].setCustomValidity("");
                        return true;
                    }

                    if(PLUS_REGEXP.test(viewValue)){
                        elm[0].setCustomValidity("");
                        return true;
                    }else{
                        elm[0].setCustomValidity("请输入正数");
                        return false;
                    }
                };
            }
        };
    });

    var NONNEGATIVE_REGEXP = /^[1-9]\d*$/;//正整数
    ng.module('scInput').directive('negativePlus', function() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                ctrl.$validators.posPlus = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(modelValue)) {
                        elm[0].setCustomValidity("");
                        return true;
                    }

                    if(NONNEGATIVE_REGEXP.test(viewValue)){
                        elm[0].setCustomValidity("");
                        return true;
                    }else{
                        elm[0].setCustomValidity("请输入正整数");
                        return false;
                    }
                };
            }
        };
    });

}(angular));