(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhCode', Code);

    Code.$inject = ['$resource', 'iServiceConfig'];

    function Code ($resource, iServiceConfig) {
        var service = $resource('web/code', {}, {
            'deleteByIds': { url: iServiceConfig.iwhDataHost + "/web/code/deletebyids", method: 'POST'},
            'detail': {url: iServiceConfig.iwhDataHost + "/web/code/save", method: 'POST'},
            'save': {url: iServiceConfig.iwhDataHost + "/web/code/save", method: 'POST'},
            'checkCode': {url: iServiceConfig.iwhDataHost + "/web/code/checkcode", method: 'POST'},
            'disable': {url: iServiceConfig.iwhDataHost + "/web/code/disable", method: 'POST'},
            'enable': {url: iServiceConfig.iwhDataHost + "/web/code/enable", method: 'POST'},
        });

        return service;
    }
})();
