(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('pko-rule', {
            parent: 'main',
            // params:{
            //     ruleCode:null
            // },
            url: 'iwh-strategy/pko-rule?ruleCode',
            data: {'pageTitle': '拣选单策略'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/pkoRule/pkoRule.html',
                    controller: 'PkoRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('PkoRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/pko-rule', {}, {
            'list': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/list', method: 'POST'},
            'add': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/add', method: 'POST'},
            'addDetail': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/add', method: 'POST'},
            'addGroupby': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/groupby/add', method: 'POST'},
            'addSortby': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/sortby/add', method: 'POST'},
            'addFilter': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/filter/add', method: 'POST'},
            'update': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/update', method: 'POST'},
            'updateStatus': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/updateStatus', method: 'POST'},
            'updateDetail': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/update', method: 'POST'},
            'updateGroupby': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/groupby/update', method: 'POST'},
            'updateSortby': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/sortby/update', method: 'POST'},
            'updateFilter': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/filter/update', method: 'POST'},
            'deleteDetail': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/delete', method: 'POST'},
            'deleteGroupby': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/groupby/delete', method: 'POST'},
            'deleteSortby': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/sortby/delete', method: 'POST'},
            'deleteFilter': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail/filter/delete', method: 'POST'},
            'detail': {url: iServiceConfig.iwhStrategyHost + '/web/pko-rule/detail', method: 'POST'}
        });
    }]);

    app.controller('PkoRuleController', [
        '$scope', 'PkoRule', 'NgTableParams', '$timeout', 'toastr','enums','$uibModal','$stateParams','$state',
        function ($scope, PkoRule, NgTableParams, $timeout, toastr,enums,$uibModal,$stateParams,$state) {

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;
            $scope.spareData = {};//备份当前被编辑的规则，作为中间数据，当子规则等被修改但又取消时  应恢复原数据

            $scope.pkoStatus = enums('strategy.PKOStatus');//状态
            $scope.status = $scope.pkoStatus.list('title','id');//状态
            $scope.pickOrderType = enums('ship.PickType').list();//拣选单类型
            $scope.pickMethod = enums('ship.PickMethod').list();//拣选方式
            $scope.pkoGroupFieldEnums = enums('strategy.PKOGroupField');//分组字段
            $scope.pkoGroupField = enums('strategy.PKOGroupField').list();//分组字段
            $scope.pkoSortFieldEnums = enums('strategy.PKOSortField');//排序字段
            $scope.pkoSortField = enums('strategy.PKOSortField').list();//排序字段
            $scope.sortTypeEnums = enums('strategy.SortType');//排序方式
            $scope.sortType = enums('strategy.SortType').list();//排序方式
            $scope.pkoFilterFieldEnums = enums('strategy.PKOFilterField');//筛选字段
            $scope.pkoFilterField = enums('strategy.PKOFilterField').list();//筛选字段
            $scope.operatorEnums = enums('common.Operator');//比较类型
            $scope.operator = enums('common.Operator').list();//比较类型
            $scope.autoReleasePickOption = [{value:0, label: '否'},{value:1, label: '是'}]

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };// (保留)

            // 防止两次请求，将tableParams放到search方法中(保留)
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return PkoRule.list({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            //配置备用数据
            $scope.configData = function (tab,res) {
                tab.data = res.content;
                if(!res.content.details){
                    tab.data.details = [];
                }

                var data = tab.data.details;
                if(tab.data.details.length==0){
                    $scope.spareData = angular.copy(tab.data);
                    return;
                }
                for(var i=0;i<data.length;i++){
                    data[i]['nameShow'] = data[i]['name'];
                    data[i]['sortOrderShow'] = data[i]['sortOrder'];
                    if(data[i].groups){
                        for(var j=0;j<data[i].groups.length;j++){
                            data[i].groups[j]['sortOrderShow'] = data[i].groups[j]['sortOrder'];
                            data[i].groups[j]['groupByFieldShow'] = data[i].groups[j]['groupByField'];
                        }
                    }else{
                        data[i].groups = [];
                    }

                    if(data[i].sorts){
                        for(var k=0;k<data[i].sorts.length;k++){
                            data[i].sorts[k]['sortOrderShow'] = data[i].sorts[k]['sortOrder'];
                            data[i].sorts[k]['sortByFieldShow'] = data[i].sorts[k]['sortByField'];
                            data[i].sorts[k]['sortTypeShow'] = data[i].sorts[k]['sortType'];
                        }
                    }else{
                        data[i].sorts = [];
                    }

                    if(data[i].filters){
                        for(var q=0;q<data[i].filters.length;q++){
                            data[i].filters[q]['fieldNameShow'] = data[i].filters[q]['fieldName'];
                            data[i].filters[q]['operatorShow'] = data[i].filters[q]['operator'];
                            data[i].filters[q]['valueShow'] = data[i].filters[q]['value'];
                        }
                    }else{
                        data[i].filters = [];
                    }

                    data[i]['spare_groups'] = angular.copy(data[i]['groups']);
                    data[i]['spare_sorts'] = angular.copy(data[i]['sorts']);
                    data[i]['spare_filters'] = angular.copy(data[i]['filters']);
                }

                $scope.spareData = angular.copy(tab.data);//配置备用数据 直接copy   tab.data
            };

            //子规则切换拣选方式，重置行列
            $scope.changeMethod = function (row) {
                row.sowRow = null;
                row.sowColumn = null;
            };

            //更新状态
            $scope.updateStatus = function (row) {
                var status = row.status === 10?0:10;
                var msg = row.status === 10?'禁用':'启用';
                PkoRule.updateStatus({},{
                    code:row.code,
                    status:status
                },function (res) {
                    toastr.success(msg+'成功','成功');
                    $scope.search();
                });
            };

            //获取当前需要编辑的规则信息----tab.data
            $scope.getChildRule = function (row) {
                return new Promise(function (resolve, reject) {
                    PkoRule.detail({},{
                        ruleCode:row.code
                    },function (res) {
                        resolve(res);
                    },function (res) {
                        reject(res);
                    });
                });
            };

            //点击列表中编辑图标时  为编辑状态  故不会设置tab中show:true,删除、新增、编辑按钮会出现
            $scope.lotRuleContent = function (row,isNew) {
                var tab = {
                    heading: row.code ? row.name : '新建',
                    contentId: "lotRuleDetail.tpl",
                    active: true
                };

                if (isNew) {
                    row = {
                        code:null,
                        status: 10,
                        name:null,
                        remark:null
                    };
                    tab.isNew = true;
                    tab.data = row;
                    $scope.tabs.push(tab);
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);

                }else{
                    for (var i = 0; i < $scope.tabs.length; i++) {
                        if ($scope.tabs[i].data.code === row.code) {
                            return $scope.active = i + 1;
                        }
                    }
                    $scope.tabs.push(tab);

                    $scope.getChildRule(row).then(function (res) {
                        if(res.content.name && !tab.heading){
                            $timeout(function () {
                                tab.heading = res.content.name;
                            });
                        }
                        //配置一些数据，将绑定输入框的数据与展示数据分开
                        $scope.configData(tab,res);
                        $scope.active = $scope.tabs.length;
                    }).catch(function (res) {
                        $scope.tabs.pop();
                    });

                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            //如果url有参数ruleCode  直接进入编辑页面
            if($stateParams.ruleCode){
                $scope.lotRuleContent({
                    code:$stateParams.ruleCode
                });
            }

            //点击列表中a标签时  仅为查看  故设置tab中show:true,控制删除、新增、编辑按钮不出现
            $scope.lotRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {lotRuleDetails: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '查看',
                    contentId: "lotRuleDetail.tpl",
                    active: true,
                    show : true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    $scope.getChildRule(row).then(function (res) {
                        if(res.content.name && !tab.heading){
                            $timeout(function () {
                                tab.heading = res.content.name;
                            });
                        }
                        //配置一些数据，将绑定输入框的数据与展示数据分开
                        $scope.configData(tab,res);
                        $scope.active = $scope.tabs.length;
                    }).catch(function (res) {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            //保存新增策略
            $scope.saveRule = function(tab){
                $('#saveRule').blur();
                var obj = {
                    code:tab.data.code,
                    status: tab.data.status,
                    name:tab.data.name,
                    remark:tab.data.remark
                };

                //新增策略
                if(tab.isNew){
                    //    新增
                    PkoRule.add({},obj,function (res) {
                        toastr.success('新增策略成功','成功');
                        tab.isNew = false;

                        var row = {code:tab.data.code};
                        $scope.getChildRule(row).then(function (res) {
                            if(res.content.name && !tab.heading){
                                $timeout(function () {
                                    tab.heading = res.content.name;
                                });
                            }
                            //配置一些数据，将绑定输入框的数据与展示数据分开
                            $scope.configData(tab,res);
                        }).catch(function (res) {
                        });
                    });
                    return;
                }

                //编辑策略
                if(tab.currentDetail){
                    if(tab.currentDetail.id && tab.currentDetail.id === -11111){
                        $scope.saveDetail(tab, tab.data.details[0]).then(function (res) {
                            PkoRule.update({},obj,function (res) {
                                toastr.success('更新策略成功','成功');
                            });
                        }).catch(function (res) {
                            toastr.error(res.data.errorMsg,'失败');
                        });
                    }else if(tab.currentDetail.id){
                        //编辑
                        for(var i=0;i<tab.data.details.length;i++){
                            if(tab.currentDetail.id === tab.data.details[i].id){
                                $scope.saveDetail(tab, tab.data.details[i]).then(function (res) {
                                    PkoRule.update({},obj,function (res) {
                                        toastr.success('更新策略成功','成功');
                                    });
                                }).catch(function (res) {
                                    toastr.error(res.data.errorMsg,'失败');
                                });
                                break;
                            }
                        }
                    }
                }else{
                    PkoRule.update({},obj,function (res) {
                        toastr.success('更新策略成功','成功');
                    });
                }
            };

            //新增或编辑或查看--子规则------first
            $scope.lotRuleDetail = function (tab, detail,isNew) {
                if(isNew){
                    //isEdit属性控制每一条子规则上  编辑、删除按钮的展示
                    for(var p=0;p<tab.data.details.length;p++){
                        tab.data.details[p].isEdit = false;
                    }
                    if(tab.data.details[0] && tab.data.details[0].id === -11111){
                        toastr.error('当前还有新增规则未完成');
                        tab.currentDetail = tab.data.details[0];
                        return;
                    }
                    var obj = {
                        ruleCode: tab.data.code,
                        subRuleCode: null,
                        id:-11111,
                        signId:-10002,
                        name: null,
                        sortOrder: null,
                        remark: null,
                        pickOrderType: null,
                        pickMethod: null,
                        sowRow:null,
                        sowColumn: null,
                        groups: [],
                        sorts: [],
                        filters: [],
                        current_groups:null,
                        current_sorts:null,
                        current_filters:null,
                        canWrite:true,//控制编码是否可改
                        isEdit:true,//判断当前是否处于编辑状态
                        isNew:true,//判断是否是新增或是编辑
                        currentField:'groups',
                        active:0//新增时 当前row.active为0，控制uib tab页的显示
                    };
                    $timeout(function () {
                        tab.currentDetail = obj;
                        tab.data.details.splice(0,0,obj);
                    });
                }else{
                    //两者相同 说明当前选中的子规则没变化  不需要继续执行--主要针对查看情况
                    if(tab.currentDetail && detail && tab.currentDetail.subRuleCode == detail.subRuleCode && tab.currentDetail.id == detail.id && tab.currentDetail.ruleCode == detail.ruleCode){
                        return;
                    }

                    //点击其它子规则时----将原来子规则数据复原
                    if(tab.currentDetail){
                        for(var r=0;r<$scope.spareData.details.length;r++){
                            //如果在备用数据中找到相同subRuleCode的子规则  替换原数据
                            if(tab.currentDetail.subRuleCode == $scope.spareData.details[r].subRuleCode && tab.currentDetail.id == $scope.spareData.details[r].id){
                                tab.data.details[r] = angular.copy($scope.spareData.details[r]);
                                tab.data.details[r].isEdit = false;
                                break;
                            }
                        }
                    }

                    //重置
                    if(tab.data.details && tab.data.details.length>0){
                        for(var i=0;i<tab.data.details.length;i++){
                            tab.data.details[i]['current_groups'] = null;
                            tab.data.details[i]['current_sorts'] = null;
                            tab.data.details[i]['current_filters'] = null;
                        }
                    }

                    //如果当前有新增未保存则直接删除
                    if(tab.currentDetail && tab.currentDetail.signId == -10002 && tab.currentDetail.id == -11111){
                        //如果第一条子规则signId为-10002   点击其它子规则时将其删除
                        tab.data.details.splice(0,1);
                    }

                    //重置当前点击数据
                    if(detail && detail.subRuleCode) {
                        detail.isEdit = true;
                        tab.currentDetail = detail;
                    }

                }
            };

            //保存子规则------first
            $scope.saveDetail = function (tab, detail) {
                return new Promise(function (resolve, reject) {
                    var form = $('#'+detail.id).attr('form_valid');
                    var verifyThrough = (form=='true')?true:false;
                    //判断当前表单是否验证通过
                    if(!verifyThrough){
                        var btn = '[form='+detail.id+']';
                        $(btn).click();
                        reject({data:{errorMsg:'当前子规则表单验证不合法'}});
                        return;
                    }

                    //新增保存-----如果当前是新增保存  必然没有分组等规则  所以直接进入保存
                    if(detail.isNew){
                        $scope.saveDetailWay(tab,detail).then(function (res) {
                            resolve(res);
                        }).catch(function (res) {
                            reject(res);
                        });
                        return;
                    }

                    //编辑保存-----
                    //如果当前有展开的分组依据----新增/编辑
                    if(detail.current_groups){
                        //新增
                        if(detail.current_groups.id && detail.current_groups.id===-10000){
                            $scope.saveGroups(detail, detail.groups[0], 'groups').then(function (res) {
                                $scope.saveDetailWay(tab,detail).then(function (res) {
                                    resolve(res);
                                }).catch(function (res) {
                                    reject(res);
                                });
                            }).catch(function (res) {
                                toastr.error(res.data.errorMsg,'失败');
                            });
                        }else if(detail.current_groups.id){
                            //编辑
                            for(var i=0;i<detail.groups.length;i++){
                                if(detail.current_groups.id === detail.groups[i].id){
                                    $scope.saveGroups(detail, detail.groups[i], 'groups').then(function (res) {
                                        $scope.saveDetailWay(tab,detail).then(function (res) {
                                            resolve(res);
                                        }).catch(function (res) {
                                            reject(res);
                                        });
                                    }).catch(function (res) {
                                        toastr.error(res.data.errorMsg,'失败');
                                    });
                                    break;
                                }
                            }
                        }
                        return;
                    }else if(detail.current_sorts){
                        //新增
                        if(detail.current_sorts.id && detail.current_sorts.id===-10000){
                            $scope.saveSorts(detail, detail.sorts[0], 'sorts').then(function (res) {
                                $scope.saveDetailWay(tab,detail).then(function (res) {
                                    resolve(res);
                                }).catch(function (res) {
                                    reject(res);
                                });
                            }).catch(function (res) {
                                toastr.error(res.data.errorMsg,'失败');
                            });
                        }else if(detail.current_sorts.id){
                            //编辑
                            for(var i=0;i<detail.sorts.length;i++){
                                if(detail.current_sorts.id === detail.sorts[i].id){
                                    $scope.saveSorts(detail, detail.sorts[i], 'sorts').then(function (res) {
                                        $scope.saveDetailWay(tab,detail).then(function (res) {
                                            resolve(res);
                                        }).catch(function (res) {
                                            reject(res);
                                        });
                                    }).catch(function (res) {
                                        toastr.error(res.data.errorMsg,'失败');
                                    });
                                    break;
                                }
                            }
                        }
                        return;
                    }else if(detail.current_filters){
                        //新增
                        if(detail.current_filters.id && detail.current_filters.id===-10000){
                            $scope.saveFilters(detail, detail.filters[0], 'filters').then(function (res) {
                                $scope.saveDetailWay(tab,detail).then(function (res) {
                                    resolve(res);
                                }).catch(function (res) {
                                    reject(res);
                                });
                            }).catch(function (res) {
                                toastr.error(res.data.errorMsg,'失败');
                            });
                        }else if(detail.current_filters.id){
                            //编辑
                            for(var i=0;i<detail.filters.length;i++){
                                if(detail.current_filters.id === detail.filters[i].id){
                                    $scope.saveFilters(detail, detail.filters[i], 'filters').then(function (res) {
                                        $scope.saveDetailWay(tab,detail).then(function (res) {
                                            resolve(res);
                                        }).catch(function (res) {
                                            reject(res);
                                        });
                                    }).catch(function (res) {
                                        toastr.error(res.data.errorMsg,'失败');
                                    });
                                    break;
                                }
                            }
                        }
                        return;
                    }

                    $scope.saveDetailWay(tab,detail).then(function (res) {
                        resolve(res);
                    }).catch(function (res) {
                        reject(res);
                    });

                });
            };

            //保存子规则---方法------first
            $scope.saveDetailWay = function (tab, detail) {
                return new Promise(function (resolve, reject) {
                    var obj = {
                        "ruleCode": detail.ruleCode,
                        "subRuleCode": detail.subRuleCode,
                        "name": detail.name,
                        "sortOrder": detail.sortOrder,
                        "remark": detail.remark,
                        "pickOrderType": detail.pickOrderType,
                        "pickMethod": detail.pickMethod,
                        "autoReleasePick": detail.autoReleasePick,
                        "sowRow": detail.sowRow,
                        "sowColumn": detail.sowColumn,
                        "pkoVolumnCap": detail.pkoVolumnCap,
                        "pkoVolumnLimit": detail.pkoVolumnLimit,
                        "pkoWeightCap": detail.pkoWeightCap,
                        "pkoWeightLimit": detail.pkoWeightLimit,
                        "pkoCaseCountCap": detail.pkoCaseCountCap,
                        "pkoCaseCountLimit": detail.pkoCaseCountLimit,
                        "pkoTaskCountCap": detail.pkoTaskCountCap,
                        "pkoTaskCountLimit": detail.pkoTaskCountLimit,
                        "caseQtyCap": detail.caseQtyCap,
                        "caseQtyLimit": detail.caseQtyLimit,
                        "caseLineCountCap": detail.caseLineCountCap,
                        "caseLineCountLimit": detail.caseLineCountLimit,
                        "caseSkuCountCap": detail.caseSkuCountCap,
                        "caseSkuCountLimit": detail.caseSkuCountLimit,
                        "caseZoneCountCap": detail.caseZoneCountCap,
                        "caseZoneCountLimit": detail.caseZoneCountLimit,
                        "caseVolumnCap": detail.caseVolumnCap,
                        "caseVolumnLimit": detail.caseVolumnLimit,
                        "caseWeightCap": detail.caseWeightCap,
                        "caseWeightLimit": detail.caseWeightLimit,
                        "orderTypeIn": detail.orderTypeIn,
                        "orderTypeEx": detail.orderTypeEx,
                        "orderPriorityCap": detail.orderPriorityCap,
                        "orderPriorityLimit": detail.orderPriorityLimit
                    };
                    //新增---
                    if(detail.isNew){
                        PkoRule.addDetail({},obj,function (res) {
                            toastr.success('新增子规则成功','成功');
                            detail.id = res.content.id;
                            //如果成功
                            detail.signId = null;
                            detail.isNew = false;
                            detail.isEdit = false;
                            detail.canWrite = false;
                            //配置数据
                            detail['nameShow'] = detail['name'];
                            detail['sortOrderShow'] = detail['sortOrder'];
                            detail['groups'] = [];
                            detail['sorts'] = [];
                            detail['filters'] = [];
                            detail['spare_groups'] = [];
                            detail['spare_sorts'] = [];
                            detail['spare_filters'] = [];
                            $scope.spareData.details.splice(0,0,angular.copy(detail));
                            tab.currentDetail = null;
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }else{
                        //   更新
                        PkoRule.updateDetail({},obj,function (res) {
                            toastr.success('子规则更新成功','成功');
                            tab.currentDetail = null;
                            detail.isEdit = false;
                            //配置数据
                            detail['nameShow'] = detail['name'];
                            detail['sortOrderShow'] = detail['sortOrder'];

                            for(var t=0;t<$scope.spareData.details.length;t++){
                                if(detail.subRuleCode === $scope.spareData.details[t].subRuleCode && detail.id === $scope.spareData.details[t].id){
                                    $scope.spareData.details[t] = angular.copy(detail);
                                    break;
                                }
                            }
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }
                });
            };

            //删除子规则--------first
            $scope.deleteDetail = function (tab, row) {
                if(row){
                    var msg = '是否确认删除当前子规则！';
                    openModal(msg,tab,row,$scope.deleteDetailWay)
                }
            };

            //删除子规则---方法--------first
            $scope.deleteDetailWay = function (tab, row) {
                var obj = {
                    ruleCode:row.ruleCode,
                    subRuleCode:row.subRuleCode
                };
                PkoRule.deleteDetail({},obj,function (res) {
                    toastr.success('已成功删除该子规则','成功');
                    tab.currentDetail = null;
                    var h;
                    for(h=0;h<tab.data.details.length;h++){
                        if(row.subRuleCode === tab.data.details[h].subRuleCode && row.id === tab.data.details[h].id){
                            tab.data.details.splice(h,1);
                            break;
                        }
                    }
                    for(h=0;h<$scope.spareData.details.length;h++){
                        if(row.subRuleCode === $scope.spareData.details[h].subRuleCode && row.id === tab.data.details[h].id){
                            $scope.spareData.details.splice(h,1);
                            break;
                        }
                    }
                });
            };

            //分组依据、排序、过滤查看或编辑------second
            $scope.pkoDetail = function (row, detail, field) {
                var currentField = 'current_'+field;//当前被点击的分组依据或排序、或过滤条件
                var spareField = 'spare_'+field;//分组依据或排序、或过滤条件的备用数据
                //两者相同 说明当前选中的子规则没变化  不需要继续执行--主要针对查看情况
                if(row[currentField] && detail && row[currentField].id == detail.id){
                    return;
                }

                //点击其它依据、排序方式或过滤条件时----将上一条数据复原
                if(row[currentField]){
                    for(var r=0;r<row[spareField].length;r++){
                        if(row[currentField].id == row[spareField][r].id){
                            row[field][r] = angular.copy(row[spareField][r]);
                            break;
                        }
                    }
                }

                //如果当前有新增未保存  则直接进入这里执行保存操作
                if(row[currentField] && row[currentField].id == -10000){
                    //如果第一条规则signId为-10002   点击其它子规则时将其删除
                    row[field].splice(0,1);
                }

                if(row && detail && currentField){
                    row[currentField] = detail;
                }

            };

            //分组依据、排序、过滤新增------second
            $scope.pkoDetailAdd = function (row,remark,field) {
                var currentField = 'current_'+field;
                if(row[field][0] && row[field][0].id == -10000){
                    toastr.error('当前还有新增'+remark+'未完成');
                    row[currentField] = row[field][0];
                    return;
                }
                var obj = {
                    ruleCode:row.ruleCode,
                    subRuleCode:row.subRuleCode,
                    id:-10000
                };
                if(field == 'groups'){
                    obj['sortOrder'] = null;
                    obj['groupByField'] = null;
                }else if(field == 'sorts'){
                    obj['sortOrder'] = null;
                    obj['sortByField'] = null;
                    obj['sortType'] = null;
                }else if(field == 'filters'){
                    obj['fieldName'] = null;
                    obj['operator'] = null;
                    obj['value'] = null;
                }
                obj.isNew = true;
                row[field].splice(0,0,obj);
                row[currentField] = obj;
            };

            //保存分组、排序、过滤规则------second
            $scope.saveGsfDetail = function (row, detail, field) {
                if(field == 'groups'){
                    $scope.saveGroups(row, detail, field);
                }else if(field == 'sorts'){
                    $scope.saveSorts(row, detail, field);
                }else if(field == 'filters'){
                    $scope.saveFilters(row, detail, field);
                }
            };

            //保存分组依据---方法(新增+更新)------second
            $scope.saveGroups = function (row, detail, field) {
                return new Promise(function (resolve, reject) {
                    var form = $('#groups_'+detail.id).attr('form_valid');
                    var verifyThrough = (form=='true')?true:false;
                    //判断当前表单是否验证通过
                    if(!verifyThrough){
                        var btn = '[form=groups_'+detail.id+']';
                        $(btn).click();
                        reject({data:{errorMsg:'当前分组依据表单验证不合法'}});
                        return;
                    }

                    var currentField ='current_'+field;
                    var fieldArr = ['sortOrder','groupByField'];
                    if(detail.isNew){
                        PkoRule.addGroupby({},{
                            ruleCode:detail.ruleCode,
                            subRuleCode:detail.subRuleCode,
                            sortOrder:detail.sortOrder,
                            groupByField:detail.groupByField
                        },function (res) {
                            toastr.success('新增分组依据成功','成功');
                            detail.isNew = false;//新增成功之后才能设为false
                            // row[currentField] = res.content;
                            row.current_groups = null;
                            $scope.configGsfData(row, detail, field,res,fieldArr,true);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }else{
                        PkoRule.updateGroupby({},{
                            id:detail.id,
                            sortOrder:detail.sortOrder,
                            groupByField:detail.groupByField
                        },function (res) {
                            toastr.success('更新分组依据成功','成功');
                            row.current_groups = null;
                            $scope.configGsfData(row, detail, field,res,fieldArr,false);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }
                });
            };

            //保存排序方式---方法(新增+更新)-------second
            $scope.saveSorts = function (row, detail, field) {
                return new Promise(function (resolve, reject) {
                    var form = $('#sorts_'+detail.id).attr('form_valid');
                    var verifyThrough = (form=='true')?true:false;
                    //判断当前表单是否验证通过
                    if(!verifyThrough){
                        var btn = '[form=sorts_'+detail.id+']';
                        $(btn).click();
                        reject({data:{errorMsg:'当前排序方式表单验证不合法'}});
                        return;
                    }
                    var currentField ='current_'+field;
                    var fieldArr = ['sortOrder','sortByField','sortType'];
                    if(detail.isNew){
                        PkoRule.addSortby({},{
                            ruleCode:detail.ruleCode,
                            subRuleCode:detail.subRuleCode,
                            sortOrder:detail.sortOrder,
                            sortByField:detail.sortByField,
                            sortType:detail.sortType
                        },function (res) {
                            toastr.success('新增排序方式成功','成功');
                            detail.isNew = false;//新增成功之后才能设为false
                            // row[currentField] = res.content;
                            row.current_sorts = null;
                            $scope.configGsfData(row, detail, field,res,fieldArr,true);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }else{
                        PkoRule.updateSortby({},{
                            id:detail.id,
                            sortOrder:detail.sortOrder,
                            sortByField:detail.sortByField,
                            sortType:detail.sortType
                        },function (res) {
                            toastr.success('更新排序方式成功','成功');
                            row.current_sorts = null;
                            $scope.configGsfData(row, detail, field,res,fieldArr,false);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }
                });
            };

            //保存过滤条件---方法(新增+更新)-------second
            $scope.saveFilters = function (row, detail, field) {
                return new Promise(function (resolve, reject) {
                    var form = $('#filters_'+detail.id).attr('form_valid');
                    var verifyThrough = (form=='true')?true:false;
                    //判断当前表单是否验证通过
                    if(!verifyThrough){
                        var btn = '[form=filters_'+detail.id+']';
                        $(btn).click();
                        // toastr.error('当前分组依据表单验证不合法','失败');
                        reject({data:{errorMsg:'当前过滤条件表单验证不合法'}});
                        return;
                    }

                    var currentField ='current_'+field;
                    var fieldArr = ['fieldName','operator','value'];
                    if(detail.isNew){
                        PkoRule.addFilter({},{
                            ruleCode:detail.ruleCode,
                            subRuleCode:detail.subRuleCode,
                            fieldName:detail.fieldName,
                            operator:detail.operator,
                            value:detail.value
                        },function (res) {
                            toastr.success('新增过滤条件成功','成功');
                            detail.isNew = false;//新增成功之后才能设为false
                            // row[currentField] = res.content;
                            row.current_filters = null;
                            $scope.configGsfData(row, detail, field,res,fieldArr,true);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }else{
                        PkoRule.updateFilter({},{
                            id:detail.id,
                            fieldName:detail.fieldName,
                            operator:detail.operator,
                            value:detail.value
                        },function (res) {
                            toastr.success('更新过滤条件成功','成功');
                            row.current_filters = null;
                            $scope.configGsfData(row, detail, field,res,fieldArr,false);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }
                });
            };

            //新增分组、排序、过滤后配置数据(新增+更新)-------second
            $scope.configGsfData = function (row, detail, field,res,fieldArr,isNew) {
                var currentField ='current_'+field,spareField = 'spare_'+field;
                if(isNew){
                    //res中必须有content  因为新增后返回id
                    row[field][0] = angular.copy(res.content);
                    for(var j=0;j<fieldArr.length;j++){
                        row[field][0][fieldArr[j]+'Show'] =  row[field][0][fieldArr[j]];
                    }
                    row[spareField] = angular.copy(row[field]);//tab>data>details>spare_ 被赋值

                    //scope.spareData中的details中对应的子规则 被赋值备用
                    for(var q=0;q<$scope.spareData.details.length;q++){
                        if(row.subRuleCode === $scope.spareData.details[q].subRuleCode && row.id === $scope.spareData.details[q].id){
                            $scope.spareData.details[q] = angular.copy(row);
                            // $scope.spareData.details[q][field] = angular.copy(row[field]);
                            // $scope.spareData.details[q][spareField] = angular.copy(row[field]);
                            break;
                        }
                    }
                }else{
                    var idx = -1;
                    for(var k=0;k<row[field].length;k++){
                        if(row[field][k].id == detail.id){
                            idx = k;
                            for(var a=0;a<fieldArr.length;a++){
                                row[field][k][fieldArr[a]+'Show'] =  row[field][k][fieldArr[a]];
                            }
                            row[spareField] = angular.copy(row[field]);
                            break;
                        }
                    }

                    if(idx>-1){
                        for(var i=0;i<$scope.spareData.details.length;i++){
                            if(row.subRuleCode === $scope.spareData.details[i].subRuleCode && row.id === $scope.spareData.details[i].id){
                                $scope.spareData.details[i][field] = angular.copy(row[field]);
                                $scope.spareData.details[i][spareField] = angular.copy(row[field]);
                                break;
                            }
                        }
                    }

                }
            };

            //删除分组、排序、过滤规则--------second
            $scope.deleteGsfDetail = function (row,detail,msg,field) {
                if(detail){
                    msg = '是否确认删除当前'+msg+'！';
                    openModal(msg,row,detail,$scope.deleteGsfDetailWay,field)
                }
            };

            //删除分组、排序、过滤规则---方法--------second
            $scope.deleteGsfDetailWay = function (row, detail,field) {
                var funcName = 'delete'+field;
                var contentField = (field=='Groupby'?'groups':(field=='Sortby'?'sorts':'filters'));
                var spareField = 'spare_'+contentField;
                PkoRule[funcName]({},{
                    id:detail.id
                },function (res) {
                    toastr.success('已成功删除','成功');
                    row['current_'+contentField] = null;
                    var h,f,idx;
                    for(h=0;h<row[contentField].length;h++){
                        if(detail.id === row[contentField][h].id){
                            row[contentField].splice(h,1);
                            row[spareField].splice(h,1);
                            idx = h;
                            break;
                        }
                    }
                    for(f=0;f<$scope.spareData.details.length;f++){
                        if(row.subRuleCode === $scope.spareData.details[f].subRuleCode && row.id === $scope.spareData.details[f].id){
                            $scope.spareData.details[f][contentField].splice(idx,1);
                            $scope.spareData.details[f][spareField] = angular.copy($scope.spareData.details[f][contentField]);
                            break;
                        }
                    }
                });
            };

            //分组、排序、过滤切换tab页时，将之前所展示的tab中的新增数据（未保存的）删除 或者有正在编辑的数据关闭
            $scope.changeData = function (row) {
                if(row.groups.length>0 && row.groups[0].id===-10000){
                    row.groups.splice(0,1);
                }
                if(row.sorts.length>0 && row.sorts[0].id===-10000){
                    row.sorts.splice(0,1);
                }
                if(row.filters.length>0 && row.filters[0].id===-10000){
                    row.filters.splice(0,1);
                }
                row.current_groups = null;
                row.current_sorts = null;
                row.current_filters = null;

                row.groups = angular.copy(row.spare_groups);
                row.sorts = angular.copy(row.spare_sorts);
                row.filters = angular.copy(row.spare_filters);

            };


            //取消操作
            $scope.cancel = function (tab, detail,isSecond,field) {
                if(isSecond){
                    //当前isSecond为true时   tab为row   detail为分组排序过滤中的一个对象
                    var currentField ='current_'+field,spareField = 'spare_'+field;
                    if(tab){
                        tab[currentField] = null;
                    }
                    //新增下的情况
                    if(detail && detail.id == -10000){
                        //表示当前需要取消的是新增的且还未保存  直接删除
                        tab[field].splice(0,1);
                        return;
                    }
                    //编辑下的情况
                    if(tab){
                        for(var x=0;x<tab[field].length;x++){
                            if(tab[field][x].id === detail.id){
                                tab[field][x] = angular.copy(tab[spareField][x]);
                                break;
                            }
                        }
                    }
                }else{
                    if(tab){
                        tab.currentDetail = null;
                        // detail.isEdit = false;
                    }
                    //新增下的情况
                    if(detail.signId == -10002){
                        //表示当前需要取消的是新增的且还未保存  直接删除
                        tab.data.details.splice(0,1);
                        return;
                    }
                    //编辑下的情况
                    if(tab){
                        for(var x=0;x<tab.data.details.length;x++){
                            if(tab.data.details[x].id === detail.id && tab.data.details[x].subRuleCode === detail.subRuleCode){
                                tab.data.details[x] = angular.copy($scope.spareData.details[x]);
                                break;
                            }
                        }
                    }
                }
            };

            // 打开弹出框提示
            function openModal(msg,tab,detail,func,field) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'iwh-strategy-module/confirmModal.html',
                    controller: ctrl,
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    resolve: {
                        item:function(){
                            return {
                                msg:msg
                            }
                        }
                    }
                });

                ctrl.$inject = ['item'];
                function ctrl(item) {
                    var $ctrl = this;
                    $ctrl.item = item;
                    $ctrl.ok = ok;
                    $ctrl.cancel =cancel;//提交

                    function ok() {
                        if(field){
                            func(tab,detail,field);
                        }else{
                            func(tab,detail);
                        }
                        modalInstance.close();
                    }

                    function cancel() {
                        modalInstance.close();
                    }
                }
            }

        }
    ]);
})();
