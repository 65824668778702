(function () {
    'use strict';

    angular.module('iwh-dataModule')
        .controller('IwhPlatformController', PlatformController);

    PlatformController.$inject = ['IwhPlatform', 'NgTableParams', '$uibModal', '$state', 'Principal', '$timeout', 'toastr'];

    function PlatformController(Platform, NgTableParams, $uibModal, $state, Principal, $timeout, toastr) {
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.active = 0;
        vm.removeTab = removeTab;

        //园区操作
        vm.addPlatform = addPlatform;
        vm.newPlatform = newPlatform;
        vm.editPlatform = editPlatform;
        vm.updatePlatform = updatePlatform;
        vm.removePlatform = removePlatform;
        //
        vm.changeStatus = changeStatus;

        vm.status = [
            {id: 1, title: "启用"},
            {id: 0, title: "禁用"},
        ];

        vm.postParams = {};
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({
                    pn: params.page(),
                    ps: params.count(),
                    orderBy: 'code'
                }, params.filter());

                if ($state.params.postParams !== null) {
                    var temp = {};
                    for (var p in $state.params.postParams) {
                        var name = p;//属性名称
                        var value = $state.params.postParams[p];//属性对应的值
                        temp[name] = $state.params.postParams[p];
                    }
                    $state.params.postParams = null;
                }

                vm.postParams = postParams;
                return Platform.listPlatform({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                    var datas = data.content.datas;
                    for (var i in datas) {
                        var temp = datas[i].types.split(',');
                        datas[i].types = temp;
                    }
                    // console.log(datas,"月台列表");
                    return datas;
                });
            }
        });

        // 出入口类型
        vm.types = [
            {'id': '10', 'title': '打包月台'},
            {'id': '20', 'title': '收货月台'},
            {'id': '30', 'title': '发货月台'}];

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function addPlatform() {
            vm.showGatewayAndPlatformingLot = false;
            var entity = {
                heading: '新建',
                contentId: "platform-edit.html",
                active: true,
                data: {
                    platform: {
                        errorAM: '',
                        errorPM: '',
                        errorAmClass: '',
                        errorPMClass: ''
                    }
                },
                newPlatform: true,
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                entity.active = 0;
            }, 0);
        }

        function newPlatform(t, form, index) {
            var valid = form.$valid;
            console.log(valid);
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if (!t.data.platform.types || t.data.platform.types.length === 0) {
                toastr.warning("请至少选择一个月台类型", "警告");
                return;
            }
            // console.info(t.data.platform);
            var temp = {
                code: t.data.platform.code,
                name: t.data.platform.name,
                coordinateX: t.data.platform.coordinateX,
                coordinateY: t.data.platform.coordinateY,
                length: t.data.platform.length,
                width: t.data.platform.width,
                worktimeAmBegin: t.data.platform.worktimeAmBegin,
                worktimeAmEnd: t.data.platform.worktimeAmEnd,
                worktimePmBegin: t.data.platform.worktimePmBegin,
                worktimePmEnd: t.data.platform.worktimePmEnd,
                remark: t.data.platform.remark,
                types: t.data.platform.types.join(','),
                //验证时间段
                errorAM: t.data.platform.errorAM,
                errorAmClass: t.data.platform.errorAmClass,
                errorPM: t.data.platform.errorPM,
                errorPmClass: t.data.platform.errorPmClass
            };

            //验证时间段
            if (!vm.verifyTime(temp)){
//            	console.log('vm.verifyTime');
            	t.data.platform.errorAM = temp.errorAM;
            	t.data.platform.errorAmClass = temp.errorAmClass;
            	t.data.platform.errorPM = temp.errorPM;
            	t.data.platform.errorPmClass = temp.errorPmClass;
                return;
            }

//            console.log(temp);
            Platform.savePlatform({}, temp, function (resp) {
//            	console.log(resp);
                if (resp.content) {
                    toastr.success("新增成功", "成功");
                    vm.showGatewayAndPlatformingLot = true;
                    vm.removeTab(index);
                    vm.search();
                }
            })

        }

        function editPlatform(row) {
            vm.showGatewayAndPlatformingLot = true;

            var temp = {
                id: row.id,
                parkCode:row.parkCode,
                warehouseCode:row.warehouseCode,
                code: row.code,
                name: row.name,
                coordinateX: row.coordinateX,
                coordinateY: row.coordinateY,
                length: row.length,
                width: row.width,
                worktimeAmBegin: new Date(row.worktimeAmBegin),
                worktimeAmEnd: new Date(row.worktimeAmEnd),
                worktimePmBegin: new Date(row.worktimePmBegin),
                worktimePmEnd: new Date(row.worktimePmEnd),
                remark: row.remark,
                types: row.types,
                //验证时间段
                errorAM: row.errorAM,
                errorAmClass: row.errorAmClass,
                errorPM: row.errorPM,
                errorPmClass: row.errorPmClass
            };

            var entity = {
                heading: '编辑' + temp.code,
                contentId: "platform-edit.html",
                active: true,
                data: {
                    platform: temp
                },
                newPlatform: false
            };
            vm.tabs.push(entity);
            $timeout(function () {
                vm.active = vm.tabs.length;
                entity.active = 0;
            }, 0);
        }

        function updatePlatform(temp, form, index) {

            if (form !== null) {
                var valid = form.$valid;
                if (!valid) {
                    form.showMsg = true;
                    return;
                }
            }
            //验证时间段
            if (!vm.verifyTime(temp)){
                return;
            }
            // console.log(temp.worktimeAmBegin.getHours(), "小时");

            if (!temp.types || temp.types.length === 0) {
                toastr.warning("请至少选择一个月台类型", "警告");
                return;
            }
            Platform.updatePlatform({types: temp.types.join(',')}, temp, function (resp) {
                if (resp.content) {
                    toastr.success("修改成功", "成功");
                    removeTab(index);
                    vm.search();
                }
            })
        }

        function removePlatform(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteConfirm.html',
                controller: deleteModal,
                controllerAs: 'ctrl',
                resolve: {
                    item: row
                }
            });
            vm.modalInstance.result.then(function (item) {
                Platform.delPlatform({}, {id: item.id, warehouseCode: item.warehouseCode}, function (resp) {
                    if (resp.content === "success") {
                        toastr.success("删除成功");
                        vm.search();
                    }
                });
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];

        function deleteModal($uibModalInstance, item) {
            var ctrl = this;
            ctrl.item = item;
            ctrl.ok = ok;
            ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close(ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        //#endregion

        function changeStatus(row) {
            row.status = row.status === 1 ? 0 : 1;
            Platform.updatePlatform({types: row.types.join(',')}, row, function (resp) {
                if (resp.content) {
                    toastr.success("修改成功", "成功");
                    vm.search();
                }
            })

        }


        //#region刷新编辑界面
        // 通用方法
        vm.search = function search() {
            vm.tableParams.reload();
        };

        /**
         * 验证作业时间段正确性
         * @param temp
         * @returns {boolean}
         */
        vm.verifyTime = function(temp){
            temp.errorAM = '';
            temp.errorAmClass = '';
            temp.errorPM = '';
            temp.errorPmClass = '';

            if (temp.worktimeAmBegin.getHours() > 11 || temp.worktimeAmEnd.getHours() > 12) {
                temp.errorAM = '这个时间段只能输入00:00-12:00';
                temp.errorAmClass = 'has-error';
                return false;
            }

            if (temp.worktimeAmBegin > temp.worktimeAmEnd) {
                temp.errorAM = '起始时间不能大于结束时间';
                temp.errorAmClass = 'has-error';
                return false;
            }

            if (temp.worktimeAmBegin.getHours() === temp.worktimeAmEnd.getHours() && temp.worktimeAmBegin.getMinutes() === temp.worktimeAmEnd.getMinutes()) {
                temp.errorAM = '起始时间和结束时间不能相同';
                temp.errorAmClass = 'has-error';
                return false;
            }

            if (temp.worktimePmBegin.getHours() < 12 || temp.worktimePmEnd.getHours() < 12) {
                temp.errorPM = '这个时间段只能输入12:00-23:59';
                temp.errorPmClass = 'has-error';
                return false;
            }

            if (temp.worktimePmBegin > temp.worktimePmEnd) {
                temp.errorPM = '起始时间不能大于结束时间';
                temp.errorPmClass = 'has-error';
                // console.log('起始时间不能大于结束时间');
                return false;
            }
            if (temp.worktimePmBegin.getHours() === temp.worktimePmEnd.getHours() && temp.worktimePmBegin.getMinutes() === temp.worktimePmEnd.getMinutes()) {
                temp.errorPM = '起始时间和结束时间不能相同';
                temp.errorPmClass = 'has-error';
                // console.log('起始时间不能大于结束时间');
                return false;
            }
            return true;
        }

    }

})();
