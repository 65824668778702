(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('print-rule', {
            parent: 'main',
            url: 'iwh-strategy/print-rule',
            data: {'pageTitle': '打印策略维护'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/printRule/printRule.html',
                    controller: 'PrintRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('PrintRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/print-rule', {}, {
            'list': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/list', method: 'POST'},
            'detail': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/detail/:code', params: {code: 'code'},method: 'POST'},
            'add': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/add', method: 'POST'},
            'update': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/update', method: 'POST'},
            'updateStatus': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/updateStatus', method: 'POST'},
            'addFilter': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/filter/add', method: 'POST'},
            'updateFilter': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/filter/update', method: 'POST'},
            'deleteFilter': {url: iServiceConfig.iwhStrategyHost + '/web/print-rule/filter/delete', method: 'POST'}
        });
    }]);

    app.controller('PrintRuleController', [
        '$scope', 'PrintRule', 'NgTableParams', '$timeout', 'toastr','enums','$uibModal',
        function ($scope, PrintRule, NgTableParams, $timeout, toastr,enums,$uibModal) {

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.printStatus = enums('strategy.PrintRuleStatus');//状态
            $scope.status = $scope.printStatus.list('title','id');//状态
            $scope.printRuleFilterEnums = enums('strategy.PrintRuleFilterField');//筛选字段
            $scope.printRuleFilterField = enums('strategy.PrintRuleFilterField').list();//筛选字段
            $scope.operatorEnums = enums('common.Operator');//比较类型
            $scope.operator = enums('common.Operator').list();//比较类型

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return PrintRule.list({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            //更新状态
            $scope.updateStatus = function (row) {
                var status = row.status === 10?0:10;
                var msg = row.status === 10?'禁用':'启用';
                PrintRule.updateStatus({},{
                    code:row.code,
                    status:status
                },function (res) {
                    toastr.success(msg+'成功','成功');
                    $scope.search();
                });
            };

            //新建规则
            $scope.createPrintRule = function () {
                var tab = {
                    heading: '新建',
                    contentId: "printRuleDetail.tpl",
                    active: true,
                    isNew:true
                };
                var row = {
                    code:null,
                    status: 10,
                    name:null,
                    remark:null
                };
                tab.data = row;
                $scope.tabs.push(tab);
                $timeout(function () {
                    $scope.active = $scope.tabs.length;
                }, 0);
            };

            //保存新增策略
            $scope.saveRule = function(tab){
                $('#saveRule').blur();
                var obj = {
                    code:tab.data.code,
                    status: tab.data.status,
                    name:tab.data.name,
                    remark:tab.data.remark
                };

                //新增策略
                if(tab.isNew){
                    //    新增
                    PrintRule.add({},obj,function (res) {
                        toastr.success('新增策略成功','成功');
                        tab.isNew = false;
                        tab.isEdit = true;
                        tab.heading = res.content.name;

                        var row = {code:tab.data.code};
                        $scope.getChildRule(row).then(function (res) {
                            if(res.content.name && !tab.heading){
                                $timeout(function () {
                                    tab.heading = res.content.name;
                                });
                            }
                            tab.data = angular.copy(res.content);
                            tab.data.filters = [];

                            //配置一些数据，将绑定输入框的数据与展示数据分开
                            $scope.configData(tab.data);
                        }).catch(function (res) {
                        });
                    });
                    return;
                }

                //编辑策略
                if(tab.data.current_filters){
                    //若当前有新增
                    if(tab.data.current_filters.id && tab.data.current_filters.id === -10000){
                        $scope.saveFilters(tab.data, tab.data.filters[0]).then(function (res) {
                            PrintRule.update({},obj,function (res) {
                                toastr.success('更新策略成功','成功');
                            });
                        }).catch(function (res) {
                        });
                    //若当前有编辑
                    }else if(tab.data.current_filters.id){
                        //编辑
                        for(var i=0;i<tab.data.filters.length;i++){
                            if(tab.data.current_filters.id === tab.data.filters[i].id){
                                $scope.saveFilters(tab.data, tab.data.filters[i]).then(function (res) {
                                    PrintRule.update({},obj,function (res) {
                                        toastr.success('更新策略成功','成功');
                                    });
                                }).catch(function (res) {
                                });
                                break;
                            }
                        }
                    }
                }else{
                    PrintRule.update({},obj,function (res) {
                        toastr.success('更新策略成功','成功');
                    });
                }
            };

            //配置数据
            $scope.configData = function (data) {
                if(data.filters){
                    for(var i=0;i<data.filters.length;i++){
                        data.filters[i].fieldNameShow = data.filters[i].fieldName;
                        data.filters[i].operatorShow = data.filters[i].operator;
                        data.filters[i].valueShow = data.filters[i].value;
                    }
                }

                data.spareData = angular.copy(data.filters);
            };

            //编辑或查看规则
            $scope.editPrintRule = function (row, isEdit) {
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data && $scope.tabs[i].data.code === row.code) {
                        return $scope.active = i + 1;
                    }
                }
                var tab = {
                    heading: row.name,
                    contentId: "printRuleDetail.tpl",
                    active: true,
                    isEdit:isEdit
                };
                $scope.tabs.push(tab);

                $scope.getChildRule(row).then(function (res) {
                    if(res.content.name && !tab.heading){
                        tab.heading = res.content.name;
                    }

                    tab.data = angular.copy(res.content);
                    $scope.configData(tab.data);
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    });
                }).catch(function (res) {
                    $scope.tabs.pop();
                });
            };

            //获取当前需要编辑的规则信息----tab.data
            $scope.getChildRule = function (row) {
                return new Promise(function (resolve, reject) {
                    PrintRule.detail({
                        code:row.code
                    },{},function (res) {
                        resolve(res);
                    },function (res) {
                        reject(res);
                    });
                });
            };

            //新增过滤规则
            $scope.addFilterRule = function (tab) {
                if(angular.isArray(tab.data.filters) && tab.data.filters[0] && tab.data.filters[0].id == -10000){
                    toastr.error('当前还有新增过滤规则未完成');
                    tab.data.current_filters = tab.data.filters[0];
                    return;
                }
                var obj = {
                    warehouseCode:tab.data.warehouseCode,
                    tenantId:tab.data.tenantId,
                    id:-10000,
                    isNew:true,
                    fieldName:null,
                    operator:null,
                    value:null
                };
                tab.data.filters.splice(0,0,obj);
                tab.data.current_filters = obj;
            };

            //编辑当前过滤规则
            $scope.editFilter = function(tab,filter,isEdit){
                //两者相同 说明当前选中的子规则没变化  不需要继续执行--主要针对查看情况
                if(tab.data.current_filters && filter && tab.data.current_filters.id == filter.id){
                    return;
                }

                //点击其它依据、排序方式或过滤条件时----将上一条数据复原
                if(tab.data.current_filters){
                    for(var r=0;r<tab.data.spareData.length;r++){
                        if(tab.data.current_filters.id == tab.data.spareData[r].id){
                            tab.data.filters[r] = angular.copy(tab.data.spareData[r]);
                            break;
                        }
                    }
                }

                //如果当前有新增未保存  则直接进入这里执行保存操作
                if(tab.data.current_filters && tab.data.current_filters.id == -10000){
                    //如果第一条规则signId为-10002   点击其它子规则时将其删除
                    tab.data.filters.splice(0,1);
                }

                tab.data.current_filters = filter;
            };

            //保存过滤条件---方法(新增+更新)-------second
            $scope.saveFilters = function (data,filter) {
                return new Promise(function (resolve, reject) {
                    if(filter.isNew){
                        PrintRule.addFilter({},{
                            ruleCode:data.code,
                            fieldName:filter.fieldName,
                            operator:filter.operator,
                            value:filter.value
                        },function (res) {
                            toastr.success('新增过滤条件成功','成功');
                            filter.isNew = false;//新增成功之后才能设为false
                            filter.id = res.content.id;
                            data.current_filters = null;//关闭当前过滤规则
                            $scope.configFilterData(data, filter,res,true);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }else{
                        PrintRule.updateFilter({},{
                            id:filter.id,
                            fieldName:filter.fieldName,
                            operator:filter.operator,
                            value:filter.value
                        },function (res) {
                            toastr.success('更新过滤条件成功','成功');
                            data.current_filters = null;
                            $scope.configFilterData(data, filter,res,false);
                            resolve(res);
                        },function (res) {
                            reject(res);
                        });
                    }
                });
            };

            //新增分过滤后配置数据(新增+更新)
            $scope.configFilterData = function (data, filter,res,isNew) {
                if(isNew){
                    //res中必须有content  因为新增后返回id
                    data.filters[0] = angular.copy(res.content);

                    data.filters[0].fieldNameShow =  data.filters[0].fieldName;
                    data.filters[0].operatorShow =  data.filters[0].operator;
                    data.filters[0].valueShow =  data.filters[0].value;

                    data.spareData = angular.copy(data.filters);//tab>data>spareData被赋值
                }else{
                    for(var k=0;k<data.filters.length;k++){
                        if(data.filters[k].id == filter.id){
                            data.filters[k].fieldNameShow =  data.filters[k].fieldName;
                            data.filters[k].operatorShow =  data.filters[k].operator;
                            data.filters[k].valueShow =  data.filters[k].value;
                            data.spareData = angular.copy(data.filters);//tab>data>spareData被赋值
                            break;
                        }
                    }
                }
            };

            //删除过滤规则
            $scope.deleteFilter = function (tab,filter) {
                if(filter){
                    openModal(tab,filter);
                }
            };

            //删除过滤规则---方法
            $scope.deleteFilterWay = function (tab,filter) {
                PrintRule.deleteFilter({},{
                    id:filter.id
                },function (res) {
                    toastr.success('该过滤规则已成功删除','成功');
                    tab.data.current_filters = null;
                    for(var h=0;h<tab.data.filters.length;h++){
                        if(filter.id === tab.data.filters[h].id){
                            tab.data.filters.splice(h,1);
                            tab.data.spareData.splice(h,1);
                            break;
                        }
                    }
                });
            };

            //取消编辑
            $scope.cancel = function (tab,filter) {
                if(filter.isNew && filter.id === -10000){
                    tab.data.filters.splice(0,1);
                    tab.data.current_filters = null;
                }else{
                    tab.data.current_filters = null;
                }
            };

            // 打开弹出框提示
            function openModal(tab,filter) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'iwh-strategy-module/confirmModal.html',
                    controller: ctrl,
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    resolve: {
                        item:function(){
                            return {
                            }
                        }
                    }
                });

                ctrl.$inject = ['item'];
                function ctrl(item) {
                    var $ctrl = this;
                    $ctrl.item = item;
                    $ctrl.ok = ok;
                    $ctrl.cancel =cancel;//提交

                    function ok() {
                        $scope.deleteFilterWay(tab,filter);
                        modalInstance.close();
                    }

                    function cancel() {
                        modalInstance.close();
                    }
                }
            }

        }
    ]);
})();
