(function () {
    'use strict';

    angular.module('doveModule')
        .controller('iBusImportTemplateController', iBusImportTemplateController);

    iBusImportTemplateController.$inject = ['iBusFileImportTemplate', 'NgTableParams', '$timeout', 'toastr','iServiceConfig','$localStorage'];

    function iBusImportTemplateController(iBusFileImportTemplate, NgTableParams, $timeout, toastr,iServiceConfig,$localStorage) {

        /************************************************************************/
        /****************START***************************************************/
        /************************************************************************/
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.templateType = ["EXCEL", "XML", "JSON", "CSV"];
        vm.status = [{'id': 0, 'title': '禁用'}, {'id': 1, 'title': '启用'}];
        vm.isUpdateFile = false;
        vm.active = 0;
        vm.search = search;
        vm.preSave = preSave;
        vm.save = save;
        vm.statusEnabled = statusEnabled;
        vm.statusDisabled = statusDisabled;
        vm.preUpdate = preUpdate;
        vm.update = update;
        vm.getLogs = getLogs;
        vm.removeTab = removeTab;
        vm.downloadTemplate = downloadTemplate;
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function (params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return iBusFileImportTemplate.search({}, postParams,
                    function (resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                    return data.content.datas;
                });
            }
        });

        /************************************************************************/
        /****************END***************************************************/
        /************************************************************************/
        /************************************************************************/
        /****************START***************************************************/
        /************************************************************************/
        function downloadTemplate(templatedata) {
        	var url = iServiceConfig.ibusHost + "/web/importTemplate/download/"+templatedata.id+"?token="+$localStorage.authenticationToken;
        	var a = document.createElement('a');
            document.body.appendChild(a); //此处增加了将创建的添加到body当中
            a.href = url;
            a.target = '_blank';
            a.click();
            a.remove();
        }

        function getLogs(row) {
            vm.tableParamsLog = new NgTableParams({count: 10}, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count()
                    }, params.filter());
                    return iBusFileImportTemplate.showLogs({code: row.code}, postParams, function (resp) {
                        // console.log("Content:");
                        // console.log(resp.content);
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function (data) {
                        // console.log("data:");
                        // console.log(data.content);
                        return data.content.datas;
                    });
                }
            });
            //加载
            return vm.tableParamsLog;
        }

        function preSave() {
            iBusFileImportTemplate.preSave({}, {}, function (resp) {
                // var data = resp.content;
                // data.templateFile = null;
                var entity = {
                    heading: "新增",
                    contentId: "importTemplate-add.html",
                    data: resp.content,
                    domain: {},
                    process: {},
                    roles: [],
                    active: true
                };
                vm.tabs.push(entity);
                //跳转到指定选项卡
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        }

        /**
         * 用于新增或者更新校验字段
         * @param tab
         * @param idx
         * @param form
         * @returns {boolean}
         */
        function isValidate(tab, idx, form) {
            var data = tab.data;
            // console.log("Domain:");
            // console.log(tab.domain);
            // console.log("Process:");
            // console.log(tab.process);
            form.passwordUnmatch = "";

            var valid = form.$valid;
            if (!valid || !!!tab.domain || !!!tab.process) {
                toastr.info("信息未填完");
                form.showMsg = true;
                return false;
            }

            //检测其他属性
            if (data.fileMaxSize <= 0) {
                toastr.error("文件限制大小必须大于0");
                return false;
            }
            if (!!!data.id || (!!data.id && vm.isUpdateFile)) {
                //用户添加模版或者更新模版且选中了重新上传模版文件多选按钮，校验文件
                //检测用户文件是否选择并且文件类型是否正确
                if (!!!data.templateFile || !!!data.templateFile[0]) {
                    toastr.info("文件尚未选择");
                    return false;
                } else {
                    var filename = data.templateFile[0].name;
                    var subfix = filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
                    var type = data.templateType.toUpperCase();
                    var flag = false;
                    if (type == "EXCEL") {
                        flag = (subfix == "xls" || subfix == "xlsx");
                    } else if (type == "XML") {
                        flag = (subfix == "xml");
                    } else if (type == "JSON") {
                        flag = (subfix == "json");
                    } else if (type == "CSV") {
                        flag = (subfix == "csv");
                    }
                    if (!flag) {
                        toastr.error("上传的文件必须是" + type);
                        return false;
                    }
                    //检测文件大小
                    if (data.templateFile[0].size <= 0) {
                        toastr.error("你不能上传空文件");
                        return false;
                    }
                }
            }
            //校验通过
            //填充业务编码，业务名称，执行流程编码，执行流程名称
            data.domainCode = tab.domain.code;
            data.domainName = tab.domain.name;
            data.processCode = tab.process.code;
            data.processName = tab.process.name;
            return true;
        }

        function save(tab, idx, form) {
            if (isValidate(tab, idx, form)) {
                iBusFileImportTemplate.save({}, tab.data, function (resp) {
                        // console.log("resp.content");
                        // console.log(resp.content);
                        vm.removeTab(idx);
                    }
                );
            }
        }

        function statusEnabled(row) {

            iBusFileImportTemplate.updateStatus({id: row.id, status: 1, code: row.code}, {}, function (resp) {
                // console.log("content:");
                // console.log(resp.content);
                row.status = resp.content.status;
                row.updateName = resp.content.updateName;
                row.updateDt = resp.content.updateDt;
            });
        }

        function statusDisabled(row) {
            iBusFileImportTemplate.updateStatus({id: row.id, status: 0, code: row.code}, {}, function (resp) {
                // console.log("content:");
                // console.log(resp.content);
                row.status = resp.content.status;
                row.updateName = resp.content.updateName;
                row.updateDt = resp.content.updateDt;
            });
        }

        function preUpdate(row) {
            vm.isUpdateFile = false;
            iBusFileImportTemplate.preUpdate({id: row.id}, {}, function (resp) {
                var data = resp.content;
                data.templateFile = null;
                var entity = {
                    heading: "编辑",
                    contentId: "importTemplate-eidt.html",
                    data: resp.content,
                    domain: {},
                    process: {},
                    domainCode: data.domainCode,
                    processCode: data.processCode,
                    roles: [],
                    active: true
                };
                // console.log("Entity:");
                // console.log(entity);
                vm.tabs.push(entity);
                //跳转到指定选项卡
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            });
        }

        function update(tab, idx, form) {
            if (isValidate(tab, idx, form)) {
                if (!vm.isUpdateFile) {
                    tab.data.templateFile = null;
                }
                iBusFileImportTemplate.update({id: tab.data.id}, tab.data, function (resp) {
                    // console.log("resp.content");
                    // console.log(resp.content);
                    vm.removeTab(idx);
                });
            }
        }

        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        /***********************************************************************/
        /****************END****************************************************/
        /***********************************************************************/
    }
})();
