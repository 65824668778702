(function() {
    'use strict';

    angular
        .module('iplanModule', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'angularMoment',
            'ui.router',
            'infinite-scroll',
            'ngTouch',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ngTable',
            'exTable',
            'filesModel',
            'ui.tree',
            'treeControl',
            'ngTree',
            'ngSelect',
            'ngMenu',
            'toastr',
            'iservice.base',
            'scTabs',
            'scModal',
            'frapontillo.bootstrap-switch'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope'];

    function run(stateHandler, $rootScope) {
        stateHandler.initialize();
    }
})();
