(function () {
    'use strict';

    var app = angular.module('iwh-strategyModule');

    app.config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('alloc-rule', {
            parent: 'main',
            url: 'iwh-strategy/alloc-rule',
            data: {'pageTitle': '分配规则'},
            views: {
                'mainContent': {
                    templateUrl: 'iwh-strategy-module/controller/allocRule/allocRule.html',
                    controller: 'AllocRuleController'
                }
            },
            resolve: {}
        })
    }]);

    app.factory('AllocRule', ['$resource', 'iServiceConfig',  function ($resource, iServiceConfig) {
        return $resource('web/alloc-rule', {}, {
            'listPost': {url: iServiceConfig.iwhStrategyHost + '/web/alloc-rule', method: 'POST'},
            'savePost': {url: iServiceConfig.iwhStrategyHost + '/web/alloc-rule/save', method: 'POST'},
            'contentGet': {url: iServiceConfig.iwhStrategyHost + '/web/alloc-rule/:id', params: {id: '@id'}, method: 'GET'},
            "detailSavePost": {url: iServiceConfig.iwhStrategyHost + '/web/alloc-rule/detail/save', method: 'POST'},
            "detailDeletePost": {url: iServiceConfig.iwhStrategyHost + '/web/alloc-rule/detail/delete/:id', params: {id: '@id'}, method: 'POST'},
            'getAllocateHierarchy': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.AllocateHierarchy.json', method: 'GET'},
            'getOverAllocateMethod': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.OverAllocateMethod.json', method: 'GET'},
            'getStdPackUom': {url: iServiceConfig.iwhStrategyHost + '/api/enum/strategy.StdPackUom.json', method: 'GET'},

        });
    }]);

    app.controller('AllocRuleController', [
        '$scope', 'AllocRule', 'NgTableParams', '$timeout', 'toastr',
        function ($scope, AllocRule, NgTableParams, $timeout, toastr) {
            $scope.ynOptions = [{k: 1, v: '是'}, {k: 0, v: '否'}];
            $scope.stdPackUomsMap = {};
            AllocRule.getStdPackUom({}, function (result) {
                $scope.stdPackUoms = result.content;
                angular.forEach($scope.stdPackUoms, function (data, index, array) {
                    $scope.stdPackUomsMap[data.value] = data.label;
                });
            });

            $scope.allocateHierarchysMap = {};
            AllocRule.getAllocateHierarchy({}, function (result) {
                $scope.allocateHierarchys = result.content;
                angular.forEach($scope.allocateHierarchys, function (data, index, array) {
                    $scope.allocateHierarchysMap[data.value] = data.label;
                });
            });
            $scope.overAllocateMethodsMap = {};
            AllocRule.getOverAllocateMethod({}, function (result) {
                $scope.overAllocateMethods = result.content;
                angular.forEach($scope.overAllocateMethods, function (data, index, array) {
                    $scope.overAllocateMethodsMap[data.value] = data.label;
                });
            });

            $scope.tableParams = null;
            $scope.tabs = [];
            $scope.active = 0;

            $scope.closeTab = function (idx) {
                $scope.tabs.splice(idx, 1);
            };

            // 防止两次请求，将tableParams放到search方法中
            $scope.search = function () {
                if ($scope.tableParams) {
                    $scope.tableParams.reload();
                } else {
                    $scope.tableParams = new NgTableParams({count: 10}, {
                        getData: function (params) {
                            var postParams = angular.merge({
                                pn: params.page(),
                                ps: params.count()
                            }, params.filter());
                            return AllocRule.listPost({}, postParams, function (resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function (data) {
                                return data.content.datas;
                            });
                        }
                    });
                }
            };

            $scope.allocRuleContent = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {details: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '(添加)',
                    contentId: "allocRuleDetail.tpl",
                    active: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    AllocRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.allocRuleShow = function (row) {
                var isNew = !row;
                if (isNew) {
                    row = {details: [], status: 1};
                }
                for (var i = 0; i < $scope.tabs.length; i++) {
                    if ($scope.tabs[i].data.id === row.id) {
                        return $scope.active = i + 1;
                    }
                }

                var tab = {
                    heading: row.id ? row.name : '(添加)',
                    contentId: "allocRuleDetail.tpl",
                    active: true,
                    show: true
                };
                $scope.tabs.push(tab);
                if (!isNew) {
                    AllocRule.contentGet({id: row.id}, function (item) {
                        tab.data = item.content;
                        $scope.active = $scope.tabs.length;
                    }, function () {
                        $scope.tabs.pop();
                    });
                } else {
                    tab.data = row;
                    $timeout(function () {
                        $scope.active = $scope.tabs.length;
                    }, 0);
                }
            };

            $scope.saveAllocRule = function (tab, index) {
                AllocRule.savePost({}, tab.data, function (resp) {
                    if (resp.content && resp.content.id) {
                        tab.data = resp.content;
                        tab.heading = tab.data.name;
                        toastr.success('保存成功！', '成功');
                        $scope.closeTab(index);
                    }
                });
            };

            $scope.allocRuleDetail = function (tab, detail) {
                var rule = tab.data;
                var cdetail = angular.copy(detail);
                if (cdetail) {
                    if (cdetail.zoneIn) {
                        cdetail.zoneIns = cdetail.zoneIn.split(/,/g);
                    } else {
                        cdetail.zoneIns = [];
                    }
                    if (cdetail.zoneEx) {
                        cdetail.zoneExs = cdetail.zoneEx.split(/,/g);
                    } else {
                        cdetail.zoneExs = [];
                    }
                    if (cdetail.orderTypeIn) {
                        cdetail.orderTypeIns = cdetail.orderTypeIn.split(/,/g);
                    } else {
                        cdetail.orderTypeIns = [];
                    }
                    if (cdetail.orderTypeEx) {
                        cdetail.orderTypeExs = cdetail.orderTypeEx.split(/,/g);
                    } else {
                        cdetail.orderTypeExs = [];
                    }
                    
                    if (cdetail.locCategoryIn) {
                        cdetail.locCategoryIns = cdetail.locCategoryIn.split(/,/g);
                    } else {
                        cdetail.locCategoryIns = [];
                    }
                    if (cdetail.locCategoryEx) {
                        cdetail.locCategoryExs = cdetail.locCategoryEx.split(/,/g);
                    } else {
                        cdetail.locCategoryExs = [];
                    }
                    if (cdetail.locStorageTypeIn) {
                        cdetail.locStorageTypeIns = cdetail.locStorageTypeIn.split(/,/g);
                    } else {
                        cdetail.locStorageTypeIns = [];
                    }
                    if (cdetail.locStorageTypeEx) {
                        cdetail.locStorageTypeExs = cdetail.locStorageTypeEx.split(/,/g);
                    } else {
                        cdetail.locStorageTypeExs = [];
                    }
                    cdetail.uoms = cdetail.uom ? detail.uom.split(/,/g) : [];
                }
                tab.currentDetail = cdetail || {
                    tenantId: rule.tenantId,
                    warehouseCode: rule.warehouseCode,
                    ruleCode: rule.code
                };
            };

            $scope.saveAllocRuleDetail = function (tab) {
                if (!tab.currentDetail) return;

                if (tab.currentDetail.zoneIns) {
                    tab.currentDetail.zoneIn = tab.currentDetail.zoneIns.join(',');
                } else {
                    tab.currentDetail.zoneIn = '';
                }
                if (tab.currentDetail.zoneExs) {
                    tab.currentDetail.zoneEx = tab.currentDetail.zoneExs.join(',');
                } else {
                    tab.currentDetail.zoneEx = '';
                }
                if (tab.currentDetail.orderTypeIns) {
                    tab.currentDetail.orderTypeIn = tab.currentDetail.orderTypeIns.join(',');
                } else {
                    tab.currentDetail.orderTypeIn = '';
                }
                if (tab.currentDetail.orderTypeExs) {
                    tab.currentDetail.orderTypeEx = tab.currentDetail.orderTypeExs.join(',');
                } else {
                    tab.currentDetail.orderTypeEx = '';
                }

                if (tab.currentDetail.locCategoryIns) {
                    tab.currentDetail.locCategoryIn = tab.currentDetail.locCategoryIns.join(',');
                } else {
                    tab.currentDetail.locCategoryIn = '';
                }
                if (tab.currentDetail.locCategoryExs) {
                    tab.currentDetail.locCategoryEx = tab.currentDetail.locCategoryExs.join(',');
                } else {
                    tab.currentDetail.locCategoryEx = '';
                }
                if (tab.currentDetail.locStorageTypeIns) {
                    tab.currentDetail.locStorageTypeIn = tab.currentDetail.locStorageTypeIns.join(',');
                } else {
                    tab.currentDetail.locStorageTypeIn = '';
                }
                if (tab.currentDetail.locStorageTypeExs) {
                    tab.currentDetail.locStorageTypeEx = tab.currentDetail.locStorageTypeExs.join(',');
                } else {
                    tab.currentDetail.locStorageTypeEx = '';
                }
                tab.currentDetail.uom = tab.currentDetail.uoms ? tab.currentDetail.uoms.join(',') : '';

                if (tab.currentDetail.overAllocate && !tab.currentDetail.overAllocateMethod) {
                    toastr.error("请选择超分配方式！");
                    return;
                }
                AllocRule.detailSavePost({}, tab.currentDetail, function (resp) {
                    tab.currentDetail = null;
                    toastr.success('保存成功！', '成功');
                    AllocRule.contentGet({id: tab.data.id}, function (item) {
                        tab.data = item.content;
                    });
                });
            };

            $scope.allocRuleDetailDel = function (tab, detail) {
                if (!detail || !detail.id) return false;
                return {
                    title: '删除确认',
                    message: '确认删除【' + detail.name + '】吗？',
                    ok: function () {
                        AllocRule.detailDeletePost({id: detail.id}, function (result) {
                            if (result.status === 'SUCCESS') {
                                if (tab.currentDetail && tab.currentDetail.id === detail.id) {
                                    delete tab.currentDetail;
                                }
                                tab.data.details.splice(tab.data.details.indexOf(detail), 1);
                                toastr.success('删除成功！', '成功');
                            }
                        });
                    }
                };
            };
        }
    ]);
})();