(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateAppCache', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/appcache', {}, {
                'getAppCacheList': {
                    url: iServiceConfig.iergateHost + '/web/appcache/getAppCacheList',
                    method: 'GET'
                },
                'saveOrUpdateAppCache': {
                    url: iServiceConfig.iergateHost + '/web/appcache/saveOrUpdateAppCache',
                    method: 'POST'
                },
                'deleteAppCache': {
                    url: iServiceConfig.iergateHost + '/web/appcache/deleteAppCache',
                    method: 'POST'
                },
                'applayWarmup': {
                    url: iServiceConfig.iergateHost + '/web/appcache/applayWarmup',
                    method: 'POST'
                }
            });
        }]);
})();