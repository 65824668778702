(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('CaseInventory', CaseInventory);

    CaseInventory.$inject = ['$resource', 'iServiceConfig'];

    function CaseInventory ($resource, iServiceConfig) {
        var service = $resource('web/caseInventory', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/caseInventory/list", method: 'POST'},
            'caseDetail': { url: iServiceConfig.iwhInventoryHost + "/web/caseInventory/caseDetail", method: 'POST'},
        });
        return service;
    }
})();
