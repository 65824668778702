(function (ng) {
    'use strict';

    ng.module('iwh-shipModule').service('BaseCode', BaseCode);

    BaseCode.$inject = ['$http', '$sce', '$localStorage'];
    function BaseCode($http, $sce, $localStorage) {
        this.codes = codes;
        this.codeObjs = codeObjs;
        function codes(urlParams) {
            var pms = {};
            if (urlParams && urlParams.length > 0) {
                angular.forEach(urlParams, function(urlParam) {
                    var url = urlParam.url;
                    var codeField = urlParam.codeField;
                    var labelField = urlParam.labelField;
                    var keyField = urlParam.keyField;
                    pms[keyField] = jsonp(url, codeField, labelField);
                });
            }

            return function(key) {
                if (pms[key]) {
                    return pms[key];
                }

                return new Promise(function(resolve) {
                    resolve({});
                });
            };
        }
        function codeObjs(urlParams) {
            var pms = {};
            if (urlParams && urlParams.length > 0) {
                angular.forEach(urlParams, function(urlParam) {
                    var url = urlParam.url;
                    var keyField = urlParam.keyField;
                    pms[keyField] = jsonpObj(url);
                });
            }

            return function(key) {
                if (pms[key]) {
                    return pms[key];
                }

                return new Promise(function(resolve) {
                    resolve({});
                });
            };
        }
        function jsonpObj(url) {

            if(url.indexOf('?')===-1){
                url = url+'?token='+ $localStorage.authenticationToken;
            }else{
                url =url+'&token='+ $localStorage.authenticationToken;
            }

            var pm = new Promise(function (resolve, reject) {
                $sce.trustAsResourceUrl(url);
                $http.get(url).then(function(response) {
                	if(response.data && response.data.status === 'SUCCESS') {
                        if (angular.isDefined(response.data.content) && angular.isArray(response.data.content) && (response.data.content.length > 0)) {
                            resolve(response.data.content);
                        } else {
                            resolve({});
                        }
                    } else {
                        resolve({});
                    }
                });
            });

            return pm;
        }
        function jsonp(url, codeField, labelField) {
            var codeFieldName = 'code';
            var labelFieldName = 'name';
            if (codeField) {
                codeFieldName = codeField;
            }
            if (labelField) {
                labelFieldName = labelField;
            }

            if(url.indexOf('?')===-1){
                url = url+'?token='+ $localStorage.authenticationToken;
            }else{
                url =url+'&token='+ $localStorage.authenticationToken;
            }

            var pm = new Promise(function (resolve, reject) {
                $sce.trustAsResourceUrl(url);
                $http.get(url).then(function(response) {
                	if(response.data && response.data.status === 'SUCCESS') {
                        if (angular.isDefined(response.data.content) && angular.isArray(response.data.content) && (response.data.content.length > 0)) {
                        	var result = {};
                            angular.forEach(response.data.content, function(item) {
                                result[item[codeFieldName]] = item[labelFieldName];
                            });

                            resolve(result);
                        } else {
                            resolve({});
                        }
                    } else {
                        resolve({});
                    }
                });
            });

            return pm;
        }
    }
}(angular));