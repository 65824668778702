(function() {
	'use strict';

	angular.module('iwh-dataModule').controller('IwhCodeTypeController',
			CodeTypeController);

	CodeTypeController.$inject = [ 'IwhCodeType', 'NgTableParams', '$uibModal',
			 '$timeout', 'IwhCode', 'toastr'];

	function CodeTypeController(IwhCodeType, NgTableParams, $uibModal, 
			 $timeout, IwhCode, toastr) {
		var vm = this;
		vm.modalInstance = null;
		vm.tableParams = null;
		vm.tenantDetail = null;
		vm.tabs = [];
		vm.active = 0;
		vm.search = search;
		vm.removeTab = removeTab;
		
		vm.create = create;
		
		vm.codeTypeEdit = codeTypeEdit;
		vm.codeTypeDetail = codeTypeDetail;
		vm.codeTypeCodeCheck=codeTypeCodeCheck;
	
		vm.codeCreate=codeCreate;
		vm.codeDeleteConfirm=codeDeleteConfirm;
		
		vm.updataCodetype=updataCodetype;
		vm.createConfirm=createConfirm;
		
		vm.NewCodeEdit=NewCodeEdit;
		
		vm.codeUpdata=codeUpdata;
		vm.deleByIds=deleByIds;
		
		vm.deleteData=deleteData;
		vm.confirmDelete=confirmDelete;


        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "启用"},
            {id : -10, title : "禁用"},
        ];

		//删除数据
		function deleteData(row){
			IwhCodeType.delete({
				id : row.id,
				tenantId : row.tenantId,
				warehouseCode : row.warehouseCode,
				},{},function(){
				vm.search();
			});
		}
		
// 通用方法
		function search() {
			vm.tableParams.reload();
		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}
		
//列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
//				console.log(postParams);
				return IwhCodeType.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});

//代码详细
		function codeTypeDetail(row){
			IwhCodeType.detail({}, {
				id : row.id
			}, function(resp) {
				var data = resp.content;
				var entity = {
					heading : '查看' + row.code,
					contentId : "codetype-detail.html",
					data : data,
					active : true
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
				return ;
        		});
		}

//代码类型新建
		function create(){
				var entity = {
					heading : '新建',
					contentId : "codetype-edit.html",
					data : {
						id : "",
						tenantId : "",
						code : "",
						name : "",
						remark : "",
						codeBeanList : []
					},
					NewCode : true,
					tableParams: new NgTableParams({}, {counts:[], getData:function(){return entity.data.codeBeanList}}),
					active : true
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
		}
		
		function codeTypeCodeCheck(tab){
			if(tab.data.code==""||tab.data.code==null){
				toastr.warning("编码不能为空", "警告");
				return;
			}
			IwhCodeType.checkCode({}, {
				code : tab.data.code
			}, function(resp) {
				if(!resp.content){
					toastr.success("编码可以使用", "成功");
				}else{
					toastr.warning("编码已经被使用", "警告")
				}
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
        		});
		}
		
		//代码类型新建保存
		function createConfirm(tab, idx, form){
			var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-save.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						idx : idx,
						form : form
					}
				}
			});
			vm.modalInstance.result.then(function(item) {
				vm.updataCodetype(item.tab, item.idx, item.form);
			});
			return ;
		}
		
		
		//保存类型后台
		function updataCodetype(tab, idx, form){
		//--------------注意	
			var codeTypeBean = tab.data;
			
			var valid = form.$valid;
			if (!valid) {
				form.showMsg = true;
				return;
			}

			IwhCodeType.save({}, codeTypeBean, function(resp) {
				vm.removeTab(idx);
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
				return;
			});
		}
		// 代码新建	
		
		function codeCreate(tab){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'code-new.html',
				controller : newCode,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						codeBean : {
							id : "",
							tenantId : tab.data.tenantId,
							warehouseCode : tab.data.warehouseCode,
							typeCode : tab.data.code,
							status : 0,
							code : "",
							name : "",
							value : "",
							remark: ""
						},
						NewCode : true
					}
				}
			});
			
			
			vm.modalInstance.result.then(function(item) {
				if(item.tab.NewCode==true){
					item.tab.data.codeBeanList.push(item.codeBean);
					item.tab.tableParams.reload();
				}else{
					codeUpdata(item.tab,item.codeBean);
				}
			});
			return;
		}
		
		//新建代码后台请求=
		function codeUpdata(tab,codeBean){
			IwhCode.save({}, codeBean, function(resp) {
				IwhCodeType.detail({}, {
					id : tab.data.id
				}, function(resp) {
					tab.data=resp.content;
					tab.tableParams.reload();
				},function(resp) {
					toastr.error(resp.data.errorMsg,"错误");
					return ;
	        		});
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
				return;
			});
		}
		
		//新建代码的删除
		function codeDeleteConfirm(tab){
			var codeBeanList= tab.tableParams.getSelected();
			if(codeBeanList==""||codeBeanList==null){
				toastr.warning("选择不能为空", "警告");
				return ;
			}
			
			//删除确认
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirm-delete.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab
					}
				}
			});
			
			vm.modalInstance.result.then(function(item) {
				if(item.tab.NewCode==true){
					var codeBeanList= item.tab.tableParams.getSelected();
					angular.forEach(codeBeanList, function(data) {
						var idx = item.tab.data.codeBeanList.indexOf(data);
						item.tab.data.codeBeanList.splice(idx, 1);
					});
					item.tab.tableParams.reload();
				}else{
					deleByIds(item.tab);
				}
			});
			return;
		}
		//代码删除后台
		function deleByIds(tab){
			var codeBeanList= tab.tableParams.getSelected();
			var tenantId = tab.data.tenantId;
			var ids=[];
			angular.forEach(codeBeanList, function(data) {
				ids.push(data.id);
			});
			IwhCode.deleteByIds({}, {tenantId : tenantId, ids : ids}, function(resp) {
				IwhCodeType.detail({}, {
					id : tab.data.id
				}, function(resp) {
					tab.data=resp.content;
					tab.tableParams.reload();
				},function(resp) {
					toastr.error(resp.data.errorMsg,"错误");
					return ;
	        		});
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
				return;
			});	
		}
		
		//新建代码的编辑
		function NewCodeEdit(row, tab){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'code-new.html',
				controller : newCode,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						tab : tab,
						codeBean : {
							id : row.id,
							tenantId : row.tenantId,
							warehouseCode : row.warehouseCode,   //
							typeCode : row.typeCode,
							code : row.code,
							name : row.name,
							value : row.value,
							remark: row.remark
						},
						row : row,
						NewCode : false
					}
				}
			});
			
			vm.modalInstance.result.then(function(item) {
				if(item.tab.NewCode==true){
					item.row.id=item.codeBean.id;
					item.row.tenantId = item.codeBean.tenantId;
					item.row.warehouseCode = item.codeBean.warehouseCode;   //
					item.row.typeCode = item.codeBean.typeCode;
					item.row.name = item.codeBean.name;
					item.row.value = item.codeBean.value;
					item.row.remark = item.codeBean.remark;
					item.tab.tableParams.reload();
				}else{
					codeUpdata(item.tab,item.codeBean);
				}
			});
			return;
		}
		
		//新建代码弹出框
		newCode.$inject = [ '$uibModalInstance', 'item', 'IwhCode', 'toastr'];
		function newCode($uibModalInstance, item, IwhCode, toastr) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;
			$ctrl.yes = yes;

			function yes(form){
//				if($ctrl.item.codeBean.code==""||$ctrl.item.codeBean.code==null){
//					toastr.warning("编码不能为空", "警告");
//					return ;
//				}
//				if($ctrl.item.codeBean.name==""||$ctrl.item.codeBean.name==null){
//					toastr.warning("名称不能为空", "警告");
//					return ;
//				}
				var valid = form.$valid;
	            if (!valid) {
	                form.showMsg = true;
	                return;
	            }
				
				if($ctrl.item.NewCode==false){

					$uibModalInstance.close($ctrl.item);
				}
				if($ctrl.item.NewCode==true){
					IwhCode.checkCode({}, {
						typeCode : $ctrl.item.codeBean.typeCode,
						code : $ctrl.item.codeBean.code
					}, function(resp) {
						if(!resp.content){
							$uibModalInstance.close($ctrl.item);
							return;
						}else{
							toastr.error("编码已存在!");
							return ;
						}
					
					},function(resp) {
						toastr.error(resp.data.errorMsg,"错误");
						return ;
					});
				}else{
					$uibModalInstance.close($ctrl.item);
				}
			}
			
			function ok(form) {
//				if($ctrl.item.codeBean.code==""||$ctrl.item.codeBean.code==null){
//					toastr.warning("编码不能为空", "警告");
//					return ;
//				}
//				if($ctrl.item.codeBean.name==""||$ctrl.item.codeBean.name==null){
//					toastr.warning("名称不能为空", "警告");
//					return ;
//				}
				var valid = form.$valid;
	            if (!valid) {
	                form.showMsg = true;
	                return;
	            }
				var codes = [];
	            angular.forEach($ctrl.item.tab.data.codeBeanList, function(data) {
	            	codes.push(data.code);
	            });
	            if($ctrl.item.NewCode==true){
	            	if(codes.indexOf($ctrl.item.codeBean.code)!=-1){
	            		toastr.error("编码已存在!");
	            		return ;
	            	}
	            }
				IwhCode.checkCode({}, {
					code : $ctrl.item.codeBean.code
				}, function(resp) {
					if(!resp.content){
						$uibModalInstance.close($ctrl.item);
					}else{
						toastr.error("编码已存在");
						return ;
					}
				},function(resp) {
					toastr.error(resp.data.errorMsg,"错误");
					return ;
	        		});
			}
			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}
		
		//类型编辑
		function codeTypeEdit(row){
			IwhCodeType.detail({}, {
				id : row.id
			}, function(resp) {
				var data = resp.content;
				var entity = {
					heading : '编辑' + row.code ,
					contentId : "codetype-edit.html",
					data : data,
					NewCode : false,
					tableParams: new NgTableParams({}, {counts:[],getData:function(){return entity.data.codeBeanList}}),
					active : true
				};
				vm.tabs.push(entity);
				$timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
			},function(resp) {
				toastr.error(resp.data.errorMsg,"错误");
        		});
			return;
		}
		
	//确认弹出窗
		confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;
			function ok() {
				$uibModalInstance.close($ctrl.item);
			}
			function cancel() {
				$uibModalInstance.dismiss('cancel');  }
		}
		
		//确认删除
		 function confirmDelete(row) {
	            vm.modalInstance = $uibModal.open({
	                templateUrl: 'confirm-delete.html',
	                controller: deleteModal,
	                controllerAs: '$ctrl',
	                resolve: {
	                    item: function () {
	                        return row;
	                    }
	                }
	            });
	            vm.modalInstance.result.then(function(item) {
	                vm.deleteData(item);
	            });
	        }
		 
		 
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;
	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }
	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }

        vm.enable = enable;
        function enable(row){
            IwhCodeType.enable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("启用成功");
                    vm.tableParams.reload();
                }
            });
        }
        vm.disable = disable;
        function disable(row){
            IwhCodeType.disable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    toastr.success("禁用成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.codeEnable = codeEnable;
        function codeEnable(row,t){
            IwhCode.enable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    IwhCodeType.detail({}, {
                        id : t.data.id
                    }, function(resp) {
                        t.data.codeBeanList = resp.content.codeBeanList;
                        t.tableParams.reload();
                    });

                    toastr.success("启用成功");

                }
            });
        }
        vm.codeDisable = codeDisable;
        function codeDisable(row,t){
            IwhCode.disable({}, row.id, function(resp) {
                if(resp.content=="success"){
                    IwhCodeType.detail({}, {
                        id : t.data.id
                    }, function(resp) {
                        t.data.codeBeanList = resp.content.codeBeanList;
                        t.tableParams.reload();
                    });
                    toastr.success("禁用成功");
                }
            });
        }
		
	}
})();
