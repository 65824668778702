(function() {
    'use strict';

    angular
        .module('ienterpriseModule')
        .factory('InventoryQuery', InventoryQuery);

    InventoryQuery.$inject = ['$resource', 'iServiceConfig'];

    function InventoryQuery ($resource, iServiceConfig) {
        var service = $resource('/web/invQuery', {}, {
            'querySku': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/sku", method: 'POST'},
            'queryOwner': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/owner", method: 'POST'},
            'queryWarehouse': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/wh", method: 'POST'},
            'queryLot': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/lot", method: 'POST'},
            'tranSku': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/tranSku", method: 'POST'},
            'tranOwner': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/tranOwner", method: 'POST'},
            'tranWh': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/tranWh", method: 'POST'},
            'queryTran': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/tran", method: 'POST'},
            'skuInventory': { url: iServiceConfig.ienterpriseHost + "/web/invQuery/skuInventory", method: 'POST'},

        });
        return service;
    }
})();
