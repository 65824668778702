(function() {
    'use strict';

    angular.module('iuserModule')
        .controller('UserModuleRoleController', UserModuleRoleController);

    UserModuleRoleController.$inject = ['UserModuleRole', 'NgTableParams','$uibModal', 'Principal','$timeout','NgTree','toastr'];

    function UserModuleRoleController(UserModuleRole, NgTableParams, $uibModal, Principal,$timeout,NgTree,toastr) {
        var vm = this;
        vm.modalInstance = null;
        vm.tableParams = null;
        
        vm.tree = null;
        
        vm.tabs = [];
        vm.active = 0;
        vm.account = {};
        vm.status = [{'id':0, 'title':'已禁用'},{'id':10, 'title':'已启用'}];
        vm.search = search;
        vm.edit = edit;
        vm.create = create;
        vm.showRole=showRole;
        vm.update = update;
        vm.deleteData = deleteData;
        vm.removeTab = removeTab;
        vm.confirmDelete = confirmDelete;
        vm.changeStatus=changeStatus;
        vm.changeData = changeData;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return UserModuleRole.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.page.tc);
                    }).$promise.then(function(data) {
                    var datas = data.content.page.datas;
                    if(datas) {
                    	for(var i=0;i<datas.length;i++){
                            var flag = datas[i].roleCode.indexOf("admin@");
                            if (flag==0){
                                datas[i].admin = true;
                                break;
                            }
                        }
                    }
                    
                    vm.tenantId = data.content.tenantId;
                    return datas;
                });
            }
        });
        
        function search() {
            vm.tableParams.reload();
        }

        function deleteData(row){
            UserModuleRole.delete({}, {roleId: row.roleId}, function() {
                vm.search();
            });
        }
        
        function changeData(row){
        	if(row.status > 1) {
        		UserModuleRole.disable({}, {
            		roleId: row.roleId
            	},function(resp){
                    if(resp.status=="SUCCESS"){
                        toastr.success("禁用成功！");
                        vm.search();
                    }
            	});
        	} else {
        		UserModuleRole.enable({}, {
        			roleId: row.roleId
            	},function(resp){
                    if(resp.status=="SUCCESS"){
                        toastr.success("禁用成功！");
                        vm.search();
                    }
            	});
        	}
        }
        function changeStatus(row){
            vm.modalInstance = $uibModal.open({
            	templateUrl: 'role-disableAndEnable.html',
                controller: changeModal,
                controllerAs: '$ctrl',
                resolve: {
                		item: function () {
                				return row;
                        	}
                    	}
                });	
            vm.modalInstance.result.then(function(item) {
                vm.changeData(item);
            });
        }
        
        changeModal.$inject = ['$uibModalInstance', 'item'];
        function changeModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        
        function showRole(row){
        	UserModuleRole.detail({},{roleId: row.roleId},function(resp){
        		if(resp.status === 'ERROR') {
        			alert(resp.errorMsg);
        			return;
        		}
        		var data = resp.content;
        		console.log(row.tenantId);
        		UserModuleRole.authTreeData({},row.tenantId,function(res){
        			if(resp.status === 'ERROR') {
            			alert(resp.errorMsg);
            			return;
            		}
        			var treeData = res.content;
        			var entity = {
                            heading: '查看'+data.roleCode,
                            contentId: "role-showRole.html",
                            data: data,
                            roles: [],
                            treeModel: new NgTree({
        	       	             data :treeData,
        	       	             selectedNodeIds : data.authNodeIds,
        	    	             config:{
        	    	                 meta:{
        	    	                     titleField : 'title',
        	    	                     parentIdField : 'parentId',
        	    	                     nodeIdField : 'nodeId',
        	    	                     childField : 'children',
        	    	                     needCheck: true
        	    	                 }
        	    	             }
        	    	         }),
                            active: true
                        };
                	
            			vm.tabs.push(entity);
            			$timeout(function(){
                            vm.active = vm.tabs.length;
                        },0);
        		});
        	});
        }
        	
        function edit(row) {
        	UserModuleRole.detail({}, {roleId: row.roleId}, function(resp) {
        		if(resp.status === 'ERROR') {
         			alert(resp.errorMsg);
         			return;
         		}
        		 var data = resp.content;
        		 UserModuleRole.authTreeData({},row.tenantId,function(res){
         			if(res.status === 'ERROR') {
             			alert(res.errorMsg);
             			return;
             		}
         			var treeData = res.content;
         			var entity = {
                             heading: '编辑'+data.roleCode,
                             contentId: "role-eidt.html",
                             data: data,
                             roles: [],
                             treeModel: new NgTree({
         	       	             data :treeData,
         	       	             selectedNodeIds : data.authNodeIds,
         	    	             config:{
         	    	                 meta:{
         	    	                     titleField : 'title',
         	    	                     parentIdField : 'parentId',
         	    	                     nodeIdField : 'nodeId',
         	    	                     childField : 'children',
         	    	                     needCheck: true
         	    	                 }
         	    	             }
         	    	         }),
                             active: true
                         };
                 	
             			vm.tabs.push(entity);
             			$timeout(function(){
                             vm.active = vm.tabs.length;
                         },0);
         		});
        	});
        }
        function create() {
        	UserModuleRole.authTreeData({}, vm.tenantId, function(resp) {
        		if(resp.status === 'ERROR') {
        			alert(resp.errorMsg);
        			return;
        		}
        		var treeData = resp.content;
	            var entity = {
	                heading: '新建',
	                contentId: "role-eidt.html",
	                
	                treeModel: new NgTree({
	                    data : treeData,
	                    config:{
	                        meta:{
	                            titleField : 'title',
	                            parentIdField : 'parentId',
	                            nodeIdField : 'nodeId',
	                            childField : 'children',
	                            needCheck: true
	                        }
	                    }
	                }),
	                data: {
	                    tenantCompanyName: vm.account.tenantName,
	                    roleCode:"",
	                    roleName:""
	                },
	                active: true
	            };
            
	            vm.tabs.push(entity);
	            $timeout(function(){
	                vm.active = vm.tabs.length;
	            },0);
        	});
        }

        function update(tab, idx, form) {
            var role = tab.data;
            console.log(tab.data);
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                toastr.error("请填写完整数据", '错误');
                return;
            }
            role.newNodeIds=tab.treeModel.getSelectedIds();
            role.selectNodeIds = role.authNodeIds;
            console.log(role.selectNodeIds);
            console.log(role.newNodeIds);
            UserModuleRole.save({}, role, function(resp) {
            	if(resp.status === 'ERROR') {
        			toastr.error("角色编码已经存在，请重新输入",'错误');
        			return;
        		}
                vm.removeTab(idx);
            });
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }

        function confirmDelete(row) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'deleteUserModal.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: function () {
                        return row;
                    }
                }
            });

            vm.modalInstance.result.then(function(item) {
                vm.deleteData(item);
            });
        }

        deleteModal.$inject = ['$uibModalInstance', 'item'];
        function deleteModal($uibModalInstance, item) {
            var $ctrl = this;
            $ctrl.item = item;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

    }
})();
