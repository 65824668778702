(function() {
    'use strict';

    angular.module('idataModule')
        .controller('SkuCategoryController', SkuCategoryController);

    SkuCategoryController.$inject = ['SkuCategory', '$uibModal', 'NgTree', 'toastr'];

    function SkuCategoryController(SkuCategory, $uibModal, NgTree, toastr) {
		var vm = this;
		vm.modalInstance = null;
		vm.addNode = {};
		vm.curNode = {};
		vm.selectNode = selectNode;
		vm.contentId = "noSelect.html";
		vm.createSkuCategory = createSkuCategory;
		vm.updataConfirm=updataConfirm;
		vm.createChildSkuCa=createChildSkuCa;
		vm.createBrotherSkuCa=createBrotherSkuCa;
		vm.editeNode=editeNode;
		vm.delNodeConfirm=delNodeConfirm;
		vm.delNode=delNode;
//	--------------------------------------	
		vm.refreshTree = refreshTree;
		vm.addChild = addChild;
		vm.refreshTree();
		function addChild(node) {
			if (node !== null) {
				vm.tree.addNode(node);
			}
		}
// 树
		vm.tree = new NgTree({
			data : [],
			config : {
				meta : {
					titleField : 'title',
					parentIdField : 'parentId',
					nodeIdField : 'nodeId',
					childField : 'children',
				}
			}
		});
		//刷新树
		function refreshTree() {
			SkuCategory.treeData({}, {}, function(resp) {
				if (resp.content) {
					vm.tree.data = resp.content;
				}
			});
		}
		
//		
		function selectNode(node) {
			vm.curNode = node;
			vm.editeNode(node);
			
		}
//  -------------新建弹出框	
		function createSkuCategory() {
			vm.modalInstance = $uibModal.open({
				templateUrl : 'createSkuCategory.html',
				controller : createSkuCategoryModle,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						pId : null,
						pName : null,
						isNew : true
					}
				}
			});
//			vm.modalInstance.validator.then(function(item) {
//				vm.refreshTree();
//			});
		}
		
		createSkuCategoryModle.$inject = [ '$uibModalInstance', 'item']
		function createSkuCategoryModle($uibModalInstance,item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = function(){
				$uibModalInstance.close($ctrl.item);
			};
			$ctrl.response = {};
			$ctrl.childNode = {
				pName : item.pName,
				data :{ 
					parentId : $ctrl.item.pId
				}
			};
			$ctrl.cancel = function(){
				$uibModalInstance.dismiss('cancel');
			}
			
			$ctrl.submitSkuCa = function(childNode,form) {
				var valid = form.$valid;
				if (!valid) {
					form.showMsg = true;
					return;
				}
				var temp = saveNew(childNode.data);
				temp.then(function(resp) {
					if (resp.status == 'SUCCESS') {
						if(!vm.tree.data || vm.tree.data.length == 0) {
							vm.refreshTree();
						} else {
							var skuca = resp.content;
							var nodeData = {title : skuca.name,
											parentId : skuca.parentId,
											nodeId : skuca.id,
											children : [],
											data: skuca};
							vm.tree.addNode(nodeData);
						}
						$ctrl.ok();
					}
					return ;
					
				});
			}
		}
		function saveNew(curNode) {
			return SkuCategory.saveNew({}, curNode).$promise
					.then(function(resp) {
						toastr.success("添加成功", '操作结果');
						return resp;
					}, function(resp) {
						return resp.data;
					});
		}
		function updata(curNode) {
			return SkuCategory.updata({}, curNode.data).$promise
					.then(function(resp) {
						toastr.success("添加成功", '操作结果');
						return resp;
					}, function(resp) {
						return resp.data;
					});
		}
		//更新节点
		function updataConfirm(curNode,form) {
			var valid = form.$valid;
			if (!valid) {
				form.showMsg = true;
				return;
			}
			
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						curNode : curNode ,
						save : true
					}
				}
			});
			
			vm.modalInstance.result.then(function(item) {
			 var temp = updata(item.curNode);
				temp.then(function(resp) {
					item.curNode.title = resp.content.name;
					item.curNode.data = resp.content;
					vm.tree.updateNode(vm.curNode, item.curNode);
				});
			});
		}
//		新增子节点
		function createChildSkuCa(pNode){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'createSkuCategory.html',
				controller : createSkuCategoryModle,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						pId : pNode.data.id,
						pName : pNode.title,
						isNew : true
					}
				}
			});
//			vm.modalInstance.validator.then(function(item) {
//				vm.refreshTree();
//			});
		}
//	新增兄弟节点
		function createBrotherSkuCa(bNode){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'createSkuCategory.html',
				controller : createSkuCategoryModle,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						pId : bNode.data.parentId,
						pName : bNode.data.pName,
						isNew : true
					}
				}
			});
//			vm.modalInstance.validator.then(function(item) {
//				vm.refreshTree();
//			});
		}
		
		//确认模板
		confirmOperation.$inject = [ '$uibModalInstance', 'item' ];
		function confirmOperation($uibModalInstance, item) {
			var $ctrl = this;
			$ctrl.item = item;
			$ctrl.ok = ok;
			$ctrl.cancel = cancel;
			function ok() {
				$uibModalInstance.close($ctrl.item);
			}
			function cancel() {
				$uibModalInstance.dismiss('cancel');
			}
		}
//		编辑节点
		function editeNode(node){
			SkuCategory.detail({}, {
				id : node.data.id
				}, function(resp) {
					vm.contentId = "editeSkuCa.html";
					vm.curNode.data = resp.content;
				});
		}
		
//		删除确认
		function delNodeConfirm(curNode){
			vm.modalInstance = $uibModal.open({
				templateUrl : 'confirmOperation.html',
				controller : confirmOperation,
				controllerAs : '$ctrl',
				resolve : {
					item : {
						curNode : curNode,
						save : false
					}
				}
			});
			
			vm.modalInstance.result.then(function(item) {
				vm.delNode(item.curNode);
				});
		}
		
		function delNode(curNode){
			SkuCategory.del({}, {
				id : curNode.data.id
				}, function(resp) {
					toastr.success("删除成功", '操作结果');
					vm.contentId = "noSelect.html";
					vm.refreshTree();
				},function(resp) {
					toastr.error(resp.errorMsg, '错误');
				});
		}
		
	}
})();
