(function () {
    'use strict';

    angular.module('ienterpriseModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('iEptSupplySettlementDetail', {
                parent: 'main',
                url: 'enterprise/supplySettlementDetail?settlementNum=',
                params: {'settlementNum': null},
                data: {
                    'pageTitle': '生成供应商结算单',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'enterprise-module/settle/SupplySettlementDetail.html',
                        controller: 'iEptSupplySettlementDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
