(function() {
    'use strict';

    angular.module('iwh-shipModule')
        .controller('DeliveryOrderController', DeliveryOrderController);

    DeliveryOrderController.$inject = ['$rootScope', 'ApiURI', 'iServiceConfig', 'DeliveryOrder','$timeout', 'NgTableParams','enums','toastr','scModal','$uibModal','Principal', 'BaseCode'];

    function DeliveryOrderController ($rootScope, ApiURI, iServiceConfig, DeliveryOrder, $timeout, NgTableParams,enums,toastr,scModal,$uibModal,Principal, BaseCode) {
        var vm = this;
        vm.active = 0;
        vm.tabs = [];
        vm.removeTab = removeTab;

        vm.searchDo = searchDo;
        vm.addDo = addDo;
        vm.editDo = editDo;
        vm.transferDo = transferDo;
        vm.deliverDo = deliverDo;
        vm.cancelDo = cancelDo;
        vm.saveAndUpdateDelivery = saveAndUpdateDelivery;
        vm.transferOne = transferOne;
        vm.deliverOne = deliverOne;
        vm.cancelOne = cancelOne;
        
        vm.BoxStatusObj = enums('ship.BoxStatus');
        vm.BoxStatus = vm.BoxStatusObj.list('title', 'id');

        vm.processStatusObj = enums('ship.ProcessStatus');
        vm.processStatus = vm.processStatusObj.list('title', 'id');
        vm.DeliveryStatusObj = enums('ship.DeliveryStatus');
        vm.DeliveryStatus = vm.DeliveryStatusObj.list('title', 'id');
        
        vm.baseCode = BaseCode.codes([
          {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'}
        ]);
        
        vm.baseCodeObjs = BaseCode.codeObjs([
          {url: iServiceConfig.iwhDataHost + ApiURI.getCodeList + 'WMS_SO_TYPE', keyField: 'WMS_SO_TYPE'}
        ]);
        
        vm.soTypes = {};
        vm.soTypeList = [];
        vm.baseCode('WMS_SO_TYPE').then(function(data) {
            vm.soTypes = data;
        });
        
        vm.baseCodeObjs('WMS_SO_TYPE').then(function(data) {
        	angular.forEach(data, function (item) {
        		vm.soTypeList.push({ title: item.name, id: item.code });
            });
        });
        
        vm.userData = {};
        Principal.identity().then(function(rdata) {
            vm.userData = rdata;
        });
        //列表页功能
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return DeliveryOrder.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });


        vm.addBoxes = addBoxes;
        vm.cancelBox = cancelBox;
        vm.buildParam = function buildParam(deliveryNum) {
        	var param = null;
            if ( undefined == vm.userData || !vm.userData.tenantId) {
                toastr.error("获取用户登录信息失败");
            }
            if (deliveryNum) {
                param = {
                	operatorName : vm.userData.realName,
                    tenantId : vm.userData.tenantId,
                    warehouseCode : vm.userData.warehouseCode,
                    deliveryNum : deliveryNum
                };
            } else {
            	var list = vm.tableParams.getSelected();
            	if (list.length > 0) {
                    param = [];
                    for(var i=0;i<list.length;i++){
                        param.push({
                        	operatorName : vm.userData.realName,
                            tenantId : vm.userData.tenantId,
                            warehouseCode : vm.userData.warehouseCode,
                            deliveryNum : list[i].deliveryNum
                        });
                    }
            	}
            }
            return param;
        }
        function searchDo() {
            vm.tableParams.reload();
        }

        function addDo() {
            DeliveryOrder.getDeliverNum({}, {}, function(resp) {
                var deliveryNum = resp.content;
                var tab = {
                    heading: '新增',
                    contentId: 'do-edit.html',
                    data: {
                        deliveryOrder: {
                            deliveryNum : deliveryNum
                        }
                    }

                };
                vm.tabs.push(tab);
                switchTab(vm.tabs.length);
            });
        }

        function saveAndUpdateDelivery(t, isChooseBox) {
            var vo = t.data.deliveryOrder;
            if (t.data.carrierObject) {
                vo.carrierName = t.data.carrierObject.name;
                vo.carrierAddress = t.data.carrierObject.address;
                vo.carrierPhone = t.data.carrierObject.phone;
            }
            vo.carrierDeliveryNum = t.data.carrierDeliveryNum;
            if (t.data.deliveryOrder && t.data.deliveryOrder.carrierProvinceId) {
                t.data.deliveryOrder.carrierCountryId = 'CN';
            }
            DeliveryOrder.saveAndUpdateDelivery({}, vo, function(resp) {
                if(!t.data.deliveryOrder.id){
                    if(resp.content==="success"){
                        toastr.success("保存成功");
                        if (isChooseBox) {
                            vm.confirmAddBox(t);
                        }
                    }else{
                        return;
                    }

                    DeliveryOrder.getDelivery({}, t.data.deliveryOrder.deliveryNum, function(resp) {
                        t.data.deliveryOrder = resp.content;
                        if (t.data.deliveryOrder) {
                            if (!t.data.carrierObject) {
                                t.data.carrierObject = {};
                            }
                            t.data.carrierObject.address = t.data.deliveryOrder.carrierAddress;
                            t.data.carrierObject.phone = t.data.deliveryOrder.carrierPhone;
                            t.data.carrierObject.name = t.data.deliveryOrder.carrierName;
                            t.data.carrierDeliveryNum = t.data.deliveryOrder.carrierDeliveryNum;
                        }
                    });
                }else{
                    if(resp.content==="success"){
                        toastr.success("更新成功");
                    }else{
                        return;
                    }
                    DeliveryOrder.getDelivery({}, t.data.deliveryOrder.deliveryNum, function(resp) {
                        t.data.deliveryOrder = resp.content;
                        if (t.data.deliveryOrder) {
                            if (!t.data.carrierObject) {
                                t.data.carrierObject = {};
                            }

                            t.data.carrierObject.address = t.data.deliveryOrder.carrierAddress;
                            t.data.carrierObject.phone = t.data.deliveryOrder.carrierPhone;
                            t.data.carrierObject.name = t.data.deliveryOrder.carrierName;
                            t.data.carrierDeliveryNum = t.data.deliveryOrder.carrierDeliveryNum;
                        }
                    });
                }
            });
        }

        vm.reloadDelivery = reloadDelivery;
        function reloadDelivery(row,t){
            DeliveryOrder.edite({}, row.id, function(resp) {
                var vo = resp.content;
                t.data.boxes = vo.boxList;
                t.data.deliveryOrder = vo.deliveryOrder;
                t.data.carrierDeliveryNum = vo.deliveryOrder.carrierDeliveryNum;
                t.data.carrierObject = {
                    address : vo.deliveryOrder.carrierAddress,
                    name : vo.deliveryOrder.carrierName,
                    phone : vo.deliveryOrder.carrierPhone
                }
            });
        }
        function editDo(row,isCheck) {
            DeliveryOrder.edite({}, row.id, function(resp) {
                var vo = resp.content;
                var tab = {
                    heading: isCheck?'查看':'编辑',
                    contentId: 'do-edit.html',
                    isCheck:isCheck,
                    data: {
                        boxes : vo.boxList,
                        deliveryOrder: vo.deliveryOrder,
                        carrierDeliveryNum: vo.deliveryOrder.carrierDeliveryNum,
                        carrierObject : {
                            address : vo.deliveryOrder.carrierAddress,
                            name : vo.deliveryOrder.carrierName,
                            phone : vo.deliveryOrder.carrierPhone
                        }
                    }
                };

                vm.tabs.push(tab);
                switchTab(vm.tabs.length);
            });
        }

        function transferDo(order) {
            if (order.length==0) {
                toastr.error("请先选择发货单!");
                return;
            }
            var ids= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status!=vm.DeliveryStatusObj.value('NEW')){
                    toastr.error("新建状态下才能交接！");
                    return;
                }
                ids.push(order[i].id);
            }
            DeliveryOrder.transfer({}, ids, function(resp) {
                if(resp.content==="success"){
                    toastr.success("交接成功");
                    vm.tableParams.reload();
                }
            });

        }

        function transferOne(row, tab) {
            var ids= [];
            if(row.status!=vm.DeliveryStatusObj.value('NEW')){
                toastr.error("新建状态下才能交接");
                return;
            }
            ids.push(row.id);

            DeliveryOrder.transfer({}, ids, function(resp) {
                if(resp.content==="success"){
                    toastr.success("交接成功");
                    if (tab) {
                        vm.reloadDelivery(row, tab);
                    }
                    vm.tableParams.reload();
                }
            });
        }

        function deliverDo(order) {
            if (order.length==0) {
                toastr.error("请先选择发货单!");
                return;
            }
            var ids= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status==vm.DeliveryStatusObj.value('NEW') || order[i].status==vm.DeliveryStatusObj.value('TRANSFER')){
                    ids.push(order[i].id);
                }else{
                    toastr.error("新建或交接状态下才能发货！");
                    return;
                }

            }
            DeliveryOrder.delivery({}, ids, function(resp) {
                toastr.success(resp.content);
                vm.tableParams.reload();
            });
        }

        function deliverOne(row, tab) {
            var ids= [];
            if(row.status==vm.DeliveryStatusObj.value('NEW')||row.status==vm.DeliveryStatusObj.value('TRANSFER')){
            }else{
                toastr.error("新建或交接状态下才能交接");
                return;
            }
            ids.push(row.id);

            DeliveryOrder.delivery({}, ids, function(resp) {
                toastr.success(resp.content);
                if (tab) {
                    vm.reloadDelivery(row, tab);
                }
                vm.tableParams.reload();
            });
        }

        function cancelDo(order) {
            if (order.length===0) {
                toastr.error("请先选择发货单!");
                return;
            }
            var ids= [];
            for(var i=0;i<order.length;i++){
                if(order[i].status!=vm.DeliveryStatusObj.value('NEW')){
                    toastr.error("新建状态下才能取消！");
                    return;
                }
                ids.push(order[i].id);
            }
            DeliveryOrder.cancel({}, ids, function(resp) {
                if(resp.content==="success"){
                    toastr.success("取消成功");
                    vm.tableParams.reload();
                }
            });
        }

        function cancelOne(row, tab) {
            var ids= [];
            if(row.status!=vm.DeliveryStatusObj.value('NEW')){
                toastr.error("新建状态下才能取消");
            }
            ids.push(row.id);

            DeliveryOrder.cancel({}, ids, function(resp) {
                if(resp.content==="success"){
                    toastr.success("取消成功");
                    if (tab) {
                        vm.reloadDelivery(row, tab);
                    }
                    vm.tableParams.reload();
                }
            });
        }

        function addBoxes(boxes,deliveryOrder,t, boxTables) {
            if (boxes.length===0){
                toastr.error("请先选择订单");
                return;
            }
            var vo ={
                deliveryNum: deliveryOrder.deliveryNum ,
                boxList : boxes
            };
            DeliveryOrder.saveBox({}, vo, function(resp) {
                if(resp.content==="success"){
                    toastr.success("添加成功");
                    vm.reloadDelivery(deliveryOrder,t);

                    if (boxTables && boxTables.reload) {
                        boxTables.reload();
                    }
                }
            });

        }

        function cancelBox(row,deliveryOrder,t) {
            DeliveryOrder.cancelOneBox({}, row.id, function(resp) {
                if(resp.content==="success"){
                    toastr.success("取消成功");
                    vm.reloadDelivery(deliveryOrder,t);
                }
            });
        }

        function removeTab(idx) {
            if (vm.tabs.length - idx > 0) {
                vm.tabs.splice(idx, 1);
                switchTab(idx);
            }
        }

        function switchTab(idx) {
            $timeout(function() {
                vm.active = idx;
            }, 0);
        }


    vm.confirmAddBox = confirmAddBox;
    function confirmAddBox(t) {
        vm.modalInstance = $uibModal.open({
            templateUrl: 'add-boxes.html',
            controller: addBoxesModal,
            size: 'xl',
            backdrop: 'static',
            controllerAs: '$ctrl',
            resolve: {
                item : t
            }
        });
    }
    addBoxesModal.$inject = ['$uibModalInstance', 'item'];
    function addBoxesModal($uibModalInstance, item) {
        var $ctrl = this;
        $ctrl.item = item;
        $ctrl.ok = ok;
        $ctrl.cancel = cancel;
        $ctrl.soTypes = vm.soTypes;
        $ctrl.soTypeList = vm.soTypeList;
        $ctrl.BoxStatus = vm.BoxStatus;
        $ctrl.BoxStatusObj = vm.BoxStatusObj;
        console.log($ctrl.BoxStatus, "$ctrl.BoxStatus")
        console.log($ctrl.BoxStatusObj, "$ctrl.BoxStatusObj")
        $ctrl.boxesParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return DeliveryOrder.pageForDelivery({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        $ctrl.addBoxes = addBoxes;

        function addBoxes() {
            var boxes = $ctrl.boxesParams.getSelected();
            vm.addBoxes(boxes, $ctrl.item.data.deliveryOrder, $ctrl.item, $ctrl.boxesParams);
        }

        function ok() {
            $uibModalInstance.close($ctrl.item);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }


        vm.toggle = toggle;
        function toggle(row,o){
            row[o] = !row[o];
        }

}
})();
