(function() {
    'use strict';

    angular.module('iwh-innerModule').controller('CountPlanController',
        CountPlanController);

    CountPlanController.$inject = [ 'CountPlan','NgTableParams', '$uibModal','enums',
        '$timeout', 'toastr','$state'];

    function CountPlanController(CountPlan,NgTableParams, $uibModal,enums,
                                 $timeout, toastr,$state) {
        var vm = this;
        vm.tabs = [];
        vm.active = 0;
        vm.typeObj = enums('inner.CountPlanType');
        vm.type = vm.typeObj.list('title', 'id');

        vm.statusObj = enums('inner.CountPlanStatus');
        vm.status = vm.statusObj.list('title', 'id');

        vm.processStatusObj = enums('inner.CountPlanProcessStatus');
        vm.processStatus = vm.processStatusObj.list('title', 'id');



        vm.yesOrNoObj = enums('inner.YesOrNo');
        vm.yesOrNo = vm.yesOrNoObj.list('title', 'id');

        vm.countPlanLogTypeObj = enums('inner.CountPlanLogType');
        vm.countPlanLogType = vm.countPlanLogTypeObj.list('title', 'id');

        vm.rows = [{'id': 1, 'name': 'xjashj'}, {'id': 1, 'name': 'xjashj'}, {'id': 1, 'name': 'xjashj'}, {'id': 1, 'name': 'xjashj'}];
        vm.columns = ['id','name'];
        vm.createOrEdit = true;
        vm.search = search;

        vm.removeTab = removeTab;

        vm.create=create;//新建

        vm.save = save;//保存

        vm.edit = edit;//编辑

        vm.execute = execute;//单次执行

        vm.begin = begin;//开始执行

        vm.cancel = cancel;//取消

        vm.close = close;//关闭

        vm.formatDateTime =formatDateTime;

        // 通用方法
        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.active=idx;
            vm.tabs.splice(idx, 1);
        }

        function formatDateTime(dt) {
            var date = new Date(dt);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            return y + '-' + m + '-' + d+' '+h+':'+minute+':'+seconds;
        }

        //列表页功能
        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());

                return CountPlan.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

        //新建
        function create(){
            CountPlan.getPlanNum({},{},function(resp){
            }).$promise.then(function(data){
                var entity = {
                    isCreate:true,
                    heading : '新建',
                    contentId : "editOrCreate.html",
                    data : {
                        planNum:data.content,
                        type: 20,
                        dynamic:1,
                        blind:1,
                        zeroInventory:1,
                        executeMethod:1,
                        executeRate:null,
                        minInventoryQty:0,
                        minTaskCount:0,
                        regions:null,
                        zones:null,
                        locs:null,
                        locAbcs:null,
                        locStorageTypes:null,
                        owners:null,
                        skus:null,
                        skuAbcs:null,
                        skuCategorys:null,
                        status:0,
                        remark:null
                    },
                    info:null,
                    active : true
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }

        //保存
        function save(t,index,form){
            var valid = form.$valid;
            if (!valid) {
                form.showMsg = true;
                return;
            }
            if(t.data.executeMethod===1 && !t.data.executeRate){
                toastr.info("请先选择执行频率", "提示");
                return;
            }
            if(t.data.type==10){
                if(t.data.regions==null&&t.data.zones==null&&t.data.locs==null){
                    t.info="区域 库区 库位 请至少选择一个";
                    return;
                }
            }else {
                if(t.data.regions==null&&t.data.zones==null&&t.data.locs==null&&t.data.owners==null&&t.data.skuAbcs==null){
                    t.info="区域 库区 库位 货主 商品ABC 请至少选择一个";
                    return;
                }
            }
            CountPlan.saveAndUpdate({},t.data,function(resp){
            }).$promise.then(function(data){
                t.isCreate?toastr.success("新增成功", "成功"):toastr.success("保存成功", "成功");
                vm.removeTab(index);
            });
        }

        //编辑
        function edit(row){
            CountPlan.edit({},row.id,function(resp){
            }).$promise.then(function(data){
                var entity = {
                    isCreate:false,
                    heading : '编辑【'+row.planNum+'】',
                    contentId : "editOrCreate.html",
                    countType : vm.countType,
                    statusType:vm.statusType,
                    tableParams:new NgTableParams({
                        count : 10
                    }, {
                        getData : function(params) {
                            var postParams = angular.merge({
                                pn : params.page(),
                                ps : params.count()
                            }, params.filter());
                            postParams.planNum = row.planNum;
                            return CountPlan.getCountPlanLog({}, postParams, function(resp) {
                                params.total(resp.content.tc);
                            }).$promise.then(function(data) {
                                var datas = data.content.datas;
                                return datas;
                            });
                        }
                    }),
                    data: data.content,
                    active : true
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            })
        }

        //单次执行
        function execute(row){
            var temp = [];
            if(row && row.planNum){
                if(row.processStatus==10){
                    toastr.error("该单正在执行请稍后再试！");
                    return;
                }
                temp.push(row.planNum);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].processStatus==10){
                            toastr.error("有单据正在执行,单号为："+list[i].planNum);
                            return;
                        }
                        if(list[i].planNum){
                            temp.push(list[i].planNum);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                CountPlan.execute({},temp,function(resp){
                }).$promise.then(function(data){
                    var con = data.content;
                    var s1 = "开始执行";
                    var s2 = '成功';
                    if(angular.isArray(con) && con.length>0){
                        s1 = "";
                        s2="以下计划单号单：";
                        for(var i=0;i<con.length;i++){
                            s1+=i+1+'.  '+con[i]+' <br> ';
                        }
                        s2 = s2+" 已开始异步执行，请稍后刷新页面查看执行结果";
                    }
                    toastr.success(s1, s2);
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择计划单", "提示");
            }
        }

        //开始
        function begin(row){
            var temp = [];
            if(row && row.planNum){
                temp.push(row.planNum);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].executeMethod==2){
                            toastr.error("","循环执行的单才能开始！单号为："+list[i].planNum);
                            return;
                        }
                        if(list[i].planNum){
                            temp.push(list[i].planNum);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                CountPlan.begin({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("开始执行", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择计划单", "提示");
            }
        }

        //取消
        function cancel(row){
            var temp = [];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                CountPlan.cancel({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("取消成功", "成功");
                     vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择计划单", "提示");
            }
        }

        //关闭
        function close(row){
            var temp = [];
            if(row && row.id){
                temp.push(row.id);
            }else{
                if(angular.isFunction(vm.tableParams.getSelected)){
                    var list = vm.tableParams.getSelected();
                    for(var i=0;i<list.length;i++){
                        if(list[i].id){
                            temp.push(list[i].id);
                        }
                    }
                }
            }

            if(angular.isArray(temp) && temp.length>0){
                CountPlan.closed({},temp,function(resp){
                }).$promise.then(function(data){
                    toastr.success("关闭成功", "成功");
                    vm.tableParams.reload();
                });
            }else{
                toastr.info("请先选择计划单", "提示");
            }
        }

    }
})();
