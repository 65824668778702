(function () {
    'use strict';

    angular.module('iplanModule')
        .config(config);

    config.$inject = ['$stateProvider'];

    function config ($stateProvider) {
        $stateProvider
            .state('iPlandispatch', {
                parent: 'main',
                url: 'plan/dispatch',
                data: {
                    'pageTitle': '预约调度'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'plan-module/dispatch/dispatch.html',
                        controller: 'iPlanDispatchController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
