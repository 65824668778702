(function () {
    'use strict';

    angular.module('iergateModule')
        .factory('ErgateRouteKeyConfig', ['$resource', 'iServiceConfig', function ($resource, iServiceConfig) {
            return $resource('web/routekeyconfig', {}, {
                'getRouteKeyConfigList': {
                    url: iServiceConfig.iergateHost + '/web/routekeyconfig/getRouteKeyConfigList',
                    method: 'GET'
                },
                'updateOrSaveRouteKeyConfig': {
                    url: iServiceConfig.iergateHost + '/web/routekeyconfig/updateOrSaveRouteKeyConfig',
                    method: 'POST'
                },
                'deleteRouteKeyConfig': {
                    url: iServiceConfig.iergateHost + '/web/routekeyconfig/deleteRouteKeyConfig',
                    method: 'POST'
                }
            });
        }]);
})();