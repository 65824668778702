(function() {
    'use strict';

    angular
        .module('doveModule')
        .factory('iBusJcoDatasource', iBusJcoDatasource);

    iBusJcoDatasource.$inject = ['$resource', 'iServiceConfig'];

    function iBusJcoDatasource ($resource, iServiceConfig) {
        var service = $resource('web/jcods', {}, {
            'search': { url: iServiceConfig.ibusHost + "/web/jcods/list", method: 'POST'},
            'add': { url: iServiceConfig.ibusHost + "/web/jcods/add", method: 'POST'},
            'update': { url: iServiceConfig.ibusHost + "/web/jcods/update", method: 'POST'},
            'delete': { url: iServiceConfig.ibusHost + "/web/jcods/delete/:id", method: 'POST'}
        });

        return service;
    }
})();
