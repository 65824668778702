(function () {
    'use strict';

    angular.module('iwh-inventoryModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('lotLocLpnInventory', {
                parent: 'main',
                url: 'iwh-inventory/lotLocLpnInventory?locCode',
                params:{
                    locCode:null
                },
                data: {
                    'pageTitle': '批次库位容器库存查询',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'iwh-inventory-module/inventorySearch/lotLocLpn/lotLocLpnInventory.html',
                        controller: 'LotLocLpnInventorySearchController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
