(function()  {
    'use strict';

    angular.module('iwh-strategyModule')
        .controller('StrategyReportConfigController', StrategyReportConfigController);

    StrategyReportConfigController.$inject = ['$rootScope','$stateParams', 'StrategyReportConfig', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout','iServiceConfig'];

    function StrategyReportConfigController ($rootScope,$stateParams, StrategyReportConfig, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.active = 0;
        vm.tabs = [];
        vm.data = {
            biz : {},
            report : []
        }

        vm.dsTypes=[{'id':null, 'title':''}, {'id':1, 'title':'DB数据源'},{'id':2, 'title':'JSON数据源'}];

        vm.tableParams = new NgTableParams({
            count : 10
        }, {
            getData : function(params) {
                var postParams = angular.merge({
                    pn : params.page(),
                    ps : params.count()
                }, params.filter());
                return StrategyReportConfig.list({}, postParams, function(resp) {
                    params.total(resp.content.tc);
                }).$promise.then(function(data) {
                    var datas = data.content.datas;
                    return datas;
                });
            }
        });

		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

        vm.save = save;
        function save(data) {
            if(data.biz==null||data.biz==""){
                toastr.error("请先选择业务功能！");
                return;
            }
            if(data.report.length==0){
                toastr.error("请先选择报表！");
                return;
            }
            var listVO = [];
            for(var i=0;i<data.report.length;i++){
                var bean = {
                    functionCode : data.biz.code,
                    functionName : data.biz.name,
                    reportCode : data.report[i].code,
                    reportName : data.report[i].name,
                    dsType : data.report[i].dsType
                }
                listVO.push(bean);
            }
            StrategyReportConfig.save({},listVO,function (resp) {
               if(resp.content=="success"){
                   toastr.success("保存成功！");
                   vm.tableParams.reload();
               }
            });
        }

        vm.del =del;
        function del(row) {
            StrategyReportConfig.del({},{id : row.id},function (resp) {
                if(resp.content=="success"){
                    toastr.success("删除成功！");
                    vm.tableParams.reload();
                }
            })
        }

    }
})();
