(function() {
    'use strict';

    angular.module('ireportNodeModule')
        .controller('RptNodeDataSourceController', RptNodeDataSourceController);

        RptNodeDataSourceController.$inject = ['RptNodeDataSource', 'NgTableParams','$uibModal', '$q','$timeout'];

    function RptNodeDataSourceController(DataSource, NgTableParams, $uibModal, $q, $timeout) {
        //debugger;
        var vm = this;
        vm.tableParams = null;
        vm.tabs = [];
        vm.status = [{'id':null, 'title':''}, {'id':0, 'title':'新建'},{'id':1, 'title':'启用'},{'id':-1, 'title':'禁用'}];
        vm.search = search;
        vm.removeTab = removeTab;
        vm.open = open;
        vm.close = close;
        vm.active = 0;
        
        vm.tableParams = new NgTableParams({count: 10}, {
            getData: function(params) {
                var postParams = angular.merge({pn: params.page(), ps: params.count()}, params.filter());
                return DataSource.list({}, postParams,
                    function(resp) {
                        params.total(resp.content.tc);
                        return resp.content.datas;
                    }).$promise.then(function(data) {
                    return data.content.datas;
                });
            }
        });
        function showError(resp) {
        	if(resp.status === 'ERROR') {
        		alert(resp.errorMsg);
        		return true;
        	} 
        	return false;
        }
        function open(datasource) {
        	DataSource.open({}, {code: datasource.code}, function(resp) {
        		if(showError(resp)) {
        			return;
        		}
                vm.search();
            });
        }

        function close(datasource) {
        	DataSource.close({}, {code: datasource.code}, function(result) {
        		if(showError(result)) {
        			return;
        		}
                vm.search();
            });
        }

        function search() {
            vm.tableParams.reload();
        }

        function removeTab(idx) {
            vm.tabs.splice(idx, 1);
        }
    }
})();
