(function() {
    'use strict';

    angular
        .module('iwh-dataModule')
        .factory('IwhStorageTypeZone', StorageTypeZone);

    StorageTypeZone.$inject = ['$resource', 'iServiceConfig'];

    function StorageTypeZone ($resource, iServiceConfig) {
        var service = $resource('web/storagetypezone', {}, {
            'list': { url: iServiceConfig.iwhDataHost+ "/web/storagetypezone/list", method: 'POST'},
            'delete': {url: iServiceConfig.iwhDataHost+ "/web/storagetypezone/delete", method: 'POST'},
            'storageList': {url: iServiceConfig.iwhDataHost+ "/web/storagetypezone/storageList", method: 'POST'},
            'save': {url: iServiceConfig.iwhDataHost+ "/web/storagetypezone/save", method: 'POST'},
            'getSkuStorageType' : {url: iServiceConfig.iwhDataHost+ "/web/storagetypezone/getSkuStorageType", method: 'POST'}
        });

        return service;
    }
})();
