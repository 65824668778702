(function () {
    'use strict';

    angular.module('itms-dataModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('tms-vehicleType-maintenance', {
                parent: 'main',
                url: 'tms-data/vehicleType-maintenance?carrierCode',
                params: {'carrierCode': null,'ownerCode':null,'postParams': null},
                data: {
                    'pageTitle': '车辆类型维护',
                    'carrierCode' : null,
                    'ownerCode':null,
                    'postParams': null
                },
                views: {
                    'mainContent': {
                        templateUrl: 'tms-data-module/vehicleType/vehicleType.html',
                        controller: 'TmsVehicleTypeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
