(function()  {
    'use strict';

    angular.module('itms-orderModule')
        .controller('TmsTransportOrderController', TmsTransportOrderController);

    TmsTransportOrderController.$inject = ['$rootScope','$stateParams', 'TmsTransportOrder', 'NgTableParams', '$uibModal', 'NgSelect', 'toastr', '$state', '$timeout', 'iServiceConfig'];

    function TmsTransportOrderController ($rootScope,$stateParams, TransportOrder, NgTableParams, $uibModal ,  NgSelect, toastr, $state, $timeout,iServiceConfig) {
        var vm = this;
        vm.state = $rootScope;
        vm.removeTab = removeTab;
        vm.iServiceConfig = iServiceConfig;
        vm.tabs = [];
        vm.modalInstance = null;
        vm.orderData = "orderData.html";
        vm.orderProducts = "orderProducts.html";
        vm.productType = {
            code : null,
            name : null
        };
        vm.carrier = {
            carrierCode : null,
            carrierName : null
        }
        vm.orderSource = [
                     {id : 1, title : "手工创建"},
                     {id : 2, title : "上游ERP"},
                     {id : 3, title : "上游WMS"},
                     {id : 4, title : "上游OMS"},
                 ];

        vm.transportMode = [
            {id : 10, title : "陆运"},
            {id : 20, title : "空运"},
            {id : 30, title : "水运"},
        ];

        vm.transportType = [
            {id : 10, title : "快递"},
            {id : 20, title : "物流"},
        ];
        vm.priority = [
            {id : 1, title : "1"},
            {id : 2, title : "2"},
            {id : 3, title : "3"},
            {id : 4, title : "4"},
            {id : 5, title : "5"},
            {id : 6, title : "6"},
            {id : 7, title : "7"},
            {id : 8, title : "8"},
            {id : 9, title : "9"},
            {id : 10, title : "10"},
        ];
        vm.type = [
            {id : 10, title : "中转"},
            {id : 20, title : "直运"},
        ];
        vm.payMethod = [
            {id : 10, title : "预付"},
            {id : 20, title : "到付"},
        ];

        vm.payStatus = [
            {id : 0, title : "未付款"},
            {id : 10, title : "部分支付"},
            {id : 20, title : "全部支付"},
        ];
        vm.status = [
            {id : 0, title : "新建"},
            {id : 10, title : "已提交"},
            {id : 15, title : "已审核"},
            {id : 20, title : "已分配承运商"},
            {id : 30, title : "已计划"},
            {id : 35, title : "已装货"},
            {id : 40, title : "运输中"},
            {id : 45, title : "已卸货"},
            {id : 50, title : "已签收"},
            {id : 60, title : "已回单"},
            {id : -10, title : "已取消"},
        ];
        vm.statusMap = {'0':'新建','10':'已提交','15':'已审核 ','20':'已分配承运商','30':'已计划','35':'已装货','40':'运输中','45':'已卸货','50' :'已签收','60':'已回单', '-10' :'取消'};

        function pageTitle() {
            return $window.document.title;
        }

      //列表页功能
		vm.tableParams = new NgTableParams({
			count : 10
		}, {
			getData : function(params) {
				var postParams = angular.merge({
					pn : params.page(),
					ps : params.count()
				}, params.filter());
				return TransportOrder.list({}, postParams, function(resp) {
					params.total(resp.content.tc);
				}).$promise.then(function(data) {
					var datas = data.content.datas;
					return datas;
				});
			}
		});
		vm.search = search;
		function search() {
			vm.tableParams.reload();

		}
		function removeTab(idx) {
			vm.tabs.splice(idx, 1);
		}

		vm.validate = validate;
        function validate(code){
        	if(code==null ||code==""){
        		return
			}else{
                TransportOrder.validateCode({}, code, function(resp) {
                    if(resp.content=="success"){

                    }
                });
			}
		}

		
		vm.show = show;
		function show(row) {
            TransportOrder.show({}, row.id, function(resp) {
				var TransportOrder = resp.content;
				var entity = {
						heading : '查看',
						contentId : "show.html",
						active : true,
                        transportOrder : TransportOrder,
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}
		
		vm.edite = edite;
		function edite(row) {
            TransportOrder.edite({}, row.id, function(resp) {
				var TransportOrder = resp.content;
				var entity = {
						heading : '编辑',
						contentId : "orderMaintenance.html",
						active : true,
                        TransportOrder : TransportOrder.TransportOrder,
                        orderDetailList: TransportOrder.orderDetailList,
                        orderDetail :{
                            productType : null,
                            productTypeName : null,
                            productName : null
                    },
                    tableParams: new NgTableParams({}, {getData:function(){return entity.orderDetailList}})
					};
					vm.tabs.push(entity);
					$timeout(function(){
		                vm.active = vm.tabs.length;
		            },0);
			 });
		}

        vm.enable = enable;
        function enable(t){
            var vo = {};
            vo.status = t.status;
            vo.id = t.id;
            TransportOrder.enable({}, vo, function(resp) {
                if(resp.content=="success"){
                    vm.tableParams.reload();
                }
            });
        }

        vm.sign = sign;
        function sign(t) {
            if(t.status!=45){
                toastr.log("卸货后才可签收，请先确认");
                return;
            }
            TransportOrder.getDetail({}, t.transportNum, function(resp) {
                var detail = resp.content;
                var entity = {
                    heading : '签收',
                    contentId : "sign.html",
                    active : true,
                    transportOrderDetailList: detail,
                    status : t.status,
                    transportNum : t.transportNum,
                    custOrderNum : t.custOrderNum,
                    id : t.id,
                    tableParams: new NgTableParams({}, {getData:function(){return entity.transportOrderDetailList}})
                };
                vm.tabs.push(entity);
                $timeout(function(){
                    vm.active = vm.tabs.length;
                },0);
            });
        }
        vm.saveSign = saveSign
        function saveSign(t) {
            var vo = {
                id : t.id,
                transportNum : t.transportNum,
                custOrderNum : t.custOrderNum,
                transportOrderDetailList : t.transportOrderDetailList,
                signatory : t.signatory,
                signNotes : t.signNotes
            }
            TransportOrder.sign({}, vo, function(resp) {

            });
        }

        vm.batchSign = batchSign;
        function batchSign() {
            var select = vm.tableParams.getSelected();
            if(select.length==0){
                toastr.error("请先选择运单");
                return;
            }
            var numString = "";
            for(var i=0;i<select.length;i++){
                numString +=select[i].transportNum+",";
            }
            numString = numString.substring(0,numString.length-1);
            var entity = {
                heading : '批量签收',
                contentId : "batchSign.html",
                active : true,
                select : select,
                num : select.length,
                numString :numString,
            };
            vm.tabs.push(entity);
            $timeout(function(){
                vm.active = vm.tabs.length;
            },0);
        }

        vm.saveBatchSign = saveBatchSign;
        function saveBatchSign(t) {
            var select = t.select;
            var vo = {
                signDTOList : [],
                signatory : t.signatory,
                signNotes : t.signNotes,
            }
            for(var i=0;i<select.length;i++){
                var dto = {
                    id : select[i].id,
                    transportNum : select[i].transportNum,
                    custOrderNum : select[i].custOrderNum,
                    status : select[i].status,
                    transportOrderDetailList : t.transportOrderDetailList,
                    signatory : t.signatory,
                    signNotes : t.signNotes
                }
                vo.signDTOList.push(dto);
            }

            TransportOrder.batchSign({}, vo, function(resp) {

            });
        }

        vm.batchPass = batchPass
        function  batchPass() {
            var vo = {
                examineList : [],
                accept : 1
            }
            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                vo.examineList.push(dto);
            }
            TransportOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核通过");
                    vm.tableParams.reload();
                }
            });
        }

        vm.batchCancel = batchCancel
        function  batchCancel(t) {
            var vo = {
                examineList : [],
                accept : 2
            }
            var examineList = [];
            var orders= vm.tableParams.getSelected();
            if(orders.length==0){
                toastr.error("请先选择订单");
                return;
            }
            for(var i=0;i<orders.length;i++){
                var dto = {
                    id: null,
                    status: null,
                }
                dto.id = orders[i].id;
                dto.status = orders[i].status;
                console.log(dto.status);
                examineList.push(dto);

            }
            vo.examineList = examineList;
            TransportOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核拒绝成功");
                    vm.tableParams.reload();
                }
            });
        }

        vm.pass = pass;
        function pass(t) {
            var vo = {
                examineList : [],
                accept : 1
            }
            var examineList = [];

            var dto = {
                id: null,
                status: null,
            }
            dto.id = t.TransportOrder.id;
            dto.status = t.TransportOrder.status;
            examineList.push(dto);
            vo.examineList = examineList;
            TransportOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核通过");
                    t.TransportOrder.status = 20;
                }
            });

        }
        vm.cancel= cancel;
        function cancel(t) {
            var vo = {
                examineList : [],
                accept : 2
            }
            var examineList = [];

            var dto = {
                id: null,
                status: null,
            }
            dto.id = t.TransportOrder.id;
            dto.status = t.TransportOrder.status;
            examineList.push(dto);
            vo.examineList = examineList;
            TransportOrder.examine({}, vo, function(resp) {
                if(resp.content=="success"){
                    toastr.success("审核拒绝成功");
                    t.TransportOrder.status = 15;
                }
            });

        }

        vm.cloneObj = cloneObj;
        function cloneObj(obj) {
            var newObj = {};
            if (obj instanceof Array) {
                newObj = [];
            }
            for (var key in obj) {
                var val = obj[key];
                newObj[key] = typeof val === 'object' ? cloneObj(val): val;
            }
            return newObj;
        };

        vm.batchAssign = batchAssign;
        function batchAssign(t) {
            if(t.getSelected().length==0){
                toastr.error("请先选择运单！");
                return
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'batchAssignConfirm.html',
                controller: batchAssignModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: t
                }
            });
        }
        batchAssignModal.$inject = ['$uibModalInstance', 'item','iServiceConfig'];
        function batchAssignModal($uibModalInstance, item,iServiceConfig) {
            var $ctrl = this;
            $ctrl.item = item.getSelected();
            $ctrl.iServiceConfig = iServiceConfig;
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.carrier = null;
            $ctrl.lineCode = null;
            $ctrl.getMeetLine =  getMeetLine;

            function getMeetLine(t){
                var vo = {
                    loadStation : $ctrl.item[0].loadStation,
                    unloadStation : $ctrl.item[0].unloadStation,
                    carrierCode : t
                }
                for(var i=1;i<$ctrl.item.length;i++){
                    if(vo.loadStation!=$ctrl.item[i].loadStation||vo.unloadStation!=$ctrl.item[i].unloadStation){
                        return
                    }
                }
                TransportOrder.getBestLine({}, vo, function (resp) {
                    $ctrl.detail = resp.content.content.detail;
                    $ctrl.transportLineDTO = resp.content.content.transportLineDTO;
                });
            }
            $ctrl.assign =  assign;
            function assign(){
                var vo = {
                    list: [],
                    carrierCode: null,
                    carrierName: null,
                    lineCode: null
                }
                for(var i=0;i<$ctrl.item.length;i++){
                    if ($ctrl.item[i].status == 15 || $ctrl.item[i].status == 20) {
                    } else {
                        toastr.error("已审核和已分配状态才能进行分配");
                        return;
                    }
                    var dto = {
                        id: null,
                        status: null
                    }
                    dto.id = $ctrl.item[i].id;
                    dto.status = $ctrl.item[i].status;
                    vo.list.push(dto);
                }
                vo.carrierCode = $ctrl.carrier.code;
                vo.carrierName = $ctrl.carrier.name;
                vo.lineCode = $ctrl.transportLineDTO.code;
                TransportOrder.assign({}, vo, function (resp) {
                    if (resp.content == "success") {
                        toastr.success("分配成功");
                        $uibModalInstance.close($ctrl.item);
                        vm.tableParams.reload();
                    }
                });
            }
            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }
		
		vm.confirmAssign = confirmAssign;
		function confirmAssign(t) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'assignConfirm.html',
                controller: assignModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: t
                }
            });
        }
            assignModal.$inject = ['$uibModalInstance', 'item','iServiceConfig'];
            function assignModal($uibModalInstance, item,iServiceConfig) {
                var $ctrl = this;
                $ctrl.item = item;
                $ctrl.iServiceConfig = iServiceConfig;
                $ctrl.lineCode = null;
                $ctrl.one = true;
                $ctrl.batch = false;
                $ctrl.ok = ok;
                $ctrl.cancel = cancel;
                $ctrl.lineDetail = [];
                $ctrl.carrier = {
                    code: null,
                    name: null
                };
                $ctrl.assign =  assign;
                $ctrl.getLine = getLine;
                function assign(){
                if ($ctrl.item.transportOrder.status == 15 || $ctrl.item.transportOrder.status == 20) {
                } else {
                    toastr.error("已审核和已分配状态才能进行分配");
                    return;
                }
                var vo = {
                    list: [],
                    carrierCode: null,
                    carrierName: null
                }
                var dto = {
                    id: null,
                    status: null
                }
                dto.id = $ctrl.item.transportOrder.id;
                dto.status = $ctrl.item.transportOrder.status;
                vo.list.push(dto);
                vo.carrierCode = $ctrl.carrier.code;
                vo.carrierName = $ctrl.carrier.name;
                vo.lineCode = $ctrl.lineCode;
                TransportOrder.assign({}, vo, function (resp) {
                    if (resp.content == "success") {
                        toastr.success("分配成功");
                        $uibModalInstance.close($ctrl.item);
                        $ctrl.item.transportOrder.statusStr="已分配承运商";
                        $ctrl.item.transportOrder.status="20";
                    }
                });
            }
                function getLine(){
                    if($ctrl.lineCode==null){
                        $ctrl.lineDetail=[];
                        return;
                    }
                    TransportOrder.getLineDetail({}, $ctrl.lineCode, function (resp) {
                        $ctrl.lineDetail = resp.content.content;
                    });
                }
                function ok() {
                    $uibModalInstance.close($ctrl.item);
                }

                function cancel() {
                    $uibModalInstance.dismiss('cancel');
                }
            }

        vm.confirmLine = confirmLine;
        function confirmLine(t) {
            if(parseInt(t.status)!=20){
                toastr.error("分配承运商后才能设置线路");
                return;
            }
            var vo = {
                loadStation : t.loadStation,
                unloadStation : t.unloadStation,
                carrierCode : t.carrierCode
            }
            vm.modalInstance = $uibModal.open({
                templateUrl: 'lineConfirm.html',
                controller: lineModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: {
                        t : t,
                        listLine : TransportOrder.getMeetLine({}, vo).$promise.then(function(resp) {
                            return resp;
                        })
                    }
                }
            });
        }
        lineModal.$inject = ['$uibModalInstance', 'item','iServiceConfig'];
        function lineModal($uibModalInstance, item,iServiceConfig) {
            var $ctrl = this;
            $ctrl.item = item.t;
            $ctrl.listLine = item.listLine.then(function(resp) {
                console.log(resp.content);
                return resp.content.content;
            });
            $ctrl.ok = ok;
            $ctrl.cancel = cancel;
            $ctrl.lineCode = null;
            $ctrl.line =  line;
            $ctrl.iServiceConfig = iServiceConfig;
            function line(){
                var vo = {
                    id : $ctrl.item.id,
                    lineCode : $ctrl.lineCode,
                }
                TransportOrder.distributLine({}, vo, function (resp) {
                    if (resp.content == "success") {
                        toastr.success("设置成功");
                        $uibModalInstance.close($ctrl.item);
                        vm.tableParams.reload();
                    }
                });
            }

            function ok() {
                $uibModalInstance.close($ctrl.item);
            }

            function cancel() {
                $uibModalInstance.dismiss('cancel');
            }
        }

        vm.confirmBatchAssign = confirmBatchAssign;
        function confirmBatchAssign(list)
        {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'assignConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item: list
                }
            });
            vm.modalInstance.result.then(function (item) {
                var vo = {
                    list: [],
                    carrierCode: null,
                    carrierName: null
                }
                for(var i=0;i<item.length;i++){
                    if (item[i].status == 15 || item[i].status == 20) {
                        var dto = {
                            id: null,
                            status: null
                        }
                        dto.id = item[i].id;
                        dto.status = item[i].status;
                        vo.list.push(dto);
                    } else {
                        toastr.error("已审核和已分配状态才能进行分配");
                        return;
                    }
                }
                vo.carrierCode = $ctrl.carrier.carrierCode;
                vo.carrierName = $ctrl.carrier.carrierName;
                TransportOrder.assign({}, vo, function (resp) {
                    if (resp.content == "success") {
                        toastr.success("分配成功");
                        search();
                    }
                });
            });
        }


		vm.confirmSave = confirmSave;
		function confirmSave(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'saveConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',

                resolve: {
                    item : {
                    	t : t,
                    	 index : $index,
                         form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
            	vm.update(item.t,item.index,item.form);
            });
        }
        vm.confirmSubmit = confirmSubmit;
        function confirmSubmit(t,$index,form) {
            vm.modalInstance = $uibModal.open({
                templateUrl: 'submitConfirm.html',
                controller: deleteModal,
                controllerAs: '$ctrl',
                resolve: {
                    item : {
                        t : t,
                        index : $index,
                        form : form
                    }
                }
            });
            vm.modalInstance.result.then(function(item) {
                if(item.t.TransportOrder.status !=0){
                    toastr.error("新建状态才能提交！");
                    return;
                }
                item.t.TransportOrder.status = 10;
                vm.update(item.t,item.index,item.form);
            });
        }
		
		 deleteModal.$inject = ['$uibModalInstance', 'item'];
	        function deleteModal($uibModalInstance, item) {
	            var $ctrl = this;
	            $ctrl.item = item;
	            $ctrl.ok = ok;
	            $ctrl.cancel = cancel;
                $ctrl.carrier = {
                    carrierCode :null,
                    carrierName : null
                };

	            function ok() {
	                $uibModalInstance.close($ctrl.item);
	            }

	            function cancel() {
	                $uibModalInstance.dismiss('cancel');
	            }
	        }
    }
})();
