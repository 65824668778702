(function() {
    'use strict';

    angular
        .module('iwh-inventoryModule')
        .factory('LotAttribute', LotAttribute);

    LotAttribute.$inject = ['$resource', 'iServiceConfig'];

    function LotAttribute ($resource, iServiceConfig) {
        var service = $resource('web/lotAttribute', {}, {
            'list': { url: iServiceConfig.iwhInventoryHost + "/web/lotAttribute/list", method: 'POST'},
            'beforeList': { url: iServiceConfig.iwhInventoryHost + "/web/lotAttribute/beforeList", method: 'POST'},

        });
        return service;
    }
})();
