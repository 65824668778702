(function() {
    "use strict";
    angular.module("itms-orderModule").factory("TmsCustomerOrder", ["$resource", "iServiceConfig",  function($resource, iServiceConfig) {
        return $resource(iServiceConfig.itmsOrderHost + "/web/customerOrder", {}, {
        	'list': { url: iServiceConfig.itmsOrderHost + "/web/customerOrder/list", method: 'POST'},
            'save': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/saveAndUpdate", method: 'POST'},
            'create': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/create", method: 'POST'},
            'edite': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/edite", method: 'POST'},
            'show': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/show", method: 'POST'},
            'delete': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/delete", method: 'POST'},
            'examine': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/examine", method: 'POST'},
            'convert': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/convert", method: 'POST'},
            'withdraw': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/withdraw", method: 'POST'},
            'codes': {url: iServiceConfig.itmsOrderHost + "/web/customerOrder/codes", method: 'GET'}
        });
    }]);
})();