(function() {
    'use strict';

    angular
        .module('idataModule')
        .factory('Warehouse', Warehouse);

    Warehouse.$inject = ['$resource', 'iServiceConfig'];

    function Warehouse ($resource, iServiceConfig) {
        var service = $resource('web/warehouse', {}, {
        	
            'list': { url: iServiceConfig.idataHost + "/web/warehouse/list", method: 'POST'},
            'detail': {url: iServiceConfig.idataHost + "/web/warehouse/detail", method: 'POST'},
            'save': {url: iServiceConfig.idataHost + "/web/warehouse/save", method: 'POST'},
            'delete':{url:iServiceConfig.idataHost + "/web/warehouse/delete",method:'POST'},
            'disable':{url:iServiceConfig.idataHost + "/web/warehouse/disable", method:'POST'},
            'enable':{url:iServiceConfig.idataHost + "/web/warehouse/enable", method:'POST'},
            'checkCode': {url: iServiceConfig.idataHost + "/web/warehouse/checkcode", method: 'POST'},
            'init': {url: iServiceConfig.idataHost + "/web/warehouse/init", method: 'POST'},
        });
        return service;
    }
})();
