(function () {
    'use strict';

    angular.module('itms-orderModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('tms-roadTrack', {
                parent: 'main',
                url: 'tms-process/roadTrack',
                data: {
                    'pageTitle': '运输计划跟踪',
                },
                views: {
                    'mainContent': {
                        templateUrl: 'tms-order-module/roadTrack/roadTrack.html',
                        controller: 'TmsRoadTrackController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
