(function() {
    'use strict';

    angular
        .module('iwh-shipModule')
        .factory('TransferBatch', TransferBatch);

    TransferBatch.$inject = ['$resource', 'iServiceConfig'];

    function TransferBatch ($resource, iServiceConfig) {
        var service = $resource('web/transferBatch', {}, {
            'list': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/list", method: 'POST'},
            'saveTransferBatch': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/saveTransferBatch", method: 'POST'},
            'addBoxToTransferBatch': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/addBoxToTransferBatch", method: 'POST'},
            'removeBoxFromTransferBatch': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/removeBoxFromTransferBatch", method: 'POST'},
            'completeTransferBatch': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/completeTransferBatch", method: 'POST'},
            'getTransferBatch': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/getTransferBatch", method: 'POST'},
            'cancelTransferBatch': { url: iServiceConfig.iwhShipHost + "/web/transferBatch/cancelTransferBatch", method: 'POST'}
        });
        return service;
    }
})();
