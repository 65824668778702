(function () {
    'use strict';

    angular.module('doveModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig($stateProvider) {
        $stateProvider
            .state('iBusmain', {
                parent: 'main',
                url: 'bus/main',
                data: {
                    'pageTitle': '数据交换监控首页'
                },
                views: {
                    'mainContent': {
                        templateUrl: 'bus-module/main/mainContent.html',
                        controller: 'iBusMainContentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
    }
})();
