(function () {
    'use strict';

    angular.module('iwh-dataModule').controller('IwhWhLocationGenController', IwhWhLocationGenController);

    IwhWhLocationGenController.$inject = ["$rootScope", "$scope", "NgTableParams",
        "$uibModal", "NgSelect", "$state", "$timeout", "toastr", "NgTree",
        "IwhWhLocation"];

    function IwhWhLocationGenController($rootScope, $scope, NgTableParams, $uibModal, NgSelect,
                                  $state, $timeout, toastr, NgTree, WhLocation) {
        var vm = this;
        vm.tabs = [];                   // 记录所有的tab
        vm.active = 0;                  // 总共打开tab的总数
        vm.noteTypes = {
            root: "root",              // 根
            section: "section",        // 区段
            zone: "zone",              // 库区
            location: "location"       // 库位
        };
        vm.genSectionParam = {
        	codePrefix: "",
        	startSeq: "",
        	genCount: 0
        };
        vm.genZoneParam = {
        	codePrefix: "",
        	startSeq: "",
        	genCount: 0
        };
        vm.genLocationParam = {
        	codePrefix: "",
        	levelCount: 0,
        	startSeq: "",
        	genCount: 0,
        	length: 1,
        	width: 1,
        	height: 1
        };
        vm.noteType = "root";           // 树当前点击节点类型
        vm.select = [{id: 1, title: "按区段"}, {id: 2, title: "按库区"}, {id: 3, title: "按库位"}];
        vm.selectCondition = {
            selectType: null,
            condition: null
        };
        vm.flagToNewLocationTable = false;
        vm.flagToInitLocationTableFiter = false;

        vm.searchTreeK = function ($event) {
            if ($event.keyCode == 13) {//回车
                vm.searchTree();
            }
        };
        
        vm.genSection = function() {
        	
        };

        vm.searchTree = function () {
            // console.log('click');
            if (!!!vm.selectCondition.selectType) {
                vm.selectCondition.condition = null;
            }
            var data = vm.selectCondition;
            // console.log("vm.selectCondition");
            // console.log(vm.selectCondition);
            WhLocation.treeDataByCondition(data, {}, function (resp) {
                if ("SUCCESS" == resp.status) {
                    vm.tree.data = resp.content || [];
                    if (vm.selectCondition.selectType == 3) {
                        //若是按库位查询则显示查询详情
                        if (!!vm.tree.data && !!vm.tree.data[0] && !!vm.tree.data[0].children) {
                            // console.log("vm.tree.data");
                            // console.log(vm.tree.data);
                            //找出第一个库区节点
                            var zoneNode = vm.tree.data[0].children[0].children[0];
                            vm.flagToNewLocationTable = true;
                            vm.flagToInitLocationTableFiter = true;
                            //触发选中节点事件
                            // vm.selectNode(zoneNode);
                            //默认选中
                            // console.log("Select");

                            vm.tree.changeConfirmSelected(zoneNode.nodeId);
                        }
                    }
                    if (vm.selectCondition.selectType == 2 || !!!vm.selectCondition.selectType) {
                        vm.flagToNewLocationTable = true;
                        vm.flagToInitLocationTableFiter = false;
                    }
                    if (!!!vm.tree.data[0].children) {
                        toastr.info("当前没有查询到任何数据");
                    }
                } else {
                    toastr.error(resp.errorMsg || "加载库位树失败！", "错误");
                }
            });
        };
        /**
         * 区段/库区树
         */
        vm.tree = new NgTree({
            data: [],
            config: {
                meta: {
                    titleField: 'title',
                    parentIdField: 'parentId',
                    nodeIdField: 'nodeId',
                    childField: 'children'
                }
            }
        });


        /**
         * 刷新树
         */
        vm.refreshTree = function () {
            WhLocation.treeData({}, {}, function (resp) {
                if ("SUCCESS" == resp.status) {
                    vm.tree.data = resp.content || [];
                } else {
                    toastr.error(resp.errorMsg || "加载库位树失败！", "错误");
                }
            });
        };
        vm.refreshTree();


        /**
         * 监听数点击节点
         * @param node
         */
        vm.selectNode = function (node) {

            // console.log("Node:");
            // console.log(node);
            // console.log(vm.noteTypes.zone == node.type);
            vm.noteType = node.type;
            if (vm.noteTypes.section == node.type) {
                vm.sectionObj.sectionCode = node.data.code;    // 当前库位代码
                vm.zoneObj.startLoadData = true;
                vm.zoneObj.search();
            } else if (vm.noteTypes.zone == node.type) {
                vm.sectionObj.sectionCode = vm.tree.getNodeById(node.parentId).data.code;
                vm.zoneObj.zoneCode = node.data.code;          // 当前库区编码
                vm.locationObj.startLoadData = true;
                vm.locationObj.search();
            } else {
                vm.noteType = vm.noteTypes.root;
                vm.sectionObj.sectionCode = "";             // 区段编码
                vm.zoneObj.zoneCode = "";                   // 库区编码
                vm.sectionObj.search();
            }
        };


        /**
         * 刷新按钮点击处理
         */
        vm.search = function () {
            if (vm.noteType == vm.noteTypes.section) {
                vm.zoneObj.search();
            } else if (vm.noteType == vm.noteTypes.zone) {
                vm.locationObj.search();
            } else {
                vm.sectionObj.search();
            }
        };

        /**
         * 创建按钮点击处理
         */
        vm.create = function () {
            if (vm.noteType == vm.noteTypes.section) {
                vm.zoneObj.createZone();
            } else if (vm.noteType == vm.noteTypes.zone) {
                vm.locationObj.createLocation();
            } else {
                vm.sectionObj.createSection();
            }
        };


        /**
         * 删除指定下标的tab页
         * @param idx 下标
         */
        vm.removeTab = function (idx) {
            vm.tabs.splice(idx, 1);
        };


        /**
         * 区段操作
         * @type {{}}
         */
        vm.sectionObj = {
            // 当前选中节点代码
            sectionCode: "",

            // 过滤器数据
            filterData: {
                status: [
                    {id: null, title: '全部'},
                    {id: 1, title: "启用"},
                    {id: -1, title: "禁用"}
                ]
            },

            // 获取区段表格数据
            getTableList: new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count(),
                        orderBy: 'code'
                    }, params.filter());
                    return WhLocation.getSectionList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        return data.content.datas;
                    });
                }
            }),

            // 查询数据
            search: function () {
                vm.sectionObj.getTableList.reload();
            },

            // 查看详情
            showOwner: function (row) {
                vm.tabs.push({
                    heading: '区段详情',
                    contentId: "section-detail.html",
                    data: row,
                    roles: [],
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },

            // 新增库区
            createSection: function () {
                vm.tabs.push({
                    heading: '新增区段',
                    contentId: "section-edit.html",
                    data: {
                        type: "add"
                    },
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },

            // 保存区段信息
            saveSection: function (tab, idx, form) {
                var section = tab.data;
                if (!form.$valid) {
                    form.showMsg = true;
                    toastr.error("请填写完整数据", '错误');
                    return;
                }
                WhLocation.saveSection({}, section, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        vm.removeTab(idx);
                        toastr.success("保存区段成功");
                        vm.sectionObj.search();
                        vm.refreshTree();
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存区段失败！"), "错误");
                    }
                });
            },

            // 更新区段信息
            updateSection: function (row) {
                row.type = "update";
                vm.tabs.push({
                    heading: '编辑区段',
                    contentId: "section-edit.html",
                    data: row,
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },

            // 删除区段信息
            deleteSection: function (row) {
                WhLocation.delSectionById({}, {
                    id: row.id
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        vm.sectionObj.search();
                        toastr.success("删除区段成功！");
                    } else {
                        toastr.error(resp.errorMsg || "删除区段失败！", "错误");
                    }
                })
            },

            // 改变区段状态
            changeSectionStatus: function (row) {
                WhLocation.changeSectionStatus({}, {
                    id: row.id,
                    tenantId: row.tenantId,
                    warehouseCode: row.warehouseCode,
                    status: (row.status === 1 ? -1 : 1)
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success(row.status === 1 ? "成功禁用" : "成功启用");
                        vm.sectionObj.search();
                    } else {
                        toastr.error(resp.errorMsg || "改变区段状态失败！", "错误");
                    }
                });
            }
        };


        /**
         * 库区操作
         * @type {{}}
         */
        vm.zoneObj = {
            startLoadData: false,
            zoneCode: "",

            // 过滤器数据
            filterData: {
                status: [
                    {id: null, title: '全部'},
                    {id: 1, title: "启用"},
                    {id: -1, title: "禁用"}
                ]
            },

            // 获取库区表格数据
            getTableList: new NgTableParams({
                count: 10
            }, {
                getData: function (params) {
                    if (!vm.zoneObj.startLoadData) {
                        return;
                    }
                    var postParams = angular.merge({
                        pn: params.page(),
                        ps: params.count(),
                        sectionCode: vm.sectionObj.sectionCode
                    }, params.filter());
                    return WhLocation.getZoneList({}, postParams, function (resp) {
                        params.total(resp.content.tc);
                    }).$promise.then(function (data) {
                        // console.debug(data.content.datas);
                        return data.content.datas;
                    });
                }
            }),

            // 查询数据
            search: function () {
                vm.zoneObj.getTableList.reload();
            },

            // 查看详情
            showOwner: function (row) {
                vm.tabs.push({
                    heading: '库区详情',
                    contentId: "zone-detail.html",
                    data: row,
                    roles: [],
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },

            // 新增库区
            createZone: function () {
                vm.tabs.push({
                    heading: '新增库区',
                    contentId: "zone-edit.html",
                    data: {
                        type: "add",
                        relayFlag: 1,
                        sectionCode: vm.sectionObj.sectionCode
                    },
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },

            // 保存库区信息
            saveZone: function (tab, idx, form) {
                var zone = tab.data;
                if (!form.$valid) {
                    form.showMsg = true;
                    toastr.error("请填写完整数据", '错误');
                    return;
                }
                WhLocation.saveZone({}, zone, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        vm.removeTab(idx);
                        toastr.success("保存库区成功");
                        vm.zoneObj.search();
                        vm.refreshTree();
                    } else {
                        toastr.error("[" + resp.errorCode + "] " + (resp.errorMsg || "保存库区失败！"), "错误");
                    }
                });
            },

            // 更新库区信息
            updateZone: function (row) {
                // console.debug(row);
                row.type = "update";
                vm.tabs.push({
                    heading: '修改库区',
                    contentId: "zone-edit.html",
                    data: row,
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            },

            // 删除库区信息
            deleteZone: function (row) {
                WhLocation.delZoneById({}, {
                    id: row.id
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success("删除库区成功！");
                        vm.zoneObj.search();
                    } else {
                        toastr.error(resp.errorMsg || "删除库区失败！", "错误");
                    }
                })
            },

            // 改变库区状态
            changeZoneStatus: function (row) {
                WhLocation.changeZoneStatus({}, {
                    id: row.id,
                    status: (row.status === 1 ? -1 : 1)
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success(row.status === 1 ? "成功禁用" : "成功启用");
                        vm.zoneObj.search();
                    } else {
                        toastr.error(resp.errorMsg || "改变库区状态失败！", "错误");
                    }
                });
            }
        };


        /**
         * 库位操作
         * @type {{}}
         */
        vm.locationObj = {
            startLoadData: false,

            // 过滤器数据
            filterData: {
                status: [
                    {id: null, title: '全部'},
                    {id: 1, title: "启用"},
                    {id: -1, title: "禁用"}
                ]
            },

            // 获取库位表格数据
            getTableList: new NgTableParams({
                    count: 10,
                    filter: {}
                },
                {
                    getData: function (params) {
                        if (!vm.locationObj.startLoadData) {
                            return;
                        }
                        var postParams = angular.merge({
                            pn: params.page(),
                            ps: params.count(),
                            orderBy: 'code',
                            sectionCode: vm.sectionObj.sectionCode,
                            zoneCode: vm.zoneObj.zoneCode
                        }, params.filter());
                        // console.log("params.filter");
                        // console.log(params.filter());
                        // console.log("postParams");
                        // console.log(postParams);

                        return WhLocation.getLocationList({}, postParams, function (resp) {
                            params.total(resp.content.tc);
                        }).$promise.then(function (data) {
                            return data.content.datas;
                        });
                    }
                }
            ),

            // 查询数据
            search: function () {
                // console.log(vm.flagToNewLocationTable);
                // console.log("vm.flagToNewLocationTable:" + vm.flagToNewLocationTable);
                if (vm.flagToNewLocationTable) {
                    // console.log(vm.flagToInitLocationTableFiter);
                    vm.locationObj.getTableList.filter.code = vm.flagToInitLocationTableFiter
                        ? vm.selectCondition.condition || "" : "";
                    // console.log(vm.locationObj.getTableList.filter.code);
                    // vm.locationObj.getTableList = new NgTableParams({
                    //         count: 10,
                    //         filter: {
                    //             code: vm.flagToInitLocationTableFiter ? vm.selectCondition.condition || "" : ""
                    //         }
                    //     },
                    //     {
                    //         getData: function (params) {
                    //             console.log(vm.locationObj.startLoadData);
                    //             if (!vm.locationObj.startLoadData) {
                    //                 return;
                    //             }
                    //             var postParams = angular.merge({
                    //                 pn: params.page(),
                    //                 ps: params.count(),
                    //                 orderBy: 'code',
                    //                 sectionCode: vm.sectionObj.sectionCode,
                    //                 zoneCode: vm.zoneObj.zoneCode
                    //             }, params.filter());
                    //             // console.log("params.filter");
                    //             // console.log(params.filter());
                    //             // console.log("postParams");
                    //             // console.log(postParams);
                    //
                    //             return WhLocation.getLocationList({}, postParams, function (resp) {
                    //                 params.total(resp.content.tc);
                    //             }).$promise.then(function (data) {
                    //                 return data.content.datas;
                    //             });
                    //         }
                    //     }
                    // );
                    vm.flagToNewLocationTable = false;
                } else {
                    vm.locationObj.getTableList.reload();

                }
            },

            // 查看详情
            showOwner: function (row) {
                vm.tabs.push({
                    heading: '库位详情',
                    contentId: "location-detail.html",
                    data: row,
                    roles: [],
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }
            ,

            // 新增库位
            createLocation: function () {
                vm.tabs.push({
                    heading: '新增库位',
                    contentId: "location-edit.html",
                    data: {
                        type: "add",
                        loseIdFlag: 0,
                        sectionCode: vm.sectionObj.sectionCode,
                        zoneCode: vm.zoneObj.zoneCode
                    },
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }
            ,

            // 保存库位信息
            saveLocation: function (tab, $index, form) {
                var zone = tab.data;
                if (!form.$valid) {
                    form.showMsg = true;
                    toastr.error("请填写完整数据", '错误');
                    return;
                }
                WhLocation.saveLocation({}, zone, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        vm.removeTab($index);
                        toastr.success("保存库位成功");
                        vm.locationObj.search();
                        vm.refreshTree();
                    } else {
                        toastr.error("[" + resp.errorCode + "] " +
                            (resp.errorMsg || "保存库位失败！"), "错误");
                    }
                });
            }
            ,

            // 更新库区信息
            updateLocation: function (row) {
                row.type = "update";
                vm.tabs.push({
                    heading: '修改库位',
                    contentId: "location-edit.html",
                    data: angular.copy(row),
                    active: true
                });
                $timeout(function () {
                    vm.active = vm.tabs.length;
                }, 0);
            }
            ,

            // 删除库位信息
            deleteLocation: function (row) {
                WhLocation.delLocationById({}, {
                    id: row.id
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success("删除库位成功！");
                        vm.locationObj.search();
                        vm.refreshTree();
                    } else {
                        toastr.error(resp.errorMsg || "删除库位失败！", "错误");
                    }
                });
            }
            ,

            // 改变库位状态
            changeLocationStatus: function (row) {
                WhLocation.changeLocationStatus({}, {
                    id: row.id,
                    status: (row.status === 1 ? -1 : 1)
                }, function (resp) {
                    if ("SUCCESS" == resp.status) {
                        toastr.success(row.status === 1 ? "成功禁用" : "成功启用");
                        vm.locationObj.search();
                        vm.refreshTree();
                    } else {
                        toastr.error(resp.errorMsg || "改变库位状态失败！", "错误");
                    }
                });
            }
        }
        ;
    };
})();

